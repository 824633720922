import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useFormContext } from "react-hook-form";
import {
  IFXAccordion,
  IFXFormLayout,
  IFXFieldWrapper,
  IFXTextArea,
  CoreValidationRuleCodes,
  useToast,
  useObservableCallback,
  ifxJsonAjax,
  IFXUserNote,
} from "@ifx-react/ifx-react-core";
import { Subject } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { UserRegistrationContext } from "./UserRegistration";
import { UserRegistrationConsts } from "./userRegistrationConsts";
import { CLEAR_ACCESS_PROFILE_REQUEST_URL } from "../../../const/portal/endpoints";
import { LayoutActions } from "../../../store/actions/common/actionCreators";

const clearAccessReqst$ = new Subject().pipe(
  mergeMap(params =>
    ifxJsonAjax
      .post(
        CLEAR_ACCESS_PROFILE_REQUEST_URL +
          "?strUserName=" +
          params.userName +
          "&userId=" +
          params.userId
      )
      .pipe(
        map(xhrResponse => {
          return xhrResponse;
        }),
        catchError(error => {
          return error;
        })
      )
  )
);

export const AccessReqstForm = props => {
  const [clearedAccessReq, setClearedAccessReq] = useState(false);
  const { ifxConfirmToast } = useToast();
  const { editedBy, userData, toggleAcc } = useContext(UserRegistrationContext);
  const { setValue } = useFormContext();

  const dispatch = useDispatch();

  const clearAccessRequest = () => {
    const clearAccessReq = UserRegistrationConsts.CLEAR_ACCESS_REQ_CONFIRM;
    clearAccessReq.onCallback = (() => (e, name) => {
      if (name === "Yes") {
        dispatch(
          LayoutActions.SET_LOADER_SHOW({
            loaderText: "Clearing...",
          })
        );
        clearAccessReqst$.next({
          userName: userData.userName,
          userId: userData.userId,
        });
      }
    })();
    ifxConfirmToast(clearAccessReq);
  };

  useObservableCallback(
    clearAccessReqst$,
    response => {
      setValue("accessRequest", "");
      setClearedAccessReq(true);
      dispatch(LayoutActions.SET_LOADER_HIDE());
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
    }
  );

  return (
    <>
      <IFXAccordion
        title="Access Request"
        isOpen={toggleAcc == "initial" ? true : toggleAcc}
        accordionActions={[
          {
            type: "clear",
            name: "Clear Access Request",
            onClick: clearAccessRequest,
            includeCondition:
              editedBy === "admin" &&
              !(!userData.accessRequest || clearedAccessReq),
          },
        ]}
      >
        {(!editedBy || editedBy === "self") && (
          <IFXUserNote>
            <div>
              Please provide the type of access you require in order to perform
              your job duties. Examples - Specifications, Prices, Service
              Schedules, Orders, Invoices, Galley Plans.
              <br />
              Additionally, please indicate any specific stations you require
              access to for your organization and role:
              <br />
            </div>
          </IFXUserNote>
        )}
        <IFXFormLayout allFieldsColProps={{ xl: 12, lg: 12, md: 12, sm: 12 }}>
          {(!editedBy || (editedBy === "self" && userData.accessRequest)) && (
            <IFXFieldWrapper
              label="Access Request"
              controlId="accessRequest"
              name="accessRequest"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXTextArea showCounter={true} maxLength={1500} />
            </IFXFieldWrapper>
          )}
          {editedBy === "self" && !userData.accessRequest && (
            <IFXFieldWrapper
              label="Access Request"
              controlId="accessRequest"
              name="accessRequest"
            >
              <IFXTextArea showCounter={true} maxLength={1500} />
            </IFXFieldWrapper>
          )}
          {editedBy === "admin" && (
            <IFXFieldWrapper
              label="Access Request"
              controlId="accessRequest"
              name="accessRequest"
              readOnly
            >
              <IFXTextArea showCounter={true} maxLength={1500} />
            </IFXFieldWrapper>
          )}
        </IFXFormLayout>
      </IFXAccordion>
    </>
  );
};
