import React, { useState } from "react";
import { Card, FormGroup } from "react-bootstrap";
import {
  IFXAccordion,
  IFXTextBox,
  IFXAutoSuggest,
  IFXFieldWrapper,
} from "../../../components/AppComponents";

export const AccordionCard = () => {
  const defaultFilterElements = {
    carriers: [],
    sampleTextBox: "",
  };
  const [filterElements, setfilterElements] = useState(defaultFilterElements);
  const currentStatus = val => {
    console.log(val);
  };
  return (
    <>
      <Card className="main-card mb-3">
        <Card.Body>
          <IFXAccordion
            title="Accordion with textbox and call Back"
            isOpen={true}
            enabled={true}
            isAccordion={true}
            toggleCallback={currentStatus}
          >
            <FormGroup className="mb-0">
              <div>
                <IFXFieldWrapper
                  className="mb-0"
                  label="Text Box"
                  controlId="sampleTextBox"
                  alwaysFloat={(() =>
                    filterElements.sampleTextBox != null &&
                    filterElements.sampleTextBox.length > 0)()}
                >
                  <IFXTextBox
                    value={filterElements.sampleTextBox}
                    onChange={e => {
                      const {
                        target: { value: v },
                      } = e;
                      setfilterElements({
                        ...filterElements,
                        sampleTextBox: v,
                      });
                    }}
                    maxLength={15}
                  ></IFXTextBox>
                </IFXFieldWrapper>
              </div>
            </FormGroup>
          </IFXAccordion>
        </Card.Body>
      </Card>
      <Card className="main-card mb-3">
        <Card.Body>
          <IFXAccordion
            title="Accordion with auto suggest"
            isOpen={true}
            enabled={true}
            isAccordion={true}
          >
            <FormGroup className="mb-0">
              <IFXFieldWrapper
                className="mb-0"
                label="Carriers"
                controlId="carriers_accordian"
                alwaysFloat={(() => filterElements.carriers.length > 0)()}
                type="group"
              >
                <IFXAutoSuggest
                  fieldType="carrier"
                  onChange={v => {
                    setfilterElements({ ...filterElements, carriers: v });
                  }}
                  selected={filterElements.carriers}
                />
              </IFXFieldWrapper>
            </FormGroup>
          </IFXAccordion>
        </Card.Body>
      </Card>
    </>
  );
};
