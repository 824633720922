import { SPMTTasks } from "../../../../../const/spmt/SPMTTaskConsts";

export const SPMTMenu = {
  dashboard: {
    authorize: { hasTask: SPMTTasks.SPMTApplication },
    icon: "metrics",
    title: "METRICS",
    to: "/app/spmt/dashboard",
    source: "/app/spmt/",
  },
};
