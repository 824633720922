import React, { useCallback } from "react";
import cx from "classnames";
import { Button } from "react-bootstrap";
import { HeaderLogo } from "../AppLogo";
import { HeaderAirlineLogo } from "../../../../components/common/header/HeaderAirlineLogo";
import { UserBox } from "../../../../components/common/header/UserBox";
import { useDispatch } from "react-redux";
import { LayoutActions } from "../../../../store/actions/common/actionCreators";
import { DocumentCenter } from "../../../../components/portal/DocumentCenter";
import { Authorize } from "../../../../components/common/security/Authorize";
import { PortalTasks } from "../../../../const/portal/PortalTaskConsts";
import { IFXTooltip } from "@ifx-react/ifx-react-core";
import { AlertIcon } from "../../../../components/portal/AlertIcon";

export const AppHeader = React.memo(props => {
  const dispatch = useDispatch();
  const isRegisterPage = props.isRegisterPage || false;

  const goFullScreen = useCallback(() => {
    dispatch(LayoutActions.SET_FULLSCREEN_ENABLE(true));
  }, []);

  return (
    <>
      <div className="app-header header-shadow">
        <HeaderLogo />
        <div
          className={cx(
            "app-header__content"
            //{'header-mobile-open': enableMobileMenuSmall},
          )}
        >
          <div className="app-header-left header-btn-lg">
            <div className="ifx-logo-src " />
          </div>
          {!isRegisterPage && (
            <div className="app-header-right pr-2">
              <AlertIcon />
              <Authorize
                authorize={{ hasTask: PortalTasks.viewReportDownloadCenter }}
              >
                <DocumentCenter />
              </Authorize>
              <HeaderAirlineLogo />
              <UserBox />
              <div className="header-icons-wrapper">
                <IFXTooltip placement="top" content="Full Screen">
                  <Button variant="transparent" onClick={goFullScreen}>
                    <div className="header-icon header-maximize-icon" />
                  </Button>
                </IFXTooltip>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
});
