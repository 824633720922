import React, { useContext, useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  IFXAccordion,
  IFXFormLayout,
  IFXFieldWrapper,
  CoreValidationRuleCodes,
  IFXDropdown,
  ifxJsonAjax,
  useObservableCallback,
  isEmptyOrNull,
} from "@ifx-react/ifx-react-core";
import { Subject } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { UserRegistrationContext } from "./UserRegistration";
import { UserRegistrationConsts } from "./userRegistrationConsts";

const companyDetails$ = new Subject().pipe(
  mergeMap(p =>
    ifxJsonAjax
      .get(
        `/ifx/framework/autosuggest.do?key=*&${p.queryString}%7D&dojo.preventCache=1600329829066`
      )
      .pipe(
        map(xhrResponse => {
          return {
            response: xhrResponse.response,
            userType: p.seltypeofUser,
            carrier: p.carrier,
          };
        }),
        catchError(error => {
          return error;
        })
      )
  )
);

const companyAddressDetails$ = new Subject().pipe(
  mergeMap(p =>
    ifxJsonAjax
      .get(
        `/ifx/api/portal/user/getCompanyAddress.do?orgId=${p.orgId}&isAirlineType=${p.isAirlineType}`
      )
      .pipe(
        map(xhrResponse => {
          return {
            response: xhrResponse.response,
            isAirlineType: p.isAirlineType,
            companyDetails: p.companyDetails,
          };
        }),
        catchError(error => {
          return error;
        })
      )
  )
);

// const typesOfUser = [
//   { label: "Airline", value: 1 },
//   { label: "Caterer", value: 2 },
//   { label: "Other", value: 3 },
// ];

const isSamlUserType = (carrier, userType) => {
  if (
    (carrier?.samlAccessUserType == 1 &&
      userType?.toLowerCase() == "airline") ||
    (carrier?.samlAccessUserType == 2 &&
      userType?.toLowerCase() == "caterer") ||
    (carrier?.samlAccessUserType == 3 &&
      (userType?.toLowerCase() == "airline" ||
        userType?.toLowerCase() == "caterer"))
  ) {
    return true;
  }
  return false;
};

export const CompanyInfoForm = props => {
  const { setValue, getValues } = useFormContext();

  const {
    defaultOptions,
    editedBy,
    userData,
    toggleAcc,
    setSamlAccessUserType,
  } = useContext(UserRegistrationContext);

  // const [carrierOptions, setCarrierOptions] = useState([]);
  // const [typeOfUserList, setTypeOfUserList] = useState([]);
  const [companyNameList, setCompanyNameList] = useState([]);
  const [addressList, setAddressList] = useState(
    userData.companyAddressList || []
  );

  const getCompanyDetails = (changedItem, changedField) => {
    let carrierId = "";
    let userType = "";
    let carrier = getValues("accessReqCarrierName");
    const typeOfUser = getValues("typeofUser");
    setValue("companyName", null);
    setValue("companyAddress", null);
    setAddressList([]);
    setCompanyNameList([]);
    if (changedField === "carrier") {
      carrierId = changedItem ? changedItem.value : "";
      userType = typeOfUser ? typeOfUser.label : "";
      carrier = changedItem;
    }
    if (changedField === "typeofUser") {
      carrierId = carrier ? carrier.value : "";
      userType = changedItem ? changedItem.label : "";
    }
    if (carrierId && userType) {
      const queryString = `service=autoSuggestController&params=%7BcompanyName:*,selcarrierId:${carrierId},seltypeofUser:${userType}`;
      setSamlAccessUserType(isSamlUserType(carrier, userType));
      companyDetails$.next({
        selcarrierId: carrierId,
        seltypeofUser: userType,
        queryString: queryString,
        carrier: carrier,
      });
    }
  };

  useObservableCallback(
    companyDetails$,
    response => {
      let data = response.response.length
        ? response.response.map(element => ({
            ...element,
            label: element.display,
            value: element.id,
          }))
        : [];

      if (response.userType.toLowerCase() === "airline") {
        setCompanyNameList([response.carrier]);
        setValue("companyName", response.carrier, { shouldValidate: true });
        companyAddressDetails$.next({
          orgId: response.carrier.value,
          isAirlineType: true,
        });
      }
      if (response.userType.toLowerCase() !== "airline") {
        if (data.length === 1 && data[0].id == 0) {
          setCompanyNameList([]);
          return;
        }
        setCompanyNameList(data);
      }
    },
    error => {
      console.error("Error in Company Details", error);
    }
  );

  useObservableCallback(
    companyAddressDetails$,
    response => {
      const data = response.response.length ? response.response : [];
      setAddressList(data);
      // if (!!editedBy) {
      //   const companyAddress = data.find(
      //     item => item.id == userData.companyAddressCmb
      //   );
      //   setValue("companyAddress", companyAddress, { shouldValidate: true });
      //   return;
      // }
      setValue("companyAddress", data[0], { shouldValidate: true });
    },
    error => {
      console.error("Error in Company Address", error);
    }
  );

  // useEffect(() => {
  //   const data = defaultOptions
  //     ? defaultOptions.carrierList.map(element => ({
  //         ...element,
  //         label: element.airlineName,
  //         value: element.id,
  //       }))
  //     : [];
  //   setCarrierOptions(data);
  //   if (data.length === 1) {
  //     setValue("accessReqCarrierName", data[0]);
  //   }
  //   if (!!editedBy && !isEmptyOrNull(userData)) {
  //     let params = {};
  //     const typeOfUser = userData.selTypeOfUser
  //       ? userData.selTypeOfUser
  //       : "airline";
  //     params.isAirlineType = typeOfUser.toLowerCase() === "airline";
  //     params.orgId = userData.selOrganizationId;
  //     companyAddressDetails$.next(params);
  //     if (props.carrierList.length > 1) {
  //       setValue(
  //         "accessReqCarrierName",
  //         props.carrierList.find(
  //           item => item.value === userData.accessReqCarrier
  //         )
  //       );
  //     }
  //   }
  // }, [UserRegistrationConsts.CARRIER_LIST_CONST]);

  useEffect(() => {
    // setTypeOfUserList(typesOfUser);
    if (!!editedBy) {
      const carrier = getValues("accessReqCarrierName");
      if (userData.selTypeOfUser) {
        const userType = props.typeOfUserList.find(
          item =>
            item.label.toLowerCase() === userData.selTypeOfUser.toLowerCase()
        );
        setSamlAccessUserType(isSamlUserType(carrier, userType.label));
        // setValue("typeofUser", userType);
        return;
      }
      setSamlAccessUserType(
        isSamlUserType(carrier, props.typeOfUserList[0].label)
      );
      setValue("typeofUser", props.typeOfUserList[0]);
    }
  }, [UserRegistrationConsts.TYPE_OF_USER_CONST]);

  return (
    <>
      <IFXAccordion
        title="Company Information"
        isOpen={
          toggleAcc == "initial" ? !editedBy || editedBy === "self" : toggleAcc
        }
      >
        <IFXFormLayout allFieldsColProps={{ xl: 3, lg: 3, md: 3, sm: 12 }}>
          <IFXFieldWrapper
            label="Carrier"
            controlId="accessReqCarrierName"
            name="accessReqCarrierName"
            type="dropdown"
            rules={[CoreValidationRuleCodes.required]}
            onChange={v => {
              getCompanyDetails(v, "carrier");
            }}
          >
            <IFXDropdown
              options={props.carrierList}
              disabled={(() => props.carrierList.length === 1 || !!editedBy)()}
            />
          </IFXFieldWrapper>
          <IFXFieldWrapper
            label="Type of User"
            controlId="typeofUser"
            name="typeofUser"
            type="dropdown"
            rules={[CoreValidationRuleCodes.required]}
            onChange={v => {
              getCompanyDetails(v, "typeofUser");
            }}
          >
            <IFXDropdown options={props.typeOfUserList} disabled={!!editedBy} />
          </IFXFieldWrapper>
          <IFXFieldWrapper
            label="Company Name"
            controlId="companyName"
            name="companyName"
            type="dropdown"
            rules={[CoreValidationRuleCodes.required]}
            onChange={v => {
              const typeOfUser = getValues("typeofUser");
              v &&
                companyAddressDetails$.next({
                  orgId: v.id,
                  isAirlineType:
                    typeOfUser && typeOfUser.label.toLowerCase() === "airline",
                });
            }}
          >
            <IFXDropdown
              options={companyNameList}
              disabled={(() => {
                const typeOfUser = getValues("typeofUser");
                return (
                  (typeOfUser &&
                    typeOfUser.label.toLowerCase() === "airline") ||
                  !!editedBy
                );
              })()}
            />
          </IFXFieldWrapper>
          <IFXFieldWrapper
            label="Company Address"
            controlId="companyAddress"
            name="companyAddress"
            type="dropdown"
            rules={[CoreValidationRuleCodes.required]}
          >
            <IFXDropdown options={addressList} />
          </IFXFieldWrapper>
        </IFXFormLayout>
      </IFXAccordion>
    </>
  );
};
