import { CUSTOM_FILTER_COMPARATOR } from "@ifx-react/ifx-react-core";

/* User Access Profiles Grid Column Definitions */
export const userAccessProfilesColDefs = ({
  serializeToLocaleDate,
  showCredentialSet,
  setEditAccessProfile,
  changeStatus,
  viewTask,
  editedBy,
}) => [
  {
    headerName: "",
    field: "componentsIconControl",
    checkboxSelection: true,
    filter: false,
    editable: false,
    hide: editedBy === "admin" ? false : true,
    sortable: false,
    suppressMenu: true,
    pinned: true,
    headerCheckboxSelection: true,
    cellRenderer: "IconCol",
    cellRendererParams: {
      isSelectableKey: "isSelectable",
    },
    minWidth: 30,
    width: 30,
  },
  {
    headerName: "Access Profile Name",
    headerTooltip: "Access Profile Name",
    field: "name",
    minWidth: 190,
  },
  {
    headerName: "Access Profile Description",
    headerTooltip: "Access Profile Description",
    field: "description",
    minWidth: 230,
  },
  {
    headerName: "Eff Date",
    headerTooltip: "Eff Date",
    field: "effDateLong",
    minWidth: 130,
    valueFormatter(params) {
      return serializeToLocaleDate(params?.data?.effDate);
    },
    filter: "agDateColumnFilter",
    filterParams: {
      valueGetter: function(params) {
        return params?.data?.effDate;
      },
      resetButton: true,
      comparator: CUSTOM_FILTER_COMPARATOR.dateInMillisFilter,
    },
  },
  {
    headerName: "End Date",
    headerTooltip: "End Date",
    field: "expDateLong",
    minWidth: 130,
    valueFormatter(params) {
      return serializeToLocaleDate(params?.data?.expDate);
    },
    filter: "agDateColumnFilter",
    filterParams: {
      valueGetter: function(params) {
        return params?.data?.expDate;
      },
      resetButton: true,
      comparator: CUSTOM_FILTER_COMPARATOR.dateInMillisFilter,
    },
  },
  {
    headerName: "Status",
    headerTooltip: "Status",
    field: "status",
    minWidth: 100,
  },
  {
    headerName: "Credentials",
    headerTooltip: "Credentials",
    field: "credentialSets",
    minWidth: 200,
  },
  {
    headerName: "Actions",
    headerTooltip: "Actions",
    field: "action",
    minWidth: 125,
    filter: false,
    sortable: false,
    pinned: "right",
    lockPinned: true,
    lockVisible: true,
    cellRenderer: "ActionCol",
    cellRendererParams: {
      actions: [
        {
          icon: "unlock",
          name: "Deactivate",
          includeCondition: data =>
            editedBy === "admin" && data.status === "Active",
          onClick: (e, data) => {
            changeStatus(data, "activate");
          },
        },
        {
          icon: "lock",
          name: "Activate",
          includeCondition: data =>
            editedBy === "admin" && data.status !== "Active",
          onClick: (e, data) => {
            changeStatus(data, "deactivate");
          },
        },
        {
          icon: "calendar",
          name: "Edit Effective Dates",
          includeCondition: () => editedBy === "admin",
          onClick: (e, data) => {
            setEditAccessProfile(data);
          },
        },
        {
          type: "edit",
          name: "Edit Credential",
          includeCondition: () => editedBy === "admin",
          onClick: (e, data) => {
            showCredentialSet(data);
          },
        },
        {
          type: "visibility",
          name: "View Tasks",
          onClick: (e, data) => {
            viewTask(data.id);
          },
        },
      ],
    },
  },
];
