import React, {
  useRef,
  useEffect,
} from "react";
import { IFXRadioButton } from "../../..";
import { omit } from "../../../../../..";

export const IFXRadioCol = (props) => {
 
  const { onChange, data } = props;

  const _onChange = val => {
    onChange(val, data);
  };

  const _props = omit(props, [
    "valueFormatted",
    "eParentOfValue",
    "getValue",
    "setValue",
    "refreshCell",
    "addRowCompListener",
    "addRenderedRowListener",
    "keyPress",
    "charPress",
    "colDef",
    "rowIndex",
    "columnApi",
    "stopEditing",
    "eGridCell",
    "parseValue",
    "formatValue",
    "agGridReact",
    "frameworkComponentWrapper",
    "reactContainer",
    "cellStartedEdit",
    "column",
    "node",
    "data",
    "api",
  ]);

  const id = `${props.colDef.field.replace(/\./g, "_")}_${props.rowIndex}`;
  return (
    <IFXRadioButton
      label=""
      name={props.colDef.field}
      {..._props}
      id={id}
      onChange={_onChange}
    />
  );
};
