import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import cx from "classnames";
import { IFXModalBody } from "./IFXModalBody";
import { IFXModalHeader, IFXModalCloseIcon } from "./IFXModalHeader";
import { IFXModalFooter, IFXModalAlertFooter } from "./IFXModalFooter";
import ToastContext from "../IFXToast/context";

export const IFXModalContext = React.createContext();

export const IFXModal = props => {
  const _props = { backdrop: "static", ...props };
  const { isToastActive = false } = useContext(ToastContext) || {
    isToastActive: false,
  };

  return (
    <IFXModalContext.Provider value={{ onHide: props.onHide }}>
      <Modal
        {..._props}
        keyboard={!isToastActive}
        dialogClassName={cx("IFXModalDialog", props.dialogClassName)}
        className={cx("IFXModal", props.className)}
      >
        {props.children}
      </Modal>
    </IFXModalContext.Provider>
  );
};

export {
  IFXModalHeader,
  IFXModalBody,
  IFXModalFooter,
  IFXModalAlertFooter,
  IFXModalCloseIcon,
};
