import initialState from "./initialState";

export function SearchRemittanceReducer(
  state = initialState.SearchRemittanceInfo,
  action
) {
  switch (action.type) {
    case "SEARCH_REMITTANCE_SELECTION_REQUEST": {
      return {
        ...state,
        searchRemittanceValues: action.payload,
        isSearchTriggered: true,
      };
    }
    default:
      return state;
  }
}
