import { useContext } from "react";
import { LayoutContext } from "../..";

export const useContentHeight = ({ delta = 130, minHeight = 200 } = {}) => {
  const layoutContext = useContext(LayoutContext);
  const contentHeight =
    layoutContext.contentHeight -
    (typeof delta === "function" ? 70 + delta() : delta);
  if (contentHeight < minHeight) return minHeight;
  return contentHeight;
};
