import React, {memo} from "react";
import { IFXIcons } from "../../..";

const DEFAULT_PROPS = {
  iconHeight: 10,
  iconWidth: 12,
  offSetPos: 3.5,
  horizontalSpacing: 15,
  lineStyle: { strokeDasharray: "3,3" },
  enableTreeConnector: true,
  iconPropsCollapsed: { icon: "angle-right" },
  iconPropsExpanded: { icon: "angle-down" },
};

export const TreeControlCol = memo(props => {
  const _props = { ...DEFAULT_PROPS, ...props };
  //console.log("_props", _props);
  const {
    node,
    api,
    node: { level, expanded, rowHeight, lastChild, childrenAfterFilter },
    iconHeight,
    iconWidth,
    offSetPos,
    horizontalSpacing,
    lineStyle,
    enableTreeConnector,
    iconPropsCollapsed,
    iconPropsExpanded,
    colDef: { field = "open" },
  } = _props;

  const _rowHeightNoBorder = rowHeight - 2;

  const hasChildren = childrenAfterFilter && childrenAfterFilter.length > 0;
  const onActionClick = () => {
    if (hasChildren) {
      node.setExpanded(!expanded);
      //console.log("node", node);
      node.data[field] = !expanded;
      api.onGroupExpandedOrCollapsed();
    }
  };

  const iconProps = expanded ? iconPropsExpanded : iconPropsCollapsed;

  const hirearchy = (() => {
    let returnArray = [node];
    let parentNode = node.parent;
    while (parentNode && parentNode !== null) {
      if (parentNode.level >= 0) {
        returnArray.push(parentNode);
        parentNode = parentNode.parent;
      } else {
        break;
      }
    }
    return returnArray;
  })();

  return (
    <>
      <div
        className="position-relative"
        style={{ cursor: "pointer" }}
        onClick={onActionClick}
      >
        {enableTreeConnector ? (
          <>
            <div
              className="tree-connector"
              style={{
                height: `${rowHeight}px`,
              }}
            >
              <svg width="100%" height="100%">
                {expanded &&
                  hasChildren &&
                  //bottom Vertical line
                  (level === 0 ? (
                    <line
                      {...lineStyle}
                      x1={offSetPos}
                      x2={offSetPos}
                      y1={_rowHeightNoBorder / 2 + iconHeight / 2}
                      y2={_rowHeightNoBorder}
                    ></line>
                  ) : (
                    <line
                      {...lineStyle}
                      x1={offSetPos + level * horizontalSpacing}
                      x2={offSetPos + level * horizontalSpacing}
                      y1={_rowHeightNoBorder / 2 + iconHeight / 2}
                      y2={_rowHeightNoBorder}
                    ></line>
                  ))}

                {hirearchy.map((e, i) => {
                  //horizontal line
                  if (i === 0 && level > 0) {
                    return (
                      <g key={i}>
                        {hasChildren ? (
                          <line
                            {...lineStyle}
                            x1={offSetPos + (level - 1) * horizontalSpacing}
                            x2={
                              offSetPos +
                              level * horizontalSpacing -
                              iconWidth / 2
                            }
                            y1={_rowHeightNoBorder / 2}
                            y2={_rowHeightNoBorder / 2}
                          ></line>
                        ) : (
                          <line
                            {...lineStyle}
                            x1={offSetPos + (level - 1) * horizontalSpacing}
                            x2="100%"
                            y1={_rowHeightNoBorder / 2}
                            y2={_rowHeightNoBorder / 2}
                          ></line>
                        )}
                      </g>
                    );
                  }
                  return null;
                })}
                {hirearchy.map((e, i) => {
                  //Vertical line

                  return (
                    <g key={i}>
                      {!e.lastChild && (
                        <line
                          {...lineStyle}
                          x1={
                            offSetPos +
                            e.level * horizontalSpacing -
                            horizontalSpacing
                          }
                          x2={
                            offSetPos +
                            e.level * horizontalSpacing -
                            horizontalSpacing
                          }
                          y1="0"
                          y2={
                            e.lastChild
                              ? _rowHeightNoBorder / 2
                              : _rowHeightNoBorder
                          }
                        ></line>
                      )}
                    </g>
                  );
                })}

                {lastChild && (
                  <line
                    {...lineStyle}
                    x1={
                      offSetPos + level * horizontalSpacing - horizontalSpacing
                    }
                    x2={
                      offSetPos + level * horizontalSpacing - horizontalSpacing
                    }
                    y1="0"
                    y2={lastChild ? _rowHeightNoBorder / 2 : _rowHeightNoBorder}
                  ></line>
                )}
              </svg>
            </div>

            {hasChildren && (
              <div
                className="tree-icon"
                style={{
                  left: level > 0 ? `${level * horizontalSpacing}px` : "0px",
                  top: `-${rowHeight}px`,
                }}
              >
                <IFXIcons {...iconProps} />
              </div>
            )}
          </>
        ) : (
          <>
            {hasChildren && (
              <div
                className="tree-icon"
                style={{
                  left: level > 0 ? `${level * horizontalSpacing}px` : "0px",
                }}
              >
                <IFXIcons {...iconProps} />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
});
