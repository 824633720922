import React, { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";

import { IFXIcons } from "@ifx-react/ifx-react-core";
import { AirlineSwitchActions } from "../../../store/actions/portal/actionCreators";
import { useRef } from "react";
import { Nav, Button, Dropdown } from "react-bootstrap";

export const HeaderAirlineLogo = React.memo(() => {
  const dispatch = useDispatch();
  const selectedAirline = useSelector(
    state => state.PortalReducer.UserContextReducer.selectedAirline
  );
  const carrierList = useSelector(
    state => state.PortalReducer.UserContextReducer.userInfo.carrierList
  );

  const dropDownRef = useRef();

  const handleAirlineSwitch = useCallback(
    item => {
      //console.log(item);
      //console.log(dropDownRef);
      if (selectedAirline.iataCode !== item.iataCode) {
        dispatch(AirlineSwitchActions.REQUEST(item));
        //dropDownRef.current.toggle();
      }
    },
    [selectedAirline]
  );

  const items = useMemo(
    () =>
      carrierList.map(item => (
        <Nav.Item
          key={item.iataCode}
          className="airline-logo-dropdown-item-wrapper"
        >
          <Button
            variant="outline-light"
            onClick={() => handleAirlineSwitch(item)}
          >
            <div>
              {item.isSelected ? (
                <div className="airline-active">
                  <span className="my-auto">
                    <IFXIcons className="ml-2" icon="check-circle" />
                  </span>
                </div>
              ) : null}
              <div
                className={cx(
                  "my-auto airline-logo-dropdown-item airline-logo-" +
                    item.iataCode,
                  {
                    "airline-grey": !item.isSelected,
                  }
                )}
              ></div>
            </div>
          </Button>
        </Nav.Item>
      )),
    [carrierList]
  );
  return (
    <div className="header-btn-lg header-btn-first px-0">
      <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <div className="widget-content-left">
            <div className="header-airline-wrapper">
              <Dropdown ref={dropDownRef}>
                <Dropdown.Toggle
                  variant="link"
                  className="p-0"
                  bsPrefix={"ifx-dropdown-menu"}
                >
                  {/* <img className="header-airline-logo-img airline-logo-blank" /> */}
                  <div
                    className={cx(
                      "header-airline-logo-img airline-logo-" +
                        selectedAirline?.iataCode || "none"
                    )}
                  />
                  <IFXIcons
                    className="ml-2 opacity-8 align-middle"
                    icon="angle-down"
                    size="xs"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  alignRight
                  popperConfig={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 0],
                        },
                      },
                    ],
                  }}
                  className="rm-pointers dropdown-menu-sm header-dropdown-menu"
                >
                  <div className="header-dropdown-menu-shadow" />
                  <Nav className="px-2 flex-column">
                    <Nav.Item className="header-dropdown-menu-text">
                      Select a different logo to change the company view
                    </Nav.Item>
                    {items}
                  </Nav>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
