export const LOGIN_URL = "/ifx/j_acegi_security_check"; //For demo server
//export const LOGIN_URL = "http://localhost:3002/api/login"; //For node server
export const LOGOUT_URL = "/ifx/logout.do";
export const VERIFY_SESSION_URL =
  "/ifx/verifyUserSession.do?requestType=polling";
export const EXTEND_SESSION_URL = "/ifx/verifyUserSession.do";
export const USER_INFO_URL = "/ifx/api/portal/security/userAccess/info.do";
export const LEGAL_NOTICE_URL = "/ifx/api/portal/user/getLegalNotice.do";
export const AIRLINE_SWITCH_URL = "/ifx/changeCarrier.do";
export const SEARCH_AIRCRAFT_TYPE =
  "/ifx/api/portal/masterData/airCraftTypes/searchAircraftTypes.do";
export const UPDATE_AIRCRAFT_TYPE =
  "/ifx/api/portal/masterData/airCraftTypes/addOrUpdateAircraftType.do";
export const CHANGE_NOTICE_VIEW = "/ifx/adm/changenoticeview.do?cnId";
export const CHANGE_NOTICE_DD_LIST = "/ifx/api/portal/changenotice/home.do";
export const CHANGE_NOTICE_DELETE = "/ifx/api/portal/changenotice/delete.do";
export const CHANGE_NOTICE_VIEW_DATA = "/ifx/api/portal/changenotice/edit.do";
export const CHANGE_NOTICE_DATA_GRID = "/ifx/api/portal/changenotice/search.do";
export const CHANGE_NOTICE_SAVE_NEW_DATA =
  "/ifx/api/portal/changenotice/savecreate.do";
export const CHANGE_NOTICE_SAVE_EXIST_DATA =
  "/ifx/api/portal/changenotice/savenotice.do";
export const CHANGE_NOTICE_DOWNLOAD_PDF =
  "/ifx/api/portal/changenotice/download/pdf.do";
export const SEARCH_USER_DATA_GRID = "/ifx/api/portal/useradmin/search.do";
export const USER_REG_DEFAULT_VALUES = "/ifx/api/portal/user/getInfo.do";
export const SAVE_REGISTRAION_VALUES_URL = "/ifx/api/portal/user/create.do";
export const DOC_MGMT_BROWSE_DATA_GRID =
  "/ifx/api/portal/document/browse.do?id=";
export const DOC_MGMT_SEARCH_DATA_GRID =
  "/ifx/api/portal/document/searchdoc.do";
export const DOC_MGMT_NAVIGATE_DATA_GRID =
  "/ifx/api/portal/document/navigatefolder.do";
export const DOC_MGMT_DOWNLOAD_DOC =
  "/ifx/api/portal/document/getdocument.do?docId=";
export const DOC_MGMT_DELETE_RECORD = "/ifx/api/portal/document/delete.do";
export const DOC_MGMT_DOC_VERSION_DATA_GRID =
  "/ifx/api/portal/document/documentversion.do?id=";
export const DOC_MGMT_CREATE_FOLDER =
  "/ifx/api/portal/document/createfolder.do";
export const DOC_MGMT_EDIT_FOLDER = "/ifx/api/portal/document/editfolder.do";
export const DOC_MGMT_CREATE_DOCUMENT = "/ifx/api/portal/document/createdoc.do";
export const DOC_MGMT_EDIT_DOCUMENT =
  "/ifx/api/portal/document/editdocument.do";
export const DOC_MGMT_CREATE_DOC_VERSION =
  "/ifx/api/portal/document/uploaddocversion.do";
export const DOC_MGMT_EDIT_DOC_VERSION =
  "/ifx/api/portal/document/editdocumentversion.do";
export const DOC_MGMT_BASEURL = "/app/portal/docMgmt?foldId=";
export const GET_RECENT_CHANGE_NOTICE =
  "/ifx/api/portal/landingPage/getRecentChangeNotices.do";
export const GET_FAVORITES_USER_LINKS =
  "/ifx/api/portal/landingPage/getFavorites.do";
export const LOAD_ALERTS =
  "/ifx/api/portal/landingPage/loadAlertNotifications.do";
export const DEACTIVATE_USER = "/ifx/api/portal/useradmin/deactivate.do";
export const ACTIVATE_USER = "/ifx/api/portal/useradmin/activate.do";
export const REJECT_USER = "/ifx/api/portal/useradmin/reject.do";
export const APPROVE_USER = "/ifx/api/portal/useradmin/approve.do";
export const GET_USER_INFO = "/ifx/api/portal/useradmin/getUserInfo.do";
export const DELETE_ACCESS_PROFILE_URL =
  "/ifx/api/portal/user/profile/delete.do";
export const GET_ACCESS_PROFILES_URL =
  "/ifx/api/portal/useradmin/getAccessProfile.do";
export const CLEAR_ACCESS_PROFILE_REQUEST_URL =
  "/ifx/api/portal/useradmin/clearAccessProfile.do";
export const CREATE_ACCESS_PROFILE_URL =
  "/ifx/api/portal/user/profile/create.do";
export const CREATE_CREDENTIAL_SET =
  "/ifx/api/portal/user/profile/credential/create.do";
export const DELETE_CREDENTIAL_SET_URL =
  "/ifx/api/portal/user/profile//credential/delete.do";
export const EDIT_ACCESS_PROFILE_URL = "/ifx/api/portal/user/profile/update.do";
export const EDIT_CREDENTIAL_SET_URL =
  "/ifx/api/portal/user/profile/credential/update.do";
export const ACTIVATE_ACCESS_PROFILE_URL =
  "/ifx/api/portal/user/profile/activate.do";
export const DEACTIVATE_ACCESS_PROFILE_URL =
  "/ifx/api/portal/user/profile/deactivate.do";
export const RESET_PASSWORD_URL =
  "/ifx/api/portal/user/profile/resetPassword.do";
export const VIEW_TASK_URL = "/ifx/api/portal/user/profile/viewTasks.do";
export const VERIFY_ACCOUNT_URL = "/ifx/api/portal/user/activateAccount.do";
export const GET_SECRET_QUESTION_URL = "/ifx/api/portal/user/forgotPassword.do";
export const VALIDATE_SECRET_DATA_URL =
  "/ifx/api/portal/user/validateSecretData.do";
export const PASSWORD_EXPIRATION_URL =
  "/ifx/api/portal/useradmin/passwordExpiration.do";
export const PASSWORD_LIMIT_URL = "/ifx/api/portal/useradmin/passwordLimit.do";
export const GP_SSO_TOKEN = "/ifx/api/portal/ssotoken.do";
export const ACCEPT_EULA_URL = "/ifx/acceptEula.do";
export const DOC_MGMT_GET_FOLDER_PROPS =
  "/ifx/api/portal/document/folderproperties.do";
export const DOC_MGMT_GET_DOC_PROPS =
  "/ifx/api/portal/document/documentproperties.do";
export const GET_AC_SEAT_CONFIG =
  "/ifx/api//portal/masterData/airCraftTypes/searchACSeatConfig.do";
export const AC_SEAT_CONFIG_CURD_URL =
  "/ifx/api/portal/masterData/airCraftTypes/addOrUpdateOrDeleteACSeatCfg.do";

export const SEARCH_AC_REG_URL =
  "/ifx/api/portal/masterData/airCraftTypes/searchACRegistration.do";
export const ADD_UPDATE_DEL_AC_REG_URL =
  "/ifx/api/portal/masterData/airCraftTypes/addOrUpdateOrDeleteACRegistrations.do";
export const GET_SEARCH_CATERER_STATION_DETAILS =
  "/ifx/api/portal/masterData/managestation/caterer/search.do";
export const SAVE_CATERER_STATION =
  "/ifx/api/portal/masterData/managestation/caterer/addUpdateDelete.do";
export const SEARCH_CARRIER_STATION =
  "/ifx/api/portal/masterData/managestation/carrier/search.do";
export const SAVE_DELETE_CATERER_STATION =
  "/ifx/api/portal/masterData/managestation/caterer/addUpdateDelete.do";
export const ADD_UPDATE_DELETE_CARRIER_STATION =
  "/ifx/api/portal/masterData/managestation/carrier/addUpdateDelete.do";
export const DOWNLOAD_CARRIER_STATION =
  "/ifx/api/portal/masterData/managestation/caterer/download.do";
export const DOWNLOD_CARRIER_STATIONS =
  "/ifx/api/portal/masterData/managestation/carrier/download.do";

export const SEARCH_CATERER =
  "/ifx/api/portal/masterData/caterer/searchCaterer.do";
export const ADD_UPDATE_CATERER =
  "/ifx/api/portal/masterData/caterer/addUpdateCaterer.do";
export const DOWNLOAD_CATERER =
  "/ifx/api/portal/masterData/caterer/downloadCaterers.do";
export const DELETE_CATERER =
  "/ifx/api/portal/masterData/caterer/deleteCaterers.do";
export const GET_CARRIER_PROPS =
  "/ifx/api/portal/masterData/caterer/getPropertiesforCarrier.do";
export const GET_FAVORITES = "/ifx/api/portal/favorites/getfavorites.do";
export const ADD_UPDATE_FAVORITE =
  "/ifx/api/portal/favorites/addeditfavorite.do";
export const DELETE_FAVORITE = "/ifx/api/portal/favorites/deletefavorite.do";

export const APPROVE_CREDENTIALSET_URL =
  "/ifx/api/portal/useradmin/approveCredentialSet.do";
export const REJECT_CREDENTIALSET_URL =
  "/ifx/api/portal/useradmin/rejectCredentialSet.do";

export const PASSWORD_EXPIRATION_CHECK_URL =
  "/ifx/api/portal/useradmin/passwordExpirationCheck.do";

export const PASSWORD_UPDATE_URL =
  "/ifx/api/portal/useradmin/updatePassword.do";

export const SEARCH_SPMLCRWML =
  "/ifx/api/portal/masterData/spmlcrwml/search.do";

export const ADDUPDATEDELETE_SPMLCRWML =
  "/ifx/api/portal/masterData/spmlcrwml/addOrUpdateDeleteSPML.do";

export const DOWNLOAD_SPMLCRWML =
  "/ifx/api/portal/masterData/spmlcrwml/downloadSPMLCRWML.do";
