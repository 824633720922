import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  memo,
} from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Row, Col, Container, Dropdown, Nav } from "react-bootstrap";

import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules, Utils } from "ag-grid-community";
import {
  omit,
  lodashUtils,
  textTruncate,
  isEmptyOrNull,
  moment,
} from "../../../utils";
import {
  IFXTextBox,
  IFXCheckbox,
  IFXDropdown,
  IFXFieldWrapper,
  IFXTab,
  IFXTooltip,
  IFXUserNote,
} from "..";
import {
  IFXTextBoxCol,
  IFXAutoSuggestCol,
  IFXDatePickerCol,
  IFXTimePickerCol,
  IFXDropdownCol,
  IFXCheckboxCol,
  IFXRadioCol,
  IFXNumberBoxCol,
  IFXValidationTextBoxCol,
} from "./editors";
import {
  EditableFacade,
  EditableAutosuggestFacade,
  EditableDropDownFacade,
  ActionCol,
  EditableCheckboxFacade,
  IconCol,
  LoadingRenderer,
  LinkRenderer,
  HeaderSelectAll,
  HeaderFilterPin,
  LabelButtonRenderer,
  LinkAndButtonRenderer,
  TreeControlCol,
  ButtonAsLinkRenderer,
  LabelIconRenderer,
  IFXRadioRowSelectCol,
  TreeControlHeader,
  TestRenderer,
  TextRendererWithTooltips,
} from "./renderers";
import { Subject } from "rxjs";
import { debounceTime, skipWhile } from "rxjs/operators";
import { useLocalStorage, useObservableCallback } from "../../../hooks";
import { compareAsc, format, parse } from "date-fns";
import { IFXGridSecondaryIconButton } from "../IFXButton";
import { IFXDataTableTooltip } from "./IFXDataTableTooltip";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";

const getUpdateDisplayedRowsObv$ = () => new Subject().pipe(debounceTime(100));

const getForceRefreshDebounceObv$ = () => new Subject().pipe(debounceTime(250));

const getSaveStateDebounceObv$ = date =>
  new Subject().pipe(
    skipWhile(() => moment().isBefore(moment(date).add(5, "seconds"))), //skip auto save first 5 seconds
    debounceTime(1000)
  );

export const DT_MODEL_TYPE = {
  clientSide: "clientSide",
  infinite: "infinite",
};

const pageSizeList = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "30", value: 30 },
];

export const DEFAULT_TABLE_CONTROL_ACTION_CONFIG = {};

export const createNewRowData = (gridRef, count, newRowData) => {
  let _newItems = [];
  const { api } = gridRef?.current || gridRef || {};
  //console.log("api.getDisplayedRowCount()", api.getDisplayedRowCount());
  let currentCount = api?.getDisplayedRowCount() || 0;
  //newRowData["new_row_id"] = "new_" + gridRef.current.api.getDisplayedRowCount() + 1;
  if (count <= 1) {
    if (Array.isArray(newRowData)) {
      _newItems = lodashUtils.cloneDeep(newRowData);
    } else {
      newRowData["new_row_id"] = "new_" + ++currentCount;
      newRowData["row_id"] = currentCount - 1;
      _newItems = [lodashUtils.cloneDeep(newRowData)];
    }
  } else {
    for (let i = 0; i < count; i++) {
      newRowData["new_row_id"] = "new_" + ++currentCount;
      newRowData["row_id"] = currentCount - 1;
      _newItems.push(lodashUtils.cloneDeep(newRowData));
    }
  }

  return _newItems;
};

const flattenRowDataReducer = (out, data) => {
  out.push(data);
  if (data.children && data.children.length > 0) {
    data.children.reduce(flattenRowDataReducer, out);
  }
  return out;
};

export const flattenRowData = rowData => {
  return rowData.reduce(flattenRowDataReducer, []);
};

export const getDataById = (flatRowData, id) => {
  return flatRowData.find(data => data["new_row_id"] === id || data.id === id);
};

export const DT_DEFAULT_CONTROL_ACTIONS = {
  add: {
    type: "add",
    params: { index: -1, newRowData: {} },
    onClick: (e, gridRef, { count, index = -1, newRowData = {} }) => {
      // console.log(gridRef);
      let _newItems = createNewRowData(gridRef, count, newRowData);
      if (index === -1) {
        gridRef.current.api.updateRowData({
          add: _newItems,
        });
      } else {
        gridRef.current.api.updateRowData({
          add: _newItems,
          addIndex: index,
        });
      }

      gridRef.current.api.ensureIndexVisible(
        gridRef.current.api.getDisplayedRowCount() - 1,
        "top"
      );
    },
  },
  clear: {
    type: "clear",
    onClick: (e, gridRef, params) => {
      console.log("clear:", gridRef);
    },
  },
  reload: {
    type: "reload",
    /* onClick: (e, gridRef, params) => {
      console.log("reload");
    }, */
  },
  reset: {
    type: "reset",
    name: "Reset to Default",
    onClick: (e, { resetGrid }, params) => {
      //console.log("reset:",gridRef);
      resetGrid();
    },
  },
  clearfilter: {
    type: "clearfilter",
    onClick: (e, { clearFilter }, params) => {
      clearFilter();
    },
  },
  export: {
    type: "export-csv",
    onClick: (e, gridRef, params) => {
      console.log("export csv");
      //params.columnKeys = ["country", "bronze"];
      gridRef.current.api.exportDataAsCsv({
        //suppressQuotes: "",
        //columnSeparator: "",
        //customHeader: "",
        customFooter: "",
      });
    },
  },
  save: {
    type: "save",
    onClick: (e, gridRef, params) => {
      console.log("save");
      const { api } = gridRef.current;
      api.forEachNode((node, index) => {
        console.log(`node`, node);
        console.log(`node data with id: ${node.id} =`, node.data);
      });
    },
  },
  delete: {
    type: "delete",
    onClick: (e, gridRef, params) => {
      console.log("delete");
    },
  },
  print: {
    type: "print",
    onClick: (e, gridRef, params) => {
      console.log("print");
    },
  },
};

export const QUICK_FILTER_FUNCS = {
  IFXAutoSuggestCol: function({ value: fieldValue = {} }) {
    return fieldValue !== null ? fieldValue.value : null;
  },
  IFXDropdownCol: function({ value: fieldValue = {} }) {
    return fieldValue !== null ? fieldValue.label : null;
  },
};

export const CUSTOM_FILTER_COMPARATOR = {
  dateInMillisFilter: (filterLocalDateAtMidnight, cellValue) => {
    let cellDate = new Date(cellValue);
    cellDate = parse(format(cellDate, "MM/dd/yyyy"), "MM/dd/yyyy", new Date());

    //console.log("cellDate", cellDate, filterLocalDateAtMidnight);

    return compareAsc(
      cellDate ? cellDate : null,
      filterLocalDateAtMidnight ? filterLocalDateAtMidnight : null
    );
    //console.log("result", result);
    //return result;
  },
};

export const CUSTOM_SORT_COMPARATOR = {
  caseInsensitiveSort: (valueA, valueB) => {
    //console.log("valueA", valueA, "valueB", valueB);
    const _valueA =
      (valueA && typeof valueA === "string" && valueA.toLowerCase()) || valueA;
    const _valueB =
      (valueB && typeof valueB === "string" && valueB.toLowerCase()) || valueB;

    return Utils.defaultComparator(_valueA, _valueB);
  },
  IFXDatePickerCol: (valueA, valueB, nodeA, nodeB, isInverted) => {
    //console.log("CUSTOM_SORT_COMPARATOR:valueA:", valueA);
    //console.log("CUSTOM_SORT_COMPARATOR:valueB:", valueB);
    return Utils.defaultComparator(
      valueA ? valueA.dateValue : null,
      valueB ? valueB.dateValue : null
    );
  },
};

export const checkRowEditable = ({ data = {} }) => {
  const { editable = true } = data;
  return editable;
};

export const getRowNodeId = d => {
  return d["new_row_id"] ? d["new_row_id"] : d.id; // return the property you want set as the id.
};

export const DEFAULT_GRID_OPTS = {
  tooltipShowDelay: 0,
  tooltipMouseTrack: true,
  animateRows: true,
  localeText: { noRowsToShow: "No Records Found" },
  singleClickEdit: true,
  //enableBrowserTooltips: true,
  //getRowNodeId,
  /* getRowStyle: function(params) {
    if (params.node.rowPinned) {
      return { "font-weight": "bold", "background-color": "#eff5f9" };
    }
  }, */
  getNodeChildDetails: data => {
    if (data.children && data.children.length > 0) {
      return {
        group: true,
        children: data.children,
        expanded: data.open,
      };
    } else {
      return null;
    }
  },
  isRowSelectable: function(rowNode) {
    return rowNode.data && rowNode.data.isSelectable !== undefined
      ? rowNode.data.isSelectable
      : true;
  },
  defaultColDef: {
    flex: true,
    editable: checkRowEditable,
    sortable: true,
    resizable: true,
    filter: "agTextColumnFilter",
    headerComponent: "HeaderFilterPin",
    tooltipComponent: "IFXDataTableTooltip",
    filterParams: {
      resetButton: true,
    },
    lockVisible: false,
    suppressDragLeaveHidesColumns: true,
    tooltipValueGetter: function(params) {
      if (params.value && typeof params.value === "object") {
        return params.valueFormatted
          ? params.valueFormatted
          : params.value.label || params.value.value;
      } else if (typeof params.value === "boolean") {
        return "";
      } else {
        return params.valueFormatted ? params.valueFormatted : params.value;
      }
    },
    comparator: CUSTOM_SORT_COMPARATOR.caseInsensitiveSort,
    //headerComponentFramework: SortableHeaderComponent,
    /* headerComponentParams: {
      menuIcon: "fa-bars",
    }, */
  },
  frameworkComponents: {
    EditableFacade,
    EditableAutosuggestFacade,
    EditableDropDownFacade,
    EditableCheckboxFacade,
    IFXTextBoxCol,
    IFXAutoSuggestCol,
    IFXDatePickerCol,
    IFXTimePickerCol,
    IFXDropdownCol,
    IFXCheckboxCol,
    IFXRadioCol,
    IFXRadioRowSelectCol,
    ActionCol,
    IconCol,
    LoadingRenderer,
    LinkRenderer,
    TestRenderer,
    HeaderSelectAll,
    HeaderFilterPin,
    LabelButtonRenderer,
    LinkAndButtonRenderer,
    TreeControlCol,
    ButtonAsLinkRenderer,
    LabelIconRenderer,
    TreeControlHeader,
    IFXNumberBoxCol,
    IFXDataTableTooltip,
    TextRendererWithTooltips,
    IFXValidationTextBoxCol,
  },
};

const DEFAULT_PROPS = {
  defaultAddRows: 1,
  enableGroupEdit: true,
  //stopEditingWhenGridLosesFocus: true,
  rowSelection: "multiple",
  suppressRowClickSelection: true,
  rowBuffer: 0,
  maxConcurrentDatasourceRequests: 1,
  cacheBlockSize: 20, //200, // page fetch size
  infiniteInitialRowCount: 0,
  blockLoadDebounceMillis: 800,
  cacheOverflowSize: 1,
  maxBlocksInCache: 10,
  tableControlActions: [
    DT_DEFAULT_CONTROL_ACTIONS.add,
    DT_DEFAULT_CONTROL_ACTIONS.delete,
    DT_DEFAULT_CONTROL_ACTIONS.clear,
    DT_DEFAULT_CONTROL_ACTIONS.export,
    DT_DEFAULT_CONTROL_ACTIONS.reload,
    DT_DEFAULT_CONTROL_ACTIONS.save,
  ],
  //domLayout: "autoHeight",
  filter: true,
  modules: AllCommunityModules,
  fullWidthCellRenderer: "LoadingRenderer",
  isFullWidthCell: node => {
    return node.id === undefined;
  },
  tableOptionsProps: {
    enableOptionsButton: true,
    enableColumnFilter: true,
    enableColumnShowHide: true,
    enablePinnedColumn: false,
    enableSaveCurrentView: true,
  },
};

const DEFAULT_WRAPPER_PROPS = {
  className: "ifx-datatable ag-theme-balham",
  style: { height: 200 },
};
const HEADER_WRAPPED_CLASSNAME = "ifx-ag-headerWrapped";

const getAllNestedChildrenColumnFields = (children, fieldsArray) => {
  if (children && children.length > 0) {
    children.forEach(item => {
      fieldsArray.push(item.field);
      getAllNestedChildrenColumnFields(item.children, fieldsArray);
    });
  }
};

const ColumnShowHideOptionItem = memo(
  ({
    column,
    gridRef,
    gridId,
    index,
    parentIndex = "",
    level = 0,
    resetRandom,
  }) => {
    //console.log("ColumnShowHideOptionItem column", column);
    const {
      optionsDisplayName,
      headerName,
      hideVisible = false,
      lockVisible = false,
      hide = false,
      children,
      field,
    } = column;

    const [checked, setChecked] = useState(!hide);

    useEffect(() => {
      setChecked(!hide);
    }, [resetRandom]);

    const onChange = e => {
      const {
        api,
        api: { columnController },
      } = gridRef.current;

      if (children && children.length > 0) {
        //setColumnGroupOpened
        let fieldsArray = [];
        getAllNestedChildrenColumnFields(children, fieldsArray);
        //console.log("fieldsArray", fieldsArray);
        columnController.setColumnsVisible(fieldsArray, e.target.checked);
        setChecked(e.target.checked);
      } else {
        columnController.setColumnVisible(field, e.target.checked);
        setChecked(e.target.checked);
      }
      api.sizeColumnsToFit();
    };

    return (
      <>
        {!hideVisible && (!!optionsDisplayName || !!headerName) && field && (
          <Nav.Item className="ml-3">
            <IFXCheckbox
              id={`colVisChk_${parentIndex}_${index}${
                gridId ? "_" + gridId : ""
              }`}
              label={
                optionsDisplayName ||
                (headerName && headerName.length > 20 ? (
                  <IFXTooltip placement="bottom" content={headerName}>
                    <span>{textTruncate(headerName, 20)}</span>
                  </IFXTooltip>
                ) : (
                  headerName
                ))
              }
              onChange={onChange}
              checked={checked}
              disabled={lockVisible}
            />
          </Nav.Item>
        )}
        {checked && children && children.length > 0 && (
          <div
            className={
              (!hideVisible &&
                (!!optionsDisplayName || !!headerName) &&
                field &&
                "ml-3") ||
              ""
            }
          >
            {children.map((childCol, i) => (
              <ColumnShowHideOptionItem
                key={i}
                index={i}
                parentIndex={`${parentIndex}${index}`}
                column={childCol}
                gridId={gridId}
                gridRef={gridRef}
                level={level + 1}
                resetRandom={resetRandom}
              />
            ))}
          </div>
        )}
      </>
    );
  }
);

const useGridViews = (userId, gridId) => {
  const [gridView, setGridView] = useLocalStorage(
    `gridViews_${userId}_${gridId}`,
    null
  );

  return [gridView, setGridView];
};

const SaveCurrentView = memo(({ gridId, gridRef }) => {
  const { userId = 0 } = useSelector(
    state => state.PortalReducer?.UserContextReducer?.userInfo
  );
  const [gridView, setGridView] = useGridViews(userId, gridId);
  const saveStateDebounceObv$ = useMemo(
    () => getSaveStateDebounceObv$(new Date()),
    []
  );

  useObservableCallback(
    saveStateDebounceObv$,
    columnState => {
      //console.log("saveStateDebounceObv$ debounced save", columnState);
      !isEmptyOrNull(columnState) && setGridView(columnState);
    },
    undefined,
    []
  );

  useEffect(() => {
    if (gridRef?.current) {
      window.setTimeout(() => {
        try {
          const { columnApi } = gridRef.current;
          const currentColumnState = columnApi.getColumnState();
          //console.log("useEffect GridReady set saved state if different");
          gridView &&
            !lodashUtils.isEqual(currentColumnState, gridView) &&
            columnApi.setColumnState(gridView);
        } catch (e) {}
      }, 700);
    }
  }, []);

  const onColumnStateChanged = ({ columnApi }) => {
    //console.log("SaveCurrentView::onColumnStateChanged");
    const newColumnState = columnApi.getColumnState();
    /* console.log("SaveCurrentView::onColumnStateChanged::", {
      newColumnState,
      gridView,
    });
    console.log(
      "SaveCurrentView::onColumnStateChanged::isEqual::",
      lodashUtils.isEqual(newColumnState, gridView)
    ); */
    if (!lodashUtils.isEqual(newColumnState, gridView)) {
      //console.log("SaveCurrentView::onColumnStateChanged::next");
      saveStateDebounceObv$.next(newColumnState);
    }
  };

  useEffect(() => {
    if (gridRef && gridRef.current.api) {
      const { api } = gridRef.current;
      //columnResized
      api.addEventListener("displayedColumnsChanged", onColumnStateChanged);
      api.addEventListener("columnResized", onColumnStateChanged);

      return () => {
        api.removeEventListener(
          "displayedColumnsChanged",
          onColumnStateChanged
        );
        api.removeEventListener("columnResized", onColumnStateChanged);
      };
    }
  }, [gridRef, onColumnStateChanged]);

  return null;
});

const getColumnDefsFromAllColumns = ({ columnApi }) => {
  const allColumns = columnApi.getAllGridColumns() || [];
  //console.log(" getColumnDefsFromAllColumns allColumns", allColumns);

  let groupObjByLevel = {};
  let outCols = [];
  let lastGroupHeader = {}; //level with group header
  let colDef, originalParent, groupId, level, colGroupDef, lastColGroupDef;
  //let optionsDisplayName,headerName,hideVisible,lockVisible,hide,field;
  allColumns.forEach(column => {
    //console.log("getColumnDefsFromAllColumns column", column);
    colDef = {
      ...column.colDef,
      hide: !column.visible,
    };
    originalParent = column.originalParent;
    if (originalParent !== null) {
      groupId = originalParent.groupId;
      level = originalParent.level;
      while (level >= 0 && originalParent !== null) {
        // console.log(
        //   `getColumnDefsFromAllColumns originalParent level: ${level}`,
        //   originalParent
        // );
        let {
          optionsDisplayName,
          headerName,
          hideVisible,
          lockVisible,
          hide,
          field,
        } = originalParent.colGroupDef;

        colGroupDef = {
          optionsDisplayName,
          headerName,
          hideVisible,
          lockVisible,
          hide,
          field,
        };

        if (
          !isEmptyOrNull(originalParent.colGroupDef) &&
          !!originalParent.colGroupDef.field
        ) {
          let {
            optionsDisplayName,
            headerName,
            hideVisible,
            lockVisible,
            hide,
            field,
            children = [],
          } = lastGroupHeader[level] || {};

          lastColGroupDef =
            (!isEmptyOrNull(lastGroupHeader[level]) && {
              optionsDisplayName,
              headerName,
              hideVisible,
              lockVisible,
              hide,
              field,
            }) ||
            null;

          // console.log(`getColumnDefsFromAllColumns isEqual:`, {
          //   colGroupDef,
          //   lastColGroupDef,
          // });

          if (!lodashUtils.isEqual(colGroupDef, lastColGroupDef)) {
            lastColGroupDef = lastGroupHeader[level] = {
              ...colGroupDef,
              children: [],
            };
            let groupArray = groupObjByLevel[level] || [];
            groupArray.push(lastColGroupDef);
            groupObjByLevel[level] = groupArray;
          } else {
            lastColGroupDef = {
              optionsDisplayName,
              headerName,
              hideVisible,
              lockVisible,
              hide,
              field,
              children,
            };
          }
          let alreadyAdded = false;
          let _children = lastColGroupDef.children;
          if (_children.length > 0) {
            let lastItem = _children[_children.length - 1];
            alreadyAdded = lodashUtils.isEqual(
              {
                headerName: lastItem.headerName,
                field: lastItem.field,
                optionsDisplayName: lastItem.optionsDisplayName,
              },
              {
                headerName: colDef.headerName,
                field: colDef.field,
                optionsDisplayName: colDef.optionsDisplayName,
              }
            );
          }
          if (!alreadyAdded) {
            _children.push(colDef);
          }
          colDef = lastColGroupDef;
        }
        if (level === 0) {
          let alreadyAdded = false;
          if (outCols.length > 0) {
            let lastItem = outCols[outCols.length - 1];
            alreadyAdded = lodashUtils.isEqual(
              {
                headerName: lastItem.headerName,
                field: lastItem.field,
                optionsDisplayName: lastItem.optionsDisplayName,
              },
              {
                headerName: colDef.headerName,
                field: colDef.field,
                optionsDisplayName: colDef.optionsDisplayName,
              }
            );
          }

          if (!alreadyAdded) {
            outCols.push(colDef);
          }
        }

        originalParent = originalParent.originalParent;
        if (originalParent !== null) {
          level = originalParent.level;
          // console.log(
          //   `getColumnDefsFromAllColumns originalParent next level:${level}`,
          //   originalParent
          // );
        }
      }
    } else {
      outCols.push(colDef);
    }
  });
  //console.log("groupObjByLevel", groupObjByLevel);
  Object.keys(groupObjByLevel)
    .sort()
    .reverse()
    .forEach(level => {
      let gArray = groupObjByLevel[level];
      gArray.forEach(g => {
        let allHidden = true;
        if (g?.children?.length) {
          g.children.forEach(item => {
            if (item.hide !== true) allHidden = false;
          });
          g.hide = allHidden;
        }
      });
    });
  //console.log("groupObjByLevel", groupObjByLevel);
  //console.log("getColumnDefsFromAllColumns outCols:", outCols);
  return outCols;
};

const DatatableOptions = memo(
  ({
    gridId,
    gridRef,
    rowModelType,
    enableColumnFilter,
    enableColumnShowHide,
    enablePinnedColumn,
    maxHeight = 200,
    floatingFilterOnChange,
  }) => {
    const [checkBoxItems, setCheckBoxItems] = useState(null);
    //console.log("DatatableOptions gridRef:", gridRef);

    /* console.log(
      "DatatableOptions columnDefs original",
      gridRef?.current?.gridOptions?.columnDefs
    ); */

    useEffect(() => {
      const columnDefs = getColumnDefsFromAllColumns(gridRef.current);
      //console.log("DatatableOptions useEffect colDefs", columnDefs);
      if (columnDefs) {
        setCheckBoxItems(
          columnDefs.map((c, i) => {
            //console.log("show/hide c", c);
            return (
              <ColumnShowHideOptionItem
                key={i}
                index={i}
                column={c}
                gridId={gridId}
                gridRef={gridRef}
                resetRandom={null}
              />
            );
          })
        );
      }
    }, [gridId]);

    const onColumnEverythingChanged = e => {
      //console.log("DatatableOptions onColumnEverythingChanged", e);
      const columnDefs = getColumnDefsFromAllColumns(e);
      /*  const { columnApi } = e;
      const columnDefs = columnApi
        .getAllGridColumns()
        ?.map(column => ({ ...column.colDef, hide: !column.visible })); */
      //gridRef.current && gridRef.current.gridOptions.columnDefs;

      /* console.log(
        "DatatableOptions onColumnEverythingChanged columnDefs",
        columnDefs
      ); */

      const resetRandom = Math.random();
      setCheckBoxItems(
        columnDefs.map((c, i) => {
          //console.log("show/hide c", c);
          return (
            <ColumnShowHideOptionItem
              key={i}
              index={i}
              column={c}
              gridId={gridId}
              gridRef={gridRef}
              resetRandom={resetRandom}
            />
          );
        })
      );
    };

    useEffect(() => {
      if (gridRef && gridRef.current.api) {
        const { api } = gridRef.current;
        /*  api.addEventListener(
          "columnEverythingChanged",
          onColumnEverythingChanged
        ); */
        api.addEventListener(
          "displayedColumnsChanged",
          onColumnEverythingChanged
        );

        return () => {
          /*  api.removeEventListener(
            "columnEverythingChanged",
            onColumnEverythingChanged
          ); */
          api.removeEventListener(
            "displayedColumnsChanged",
            onColumnEverythingChanged
          );
        };
      }
    }, [gridRef]);

    return (
      <Dropdown className="d-inline-block datatable-options">
        <Dropdown.Toggle
          bsPrefix={"ifx-dropdown-menu ml-2"}
          type="options"
          name="Settings"
          as={IFXGridSecondaryIconButton}
        />

        <Dropdown.Menu
          alignRight
          flip
          popperConfig={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [3, 3],
                },
              },
            ],
          }}
          className="rm-pointers dropdown-menu-sm datatable-dropdown-menu"
        >
          <Nav className="px-2 flex-column">
            {enableColumnFilter && rowModelType !== DT_MODEL_TYPE.infinite ? (
              <Nav.Item>
                <IFXCheckbox
                  id={`filterVisible${gridId ? "_" + gridId : ""}`}
                  label="Show Column Filters"
                  defaultChecked={false}
                  onChange={e => {
                    //console.log(e.target.checked);
                    floatingFilterOnChange(e.target.checked);
                  }}
                />
              </Nav.Item>
            ) : null}
            {enableColumnShowHide || enablePinnedColumn ? (
              <>
                {/* <Nav.Item className="mt-2 font-weight-normal">
                Column Settings:
              </Nav.Item> */}
                <div className="mt-2">
                  {/* <IFXTab size="mini"> */}
                  {enableColumnShowHide ? (
                    <div name="Show/Hide">
                      <div
                        style={{ maxHeight }}
                        className="overflow-auto column-list"
                      >
                        {checkBoxItems}
                      </div>
                    </div>
                  ) : null}
                  {/* {enablePinnedColumn ? (
                  <div name="Pin">
                    <div
                      style={{ maxHeight }}
                      className="overflow-auto column-list"
                    >
                      {options.columnDefs.map((c, i) => {
                        //console.log(c.field, c.pinned);
                        if (
                          suppressPinColumn &&
                          suppressPinColumn.includes(c.field)
                        ) {
                          return null;
                        }

                        return (
                          c.headerName && (
                            <Nav.Item className="ml-3" key={i}>
                              <IFXCheckbox
                                id={`colpinned_${i}${
                                  gridId ? "_" + gridId : ""
                                }`}
                                label={c.headerName}
                                checked={
                                  c.pinned !== undefined ? c.pinned : false
                                }
                                onChange={() => {
                                  let columnDefs = [...options.columnDefs];
                                  let pinned =
                                    c.pinned !== undefined ? !c.pinned : false;
                                  columnDefs[i] = { ...c, pinned };
                                  onChange({ columnDefs });
                                }}
                              />
                            </Nav.Item>
                          )
                        );
                      })}
                    </div>
                  </div>
                ) : null} */}
                  {/* </IFXTab> */}
                </div>
              </>
            ) : null}
          </Nav>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);

export const IFXDataTable = memo(
  forwardRef((props, ref) => {
    //console.log("IFXDataTable", props);
    const gridRef = useRef();
    const dataTableRef = useRef();

    const [selectAllMessage, setSelectAllMessage] = useState();

    const updateDisplayedRowsObv$ = useMemo(
      () => getUpdateDisplayedRowsObv$(),
      []
    );
    const forceRefreshDebounceObv$ = useMemo(
      () => getForceRefreshDebounceObv$(),
      []
    );

    const updateDisplayedRows = useCallback(e => {
      updateDisplayedRowsObv$.next();
    }, []);

    const onFilterChanged = useCallback(e => {
      forceRefreshDebounceObv$.next();
      updateDisplayedRows();
    }, []);

    const [floatingFilter, setFloatingFilter] = useState(false);
    const [quickFilterText, setQuickFilterText] = useState("");
    const [currentDisplayed, setCurrentDisplayed] = useState({
      from: 0,
      to: 0,
      total: 0,
    });

    const [pageSize, setPageSize] = useState(
      (props.paginationPageSize && [
        {
          label: props.paginationPageSize + "",
          value: props.paginationPageSize,
        },
      ]) ||
        []
    );

    useEffect(() => {
      if (gridRef && gridRef.current.api) {
        const { api } = gridRef.current;
        api.addEventListener("filterChanged", onFilterChanged);
        return () => {
          api.removeEventListener("filterChanged", onFilterChanged);
        };
      }
    }, [gridRef, onFilterChanged]);

    useObservableCallback(forceRefreshDebounceObv$, () => {
      //console.log("forceRefreshDebounceObv$ debounced");
      if (gridRef) {
        gridRef.current.api.refreshCells({ force: true });
      }
    });

    useObservableCallback(updateDisplayedRowsObv$, () => {
      //console.log("updateDisplayedRowsObv$ debounced gridRef::", gridRef);
      if (gridRef) {
        if (
          props.externalFilterValue &&
          props.externalFilterValue.customRecordDisplay
        ) {
          let customRecordDisplay =
            props.externalFilterValue.customRecordDisplay;
          let startIndex = Number(customRecordDisplay.startIndex);
          let resultsPerPage = Number(customRecordDisplay.resultsPerPage);
          let totalCount = Number(customRecordDisplay.totalCount);
          let limit = startIndex - 1 + resultsPerPage;
          setCurrentDisplayed({
            from: startIndex,
            to: limit > totalCount ? totalCount : limit === -1 ? 0 : limit,
            total: totalCount,
          });
        } else {
          const { api } = gridRef.current;
          setCurrentDisplayed(
            api.getDisplayedRowCount() === 0
              ? { from: 0, to: 0, total: 0 }
              : {
                  from: api.getFirstDisplayedRow() + 1,
                  to: api.getLastDisplayedRow() + 1,
                  total: api.getDisplayedRowCount(),
                }
          );
        }
      }
    });

    const resetColumnState = () => {
      const { api } = gridRef.current;
      api.columnController.resetColumnState();
      api.sizeColumnsToFit();
    };
    const clearFilter = () => {
      const { api } = gridRef.current;
      setQuickFilterText("");
      api.setFilterModel(null);
      api.onFilterChanged();
    };

    const resetSort = () => {
      gridRef.current.api.setSortModel([]);
    };

    const resetGrid = () => {
      resetColumnState();
      clearFilter();
      resetSort();
    };

    const customAPIFuncs = {
      getInstance: () => {
        return gridRef.current;
      },
      getExternalFilterValue: () => {
        return props.externalFilterValue;
      },
      updateDisplayedRows,
      clearFilter,
      resetSort,
      resetGrid,
      pageSize,
    };

    useImperativeHandle(ref, () => {
      return customAPIFuncs;
    });

    let _props = {
      ...DEFAULT_PROPS,
      ...props,
      tableOptionsProps: {
        ...DEFAULT_PROPS.tableOptionsProps,
        ...props.tableOptionsProps,
      },
      ...(props.id && { key: props.id }),
    };
    const {
      id,
      className,
      tableControlActions,
      newRowData = {},
      gridOptions,
      defaultAddRows,
      wrapperProps = DEFAULT_WRAPPER_PROPS,
      rowModelType,
      tableOptionsProps,
      columnDefs,
      headerWrapped = false,
      editable = DEFAULT_GRID_OPTS.defaultColDef.editable,
      treeColumnEnabled = false,
      enableTableControls = true,
      showRecordCount = true,
      customRecordCount,
      onRowDataChanged,
      onPageChange,
      enablePageSizeSelection = false,
      showColumnSearch = true,
      printLayout = false,
      setPrintLayout = () => {},
      showSelectAllMessage = false,
    } = _props;

    const _onRowDataChanged = useCallback(() => {
      onRowDataChanged && onRowDataChanged();
      updateDisplayedRows();
    }, [onRowDataChanged]);

    let defaultGridOpt = {
      ...DEFAULT_GRID_OPTS,
      onViewportChanged: updateDisplayedRows,
      onRowDataChanged: _onRowDataChanged,
    };

    if (!treeColumnEnabled) {
      defaultGridOpt = omit(defaultGridOpt, ["getNodeChildDetails"]);
    }
    /*   if (editable === false) {
    defaultGridOpt = omit(defaultGridOpt, ["getRowNodeId"]);
  } */

    let _gridOptions = {
      ...defaultGridOpt,
      ...gridOptions,
      setSelectAllMessage,
    };

    _gridOptions.defaultColDef = { ..._gridOptions.defaultColDef, editable };

    //console.log("_gridOptions", _gridOptions);
    _props = omit(_props, [
      "className",
      "id",
      "tableOptionsProps",
      "headerWrapped",
      "enableTableControls",
      "showRecordCount",
      "customRecordCount",
    ]);

    let _wrapperProps = {
      ...wrapperProps,
      style: { width: "99.99%", ...wrapperProps.style },
    };

    useEffect(() => {
      if (gridRef && gridRef.current.api) {
        //console.log(floatingFilter);
        const timer = setTimeout(() => {
          gridRef.current.api.refreshHeader();
        });
        return () => clearTimeout(timer);
      }
    }, [floatingFilter, gridRef]);

    useEffect(() => {
      if (gridRef && gridRef.current) {
        const timer = setTimeout(() => {
          gridRef.current.api.sizeColumnsToFit();
        });
        return () => clearTimeout(timer);
      }
    }, [gridRef.current, columnDefs]);
    // additional logic for pagination if active start
    useEffect(() => {
      if (gridRef && gridRef.current.api) {
        const { api } = gridRef.current;
        if (api.paginationProxy.active && onPageChange) {
          api.addEventListener("paginationChanged", onPageChange);
        }
        return () => {
          if (api.paginationProxy.active && onPageChange) {
            api.removeEventListener("paginationChanged", onPageChange);
          }
        };
      }
    }, [gridRef, onPageChange]);
    // additional logic for pagination if active end

    //----------------------------
    // Print logic.
    //----------------------------
    const printGrid = useReactToPrint({
      content: () => {
        //debugger;
        return dataTableRef.current;
      },
    });
    useEffect(() => {
      if (gridRef && gridRef.current.api && printLayout) {
        //debugger;
        const { api } = gridRef.current;
        //gridRef.current.eGridDiv.style.height = '';
        api.setDomLayout("print");
        setTimeout(() => {
          //debugger;
          //print(gridRef.current.eGridDiv);
          printGrid();
          setPrintLayout(false);
          //gridRef.current.eGridDiv.style.height = '289px';
          api.setDomLayout(null);
        }, 1000);
      }
    }, [gridRef, printLayout]);

    return (
      <>
        <div ref={dataTableRef} className="ifx-datatable-wrapper">
          <Container fluid>
            {enableTableControls && (
              <div className="ifx-datatable-table-controls">
                {/* <Row></Row> */}
                <Row>
                  <Col xs="3" className="pl-1 display-count">
                    {showRecordCount && (
                      <IFXUserNote
                        marginClassName="m-0"
                        paddingClassName="p-0 pt-1"
                        noBorder={true}
                        noBackground={true}
                        className="font-weight-400"
                      >
                        {customRecordCount ? (
                          <span className="d-block">{`Records Found ${customRecordCount}`}</span>
                        ) : (
                          <span className="d-block">{`Record ${currentDisplayed.from} to ${currentDisplayed.to} of ${currentDisplayed.total}`}</span>
                        )}
                      </IFXUserNote>
                    )}
                  </Col>
                  <Col xs="3">
                    {showSelectAllMessage && !!selectAllMessage && (
                      <IFXUserNote
                        marginClassName="m-0"
                        paddingClassName="p-0 pt-1"
                        noBorder={true}
                        noBackground={true}
                      >
                        {selectAllMessage}
                      </IFXUserNote>
                    )}
                    {enablePageSizeSelection && (
                      <div style={{ width: 100 }}>
                        <IFXFieldWrapper
                          label="Page Size"
                          controlId="pageSize"
                          type="dropdown"
                          alwaysFloat={(() => pageSize.length > 0)()}
                        >
                          <IFXDropdown
                            options={pageSizeList}
                            selected={pageSize}
                            onChange={v => {
                              gridRef.current.api.paginationSetPageSize(
                                v[0].value
                              );
                              setPageSize(v);
                            }}
                          />
                        </IFXFieldWrapper>
                      </div>
                    )}
                  </Col>
                  <Col className="pr-1">
                    <div className="float-right table-control-action-icons-group">
                      {tableControlActions &&
                        tableControlActions.map(
                          (
                            { params = {}, onClick, type, ...actionProps },
                            i
                          ) => (
                            <IFXGridSecondaryIconButton
                              {...actionProps}
                              type={type}
                              key={i}
                              {...(onClick && {
                                onClick: e => {
                                  let _params = params;
                                  if (type === "add") {
                                    _params = {
                                      count: defaultAddRows,
                                      newRowData,
                                      ...params,
                                    };
                                  }
                                  onClick(
                                    e,
                                    { ...gridRef, ...customAPIFuncs },
                                    _params
                                  );
                                },
                              })}
                            />
                          )
                        )}
                      {(tableOptionsProps.enableSaveCurrentView && id && (
                        <SaveCurrentView gridId={id} gridRef={gridRef} />
                      )) ||
                        null}
                      {tableOptionsProps.enableOptionsButton && (
                        <DatatableOptions
                          gridId={id}
                          gridRef={gridRef}
                          floatingFilterOnChange={setFloatingFilter}
                          rowModelType={rowModelType}
                          {...tableOptionsProps}
                        />
                      )}
                    </div>
                    {rowModelType !== DT_MODEL_TYPE.infinite &&
                    showColumnSearch ? (
                      <div className="float-right" style={{ width: 250 }}>
                        <IFXTextBox
                          placeholder="All Column Search"
                          value={quickFilterText}
                          onChange={e => {
                            const {
                              target: { value: v },
                            } = e;
                            setQuickFilterText(v);
                          }}
                        />
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </div>
            )}
            <Row>
              <div
                {..._wrapperProps}
                className={cx(DEFAULT_WRAPPER_PROPS.className, className, {
                  [HEADER_WRAPPED_CLASSNAME]: headerWrapped,
                })}
              >
                <AgGridReact
                  {..._props}
                  gridOptions={_gridOptions}
                  ref={gridRef}
                  quickFilterText={quickFilterText}
                  floatingFilter={floatingFilter}
                  columnDefs={columnDefs}
                  singleClickEdit
                ></AgGridReact>
              </div>
            </Row>
          </Container>
        </div>
      </>
    );
  })
);

IFXDataTable.prototype = {};

export * from "./renderers";
export * from "./editors";
