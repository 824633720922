import initailState from "./initialState";

export const dashboardFilterCriteriaReducer = (
  state = initailState.dashboardFilterCriteria,
  action
) => {
  switch (action.type) {
    case "DASHBOARD_SEARCH_CRITERIA_REQUEST":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
