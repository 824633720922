import React, { useCallback, useContext } from "react";
import { Modal, Col, Container } from "react-bootstrap";
import cx from "classnames";
import { IFXModalContext } from ".";
import { IFXTooltip } from "..";

export const IFXModalCloseIcon = ({
  className = "d-flex pb-2 justify-content-end",
  onHide,
}) => (
  <div className={className}>
    <IFXTooltip placement="right" content="Close">
      <button type="button" className="close" onClick={onHide}>
        <span aria-hidden="true">×</span>
        <span className="sr-only">Close</span>
      </button>
    </IFXTooltip>
  </div>
);

const IFXModalTitle = ({ children }) => (
  <div className="h5 modal-title">{children}</div>
);
export const IFXModalHeader = ({
  headerActions,
  customHeader = false,
  closeButton = true,
  ...props
}) => {
  const modalContext = useContext(IFXModalContext);
  const onHide = useCallback(() => {
    if (modalContext) modalContext.onHide();
    if (props.onHide) props.onHide();
  }, [modalContext, props.onHide]);
  return (
    <>
      <Modal.Header
        closeButton={false}
        {...props}
        className={cx("IFXModalHeader", props.className)}
      >
        {(customHeader && customHeader) ||
          (headerActions && (
            <Container fluid className="p-0">
              <div className="d-flex justify-content-between">
                <Col className="p-0">
                  <IFXModalTitle>{props.children}</IFXModalTitle>
                </Col>
                <div>{headerActions}</div>
              </div>
            </Container>
          )) || <IFXModalTitle>{props.children}</IFXModalTitle>}
        {closeButton && <IFXModalCloseIcon className="pl-3" onHide={onHide} />}
      </Modal.Header>
    </>
  );
};
