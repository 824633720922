import React from "react";
import cx from "classnames";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { omit } from "../../utils";

const DEFAULT_PROPS = {
  className: "ifx-textarea",
  maxLength: 250,
};

export const IFXTextArea = ({ required, showCounter = true, ...props }) => {
  let _props = { ...DEFAULT_PROPS, className: "", ...props };
  _props = omit(_props, ["defaultValue", "value"]);
  const { maxLength } = _props;

  const value = ("value" in props && props.value) || "" || props.defaultValue;
  const count = (value && value.length) || 0;

  return (
    <>
      <Form.Control
        {...(("value" in props && { value: props.value || "" }) || {
          defaultValue: props.defaultValue,
        })}
        {..._props}
        className={cx(DEFAULT_PROPS.className, _props.className, {
          "field-mandatory": required,
        })}
        as="textarea"
      />
      {showCounter && (
        <div className="ifx-textarea-counter">
          <span>{count}</span>/<span>{maxLength}</span> (Additional characters
          will be truncated)
        </div>
      )}
    </>
  );
};

IFXTextArea.propTypes = {
  maxLength: PropTypes.number,
  showCounter: PropTypes.bool,
};
