import { combineReducers } from "redux";
import { UserContextReducer } from "./UserContextReducer";
import { AircraftTypeReducer } from "./AircraftTypeReducer";
import { UserSearchReducer } from "./UserSearchReducer";
import { AircraftSeatConfigReducer } from "./AircraftSeatConfigReducer";
import { CateringStationGlobalReducer } from "./CateringStationGlobalReducer";
import { CarrierStationReducer } from "./CarrierStationReducer";
import { CatererReducer } from "./CatererReducer";
import { ManageSpmlCrwmlReducer } from "./ManageSpmlCrwmlReducer"
const appPortalReducer = combineReducers({
  UserContextReducer,
  AircraftTypeReducer,
  ManageSpmlCrwmlReducer,
  UserSearchReducer,
  AircraftSeatConfigReducer,
  CateringStationGlobalReducer,
  CarrierStationReducer,
  CatererReducer,
});

export function PortalReducer(state, action) {
  switch (action.type) {
    case "RESET_REDUX": {
      console.log("RESET_REDUX PortalReducer");
      return appPortalReducer(undefined, action);
    }
    default:
      return appPortalReducer(state, action);
  }
}
