import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LogoutActions } from "../../../store/actions/portal/actionCreators";
import { Redirect } from "react-router-dom";
import { useEffect } from "react";
//import { Login } from ".";
import { IFXLoader } from "../../../components/common/IFXLoader";

export const Logout = React.memo(() => {
  const {
    LOGOUT_STATUS: logoutStatus,
    IS_SAML: isSaml,
    SAML_EXTERNAL_LOGOUT_REDIRECT_URL: samlExternalLogoutRedirectUrl,
  } = useSelector(state => state.LoginReducer);
  const dispatch = useDispatch();

  //console.log("logoutStatus", logoutStatus);
  useEffect(() => {
    if (!logoutStatus) {
      dispatch(LogoutActions.REQUEST());
      //console.log("dispatching logout");
    }
  }, []);

  if (!logoutStatus) {
    return <IFXLoader message="Logging Out" loaderOpaque enable />;
  } else if (isSaml && samlExternalLogoutRedirectUrl) {
    window.location = samlExternalLogoutRedirectUrl;
  } else {
    return <Redirect to={{ pathname: "/login", state: { logout: true } }} />;
  }
});
