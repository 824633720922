import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { useObservableCallback } from "../../../hooks";
import ToastContext from "./context";

const backgroundStateDebounce$ = new Subject().pipe(debounceTime(700));

function withToastProvider(Component) {
  function WithToastProvider(props) {
    const [isToastActive, setIsToastActive] = useState(false);
    const [hideBackground, setHideBackground] = useState(false);
    const { info, warn, success, error, isActive, dismiss, onChange } = toast;

    useObservableCallback(backgroundStateDebounce$, val => {
      setIsToastActive(val);
    });

    useEffect(() => {
      const unsubscribe = onChange((numberOfToastDisplayed, containerId) => {
        /* console.log("toast OnChange:", {
          numberOfToastDisplayed,
          containerId,
        }); */
        const isActive = numberOfToastDisplayed > 0;
        if (isActive) {
          setIsToastActive(isActive);
        }
        backgroundStateDebounce$.next(isActive);
      });

      return () => unsubscribe();
    }, []);

    return (
      <ToastContext.Provider
        value={{
          toast,
          info,
          warn,
          success,
          error,
          isActive,
          dismiss,
          setHideBackground,
          isToastActive,
        }}
      >
        <Component {...props} />
        <div
          className={`ifx-toasts-wrapper ${(isToastActive && "active") ||
            ""} ${(hideBackground && "hideBackground") || ""}`}
          id="ifx-toast"
        >
          <ToastContainer
            enableMultiContainer
            containerId={"A"}
            position="top-center"
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            draggable={false}
            limit={1}
            pauseOnFocusLoss={false}
          />
        </div>
      </ToastContext.Provider>
    );
  }

  return React.memo(WithToastProvider);
}

export default withToastProvider;
