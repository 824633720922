import React, { forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { IFXIcons } from "@ifx-react/ifx-react-core";
import { IFXBreadcrumb } from "../IFXBreadcrumb";
import { HelpLink } from "../HelpLink";

const PageTitleIcon = React.memo(({ item }) => {
  //console.log(item.icon);
  if (item.icon) {
    let iconArgs = item.icon
      ? typeof item.icon === "string"
        ? { icon: item.icon }
        : item.icon
      : null;
    //console.log(iconArgs);
    return (
      <div className="page-title-icon">
        <IFXIcons {...iconArgs} key={iconArgs} />
      </div>
    );
  }
  return null;
});

export const PageTitle = React.memo(
  forwardRef((props, ref) => {
    const items = useSelector(
      state => state.CommonReducer.BreadcrumbReducer.navBreadcrumbs
    );

    const currentItem =
      items.length > 0 ? items[items.length - 1] : { title: "" };

    useEffect(() => {
      document.title = `InFlight Exchange - ${currentItem.title}`;
      return () => {
        document.title = "InFlight Exchange";
      };
    }, [currentItem.title]);

    return (
      <div ref={ref} className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <PageTitleIcon item={currentItem} />
            <div className="page-title-heading-content">
              {currentItem.moduleTitle || currentItem.title}
              {/* <div
                className={cx("page-title-subheading", {
                  "d-none": !enablePageTitleSubheading
                })}
              >
                {subheading}
              </div> */}
            </div>
          </div>
          <div className="page-title-actions">
            <IFXBreadcrumb />
          </div>
        </div>
        <HelpLink currentPage={currentItem}/>
      </div>
    );
  })
);
