export const HELP_MODULE = {
  PORTAL: "PORTAL",
  SPECIFICATIONS: "SPECIFICATIONS",
  SCHEDULES: "SCHEDULES",
  ORDERS: "ORDERS",
  INVOICES: "INVOICES",
  REPORTS: "REPORTS",
  GALLEYS: "GALLEYS",
  METRICS: "METRICS",
};

export const METRICS = [
  {
    value: "METRICS",
    id: "METRICS",
    display: "METRICS",
  },
];

export const GALLEYS = [
  {
    value: "Designer",
    id: "Designer",
    display: "Designer",
  },
  {
    value: "Loader",
    id: "Loader",
    display: "Loader",
  },
  {
    value: "Fuel Burn",
    id: "Fuel Burn",
    display: "Fuel Burn",
  },
  {
    value: "Task Manager",
    id: "Task Manager",
    display: "Task Manager",
  },
  {
    value: "Publisher",
    id: "Publisher",
    display: "Publisher",
  },
  {
    value: "Caterer Portal",
    id: "Caterer Portal",
    display: "Caterer Portal",
  },
  {
    value: "Reference Data",
    id: "Reference Data",
    display: "Reference Data",
  },
  {
    value: "Image Library",
    id: "Image Library",
    display: "Image Library",
  },
  {
    value: "Parts Catalog",
    id: "Parts Catalog",
    display: "Parts Catalog",
  },
  {
    value: "Container Catalog",
    id: "Container Catalog",
    display: "Container Catalog",
  },
  {
    value: "Preferences",
    id: "Preferences",
    display: "Preferences",
  },
  {
    value: "Tools",
    id: "Tools",
    display: "Tools",
  },
];

function BreadcrumItem({
  moduleTitle,
  title,
  url,
  icon,
  params,
  helpModuleTitle,
  helpFunctionTitle,
  hideHelpLinkMenu = false,
}) {
  this.moduleTitle = moduleTitle || title;
  this.helpModuleTitle = helpModuleTitle;
  this.helpFunctionTitle = helpFunctionTitle || title;
  this.title = title;
  this.url = url;
  this.icon = icon;
  this.params = params;
  this.hideHelpLinkMenu = hideHelpLinkMenu;
}

/* function BreadcrumItemIcon(iconSource, className) {
  this.iconSource = iconSource;
  this.className = className;
} */

export const BREADCRUMB_ITEMS = {
  DEFAULT: new BreadcrumItem({
    title: "",
  }),
  REGISTER: new BreadcrumItem({
    title: "New User Registration",
    icon: { iconSource: "svg", icon: "person" },
    url: "/register",
  }),
  PORTAL: {
    MY_IFX: new BreadcrumItem({
      title: "Dashboard",
      icon: { iconSource: "svg", icon: "portal_home" },
      url: "/app/portal/myIFX",
      helpModuleTitle: HELP_MODULE.PORTAL,
      hideHelpLinkMenu: true,
    }),
    DOC_MGMT: new BreadcrumItem({
      title: "Document Management",
      icon: { iconSource: "svg", icon: "portal_home" },
      url: "/app/portal/docMgmt",
      helpModuleTitle: HELP_MODULE.PORTAL,
    }),
    USER_ADMIN: new BreadcrumItem({
      title: "User Administration",
      icon: { iconSource: "svg", icon: "portal_home" },
      url: "/app/portal/user/search",
      helpModuleTitle: HELP_MODULE.PORTAL,
    }),
    ACCESS_PROFILE: new BreadcrumItem({
      title: "User Administration",
      icon: { iconSource: "svg", icon: "portal_home" },
      url: "/app/portal/user/edit/:profileUserName",
      helpModuleTitle: HELP_MODULE.PORTAL,
    }),
    ACCESS_SELF_PROFILE: new BreadcrumItem({
      title: "User Preferences",
      icon: { iconSource: "svg", icon: "portal_home" },
      url: "/app/portal/user/editProfile",
      helpModuleTitle: HELP_MODULE.PORTAL,
    }),
    AIRCRAFT_TYPE: new BreadcrumItem({
      title: "Master Data",
      icon: { iconSource: "svg", icon: "portal_home" },
      url: "/app/portal/aircraftType",
      helpModuleTitle: HELP_MODULE.PORTAL,
    }),
    MANAGE_SPML_CRWML: new BreadcrumItem({
      title: "Master Data",
      icon: { iconSource: "svg", icon: "portal_home" },
      url: "/app/portal/manageSpmlCrwml",
      helpModuleTitle: HELP_MODULE.PORTAL,
    }),
    CATERER: new BreadcrumItem({
      title: "Master Data",
      icon: { iconSource: "svg", icon: "portal_home" },
      url: "/app/portal/caterer",
      helpModuleTitle: HELP_MODULE.PORTAL,
    }),
    CHANGE_NOTICE: new BreadcrumItem({
      title: "Change Notice",
      icon: { iconSource: "svg", icon: "portal_home" },
      url: "/app/portal/notice/change",
      helpModuleTitle: HELP_MODULE.PORTAL,
    }),
    CHANGE_NOTICE_ACTION: new BreadcrumItem({
      title: "Change Notice",
      icon: { iconSource: "svg", icon: "portal_home" },
      url: "/app/portal/notice/change",
      helpModuleTitle: HELP_MODULE.PORTAL,
    }),
    FAVORITE_MGMT: new BreadcrumItem({
      title: "User Preferences",
      icon: { iconSource: "svg", icon: "portal_home" },
      url: "/app/portal/favorite",
      helpModuleTitle: HELP_MODULE.PORTAL,
    }),
    EMAIL_PREFERENCE: new BreadcrumItem({
      title: "User Preferences",
      icon: { iconSource: "svg", icon: "portal_home" },
      url: "/app/portal/user/email/preference",
      helpModuleTitle: HELP_MODULE.PORTAL,
    }),
    SYS_ADMIN: new BreadcrumItem({
      title: "System Administration",
      icon: { iconSource: "svg", icon: "portal_home" },
      url: "/app/portal/jobs",
      helpModuleTitle: HELP_MODULE.PORTAL,
    }),
    AIRCRAFT_SEATCONFIG: new BreadcrumItem({
      title: "Master Data",
      icon: { iconSource: "svg", icon: "portal_home" },
      url:
        "/app/portal/aircraftSeatConfig/:carrierId/:aircraftTypeId/:aircraftType",
        helpModuleTitle: HELP_MODULE.PORTAL,
    }),
    AIRCRAFT_REGISTRATION: new BreadcrumItem({
      title: "Master Data",
      icon: { iconSource: "svg", icon: "portal_home" },
      url: "/app/portal/aircraftRegistration",
      helpModuleTitle: HELP_MODULE.PORTAL,
    }),
    VIEW_CHANGE_NOTICE: new BreadcrumItem({
      title: "View Change Notice",
      icon: { iconSource: "svg", icon: "portal_home" },
      url: "/app/portal/viewChangeNotice/:cnId",
      helpModuleTitle: HELP_MODULE.PORTAL,
      hideHelpLinkMenu: true
    }),
    HELP_LINKS: new BreadcrumItem({
      title: "System Administration",
      icon: { iconSource: "svg", icon: "portal_home" },
      url: "/app/portal/manageHelpLinks",
      helpModuleTitle: HELP_MODULE.PORTAL,
    }),
  },
  ESS: {
    SAMPLE_1: new BreadcrumItem({
      title: "IFX Components Demo",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/components",
      hideHelpLinkMenu: true,
    }),
    DASHBOARD: new BreadcrumItem({
      title: "Schedules",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/components",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
      helpFunctionTitle: "Flight Service Schedule",
    }),
    SCHEDULE_SET: new BreadcrumItem({
      title: "Schedules",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/components",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
      hideHelpLinkMenu: true, // Need to check
    }),
    RELEASE_FSS: new BreadcrumItem({
      title: "Flight Service Schedule",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/components",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
    }),
    QUERY_RELEASE_FSS: new BreadcrumItem({
      title: "Flight Service Schedule",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/components",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
    }),
    FLIGHTTAG: new BreadcrumItem({
      title: "Administration",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/components",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
    }),
    MAINTAIN_RULES: new BreadcrumItem({
      title: "Scheduling Rules",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/rulesView/maintainRuleSet",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
    }),
    GLOBAL_CATEGORIES: new BreadcrumItem({
      title: "Scheduling Rules",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/rulesView/globalRuleCategories",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
    }),
    CREATE_RULES: new BreadcrumItem({
      title: "Scheduling Rules",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/rules/createRules",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
    }),
    EDIT_RULES: new BreadcrumItem({
      title: "Scheduling Rules",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/rules/editRules",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
    }),
    SEARCH_RULES: new BreadcrumItem({
      title: "Scheduling Rules",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/searchRule",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
    }),
    QUERY_FSS: new BreadcrumItem({
      title: "Flight Service Schedule",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/rulesView/queryFSS",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
    }),
    CREATE_RULE: new BreadcrumItem({
      title: "Scheduling Rules",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/createRule",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
    }),
    Hypothetical_FSS: new BreadcrumItem({
      title: "Flight Service Schedule",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/fss/create",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
    }),
    SEARCH_RELEASED_SDF: new BreadcrumItem({
      title: "Service Demand Forecast",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/sdf/search/released",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
    }),
    SEARCH_SDF: new BreadcrumItem({
      title: "Service Demand Forecast",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/sdf/search",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
    }),
    CUP_VIEW: new BreadcrumItem({
      title: "Flight Service Schedule",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/cupSearch",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
    }),
    ORDER_OF_SERVICE: new BreadcrumItem({
      title: "Administration",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/order/service",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
    }),
    FSS_FLIGHT_DETAILS: new BreadcrumItem({
      title: "Flight Details",
      icon: { iconSource: "svg", icon: "schedules" }, //using pe7 css <i/> icons
      url: "/app/ess/flight/view/:legId/:page",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
      hideHelpLinkMenu: true, // Need to check
    }),
    SDF_DASHBOARD: new BreadcrumItem({
      title: "Service Demand Forecast",
      icon: { iconSource: "svg", icon: "schedules" },
      url: "/app/ess/sdf/dashboard",
      helpModuleTitle: HELP_MODULE.SCHEDULES,
    }),
  },
  ESO: {
    SO_QUERY: new BreadcrumItem({
      title: "Order",
      icon: { iconSource: "svg", icon: "orders" },
      url: "/app/eso/serviceOrder/query",
      helpModuleTitle: HELP_MODULE.ORDERS,
      helpFunctionTitle: "Order"
    }),
    SO_DASHBOARD: new BreadcrumItem({
      title: "Orders",
      icon: { iconSource: "svg", icon: "orders" },
      url: "/app/eso/serviceOrder/dashboard",
      helpModuleTitle: HELP_MODULE.ORDERS,
      helpFunctionTitle: "Order",
    }),
    ORDER_CREATE: new BreadcrumItem({
      moduleTitle: "Orders",
      title: "Create Order",
      icon: { iconSource: "svg", icon: "orders" },
      url: "/app/eso/serviceOrder/createServiceOrder",
      helpModuleTitle: HELP_MODULE.ORDERS,
      helpFunctionTitle: "Order"
    }),
    ORDER_MANAGE: new BreadcrumItem({
      moduleTitle: "Orders",
      title: "Manage Order",
      icon: { iconSource: "svg", icon: "orders" },
      url: "/app/eso/serviceOrder/createServiceOrder",
      helpModuleTitle: HELP_MODULE.ORDERS,
      helpFunctionTitle: "Order"
    }),
    SO_IMPORT: new BreadcrumItem({
      title: "Orders",
      icon: { iconSource: "svg", icon: "orders" },
      url: "/app/eso/serviceOrder/import",
      helpModuleTitle: HELP_MODULE.ORDERS,
      helpFunctionTitle: "Order"
    }),
    VIEW_SO_DETAILS: new BreadcrumItem({
      title: "Order",
      icon: { iconSource: "svg", icon: "orders" },
      url: "/app/eso/serviceOrder/viewServiceOrder",
      helpModuleTitle: HELP_MODULE.ORDERS,
      helpFunctionTitle: "Order"
    }),
    SMART_ORD_DASHBOARD: new BreadcrumItem({
      title: "Order",
      icon: { iconSource: "svg", icon: "orders" },
      url: "/app/eso/smartOrder/dashboard",
      helpModuleTitle: HELP_MODULE.ORDERS,
      helpFunctionTitle: "Order"
    }),
    SMART_ORD_AUDIT_LOG: new BreadcrumItem({
      title: "Order",
      icon: { iconSource: "svg", icon: "orders" },
      url: "/app/eso/SORuleAudit/SORuleAuditLog",
      helpModuleTitle: HELP_MODULE.ORDERS,
      helpFunctionTitle: "Order"
    }),
    ESO_POLLING_POLICIES: new BreadcrumItem({
      title: "Administration",
      icon: { iconSource: "svg", icon: "orders" },
      url: "/app/eso/Administration/ESOPollingPolicies",
      helpModuleTitle: HELP_MODULE.ORDERS,
    }),
    PAX_ADJUSTMENT_RULE_SETS: new BreadcrumItem({
      title: "Pax Adjustment Rules",
      icon: { iconSource: "svg", icon: "orders" },
      url: "/app/eso/paxAdjustmentRule/ruleSets",
      helpModuleTitle: HELP_MODULE.ORDERS,
    }),
    SEARCH_RULES: new BreadcrumItem({
      title: "Pax Adjustment Rules",
      icon: { iconSource: "svg", icon: "orders" }, //using pe7 css <i/> icons
      url: "/app/eso/paxAdjustmentRule/searchRule",
      helpModuleTitle: HELP_MODULE.ORDERS,
    }),
    VIEW_SEARCH_RULES: new BreadcrumItem({
      title: "Pax Adjustment Rules",
      icon: { iconSource: "svg", icon: "orders" }, //using pe7 css <i/> icons
      url: "/app/eso/paxAdjustmentRule/searchRule/:ruleSetCode/:categoryCode",
      helpModuleTitle: HELP_MODULE.ORDERS,
    }),
    CREATE_PAX_RULES: new BreadcrumItem({
      title: "Pax Adjustment Rules",
      icon: { iconSource: "svg", icon: "orders" }, //using pe7 css <i/> icons
      url: "/app/eso/paxAdjustmentRule/Rule/createPaxRuleForm",
      helpModuleTitle: HELP_MODULE.ORDERS,
    }),
    EDIT_PAX_RULE_SETS: new BreadcrumItem({
      title: "Pax Adjustment Rules",
      icon: { iconSource: "svg", icon: "orders" },
      url: "/app/eso/paxAdjustmentRule/paxCategory/editPaxRuleSet",
      helpModuleTitle: HELP_MODULE.ORDERS,
    }),
    PAX_TEST_RULE: new BreadcrumItem({
      title: "Pax Test Rules",
      icon: { iconSource: "svg", icon: "orders" }, //using pe7 css <i/> icons
      url: "/app/eso/paxAdjustmentRule/Rule/TestRule",
      helpModuleTitle: HELP_MODULE.ORDERS,
      helpFunctionTitle: "Pax Adjustment Rules"
    }),
    PAX_VARIABLE_SETUP: new BreadcrumItem({
      title: "Pax Adjustment Rules",
      icon: { iconSource: "svg", icon: "orders" },
      url: "/app/eso/paxAdjustmentRule/paxVariable",
      helpModuleTitle: HELP_MODULE.ORDERS,
    }),
    TOP_FOCUS_ITEMS: new BreadcrumItem({
      title: "Administration",
      icon: { iconSource: "svg", icon: "orders" },
      url: "/app/eso/topFocusItems/topFocusItems",
      helpModuleTitle: HELP_MODULE.ORDERS,
    }),
    SO_REPORTS: new BreadcrumItem({
      title: "Reports",
      icon: { iconSource: "svg", icon: "orders" },
      url: "/app/eso/report/overVsShort",
      helpModuleTitle: HELP_MODULE.ORDERS,
    }),
  },
  ESM: {
    SPEC_SEARCH: new BreadcrumItem({
      title: "Spec",
      icon: { iconSource: "svg", icon: "specifications" },
      url: "/app/esm/searchSpec",
      helpModuleTitle: HELP_MODULE.SPECIFICATIONS,
      helpFunctionTitle: "Spec",
    }),
    SPEC_CREATE: new BreadcrumItem({
      title: "Spec",
      icon: { iconSource: "svg", icon: "specifications" },
      url: "/app/esm/createSpec",
      helpModuleTitle: HELP_MODULE.SPECIFICATIONS,
    }),
    SPEC_UPDATE: new BreadcrumItem({
      title: "Spec",
      icon: { iconSource: "svg", icon: "specifications" },
      url: "/app/esm/update/:specVrsnId",
      helpModuleTitle: HELP_MODULE.SPECIFICATIONS,
    }),
    SPEC_WHERE_USED: new BreadcrumItem({
      title: "Spec",
      icon: { iconSource: "svg", icon: "specifications" },
      url: "/app/esm/whereused/:specId/:specVrsnId",
      helpModuleTitle: HELP_MODULE.SPECIFICATIONS,
    }),
    SPEC_TREE: new BreadcrumItem({
      title: "Spec",
      icon: { iconSource: "svg", icon: "specifications" },
      url: "/app/esm/whereused/specTree/:specId/:specVersionId/:effDate",
      helpModuleTitle: HELP_MODULE.SPECIFICATIONS,
    }),
    SPEC_COMPARE: new BreadcrumItem({
      title: "Spec",
      icon: { iconSource: "svg", icon: "specifications" },
      url: "/app/esm/compare/:specVerId",
      helpModuleTitle: HELP_MODULE.SPECIFICATIONS,
    }),
    COMPARE_SPEC_VIEW: new BreadcrumItem({
      title: "Spec",
      icon: { iconSource: "svg", icon: "specifications" },
      url: "/app/esm/version/compare/:versionId",
      helpModuleTitle: HELP_MODULE.SPECIFICATIONS,
    }),
    SPML_QUERY: new BreadcrumItem({
      title: "Administration",
      icon: { iconSource: "svg", icon: "specifications" },
      url: "/app/esm/spml/query",
      helpModuleTitle: HELP_MODULE.SPECIFICATIONS,
    }),
    CRWML_QUERY: new BreadcrumItem({
      title: "Administration",
      icon: { iconSource: "svg", icon: "specifications" },
      url: "/app/esm/crwml/query",
      helpModuleTitle: HELP_MODULE.SPECIFICATIONS,
    }),
    SPEC_PRICE_SEARCH: new BreadcrumItem({
      title: "Price",
      icon: { iconSource: "svg", icon: "specifications" },
      url: "/app/esm/pricing/searchSpecPrice",
      helpModuleTitle: HELP_MODULE.SPECIFICATIONS,
    }),
    SPEC_REPLACE: new BreadcrumItem({
      title: "Spec",
      icon: { iconSource: "svg", icon: "specifications" },
      url: "/app/esm/replaceSpec",
      helpModuleTitle: HELP_MODULE.SPECIFICATIONS,
    }),
    SPEC_TAG_CATEGORY: new BreadcrumItem({
      title: "Administration",
      icon: { iconSource: "svg", icon: "specifications" },
      url: "/app/esm/specTags/specCategory",
      helpModuleTitle: HELP_MODULE.SPECIFICATIONS,
    }),
    LOAD_SCALE: new BreadcrumItem({
      title: "Administration",
      icon: { iconSource: "svg", icon: "specifications" },
      url: "/app/esm/loadScale",
      helpModuleTitle: HELP_MODULE.SPECIFICATIONS,
    }),
    ADD_EDIT_LOAD_SCALE: new BreadcrumItem({
      title: "Administration",
      icon: { iconSource: "svg", icon: "specifications" },
      url: "/app/esm/addEditLoadScale",
      helpModuleTitle: HELP_MODULE.SPECIFICATIONS,
    }),
    ROTATION_TYPE_WHERE_USED: new BreadcrumItem({
      title: "Administration",
      icon: { iconSource: "svg", icon: "specifications" },
      url: "/app/esm/rotationType/whereused/:rotationId/:rotationVer",
      helpModuleTitle: HELP_MODULE.SPECIFICATIONS,
    }),
  },
  ESV: {
    SEARCH_INVOICE: new BreadcrumItem({
      title: "Invoice",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/manageinvoice/detailedsearch",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    REMITTANCE: new BreadcrumItem({
      title: "Invoice",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/manageinvoice/remittance",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    VIEW_REMITTANCE_SUMMARY: new BreadcrumItem({
      title: "Invoice",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/manageinvoice/remittance/viewRemittanceSummary",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    VIEW_REG_INV_DETAILS: new BreadcrumItem({
      title: "Invoice",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/invoiceManagement/viewInvDetails",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    CREATE_MISC_INVOICE: new BreadcrumItem({
      title: "Invoice",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/manageInvoice/createMiscInvoice",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    ESV_CREATE_RULE: new BreadcrumItem({
      title: "Invoicing Rules",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/rules/create",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    ESV_RULE_CATEGORIES: new BreadcrumItem({
      title: "Invoicing Rules",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/rules/ruleCategories",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    ESV_RULE_GROUPS: new BreadcrumItem({
      title: "Invoicing Rules",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/rules/ruleGroups/:ruleCategoryId",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    ESV_RULE_GROUPS_EDIT: new BreadcrumItem({
      title: "Invoicing Rules",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/rules/ruleGroups/update/:ruleGroupId",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    ESV_RULE_VARIABLES: new BreadcrumItem({
      title: "Invoicing Rules",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/rules/ruleVariables",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    PRICE_ALTERATION: new BreadcrumItem({
      title: "Administration",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/managecharges/priceAlterationMethod",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    BROWSE_INVOICE: new BreadcrumItem({
      title: "Invoice",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/manageInvoice/general",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    MANAGE_INVOICE_PERIOD: new BreadcrumItem({
      title: "Administration",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/manageInvoice/manageInvoicePeriods",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    STATION_PROPERTIES: new BreadcrumItem({
      title: "Administration",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/administration/stationProperties",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    VIEW_STATION_PROPERTIES_DETAILS: new BreadcrumItem({
      title: "Administration",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/administration/viewStationPropertiesDetails",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    SEARCH_REC_TEMPLATE: new BreadcrumItem({
      title: "Invoice",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/invoice/recurrenceInvoice",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    ESV_DASHBOARD: new BreadcrumItem({
      title: "Invoices",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/dashboard/ESVDashboard",
      helpModuleTitle: HELP_MODULE.INVOICES,
      helpFunctionTitle: "Invoice",
    }),
    INVOICING_PERIOD: new BreadcrumItem({
      title: "Invoicing Period",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/manageinvoice/invoicingPeriod",
      helpModuleTitle: HELP_MODULE.INVOICES,
      helpFunctionTitle: "Invoice",
    }),
    INVOICING_PERIOD_LEVEL2: new BreadcrumItem({
      title: "Invoicing Period",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/InvoicingPeriod",
      helpModuleTitle: HELP_MODULE.INVOICES,
      helpFunctionTitle: "Invoice",
    }),
    INVOICING_PERIOD_LEVEL3: new BreadcrumItem({
      title: "Invoicing Period",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/listOfInvoices",
      helpModuleTitle: HELP_MODULE.INVOICES,
      helpFunctionTitle: "Invoice",
    }),
    INVOICE_TAGS: new BreadcrumItem({
      title: "Administration",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/administration/invoiceTags",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    PMNT_REPORT: new BreadcrumItem({
      title: "Invoice",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/manageInvoice/generatePmntReport",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    ESV_SEARCH_RULES: new BreadcrumItem({
      title: "Invoicing Rules",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/rules/searchRules",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
    SEARCH_RAP_REQUEST: new BreadcrumItem({
      title: "Invoice",
      icon: { iconSource: "svg", icon: "invoices" },
      url: "/app/esv/manageInvoice/rapRequests",
      helpModuleTitle: HELP_MODULE.INVOICES,
    }),
  },
  REPORTS: {
    IFX_REPORTS: new BreadcrumItem({
      title: "Reports",
      icon: { iconSource: "svg", icon: "report" },
      url: "/app/reports/dashboard",
      helpModuleTitle: HELP_MODULE.REPORTS,
    }),
  },
  SPMT: {
    IFX_SPMT: new BreadcrumItem({
      title: "Metrics",
      icon: { iconSource: "svg", icon: "metrics" },
      url: "/app/spmt/dashboard",
      helpModuleTitle: HELP_MODULE.METRICS,
    }),
  },
};
