import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Redirect, Switch, withRouter } from "react-router-dom";
import fscreen from "fscreen";
import { IFXAppMain } from "../../IFXAppMain";
import { Login, Logout } from "../../Login";
import { UserRegistration } from "../../UserRegistration/UserRegistration";
import { IFXLoader } from "../../../../components/common/IFXLoader";
import { IFXNavWrapper } from "../../../../components/common/IFXBreadcrumb/IFXNavWrapper";
import { BREADCRUMB_ITEMS } from "../../../../const/portal/breadcrumbItems";

import { useDispatch, useSelector } from "react-redux";
import {
  UserContextActions,
  UserInfoActions,
} from "../../../../store/actions/portal/actionCreators";
import { LayoutActions } from "../../../../store/actions/common/actionCreators";
import { IFXRoute } from "../../../../components/common/IFXRoute";
import { TIMER_CONSTS } from "../../../../const/common/timerConsts";
import { EMPTY, Subject, timer } from "rxjs";
import {
  ifxFormAjax,
  RESPONSE_TYPES,
  useObservableCallback,
  useToast,
} from "@ifx-react/ifx-react-core";
import {
  VERIFY_SESSION_URL,
  EXTEND_SESSION_URL,
} from "../../../../const/portal/endpoints";
import { catchError, map, switchMap } from "rxjs/operators";
import { ActivateAccount } from "../../UserRegistration/ActivateAccount";
import { Form } from "react-bootstrap";
import { BuildInfo } from "../../BuildInfo";

export const PORTAL_DASHBOARD_URL = "/app/portal/myIFX";
export const SSO_ERROR_URL = "/ssoError/*";
const NON_AUTHENTICATED_URLS = [
  "/",
  "/login",
  "/logout",
  "/register",
  "/activateAccount",
  SSO_ERROR_URL,
];

/* console.log(
  "checkArrayWithWildCardInclude",
  `${item.replaceAll("*", ".*")}$`
);
console.log(
  "checkArrayWithWildCardInclude match",
  checkString.match(new RegExp(`${item.replaceAll("*", ".*")}$`))
); */
const getURLMatchRegex = urlPattern =>
  new RegExp(`${urlPattern.replaceAll("*", ".*")}$`);

const checkArrayWithWildCardInclude = (array, checkString) =>
  array.filter(urlPattern => checkString.match(getURLMatchRegex(urlPattern)))
    .length > 0;

const getVerifySessionPollRequest = () =>
  timer(0, TIMER_CONSTS.LOGIN_SEESION_CHECK_INTERVAL).pipe(
    switchMap(() =>
      ifxFormAjax
        .get(VERIFY_SESSION_URL, undefined, { ...RESPONSE_TYPES.string })
        .pipe(
          map(({ response }) => {
            return { response };
          }),
          catchError(error => {
            console.error(error);
            return [{ error }];
          })
        )
    )
  );

const extendSessionRequest$ = new Subject().pipe(
  switchMap(() =>
    ifxFormAjax
      .get(EXTEND_SESSION_URL, undefined, { ...RESPONSE_TYPES.string })
      .pipe(
        map(({ response }) => {
          return { response };
        }),
        catchError(error => {
          console.error(error);
          return [{ error }];
        })
      )
  )
);

const CheckLoginStatus = withRouter(
  memo(
    ({
      carrierCode,
      location: { pathname, state: { logout } = { logout: false } },
      children,
    }) => {
      const dispatch = useDispatch();
      const [appURL, setAppURL] = useState(PORTAL_DASHBOARD_URL);

      const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(
        false
      );

      const { ifxConfirmToast, ifxSuccessToast, ifxDismissToast } = useToast();

      const initialLoad = useSelector(
        state => state.PortalReducer.UserContextReducer.initialLoad
      );

      const carrierSwitchKey = useSelector(
        state => state.PortalReducer.UserContextReducer.carrierSwitchKey || null
      );

      const { AUTHENTICATION_STATUS: authStatus = null } = useSelector(
        state => state.LoginReducer
      );
      const { autoLogoutEnabled } = useSelector(
        state => state.PortalReducer.UserContextReducer.userInfo
      );

      /* console.log("CheckLoginStatus", {
        pathname,
        initialLoad,
        state,
        logout,
        carrierSwitchKey,
      }); */

      const verifySessionObsrv$ = useMemo(
        () =>
          (autoLogoutEnabled &&
            initialLoad === 2 &&
            getVerifySessionPollRequest()) ||
          EMPTY,
        [initialLoad]
      );

      useObservableCallback(verifySessionObsrv$, ({ response, error }) => {
        //console.log("verifySession Response", response);
        if (response === "notAuthenticated" || error) {
          setShowLogoutConfirmation(false);
          ifxDismissToast();
          dispatch(UserContextActions.SET_INITIAL_LOAD(-2)); //logout
        } else if (response === "showConfirmation") {
          setShowLogoutConfirmation(true);
        }
      });

      useObservableCallback(extendSessionRequest$, ({ response, error }) => {
        //console.log("verifySession Response", response);
        if (response === "notAuthenticated" || error) {
          setShowLogoutConfirmation(false);
          ifxDismissToast();
          dispatch(UserContextActions.SET_INITIAL_LOAD(-2)); //logout
        } else {
          ifxSuccessToast({ content: "Your session has been extended." });
        }
      });

      useEffect(() => {
        if (showLogoutConfirmation) {
          ifxConfirmToast({
            content: <LogoutConfirmation />,
            hideProgressBar: false,
            autoClose: TIMER_CONSTS.AUTO_LOGOUT_CONFIRMATION_WINDOW,
            onCallback: (e, name, { type }) => {
              //console.log("confirm onCallback", e, name, type);
              if (type === "yes") {
                extendSessionRequest$.next();
              } else {
                dispatch(UserContextActions.SET_INITIAL_LOAD(-2));
              }
              setShowLogoutConfirmation(false);
            },
            options: {
              pauseOnHover: false,
              pauseOnFocusLoss: false,
              onClose: ({ lastAction }) => {
                //console.log("confirm onClose", lastAction);
                if (lastAction === null) {
                  ifxDismissToast();
                  dispatch(UserContextActions.SET_INITIAL_LOAD(-2));
                }
              },
            },
          });
        }
      }, [showLogoutConfirmation]);

      useEffect(() => {
        /* console.log(
          "CheckLoginStatus initialLoad loading user info useEffect start",
          { logout, authStatus, pathname, initialLoad }
        ); */
        if (
          (!logout || authStatus === "success") &&
          pathname !== "/logout" &&
          (initialLoad === 0 ||
            ((initialLoad === -1 || initialLoad === -2) &&
              authStatus === "success"))
        ) {
          /* console.log("CheckLoginStatus initialLoad loading user info inside", {
            logout,
            authStatus,
            pathname,
            initialLoad,
          }); */
          dispatch(UserContextActions.SET_INITIAL_LOAD(1)); //setting inprogress
          //async , if failed set -1
          dispatch(
            UserInfoActions.REQUEST({
              carrierCode,
              failureStatus: -1,
            })
          );
        }
        if (pathname === "/logout")
          dispatch(UserContextActions.SET_INITIAL_LOAD(-1));
      }, [initialLoad, authStatus, pathname, logout]);

      useEffect(() => {
        initialLoad === 0 &&
          !logout &&
          !checkArrayWithWildCardInclude(NON_AUTHENTICATED_URLS, pathname) &&
          setAppURL(pathname);
        logout && setAppURL(PORTAL_DASHBOARD_URL);
      }, [initialLoad, pathname, logout]);

      const carrierSwitchRedirect = useMemo(() => {
        if (initialLoad === 2 && carrierSwitchKey) {
          if (pathname !== PORTAL_DASHBOARD_URL) {
            return true;
          } else {
            dispatch(UserContextActions.SET_RESET_CARRIER_SWITCH_KEY());
            return false;
          }
        }
        return false;
      }, [initialLoad, carrierSwitchKey, pathname]);

      if (initialLoad === -3) {
        return <SAMLForward />;
      }

      if (carrierSwitchRedirect) {
        return <Redirect to={PORTAL_DASHBOARD_URL} />;
      }

      if (!logout && (initialLoad === 0 || initialLoad === 1)) {
        return <IFXLoader globalScope loaderOpaque enable />;
      }

      if (!checkArrayWithWildCardInclude(NON_AUTHENTICATED_URLS, pathname)) {
        //console.log("pathname", pathname, initialLoad);
        if (initialLoad === 0 || initialLoad === 1) {
          return <IFXLoader globalScope loaderOpaque enable />;
        } else if (initialLoad === -1) {
          return <Redirect to="/login" />;
        } else if (initialLoad === -2) {
          return <Redirect to="/logout" />;
        }
      }

      if (
        initialLoad === 2 &&
        checkArrayWithWildCardInclude(NON_AUTHENTICATED_URLS, pathname) &&
        pathname !== "/logout"
      ) {
        return <Redirect to={appURL} />;
      }

      return children;
    }
  )
);

export const AppMain = withRouter(
  memo(() => {
    const dispatch = useDispatch();

    const initialLoad = useSelector(
      state => state.PortalReducer.UserContextReducer.initialLoad
    );
    const carrierCode = useSelector(
      state =>
        state.PortalReducer.UserContextReducer.selectedAirline?.iataCode || null
    );

    const loaderOpaque = useSelector(
      state => state.CommonReducer.LayoutReducer.loaderOpaque
    );

    const isfullScreenEnabled = useSelector(
      state => state.CommonReducer.LayoutReducer.fullScreenEnable
    );

    const onChangeFullScreen = useCallback(() => {
      const enabled =
        fscreen.fullscreenElement === document.getElementsByTagName("body")[0];
      //console.log("onChangeFullScreen:enabled:" + enabled);
      if (!enabled) {
        dispatch(LayoutActions.SET_FULLSCREEN_ENABLE(false));
      }
    }, []);

    useEffect(() => {
      fscreen.addEventListener("fullscreenchange", onChangeFullScreen);
      return () => {
        fscreen.removeEventListener("fullscreenchange", onChangeFullScreen);
      };
    }, []);

    useEffect(() => {
      if (isfullScreenEnabled && fscreen.fullscreenEnabled) {
        fscreen.requestFullscreen(document.getElementsByTagName("body")[0]);
      }
    }, [isfullScreenEnabled]);

    return (
      <CheckLoginStatus carrierCode={carrierCode}>
        <AppMainContent
          key={`AppMainContent_${carrierCode}`}
          initialLoad={initialLoad}
          loaderOpaque={loaderOpaque}
          carrierCode={carrierCode}
        />
      </CheckLoginStatus>
    );
  })
);

const AppMainContent = memo(({ initialLoad, loaderOpaque, carrierCode }) => (
  <>
    <IFXLoader globalScope loaderOpaque={loaderOpaque} />
    <Switch>
      {initialLoad === 2 && (
        <IFXRoute
          key={`IFXAppMain_${carrierCode}`}
          path="/app"
          component={IFXAppMain}
        />
      )}
      <IFXRoute exact path="/register" component={UserRegistration}>
        <IFXNavWrapper item={BREADCRUMB_ITEMS.REGISTER}>
          <UserRegistration />
        </IFXNavWrapper>
      </IFXRoute>
      <IFXRoute exact path="/login" component={Login} />
      <IFXRoute exact path="/logout" component={Logout} />
      {/* <IFXRoute exact path="/ssoError" component={Login} /> */}
      <IFXRoute exact path="/" render={() => <Redirect to="/login" />} />
      <IFXRoute exact path="/activateAccount" component={ActivateAccount} />
    </Switch>
    <div id="textWidthCheck"></div>
    <BuildInfo />
  </>
));

const LogoutConfirmation = memo(() => {
  return "Your session is about to timeout due to inactivity. Would you like to extend?";
});

const SAMLForward = memo(() => {
  const formRef = useRef();
  const {
    AUTHENTICATION_STATUS: authStatus,
    SAML_XML_REQUEST,
    SAML_DESTINATION,
  } = useSelector(state => state.LoginReducer);

  useEffect(() => {
    if (authStatus === "saml_forward") {
      try {
        formRef.current.submit();
      } catch (e) {
        console.error(e);
      }
    }
  }, [authStatus]);

  return (
    <form
      ref={formRef}
      action={SAML_DESTINATION}
      method="post"
      style={{ display: "none" }}
    >
      <Form.Control name="SAMLRequest" type="hidden" value={SAML_XML_REQUEST} />
    </form>
  );
});
