import React, { forwardRef, useImperativeHandle, memo } from "react";
import { Link } from "react-router-dom";
import { ActionButton } from "./ActionCol";

export const LinkAndButtonRenderer = memo(forwardRef((props, ref) => {
  const { data, value, getTo, buttonProps = {} } = props;
  const to = getTo(data);

  useImperativeHandle(ref, () => {
    return {};
  });

  return (
    <>
      {props.value && (
        <div className="d-flex">
          <div className="float-left action-col-container">
            <ActionButton data={data} {...buttonProps} />
          </div>
          <span className="float-left ifx-link ifx-text-overflow">
            <Link to={to}>{value}</Link>
          </span>
        </div>
      )}
    </>
  );
}));
