import React, { forwardRef } from "react";
import { Form } from "react-bootstrap";
import cx from "classnames";

const DEFAULT_PROPS = {
  className: "ifx-file-upload form-control form-control-sm",
  size: "sm",
  autoComplete: "off",
  type: "file",
};

export const IFXFile = forwardRef(({ required, ...props }, ref) => {
  const _props = { ...DEFAULT_PROPS, className: "", ...props };

  return (
    <>
      <Form.Control
        {..._props}
        className={cx(DEFAULT_PROPS.className, _props.className, {
          "field-mandatory": required,
        })}
        ref={ref}
      />
    </>
  );
});
