import React, { useCallback, useContext, useMemo, useRef } from "react";
import ToastContext from "./context";
import { IFXDoubleConfirmToast } from "./IFXDoubleConfirmToast";
import { IFXToast } from "./Toast";


const AUTOCLOSE_TIMER = 2000;

const defaultActions = {
  information: [{ name: "Ok", type: "ok" }],
  error: [{ name: "Ok", type: "ok" }],
  confirmation: [
    { name: "Yes", type: "yes" },
    { name: "No", type: "no" },
  ],
};

const defaultToastProps = {
  className: "ifx-toast-container",
  bodyClassName: "ifx-toast-body",
  containerId: "A",
};

const toastType = ["Success", "Warning", "Information", "Error"];

function useToast() {
  const lastActionRef = useRef(null);
  const setLastAction = useCallback(
    action => (lastActionRef.current = action),
    []
  );

  const {
    toast: ifxToast,
    info: ifxInfoToast,
    warn: ifxWarnToast,
    success: ifxSuccessToast,
    error: ifxErrorToast,
    isActive: ifxIsActiveToast,
    dismiss: ifxDismissToast,
    setHideBackground,
  } = useContext(ToastContext);

  return useMemo(() => {
    const toastWrapper = (toastHandler, t = "Information", ToastComp = IFXToast) => ({
      content: message,
      options,
      onCallback = (e, name) => {},
      title = t,
      actions = undefined,
      hideBackground = undefined,
      hideProgressBar = undefined,
      autoClose = undefined,
    }) => {
      const newToastId = `ifx-toast-${Date.now()}`;

      const actionsProp =
        actions || defaultActions[title.toLowerCase()] || undefined;

      ifxToast.clearWaitingQueue({ containerId: "A" });

      //console.log("toastWrapper invoked:", title, hideBackground, actionsProp);

      // No action No background hidden.
      /* if (hideBackground === undefined) {
        if (!actionsProp?.length) {
          setHideBackground(true);
        } else {
          setHideBackground(false);
        }
      } else {
        setHideBackground(hideBackground);
      } */
      const className = `toast${(toastType.includes(title) &&
        title.toLowerCase()) ||
        "information"}`;

      const onCloseCustom = params => {
        //console.log("onClose", newToastId, params);
        //console.log("lastAction", lastActionRef.current);
        options?.onClose &&
          options.onClose({ ...params, lastAction: lastActionRef.current });
        setLastAction(null);
      };
      const onOpen = params => {
        //console.log("onOpen Params", newToastId, params);
        options?.onOpen && onOpen(params);
      };

      const toastProps = {
        message,
        title,
        onCallback,
        onCloseCustom,
        actions: actionsProp,
        className,
        ifxDismissToast,
        setLastAction,
        hideProgressBar:
          hideProgressBar === undefined ? !!actionsProp : hideProgressBar,
      };

      const optionsProps = {
        toastId: newToastId,
        ...defaultToastProps,
        ...options,
        onClose: null,
        onOpen,
        progressClassName: className,
        hideProgressBar:
          hideProgressBar === undefined ? !!actionsProp : hideProgressBar,
        autoClose:
          autoClose === undefined ? !actionsProp && AUTOCLOSE_TIMER : autoClose,
        closeButton: !actionsProp,
      };
      toastHandler(<ToastComp {...toastProps} />, optionsProps);

      return options?.toastId || newToastId;
    };

    return {
      ifxToast: toastWrapper(ifxToast),
      ifxInfoToast: toastWrapper(ifxInfoToast, "Information"),
      ifxWarnToast: toastWrapper(ifxWarnToast, "Warning"),
      ifxSuccessToast: toastWrapper(ifxSuccessToast, "Success"),
      ifxErrorToast: toastWrapper(ifxErrorToast, "Error"),
      ifxConfirmToast: toastWrapper(ifxToast, "Confirmation"),
      ifxDoubleConfirmToast: toastWrapper(ifxToast, "Confirmation", IFXDoubleConfirmToast),
      ifxIsActiveToast,
      ifxDismissToast,
    };
  }, []);
}

export default useToast;
