import initialState from "./initialState";

export function CateringStationGlobalReducer(
  state = initialState.cateringStationFormValues,
  action
) {
  switch (action.type) {
    case "CATERING_STATION_FORM_DATA_REQUEST":
      return {
        ...state,
        cateringStationFormValues: action.payload,
      };
    default:
      return state;
  }
}
