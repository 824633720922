import initialState from "./initialState";
export function LoadScaleSearchReducer(
  state = initialState.loadScaleSearchParams,
  action
) {
  switch (action.type) {
    case "LOAD_SCALE_SEARCH_REQUEST":
      return action.payload;
    default:
      return state;
  }
}
