import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  IFXFormValidation,
  IFXButton,
  ifxJsonAjax,
  useObservableCallback,
  isEmptyOrNull,
  useToast,
  LayoutContext,
  IFXErrorAlert,
  IFXFloatingFooter,
  IFXPromptIfDirty,
} from "@ifx-react/ifx-react-core";
import { useFormContext } from "react-hook-form";
import { Container } from "react-bootstrap";
import { PageTitle } from "../../../components/common/PageTitle";
import { IfxAppMainContext } from "../../common/IFXAppMain";
import { UserInfoForm } from "./UserInfoForm";
import { LoginInfoForm } from "./LoginInfoForm";
import { CompanyInfoForm } from "./CompanyInfoForm";
import { UserPrefForm } from "./UserPrefForm";
import { AccessReqstForm } from "./AccessReqstForm";
import { UserAccessProfiles } from "./UserAccessProfiles";
import { RegistrationHeader } from "./RegistrationHeader";
import { Subject, throwError } from "rxjs";
import { Redirect, withRouter } from "react-router-dom";
import { mergeMap, map, catchError } from "rxjs/operators";
import { LayoutActions } from "../../../store/actions/common/actionCreators";
import { AccessDenied } from "../../../views/common/AccessDenied";
import {
  USER_REG_DEFAULT_VALUES,
  SAVE_REGISTRAION_VALUES_URL,
  GET_USER_INFO,
} from "../../../const/portal/endpoints";
import { UserRegistrationConsts } from "./userRegistrationConsts";
import { AppHeader } from "../layout/AppHeader/";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReactResizeDetector from "react-resize-detector";
import { debounceTime } from "rxjs/operators";
import { CommonToastConsts } from "../../../const/common/toastConsts";
import { useUserTaskAnyGranted } from "../../../hooks/portal/userSecurityHooks";
import { PortalTasks } from "../../../const/portal/PortalTaskConsts";

export const UserRegistrationContext = React.createContext();

const resizeDebounceObv = new Subject().pipe(debounceTime(300));
const resizeWidthDebounceObv = new Subject().pipe(debounceTime(200));

const loadDefaultOptions$ = params =>
  ifxJsonAjax.post(USER_REG_DEFAULT_VALUES, params).pipe(
    map(xhrResponse => {
      return xhrResponse;
    }),
    catchError(error => {
      console.error(error);
      return throwError(error);
    })
  );

const getSaveUserAjaxObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax.post(SAVE_REGISTRAION_VALUES_URL, params).pipe(
        map(xhrResponse => {
          return {
            response: xhrResponse.response,
            params,
          };
        }),
        catchError(error => {
          error.userName = params.userName;
          console.error("Error in New Registration", error);
          errorHandler(error);
          return [];
        })
      )
    )
  );

const getUserInfo$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax
        .post(GET_USER_INFO + "?editUserName=" + params.userName, {})
        .pipe(
          map(xhrResponse => {
            xhrResponse.response.doScroll = params.doScroll;
            xhrResponse.response.languageOptions = params.languageOptions;
            xhrResponse.response.timeFormatOptions = params.timeFormatOptions;
            xhrResponse.response.dateFormatOptions = params.dateFormatOptions;
            xhrResponse.response.countryOptions = params.countryOptions;
            xhrResponse.response.carrierOptions = params.carrierOptions;
            xhrResponse.response.secretQuestionList = params.secretQuestionList;
            return xhrResponse.response || {};
          }),
          catchError(error => {
            console.error("Error in Access Profile", error);
            errorHandler(error);
            return [];
          })
        )
    )
  );

const setDefaultList = (
  defaultOptionList,
  dropdownList,
  labelName,
  labelValue
) => {
  const list = defaultOptionList
    ? defaultOptionList[dropdownList].map(element => ({
        ...element,
        label: element[labelName],
        value: element[labelValue],
      }))
    : [];
  return list;
};

const defaultFormValues = {};

const ButtonGroup = ({
  usernameExists,
  existedUserName,
  cancelRegistration,
}) => {
  const { formState } = useFormContext();
  return (
    <IFXFloatingFooter>
      <div className="pt-2 text-right register-errors">
        {formState.isSubmitted && (
          <div className="error-msg">
            <IFXErrorAlert show={!formState.isValid}>
              There are errors on page!
            </IFXErrorAlert>
            <IFXErrorAlert show={usernameExists && formState.isValid}>
              User Name <b>'{existedUserName}'</b> already exists.
            </IFXErrorAlert>
          </div>
        )}
        <IFXButton type="submit" name="Save" onClick={() => {}} />
        <IFXButton type="clear" name="Cancel" onClick={cancelRegistration} />
      </div>
    </IFXFloatingFooter>
  );
};

export const UserRegistration = withRouter(props => {
  const {
    match: {
      params: {},
    },
    location: { search },
    history: { goBack },
    createdBy,
    editedBy,
    profileUserName,
  } = props;

  const [defaultOptions, setDefaultOptions] = useState({});
  const [userData, setUserData] = useState({});
  const [redirectHome, setRedirectHome] = useState(false);
  const [redirectUserEdit, setRedirectUserEdit] = useState(null);
  const [redirectLogin, setRedirectLogin] = useState(false);
  const [valueMatchRule, setValueMatchRule] = useState({});
  const [usernameExists, setUsernameExists] = useState(false);
  const [height, setHeight] = useState(200);
  const [existedUserName, setExistedUserName] = useState("");
  const [toggleAcc, setToggleAcc] = useState("initial");
  const [samlAccessUserType, setSamlAccessUserType] = useState(false);
  const [carrierList, setCarrierList] = useState([]);
  const [typeOfUserList, setTypeOfUserList] = useState([
    { label: "Airline", value: 1 },
    { label: "Caterer", value: 2 },
    { label: "Other", value: 3 },
  ]);
  const [languageList, setLanguageList] = useState([]);
  const [dateFormatList, setDateFormatList] = useState([]);
  const [timeFormatList, setTimeFormatList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [showAccessDenied, setShowAccessDenied] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});

  const editUserTask = useUserTaskAnyGranted([PortalTasks.MaintainUserProfile]);

  const contentContainerRef = useRef();
  const scrollContainerRef = useRef();
  const scrollbarRef1 = useRef();
  const accessProfilesRef = useRef();

  const dispatch = useDispatch();

  const { ifxConfirmToast, ifxInfoToast, ifxErrorToast } = useToast();

  const className =
    createdBy !== "admin" ? "new-account sign-up" : "new-account";

  useEffect(() => {
    dispatch(
      LayoutActions.SET_LOADER_SHOW({
        loaderText: "Loading...",
      })
    );
    const params =
      createdBy == "admin"
        ? {
            createdby: editedBy === "self" ? "self" : "admin",
            service: "registration",
          }
        : {};
    const updateSubscribe = loadDefaultOptions$(params).subscribe(
      response => {
        const defaultOptionList = response.response || {};
        setDefaultOptions(defaultOptionList);
        if (response.response && response.response.carrierList) {
          const samlUserType = response.response.carrierList.find(item => {
            return (
              item.samlAccessUserType == "1" ||
              item.samlAccessUserType == "2" ||
              item.samlAccessUserType == "3"
            );
          });
          if (samlUserType) {
            setSamlAccessUserType(true);
          }
        }
        const languageOptions = setDefaultList(
          defaultOptionList,
          "languageList",
          "displaylanguage",
          "id"
        );
        const dateFormatOptions = setDefaultList(
          defaultOptionList,
          "shortDateList",
          "dateFormatPattern",
          "id"
        );
        const timeFormatOptions = setDefaultList(
          defaultOptionList,
          "timeFormatList",
          "dateFormatPattern",
          "id"
        );
        const countryOptions = setDefaultList(
          defaultOptionList,
          "countryVOList",
          "countryName",
          "id"
        );
        const carrierOptions = setDefaultList(
          defaultOptionList,
          "carrierList",
          "airlineName",
          "id"
        );
        const secretQuestionList = setDefaultList(
          defaultOptionList,
          "secretQuestionList",
          "description",
          "id"
        );
        setLanguageList(languageOptions);
        setDateFormatList(dateFormatOptions);
        setTimeFormatList(timeFormatOptions);
        setCountryList(countryOptions);
        setCarrierList(carrierOptions);
        if (profileUserName) {
          userProfile$.next({
            userName: profileUserName,
            doScroll: editedBy === "admin",
            languageOptions,
            dateFormatOptions,
            timeFormatOptions,
            countryOptions,
            carrierOptions,
            secretQuestionList,
          });
          return;
        }
        setDefaultValues({
          ...defaultFormValues,
          language: languageOptions[0] || null,
          dateFormat: dateFormatOptions[0] || null,
          timeFormat: timeFormatOptions[0] || null,
          accessReqCarrierName:
            carrierOptions.length === 1 ? carrierOptions[0] : null,
        });
      },
      error => {
        if (error.response && error.response.message === "REG_NOT_ALLOWED") {
          dispatch(LayoutActions.SET_LOADER_HIDE());
          setShowAccessDenied(true);
        }
      },
      () => {
        !editedBy && dispatch(LayoutActions.SET_LOADER_HIDE());
        updateSubscribe.unsubscribe();
      }
    );
  }, []);

  const cancelRegistration = () => {
    const cancelConfirm = UserRegistrationConsts.REGISTER_CANCEL_CONFIRM;
    cancelConfirm.onCallback = (() => (e, name) => {
      if (name === "Yes") {
        createdBy === "admin" ? setRedirectHome(true) : setRedirectLogin(true);
      }
    })();
    ifxConfirmToast(cancelConfirm);
  };

  const userProfile$ = useMemo(() => {
    return getUserInfo$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
      },
    });
  }, [profileUserName]);

  const saveUserDetails$ = useMemo(() => {
    return getSaveUserAjaxObj$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        if (error.response && error.response.message === "USER_NAME_EXISTS") {
          setExistedUserName(error.userName);
          setUsernameExists(true);
          return;
        }
        ifxErrorToast(CommonToastConsts.ERROR);
      },
    });
  }, []);

  const createAccount = formdata => {
    dispatch(
      LayoutActions.SET_LOADER_SHOW({
        loaderText: "Saving...",
      })
    );
    console.log(formdata);
    const {
      accessReqCarrierName,
      typeofUser,
      country,
      language,
      companyName,
      dateFormat,
      timeFormat,
      companyAddress,
      timeZone,
      station,
      secretQuestion,
    } = formdata;

    const params = {
      firstName: formdata.firstName || userData.firstName,
      lastName: formdata.lastName || userData.lastName,
      emailText: formdata.emailText || userData.emailText,
      confirmEmailText: formdata.confirmEmailText || userData.emailText,
      workPhone: formdata.workPhone || userData.workPhone,
      cellPhone:
        formdata.cellPhone ||
        (defaultOptions.isUserSamlEnabled ? userData.cellPhone : ""),
      department: formdata.department || userData.department,
      jobtitle: formdata.jobtitle || userData.jobtitle,
      stationId: (station && station.id) || userData.stationId,
      managerName: formdata.managerName || userData.managerName,
      managerEmail: formdata.managerEmail || userData.managerEmail,
      managerPhone: formdata.managerPhone || userData.managerPhone,
      userName: formdata.userName || userData.userName,
      passwordText: formdata.passwordText,
      confirmPassword: formdata.confirmPassword,
      questionCmb:
        editedBy !== "admin" && secretQuestion && secretQuestion.id
          ? secretQuestion.id
          : userData.secretQuestionId,
      secretAnswer: formdata.secretAnswer || userData.secretAnswer,
      confirmAnswer: formdata.confirmAnswer || userData.confirmAnswer,
      accessReqCarrier: accessReqCarrierName && accessReqCarrierName.value,
      accessReqCarrierName: accessReqCarrierName && accessReqCarrierName.value,
      typeofUser: typeofUser && typeofUser.label,
      companyNameCmb:
        companyAddress && companyAddress.orgId
          ? companyAddress.orgId
          : companyName
          ? companyName.id
          : formdata.selOrganizationId || userData.selOrganizationId,
      selectedComp: null,
      country: country && country.value,
      language: language && language.value,
      isUserSamlEnabled: false,
      shortDateFormateId: dateFormat && dateFormat.value,
      timeFormateId: timeFormat && timeFormat.value,
      companyAddressCmb: companyAddress
        ? companyAddress.value
        : formdata.companyAddressCmb || userData.companyAddressCmb,
      timeZoneId: timeZone && timeZone.value,
      accessRequest:
        formdata.accessRequest ||
        (defaultOptions.isUserSamlEnabled ? userData.accessRequest : ""),
      createdby: createdBy,
    };
    if (!!editedBy) {
      params.userId = userData.userId;
    }
    if (samlAccessUserType || defaultOptions.isUserSamlEnabled) {
      params.isUserSamlEnabled = true;
    }
    saveUserDetails$.next(params);
  };

  const { scrollbarRef } = useContext(IfxAppMainContext) || {};

  const handleResize = e => {
    createdBy !== "admin" && resizeDebounceObv.next(e);
  };

  const handleUpdateScroll = e => {
    scrollbarRef1.current && scrollbarRef1.current.updateScroll();
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useObservableCallback(resizeWidthDebounceObv, width => {
    dispatch(LayoutActions.SET_CONTENT_WIDTH(width));
  });

  useObservableCallback(resizeDebounceObv, () => {
    const contContainer = contentContainerRef.current;
    let height =
      window.innerHeight - ((contContainer && contContainer.offsetTop) || 100);

    if (height < 200) height = 200; // min height
    setHeight(height);

    handleUpdateScroll();
  });

  useObservableCallback(
    saveUserDetails$,
    response => {
      if (
        !isEmptyOrNull(response) &&
        response.response &&
        response.response.length &&
        !editedBy
      ) {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        const content = (
          <>
            New user account <b>'{response.params.userName}' </b>
            for{" "}
            <b>
              '{response.params.firstName} {response.params.lastName}'{" "}
            </b>
            has been created successfully.
            {createdBy === "admin" && (
              <>
                <br />
                Do you want to setup access profiles and credential sets?
              </>
            )}
            {createdBy !== "admin" && (
              <>
                <br />
                An email has been sent with instructions for email verification.
                Once your email is verified and an administrator has reviewed
                and approved your request, you will be notified via email. At
                that time you will be able to log in to the application.
              </>
            )}
          </>
        );
        const confirmSetup = {
          content: content,
          actions: ["Yes", "Close"],
        };
        confirmSetup.actions =
          createdBy !== "admin" ? ["OK"] : ["Yes", "Close"];
        confirmSetup.onCallback = (() => (e, name) => {
          if (name === "Yes" && editUserTask) {
            setRedirectUserEdit(response.params.userName);
          } else if (name === "OK") {
            setRedirectLogin(true);
          } else {
            setRedirectHome(true);
          }
        })();
        createdBy === "admin"
          ? ifxConfirmToast(confirmSetup)
          : ifxInfoToast(confirmSetup);
      } else if (!!editedBy) {
        userProfile$.next({
          userName: profileUserName,
        });
      }
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
    }
  );

  useObservableCallback(
    userProfile$,
    response => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      setUserData(response);
      if (!isEmptyOrNull(response)) {
        const language = response.languageOptions?.length
          ? response.languageOptions.find(item => item.id === response.language)
          : [];
        const dateFormat = response.dateFormatOptions?.length
          ? response.dateFormatOptions.find(
              item => item.id === response.shortDateFormateId
            )
          : [];
        const timeFormat = response.timeFormatOptions?.length
          ? response.timeFormatOptions.find(
              item => item.id === response.timeFormateId
            )
          : [];
        const country = response.countryOptions?.length
          ? response.countryOptions.find(item => item.id === response.country)
          : [];
        const carrier = response.carrierOptions?.length
          ? response.carrierOptions.find(
              item => item.value === response.accessReqCarrier
            )
          : [];
        const timeZone = response.timeZoneList?.length
          ? response.timeZoneList.find(
              item => item.value === response.timeZoneId
            )
          : null;
        const companyAddress = response.companyAddressList?.length
          ? response.companyAddressList.find(
              item => item.value === response.companyAddressCmb
            )
          : null;
        const typeofUser = typeOfUserList?.find(
          item =>
            item.label.toLowerCase() === response.selTypeOfUser.toLowerCase()
        );
        const secretQuestion =
          response.secretQuestionId &&
          response.secretQuestionList?.find(
            item => item.id === response.secretQuestionId
          );
        setDefaultValues({
          firstName: response.firstName || "",
          lastName: response.lastName || "",
          emailText: response.emailText || "",
          confirmEmailText: response.confirmEmailText || "",
          workPhone: response.workPhone || "",
          cellPhone: response.cellPhone || "",
          department: response.department || "",
          jobtitle: response.jobtitle || "",
          station: !!editedBy
            ? { id: response.stationId, value: response.stationCode }
            : null,
          managerName: response.managerName || "",
          managerEmail: response.managerEmail || "",
          managerPhone: response.managerPhone || "",
          userName: response.userName || "",
          passwordText: response.passwordText,
          confirmPassword: response.confirmPassword,
          secretQuestion,
          secretAnswer: response.secretAnswer,
          confirmAnswer: response.confirmAnswer,
          accessReqCarrierName: carrier,
          typeofUser,
          companyName: !!editedBy && {
            id: response.selOrganizationId,
            value: response.selOrganizationId,
            label: response.companyNameVal,
          },
          companyAddress,
          language,
          dateFormat,
          timeFormat,
          country,
          timeZone,
          accessRequest: response.accessRequest || "",
        });
      }
      response.doScroll &&
        accessProfilesRef.current &&
        scrollbarRef.current._container.scrollTo({
          top: accessProfilesRef.current.getInstance().offsetTop,
          behavior: "smooth",
        });
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
    }
  );

  const userRegistrationForm =
    !isEmptyOrNull(defaultOptions) &&
    !isEmptyOrNull(defaultValues) &&
    ((!!editedBy && !isEmptyOrNull(userData)) || !editedBy) ? (
      <>
        <div className={className}>
          {redirectHome && <Redirect to="/app/portal/myIFX" push />}
          {!isEmptyOrNull(redirectUserEdit) && (
            <Redirect to={`/app/portal/user/edit/${redirectUserEdit}`} />
          )}
          {redirectLogin && <Redirect to="/login" push />}
          <IFXFormValidation
            onSubmit={createAccount}
            defaultValues={defaultValues}
            customValidationRules={{
              ...valueMatchRule,
            }}
          >
            <UserRegistrationContext.Provider
              value={{
                defaultOptions,
                editedBy,
                userData,
                toggleAcc,
                samlAccessUserType,
                setSamlAccessUserType,
              }}
            >
              {((!!editedBy && !isEmptyOrNull(userData)) || !!createdBy) && (
                <RegistrationHeader
                  {...{
                    goBack,
                    setToggleAcc,
                    userProfile$,
                    usernameExists,
                    existedUserName,
                  }}
                />
              )}
              {!(defaultOptions.isUserSamlEnabled && editedBy === "self") && (
                <UserInfoForm
                  setValueMatchRule={setValueMatchRule}
                  valueMatchRule={valueMatchRule}
                />
              )}
              {!(defaultOptions.isUserSamlEnabled && editedBy === "self") && (
                <CompanyInfoForm {...{ carrierList, typeOfUserList }} />
              )}
              {!(defaultOptions.isUserSamlEnabled && editedBy === "self") && (
                <LoginInfoForm valueMatchRule={valueMatchRule} />
              )}
              <UserPrefForm
                {...{
                  languageList,
                  dateFormatList,
                  timeFormatList,
                  countryList,
                }}
              />
              {!(defaultOptions.isUserSamlEnabled && editedBy === "self") && (
                <AccessReqstForm />
              )}
              {!!editedBy && (
                <UserAccessProfiles
                  userAccessProfiles={userData.accessProfileList}
                  accessProfilesRef={accessProfilesRef}
                  userData={userData}
                  scrollbarRef={scrollbarRef}
                />
              )}
              {!editedBy && !createdBy && (
                <ButtonGroup
                  {...{ usernameExists, existedUserName, cancelRegistration }}
                />
              )}
              <IFXPromptIfDirty />
            </UserRegistrationContext.Provider>
          </IFXFormValidation>
        </div>
      </>
    ) : null;

  const renderForm =
    createdBy !== "admin" ? (
      <>
        <AppHeader isRegisterPage={true} />
        <div className="app-main">
          <div className="app-main__inner">
            {!showAccessDenied && (
              <div className="app-main__title">{<PageTitle />}</div>
            )}
            <LayoutContext.Provider value={{ contentHeight: height }}>
              <ReactResizeDetector
                handleWidth
                onResize={width => {
                  resizeWidthDebounceObv.next(width);
                }}
              >
                <Container
                  ref={contentContainerRef}
                  id="contentContainer" //required for legacy
                  fluid
                  className="app-main__content p-0"
                  style={{ height: height }}
                >
                  <PerfectScrollbar ref={scrollbarRef1}>
                    <Container
                      fluid
                      className="p-0 pr-4 mx-2"
                      style={{ height: height - 10 }}
                      ref={scrollContainerRef}
                    >
                      <div className="resize-detect-div">
                        <ReactResizeDetector
                          handleHeight
                          onResize={handleUpdateScroll}
                        >
                          {showAccessDenied ? (
                            <AccessDenied path="/register" goBackEnabled />
                          ) : (
                            userRegistrationForm
                          )}
                        </ReactResizeDetector>
                      </div>
                    </Container>
                  </PerfectScrollbar>
                </Container>
              </ReactResizeDetector>
            </LayoutContext.Provider>
          </div>
        </div>
      </>
    ) : (
      <>{userRegistrationForm}</>
    );

  return renderForm;
});
