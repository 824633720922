import {
  createSetterAction,
  createAsyncAction,
  createAction,
} from "../createAction";
import * as types from "./actionTypes";

export const LoginActions = createAsyncAction(types.LOGIN_ACTION);

export const LogoutActions = createAsyncAction(types.LOGOUT_ACTION);

export const ResetReduxActions = createAction(
  types.RESET_REDUX_ACTION,
  [types.RESET_REDUX_ACTION],
  false
);

export const UserContextActions = createSetterAction(
  types.USER_CONTEXT_ACTION,
  types.USER_CONTEXT_SUB_ACTIONS
);

export const UserInfoActions = createAsyncAction(types.USER_INFO_ACTION);

export const AirlineSwitchActions = createAsyncAction(
  types.AIRLINE_SWITCH_ACTION
);

export const AircraftTypeFormActions = createAsyncAction(
  types.AIRCRAFT_TYPE_FORM_DATA
);

export const SearchUserProfileActions = createAsyncAction(
  types.SEARCH_USER_PROFILE
);

export const SearchAcSeatConfigActions = createAsyncAction(
  types.SEARCH_AC_SEAT_CONFIG
);

export const CateringStationFormActions = createAsyncAction(
  types.CATERING_STATION_FORM_DATA
);

export const CarrierStationFormActions = createAsyncAction(
  types.CARRIER_STATION_FORM_DATA
);
export const CatererFormActions = createAsyncAction(
  types.CATERER_FORM_DATA
);
export const ManageSpmlCrwmlFormActions = createAsyncAction(
  types.MANAGE_SPML_CRWML
);
