import initialState from "./initialState";

export function ViewRuleLogReducer(state = null, action) {
  switch (action.type) {
    case "FSS_RULE_LOG_STORE_SEARCH_CRITERIA":
      return action.payload;

    default:
      return state;
  }
}
