import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  IFXModal,
  IFXModalBody,
  IFXModalHeader,
  IFXModalFooter,
  IFXButton,
  ifxJsonAjax,
  useObservableCallback,
  useToast,
} from "@ifx-react/ifx-react-core";
import { Subject } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { sanatizeHtml } from "@ifx-react/ifx-react-core/src/utils/string";
import { CommonToastConsts } from "../../../const/common/toastConsts";
import { LoginActions } from "./../../../store/actions/portal/actionCreators";
import { ACCEPT_EULA_URL } from "../../../const/portal/endpoints";

const getNewEulaAcceptObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax.post(ACCEPT_EULA_URL, params).pipe(
        map(xhrResponse => xhrResponse.response || {}),
        catchError(error => {
          console.error("Error in New Eula Changed", error);
          errorHandler(error);
          return [];
        })
      )
    )
  );

export const LegalNoticePopup = ({
  show,
  onHide,
  legalNoticeContent,
  isNewEula,
  username,
  password,
}) => {
  const [redirectSignup, setRedirectSignup] = useState(false);
  const [enableRead, setEnableRead] = useState(false);

  const dispatch = useDispatch();

  const { ifxErrorToast } = useToast();

  const checkIfScrollToBottom = e => {
    if (
      e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop <=
      40
    ) {
      e.target.removeEventListener("scroll", checkIfScrollToBottom, true);
      setEnableRead(true);
    }
  };

  const printLegalNotices = () => {
    const printButton = `
            <br />
            <input type="Button" name="print" value="Print" onclick="window.print(); return false;" />
            <br />
            <br />
        `;
    let legalNotice = document.createElement("div");
    legalNotice.innerHTML = `<div>
            <div style="float:right;">IFX <sup>TM</sup>
            </div>
        </div>`;
    legalNotice.innerHTML =
      legalNotice.innerHTML +
      "<div style='float: left'>" +
      document.querySelector(".ifxportalfont .ifxportalfont").innerHTML +
      "</div>";
    var legal = sanatizeHtml(printButton + legalNotice.innerHTML);

    let pWin = window.open();
    pWin.document.write(
      "<html><head><title></title><style> body</style></head><body>"
    );
    pWin.document.write(legal);
    pWin.document.write("</body></html>");
    pWin.document.close();
  };

  const newEulaAccept$ = useMemo(() => {
    return getNewEulaAcceptObj$({
      errorHandler: error => {
        console.error("Error in Accept EULA", error);
        ifxErrorToast(CommonToastConsts.ERROR);
      },
    });
  }, []);

  const handleReadAndAgree = () => {
    if (isNewEula) {
      newEulaAccept$.next({});
    } else {
      setRedirectSignup(true);
    }
  };

  useEffect(() => {
    const ele = document.getElementById("noticeDiv");
    ele.addEventListener("scroll", checkIfScrollToBottom, true);
  }, []);

  useObservableCallback(
    newEulaAccept$,
    response => {
      onHide();
      if (response.EULA_STATUS === "success") {
        dispatch(LoginActions.RESET());
        dispatch(LoginActions.REQUEST({ username, password }));
      }
    },
    error => {
      console.error("Error in Accept EULA", error);
    }
  );

  const disbaleClass = !enableRead ? "disableRead" : "";

  return (
    <>
      {redirectSignup && <Redirect to="/register" push />}
      <IFXModal show={show} onHide={onHide} size="lg" className="legalNotice">
        <IFXModalHeader>Legal Notice</IFXModalHeader>
        <IFXModalBody>
          <Container>
            <Row>
              <Col
                dangerouslySetInnerHTML={{
                  __html: sanatizeHtml(legalNoticeContent),
                }}
              ></Col>
            </Row>
            {isNewEula && (
              <Row>
                <Col className="font-weight-bold">
                  Our legal agreement has been changed. Please read and accept
                  the new legal agreement. Thank you.{" "}
                </Col>
              </Row>
            )}
          </Container>
        </IFXModalBody>
        <IFXModalFooter>
          <IFXButton
            type="save"
            name="Read & I Agree"
            className={disbaleClass}
            onClick={handleReadAndAgree}
          />
          <IFXButton type="cancel" name="Decline" onClick={onHide} />
          <IFXButton type="upload" name="Print" onClick={printLegalNotices} />
        </IFXModalFooter>
      </IFXModal>
    </>
  );
};
