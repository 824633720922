import React, { useContext, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormContext } from "react-hook-form";
import {
  IFXFloatingHeader,
  IFXButtonGroup,
  IFXPageHeaderIconButton,
  useObservableCallback,
  ifxJsonAjax,
  useToast,
  IFXErrorAlert,
} from "@ifx-react/ifx-react-core";
import { Row, Col } from "react-bootstrap";
import { Subject } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { UserRegistrationContext } from "./UserRegistration";
import {
  DEACTIVATE_USER,
  ACTIVATE_USER,
  REJECT_USER,
  APPROVE_USER,
  RESET_PASSWORD_URL,
} from "../../../const/portal/endpoints";
import { LayoutActions } from "../../../store/actions/common/actionCreators";
import { CommonToastConsts } from "../../../const/common/toastConsts";
import { ChangePasswordModal } from "../Login/ChangePasswordModal";

const getUserStatusObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(p =>
      ifxJsonAjax.post(p.url, p.params).pipe(
        map(xhrResponse => xhrResponse.response || {}),
        catchError(error => {
          console.error("Error in Changing the status of User", error);
          errorHandler(error);
          return [];
        })
      )
    )
  );

const getResetPasswordObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax
        .post(
          RESET_PASSWORD_URL +
            "?userName=" +
            params.userName +
            "&userId=" +
            params.userId
        )
        .pipe(
          map(xhrResponse => xhrResponse.response || {}),
          catchError(error => {
            console.error("Error in Reset Password", error);
            errorHandler(error);
            return [];
          })
        )
    )
  );

const showDyanamicMsg = infoMsg => (
  <span dangerouslySetInnerHTML={{ __html: infoMsg }} />
);

export const RegistrationHeader = props => {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const dispatch = useDispatch();

  const { ifxErrorToast, ifxInfoToast } = useToast();

  const {
    editedBy,
    userData,
    toggleAcc,
    samlAccessUserType,
    defaultOptions,
  } = useContext(UserRegistrationContext);
  const {
    goBack,
    setToggleAcc,
    userProfile$,
    existedUserName,
    usernameExists,
  } = props;

  const { formState } = useFormContext();

  const manageUserAction = type => {
    let url = DEACTIVATE_USER;
    let loaderText = "Deactivating...";
    const params = { strUserName: userData.userName };
    if (type === "activate") {
      url = ACTIVATE_USER;
      loaderText = "Activating...";
    } else if (type === "reject") {
      url = REJECT_USER;
      loaderText = "Rejecting...";
      params.orgid = userData.selOrganizationId;
    } else if (type === "approve") {
      url = APPROVE_USER;
      loaderText = "Approving...";
      params.orgid = userData.selOrganizationId;
    }
    const obj = {
      url,
      params: { ...params },
    };
    dispatch(
      LayoutActions.SET_LOADER_SHOW({
        loaderText,
      })
    );
    userActions$.next(obj);
  };

  const userActions$ = useMemo(() => {
    return getUserStatusObj$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        ifxErrorToast(CommonToastConsts.ERROR);
      },
    });
  }, []);

  const resetPassword = () => {
    dispatch(
      LayoutActions.SET_LOADER_SHOW({
        loaderText: "Resetting...",
      })
    );
    resetPassword$.next({
      userName: userData.userName,
      userId: userData.userId,
    });
  };

  const resetPassword$ = useMemo(() => {
    return getResetPasswordObj$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        ifxErrorToast(CommonToastConsts.ERROR);
      },
    });
  }, []);

  const onHide = () => {
    setShowChangePasswordModal(false);
  };

  useObservableCallback(
    userActions$,
    response => {
      userProfile$.next({
        userName: userData.userName,
      });
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
    }
  );

  useObservableCallback(
    resetPassword$,
    response => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      ifxInfoToast({
        content: showDyanamicMsg(
          `Password for user has been set to <b>${response.newPassword}</b> and has been emailed.`
        ),
      });
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
    }
  );

  return (
    <IFXFloatingHeader>
      <ChangePasswordModal
        show={showChangePasswordModal}
        {...{ onHide, username: userData.userName, editedBy }}
      />
      <Row>
        {!!editedBy && (
          <Col xs={7} className="mt-2">
            <Row>
              <Col>
                <span className="title">User Name:</span>
                <span className="text pl-1">{userData.userName}</span>
              </Col>
              <Col>
                <span className="title">Name:</span>
                <span className="text pl-1">
                  {userData.firstName + " " + userData.lastName}
                </span>
              </Col>
              <Col>
                <span className="title">Company:</span>
                <span className="text pl-1">{userData.companyNameVal}</span>
              </Col>
              <Col>
                <span className="title">Home Station:</span>
                <span className="text pl-1">{userData.stationCode}</span>
              </Col>
            </Row>
          </Col>
        )}
        <IFXButtonGroup>
          <IFXPageHeaderIconButton type="save" />
          {editedBy === "self" &&
            !(samlAccessUserType || defaultOptions.isUserSamlEnabled) && (
              <IFXPageHeaderIconButton
                icon="reset_pswd"
                name="Change Password"
                onClick={() => {
                  setShowChangePasswordModal(true);
                }}
              />
            )}
          {((editedBy === "admin" && userData.userStatusId === 194) ||
            userData.userStatusId === 195) && (
            <IFXPageHeaderIconButton
              type="approve"
              name="Approve"
              onClick={() => {
                manageUserAction("approve");
              }}
            />
          )}
          {editedBy === "admin" && userData.userStatusId === 194 && (
            <IFXPageHeaderIconButton
              icon="reject"
              name="Reject"
              onClick={() => {
                manageUserAction("reject");
              }}
            />
          )}
          {editedBy === "admin" && userData.userStatusId === 196 && (
            <IFXPageHeaderIconButton
              icon="unlock"
              name="Deactivate"
              onClick={() => {
                manageUserAction("deactivate");
              }}
            />
          )}
          {editedBy === "admin" && userData.userStatusId === 197 && (
            <IFXPageHeaderIconButton
              icon="lock"
              name="Activate"
              onClick={() => {
                manageUserAction("activate");
              }}
            />
          )}
          {editedBy === "admin" && !samlAccessUserType && (
            <IFXPageHeaderIconButton
              icon="reset_pswd"
              name="Reset Password"
              onClick={resetPassword}
            />
          )}
          <IFXPageHeaderIconButton
            icon={["folder", "folder-collapse"]}
            name={["Collapse All", "Expand All"]}
            isToggle
            onToggle={useCallback(() => {
              setToggleAcc(!toggleAcc);
            }, [toggleAcc])}
            defaultToggle
          />
          <IFXPageHeaderIconButton type="back" onClick={goBack} />
        </IFXButtonGroup>
      </Row>
      {formState.isSubmitted && (
        <>
          <IFXErrorAlert show={usernameExists && formState.isValid}>
            User Name <b>'{existedUserName}'</b> already exists.
          </IFXErrorAlert>
          <IFXErrorAlert show={!formState.isValid}>
            There are errors on page!
          </IFXErrorAlert>
        </>
      )}
    </IFXFloatingHeader>
  );
};
