export const SoDashboardJobHistoryReducer = (state = [], action) => {
  switch (action.type) {
    case "SODASHBOARD_STORE_JOB_HISTORY":
      return [...state, action.payload];
    case "SODASHBOARD_REMOVE_OLD_JOB":
      return state;
    default:
      return state;
  }
};
