import initialState from "./initialState";

export function AircraftTypeReducer(
  state = initialState.AircraftTypeFormValues,
  action
) {
  switch (action.type) {
    case "AIRCRAFT_TYPE_FORM_DATA_REQUEST":
      return {
        ...state,
        AircraftTypeFormValues: action.payload,
      };
    default:
      return state;
  }
}
