import React, { useState } from "react";
// import { LayoutActions } from "../../../../store/actions/common/actionCreators";
// import { useSelector, useDispatch } from "react-redux";

export const HeaderLogo = React.memo(() => {
  //const [active, setActive] = useState(false);
  //const [mobile, setMobile] = useState(false);
  /* const [activeSecondaryMenuMobile, setActiveSecondaryMenuMobile] = useState(
    false
  ); */

  //const [openLeft] = useState(false);
  //const [openRight] = useState(false);
  //const [relativeWidth] = useState(false);
  //const [width] = useState(280);
  //const [noTouchOpen] = useState(false);
  //const [noTouchClose] = useState(false);

  /* const sidebarClosedEnable = useSelector(
    state => state.CommonReducer.LayoutReducer.sidebarClosedEnable
  ); */
  //const dispatch = useDispatch();
  // useEffect(() => {
  //   LayoutActions.SET_SIDEBAR_CLOSED_ENABLE(false);
  // });
  /*  const toggleSidebarClosedEnable = () => {
    dispatch(LayoutActions.SET_SIDEBAR_CLOSED_ENABLE(true));
  }; */

  return (
    <>
      <div className="app-header__logo">
        <div className="logo-src" />
        {/* <div className="header__pane ml-auto">
          <div onClick={toggleSidebarClosedEnable}>
            <Hamburger
              active={sidebarClosedEnable}
              type="elastic"
              onClick={() => setActive({ active: !active })}
            />
          </div>
        </div> */}
      </div>
      {/* <AppMobileMenu/> */}
    </>
  );
});
