import {
  createAction,
  createSetterAction,
  createAsyncAction,
} from "../createAction";
import * as types from "./actionTypes";

export const LayoutActions = createSetterAction(
  types.LAYOUT_ACTION,
  types.LAYOUT_SUB_ACTIONS
);

export const BreadCrumbActions = createAction(
  types.BREADCRUMB_ACTION,
  types.BREADCRUMB_SUB_ACTIONS
);

export const LoadCarrierProperties = createAsyncAction(
  types.CARRIER_PROPERTIES_ACTION
);

export const StoreCarrierProperties = createAction(
  types.CARRIER_PROPERTIES_ACTION,
  types.STORE_CARRIER_PROPERTIES_ACTION
);

export const BuildInfoActions = createAction(
  types.BUILD_INFO_ACTION,
  types.BUILD_INFO_SUB_ACTIONS
);

export const DocumentCenterActions = createAction(
  types.DOCUMENT_CENTER_ACTION,
  types.DOCUMENT_CENTER_SUB_ACTIONS
);

export const LegacyActions = createSetterAction(
  types.LEGACY_ACTION,
  types.LEGACY_SUB_ACTIONS
);

export const LoadCodeVal = createAsyncAction(types.CODE_VAL_ACTION);

export const StoreCodeVal = createAction(
  types.CODE_VAL_ACTION,
  types.STORE_CODE_VAL_ACTION
);

export const LoadUserStations = createAsyncAction(types.USER_STATIONS_ACTION);

export const StoreUserStations = createAction(
  types.USER_STATIONS_ACTION,
  types.STORE_USER_STATIONS_ACTION
);

export const LoadHelpLink = createAsyncAction(types.HELP_LINK_ACTION);

window.ifxReactDependency.Actions = {
  ...window.ifxReactDependency.Actions,
  LayoutActions,
  LegacyActions,
};
//- temp action to test fetch abort
export const AbortActions = createAction(types.ABORT, types.STOP_FETCH_CALL);
//usage example
//LayoutActions.SET_SIDEBAR_CLOSED_ENABLE(true) -> {type:'LAYOUT_SIDEBAR_CLOSED_ENABLE',payload:true}
//LayoutActions.SET_FULLSCREEN_ENABLE(true) -> {type:'LAYOUT_FULLSCREEN_ENABLE',payload:true}
