import React, {
  forwardRef,
  useRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { IFXCheckbox } from "../../..";
import { omit } from "../../../../../..";

export const IFXCheckboxCol = forwardRef((props, ref) => {
  //console.log("IFXCheckboxCol:", props);
  const innerRef = useRef();
  const [checked, setChecked] = useState(
    props.value === null ? false : props.value
  );

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return checked;
      },
    };
  });

  useEffect(() => {
    window.setTimeout(() => {
      //console.log(innerRef);
      try {
        innerRef.current.focus();
        //setChecked(!checked);
      } catch (e) {}
    }, 100);
  }, []);

  const onChange = v => {
    if (props.onChange) props.onChange(!checked, props);
    setChecked(!checked);
  };

  const _props = omit(props, [
    "valueFormatted",
    "eParentOfValue",
    "getValue",
    "setValue",
    "refreshCell",
    "addRowCompListener",
    "addRenderedRowListener",
    "keyPress",
    "charPress",
    "colDef",
    "rowIndex",
    "columnApi",
    "stopEditing",
    "eGridCell",
    "parseValue",
    "formatValue",
    "agGridReact",
    "frameworkComponentWrapper",
    "reactContainer",
    "value",
    "cellStartedEdit",
    "column",
    "node",
    "data",
    "api",
  ]);

  const id = `${props.colDef.field.replace(/\./g, "_")}_${props.rowIndex}`;
  return (
    <IFXCheckbox
      label=""
      {..._props}
      ref={innerRef}
      id={id}
      checked={checked}
      onChange={onChange}
    />
  );
});
