import React, { memo } from "react";
import { IFXGridRowActionIconButton } from "../../..";

export const ActionButton = memo(
  ({ data, params, onClick, includeCondition = true, ...action }) => {
    let _includeCondition = true;
    if (typeof includeCondition === "boolean") {
      _includeCondition = includeCondition;
    } else if (typeof includeCondition === "function") {
      _includeCondition = includeCondition(data);
    } else if (typeof includeCondition === "string") {
      _includeCondition =
        data[includeCondition] !== undefined ? data[includeCondition] : true;
    }

    if (!_includeCondition) {
      return null;
    } else {
      return (
        <IFXGridRowActionIconButton
          {...action}
          onClick={e => {
            onClick
              ? onClick(e, data, params, action)
              : console.error("No OnClick() provided for action", action);
          }}
        />
      );
    }
  }
);

export const ActionCol = memo(({ data, actions = [] }) => {
  return (
    <>
      <div className="float-left action-col-container">
        {actions.map((props, i) => (
          <ActionButton data={data} {...props} key={i} />
        ))}
      </div>
    </>
  );
});
