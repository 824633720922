import { createAction as reduxCreateAction } from "redux-actions";
import { AJAX_ACTIONS } from "./common/actionTypes";

//This creates ACTIONS with prefix 'SET_'
export const createSetterAction = (type, actionTypes) => {
  const Action = {
    type,
  };
  Object.keys(actionTypes).forEach(key => {
    //console.log(`${type}_${actionTypes[key]}`);
    Action[`SET_${actionTypes[key]}`] = reduxCreateAction(
      `${type}_${actionTypes[key]}`
    );
  });
  //console.log(Action);

  return Action;
};

export const createAction = (type, actionTypes, prefix = true) => {
  const Action = {
    type,
  };
  const _prefix = prefix === true ? `${type}_` : "";
  Object.keys(actionTypes).forEach(key => {
    //console.log(`${type}_${actionTypes[key]}`);
    Action[actionTypes[key]] = reduxCreateAction(
      `${_prefix}${actionTypes[key]}`
    );
  });
  //console.log(Action);
  return Action;
};

//This creates ASYNC ACTIONS using AJAX_ACTIONS useful for Epics
export const createAsyncAction = (type, actionTypes = AJAX_ACTIONS) => {
  // console.log(type);
  const Action = {
    type,
    isFetching: false,
  };
  Object.keys(actionTypes).forEach(key => {
    //console.log(`${type}_${actionTypes[key]}`);
    Action[`${actionTypes[key]}`] = reduxCreateAction(
      `${type}_${actionTypes[key]}`
    );
  });
  //console.log(Action);

  return Action;
};
