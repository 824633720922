import React, { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IFXAccordionHeaderIconButton,
  isEmptyOrNull,
} from "@ifx-react/ifx-react-core";
import { LoadHelpLink } from "../../../store/actions/common/actionCreators";

export const HelpLink = React.memo(({ currentPage }) => {
  const dispatch = useDispatch();

  const helpLinkData = useSelector(
    state => state.CommonReducer.HelpLinkReducer
  );

  const carrierId = useSelector(
    state => state.PortalReducer.UserContextReducer.selectedAirline?.id || null
  );

  const { employerType } = useSelector(
    state => state.PortalReducer.UserContextReducer.userInfo
  );

  useEffect(() => {
    if (carrierId != null) {
      dispatch(
        LoadHelpLink.REQUEST({
          carrierId,
          userType: employerType,
        })
      );
    } else {
      dispatch(LoadHelpLink.RESET());
    }
  }, [carrierId]);

  const viewHelpLink = useMemo(() => {
    const filterData = helpLinkData.filter(
      item =>
        !currentPage?.hideHelpLinkMenu &&
        item?.module?.toLowerCase() ===
          currentPage?.helpModuleTitle?.toLowerCase() &&
        item?.function?.toLowerCase() ===
          currentPage?.helpFunctionTitle?.toLowerCase()
    );

    if (filterData.length === 1) {
      return filterData[0];
    } else if (filterData.length > 1) {
      return filterData.find(item => {
        if (item.carrierId && item.userType) {
          return item;
        } else if (item.carrierId && isEmptyOrNull(item.userType)) {
          return item;
        } else if (isEmptyOrNull(item.carrierId) && item.userType) {
          return item;
        } else {
          return item;
        }
      });
    }
  }, [helpLinkData, currentPage]);

  return viewHelpLink && viewHelpLink?.externalURL ? (
    <div className="page-help-link">
      <a href={viewHelpLink.externalURL} target="_blank">
        <IFXAccordionHeaderIconButton
          icon="info"
          name="Click here for help on this page"
          iconSource="svg"
          size="sm"
          tooltipProps={{
            placement: "left",
          }}
        />
      </a>
    </div>
  ) : null;
});
