import { PortalTasks } from "../../../../../const/portal/PortalTaskConsts";

export const ReportsMenu = {
  dashboard: {
    authorize: {
      hasAnyTask: [
        PortalTasks.ReportCatererList,
        PortalTasks.ReportCateringService,
        PortalTasks.ReportChargeType,
        PortalTasks.ReportDishOverflow,
        PortalTasks.ReportDishWithOutOverflow,
        PortalTasks.ReportInvoiceDetails,
        PortalTasks.ReportInvoiceMealServed,
        PortalTasks.ReportInvoicePayment,
        PortalTasks.ReportItemOverview,
        PortalTasks.ReportItemsServed,
        PortalTasks.ReportMealDish,
        PortalTasks.ReportMealOrder,
        PortalTasks.ReportMealWastage,
        PortalTasks.ReportNoDishPrice,
        PortalTasks.ReportOrderMealServed,
        PortalTasks.ReportSPMLConsumption,
        PortalTasks.ReportSpecDesc,
        PortalTasks.ReportSpecMealType,
        PortalTasks.ReportSpecPricable,
        PortalTasks.ReportSpecPrice,
        PortalTasks.ReportNonMatchACTypeShip,
        PortalTasks.ReportApprovedIngredient,
        PortalTasks.ReportApprovedRecipe,
        PortalTasks.ReportApprovedMenu,
        PortalTasks.ReportInvValidation,
        PortalTasks.ReportSPMT,
        PortalTasks.ReportCrewMeal,
        PortalTasks.ReportDownloadCrewMeal,
        PortalTasks.ReportAgingReport,
        PortalTasks.ReportCancelledFltSOS,
        PortalTasks.ReportRemittance,
        PortalTasks.ReportCompareReport,
        PortalTasks.ReportAllRejectionReport,
        PortalTasks.ReportMonthlySPML,
        PortalTasks.ReportCompareBrdFltCount,
        PortalTasks.ReportMenuRotation,
        PortalTasks.ReportInvDetailSummary,
        PortalTasks.ReportMenuReport,
        PortalTasks.ReportUnusedFltTag,
        PortalTasks.ReportInternationalLocation,
        PortalTasks.ReportTimeToDL,
        PortalTasks.ReportEGMApp,
        PortalTasks.IA42InvoiceDetails,
        PortalTasks.ReportMissingPrice,
        PortalTasks.ReportPennyPrice,
        PortalTasks.ReportZeroPrice,
      ],
    },
    icon: "report",
    title: "REPORTS",
    to: "/app/reports/dashboard",
    source: "/app/reports/",
  },
  menu: [],
};
