import { createStore, applyMiddleware, compose } from "redux";
import RootReducer from "./reducers";
import rootEpic from "./epics";
import epicMiddleware from "./epicMiddleware";

const getEnhancer = () => {
  //console.log(process.env.NODE_ENV);
  if (process.env.NODE_ENV === "production") {
    return param => param;
  } else {
    // add support for Redux dev tools
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }
};

export default function configureStore() {
  const composeEnhancers = getEnhancer();

  const store = createStore(
    RootReducer,
    composeEnhancers(applyMiddleware(epicMiddleware))
  );
  epicMiddleware.run(rootEpic);
  //console.log(store.getState());
  window.ifxReactDependency.store = store;
  return store;
}
