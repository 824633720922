import { ESVTasks } from "../../../../../const/esv/ESVTaskConsts";

export const ServiceValidationMenu = {
  dashboard: {
    icon: "invoices",
    title: "INVOICES",
    source: "/app/esv/",
    authorize: { hasTask: ESVTasks.ServiceValidator },
  },
  menu: [
    {
      icon: "pe-7s-browser",
      label: "Dashboard",
      to: "/app/esv/dashboard",
    },
    {
      icon: "pe-7s-browser",
      label: "Invoice",
      authorize: {
        hasAnyTask: [
          ESVTasks.BrowseInvoicePeriods,
          ESVTasks.CreateMiscellaneousInvoice,
          ESVTasks.ListRecurringInvoiceTemplate,
          ESVTasks.ViewRAPRequests,
          ESVTasks.SearchRemittance,
          ESVTasks.BrowseInvoicePeriodsArch,
        ],
      },
      content: [
        {
          label: "Search Invoice",
          to: "/app/esv/manageInvoice/detailedsearch",
          authorize: { hasTask: ESVTasks.BrowseInvoicePeriods },
        },
        {
          label: "Search Invoicing Period",
          to: "/app/esv/manageinvoice/invoicingPeriod",
          authorize: { hasTask: ESVTasks.BrowseInvoicePeriods },
        },
        {
          label: "Create Misc Invoice",
          to: "/app/esv/manageInvoice/createMiscInvoice",
          authorize: { hasTask: ESVTasks.CreateMiscellaneousInvoice },
        },
        {
          label: "Recurring Invoice Template",
          to: "/app/esv/invoice/recurrenceInvoice",
          authorize: { hasTask: ESVTasks.ListRecurringInvoiceTemplate },
        },
        {
          label: "Remittance",
          to: "/app/esv/manageInvoice/remittance",
          authorize: { hasTask: ESVTasks.SearchRemittance },
        },
        {
          label: "RAP Requests",
          to: "/app/esv/manageInvoice/rapRequests",
          authorize: { hasTask: ESVTasks.ViewRAPRequests },
        },
        {
          label: "Generate Payment Report",
          to: "/app/esv/manageInvoice/generatePmntReport",
          authorize: { hasTask: ESVTasks.PMNTRPTDOWNLOAD },
        },
        {
          label: "Search Archived Invoice",
          to: "/app/esv/manageInvoice/search/archive",
          authorize: { hasTask: ESVTasks.BrowseInvoicePeriodsArch },
        },
      ],
    },
    {
      icon: "pe-7s-browser",
      label: "Invoicing Rules",
      authorize: {
        hasAnyTask: [
          ESVTasks.BrowseRuleCategories,
          ESVTasks.AddRule,
          ESVTasks.SearchRules,
          ESVTasks.BrowseInvoiceVariables,
          ESVTasks.BrowseCategorization,
        ],
      },
      content: [
        {
          label: "Rule Variables",
          to: "/app/esv/rules/ruleVariables",
          authorize: { hasTask: ESVTasks.BrowseInvoiceVariables },
        },
        {
          label: "Rule Categories",
          to: "/app/esv/rules/ruleCategories",
          authorize: { hasTask: ESVTasks.BrowseRuleCategories },
        },
        {
          label: "Search Rules",
          to: "/app/esv/rules/searchRules",
          authorize: { hasTask: ESVTasks.SearchRules },
        },
        {
          label: "Create Rule",
          to: "/app/esv/rules/create",
          authorize: { hasTask: ESVTasks.AddRule },
        },
        {
          label: "Search Categorization Rules",
          to: "/app/esv/rules/categorization",
          authorize: { hasTask: ESVTasks.BrowseCategorization },
        },
      ],
    },
    {
      icon: "pe-7s-browser",
      label: "Administration",
      authorize: {
        hasAnyTask: [
          ESVTasks.ManageApprovalLimits,
          ESVTasks.ManageInvoiceTags_Categories,
          ESVTasks.ManageError_RejectionCodes,
          ESVTasks.ManageInvoicingPeriods,
          ESVTasks.ManageCarrierCatering_StationProperties,
          ESVTasks.ManageCarrierProperties,
          ESVTasks.ManageChargeCategory,
          ESVTasks.ManagePriceAlterationMethod,
        ],
      },
      content: [
        {
          label: "Invoicing Periods",
          to: "/app/esv/manageInvoice/manageInvoicePeriods",
          authorize: { hasTask: ESVTasks.ManageInvoicingPeriods },
        },
        {
          label: "Charges",
          to: "/app/esv/charge/category",
          authorize: { hasTask: ESVTasks.ManageChargeCategory },
        },
        {
          label: "Price Alteration Method",
          to: "/app/esv/managecharges/priceAlterationMethod",
          authorize: { hasTask: ESVTasks.ManagePriceAlterationMethod },
        },
        {
          label: "Invoice Tags",
          to: "/app/esv/administration/invoiceTags",
          authorize: {
            hasTask: ESVTasks.ManageInvoiceTags_Categories,
          },
        },
        {
          label: "Error Codes",
          to: "/app/esv/administration/rejectionCodes",
          authorize: {
            hasTask: ESVTasks.ManageError_RejectionCodes,
          },
        },
        {
          label: "Approval Limits",
          to: "/app/esv/administration/approvalLimits",
          authorize: { hasTask: ESVTasks.ManageApprovalLimits },
        },
        {
          label: "Invoice Parameters",
          to: "/app/esv/administration/invParameters",
          authorize: { hasTask: ESVTasks.ManageCarrierProperties },
        },
        {
          label: "Station Invoice Parameters",
          to: "/app/esv/administration/stationProperties",
          authorize: {
            hasTask: ESVTasks.ManageCarrierCatering_StationProperties,
          },
        },
      ],
    },
  ],
};
