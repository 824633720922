import React from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import { format, parse } from "date-fns";
import { CalenderContainer } from "./IFXDatePicker";
import cx from "classnames";
import moment from "moment-timezone";
import { ISO_DATE_FORMAT } from "../../utils";

const DEFAULT_PROPS = {
  className: "IFXDateTimePicker",
  dateFormat: "MM/dd/yyyy HH:mm",
  timeFormat: "HH:mm",
  timeIntervals: 15,
  timeCaption: "Time",
  showMonthDropdown: true,
  showYearDropdown: true,
  useShortMonthInDropdown: true,
  showTimeSelect: true,
  highlightDates: [new Date()],
  size: "sm",
  serializeFormat: "MM/dd/yyyy HH:mm",
  serializeDateFormat: "MM/dd/yyyy",
  serializeTimeFormat: "HH:mm",
  isSerialized: false,
  autoComplete: "off",
  dropdownMode: "scroll",
  isClearable: true,
};

export const IFXDateTimePicker = ({
  required,
  isISOSerialized = false,
  ...props
}) => {
  const _props = { ...DEFAULT_PROPS, ...props };
  const {
    selected,
    isSerialized,
    serializeFormat,
    serializeDateFormat,
    serializeTimeFormat,
  } = _props;

  const onChange = v => {
    if (v != null) {
      if (isSerialized) {
        v = {
          serialized: format(v, _props.serializeFormat),
          serializedDate: format(v, _props.serializeDateFormat),
          serializedTime: format(v, _props.serializeTimeFormat),
          dateValue: v,
        };
      } else if (isISOSerialized) {
        v = moment(v).format(ISO_DATE_FORMAT);
      }
    }
    if (_props.onChange) _props.onChange(v);
  };

  let _selected = selected;
  if (selected != null) {
    if (isSerialized) {
      if (selected.serialized) {
        _selected = parse(selected.serialized, serializeFormat, new Date());
      } else if (selected.serializedDate && selected.serializedTime) {
        _selected = parse(
          `${selected.serializedDate} ${selected.serializedTime}`,
          `${serializeDateFormat} ${serializeTimeFormat}`,
          new Date()
        );
      }
    } else if (isISOSerialized) {
      _selected = moment(selected).toDate();
    }
  }

  return (
    <Form.Control
      {..._props}
      className={cx(DEFAULT_PROPS.className, _props.className, {
        "field-mandatory": required,
      })}
      as={DatePicker}
      onChange={onChange}
      selected={_selected}
      popperContainer={CalenderContainer}
      isClearable={_props.disabled ? false : _props.isClearable}
    />
  );
};

IFXDateTimePicker.propTypes = {
  isSerialized: PropTypes.bool,
  serializeFormat: PropTypes.string,
  serializeDateFormat: PropTypes.string,
  serializeTimeFormat: PropTypes.string,
};
