import { CommonToastConsts } from "../../const/common/toastConsts"

export const convertArrayToFormSerialized = (paramKey, values) => {
  return values
    .map((val, i) => ({ [`${paramKey}[${i}]`]: val }))
    .reduce((out, cp) => ({ ...out, ...cp }), {});
};

export const globalErrorHandler = (error, ifxErrorToast=()=>{} ,errorCallback=()=>{}) => {
  if (error?.name === "AjaxTimeoutError"){
    ifxErrorToast(CommonToastConsts.TIMEOUT);
  }
  console.error(error);
  errorCallback();
}