import React from "react";
import { IFXFormLayout } from "../../../components/AppComponents/IFXFormLayout";
import {
  IFXButton,
  IFXAccordion,
  IFXFieldWrapper,
  IFXTextBox,
  IFXAutoSuggest,
  IFXDateRangePicker,
  IFXTimeRangePicker,
  IFXTable,
} from "../../../components/AppComponents";

const onClear = () => {};

export const FormLayoutDemo = () => {
  return (
    <>
      <MyDemoForm />
    </>
  );
};

const MyDemoForm = () => {
  return (
    <>
      <IFXAccordion title="Section 1">
        <IFXFormLayout submitButton clearCallBack={onClear}>
          <IFXFieldWrapper
            type="group" //TODO need to check why group is required now that we dont have inputgroup
            label="Departure Station"
            controlId="deptStation"
            name="deptStation"
            colProps={{ md: 6 }}
          >
            <IFXAutoSuggest
              required
              multiple={true}
              fieldType="station"
              params={{
                TaskName: "eSOViewSmartOrderDash",
              }}
              onChange={v => {}}
            />
          </IFXFieldWrapper>
          <IFXFieldWrapper
            type="group"
            label="Destination Station"
            controlId="destStation"
            name="destStation"
            colProps={{ md: 6 }}
          >
            <IFXAutoSuggest
              required
              multiple={true}
              fieldType="station"
              params={{
                TaskName: "eSOViewSmartOrderDash",
              }}
              onChange={v => {}}
            />
          </IFXFieldWrapper>
          <IFXFieldWrapper
            type="range"
            colProps={{ span: 2, md: 6 }}
            label={["Range Date From", "Range Date To"]}
            controlId={["fromDateId", "toDateId"]}
            name={["rangeDateFrom", "rangeDateTo"]}
          >
            <IFXDateRangePicker required onChange={v => {}} />
          </IFXFieldWrapper>
          <IFXFieldWrapper
            type="range"
            colProps={{ xs: 12, sm: 12, md: 12, lg: 6 }}
            label={["From Time", "To Time"]}
            controlId={["fromTimeDVId", "toTimeDVd"]}
            name={["fromTimeName", "toTimeName"]}
          >
            <IFXTimeRangePicker onChange={v => {}} />
          </IFXFieldWrapper>
        </IFXFormLayout>
      </IFXAccordion>
      <IFXAccordion title="Section 2">
        <IFXFormLayout>
          <IFXFieldWrapper label="A/C Type" controlId="actype">
            <IFXTextBox required name="actype" onChange={e => {}}></IFXTextBox>
          </IFXFieldWrapper>
        </IFXFormLayout>
      </IFXAccordion>
      <IFXFormLayout
        submitButton
        clearCallBack={onClear}
        footerActionIdx={1}
        footerActions={[
          <IFXButton type="save" />,
          <IFXButton type="update" />,
          <IFXButton type="download" />,
          <IFXButton type="clear" name="Reset" />,
          <IFXButton type="download" />,
          <IFXButton type="download" />,
          <IFXButton type="download" />,
          <IFXButton type="download" />,
          <IFXButton type="download" />,
          <IFXButton type="download" />,
          <IFXButton type="download" />,
          <IFXButton type="download" />,
          <IFXButton type="download" />,
          <IFXButton type="download" />,
          <IFXButton type="download" />,
          <IFXButton type="download" />,
          <IFXButton type="download" />,
          <IFXButton type="download" />,
          <IFXButton type="download" />,
        ]}
        footerProps={{ enableBottomMargin: true, marginClassName: "mt-0" }}
      />
      <IFXAccordion title="Custom Table">
      <div className="mt-2">
        <IFXTable />
      </div>
      </IFXAccordion>
     
    </>
  );
};
