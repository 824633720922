import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { IFXAutoSuggest } from "../../../components/AppComponents";
import { IFXFieldWrapper } from "../../../components/AppComponents/IFXFieldWrapper";

export const AutoSuggestCard = () => {
  const defaultFilterElements = {
    carriers: [
      {
        id: "1189321015",
        value: "DL",
      },
    ],
    stations: [
      {
        id: "209591",
        value: "ATHHHHHHH01",
      },
      {
        id: "209601",
        value: "ATLLLLLLL01",
      },
      {
        id: "20960",
        value: "ATL",
      },
      {
        id: "209511",
        value: "ASE",
      },
    ],
  };
  const [filterElements, setfilterElements] = useState(defaultFilterElements);

  return (
    <>
      <Card className="main-card mb-3">
        <Card.Body>
          <Card.Title>Auto Suggest 1</Card.Title>
          <IFXFieldWrapper
            type="autosuggest"
            label="Stations"
            controlId="stations"
            alwaysFloat={(() => filterElements.stations.length > 0)()}
          >
            <IFXAutoSuggest
              multiple={true}
              fieldType="station"
              params={{
                TaskName: "eSOViewSmartOrderDash",
              }}
              onChange={v => {
                console.log(v);
                setfilterElements({ ...filterElements, stations: v });
              }}
              selected={filterElements.stations}
            />
          </IFXFieldWrapper>
          <Card.Title className="mt-3">Auto Suggest 2</Card.Title>
          <IFXFieldWrapper
            label="Carriers"
            controlId="carriers"
            alwaysFloat={(() => filterElements.carriers.length > 0)()}
            type="autosuggest"
          >
            <IFXAutoSuggest
              fieldType="carrier"
              onChange={v => {
                console.log(v);
                setfilterElements({ ...filterElements, carriers: v });
              }}
              selected={filterElements.carriers}
            />
          </IFXFieldWrapper>
          <Card.Title className="mt-3">
            Auto Suggest - redux with func
          </Card.Title>
          <IFXFieldWrapper
            label="Carriers Redux func"
            controlId="carriersRedux"
            alwaysFloat={(() => filterElements.carriers.length > 0)()}
            type="autosuggest"
          >
            <IFXAutoSuggest
              reduxSelector={state =>
                state.PortalReducer.UserContextReducer.userInfo.carrierList
              }
              optionsConverter={options => {
                console.log("optionsConverter", options);
                const _options = options.map((item, i) => ({
                  id: item.id.toString(),
                  value: item.iataCode,
                  display: `${item.iataCode} - ${item.airlineName} redux`,
                }));
                console.log("optionsConverter _options", _options);
                return _options;
              }}
              onChange={v => {
                setfilterElements({ ...filterElements, carriers: v });
              }}
              selected={filterElements.carriers}
            />
          </IFXFieldWrapper>
          <Card.Title className="mt-3">
            Auto Suggest - redux with path
          </Card.Title>
          <IFXFieldWrapper
            label="Carriers Redux path"
            controlId="carriersRedux2"
            alwaysFloat={(() => filterElements.carriers.length > 0)()}
            type="autosuggest"
          >
            <IFXAutoSuggest
              reduxSelector="PortalReducer.UserContextReducer.userInfo.carrierList"
              optionsConverter={options => {
                console.log("optionsConverter", options);
                const _options = options.map((item, i) => ({
                  id: item.id.toString(),
                  value: item.iataCode,
                  display: `${item.iataCode} - ${item.airlineName} redux`,
                }));
                console.log("optionsConverter _options", _options);
                return _options;
              }}
              onChange={v => {
                setfilterElements({ ...filterElements, carriers: v });
              }}
              selected={filterElements.carriers}
            />
          </IFXFieldWrapper>
          <Card.Title className="mt-3">
            Auto Suggest - redux with path
          </Card.Title>
          <IFXFieldWrapper
            label="Carriers In Memory"
            controlId="carriersInMemory"
            alwaysFloat={(() => filterElements.carriers.length > 0)()}
            type="autosuggest"
          >
            <IFXAutoSuggest
              inMemory
              options={[
                {
                  id: "1189321015",
                  value: "DL",
                  display: "DL - Delta Air Lines InMemory",
                },
                {
                  id: "1189321017",
                  value: "QF",
                  display: "QF - IFX-QF:Qantas InMemory",
                },
                {
                  id: "1189321024",
                  value: "SK",
                  display: "SK - IFX-SK:SAS InMemory",
                },
              ]}
              onChange={v => {
                setfilterElements({ ...filterElements, carriers: v });
              }}
              selected={filterElements.carriers}
            />
          </IFXFieldWrapper>
        </Card.Body>
      </Card>
    </>
  );
};
