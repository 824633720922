import PropTypes from "prop-types";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { FormContext, useForm } from "react-hook-form";
import { validationRulesMap } from "../../const";
import { IFXFieldWrapper } from "../AppComponents/IFXFieldWrapper";

const getFormScema = (formScemaRegister, register) => {
  if (formScemaRegister) {
    function getRegister(ParaName) {
      return register(
        { name: ParaName },
        {
          required: true,
        }
      );
    }
    return () => {
      formScemaRegister.map(arg => getRegister(arg.key));
    };
  }
};

//-----------------------------------------
// Generic Form Validation Component
//-----------------------------------------
export const IFXFormValidation = React.memo(
  forwardRef(
    (
      {
        children,
        onSubmit,
        manualValidation,
        stopManualValClb,
        customValidationRules = {},
        mode = "onSubmit",
        reValidateMode = "onChange",
        defaultValues,
        /* for backward compatability start */
        render,
        formScemaRegister = [],
        enableGlobalError = false,
        globalRuleKey = "groupFieldKey",
        /* for backward compatability end */
        ...props
      },
      ref
    ) => {
      const methods = useForm({
        mode,
        reValidateMode,
        defaultValues,
        ...props,
      });

      const [isSubmitting, setIsSubmitting] = useState(false);

      /* for backward compatability start */
      const { register, errors, setValue, setError, clearError } = methods;
      const oldFormParams = {
        ifxRegister: register,
        ifxErrors: errors,
        ifxSetValue: setValue,
        ifxSetError: setError,
        ifxClearError: clearError,
        ...methods,
      };

      useEffect(getFormScema(formScemaRegister, register), [
        formScemaRegister,
        register,
      ]);

      useEffect(() => {
        if (formScemaRegister)
          formScemaRegister.map(arg =>
            setTimeout(() => setValue(arg.key, arg.value))
          );
      }, [formScemaRegister, setValue]);

      /* End Backward Compatability end */

      const { handleSubmit, triggerValidation } = methods;

      useEffect(() => {
        if (isSubmitting) {
          let timer;
          timer = setTimeout(() => {
            setIsSubmitting(false);
          }, 1500);
          return () => {
            clearTimeout(timer);
          };
        }
      }, [isSubmitting]);

      const _onSubmit = useCallback(
        e => {
          e.stopPropagation();
          e.preventDefault();

          if (!isSubmitting) {
            setIsSubmitting(true);
            return handleSubmit(onSubmit)(e);
          }
        },
        [onSubmit, isSubmitting]
      );

      // // triger manual validation
      if (manualValidation) {
        (async () => {
          const result = await triggerValidation();
          stopManualValClb();
        })();
      }
      return (
        <FormContext
          {...methods}
          validationRulesMap={{
            ...validationRulesMap,
            ...customValidationRules,
          }}
        >
          <form ref={ref} onSubmit={_onSubmit} className="ifx-form-validation">
            {enableGlobalError && (
              <IFXFieldWrapper
                className="ifx-hidden"
                controlId="groupField"
                name="groupField"
                rules={["groupRule"]}
                ruleKey={`groupField_${globalRuleKey}`}
                marginClassName="m-0"
              >
                <input type="hidden" />
              </IFXFieldWrapper>
            )}
            {children || (render && render(oldFormParams))}
          </form>
        </FormContext>
      );
    }
  )
);

IFXFormValidation.propTypes = {
  onSubmit: PropTypes.func,
  manualValidation: PropTypes.bool,
  stopManualValClb: PropTypes.func,
  customValidationRules: PropTypes.object,
  render: PropTypes.func,
};
