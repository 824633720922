import React, { forwardRef } from "react";

export const AppFooter = React.memo(
  forwardRef((props, ref) => {
    return (
      <div className="app-footer" ref={ref}>
        <div className="app-footer__inner">
          <div className="app-footer-left">Copyright &copy; Paxia 2020</div>
          <div className="app-footer-right"></div>
        </div>
      </div>
    );
  })
);
