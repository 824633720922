export default {
  EsoCommonReducer: {
    statusInfo: [],
    systemPaxFigure: {},
    soCustomization: {},
  },
  dashboardFilterCriteria: {},
  AcSeatCfgModalReducer: { modal: false },
  PaxSearchRuleReducer: null,
  PollingPoliciesReducer: null,
};
