import React, { useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IFXModal,
  IFXModalBody,
  IFXModalHeader,
  IFXModalFooter,
  IFXButton,
  IFXAutoSuggest,
  IFXFormValidation,
  ifxJsonAjax,
  IFXFieldWrapper,
  CoreValidationRuleCodes,
  useObservableCallback,
  useToast,
  IFXButtonGroup,
  IFXModalAlertFooter,
  isEmptyOrNull,
} from "@ifx-react/ifx-react-core";
import { Subject } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { useFormContext } from "react-hook-form";
import { IFXUserDatePicker } from "../../../components/common/DatePickers";
import { subDays, format, addYears } from "date-fns";
import { UserRegistrationContext } from "./UserRegistration";
import { CREATE_ACCESS_PROFILE_URL } from "../../../const/portal/endpoints";
import { LayoutActions } from "../../../store/actions/common/actionCreators";
import { CommonToastConsts } from "../../../const/common/toastConsts";

const getAddAccessProfileObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax.post(CREATE_ACCESS_PROFILE_URL, params).pipe(
        map(xhrResponse => xhrResponse),
        catchError(error => {
          console.error("Error in Add Access Profile", error);
          errorHandler(error);
          return [];
        })
      )
    )
  );

export const AddAccessProfileModal = ({
  onHide,
  show,
  reloadGrid,
  userAccessProfiles,
}) => {
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();

  const { ifxErrorToast } = useToast();

  const { userData } = useContext(UserRegistrationContext);

  const defaultValues = {
    effDate: {
      dateValue: new Date(),
      serialized: format(new Date(), "MM/dd/yyyy"),
    },
    expDate: {
      dateValue: addYears(new Date(), 10),
      serialized: format(addYears(new Date(), 10), "MM/dd/yyyy"),
    },
  };

  const addAccessProfile$ = useMemo(() => {
    return getAddAccessProfileObj$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        ifxErrorToast(CommonToastConsts.ERROR);
        onHide();
      },
    });
  }, []);

  const createAccessProfile = formData => {
    console.log(formData);
    dispatch(
      LayoutActions.SET_LOADER_SHOW({
        loaderText: "Loading...",
      })
    );
    const alreadyExistData = formData.accessProfile.find(item => {
      return userAccessProfiles.find(profile => {
        if (
          profile.accessProfileId == item.id &&
          profile.expDateLong >
            new Date(formData.effDate.dateValue).setHours(0, 0, 0, 0)
        ) {
          return true;
        }
      });
    });
    if (!isEmptyOrNull(alreadyExistData)) {
      setErrorMsg("This Access Profile overlaps with same Access Profile.");
      dispatch(LayoutActions.SET_LOADER_HIDE());
      return;
    }
    addAccessProfile$.next({
      userId: userData.userId,
      userName: userData.userName,
      accessProfilesList: formData.accessProfile,
      // credentialSetsList: formData.credentialSet,
      effDate: formData.effDate.serialized || null,
      expDate: formData.expDate.serialized || null,
    });
  };

  useObservableCallback(
    addAccessProfile$,
    response => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      onHide();
      reloadGrid();
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      onHide();
    }
  );

  return (
    <>
      <IFXModal show={show} onHide={onHide} size="md">
        <IFXModalHeader>Add Access Profile</IFXModalHeader>
        <IFXFormValidation
          onSubmit={createAccessProfile}
          defaultValues={defaultValues}
        >
          <IFXModalBody>
            <AccessProfileForm userData={userData} />
          </IFXModalBody>
          <IFXModalFooter>
            <IFXModalAlertFooter message={errorMsg} errorColProps={{ xs: 8 }}>
              <IFXButtonGroup>
                <IFXButton type="save" />
                <IFXButton type="cancel" name="Cancel" onClick={onHide} />
              </IFXButtonGroup>
            </IFXModalAlertFooter>
          </IFXModalFooter>
        </IFXFormValidation>
      </IFXModal>
    </>
  );
};

const AccessProfileForm = ({ userData }) => {
  const { watch } = useFormContext();
  const effDate = watch("effDate");
  const expDate = watch("expDate");
  // const accessProfile = watch('accessProfile');

  return (
    <>
      <IFXFieldWrapper
        label="Access Profile"
        controlId="accessProfile"
        name="accessProfile"
        rules={[CoreValidationRuleCodes.required]}
      >
        <IFXAutoSuggest
          serviceName="autoSuggestController"
          fieldType="addAccessProfile"
          multiple
          params={{
            userId: userData.userId,
          }}
        />
      </IFXFieldWrapper>
      {/* <IFXFieldWrapper
                label="Credential Set"
                controlId="credentialSet"
                name="credentialSet"
                rules={[CoreValidationRuleCodes.required]}
                multiple
            >
                <IFXAutoSuggest
                    serviceName="autoSuggestController"
                    fieldType="addCredentialSet"
                    params={{
                        userId: userData.userId,
                        accProfileId: accessProfile && accessProfile.length && accessProfile[0].id
                    }}
                />
            </IFXFieldWrapper> */}
      <IFXFieldWrapper
        type="datepicker"
        label="Eff date"
        controlId="effDate"
        name="effDate"
        rules={[CoreValidationRuleCodes.required]}
      >
        <IFXUserDatePicker
          minDate={subDays(new Date(), 0)}
          maxDate={
            expDate && expDate.dateValue && subDays(expDate.dateValue, 0)
          }
        />
      </IFXFieldWrapper>
      <IFXFieldWrapper
        type="datepicker"
        label="End date"
        controlId="expDate"
        name="expDate"
        rules={[CoreValidationRuleCodes.required]}
      >
        <IFXUserDatePicker
          minDate={
            effDate && effDate.dateValue
              ? subDays(effDate.dateValue, 0)
              : subDays(new Date(), 0)
          }
        />
      </IFXFieldWrapper>
    </>
  );
};
