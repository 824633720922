import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BreadCrumbActions } from "../../../store/actions/common/actionCreators";
import { BREADCRUMB_ITEMS } from "../../../const/portal/breadcrumbItems";

export const IFXNavWrapper = ({
  uniqueId = null,
  item = BREADCRUMB_ITEMS.DEFAULT,
  children /* ,reset = false */,
}) => {
  //console.log("In IFXNavWrapper");

  const dispatch = useDispatch();

  useEffect(() => {
    //console.log("key:" + key);
    //if (reset) {
    dispatch(BreadCrumbActions.RESET_ADD_ITEM(item));
    /*  } else {
      dispatch(BreadCrumbActions.ADD_ITEM(item));
    } */
  }, [dispatch, item, uniqueId]);

  return <>{children}</>;
};
