import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { ifxJsonAjax, useToast } from "@ifx-react/ifx-react-core";
import { throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { VERIFY_ACCOUNT_URL } from "../../../const/portal/endpoints";
import { LayoutActions } from "../../../store/actions/common/actionCreators";

const verifyUserAccount$ = params =>
  ifxJsonAjax.post(VERIFY_ACCOUNT_URL, params).pipe(
    map(xhrResponse => {
      return xhrResponse.response;
    }),
    catchError(error => {
      console.log(error);
      return throwError(error);
    })
  );

export const ActivateAccount = withRouter(props => {
  const {
    location: { search },
  } = props;
  const userVerInfo = search
    .replace("?username=", "")
    .replace("verificationCode=", "")
    .split("&");
  const userName = userVerInfo[0];
  const verificationCode = decodeURIComponent(userVerInfo[1]);
  const { ifxInfoToast, ifxErrorToast } = useToast();
  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    dispatch(
      LayoutActions.SET_LOADER_SHOW({
        loaderText: "Loading...",
      })
    );
    const params = {
      userName: userName,
      verificationCode: verificationCode,
    };
    const verifyAccount = verifyUserAccount$(params).subscribe(
      response => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        ifxInfoToast({
          content: response,
        });
        history.push("/login");
      },
      error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        if (
          error.response &&
          error.response.messageCode === "EMAIL_NOTSUCCESS"
        ) {
          ifxErrorToast({
            content: `Email Verification for your account ${userName} has not been successful.
              Please contact your administrator.`,
          });
        }
        history.push("/login");
      },
      () => {
        history.push("/login");
        verifyAccount.unsubscribe();
      }
    );
  }, []);

  return null;
});
