import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  IFXModal,
  IFXModalBody,
  IFXModalHeader,
  IFXModalFooter,
  IFXButton,
  IFXFieldWrapper,
  IFXTextBox,
  IFXFormLayout,
  IFXFormValidation,
  CoreValidationRuleCodes,
  IFXButtonGroup,
  IFXAccordion,
  ifxJsonAjax,
  useObservableCallback,
  IFXAlert,
} from "@ifx-react/ifx-react-core";
import { Subject } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { GET_SECRET_QUESTION_URL } from "../../../const/portal/endpoints";
import { LayoutActions } from "../../../store/actions/common/actionCreators";

const getSecretQuestion$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax
        .post(`${GET_SECRET_QUESTION_URL}?strUserName=${params.userName}`)
        .pipe(
          map(xhrResponse => {
            return xhrResponse.response || "";
          }),
          catchError(error => {
            console.error("Error in Reset Password", error);
            errorHandler(error.response || {});
            return [];
          })
        )
    )
  );

const defaultValues = { userName: "" };

const ResetPasswordForm = ({ onHide }) => {
  return (
    <>
      <IFXModalBody disableBackground>
        <IFXAccordion title="" isOpen={true} enabled={true} isAccordion={false}>
          <IFXFormLayout allFieldsColProps={{ lg: 12 }}>
            <IFXFieldWrapper
              label="User Name"
              controlId="userName"
              name="userName"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXTextBox maxLength={25} />
            </IFXFieldWrapper>
          </IFXFormLayout>
        </IFXAccordion>
      </IFXModalBody>
      <IFXModalFooter>
        <div>
          <div className="reset-password-info mt-2">
            If you require technical assistance with this web site, please feel
            free to contact our Help Desk using one of the methods listed below:
          </div>
          <div className="mt-2 reset-password-contact">
            Phone: (+1) 571 370 9700
          </div>
          <div className="reset-password-contact">
            Email:{" "}
            <a href="mailto:support@paxiasolutions.com">
              support@paxiasolutions.com
            </a>
          </div>
          <IFXButtonGroup>
            <IFXButton type="submit" name="Next" />
            <IFXButton type="cancel" onClick={onHide} />
          </IFXButtonGroup>
        </div>
      </IFXModalFooter>
    </>
  );
};

export const ResetPassword = ({
  show,
  onHide,
  setSecretData,
  setVerifySecretDataModal,
}) => {
  const [emailNotMatchError, setEmailNotMatchError] = useState(false);

  const dispatch = useDispatch();

  const secretQuestion$ = useMemo(() => {
    return getSecretQuestion$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        if (error.messageCode === "USER_NAME_NOTEXISTS") {
          setEmailNotMatchError(true);
          return;
        }
        onHide();
      },
    });
  }, []);

  const handleOnSubmit = formValues => {
    emailNotMatchError && setEmailNotMatchError(false);
    dispatch(
      LayoutActions.SET_LOADER_SHOW({
        loaderText: "Loading...",
      })
    );
    secretQuestion$.next({
      userName: formValues.userName,
    });
  };

  useObservableCallback(
    secretQuestion$,
    response => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      if (response.secretquestionData) {
        onHide();
        setVerifySecretDataModal(true);
        setSecretData(response);
      }
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
    }
  );

  return (
    <>
      <IFXModal show={show} onHide={onHide} size="md">
        <IFXModalHeader>Reset Password</IFXModalHeader>
        {emailNotMatchError && (
          <IFXAlert variant="danger">
            The User Name you have entered is not valid. Please enter a valid
            User Name.
          </IFXAlert>
        )}
        <IFXFormValidation
          defaultValues={defaultValues}
          onSubmit={handleOnSubmit}
        >
          <ResetPasswordForm onHide={onHide} />
        </IFXFormValidation>
      </IFXModal>
    </>
  );
};
