import initialState from "./initialState";

export function SearchSpecReducer(
  state = initialState.searchSpecParams,
  action
) {
  switch (action.type) {
    case "SEARCH_SPEC_REQUEST":
      return action.payload;

    default:
      return state;
  }
}
