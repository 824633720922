import initialState from "./initialState";

export function ErrorCodesReducer(state = initialState.ErrorCodesInfo, action) {
  switch (action.type) {
    case "SEARCH_ERRORCODE_SELECTION_REQUEST": {
      return {
        ...state,
        errorCodeValues: action.payload,
        isSearchTriggered: true,
      };
    }
    default:
      return state;
  }
}
