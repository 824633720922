import React from "react";
import { PlainReadOnlyCell } from "./EditableFacade";
import { IFXTooltip } from "../../../IFXTooltip";

export const TextRendererWithTooltips = React.memo(
  ({ value, tooltipProps }) => {
    return (
      <IFXTooltip placement="bottom" {...tooltipProps}>
        <div>
        <PlainReadOnlyCell value={value} />
        </div>
      </IFXTooltip>
    );
  }
);
