import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { LegacyActions } from "../../store/actions/common/actionCreators";

export const IFXLegacyRedirect = props => {
  const dispatch = useDispatch();
  const legacyRedirectProps = useSelector(state => {
    return state.CommonReducer.LegacyReducer;
  });
  const { url, hash, ...state } = legacyRedirectProps;
  //console.log('legacy Redirect props 1 ', legacyRedirectProps);
  useEffect(() => {
    //console.log('legacy Redirect props 2 ', legacyRedirectProps);
    if (legacyRedirectProps && url)
      dispatch(LegacyActions.SET_RESET_LEGACY_STATE());
  }, [legacyRedirectProps]);
  return (
    <>
      {legacyRedirectProps && url && (
        <Redirect
          push
          to={{
            pathname: url,
            state,
            hash,
          }}
        />
      )}
    </>
  );
};
