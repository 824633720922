import React, { useState, useEffect, memo, useRef } from "react";
import { IFXCheckbox } from "../../..";

const SELECT_ALL_MESSAGE = {
  ALL_ROWS: count => `All ${count} rows were selected`,
  ONLY_ROWS: count => `Only ${count} rows were selected`,
};

export const HeaderSelectAll = memo(props => {
  const {
    api,
    setSelectAll,
    selectAll = false,
    agGridReact: {
      gridOptions: { setSelectAllMessage },
    },
    idFieldKey = "id",
  } = props;
  // console.log("HeaderSelectAll props", api, props);

  const [checked, setChecked] = useState(selectAll);
  const [isSelectionChanged, setIsSelectionChanged] = useState(false);
  const [unselectRecord, setUnselectRecord] = useState([]);
  const headerCheckBoxRef = useRef();

  const clearSelection = () => {
    headerCheckBoxRef.current.indeterminate = false;
    setSelectAll(false);
    setChecked(false);
    setSelectAllMessage("");
    setUnselectRecord([]);
  };

  const onSelectionChanged = e => {
    // console.log("onSelectionChanged::e", e);
    setIsSelectionChanged(true);
    headerCheckBoxRef.current.indeterminate = true;

    const x = api.getSelectedNodes()?.length;
    if (x > 0) {
      if (x === api.getDisplayedRowCount()) {
        setSelectAllMessage(
          SELECT_ALL_MESSAGE.ALL_ROWS(api.getDisplayedRowCount())
        );
        headerCheckBoxRef.current.indeterminate = false;
        setSelectAll(true);
        setChecked(true);
      } else {
        setSelectAllMessage(SELECT_ALL_MESSAGE.ONLY_ROWS(x));
        setSelectAll(false);
      }
    } else {
      clearSelection();
    }
  };

  const onPaginationChanged = e => {
    // console.log("unselectRecord row", unselectRecord);
    const unSelectRecordTemp = [...unselectRecord];
    if (api.getSelectedNodes().length == 0) {
      clearSelection();
      unSelectRecordTemp = [];
    }
    if (checked) {
      api.forEachNode(row => {
        if (
          !(
            unSelectRecordTemp.length &&
            unSelectRecordTemp.includes(row.data[idFieldKey])
          )
        ) {
          row.setSelected(true, false, true);
        }
      });
      setTimeout(() => {
        if (isSelectionChanged) {
          const x = api.getSelectedNodes()?.length;
          setSelectAllMessage(SELECT_ALL_MESSAGE.ONLY_ROWS(x));
        } else {
          setSelectAllMessage(
            SELECT_ALL_MESSAGE.ALL_ROWS(api.getDisplayedRowCount())
          );
        }
      });
    }
  };

  const onRowSelected = ({ node, data }) => {
    if (checked && !node.selected) {
      setUnselectRecord([...unselectRecord, data[idFieldKey]]);
    }
  };

  useEffect(() => {
    if (!checked) setSelectAllMessage("");
  }, [checked]);

  useEffect(() => {
    api.addEventListener("selectionChanged", onSelectionChanged);
    api.addEventListener("paginationChanged", onPaginationChanged);
    api.addEventListener("rowSelected", onRowSelected);
    return () => {
      api.removeEventListener("selectionChanged", onSelectionChanged);
      api.removeEventListener("paginationChanged", onPaginationChanged);
      api.removeEventListener("rowSelected", onRowSelected);
    };
  }, [api, setSelectAll, checked, isSelectionChanged, unselectRecord]);

  const onChange = v => {
    setIsSelectionChanged(false);
    headerCheckBoxRef.current.indeterminate = false;
    const _checked = !checked;
    if (_checked) {
      api.forEachNode(row => {
        row.setSelected(_checked, false, true);
      });
    } else {
      api.forEachNode(row => {
        row.setSelected(false, false, true);
      });
    }
    setSelectAll(_checked);
    setChecked(_checked);
    setTimeout(() => {
      if (!_checked) {
        setUnselectRecord([]);
      }
    });
    if (_checked && api.getDisplayedRowCount() != 0) {
      setSelectAllMessage(
        SELECT_ALL_MESSAGE.ALL_ROWS(api.getDisplayedRowCount())
      );
    }
  };

  return (
    <div className="header-select-all">
      <div className="float-left">
        <IFXCheckbox
          ref={headerCheckBoxRef}
          label={props.displayName}
          id="header_select_all_chk"
          checked={checked}
          onChange={onChange}
        />
      </div>
    </div>
  );
});
