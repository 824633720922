import initialState from "./initialState";

export function PriceGroupReducer(
  state = initialState.priceGroupParams,
  action
) {
  switch (action.type) {
    case "SEARCH_PRICE_GROUP_REQUEST":
      return {
        ...state,
        data: { ...action.payload },
      };
    case "LOAD_PRICEGROUP_STATION_REQUEST":
      return {
        ...state,
        priceGrpStation: action.payload,
      };
    default:
      return state;
  }
}
