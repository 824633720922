import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

export const IFXMultiSelectSwap = props => {
  const inputList = useRef([]);

  const [getRgtVal, setRgtVal] = useState([]);

  function handleChange(e) {
    const id = e.target.id;
    if (id) {
      if (getRgtVal.some(item => item.id === id)) {
        inputList.current[id].className = "";
        const data = getRgtVal.filter(item => item.id !== id);
        setRgtVal(data);
        props.rightSideArray(data);
      } else {
        inputList.current[id].className = "active-custom";
        const data1 = props.data.filter(item => item.id === id);
        const mergeArray = [...getRgtVal, ...data1];
        setRgtVal(mergeArray);
        props.rightSideArray(mergeArray);
      }
    }
  }

  return (
    <>
      <Form.Group controlId="exampleForm.ControlSelect2">
        <Form.Label>{props.containerName}</Form.Label>
        <ul className="ifx-custom-ul"   onClick={e => handleChange(e)}>
          {props.data.map(item => (
            <li
              key={item.id}
              id={item.id}
              ref={el => (inputList.current[item.id] = el)}
            >
              {item.display}
            </li>
          ))}
        </ul>
        {/* <Form.Control
            as="select"
            multiple
            onChange={e => props.handleChangeEvent(e)}
          >
            {props.data.map(item => (
              <option key={item.id} value={item.value}>
                {item.display}
              </option>
            ))}
          </Form.Control> */}
      </Form.Group>
    </>
  );
};

PropTypes.prototype = {
  containerName: PropTypes.string,
  rightSideArray: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    })
  ),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    })
  ),
  swap: PropTypes.string,
};
