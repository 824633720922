import React, { memo, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { IFXIcons } from "@ifx-react/ifx-react-core";

export const IFXMenuItem = withRouter(
  memo(props => {
    const {
      config,
      config: { dashboard = {}, menu = [] },
      setSelectedMenu,
      setHideOnMouseLeave,
      setRedirectUrl,
      scrollbarRef,
      id,
      //checked = false,
      setIconClicked,
      iconClicked,
      source,
      title,
      location: { pathname },
    } = props;

    let { to = "/" } = dashboard;
    if (to && Array.isArray(to) && to.length > 0) {
      to = to[0].to;
    }
    const handleUpdateScroll = useCallback(
      e => {
        if (scrollbarRef && scrollbarRef.current)
          scrollbarRef.current._ps.element.scrollTop = 0;
      },
      [scrollbarRef]
    );
    const clickOnMenuItem = useCallback(
      e => {
        if (menu?.length > 0) {
          if (iconClicked === title) {
            setHideOnMouseLeave(true);
            setIconClicked(null);
          } else {
            setSelectedMenu(config);
            setIconClicked(title);
            setHideOnMouseLeave(false);
            setRedirectUrl(null);
          }
        } else {
          setHideOnMouseLeave(true);
          setRedirectUrl(dashboard.to);
          setIconClicked(null);
        }
      },
      [dashboard, menu, config, title]
    );
    const onChange = useCallback(
      ({ target: { value } }) => setIconClicked(value),
      []
    );
    return (
      <>
        {dashboard.icon && (
          <div
            className={`ifx-menu-icon-wrapper cur-pointer${(iconClicked ===
              title &&
              " ifx-menu-active") ||
              (iconClicked === null &&
                pathname.startsWith(source) &&
                " ifx-menu-matched") ||
              ""}`}
            onClick={handleUpdateScroll}
          >
            <div
              className="ifx-menu-icon cur-pointer"
              onClick={clickOnMenuItem}
            >
              <span>
                <IFXIcons size="md" iconSource="svg" icon={dashboard.icon} />
              </span>
            </div>
            {iconClicked !== title && (
              <div className="ifx-menu-title">{dashboard.title}</div>
            )}
          </div>
        )}
      </>
    );
  })
);
