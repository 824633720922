import React, { useState } from "react";
import { IFXFormLayout } from "../../../components/AppComponents/IFXFormLayout";
import {
  IFXButton,
  IFXAccordion,
  IFXFieldWrapper,
  IFXTextBox,
  IFXAutoSuggest,
  IFXDateRangePicker,
  IFXTimeRangePicker,
  IFXFormValidation,
  IFXDatePicker,
  IFXTimePicker,
  IFXCheckbox,
  IFXRadioButton,
  IFXFieldGroup,
  IFXModal,
  IFXModalHeader,
  IFXModalBody,
  IFXModalFooter,
  IFXRichTextEditor,
  IFXDateTimePicker,
  IFXNumberTextBox2,
  IFXFloatingFooter,
  IFXPageBody,
  IFXFloatingHeader,
  IFXNumberRangeTextBox,
  IFXDaysOfOperations,
  IFXPromptIfDirty,
} from "../../../components/AppComponents";
import { useFormContext } from "react-hook-form";
import { CoreValidationRuleCodes } from "../../../const";
import { format } from "date-fns";
import { substitute } from "../../../utils/string";
import { Row, Col } from "react-bootstrap";

const depStationParams = {
  TaskName: "eSOViewSmartOrderDash",
};

//const onClear = () => {};
const defaultValues = {
  specCode: "",
  deptStation: {
    id: "20960",
    value: "ATL",
  },

  destStation: [
    {
      id: "20959",
      value: "ATH",
    },
  ],
  flightDate: {
    serialized: format(new Date(), "MM/dd/yyyy"),
    dateValue: new Date(),
  },
  flightTime: {
    serialized: format(new Date(), "HH:mm"),
    dateValue: new Date(),
  },
  dateRange: null,
  timeRange: {
    from: { serialized: "10:45", dateValue: new Date(1976, 0, 1, 10, 45) },
    to: { serialized: "11:30", dateValue: new Date(1976, 0, 0, 11, 30) },
  },
  isScheduled: true /* have to provide defaultvalue boolean for checkbox */,
  color: "green",
  myDateTimePicker: null,
  rte: "<strong>gaurav khurana</strong>",
  daysOfOperation: [false, false, false, false, false, false, false],
  numberRange: { from: "111", to: "222" },
};

const customValidationRules = {
  noSpcChar: ({ value, label }, regExp) =>
    (regExp.test(value) &&
      substitute("No Special Characters allowed on ${label}", { label })) ||
    undefined,
};

export const FormValidationDemo = React.memo(() => {
  return (
    <>
      <IFXFormValidation
        defaultValues={defaultValues}
        onSubmit={formData => {
          console.log("my onSubmit formData ", formData);
        }}
        customValidationRules={customValidationRules}
      >
        <MyDemoForm />
        <IFXPromptIfDirty />
      </IFXFormValidation>
    </>
  );
});

const MyDemoForm = React.memo(() => {
  const {
    //getValues,// setValue,
    reset,
  } = useFormContext();

  const [showAddPopupOpen, setShowAddPopupOpen] = useState(false);

  const onClear = () => {
    reset({
      specCode: "",
      deptStation: [],
      destStation: [],
      flightDate: null,
      flightTime: null,
      dateRange: null,
      timeRange: null,
      myDateTimePicker: null,
    });
  };

  const onReset = () => {
    reset(defaultValues);
  };

  return (
    <>
      <IFXFloatingHeader>
        <IFXFormLayout
          submitButton={{ type: "save" }}
          clearCallBack={onClear}
          footerProps={{ marginClassName: "mt-2" }}
          footerActions={[
            <IFXButton type="clear" name="Reset" onClick={onReset} />,
            <IFXButton
              name="Add Popup"
              onClick={() => setShowAddPopupOpen(true)}
            />,
          ]}
        />
      </IFXFloatingHeader>
      <IFXPageBody>
        <IFXAccordion title="Section 1">
          <IFXFormLayout>
            <IFXFieldWrapper
              label="Spec Code"
              controlId="specCode"
              name="specCode"
              rules={[
                CoreValidationRuleCodes.required,
                { minLength: 4 },
                { maxLength: [8] },
                //{ minMaxLength: [4, 8] },
                { noSpcChar: /\W/ },
              ]}
            >
              <IFXTextBox />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              type="autosuggest"
              label="Dept Station"
              controlId="deptStation"
              name="deptStation"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXAutoSuggest fieldType="station" params={depStationParams} />
            </IFXFieldWrapper>

            <IFXFieldWrapper
              type="autosuggest"
              label="Destination Station"
              controlId="destStation"
              name="destStation"
              onChange={v => {
                console.log("My onchange", v);
              }}
              //rules={[CoreValidationRuleCodes.required]}
            >
              <IFXAutoSuggest
                multiple={true}
                fieldType="station"
                params={{
                  TaskName: "eSOViewSmartOrderDash",
                }}
              />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              type="datepicker"
              label="Flight Date"
              controlId="flightDate"
              name="flightDate"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXDatePicker />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              type="timepicker"
              label="Flight Time"
              controlId="flightTime"
              name="flightTime"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXTimePicker />
            </IFXFieldWrapper>

            <IFXFieldWrapper
              type="range"
              colProps={{ span: 2, md: 6 }}
              label={["Date Range From", "Date Range To"]}
              controlId={["dateRangeFrom", "dateRangeTo"]}
              name="dateRange"
              rules={[{ [CoreValidationRuleCodes.required]: ["from"] }]}
            >
              <IFXDateRangePicker />
            </IFXFieldWrapper>
            <IFXFieldGroup>
              <Row>
                {["Red", "Green", "Blue"].map(color => (
                  <Col key={color}>
                    <IFXFieldWrapper
                      controlId={`color_${color.toLowerCase()}`}
                      name="color"
                    >
                      <IFXRadioButton
                        key={color}
                        label={color}
                        value={color.toLowerCase()}
                        defaultChecked={
                          defaultValues.color === color.toLowerCase()
                        }
                      />
                    </IFXFieldWrapper>
                  </Col>
                ))}
              </Row>
            </IFXFieldGroup>
            <IFXFieldWrapper
              type="range"
              colProps={{ span: 2, md: 6 }}
              label={["Time Range From", "Time Range To"]}
              controlId={["timeRangeFrom", "timeRangeTo"]}
              name="timeRange"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXTimeRangePicker />
            </IFXFieldWrapper>
            {/* have to provide defaultvalue boolean for checkbox */}
            <IFXFieldWrapper controlId="isScheduled" name="isScheduled">
              <IFXCheckbox label="Is Scheduled" />
            </IFXFieldWrapper>

            <IFXFieldWrapper
              type="datepicker"
              label="Departure DateTime"
              controlId="dateTimePicker"
              name="myDateTimePicker"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXDateTimePicker />
            </IFXFieldWrapper>
          </IFXFormLayout>
        </IFXAccordion>
        <IFXAccordion title="IFXNumberTextBox New">
          <IFXFormLayout>
            <IFXFieldWrapper label="Pax input" controlId="pax1" name="pax1">
              <IFXNumberTextBox2 maxLength={5} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="With Leading zero"
              controlId="num1"
              name="num1"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXNumberTextBox2 allowLeadingZero maxLength={8} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="Price"
              controlId="num2"
              name="num2"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXNumberTextBox2 min={0} decimal={2} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="negativeMinAndMax"
              controlId="num3"
              name="num3"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXNumberTextBox2 min={-9999} max={9999} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="negativeMinAndMaxWithDecimal"
              controlId="num4"
              name="num4"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXNumberTextBox2 min={-9999} max={9999} decimal={3} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              type="numberRange"
              colProps={{ span: 2, md: 6 }}
              label={["Number From", "Number To"]}
              controlId={["numberRangeFrom", "numberRangeTo"]}
              name="numberRange"
              rules={[
                CoreValidationRuleCodes.required,
                CoreValidationRuleCodes.numberRangeCheck,
              ]}
            >
              <IFXNumberRangeTextBox min={0} max={1000} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              type="numberRange"
              colProps={{ span: 2, md: 6 }}
              label={["Flt # From", "Flt # To"]}
              controlId={["fltRangeFrom", "fltRangeTo"]}
              name="fltRange"
              rules={[
                //CoreValidationRuleCodes.required,
                CoreValidationRuleCodes.rangeNumberRequiredWhen,
                CoreValidationRuleCodes.numberRangeCheck,
              ]}
            >
              <IFXNumberRangeTextBox allowLeadingZero maxLength={8} copyTo />
            </IFXFieldWrapper>
            <IFXDaysOfOperations
              name="daysOfOperation"
              title="Days of Operation"
              disableAll={false}
              disableOnly={[1, 2]}
            />
          </IFXFormLayout>
        </IFXAccordion>
        <IFXAccordion title="Rich Text Editor" contentClassName="p-0">
          <IFXFieldWrapper
            marginClassName="m-0"
            controlId={"rte"}
            name="rte"
            colProps={{ lg: 12 }}
          >
            <IFXRichTextEditor />
          </IFXFieldWrapper>
        </IFXAccordion>
      </IFXPageBody>

      {showAddPopupOpen && (
        <MyValidationModal
          show={showAddPopupOpen}
          onHide={() => {
            setShowAddPopupOpen(false);
          }}
        />
      )}
    </>
  );
});

const MyValidationModal = React.memo(({ onHide, show }) => {
  return (
    <IFXModal show={show} onHide={onHide}>
      <IFXFormValidation
        onSubmit={formData => {
          console.log("MyValidationModal onSubmit formData ", formData);
        }}
      >
        <IFXModalHeader>IFX Modal</IFXModalHeader>
        <IFXModalBody>
          <IFXFormLayout>
            <IFXFieldWrapper
              colProps={{ lg: 6, md: 6 }}
              label="Comp. Spec Code"
              controlId="compSpecCode"
              name="compSpecCode"
              rules={[CoreValidationRuleCodes.required, { minLength: 4 }]}
            >
              <IFXTextBox />
            </IFXFieldWrapper>
          </IFXFormLayout>
        </IFXModalBody>
        <IFXModalFooter>
          <IFXButton type="save" />
          <IFXButton type="close" onClick={onHide} />
        </IFXModalFooter>
      </IFXFormValidation>
    </IFXModal>
  );
});
