export default {
  searchSpecParams: {
    serviceType: "initialState",
  },
  searchSpecPriceParams: {
    data: { serviceType: "initialState" },
  },
  UserPriceGroupsReducer: {},
  specTagParams: {
    serviceType: "initialState",
  },
  whereUsedFormInfo: {
    serviceType: "initialState",
  },
  searchContractPriceParams: {
    serviceType: "initialState",
  },
  loadScaleSearchParams: {
    serviceType: "initialState",
  },
  acDependencyParams: {
    serviceType: "initialState",
  },
  auditTrailFormValues: {
    init: "init",
  },
  priceGroupParams: {
    init: "initialState",
  },
  specTypeParams: {
    serviceType: "initialState",
  },
  rotationTypeParams: {
    serviceType: "initialState",
  },
  priceParametersParams: {
    serviceType: "initialState",
  },
  prgFormValues: {
    serviceType: "initialState",
  },
  versionTimelineParams:{
    serviceType: "initialState",
  },
  priceTimelineParams: {
    serviceType: "initialState",
  },
  spmlAssignmentParams: null,
  crwmlAssignmentParams: null,
};
