import { PortalTasks } from "../../../../../const/portal/PortalTaskConsts";

export const IFXHomeMenu = {
  dashboard: {
    icon: "portal_home",
    title: "HOME",
    source: "/app/portal/",
    authorize: {
      hasAnyTask: [PortalTasks.PortalApplication, PortalTasks.AdminMainMenu],
    },
  },
  menu: [
    {
      icon: "pe-7s-browser",
      label: "Dashboard",
      to: "/app/portal/myIFX",
      authorize: {
        hasAnyTask: [PortalTasks.PortalApplication, PortalTasks.AdminMainMenu],
      },
    },
    {
      icon: "pe-7s-browser",
      label: "Document Management",
      to: "/app/portal/docMgmt",
      authorize: { hasTask: PortalTasks.ViewDocument },
    },
    {
      icon: "pe-7s-browser",
      label: "Change Notice",
      to: "/app/portal/notice/change",
      authorize: { hasTask: PortalTasks.SearchNotice },
    },
    /* {
      icon: "pe-7s-browser",
      label: "Contact Directory",
      to: "/app/portal/sample",
      authorize: { hasTask: PortalTasks.SearchContactDirectory },
    }, */
    {
      icon: "pe-7s-browser",
      label: "User Preferences",
      content: [
        {
          label: "Profile",
          to: "/app/portal/user/editProfile",
        },
        {
          label: "Favorites",
          to: "/app/portal/user/favorite",
          authorize: { hasTask: PortalTasks.PortalApplication },
        },
        {
          label: "Links",
          to: "/app/portal/user/link",
          authorize: { hasTask: PortalTasks.UserLinks },
        },
        {
          label: "Email Preferences",
          to: "/app/portal/user/email/preference",
          authorize: { hasTask: PortalTasks.EmailPreference },
        },
      ],
    },
    {
      icon: "pe-7s-browser",
      label: "Master Data",
      authorize: {
        hasAnyTask: [
          PortalTasks.SearchAircraftTypes,
          PortalTasks.SearchStation,
          PortalTasks.maintainCaterer,
          PortalTasks.ViewCaterer,
          PortalTasks.SearchCateringStation,
          PortalTasks.ManageSpmlCrwml,
          PortalTasks.TestPage,
        ],
      },
      content: [
        {
          label: "Aircraft Type",
          to: "/app/portal/aircraftType",
          authorize: { hasTask: PortalTasks.SearchAircraftTypes },
        },
        {
          label: "Station",
          to: "/app/portal/station",
          authorize: { hasTask: PortalTasks.SearchStation },
        },
        {
          label: "Caterer",
          to: "/app/portal/caterer",
          authorize: {
            hasAnyTask: [PortalTasks.maintainCaterer, PortalTasks.ViewCaterer],
          },
        },
        {
          label: "Catering Station",
          to: "/app/portal/cateringStation",
          authorize: { hasTask: PortalTasks.SearchCateringStation },
        },
        {
          label: "SPML/CRWML",
          to: "/app/portal/manageSpmlCrwml",
          authorize: { hasTask: PortalTasks.ManageSpmlCrwml },
        },
        {
          label: "Carrier Properties",
          to: "/app/portal/carrierProps",
          authorize: { hasTask: PortalTasks.TestPage },
        },
        {
          label: "Code Value",
          to: "/app/portal/codeVal",
          authorize: { hasTask: PortalTasks.TestPage },
        },
      ],
    },
    {
      icon: "pe-7s-browser",
      label: "User Administration",
      authorize: { hasTask: PortalTasks.SearchUsers },
      content: [
        {
          label: "Search User",
          to: "/app/portal/user/search",
        },
        {
          label: "Create User",
          to: "/app/portal/user/create",
        },
      ],
    },
    {
      icon: "pe-7s-browser",
      label: "System Administration",
      authorize: { hasTask: PortalTasks.ifxSystemAdministration },
      content: [
        {
          label: "Job Scheduler",
          to: "/app/portal/jobs",
        },
        {
          label: "Manage Help Links",
          to: "/app/portal/manageHelpLinks",
          authorize: { hasTask: PortalTasks.ManageHelpLinks }
        },
      ],
    },
  ],
};
