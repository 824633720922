import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback,
  useEffect,
  useState,
  memo,
} from "react";
import cx from "classnames";

export const HeaderFilterPin = forwardRef((props, ref) => {
  //console.log("props", props);
  const {
    api,
    columnApi,
    displayName,
    showColumnMenu,
    progressSort,
    enableSorting,
    enableMenu,
    column,
    column: {
      pinned,
      colId,
      colDef: { lockPinned, hidePin = false },
    },
  } = props;

  const menuButton = useRef();
  const [filterActive, setFilterActive] = useState(column.filterActive);
  const [currentSort, setCurrentSort] = useState(column.sort);
  const [currentSortOrder, setCurrentSortOrder] = useState();
  const [currentPinned, setCurrentPinned] = useState(pinned);

  const onMenuClick = useCallback(() => {
    showColumnMenu(menuButton.current);
  }, [showColumnMenu]);

  const onPinClick = useCallback(() => {
    if (lockPinned) return;
    let _currentPinned = currentPinned === "left" ? null : "left";

    columnApi.setColumnPinned(colId, _currentPinned);
    setCurrentPinned(_currentPinned);
  }, [columnApi, lockPinned, currentPinned]);

  const onSortRequestClick = useCallback(
    e => {
      if (!enableSorting) return;
      progressSort(e.shiftKey);
    },
    [enableSorting, progressSort]
  );

  const onFilterChanged = useCallback(
    e => {
      const { column } = e;
      setFilterActive(column.isFilterActive());
    },
    [setFilterActive]
  );

  const onSortChanged = useCallback(
    e => {
      //console.log("onSortChanged", e);
      const {
        column: { sort },
      } = e;
      setCurrentSort(sort);
    },
    [setCurrentSort]
  );

  const updateSortOrder = useCallback(
    api => {
      let currentSortList = api.getSortModel();
      if (currentSortList.length > 1) {
        let currentIdx = currentSortList.findIndex(
          item => item.colId === colId
        );
        setCurrentSortOrder(currentIdx + 1);
      } else {
        setCurrentSortOrder(null);
      }
    },
    [colId]
  );

  const onTableSortChanged = useCallback(
    e => {
      updateSortOrder(e.api);
    },
    [updateSortOrder]
  );

  useEffect(() => {
    updateSortOrder(api);
  }, [api, updateSortOrder]);

  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses: () => {
        return [
          "ifx-header-cell ag-cell-label-container ag-header-cell-sorted-none",
        ];
      },
    };
  });

  useEffect(() => {
    column.addEventListener("filterChanged", onFilterChanged);
    column.addEventListener("sortChanged", onSortChanged);
    api.addEventListener("sortChanged", onTableSortChanged);
    return () => {
      column.removeEventListener("filterChanged", onFilterChanged);
      column.removeEventListener("sortChanged", onSortChanged);
      api.removeEventListener("sortChanged", onTableSortChanged);
    };
  }, [api, column, onFilterChanged, onSortChanged, onTableSortChanged]);

  return (
    <>
      <span className="ag-header-icon">
        {!hidePin && !!displayName && (!lockPinned || !!currentPinned) && (
          <span className="ag-icon ag-ifx-icon-pin" onClick={onPinClick}>
            {/* <IFXIcons iconSource="svg" icon="pin" size="xs" /> */}
            <span
              className={cx("ag-icon ag-icon-pin", lockPinned && "pin-locked")}
            ></span>
          </span>
        )}
      </span>
      <div className="ag-header-cell-label" onClick={onSortRequestClick}>
        <span className="ag-header-cell-text">{displayName}</span>
        <span
          className={cx(
            "ag-header-icon ag-filter-icon",
            !filterActive && "ag-hidden"
          )}
        >
          <span className="ag-icon ag-icon-filter"></span>
        </span>
        <span
          className={cx(
            "ag-header-icon ag-sort-order",
            !currentSortOrder && "ag-hidden"
          )}
        >
          {currentSortOrder}
        </span>
        <span
          className={cx(
            "ag-header-icon ag-sort-ascending-icon",
            !(currentSort === "asc") && "ag-hidden"
          )}
        >
          <span className="ag-icon ag-icon-asc"></span>
        </span>
        <span
          className={cx(
            "ag-header-icon ag-sort-descending-icon",
            !(currentSort === "desc") && "ag-hidden"
          )}
        >
          <span className="ag-icon ag-icon-desc"></span>
        </span>
        <span className="ag-header-icon ag-sort-none-icon ag-hidden">
          <span className="ag-icon ag-icon-none"></span>
        </span>
      </div>
      {enableMenu && (
        <span
          ref={menuButton}
          className="ag-header-icon ag-header-cell-menu-button"
          onClick={onMenuClick}
        >
          <span className="ag-icon ag-icon-menu"></span>
        </span>
      )}
    </>
  );
});
