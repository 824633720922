import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { IFXNumberRangePicker } from "../../../components/AppComponents";
import { IFXFieldWrapper } from "../../../components/AppComponents/IFXFieldWrapper";

export const NumberRangePickerCard = () => {
  const [numberRange, setNumberRange] = useState({});
  const [numberRangeValidation, setNumberRangeValidation] = useState(false);
  const [decimalNumberRange, setDecimalNumberRange] = useState({});
  const [
    decimalNumberRangeValidation,
    setDecimalNumberRangeValidation,
  ] = useState(false);
  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title>Number Range</Card.Title>
        <IFXFieldWrapper
          type="range"
          label={["From Number", "To Number"]}
          controlId={["from", "to"]}
          alwaysFloat={[
            (() => numberRange.from != null)(),
            (() => numberRange.to != null)(),
          ]}
          errorMessage="From  should be less than To "
          isInvalid={numberRangeValidation}
        >
          <IFXNumberRangePicker
            selected={numberRange}
            numberonly={true}
            onChange={v => {
              if (v.isInvalid === true) {
                setNumberRangeValidation(true);
              } else {
                setNumberRangeValidation(false);
              }
              setNumberRange(v);
            }}
          />
        </IFXFieldWrapper>
        <Card.Title className="mt-4 mb-4">Decimal Number Range</Card.Title>
        <IFXFieldWrapper
          type="range"
          label={["From Decimal Number", "To Decimal Number"]}
          controlId={["fromDecimal", "toDecimal"]}
          alwaysFloat={[
            (() => decimalNumberRange.from != null)(),
            (() => decimalNumberRange.to != null)(),
          ]}
          errorMessage="From  should be less than To "
          isInvalid={decimalNumberRangeValidation}
        >
          <IFXNumberRangePicker
            selected={decimalNumberRange}
            numberonly={false}
            onChange={v => {
              if (v.isInvalid === true) {
                setDecimalNumberRangeValidation(true);
              } else {
                setDecimalNumberRangeValidation(false);
              }
              setDecimalNumberRange(v);
            }}
          />
        </IFXFieldWrapper>
      </Card.Body>
    </Card>
  );
};
