export const MasterDataTaskConst = {
  SearchACRegistrations: "SearchACRegistrations",
  AddACRegistrations: "AddACRegistrations",
  EditACRegistrations: "EditACRegistrations",
  DeleteACRegistrations: "DeleteACRegistrations",
  AddAircraftTypes: "AddAircraftTypes",
  EditAircraftTypes: "EditAircraftTypes",
  DeleteAircraftTypes: "DeleteAircraftTypes",
  SearchACSeatCfgs: "SearchACSeatCfgs",
  AddACSeatCfgs: "AddACSeatCfgs",
  EditACSeatCfgs: "EditACSeatCfgs",
  DeleteACSeatCfgs: "DeleteACSeatCfgs",
  AddStation: "AddStation",
  DeleteStation: "DeleteStation",
  EditStation: "EditStation",
  AddCateringStation: "AddCateringStation",
  DeleteCateringStation: "DeleteCateringStation",
  EditCateringStation: "EditCateringStation",
  SearchCateringStation: "SearchCateringStation",
  AddSPMLCRWML: "AddSPMLCRWML",
  EditSPMLCRWML: "EditSPMLCRWML",
  DeleteSPMLCRWML: "DeleteSPMLCRWML",
  DownloadSPMLCRWML: "DownloadSPMLCRWML",
  MaintainUserCredentials: "MaintainUserCredentials",
};
