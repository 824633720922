import React from "react";
import { Card, FormGroup, Container, Row, Col } from "react-bootstrap";
import { IFXIcons, IFXSVGTemplates } from "../../../components/AppComponents";

export const CSSOnlyToolTip = () => {
  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title>CSS only Tooltip</Card.Title>
        <FormGroup>
          <Container>
            <Row>Replace title attribute with data-title</Row>
            <Row>
              <Col>
                <button class="ifxtooltip" data-title="Show Bottom">
                  Default
                </button>
              </Col>
              <Col>
                <button class="ifxtooltip top" data-title="Show Top">
                  Top
                </button>
              </Col>
              <Col>
                <button class="ifxtooltip right" data-title="Show Right">
                  Right
                </button>
              </Col>
              <Col>
                <button class="ifxtooltip left" data-title="Show Left">
                  Left
                </button>
              </Col>
              <Col>
                <button class="ifxtooltip bottom" data-title="Show Bottom">
                  Bottom
                </button>
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </Card.Body>
    </Card>
  );
};
