import React, { useEffect, useState, useRef, lazy } from "react";
import { Switch } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Container } from "react-bootstrap";
import ReactResizeDetector from "react-resize-detector";

// Layout

import { AppHeader } from "../layout/AppHeader/";
import { AppSidebar } from "../layout/AppSidebar/";
import { AppFooter } from "../layout/AppFooter/";
import { PageTitle } from "../../../components/common/PageTitle";

import { useSelector, useDispatch } from "react-redux";
import { IFXRoute } from "../../../components/common/IFXRoute";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import {
  LayoutContext,
  useObservableCallback,
} from "@ifx-react/ifx-react-core";
import { IFXSuspense } from "../../../components/common/IFXSuspense";
import { IFXLegacyRedirect } from "../../../components/common/IFXLegacyRedirect";
import { LayoutActions } from "../../../store/actions/common/actionCreators";
import { IFXLoader } from "../../../components/common/IFXLoader";

const Portal = lazy(() =>
  import(/* webpackChunkName: "Portal", webpackPrefetch: true */ "../../Portal")
);
const ESS = lazy(() =>
  import(/* webpackChunkName: "ESS", webpackPrefetch: true */ "../../ESS")
);
const ESM = lazy(() =>
  import(/* webpackChunkName: "ESM", webpackPrefetch: true */ "../../ESM")
);
const ESO = lazy(() =>
  import(/* webpackChunkName: "ESO", webpackPrefetch: true */ "../../ESO")
);
const ESV = lazy(() =>
  import(/* webpackChunkName: "ESV", webpackPrefetch: true */ "../../ESV")
);
const SPMT = lazy(() =>
  import(/* webpackChunkName: "SPMT", webpackPrefetch: true */ "../../SPMT")
);
const Reports = lazy(() =>
  import(
    /* webpackChunkName: "Reports", webpackPrefetch: true */ "../../Reports"
  )
);
const GP = lazy(() =>
  import(/* webpackChunkName: "GP", webpackPrefetch: true */ "../../GP")
);

const resizeDebounceObv = new Subject().pipe(debounceTime(300));
const resizeWidthDebounceObv = new Subject().pipe(debounceTime(200));

export const IfxAppMainContext = React.createContext(null);

export const LOADER_SCOPE_IFXAPP = "ifxApp";

export const IFXAppMain = React.memo(prop => {
  //console.log(prop);
  let {
    match,
    location: { pathname },
  } = prop;
  const [height, setHeight] = useState(200);
  const [menuHeight, setMenuHeight] = useState(475);
  const scrollbarRef = useRef();
  const sideMenuScrollBarRef = useRef();
  const scrollContainerRef = useRef();
  const dispatch = useDispatch();

  const pageTitleRef = useRef();
  const contentContainerRef = useRef();
  const appFooterRef = useRef();

  const { dateFormat: userDateFormat } = useSelector(
    state => state.PortalReducer.UserContextReducer.userInfo
  );

  useObservableCallback(resizeDebounceObv, () => {
    const pageTitleContainer = pageTitleRef.current;
    const contContainer = contentContainerRef.current;
    const footContainer = appFooterRef.current;

    const pageTitleHeight = pageTitleContainer?.offsetHeight || 0;
    const footerHeight = footContainer?.offsetHeight || 0;

    const height =
      window.innerHeight - (contContainer?.offsetTop || 0) - footerHeight;

    const mHeight = height + pageTitleHeight + footerHeight - 1;

    setMenuHeight(mHeight < 299 ? 299 : mHeight);

    //console.log("handleResize:" + height);
    setHeight(height < 200 ? 200 : height);

    handleUpdateScroll();
  });

  useObservableCallback(resizeWidthDebounceObv, width => {
    dispatch(LayoutActions.SET_CONTENT_WIDTH(width));
  });

  const isfullScreenEnabled = useSelector(
    state => state.CommonReducer.LayoutReducer.fullScreenEnable
  );

  const handleResize = e => {
    resizeDebounceObv.next(e);
  };

  const handleUpdateScroll = e => {
    //console.log("handleUpdateScroll updating");
    //console.log("handleUpdateScroll");
    //resizeDebounceObv.next(e);
    scrollbarRef.current.updateScroll();
    sideMenuScrollBarRef?.current &&
      sideMenuScrollBarRef.current.updateScroll();
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    //console.log("on load");
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  if (!userDateFormat) {
    return <IFXLoader enable />;
  }

  return (
    <IfxAppMainContext.Provider value={{ scrollbarRef }}>
      {!isfullScreenEnabled ? <AppHeader /> : null}
      <div className="app-main">
        {!isfullScreenEnabled ? (
          <AppSidebar
            height={menuHeight}
            sideMenuScrollBarRef={sideMenuScrollBarRef}
          />
        ) : null}
        <div className="app-main__outer">
          <div className="app-main__inner">
            {pathname.startsWith("/app/gp") ? (
              <></>
            ) : (
              <>
                <div className="app-main__title">
                  <PageTitle ref={pageTitleRef} />
                </div>
              </>
            )}
            <IFXLegacyRedirect />
            <LayoutContext.Provider value={{ contentHeight: height }}>
              <ReactResizeDetector
                handleWidth
                onResize={width => {
                  resizeWidthDebounceObv.next(width);
                }}
              >
                <Container
                  ref={contentContainerRef}
                  id="contentContainer" //required for legacy
                  fluid
                  className="app-main__content p-0 position-relative"
                  style={{ height: height }}
                >
                  <IFXLoader scope={LOADER_SCOPE_IFXAPP} />
                  <PerfectScrollbar
                    key="ifxAppMainScrollBar"
                    ref={scrollbarRef}
                  >
                    <Container
                      fluid
                      className="p-0 pr-4 mx-2"
                      style={{ height: height - 10 }}
                      ref={scrollContainerRef}
                    >
                      <div className="resize-detect-div">
                        <ReactResizeDetector
                          handleHeight
                          onResize={handleUpdateScroll}
                        >
                          <IFXSuspense>
                            <Switch>
                              <IFXRoute
                                path={`${match.url}/portal`}
                                component={Portal}
                              />
                              <IFXRoute
                                path={`${match.url}/ess`}
                                component={ESS}
                              />
                              <IFXRoute
                                path={`${match.url}/esm`}
                                component={ESM}
                              />
                              <IFXRoute
                                path={`${match.url}/eso`}
                                component={ESO}
                              />
                              <IFXRoute
                                path={`${match.url}/esv`}
                                component={ESV}
                              />
                              <IFXRoute
                                path={`${match.url}/gp`}
                                component={GP}
                              />
                              <IFXRoute
                                path={`${match.url}/Reports`}
                                component={Reports}
                              />
                              <IFXRoute
                                path={`${match.url}/SPMT`}
                                component={SPMT}
                              />
                            </Switch>
                          </IFXSuspense>
                        </ReactResizeDetector>
                      </div>
                    </Container>
                  </PerfectScrollbar>
                </Container>
              </ReactResizeDetector>
            </LayoutContext.Provider>
          </div>
          {!isfullScreenEnabled ? <AppFooter ref={appFooterRef} /> : null}
        </div>
      </div>
    </IfxAppMainContext.Provider>
  );
});
