import React from "react";
import cx from "classnames";
import { FormCheck } from "react-bootstrap";

const DEFAULT_PROPS_CLASS_NAME = "ifx-checkbox";

export const EditableCheckboxFacade = React.memo(props => {
  //console.log("EditableCheckboxFacade:", props);
  const {
    value,
    colDef: { editable = true },
    includeCondition = true,
    data,
  } = props;

  const _editable = typeof editable === "function" ? editable(props) : editable;
  let _includeCondition = true;
  if (typeof includeCondition === "boolean") {
    _includeCondition = includeCondition;
  } else if (typeof includeCondition === "function") {
    _includeCondition = includeCondition(data);
  } else if (typeof includeCondition === "string") {
    _includeCondition =
      data[includeCondition] !== undefined ? data[includeCondition] : true;
  }

  return (
    <div>
      {_includeCondition && (
        <FormCheck
          custom
          type="checkbox"
          defaultChecked={value === null ? false : value}
          label=""
          disabled={!_editable}
          className={cx(DEFAULT_PROPS_CLASS_NAME)}
        />
      )}
    </div>
  );
});
