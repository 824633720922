import { combineReducers } from "redux";
import { EsoCommonReducer } from "./EsoCommonReducer";
import { dashboardFilterCriteriaReducer } from "./dashboardFilterCriteria.reducer";
import { AcSeatCfgModalReducer } from "./acSeatCfgModalReducer";
import { PaxSearchRuleReducer } from "./PaxSearchRuleReducer";
import { PollingPoliciesReducer } from "./PollingPoliciesReducer";
import { SoDashboardJobHistoryReducer } from "./SoDashboardJobHistoryReducer";

const appEsoReducer = combineReducers({
  EsoCommonReducer,
  dashboardFilterCriteriaReducer,
  AcSeatCfgModalReducer,
  PaxSearchRuleReducer,
  PollingPoliciesReducer,
  SoDashboardJobHistoryReducer,
});

export function EsoReducer(state, action) {
  switch (action.type) {
    case "RESET_REDUX": {
      console.log("RESET_REDUX EsoReducer");
      return appEsoReducer(undefined, action);
    }
    default:
      return appEsoReducer(state, action);
  }
}
