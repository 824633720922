import {
  checkRowEditable,
  QUICK_FILTER_FUNCS,
  CUSTOM_SORT_COMPARATOR,
  ColorOptions,
} from "../../../components/AppComponents";
import { format } from "date-fns";

import { statusList } from "../../mockData/dataGridMockData";

const editOnClick = (e, data, param, action) => {
  console.log("data:", data);
  console.log("param:", param);
  console.log("action:", action);
  alert(`'${action.type}' is clicked, see console log for params`);
};

const clearRowOnClick = (e, data, param, action) => {
  console.log("data:", data);
  console.log("param:", param);
  console.log("action:", action);
  alert(`'${action.type}' is clicked, see console log for params`);
};

const checkClearableFn = (data = {}) => {
  if (!data.id || data.id === 0) return true; // allow clear on new rows
  return false;
};

const printOnClick = (e, data, param, action) => {
  console.log("data:", data);
  console.log("param:", param);
  console.log("action:", action);
  alert(`'${action.type}' is clicked, see console log for params`);
};

export const getClientSideGridColumn = ({ editButtonEnabled = true }) => [
  {
    headerName: "Sel",
    headerTooltip: "Sel",
    field: "iconControl",
    checkboxSelection: true,
    editable: false,
    sortable: false,
    suppressMenu: true,
    filter: false,
    pinned: true,
    resizable: false,
    //dragable: false,
    headerCheckboxSelection: true,
    cellRenderer: "IconCol",
    cellRendererParams: {
      isSelectableKey: "isSelectable",
      wrapperClassName: "validation-icon",
    },
    width: 85,
    flex: false,
    lockPinned: true,
    pinned: "left",
  },
  {
    headerName: "Expand",
    editable: false,
    field: "open",
    width: 140,
    headerComponent: "TreeControlHeader",
    cellRenderer: "TreeControlCol",
    lockPinned: true,
    pinned: "left",
  },
  {
    //Number
    headerName: "Flight#",
    headerTooltip: "Flight#",
    field: "flightNo",
    cellRenderer: "EditableFacade",
    cellEditor: "IFXTextBoxCol",
    width: 100,
    flex: false,
    headerComponent: "HeaderFilterPin",
    lockPinned: true,
    pinned: "left",
  },
  {
    //Autosuggest
    headerName: "Leg#",
    headerTooltip: "Leg#",
    field: "legNo",
    width: 90,
    flex: false, // auto width expand
    editable: params => {
      //console.log(params.data);
      const { data = {} } = params;
      const { legNoDisabled = false } = data;
      return checkRowEditable(params) && !legNoDisabled;
    },
    cellRenderer: "EditableAutosuggestFacade",
    cellEditor: "IFXAutoSuggestCol",
    cellEditorParams: { serviceName: "legNo" },
    getQuickFilterText: QUICK_FILTER_FUNCS.IFXAutoSuggestCol,
    //tooltipField: "legNo.value",
    headerComponent: "HeaderFilterPin",
  },
  {
    //Autosuggest
    headerName: "Class",
    headerTooltip: "Class",
    field: "class",
    width: 90,
    flex: false, // auto width expand
    cellRenderer: "EditableAutosuggestFacade",
    cellRendererParams: {
      plainReadOnlyType: true,
    },
    cellEditor: "IFXAutoSuggestCol",
    cellEditorParams: {
      serviceName: "class",
      rowParams: {
        legNo: "legNo.value",
        departureDate: "flightDate.serialized",
      },
    },
    getQuickFilterText: QUICK_FILTER_FUNCS.IFXAutoSuggestCol,
    //tooltipField: "class.value",
  },
  {
    //Date
    headerName: "Flight Date",
    headerTooltip: "Flight Date",
    field: "flightDate",
    width: 140,
    flex: false,
    cellRenderer: "EditableFacade",
    cellEditor: "IFXDatePickerCol",
    comparator: CUSTOM_SORT_COMPARATOR.IFXDatePickerCol,
    valueFormatter: params => {
      //console.log(params);
      return params.value && params.value.dateValue
        ? format(params.value.dateValue, "MM/dd/yyyy")
        : "";
    },
    //tooltipField: "flightDate",
  },
  {
    //Date
    headerName: "Flight Time",
    headerTooltip: "Flight Time",
    field: "flightTime",
    width: 130,
    flex: false,
    editable: params => {
      //console.log(params.data);
      const { data = {} } = params;
      const { flightTimeDisabled = false } = data;

      return checkRowEditable(params) && !flightTimeDisabled;
    },
    cellRenderer: "EditableFacade",
    cellEditor: "IFXTimePickerCol",
    valueFormatter: params => {
      //console.log(params);
      return params.value && params.value.dateValue
        ? format(params.value.dateValue, "HH:mm")
        : "";
    },
    //tooltipField: "flightTime",
  },
  {
    //text
    headerName: "Name",
    headerTooltip: "Name",
    field: "name",
    cellRenderer: "EditableFacade",
    cellRendererParams: {
      plainReadOnlyType: true,
    },
    minWidth: "60",
    cellEditor: "IFXTextBoxCol",
    //tooltipField: "name",
  },
  {
    //text
    headerName: "Description",
    headerTooltip: "Description",
    field: "description",
    cellRenderer: "EditableFacade",
    cellEditor: "IFXTextBoxCol",
    minWidth: "60",
    cellRenderer: "TestRenderer",
    cellRendererParams: {
      className: "testRenderer",
    },
    //tooltipField: "description",
  },
  {
    //text
    headerName: "Label Button",
    headerTooltip: "Label Button",
    field: "name",
    editable: false,
    cellRenderer: "LabelButtonRenderer",
    cellRendererParams: {
      buttonProps: {
        includeCondition: data => !!data.name,
        onClick: (e, data, param, action) => {
          console.log("data:", data);
          console.log("param:", param);
          console.log("action:", action);
          alert(`'Label Button' is clicked, see console log for params`);
        },
      },
    },

    //tooltipField: "description",
  },
  {
    //Dropdown
    headerName: "Status",
    headerTooltip: "Status",
    field: "status",
    editable: params => {
      const { data = {} } = params;
      const { statusDisabled = false } = data;
      return checkRowEditable(params) && !statusDisabled;
    },
    width: 150,
    flex: false,
    cellRenderer: "EditableDropDownFacade",
    cellRendererParams: {
      plainReadOnlyType: true,
    },
    cellEditor: "IFXDropdownCol",
    cellEditorParams: { options: statusList },
    getQuickFilterText: QUICK_FILTER_FUNCS.IFXDropdownCol,
  },
  {
    headerName: "Action",
    headerTooltip: "Action",
    field: "action",
    editable: false,
    cellRenderer: "ActionCol",
    pinned: "right",
    lockPinned: true,
    cellRendererParams: {
      actions: [
        {
          type: "edit",
          onClick: editOnClick,
          params: { myCustomParam: "custom Param Value" },
          includeCondition: editButtonEnabled, // boolean, key for row data or func for task checking
        },
        {
          type: "print",
          onClick: printOnClick,
        },
        {
          type: "clear",
          onClick: clearRowOnClick,
          includeCondition: checkClearableFn, // boolean, key for row data or func with row data
        },
      ],
    },
    //   filter: "agTextColumnFilter",
    //   filterParams: { applyButton: true, resetButton: true },
  },
];
