import React, { forwardRef } from "react";
import cx from "classnames";
//import PropTypes from "prop-types";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { omit } from "../../utils/utils";

const DEFAULT_PROPS = {
  className: "ifx-tooltip",
  controlled: false,
  placement: "right",
  content: <>no content</>,
  hideArrow: false,
  delay: { show: 0, hide: 0 },
};

export const withTooltip = (component, toolTipProps) =>
  React.createElement(IFXTooltip, {
    children: component,
    ...toolTipProps,
  });

export const IFXTooltip = forwardRef((props, ref) => {
  let newProps = { ...DEFAULT_PROPS, ...props };
  const { controlled, content, hideArrow } = newProps;
  newProps = omit(newProps, ["controlled", "hideArrow"]);

  if (controlled) {
    return (
      <>
        <Tooltip
          {...newProps}
          ref={ref}
          className={cx(
            DEFAULT_PROPS.className,
            props.className,
            hideArrow && "hide-arrow"
          )}
        />
      </>
    );
  } else {
    return (
      <>
        {content !== null && content !== "" ? (
          <OverlayTrigger
            flip
            {...newProps}
            overlay={props => (
              <Tooltip
                {...props}
                show={props.show.toString()}
                className={cx(
                  DEFAULT_PROPS.className,
                  props.className,
                  hideArrow && "hide-arrow"
                )}
              >
                {content}
              </Tooltip>
            )}
          >
            {newProps.children}
          </OverlayTrigger>
        ) : (
          <>{newProps.children}</>
        )}
      </>
    );
  }
});
