import initialState from "./initialState";

export function SpecTagReducer(state = initialState.specTagParams, action) {
  switch (action.type) {
    case "SPEC_TAG_REQUEST":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
