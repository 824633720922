import cx from "classnames";
import { includes } from "ramda";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { IFXUserNote } from "./IFXAlert";
import { IFXCheckbox } from "./IFXCheckbox";
import { IFXFieldset2 } from "./IFXFieldset";
import { IFXFieldWrapper } from "./IFXFieldWrapper";

const DEFAULT_PROPS_CLASS_NAME = "ifx-daysofoperation";
const days = [1, 2, 3, 4, 5, 6, 7];
const NOTE = "[Note: 1-Mon through 7-Sun]";

//----------------------------------
// Main IFXDaysOfOperations component
//----------------------------------
export const IFXDaysOfOperations = ({
  className,
  name,
  daysToOperate = days,
  disableAll = false,
  disableOnly = [],
  title,
  colProps = { span: 2, md: 6 },
  prefix = "",
  marginClassName = "mt-ifx-100",
  rules,
  showNote = false,
  ...props
}) => {
  return (
    <>
      <div className={cx(DEFAULT_PROPS_CLASS_NAME, "d-flex", className)}>
        <IFXFieldset2 title={title} marginClassName={marginClassName}>
          <Row className="pr-4">
            {daysToOperate.map((x, i) => (
              <div key={x} className="d-inline-block px-0 pb-3">
                <IFXFieldWrapper
                  colProps={colProps}
                  controlId={`${prefix}-daysOfOperation-${i}`}
                  key={`${prefix}-daysOfOperation-${i}`}
                  name={`${name}[${x - 1}]`}
                  marginClassName="mb-0 mt-0"
                >
                  <IFXCheckbox
                    label={x}
                    {...(((disableAll || includes(x, disableOnly)) && {
                      disabled: true,
                    }) ||
                      {})}
                  />
                </IFXFieldWrapper>
              </div>
            ))}
          </Row>
          {showNote && (
            <Row>
              <Col>
                <IFXUserNote
                  variant="ifx-label"
                  noBackground
                  noBorder
                  marginClassName="mt-1 mb-0"
                  paddingClassName="p-0"
                  contentJustifyClassName="justify-content-center"
                >
                  {NOTE}
                </IFXUserNote>
              </Col>
            </Row>
          )}
        </IFXFieldset2>
      </div>
      {rules && (
        <Row>
          <Col>
            <IFXFieldWrapper
              type="privateGroupError"
              name={`${name}_group`}
              marginClassName="m-0"
              rules={rules}
            >
              <input type="hidden" />
            </IFXFieldWrapper>
          </Col>
        </Row>
      )}
    </>
  );
};
