import { useContext, useEffect } from "react";
import { LayoutContext } from "@ifx-react/ifx-react-core";
import { useSelector } from "react-redux";

export const useContentHeight = ({ delta = 117, minHeight = 200 } = {}) => {
  const layoutContext = useContext(LayoutContext);
  const contentHeight = layoutContext.contentHeight - delta;
  if (contentHeight < minHeight) return minHeight;
  return contentHeight;
};

export const useResponsiveGrid = ref => {
  const { contentWidth } = useSelector(
    state => state.CommonReducer.LayoutReducer
  );

  //console.log("useResponsiveGrid contentWidth", contentWidth);
  useEffect(() => {
    if (ref) ref.current.getInstance().api.sizeColumnsToFit();
  }, [ref, contentWidth]);
};
