import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { IFXDateTimePicker } from "../../../components/AppComponents";
import { IFXFieldWrapper } from "../../../components/AppComponents/IFXFieldWrapper";

export const DateTimePickerCard = () => {
  const [departureDateTime, setDepartureDateTime] = useState(null);
  //   console.log(
  //     "selected  departureDateTime is : " + JSON.stringify(departureDateTime)
  //   );
  const [arrivalDateTime, setArrivalDateTime] = useState({
    serialized: "02282020 1645",
    serializedDate: "02282020 1645",
    serializedTime: "1645",
  });
  //   console.log(
  //     "selected  arrivalDateTime is : " + JSON.stringify(arrivalDateTime)
  //   );

  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title className="mb-4">Date Time Picker</Card.Title>
        <IFXFieldWrapper
          type="group"
          label="Departure Date Time"
          controlId="departureDateTime"
          alwaysFloat={(() => departureDateTime != null)()}
        >
          <IFXDateTimePicker
            selected={departureDateTime}
            onChange={v => {
              setDepartureDateTime(v);
            }}
          />
        </IFXFieldWrapper>

        <Card.Title className="my-4">
          Date Time Picker with Default Value
        </Card.Title>
        <IFXFieldWrapper
          type="group"
          label="Arrival Date Time"
          controlId="arrivalDateTime"
          alwaysFloat={(() => arrivalDateTime != null)()}
        >
          <IFXDateTimePicker
            selected={arrivalDateTime}
            onChange={v => {
              setArrivalDateTime(v);
            }}
            isSerialized={true}
            serializeFormat="MMddyyyy HHmm" // this is optional default is MM/dd/yyyy HH:mm
            serializeTimeFormat="HHmm" // this is optional default is HH:mm
            serializeDateFormat="MMddyyyy" // this is optional default is MM/dd/yyyy
          />
        </IFXFieldWrapper>
      </Card.Body>
    </Card>
  );
};
