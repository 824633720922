import React, { forwardRef } from "react";
import { InputGroup, Form } from "react-bootstrap";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import cx from "classnames";
import { format, parse } from "date-fns";
import { isValidDate } from "../../utils/date";
import { Portal } from "react-overlays";

export const CalenderContainer = ({ children }) => {
  const el = document.body;
  return <Portal container={el}>{children}</Portal>;
};

const DEFAULT_PROPS = {
  className: "ifx-date-picker",
  dateFormat: "MM/dd/yyyy",
  showMonthDropdown: true,
  showYearDropdown: true,
  useShortMonthInDropdown: true,
  highlightDates: [new Date()],
  size: "sm",
  serializeFormat: "MM/dd/yyyy",
  isSerialized: true,
  autoComplete: "off",
  dropdownMode: "scroll",
  enablePrepend: false,
  isClearable: true,
};

export const IFXDatePicker = forwardRef(({ required, ...props }, ref) => {
  const _props = { ...DEFAULT_PROPS, className: "", ...props };
  const {
    inputGroupClassName,
    selected,
    isSerialized,
    serializeFormat,
    enablePrepend,
  } = _props;

  const onChange = v => {
    if (v != null) {
      if (isSerialized)
        v = { serialized: format(v, _props.serializeFormat), dateValue: v };
    }
    if (_props.onChange) _props.onChange(v);
  };

  let _selected = selected;
  if (isSerialized && selected != null) {
    const parseDate = parse(selected.serialized, serializeFormat, new Date());
    _selected = isValidDate(parseDate) ? parseDate : new Date();
  }

  if (enablePrepend) {
    return (
      <>
        <InputGroup
          size={_props.size}
          className={cx(inputGroupClassName, {
            "field-mandatory": required,
          })}
          title="Date"
        >
          <InputGroup.Prepend>
            <div className="input-group-text">
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
          </InputGroup.Prepend>
          <Form.Control
            {..._props}
            ref={ref}
            className={cx(DEFAULT_PROPS.className, _props.className)}
            as={DatePicker}
            selected={_selected}
            onChange={onChange}
            popperContainer={CalenderContainer}
          />
        </InputGroup>
      </>
    );
  } else {
    return (
      <>
        <Form.Control
          {..._props}
          ref={ref}
          className={cx(DEFAULT_PROPS.className, _props.className, {
            "field-mandatory": required,
          })}
          as={DatePicker}
          selected={_selected}
          onChange={onChange}
          popperContainer={CalenderContainer}
          isClearable={_props.disabled ? false : _props.isClearable}
        />
      </>
    );
  }
});

IFXDatePicker.propTypes = {
  isSerialized: PropTypes.bool,
  serializeFormat: PropTypes.string,
  enablePrepend: PropTypes.bool,
};
