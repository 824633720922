export const ESVTasks = {
  ManageApprovalLimits: "ManageApprovalLimits",
  ManageInvoiceTags_Categories: "ManageInvoiceTags/Categories",
  ManageError_RejectionCodes: "ManageError/RejectionCodes",
  ManageCarrierCatering_StationProperties:
    "ManageCarrierCatering-StationProperties",
  ManageCarrierProperties: "ManageCarrierProperties",
  ManageInvoicingPeriods: "ManageInvoicingPeriods",
  ManageChargeCategory: "ManageChargeCategory",
  ManageChargeTypes: "ManageChargeTypes",
  ManagePriceAlterationMethod: "ManagePriceAlterationMethod",
  BrowseRuleCategories: "BrowseRuleCategories",
  DeleteRuleCategories: "DeleteRuleCategories",
  AddRuleCategory: "AddRuleCategory",
  EditRuleCategory: "EditRuleCategory",
  ViewRuleGroups: "ViewRuleGroups",
  AddRuleGroup: "AddRuleGroup",
  EditRuleGroup: "EditRuleGroup",
  DeleteRuleGroups: "DeleteRuleGroups",
  AddRule: "AddRule",
  DeleteRules: "DeleteRules",
  CopyRule: "CopyRule",
  SearchRules: "SearchRules",
  BrowseInvoiceVariables: "BrowseInvoiceVariables",
  BrowseCategorization: "BrowseCategorization",
  BrowseInvoicePeriods: "BrowseInvoicePeriods",
  CreateMiscellaneousInvoice: "CreateMiscellaneousInvoice",
  ListRecurringInvoiceTemplate: "ListRecurringInvoiceTemplate",
  ViewRAPRequests: "ViewRAPRequests",
  SearchRemittance: "SearchRemittance",
  BrowseInvoicePeriodsArch: "BrowseInvoicePeriodsArch",
  PMNTRPTDOWNLOAD: "PMNTRPTDOWNLOAD",
  TestESVTask: "Test",
  RegularViewVendorInvoiceNum: "RegularViewVendorInvoiceNum",
  DSAIASearchResults: "DSAIASearchResults",
  DSExecutiveSearchResults: "DSExecutiveSearchResults",
  DSCatererSearchResults: "DSCatererSearchResults",
  DSASMSearchResults: "DSASMSearchResults",
  DS_INV_NO: "DSInvNo",
  DS_INV_TYPE: "DSInvType",
  DS_INV_STATUS: "DSInvStatus",
  DS_CATERER: "DSCaterer",
  DS_CATERING_STATION: "DSCateringStation",
  DS_FLIGHT_NO: "DSFlightNo",
  DS_DEPARTURE_STATION: "DSDepartureStation",
  DS_DESTINATION_STATION: "DSDestinationStation",
  DS_SERVICE_DATE: "DSServiceDate",
  DS_CREATED_DATE: "DSCreatedDate",
  DS_INV_TOTAL_PMT: "DSInvTotalPmt",
  DS_UPDATED_DATE: "DSUpdatedDate",
  DS_UPDATED_BY: "DSUpdatedBy",
  DS_ERROR_CODES: "DSErrorCodes",
  DS_INV_TAGS: "DSInvTags",
  DS_REQ_TO_RE_INSTATE: "DSReqToReInstate",
  DS_INV_ACTION: "DSInvAction",
  DS_INVOICE_TOTALS_FINAL: "DSInvoiceTotalsFinal",
  DS_INVOICE_TOTALS_ORDERED: "DSInvoiceTotalsOrdered",
  DS_INVOICE_TOTALS_BILLED: "DSInvoiceTotalsBilled",
  DS_INVOICE_TOTALS_CLOSEOUT: "DSInvoiceTotalsCloseOut",
  DSBulkApprove: "DSBulkApprove",
  DSSubInvForValidation: "DSSubInvForValidation",
  DSExpandAllCollapseAll: "DSExpandAllCollapseAll",
  ServiceValidator: "Service Validator",
  DSAIRLINEADMINSEARCHRESULTS: "DSAirlineAdminSearchResults",
  ESVAirlineAdmin: "ESVAirlineAdmin",
  LineItemSerialNo: "LineItemSerialNo",
  LineItemSource: "LineItemSource",
  LineItemServiceItem: "LineItemServiceItem",
  LineItemCabinClass: "LineItemCabinClass",
  LineItemPrice: "LineItemPrice",
  LineItemOrderedQuantity: "LineItemOrderedQuantity",
  LineItemOrderedGross: "LineItemOrderedGross",
  LineItemOrderedCharges: "LineItemOrderedCharges",
  LineItemOrderedNet: "LineItemOrderedNet",
  LineItemBilledQuantity: "LineItemBilledQuantity",
  LineItemBilledGross: "LineItemBilledGross",
  LineItemBilledCharges: "LineItemBilledCharges",
  LineItemBilledNet: "LineItemBilledNet",
  LineItemFinalQuantity: "LineItemFinalQuantity",
  LineItemCloseOutQuantity: "LineItemCloseOutQuantity",
  LineItemFinalGross: "LineItemFinalGross",
  LineItemFinalCharges: "LineItemFinalCharges",
  LineItemFinalNet: "LineItemFinalNet",
  LineItemItemTrackingNo: "LineItemItemTrackingNo",
  LineItemErrorCodes: "LineItemErrorCodes",
  LineItemInvoiceTags: "LineItemInvoiceTags",
  LineItemActions: "LineItemActions",
  ViewBomEvaluation: "viewbomevaluation",
  EsvBufferOverflow: "esvBufferOverflow",
  LineItemCloseOutGross: "LineItemCloseOutGross",
  LineItemCloseOutCharges: "LineItemCloseOutCharges",
  LineItemCloseOutNet: "LineItemCloseOutNet",
  LineItemServiceDescription: "lineItemserviceDescription",
  VeDelLineItem: "VEDelLineItem",
  ESVCaterer: "ESVCaterer",
  ESVAirportStationManager: "ESVAirportStationManager",
  ESVAIAUser: "ESVAIAUser",
  creditDebitEXEReview: "creditDebitEXEReview",
  creditDebitCATReview: "creditDebitCATReview",
  creditDebitAIAReview: "creditDebitAIAReview",
  ESVExecutive: "ESVExecutive",
  VEApprove: "VEApprove",
  RegularViewLineItemDescription: "RegularViewLineItemDescription",
  VILineItemDeleteComments: "VILineItemDeleteComments",
  VILineItemAddComments: "VILineItemAddComments",
  VIViewLineItemComments: "VIViewLineItemComments",
  VEAddLineItem: "VEAddLineItem",
  SpmlAsNormalMeal: "SpmlAsNormalMeal",
  VEViewInvComments: "VEViewInvComments",
  VEAIAReview: "VEAIAReview",
  VEReqReinstate: "VEReqReinstate",
  VESaveAsDraft: "VESaveAsDraft",
  VESubmitForValidation: "VESubmitForValidation",
  VECreateCreDebNote: "VECreateCre/DebNote",
  VECatererReview: "VECatererReview",
  VEExecReview: "VEExecReview",
  VERePriceInvoice: "VERePriceInvoice",
  ViewCarrSumInvNo: "ViewCarSumInvNo",
  MealOrderClsOfSVC: "MealOrderClassOfSVC",
  MealOrderOrderedQtySpml: "MealOrderOrderedQtySpml",
  MealOrderOrderedQtyNormal: "MealOrderOrderedQtyNormal",
  MealOrderBilledQtyNormal: "MealOrderBilledQtyNormal",
  MealOrderBilledQtySpml: "MealOrderBilledQtySpml",
  MealOrderCloseOutQtyNormal: "MealOrderCloseOutQtyNormal",
  MealOrderCloseOutQtySpml: "MealOrderCloseOutQtySpml",
  Detailedinvoicesearch: "Detailedinvoicesearch",
  CreditDebitReject: "CreditDebitReject",
  RegularViewReinstateReqInv: "RegularViewReinstateReqInv",
  RegularApproveReinstateReqInv: "RegularApproveReinstateReqInv",
  RegularRejectReinstateReqInv: "RegularRejectReinstateReqInv",
  VEExecReview: "VEExecReview",
  VEVoid: "VEVoid",
  VESubmit: "VESubmit",
  RegularEditVendorInvoiceNum: "RegularEditVendorInvoiceNum",
  InvBreakDownClassOfSVC: "InvBreakDownClassOfSVC",
  InvBreakDownSVCCat: "InvBreakDownSVCCat",
  InvBreakDownSVCType: "InvBreakDownSVCType",
  InvBreakDownOrderedGross: "InvBreakDownOrderedGross",
  InvBreakDownOrderedTaxes: "InvBreakDownOrderedTaxes",
  InvBreakDownOrderedDiscount: "InvBreakDownOrderedDiscount",
  InvBreakDownOrderedNet: "InvBreakDownOrderedNet",
  InvBreakDownBilledGross: "InvBreakDownBilledGross",
  InvBreakDownBilledTaxes: "InvBreakDownBilledTaxes",
  InvBreakDownBilledDiscount: "InvBreakDownBilledDiscount",
  InvBreakDownBilledNet: "InvBreakDownBilledNet",
  InvBreakDownCloseOutGross: "InvBreakDownCloseOutGross",
  InvBreakDownCloseOutTaxes: "InvBreakDownCloseOutTaxes",
  InvBreakDownCloseOutDiscount: "InvBreakDownCloseOutDiscount",
  InvBreakDownCloseOutNet: "InvBreakDownCloseOutNet",
  InvBreakDownFinalGross: "InvBreakDownFinalGross",
  InvBreakDownFinalTaxes: "InvBreakDownFinalTaxes",
  InvBreakDownFinalDiscount: "InvBreakDownFinalDiscount",
  InvBreakDownFinalNet: "InvBreakDownFinalNet",
  InvBreakDownCurrency: "InvBreakDownCurrency",
  InvTotalsOrdered: "InvTotalsOrdered",
  InvTotalsBilled: "InvTotalsBilled",
  InvTotalsFinal: "InvTotalsFinal",
  InvTotalsServicesAndCharges: "InvTotalsServicesAndCharges",
  AddInvoiceComments: "AddInvoiceComments",
  DeleteInvoiceComments: "DeleteInvoiceComments",
  creditDebitEXEReview: "creditDebitEXEReview",
  creditDebitAIAReview: "creditDebitAIAReview",
  creditDebitCATReview: "creditDebitCATReview",
  createMiscellaneousInvoice: "Create Miscellaneous Invoice",
  MIRePriceInvoice: "MIRePriceInvoice",
  MISubmit: "MISubmit",
  MIApprove: "MIApprove",
  MIVoid: "MIVoid",
  miscCorporateExecutiveReview: "miscCorporateExecutiveReview",
  miscCateringStationExecutiveReview: "miscCateringStationExecutiveReview",
  miscCharterExecutiveReview: "miscCharterExecutiveReview",
  MiscCharterViewVendorInvoiceNum: "MiscCharterViewVendorInvoiceNum",
  MiscCharterEditVendorInvoiceNum: "MiscCharterEditVendorInvoiceNum",
  MiscCateringStationViewVendorInvoiceNum:
    "MiscCateringStationViewVendorInvoiceNum",
  MiscCateringStationEditVendorInvoiceNum:
    "MiscCateringStationEditVendorInvoiceNum",
  MiscCorporateViewVendorInvoiceNum: "MiscCorporateViewVendorInvoiceNum",
  MiscCorporateEditVendorInvoiceNum: "MiscCorporateEditVendorInvoiceNum",
  MICatererReview: "MICatererReview",
  MICreditDebit: "MICreditDebit",
  creditDebitMiscCorporateExecutiveReview:
    "creditDebitMiscCorporateExecutiveReview",
  creditDebitMiscCateringStationExecutiveReview:
    "creditDebitMiscCateringStationExecutiveReview",
  creditDebitMiscCharterExecutiveReview:
    "creditDebitMiscCharterExecutiveReview",
  MIAirlineReview: "MIAirlineReview",
  MIReinstate: "MIReinstate",
  MISaveAsDraft: "MISaveAsDraft",
  MICreate: "MICreate",
  MIClear: "MIClear",
  MiscCorporateViewLineItemDescription: "MiscCorporateViewLineItemDescription",
  MiscCSViewLineItemDescription: "MiscCSViewLineItemDescription",
  MiscCharterViewLineItemDescription: "MiscCharterViewLineItemDescription",
  MILineItemDeleteComments: "MILineItemDeleteComments",
  MILineItemAddComments: "MILineItemAddComments",
  MIViewLineItemComments: "MIViewLineItemComments",
  MILineItemDelete: "MILineItemDelete",
  MIRequestToReinstate: "MIRequestToReinstate",
  SOPreSelectView: "SOPreSelectView",
  viewRuleTask: "ViewRule(s)",
  addRuleTask: "AddRule",
  editRuleTask: "EditRule",
  UploadInvDocuments: "UploadInvDocuments",

  //Station Invoice Parameters
  MCCSPViewProperties: "MCCSPViewProperties",
  MCCSPViewBillPayCurrency: "MCCSPViewBillPayCurrency",
  MCCSPAddBillPayCurrency: "MCCSPAddBillPayCurrency",
  MCCSPDeleteBillPayCurrency: "MCCSPDeleteBillPayCurrency",
  MCCSPEditBillPayCurrency: "MCCSPEditBillPayCurrency",
  MCCSPViewLiability: "MCCSPViewLiability",
  MCCSPAddLibability: "MCCSPAddLibability",
  MCCSPDeleteLiability: "MCCSPDeleteLiability",
  MCCSPEditLiability: "MCCSPEditLiability",
  MCCSPSetInvoiceValid: "MCCSPSetInvoiceValid",
  MCCSPViewChargeValues: "MCCSPViewChargeValues",
  MCCSPAddChargeValue: "MCCSPAddChargeValue",
  MCCSPDeleteChargeValue: "MCCSPDeleteChargeValue",
  MCCSPCopyChargeValue: "MCCSPCopyChargeValue",
  MCCSPEditChargeValue: "MCCSPEditChargeValue",
  MCCSPSaveUpdates: "MCCSPSaveUpdates",
  MCCSPSearchResults: "MCCSPSearchResults",
  IsChargeValueValidDayExist: "IsChargeValueValidDayExist",
  BIPInvPeriodPayHistory: "BIPInvPeriodPayHistory",
  BIPSchePayment: "BIPSchePayment",
  ViewInvDocuments: "ViewInvDocuments",
  VendorInvoiceNumberUpdate: "VendorInvoiceNumberUpdate",
  PE3_FILE_UPLOAD: "PE3_FILE_UPLOAD",
  ViewUpdtInvTab: "ViewUpdtInvTab",
  PaymentRequestsPending: "PaymentRequestsPending",
  ESVAirlineUser: "ESVAirlineUser",
  ESVTaxAdmin: "ESVTaxAdmin",
  ESVPortalAdmin: "ESVPortalAdmin",
  recentlyUpdatedUserName: "recentlyUpdatedUserName",
  ESOApplication: "ESOApplication",
  UploadDeleteInvDocuments: "UploadDeleteInvDocuments",

  //Invoicing Period L2 - Tasks
  INVOICING_PERIOD_FLIGHT_NO: "InvoicingPeriodFlightNo",
  INVOICING_PERIOD_DEPARTURE_STATION: "InvoicingPeriodDepartureStation",
  INVOICING_PERIOD_DESTINATION_STATION: "InvoicingPeriodDestinationStation",
  INVOICING_PERIOD_AIRCRAFT_TYPE: "InvoicingPeriodAircraftType",
  INVOICING_PERIOD_DRAFT: "InvoicingPeriodDraft",
  INVOICING_PERIOD_READY_FOR_AU_REVIEW: "InvoicingPeriodReadyForAUReview",
  INVOICING_PERIOD_RETOTAL_INVOICE: "InvoicingPeriodRetotalInvoice",
  INVOICING_PERIOD_PENDING_VALIDATION: "InvoicingPeriodPendingValidation",
  INVOICING_PERIOD_RETOTAL_IN_PROGRESS: "InvoicingPeriodRetotalInProgress",
  INVOICING_PERIOD_PENDING_CLOSEOUT: "InvoicingPeriodPendingCloseOut",
  INVOICING_PERIOD_PENDING_CATERER_REVIEW:
    "InvoicingPeriodPendingCatererReview",
  INVOICING_PERIOD_PENDING_AIA_REVIEW: "InvoicingPeriodPendingAIAReview",
  INVOICING_PERIOD_PENDING_EXECUTIVE_REVIEW:
    "InvoicingPeriodPendingExecutiveReview",
  INVOICING_PERIOD_VALID: "InvoicingPeriodValid",
  INVOICING_PERIOD_VOID: "InvoicingPeriodVoid",
  INVOICING_PERIOD_TOTAL: "InvoicingPeriodTotal",
  INVOICING_PERIOD_INV_TOTAL_CURR: "InvoicingPeriodInvTotalCurr",
  INVOICING_PERIOD_INV_TOTAL_PMT: "InvoicingPeriodInvTotalPmt",
  INVOICING_PERIOD_INV_TOTAL_PMT_CURR: "InvoicingPeriodInvTotalPmtCurr",
  INVOICING_PERIOD_FINAL_INV_TOTALS: "InvoicingPeriodFinalInvTotals",
  INVOICING_PERIOD_ORDERED_INV_TOTALS: "InvoicingPeriodOrderedInvTotals",
  INVOICING_PERIOD_BILLED_INV_TOTALS: "InvoicingPeriodBilledInvTotals",
  INVOICING_PERIOD_CLOSEOUT_INV_TOTALS: "InvoicingPeriodCloseOutInvTotals",
  INVOICING_PERIOD_ACTION: "InvoicingPeriodAction",
  dashboardPendingReviewTask: "Create Miscellaneous Invoice",

  //Invoicing Period L3 - Tasks
  LIST_INVOICES_INV_NO: "ListInvoicesInvNo",
  LIST_INVOICES_INV_TYPE: "ListInvoicesInvType",
  LIST_INVOICES_INV_STATUS: "ListInvoicesInvStatus",
  LIST_INVOICES_CATERER: "ListInvoicesCaterer",
  LIST_INVOICES_CATERING_STATION: "ListInvoicesCateringStation",
  LIST_INVOICES_FLIGHT_NO: "ListInvoicesFlightNo",
  LIST_INVOICES_AC_TYPE: "ListInvoicesAcType",
  LIST_INVOICES_DEPARTURE_STATION: "ListInvoicesDepartureStation",
  LIST_INVOICES_DESTINATION_STATION: "ListInvoicesDestinationStation",
  LIST_INVOICES_SERVICE_DATE: "ListInvoicesServiceDate",
  LIST_INVOICES_CREATED_DATE: "ListInvoicesCreatedDate",
  LIST_INVOICES_INVTOTAL_PMT: "ListInvoicesInvTotalPmt",
  LIST_INVOICES_UPDATED_DATE: "ListInvoicesUpdatedDate",
  LIST_INVOICES_UPDATED_BY: "ListInvoicesUpdatedBy",
  LIST_INVOICES_ERROR_CODES: "ListInvoicesErrorCodes",
  LIST_INVOICES_INV_TAGS: "ListInvoicesInvTags",
  LIST_INVOICES_FINAL_INV_TOTALS: "ListInvoicesFinalInvTotals",
  LIST_INVOICES_ORDERED_INV_TOTALS: "ListInvoicesOrderedInvTotals",
  LIST_INVOICES_BILLED_INV_TOTALS: "ListInvoicesBilledInvTotals",
  LIST_INVOICES_CLOSEOUT_INV_TOTALS: "ListInvoicesCloseOutInvTotals",
  LIST_INVOICES_ACTION: "ListInvoicesAction",
  //PAM
  PAM_ADD_PAM: "PAMAddPAM",
  PAM_EDIT_PAM: "PAMEditPAM",
  PAM_DELETE_PAM: "PAMDeletePAM",
  //recurring invoice
  LRIT_SEARCH_VIEW: "LRITSearchView",
  LRIT_RESET: "LRITReset",
  LRIT_VIEW_SEARCHRESULTS: "LRITViewSearchResult",
  LRIT_ADD: "LRITAdd",
  LRIT_EDIT: "LRITEdit",
  LRIT_DELETE: "LRITDelete",

  //invoice tags and categories

  INV_TAG_ADD: "InvTagAdd",
  INV_TAG_EDIT: "InvTagEdit",
  INV_TAG_DELETE: "InvTagDelete",
  INV_TAG_CAT_ADD: "InvTagCatAdd",
  INV_TAG_CAT_EDIT: "InvTagCatEdit",
  INV_TAG_CAT_DELETE: "InvTagCatDelete",
  INV_TAG_CAT_VIEW_INV_TAGS: "InvTagCatViewInvTags",

  //Search Rules
  DELETE_RULES: "DeleteRules",
  COPY_RULES: "CopyRule",
  ADD_RULE: "AddRule",

  //Charges
  TAXADMINCHARGES: "PositiveTaskForTaxAdmin",
  MCC_ADD_CHARGE_CATEGORY: "MCCAddChargeCategory",
  MCC_DELETE_CHARGE_CATEGORY: "MCCDeleteChargeCategory",
  MANAGE_CHARGECATEGORY: "Manage ChargeCategory",
  MCC_VIEW_CHARGE_CATEGORY: "MCCViewChargeCategory",
  MCC_EDIT_CHARGE_CATEGORY: "MCCEditChargeCategory",
  MCC_VIEW_CHARGE_TYPES: "MCCViewChargeTypes",
  MCC_ADD_CHARGE_TYPE: "MCCAddChargeType",
  MCC_EDIT_CHARGE_TYPE: "MCCEditChargeType",
  MCC_DELETE_CHARGE_TYPE: "MCCDeleteChargeType",
  MANAGE_CHARGETYPES: "Manage ChargeTypes",
  MCT_CATEGORY_FILTER: "MCTCategoryFilter",
  MCT_VIEW_CHARGE_TYPES: "MCTViewChargeTypes",
  MCT_ADDC_HARGE_TYPE: "MCTAddChargeType",
  MCT_EDIT_CHARGE_TYPE: "MCTEditChargeType",
  MCT_DELETE_CHARGE_TYPE: "MCTDeleteChargeType",
  //Error Codes
  VIEW_ERROR_CODES: "ViewErrorCodes",
  ERROR_CODES_ADD: "ErrorCodesAdd",
  ERROR_CODES_DELETE: "ErrorCodesDelete",
  ERROR_CODES_EDIT: "ErrorCodesEdit",

  DSBulkInvVoid: "DSBulkInvVoid",
};
