import React from "react";
import { ErrorCode } from "./ErrorCode";
import { IFXErrorBoundary } from "../../../components/AppComponents";

function ErrorPage() {
  return (
    <div>
      <p>
        <b>
          Error Page: Click on the numbers to increase the counters.
          <br />
          The counter is programmed to throw error when it reaches 5. Simulating
          JavaScript error in a component.
        </b>
      </p>
      <hr />
      <IFXErrorBoundary>
        <p>
          Below two counters components are inside the same error boundary. If
          one crashes, the error boundary fallback on both components.
        </p>
        <ErrorCode />
        <ErrorCode />
      </IFXErrorBoundary>
      <hr />
      <p>
        Below two counters components are each inside of their own error
        boundary. So if one crashes, the other component is not affected.
      </p>
      <IFXErrorBoundary>
        <ErrorCode />
      </IFXErrorBoundary>
      <IFXErrorBoundary>
        <ErrorCode />
      </IFXErrorBoundary>
    </div>
  );
}

export { ErrorPage };
