import React, {memo} from "react";
import {
  IFXIcons,
  IconStyleOptions,
  ColorOptions,
  IFXTooltip,
  IFXCheckbox,
} from "../../..";
import { omit } from "../../../../../..";

const WRAPPER_CLASSNAME = "table-icon-col";

const DEFAULT_ICON_PROPS = {
  icon: "error",
  iconSource: "svg",
  size: "sm",
  //iconStyle: IconStyleOptions.CIRCLE,
  //border: true,
  //borderColor: ColorOptions.danger,
  color: ColorOptions.danger,
  //backgroundColor: ColorOptions.danger,
};

const DEFAULT_TOOLTIP_PROPS = {
  content: <></>,
  placement: "top",
};
const DEFAULT_PROPS = { iconProps: {}, tooltipProps: {} };

export const IconCol = memo(({ showIconCondition, ...props }) => {
  //console.log("IconCol:", props);

  let _props = {
    wrapperClassName: WRAPPER_CLASSNAME,
    ...DEFAULT_PROPS,
    ...props,
  };
  const {
    value = { showIcon: false },
    isSelectableKey,
    isSelectable,
    disabledCheckboxProps = {},
    wrapperClassName,
  } = _props;

  const _iconProps = {
    ...DEFAULT_ICON_PROPS,
    ..._props.iconProps,
    ...value.iconProps,
  };
  const _tooltipProps = {
    ...DEFAULT_TOOLTIP_PROPS,
    ..._props.tooltipProps,
    ...value.tooltipProps,
  };

  const _showIcon =
    (showIconCondition && showIconCondition(props)) || value?.showIcon;

  _props = omit(props, [
    "wrapperClassName",
    "iconProps",
    "valueFormatted",
    "eParentOfValue",
    "getValue",
    "setValue",
    "refreshCell",
    "addRowCompListener",
    "addRenderedRowListener",
    "keyPress",
    "charPress",
    "colDef",
    "rowIndex",
    "columnApi",
    "stopEditing",
    "eGridCell",
    "parseValue",
    "formatValue",
    "agGridReact",
    "frameworkComponentWrapper",
    "reactContainer",
    "value",
    "cellStartedEdit",
    "column",
    "node",
    "data",
    "api",
  ]);

  //const id = `${props.colDef.field.replace(/\./g, "_")}_${props.rowIndex}`;
  return (
    <>
      {props.data &&
        (props.data[isSelectableKey] === false || isSelectable === false) && (
          <div className="float-left">
            <IFXCheckbox label="" disabled {...disabledCheckboxProps} />
          </div>
        )}
      {_showIcon ? (
        <IFXTooltip {..._tooltipProps}>
          <span className={wrapperClassName}>
            <IFXIcons {..._iconProps} />
          </span>
        </IFXTooltip>
      ) : (
        <span></span>
      )}
    </>
  );
});
