import React, { memo } from "react";
import cx from "classnames";
import { PlainReadOnlyCell } from "./EditableFacade";

export const EditableAutosuggestFacade = memo(props => {
  //console.log("EditableAutosuggestFacade", props);
  const {
    value,
    colDef: { editable = true },
    multiple = false,
    required = false,
    plainReadOnlyType,
  } = props;

  const _editable = typeof editable === "function" ? editable(props) : editable;
  const _required = typeof required === "function" ? required(props) : required;
  const _plainReadOnlyType =
    typeof plainReadOnlyType === "function"
      ? plainReadOnlyType(props)
      : plainReadOnlyType;

  const _value = !multiple
    ? value
      ? value.value
      : ""
    : value?.map(item => item.value).join(", ") || "";

  if (_plainReadOnlyType && !_editable) {
    return <PlainReadOnlyCell value={_value} />;
  }

  return (
    <div
      className={`ifx-autosuggest${(_required && " field-mandatory") || ""}`}
    >
      <div
        //title={_value}
        className={cx("form-control form-control-sm ifx-text-overflow", {
          disabled: !_editable,
        })}
      >
        {_value}
      </div>
    </div>
  );
});
