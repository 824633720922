import { ESOTasks } from "../../../../../const/eso/ESOTaskConsts";

export const ServiceOrderingMenu = {
  dashboard: {
    icon: "orders",
    source: "/app/eso/",
    title: "ORDERS",
    authorize: { hasTask: ESOTasks.ESOApplication },
  },
  menu: [
    {
      icon: "pe-7s-display1",
      label: "Dashboard",
      to: "/app/eso/serviceOrder/dashboard",
      authorize: { hasTask: ESOTasks.ViewServiceOrderDashboard },
    },
    {
      icon: "pe-7s-browser",
      label: "Order",
      authorize: {
        hasAnyTask: [
          ESOTasks.eSOViewSmartOrderDash,
          ESOTasks.ViewServiceOrderQuery,
          ESOTasks.ServiceOrderCreate,
          ESOTasks.ESOExcelImportOrder,
        ],
      },
      content: [
        {
          icon: "pe-7s-id",
          label: "Smart Order Dashboard",
          to: "/app/eso/smartOrder/dashboard",
          authorize: { hasTask: ESOTasks.eSOViewSmartOrderDash },
        },
        {
          icon: "pe-7s-id",
          label: "Search Order",
          to: "/app/eso/serviceOrder/query",
          authorize: { hasTask: ESOTasks.ViewServiceOrderQuery },
        },
        {
          label: "Create Order",
          to: "/app/eso/serviceOrder/orderDetail/create",
          authorize: { hasTask: ESOTasks.ServiceOrderCreate },
        },
        {
          label: "Import Order",
          to: "/app/eso/serviceOrder/import",
          authorize: {
            hasAllTasks: [
              ESOTasks.ServiceOrderCreate,
              ESOTasks.ESOExcelImportOrder,
            ],
          },
        },
        {
          icon: "pe-7s-id",
          label: "Search Archived Order",
          to: "/app/eso/serviceOrder/query/archive",
          authorize: { hasTask: ESOTasks.ServiceOrdersArch },
        },
        {
          icon: "pe-7s-id",
          label: "Generate Order Rule Log",
          to: "/app/eso/SORuleAudit/SORuleAuditLog",
          authorize: { hasTask: ESOTasks.SO_RULE_AUDIT_LOG },
        },
      ],
    },
    {
      icon: "pe-7s-browser",
      label: "PAX Adjustment Rules",
      authorize: {
        hasAnyTask: [
          ESOTasks.ViewPAxVariables,
          ESOTasks.MaintainRules,
          ESOTasks.SearchPAXRules,
          ESOTasks.CreateRule,
          ESOTasks.TestRules,
        ],
      },
      content: [
        {
          label: "Rule Variables",
          to: "/app/eso/paxAdjustmentRule/paxVariable",
          authorize: { hasTask: ESOTasks.ViewPAxVariables },
        },
        {
          label: "Rule Sets",
          to: "/app/eso/paxAdjustmentRule/ruleSets",
          authorize: { hasTask: ESOTasks.MaintainRules },
        },
        {
          label: "Search Rules",
          to: "/app/eso/paxAdjustmentRule/Rule/searchRule",
          authorize: { hasTask: ESOTasks.SearchPAXRules },
        },
        {
          label: "Create Rule",
          to: "/app/eso/paxAdjustmentRule/Rule/createPaxRuleForm",
          authorize: { hasTask: ESOTasks.CreateRule },
        },
        {
          label: "Test Rules",
          to: "/app/eso/paxAdjustmentRule/Rule/TestRule",
          authorize: { hasTask: ESOTasks.TestRules },
        },
      ],
    },
    {
      icon: "pe-7s-browser",
      label: "Flight Service Schedule",
      authorize: { hasTask: ESOTasks.ViewQueryFSS },
      content: [
        {
          label: "Search Released FSS",
          to: "/app/ess/fss/queryReleasedFSS",
          authorize: { hasTask: ESOTasks.ViewQueryFSS },
        },
      ],
    },
    {
      icon: "pe-7s-browser",
      label: "Reports",
      authorize: {
        hasAnyTask: [
          ESOTasks.ViewReports,
          ESOTasks.RepOverShort,
          ESOTasks.RepOrderedBooked,
          ESOTasks.RepAuthorizations,
        ],
      },
      content: [
        {
          label: "Over Vs Short Report",
          to: "/app/eso/report/overVsShort",
          authorize: { hasTask: ESOTasks.RepOverShort },
        },
        {
          label: "Ordered Vs Actual Report",
          to: "/app/eso/report/orderedVsActual",
          authorize: { hasTask: ESOTasks.RepOrderedBooked },
        },
        {
          label: "Authorizations Report",
          to: "/app/eso/report/authorization",
          authorize: { hasTask: ESOTasks.RepAuthorizations },
        },
      ],
    },
    {
      icon: "pe-7s-browser",
      label: "Administration",
      authorize: {
        hasAnyTask: [
          ESOTasks.MaintainPollingPolicies,
          ESOTasks.SOgeneralSetup,
          ESOTasks.ServiceOrderBoardDocSetup,
          ESOTasks.TopFocusItems,
          ESOTasks.SOJobs,
        ],
      },
      content: [
        {
          label: "Polling Policies",
          to: "/app/eso/Administration/ESOPollingPolicies",
          authorize: { hasTask: ESOTasks.MaintainPollingPolicies },
        },
        {
          label: "Order Parameters",
          to: "/app/eso/order/parameters",
          authorize: { hasTask: ESOTasks.SOgeneralSetup },
        },
        {
          label: "Boarding Document",
          to: "/app/eso/order/boardingdoc",
          authorize: { hasTask: ESOTasks.ServiceOrderBoardDocSetup },
        },
        {
          label: "Top Focus Items",
          to: "/app/eso/setup/topFocusItems/topFocusItems",
          authorize: { hasTask: ESOTasks.TopFocusItems },
        },
        {
          label: "Job Tracker",
          to: "/app/eso/order/jobTracker",
          authorize: { hasTask: ESOTasks.SOJobs },
        },
      ],
    },
  ],
};
