import initialState from "./initialState";

export function RecurrenceTemplateReducer(
  state = initialState.RecurrenceTemplateInfo,
  action
) {
  switch (action.type) {
    case "RECURRING_TEMPLATE_SELECTION_REQUEST": {
      //console.log("its coming main reducer>>>", action.payload);
      return {
        ...state,
        recSearchValues: action.payload,
        isSearchTriggered: true,
      };
    }
    default:
      return state;
  }
}
