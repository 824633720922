import React from "react";
import cx from "classnames";
import { Form, Col, Row } from "react-bootstrap";
import { IFXNumberTextBox2 } from ".";

const DEFAULT_PROPS = {
  className: "ifx-number-range-textbox",
};

export const IFXNumberRangeTextBox = ({
  inputGroupClassName,
  isInvalid = [],
  controlId = [],
  alwaysFloat = [],
  wrapperState = { from: {}, to: {} },
  required = [],
  label = [],
  onFocus = [],
  onBlur = [],
  value = {},
  copyTo,
  fromColProps,
  toColProps,
  ...props
}) => {
  //console.log("IFXNumberRangeTextBox", props, value, required);

  let _props = { ...DEFAULT_PROPS, className: "", ...props };

  //let { min: fromMin, min: toMin, max: fromMax, max: toMax } = _props;

  let onFocusFrom, onFocusTo, onBlurFrom, onBlurTo;

  if (Array.isArray(onFocus)) {
    [onFocusFrom, onFocusTo] = onFocus;
  } else {
    onFocusFrom = onFocusTo = onFocus;
  }
  if (Array.isArray(onBlur)) {
    [onBlurFrom, onBlurTo] = onBlur;
  } else {
    onBlurFrom = onBlurTo = onBlur;
  }

  const onChange = (key, v) => {
    v = { ...value, [key]: v };
    //console.log("onChange", v);
    if (_props.onChange) _props.onChange(v);
  };

  const _onBlurFrom = e => {
    //console.log("_onBlurFrom value", value);
    copyTo && value.from && !value.to && onChange("to", value.from);
    onBlurFrom && onBlurFrom(e);
  };

  const onKeyUpFrom = e => {
    //console.log("onKeyUpFrom", e.key, e);
    if (e.key === "Enter") {
      copyTo && value.from && !value.to && onChange("to", value.from);
    }
  };

  //if (value.from) toMin = value.from;
  //if (value.to) fromMax = value.to;

  return (
    <Row>
      <Col sm="6" {...fromColProps}>
        <div
          className={cx(
            "range-field-wrapper range-from position-relative",
            {
              "floating-label-active":
                wrapperState.from.isFocused || alwaysFloat[0],
              "field-mandatory": required[0],
            },
            isInvalid[0] && "is-invalid"
          )}
        >
          <IFXNumberTextBox2
            {..._props}
            className={cx(DEFAULT_PROPS.className, _props.className)}
            key="from"
            id={controlId[0]}
            value={value.from}
            onFocus={onFocusFrom}
            onBlur={_onBlurFrom}
            onKeyUp={onKeyUpFrom}
            onChange={v => {
              onChange("from", v.target.value);
            }}
          />
          {label && <Form.Label htmlFor={controlId[0]}>{label[0]}</Form.Label>}
          <Form.Control.Feedback type="ifx-field-error invalid">
            {wrapperState.from.errorMessage}
          </Form.Control.Feedback>
        </div>
      </Col>
      <Col sm="6" {...toColProps}>
        <div
          className={cx(
            "range-field-wrapper range-to position-relative",
            {
              "floating-label-active":
                wrapperState.to.isFocused || alwaysFloat[1],
              "field-mandatory": required[1],
            },
            isInvalid[1] && "is-invalid"
          )}
        >
          <IFXNumberTextBox2
            {..._props}
            className={cx(DEFAULT_PROPS.className, _props.className)}
            id={controlId[1]}
            key="to"
            value={value.to}
            onFocus={onFocusTo}
            onBlur={onBlurTo}
            onChange={v => {
              onChange("to", v.target.value);
            }}
          />
          {label && <Form.Label htmlFor={controlId[1]}>{label[1]}</Form.Label>}
          <Form.Control.Feedback type="ifx-field-error invalid">
            {wrapperState.to.errorMessage}
          </Form.Control.Feedback>
        </div>
      </Col>
    </Row>
  );
};
