import React, { forwardRef, memo } from "react";
import { FormCheck } from "react-bootstrap";
import cx from "classnames";
import PropTypes from "prop-types";

const DEFAULT_PROPS_CLASS_NAME = "ifx-checkbox";

export const IFXCheckbox = memo(
  forwardRef((props, ref) => {
    return (
      <>
        <FormCheck
          custom
          type="checkbox"
          {...props}
          className={cx(DEFAULT_PROPS_CLASS_NAME, props.className)}
          ref={ref}
        />
      </>
    );
  })
);
