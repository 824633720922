import React, { forwardRef } from "react";
import { Form } from "react-bootstrap";
import { omit } from "../../utils";

export const IFXHiddenInput = forwardRef((props, ref) => {
  let { required, ..._props } = props;
  _props = omit(_props, ["defaultValue", "value"]);

  return (
    <Form.Control
      {...(("value" in props && { value: props.value || "" }) || {
        defaultValue: props.defaultValue,
      })}
      {..._props}
      type="hidden"
      ref={ref}
    />
  );
});
