import React from "react";
import { Card, FormGroup, Container, Row, Col } from "react-bootstrap";
import {
  IFXIcons,
  IconStyleOptions,
  ColorOptions,
  IFXTooltip,
  IFXFieldWrapper,
  IFXTextBox,
} from "../../../components/AppComponents";

export const TooltipCard = () => {
  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title>Tooltip</Card.Title>
        <FormGroup>
          <Container>
            <Row>Mouse over to see tooltip</Row>
            <Row>
              <Col>
                <div className="d-inline-block">
                  <IFXTooltip
                    placement="left"
                    content={<>This is Sample tooltip</>}
                  >
                    <IFXIcons
                      character="E"
                      iconStyle={IconStyleOptions.CIRCLE}
                      border={true}
                      borderColor={ColorOptions.green}
                    />
                  </IFXTooltip>
                </div>
                <div className="d-inline-block">
                  <IFXTooltip
                    placement="top"
                    content={
                      <>
                        <div className="text-left">
                          <div>Boarding %: 50%</div>
                          <div>pax : 20</div>
                          <div>Pre-Select : 10</div>
                        </div>
                      </>
                    }
                  >
                    <IFXIcons
                      character="B"
                      iconStyle={IconStyleOptions.CIRCLE}
                      border={true}
                      borderColor={ColorOptions.green}
                    />
                  </IFXTooltip>
                </div>
              </Col>
            </Row>
            <Row>Title instead of tooltip:</Row>
            <Row>
              <Col>
                <IFXIcons
                  title="Sample Title"
                  borderThin={true}
                  border={true}
                  borderColor={ColorOptions.grey}
                  backgroundColor={ColorOptions.yellow}
                  iconStyle={IconStyleOptions.ROUNDED_SQUARE}
                  className="opacity-8"
                  icon="exclamation"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <IFXFieldWrapper label="Flight #" controlId="flightNo">
                  <IFXTooltip
                    trigger="focus"
                    placement="bottom"
                    content="Enter flight numbers and/or ranges separated by comma e.g. 1,2,13-19"
                  >
                    <IFXTextBox name="flightNo" />
                  </IFXTooltip>
                </IFXFieldWrapper>
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </Card.Body>
    </Card>
  );
};
