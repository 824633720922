import initialState from "./initialState";

export function BreadcrumbReducer(
  state = initialState.BreadcrumbReducer,
  action
) {
  switch (action.type) {
    case "BREADCRUMB_ADD_ITEM": {
      if (state.navBreadcrumbs.indexOf(action.payload) === -1) {
        return {
          ...state,
          navBreadcrumbs: [...state.navBreadcrumbs, action.payload],
        };
      }
      return state;
    }

    case "BREADCRUMB_RESET_ADD_ITEM": {
      return {
        ...state,
        navBreadcrumbs: [action.payload],
      };
    }

    default:
      return state;
  }
}
