import initialState from "./initialState";

export function AcSeatCfgModalReducer(
    state = initialState.AcSeatCfgModalReducer,
    action
) {
    switch (action.type) {
        case "AC_SEAT_CFG_MODAL_SHOW":
            return {
                ...state,
                modal: true,
            };
            case "AC_SEAT_CFG_MODAL_HIDE":
                return {
                    ...state,
                    modal: false,
                };
             
        default:
            return state;
    }
}
