import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
  } from "react";
  import { IFXButton } from "../IFXButton";
  import { isEmpty } from "ramda";
  import { Col, Container, Row } from "react-bootstrap";
  import { IFXUserNote } from "../IFXAlert";
  import { IFXFieldWrapper } from "../IFXFieldWrapper";
  import { IFXNumberTextBox2 } from "../IFXNumberTextBox2";
  
  const TOAST_BUTTON_DEFAULT_PROPS = {
    type: "default",
    size: "sm",
  };
  
  export const IFXDoubleConfirmToast = React.memo(params => {
    const {
      title = "Information",
      message,
      onCallback,
      actions = [],
      className = "",
      ifxDismissToast,
      setLastAction,
      onCloseCustom,
      hideProgressBar,
    } = params;
    const [maxHeight, setMaxHeight] = useState(window.innerHeight);
    const [numberInput, setNumberInput] = useState("");
    const [isValid, setIsValid] = useState(false);
  
    const inputRef = useRef();
  
    const handleResize = useCallback(e => setMaxHeight(window.innerHeight), []);
  
    const randomNumber = useMemo(
      () => Math.floor(1000 + Math.random() * 9000),
      []
    );
  
    const onChange = useCallback(e => {
      setIsValid(randomNumber === Number(e.target.value));
      setNumberInput(e.target.value);
    }, []);
  
    useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [handleResize]);
  
    useEffect(() => {
      inputRef.current.focus();
      return () => {
        onCloseCustom(params);
      };
    }, []);
  
    const actionButtons = useMemo(
      () =>
        actions.map((action, key) => {
          const label = typeof action === "string" ? action : undefined;
          const _name = typeof action === "string" ? action : action.name;
          return (
            <IFXButton
              disabled={action.type === "yes" && !isValid}
              {...{
                ...TOAST_BUTTON_DEFAULT_PROPS,
                key,
                ...((label && { name: label }) || {}),
                ...((typeof action !== "string" && action) || {}),
                onClick: e =>
                  !isEmpty(actions) &&
                  (setLastAction(action),
                  ifxDismissToast(),
                  onCallback(e, _name, action)),
              }}
            />
          );
        }),
      [actions, onCallback, isValid]
    );
    return (
      <div className={`toastbody ${className}`}>
        <header className={className}>
          <h6>{title}</h6>
        </header>
        <main style={{ maxHeight: `calc(${maxHeight}px - 10.715rem)` }}>
          <div className="toastcontent">{message}</div>
        </main>
        <footer
          className={`toastbuttons pt-1 ${
            hideProgressBar === false ? "pb-3" : "pb-2"
          }`}
        >
          <Container fluid style={{ padding: "0 0.715rem" }}>
            <Row>
              <Col>
                <span
                  style={{
                    margin: "0rem",
                    color: "black",
                    fontWeight: 400,
                    verticalAlign: "text-bottom",
                  }}
                  className="toastcontent d-inline-block"
                >
                  Please enter the number displayed to confirm:
                </span>
                <IFXUserNote
                  noBackground
                  noBorder
                  paddingClassName="p-0"
                  marginClassName="m-0"
                  className="d-inline-block"
                >
                  <span className="pl-1">
                    <h5 className="mb-0">{randomNumber}</h5>
                  </span>
                </IFXUserNote>
              </Col>
            </Row>
            <Row>
              <Col xs={5}>
                <IFXFieldWrapper
                  label="Enter Here"
                  controlId="numberInput"
                  onChange={onChange}
                  value={numberInput}
                >
                  <IFXNumberTextBox2 ref={inputRef} maxLength={4} />
                </IFXFieldWrapper>
              </Col>
              <Col className="d-flex justify-content-end pr-2">
                <div style={{ marginTop: "auto", marginBottom: "0.1rem" }}>
                  {actionButtons}
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    );
  });
  