export const LOGIN_ACTION = "LOGIN";
export const LOGOUT_ACTION = "LOGOUT";
export const RESET_REDUX_ACTION = "RESET_REDUX";

//USER CONTEXT ACTIONS
export const USER_CONTEXT_ACTION = "USER_CONTEXT";

export const USER_CONTEXT_SUB_ACTIONS = [
  "INITIAL_LOAD",
  "SELECTED_AIRLINE",
  /* "AIRLINE_LIST", */
  "USER_INFO",
  "RESET_CARRIER_SWITCH_KEY",
];

export const USER_INFO_ACTION = "USER_INFO";

export const AIRLINE_SWITCH_ACTION = "AIRLINE_SWITCH";

export const MANAGE_SPML_CRWML = "MANAGE_SPML_CRWML";

export const SEARCH_USER_PROFILE = "SEARCH_USER_PROFILE";

export const SEARCH_AC_SEAT_CONFIG = "SEARCH_AC_SEAT_CONFIG";
export const CATERING_STATION_FORM_DATA = "CATERING_STATION_FORM_DATA";
export const CARRIER_STATION_FORM_DATA = "CARRIER_STATION_FORM_DATA";

export const CATERER_FORM_DATA = "CATERER_FORM_DATA";
