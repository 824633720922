import { useSelector } from "react-redux";
import { isEmptyOrNull } from "@ifx-react/ifx-react-core";
import { useMemo } from "react";

//returns true/false
export const isTaskAuthorized = (taskNames, authTaskList) => {
  if (authTaskList != null && authTaskList.length > 0 && taskNames != null) {
    if (typeof taskNames === "string") {
      return authTaskList.includes(taskNames);
    } else if (Array.isArray(taskNames)) {
      return taskNames.every(taskName => authTaskList.includes(taskName));
    }
  }

  return false;
};

export const isAnyTaskAuthorized = (taskNames, authTaskList) => {
  if (authTaskList != null && authTaskList.length > 0 && taskNames != null) {
    if (Array.isArray(taskNames)) {
      return taskNames.some(taskName => authTaskList.includes(taskName));
    }
  }

  return false;
};

export const useUserTaskGranted = taskNames => {
  const authTaskList = useSelector(
    state => state.PortalReducer.UserContextReducer.userInfo.taskList
  );
  return isTaskAuthorized(taskNames, authTaskList);
};

export const useUserTaskAnyGranted = taskNames => {
  const authTaskList = useSelector(
    state => state.PortalReducer.UserContextReducer.userInfo.taskList
  );

  return isAnyTaskAuthorized(taskNames, authTaskList);
};

export const useUserTaskGrantedMulti = taskNames => {
  const authTaskList = useSelector(
    state => state.PortalReducer.UserContextReducer.userInfo.taskList
  );

  return useMemo(() => {
    //console.log("useUserTaskGrantedMulti memo");
    if (isEmptyOrNull(authTaskList)) return {};
    return taskNames.reduce(
      (out, taskName) =>
        ((out[taskName] = isTaskAuthorized(taskName, authTaskList)) && out) ||
        out,
      {}
    );
  }, [authTaskList]);
};

export const authorizeConfigRecursive = (
  inPropObj,
  outPropObj,
  authTaskList
) => {
  if (
    typeof inPropObj === "object" &&
    !Array.isArray(inPropObj) &&
    inPropObj.hasOwnProperty("authorize")
  ) {
    let authorize = inPropObj["authorize"];
    let { hasTask, hasAllTasks, hasAnyTask } = authorize;
    if (hasTask || hasAllTasks) {
      if (hasAllTasks) hasTask = hasAllTasks;
      if (!isTaskAuthorized(hasTask, authTaskList)) {
        return undefined;
      }
    } else if (hasAnyTask) {
      if (!isAnyTaskAuthorized(hasAnyTask, authTaskList)) {
        return undefined;
      }
    }
  }
  Object.keys(inPropObj).reduce((prevObj, curKey) => {
    let childPropObj = inPropObj[curKey];
    if (typeof childPropObj === "object") {
      if (Array.isArray(childPropObj)) {
        childPropObj = authorizeConfigRecursive(childPropObj, [], authTaskList);
      } else {
        childPropObj = authorizeConfigRecursive(childPropObj, {}, authTaskList);
      }
    }

    if (childPropObj) {
      if (Array.isArray(outPropObj)) {
        outPropObj.push(childPropObj);
      } else {
        outPropObj[curKey] = childPropObj;
      }
    }
  }, outPropObj);

  return outPropObj;
};
