import React from "react";
import {
  CUSTOM_FILTER_COMPARATOR,
  sanatizeHtml,
} from "@ifx-react/ifx-react-core";
import { Authorize } from "../../../components/common/security/Authorize";

const getApprovalHistory = list => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: sanatizeHtml(list.join("<br>")),
      }}
    />
  );
};

/* User Access Profiles Grid Column Definitions */
export const credentialSetColDefs = ({
  serializeToLocaleDate,
  setEditCredential,
  approveCredentialSet,
  rejectCredentialSet,
  maintainUserCredentials,
}) => [
  {
    headerName: "",
    field: "componentsIconControl",
    checkboxSelection: true,
    filter: false,
    editable: false,
    sortable: false,
    suppressMenu: true,
    pinned: true,
    headerCheckboxSelection: true,
    cellRenderer: "IconCol",
    cellRendererParams: {
      isSelectableKey: "isSelectable",
    },
    minWidth: 30,
    width: 30,
  },
  {
    headerName: "Name",
    headerTooltip: "Access Profile Name",
    field: "name",
    minWidth: 190,
  },
  {
    headerName: "Description",
    headerTooltip: "Access Profile Description",
    field: "description",
    minWidth: 230,
  },
  {
    headerName: "Eff Date",
    headerTooltip: "Eff Date",
    field: "effDateLong",
    minWidth: 130,
    valueFormatter(params) {
      return serializeToLocaleDate(params?.data?.effDate);
    },
    filter: "agDateColumnFilter",
    filterParams: {
      valueGetter: function(params) {
        return params?.data?.effDate;
      },
      resetButton: true,
      comparator: CUSTOM_FILTER_COMPARATOR.dateInMillisFilter,
    },
  },
  {
    headerName: "End Date",
    headerTooltip: "End Date",
    field: "expDateLong",
    minWidth: 130,
    valueFormatter(params) {
      return serializeToLocaleDate(params?.data?.expDate);
    },
    filter: "agDateColumnFilter",
    filterParams: {
      valueGetter: function(params) {
        return params?.data?.expDate;
      },
      resetButton: true,
      comparator: CUSTOM_FILTER_COMPARATOR.dateInMillisFilter,
    },
  },
  {
    headerName: "Status",
    headerTooltip: "Status",
    field: "status",
    minWidth: 100,
  },
  {
    headerName: "Actions",
    headerTooltip: "Actions",
    field: "action",
    minWidth: 140,
    filter: false,
    sortable: false,
    pinned: "right",
    lockPinned: true,
    lockVisible: true,
    cellRenderer: "ActionCol",
    cellRendererParams: ({ data }) => ({
      actions: [
        {
          icon: "calendar",
          name: "Edit Effective Dates",
          onClick: (e, data, parama) => {
            setEditCredential(data);
          },
        },
        {
          icon: "history",
          name: getApprovalHistory(data.approvalHistory),
        },
        {
          icon: "approve",
          name: "Approve",
          onClick: (e, data, parama) => {
            approveCredentialSet(data);
          },
          includeCondition: data => {
            return (
              data.approveRejectVisible && maintainUserCredentials
            );
          },
        },
        {
          icon: "reject",
          name: "Reject",
          onClick: (e, data, parama) => {
            rejectCredentialSet(data);
          },
          includeCondition: data => {
            return data.approveRejectVisible && maintainUserCredentials;
          },
        },
      ],
    }),
  },
];
