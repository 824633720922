import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { IFXTimeRangePicker } from "../../../components/AppComponents";
import { IFXFieldWrapper } from "../../../components/AppComponents/IFXFieldWrapper";

export const TimeRangePickerCard = () => {
  const [timeRange, setTimeRange] = useState({});
  const [timeRangeDV, setTimeRangeDV] = useState({
    from: { serialized: "08:55" },
    to: { serialized: "10:35" },
  });

  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title className="mb-4">Time Range</Card.Title>
        <IFXFieldWrapper
          type="range"
          label={["From Time", "To Time"]}
          controlId={["fromTimeId", "toTimeId"]}
          alwaysFloat={[
            (() => timeRange.from != null)(),
            (() => timeRange.to != null)(),
          ]}
        >
          <IFXTimeRangePicker
            selected={timeRange}
            onChange={v => {
              setTimeRange(v);
            }}
          />
        </IFXFieldWrapper>

        <Card.Title className="mt-4 mb-4">
          Time Range with default value
        </Card.Title>
        <IFXFieldWrapper
          type="range"
          label={["From Time", "To Time"]}
          controlId={["fromTimeDVId", "toTimeDVd"]}
          alwaysFloat={[
            (() => timeRangeDV.from != null)(),
            (() => timeRangeDV.to != null)(),
          ]}
        >
          <IFXTimeRangePicker
            selected={timeRangeDV}
            onChange={v => {
              setTimeRangeDV(v);
            }}
          />
        </IFXFieldWrapper>
      </Card.Body>
    </Card>
  );
};
