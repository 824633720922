import React from "react";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import cx from "classnames";
import { set } from "date-fns";
import { InputGroup, Form, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { CalenderContainer } from "./IFXDatePicker";
import { moment } from "../../utils/date";

const DEFAULT_PROPS = {
  className: "ifx-date-range-picker",
  dateFormat: "MM/dd/yyyy",
  showMonthDropdown: true,
  showYearDropdown: true,
  useShortMonthInDropdown: true,
  highlightDates: [new Date()],
  size: "sm",
  serializeFormat: "MM/DD/YYYY",
  isSerialized: true,
  autoComplete: "off",
  dropdownMode: "scroll",
  minDate: set(new Date(), {
    year: 1976,
    month: 0,
    date: 1,
    hours: 0,
    minutes: 0,
  }),
  maxDate: set(new Date(), {
    year: 2500,
    month: 12,
    date: 31,
    hours: 23,
    minutes: 59,
  }),
  split: true,
  isClearable: true,
};

export const IFXDateRangePicker = ({
  isInvalid = [],
  required = [],
  fromColProps,
  toColProps,
  ...props
}) => {
  const {
    onFocus = [],
    onBlur = [],
    onCalendarClose = [],
    controlId = [],
    label = [],
    alwaysFloat = [],
    wrapperState = { from: {}, to: {} },
    selected = {},
    disabled = [],
    customStartDate = undefined,
    customEndDate = undefined,
  } = props;
  //const { rangeErrorComponent = undefined } = props;
  //const { fromError = undefined, toError = undefined } = rangeErrorComponent;
  const _props = { ...DEFAULT_PROPS, className: "", ...props };

  let {
    inputGroupClassName = "",
    isSerialized,
    serializeFormat,
    minTime: fromMin,
    minTime: toMin,
    maxTime: fromMax,
    maxTime: toMax,
    split,
  } = _props;

  let onFocusFrom,
    onFocusTo,
    onBlurFrom,
    onBlurTo,
    onCalendarCloseFrom,
    onCalendarCloseTo;
  if (Array.isArray(onFocus)) {
    [onFocusFrom, onFocusTo] = onFocus;
  } else {
    onFocusFrom = onFocusTo = onFocus;
  }

  if (Array.isArray(onBlur)) {
    [onBlurFrom, onBlurTo] = onBlur;
  } else {
    onBlurFrom = onBlurTo = onBlur;
  }
  if (Array.isArray(onCalendarClose)) {
    [onCalendarCloseFrom, onCalendarCloseTo] = onCalendarClose;
  } else {
    onCalendarCloseFrom = onCalendarCloseTo = onCalendarClose;
  }

  const onChange = (key, v) => {
    if (v != null) {
      if (isSerialized)
        v = (customEndDate &&
          moment(v).isBefore(customEndDate) && {
            serialized: moment(customEndDate).format(_props.serializeFormat),
            dateValue: moment(customEndDate, _props.serializeFormat).toDate(),
          }) || {
          serialized: moment(v).format(_props.serializeFormat),
          dateValue: moment(v, _props.serializeFormat).toDate(),
        };
    }
    v = { ...selected, [key]: v };

    if (_props.onChange) _props.onChange(v);
  };

  let _selected = { from: null, to: null, ...selected };

  if (isSerialized && selected != null) {
    if (selected.from) {
      toMin = _selected.from = moment(
        selected.from.serialized,
        serializeFormat
      ).toDate();
    }

    if (selected.to) {
      fromMax = _selected.to = moment(
        selected.to.serialized,
        serializeFormat
      ).toDate();
    }
  }

  if (_selected.from) toMin = _selected.from;
  if (_selected.to) fromMax = _selected.to;

  return (
    <>
      <Row>
        <Col xs="6" {...fromColProps}>
          <div
            className={cx(
              "range-field-wrapper range-from position-relative",
              {
                "floating-label-active":
                  wrapperState.from.isFocused || alwaysFloat[0],
                "field-mandatory": required[0],
              },
              isInvalid[0] && "is-invalid"
            )}
          >
            <Form.Control
              {..._props}
              className={cx(DEFAULT_PROPS.className, _props.className)}
              id={controlId[0]}
              key="from"
              as={DatePicker}
              onFocus={onFocusFrom}
              onBlur={onBlurFrom}
              onCalendarClose={onCalendarCloseFrom}
              selected={_selected.from}
              onChange={v => {
                onChange("from", v);
              }}
              minDate={fromMin}
              maxDate={fromMax}
              selectsStart
              startDate={_selected.from}
              endDate={_selected.from ? _selected.to : new Date()}
              popperContainer={CalenderContainer}
              disabled={disabled && disabled[0]}
              isClearable={disabled && disabled[0] ? false : _props.isClearable}
            />
            {label && (
              <Form.Label htmlFor={controlId[0]}>{label[0]}</Form.Label>
            )}
            <Form.Control.Feedback type="ifx-field-error invalid">
              {wrapperState.from.errorMessage}
            </Form.Control.Feedback>
          </div>
        </Col>
        <Col xs="6" {...toColProps}>
          <div
            className={cx(
              "range-field-wrapper range-to position-relative",
              {
                "floating-label-active":
                  wrapperState.to.isFocused || alwaysFloat[1],
                "field-mandatory": required[1],
              },
              isInvalid[1] && "is-invalid"
            )}
          >
            <Form.Control
              {..._props}
              className={cx(DEFAULT_PROPS.className, _props.className)}
              id={controlId[1]}
              key="to"
              as={DatePicker}
              onFocus={onFocusTo}
              onBlur={onBlurTo}
              onCalendarClose={onCalendarCloseTo}
              selected={_selected.to}
              onChange={v => {
                onChange("to", v);
              }}
              minDate={toMin}
              maxDate={toMax}
              selectsEnd
              startDate={_selected.to ? _selected.from : new Date()}
              endDate={_selected.to}
              popperContainer={CalenderContainer}
              disabled={disabled && disabled[1]}
              isClearable={disabled && disabled[1] ? false : _props.isClearable}
            />
            {label && (
              <Form.Label htmlFor={controlId[1]}>{label[1]}</Form.Label>
            )}
            <Form.Control.Feedback type="ifx-field-error invalid">
              {wrapperState.to.errorMessage}
            </Form.Control.Feedback>
          </div>
        </Col>
      </Row>
    </>
  );
};
IFXDateRangePicker.propTypes = {
  isSerialized: PropTypes.bool,
  serializeFormat: PropTypes.string,
};
