import { mergeMap, catchError, tap } from "rxjs/operators";
import { ofType } from "redux-observable";

import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import {
  LoadInvoiceStatus,
  StoreInvoiceStatus,
} from "../../actions/esv/actionCreators";
import { AJAX_HEADER_JSON } from "../../../const/common/ajaxConsts";
import { FETCH_INVOICE_STATUS_URL } from "../../../const/esv/endpoints";

export const invoiceStatusEpic = action$ =>
  action$.pipe(
    ofType(LoadInvoiceStatus.REQUEST().type),
    mergeMap(val =>
      ajax
        .post(
          FETCH_INVOICE_STATUS_URL,
          {},
          {
            ...AJAX_HEADER_JSON,
          }
        )
        .pipe(
          mergeMap(xhrResponse => {
            return of(
              LoadInvoiceStatus.SUCCESS(),
              StoreInvoiceStatus.PUT_INVOICE_STATUS(xhrResponse.response)
            );
          }),
          catchError(error => {
            console.log(error);
            return of(LoadInvoiceStatus.FAILURE());
          })
        )
    )
  );
