import React from "react";
import { IFXFieldWrapper } from "../components/AppComponents/IFXFieldWrapper";

//---------------------------------
// Local Constants
//---------------------------------
const config = {
  component: <IFXFieldWrapper />,
  props: {},
  children: {
    component: "",
    props: {},
  },
};
//---------------------------------
// Generic renderer
// usage to avoid cumbersome JSX
//---------------------------------
export function renderer(config) {
  return (
    (config.component &&
      React.cloneElement(
        config.component,
        config.props || {},
        config.children &&
          ((Array.isArray(config.children) &&
            config.children.map(c => renderer(c))) ||
            (typeof config.children === "object" &&
              renderer(config.children)) ||
            config.children)
      )) ||
    config
  );
}

//---------------------------------
// Generic IFXFieldWrapper Rendered
//---------------------------------
export function withIFXFieldWrapper(
  props = {},
  child = undefined,
  childProps = {}
) {
  const configuration = {
    ...config,
    ...{ props },
    ...{ children: { component: child, props: childProps } },
  };
  return renderer(configuration);
}
