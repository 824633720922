import React, { useState } from "react";
import { Card, FormGroup } from "react-bootstrap";
import { IFXCheckbox } from "../../../components/AppComponents";

export const CheckBoxCard = () => {
  const [checked, setChecked] = useState(false);
  //console.log("checkBox status: " + isChecked);

  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title>Check Boxes</Card.Title>
        <FormGroup>
          <div>
            <IFXCheckbox
              id="samplechkbox1"
              label="Check me out"
              checked={checked}
              onChange={v => {
                setChecked(!checked);
              }}
            />
            <IFXCheckbox
              id="samplechkbox2"
              checked={checked}
              label="Disabled Checkbox"
              disabled
            />
          </div>
        </FormGroup>
      </Card.Body>
    </Card>
  );
};
