export default {
  //LoginReducer: {},
  UserContextReducer: {
    initialLoad: 0, //0 - initial, 1 -in progress, 2- completed, -1 failure, -2 redirect to logout->login,-3 samlforward
    selectedAirline: {},
    userInfo: { carrierList: [], taskList: [] },
  },
  AircraftTypeFormValues: {
    carrier: [],
    aircraftTypes: [],
    aircraftDesc: "",
    init: "init",
  },
  MangeSpmlCrwmlFormValues: {
    code: [],
    name: [],
    description: [],
    spml: true,
    crwml: true,
    init: "init",
  },
  UserSearchReducer: {
    serviceType: "initialState",
  },

  AcSeatConfigInfo: {
    searchAcSeatConfigCriteria: { state: "initial" },
  },
  cateringStationFormValues: {
    carrier: [],
    caterer: [],
    station: [],
    cateringStationCode: "",
    csEffEndDate: {
      from: null,
      to: null,
    },
    init: "init",
  },
  carrierStationFormValues: {
    carrier: [],
    station: [],
    carrierStationDate: {
      from: null,
      to: null,
    },
    init: "init",
  },
  catererFormValues: {
    carrier: [],
    catererCode: "",
    catererName: "",
    init: "init",
  },
};
