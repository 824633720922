import initialState from "./initialState";

export function SearchPriceReducer(
  state = initialState.searchSpecPriceParams,
  action
) {
  switch (action.type) {
    case "SEARCH_SPEC_PRICE_REQUEST":
      return {
        ...state,
        data: { ...action.payload },
      };
    default:
      return state;
  }
}
