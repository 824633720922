import initialState from "./initialState";

export function RapRequestReducer(
  state = initialState.RapRequestInfo,
  action
) {
  switch (action.type) {
    case "SEARCH_RAP_SELECTION_REQUEST": {
      console.log("its coming main reducer>>>", action.payload);
      return {
        ...state,
        rapSearchValues: action.payload,
        isSearchTriggered: true,
      };
    }
    default:
      return state;
  }
}
