import initialState from "./initialState";

export function PriceParametersReducer(
  state = initialState.priceParametersParams,
  action
) {
  switch (action.type) {
    case "SEARCH_PRICEPARAMETERS_REQUEST":
      return action.payload;

    default:
      return state;
  }
}
