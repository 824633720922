import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import { IFXTooltip } from "..";
import { Overlay, Tooltip } from "react-bootstrap";

const getTextWidth = value => {
  const textWidthCheck = document.getElementById("textWidthCheck");
  textWidthCheck.style.fontSize = fontSize;

  textWidthCheck.innerText = value;

  const textWidth = textWidthCheck.clientWidth + 20; //padding
  textWidthCheck.innerText = "";
  return textWidth;
};

const fontSize = 12;
const lgMaxWidth = 480;
export const IFXDataTableTooltip = forwardRef((props, ref) => {
  //console.log("IFXDataTableTooltip", props);
  const {
    value,
    placement = "right",
    reactContainer,
    colDef: { tooltipWidthCheck = true },
    column: { actualWidth: colWidth },
    isEditableFacade = false,
  } = props;
  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses: () => {
        return ["ifx-datatable-tooltip"];
      },
    };
  });

  const textWidth =
    typeof value === "string" || typeof value === "number"
      ? getTextWidth(value) + (isEditableFacade ? 27 : 0)
      : 9999;

  //console.log("textWidth", textWidth, "colWidth", colWidth);
  //console.log("tooltipWidthCheck", tooltipWidthCheck);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {(textWidth >= colWidth || !tooltipWidthCheck) && (
        <Overlay target={reactContainer} show={show} placement={placement}>
          {props => {
            return (
              <IFXTooltip controlled hideArrow {...props}>
                {value}
              </IFXTooltip>
            );
          }}
        </Overlay>
      )}
    </>
  );
});
