import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Col } from "react-bootstrap";
import {
  ifxJsonAjax,
  useObservableCallback,
  useToast,
  IFXAlert,
} from "@ifx-react/ifx-react-core";
import { Subject } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { VERIFY_ACCOUNT_URL } from "../../../const/portal/endpoints";
import { LayoutActions } from "../../../store/actions/common/actionCreators";

const verifyUserAccount$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax.post(VERIFY_ACCOUNT_URL, params).pipe(
        map(xhrResponse => {
          return xhrResponse.response || "";
        }),
        catchError(error => {
          console.error("Error in Verify Account", error);
          error.userName = params.userName;
          errorHandler(error || {});
          return [];
        })
      )
    )
  );

export const VerifyAccount = ({ setTitle, setshowToggle }) => {
  const dispatch = useDispatch();
  const { ifxInfoToast, ifxErrorToast } = useToast();

  const [userName, setUserName] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [showRequiredError, setShowRequiredError] = useState(false);

  const backToLogin = isError => {
    if (!isError) {
      setshowToggle(false);
      setTitle("Login");
    }
    dispatch(LayoutActions.SET_LOADER_HIDE());
  };

  const verifyAccount$ = useMemo(() => {
    return verifyUserAccount$({
      errorHandler: error => {
        backToLogin(true);
        if (
          error.response &&
          error.response.messageCode === "EMAIL_NOTSUCCESS"
        ) {
          ifxErrorToast({
            content: `Email Verification for your account ${error.userName} has not been successful.
              Please contact your administrator.`,
          });
        }
      },
    });
  }, []);

  const submitVerificationForm = e => {
    if (!(userName && verificationCode)) {
      setShowRequiredError(true);
      e.preventDefault();
      return;
    }
    showRequiredError && setShowRequiredError(false);
    e.preventDefault();
    dispatch(
      LayoutActions.SET_LOADER_SHOW({
        loaderText: "Loading...",
      })
    );
    verifyAccount$.next({
      userName: userName,
      verificationCode: verificationCode,
    });
  };

  useObservableCallback(
    verifyAccount$,
    response => {
      backToLogin();
      ifxInfoToast({
        content: response,
      });
    },
    error => {
      backToLogin();
    }
  );

  return (
    <Form onSubmit={submitVerificationForm}>
      <div className="login-form account-verify">
        {showRequiredError && (
          <IFXAlert variant="danger">
            User Name and Verification Code are required.
          </IFXAlert>
        )}
        <Form.Row>
          <Col md={12}>
            <Form.Group>
              <Form.Control
                name="userName"
                id="userName"
                value={userName}
                placeholder="User Name"
                onChange={e => setUserName(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <Form.Control
                name="verificationCode"
                id="verificationCode"
                value={verificationCode}
                placeholder="Verification Code"
                onChange={e => setVerificationCode(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Button
          variant="primary"
          size="sm"
          type="submit"
          className="login-button"
          block
        >
          Verify
        </Button>
        <Button
          variant="primary"
          size="sm"
          className="login-button"
          onClick={() => {
            setshowToggle(false);
            setTitle("Login");
          }}
          block
        >
          Close
        </Button>
      </div>
    </Form>
  );
};
