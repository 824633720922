import React, { forwardRef, useImperativeHandle, memo,useState } from "react";
import { Link } from "react-router-dom";

export const LinkRenderer = memo(forwardRef((props, ref) => {
  const { data, value, getTo, enableCondition = true } = props;

  const _enableCondition =
    typeof enableCondition === "function"
      ? enableCondition(props)
      : enableCondition;

  const to = getTo(data);

  useImperativeHandle(ref, () => {
    return {};
  });

  return (
    <>
      {props.value && _enableCondition ? (
        <div className="ifx-link ifx-text-overflow">
          <Link to={to}>{value}</Link>
        </div>
      ) : (
        <div className=" ifx-text-overflow">{value} </div>
      )}
    </>
  );
}));
