import React, { useState } from "react";
import { Card, Container } from "react-bootstrap";
import { IFXMultiSelect } from "../../../components/AppComponents/IFXMultiSelect";

export const MultiSelectCard = () => {

  // sample output 

  const multiSelectOptions = [
    {
      id: "1",
      value: "a",
      display: "a",
    },
    {
      id: "2",
      value: "ab",
      display: "ab",
    },
    {
      id: "3",
      value: "abc",
      display: "abc",
    },
    {
      id: "4",
      value: "abcd",
      display: "abcd",
    },
    {
      id: "5",
      value: "test",
      display: "test",
    },
    {
      id: "6",
      value: "test1",
      display: "test1",
    },
    {
      id: "7",
      value: "test2",
      display: "test2",
    },
  ];

  const defaultSelectedVal = [];

  const [getSelectVal, setSelectVal] = useState(defaultSelectedVal);

  // final output

  // console.log("getSelectVal",getSelectVal)




  // Edit or after the selecting re selected element

  // const reSelectData = [
  //   {
  //     id: "3",
  //     value: "abc",
  //     display: "abc",
  //   },
  //   {
  //     id: "4",
  //     value: "test",
  //     display: "test",
  //   },
  // ]

  // if nothing to reselect the multiselect element send as default you can send empty array

  const reSelectData = []

  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title className="mb-4">Multi Select and Swap Card</Card.Title>
        <Container>
          <IFXMultiSelect selectedVal={data => setSelectVal(data)} data={multiSelectOptions} reSelectData={reSelectData} containerLeftName="Available Spec Type" containerRightName="Selected Spec Types" />
        </Container>
      </Card.Body>
    </Card>
  );
};
