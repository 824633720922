import React, {
  useState,
  forwardRef,
  useContext,
  memo,
  useCallback,
  useMemo,
} from "react";
import cx from "classnames";
import {
  Typeahead,
  AsyncTypeahead,
  Highlighter,
  TypeaheadMenu,
  Token,
} from "react-bootstrap-typeahead";
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import * as queryString from "query-string";
import {
  AUTOSUGGEST_URL_PREFIX,
  AUTOSUGGEST_URL_SUFFIX,
} from "../../const/endpoints";
import { AJAX_HEADER_APP_DEFAULT } from "../../const/ajaxConsts";
import getOptionLabel from "react-bootstrap-typeahead/lib/utils/getOptionLabel";
import defaultFilterBy from "react-bootstrap-typeahead/lib/utils/defaultFilterBy";
import PropTypes from "prop-types";
import FormContext from "react-bootstrap/FormContext";
import { IFXTooltip } from "./IFXTooltip";
import { useSelector } from "react-redux";
import { lodashUtils, triggerInputChange } from "../../utils";
import { isEmptyOrNull } from "../../utils/form";
import { useCombinedRefs } from "../../hooks";

const renderMenuItemChildren = (option, props, idx) => {
  return React.createElement(
    Highlighter,
    {
      search: option.disabled ? "" : props.text,
    },
    getOptionLabel(option, "display")
  );
};

const getRenderTokenFunc = truncateToken => (option, props, idx) => {
  const label = getOptionLabel(option, props.labelKey);
  const token = React.createElement(
    Token,
    {
      //title: label,
      className: `ifx-autosuggest-token${(truncateToken && " truncate-token") ||
        ""}`,
      disabled: props.disabled,
      key: idx,
      onRemove: props.onRemove,
      tabIndex: props.tabIndex,
    },
    <span>{label}</span>
  );
  return truncateToken ? (
    <IFXTooltip key={idx} placement="top" content={label}>
      {token}
    </IFXTooltip>
  ) : (
    token
  );
};

const renderMenu = (results, menuProps) => {
  return React.createElement(TypeaheadMenu, {
    ...menuProps,
    options: results,
    className: cx("ifx-autosuggest-menu", menuProps.className),
  });
};

const filterBy = (option, props) => {
  const { text = "", multiple, selected } = props;

  if (
    multiple &&
    selected.some(function(o) {
      return o.id === option.id;
    })
  ) {
    return false;
  }

  if (text === "*") {
    return true;
  } else {
    return defaultFilterBy(option, { ...props, filterBy: ["display"] });
  }
};

const WRAPPER_CLASS_NAME = "position-relative ifx-autosuggest";
const SYNC_DEFAULT_PROPS = {
  minLength: 1,
  paginated: false,
  maxResults: 50,
  clearButton: true,
  emptyLabel: "No Records Found!",
  labelKey: "value",
  flip: true,
  caseSensitive: false,
  dropup: false,
  selectHintOnEnter: true,
  renderMenuItemChildren,
  filterBy,
  positionFixed: true,
  renderMenu,
  bsSize: "sm",
  defaultOpen: false,
  //enablePrepend: false,
  autoComplete: "off",
  required: false,
  align: "left",
};
const DEFAULT_PROPS = {
  ...SYNC_DEFAULT_PROPS,
  delay: 300, //Debounce
  url: AUTOSUGGEST_URL_PREFIX,
  urlSuffix: AUTOSUGGEST_URL_SUFFIX,
  promptText: "Loading...",
  searchText: "Loading...",
  useCache: false,
};

const ajaxRequestObs$ = (
  key,
  { url, urlSuffix, params, serviceName, fieldType, method = "GET" }
) => {
  //console.log(key);
  let data = { key, ...params };

  url = `${url}${serviceName ? "/" + serviceName : ""}${
    fieldType ? "/" + fieldType : ""
  }${urlSuffix}?${(method === "GET" && queryString.stringify(data)) || ""}`;

  return ajax({
    url,
    method,
    responseType: "json",
    headers: {
      ...AJAX_HEADER_APP_DEFAULT,
    },
    ...((method === "POST" && { body: data }) || {}),
  }).pipe(
    map(xhrResponse => {
      //console.log("Autosuggest Response:", xhrResponse);
      //TODO check access denied or status code != 200
      return xhrResponse.response;
    }),
    catchError(error => {
      console.error("error: ", error);
      return of([
        {
          disabled: true,
          id: "0",
          value: "System Error Occurred!",
        },
      ]);
    })
  );
};

export const IFXAutoSuggestErrorItem = ({ display }) => (
  <span className="text-danger ifx-fw-500">{display}</span>
);

const checkExistingAndFilter = (selected = [], result = []) => {
  const selectedIds = selected.map(item => item.id);
  if (selectedIds.length) {
    return result.filter(item => !selectedIds.includes(item.id));
  }
  return result;
};

const IFXAsyncAutoSuggest = memo(
  forwardRef((props, ref) => {
    const combinedRef = useCombinedRefs(ref);
    //console.log("IFXAsyncAutoSuggest start", props);
    const [isFocused, setIsFocused] = useState(false);
    const [autosuggestState, setAutosuggestState] = useState({
      isLoading: false,
      options: [],
      emptyLabel: DEFAULT_PROPS.emptyLabel,
      open: false,
    });
    const [ajaxObservable, setAjaxObservable] = useState(false);
    const renderTokenFunc = useMemo(
      () => getRenderTokenFunc(props.truncateToken),
      [props.truncateToken]
    );

    //combine and override props
    let { required, resultHandler, maxSelected, selected, ..._props } = {
      ...DEFAULT_PROPS,
      className: "",
      renderToken: renderTokenFunc,
      ...props,
    };

    let {
      url,
      urlSuffix,
      params = {},
      serviceName,
      fieldType,
      method,
      //enablePrepend,
      //inputGroupClassName = "",
      onChange,
      onFocus,
      onBlur,
      multiple,
    } = _props;

    const onChangeCustom = useCallback(
      e => {
        setAutosuggestState({
          ...autosuggestState,
          emptyLabel: _props.searchText,
          options: [],
          isLoading: false,
          open: false,
        });
        return onChange && onChange(e);
      },
      [onChange, autosuggestState]
    );

    const onFocusCustom = useCallback(
      e => {
        setIsFocused(true);
        return onFocus && onFocus(e);
      },
      [onFocus]
    );

    const onBlurCustom = useCallback(
      e => {
        setIsFocused(false);
        if (ajaxObservable) ajaxObservable.unsubscribe();
        if (!multiple && isEmptyOrNull(selected)) {
          if (combinedRef.current?.getInstance())
            combinedRef.current.getInstance().clear();
        } else if (multiple) {
          if (combinedRef.current?.getInstance()?.getInput())
            triggerInputChange(
              combinedRef.current.getInstance().getInput(),
              ""
            );
        }

        setAutosuggestState({
          ...autosuggestState,
          emptyLabel: _props.searchText,
          options: [],
          isLoading: false,
          open: false,
        });
        return onBlur && onBlur(e);
      },
      [onBlur, autosuggestState, multiple, selected, ajaxObservable]
    );

    const onInputChangeCustom = useCallback(
      (input, e) => {
        //console.log("onInputChangeCustom", input, e);
        if (isEmptyOrNull(input)) {
          if (ajaxObservable) ajaxObservable.unsubscribe();
          setAutosuggestState({
            ...autosuggestState,
            emptyLabel: _props.searchText,
            options: [],
            isLoading: false,
            open: false,
          });
        }
      },
      [ajaxObservable]
    );

    const parentClassName = useMemo(
      () =>
        cx(WRAPPER_CLASS_NAME, {
          "field-mandatory": required,
        }),
      [required]
    );
    const { controlId } = useContext(FormContext);
    //console.log("formContext controlId", controlId);

    const onSearch = useCallback(
      key => {
        //console.log("onSearch", key);
        if (isEmptyOrNull(key)) {
          setAutosuggestState({
            ...autosuggestState,
            emptyLabel: _props.searchText,
            options: [],
            isLoading: false,
            open: false,
          });
          return;
        }
        setAutosuggestState({
          ...autosuggestState,
          emptyLabel: _props.searchText,
          options: [],
          isLoading: true,
          open: true,
        });
        const _params = typeof params === "function" ? params() : params;
        const ajaxProps = {
          url,
          urlSuffix,
          params: _params,
          serviceName,
          fieldType,
          method,
        };

        let isValid = true;
        if (ajaxObservable) ajaxObservable.unsubscribe();
        setAjaxObservable(
          ajaxRequestObs$(key, ajaxProps).subscribe(result => {
            //console.log("ajaxRequestObs$ result:", result);
            let emptyLabel = _props.emptyLabel;

            result != null &&
              result.length === 1 &&
              (result[0].id === "0" || result[0].value === "0") &&
              ((emptyLabel = result[0].value) ||
                (emptyLabel = result[0].display)) &&
              (isValid = false);

            (result === null || !isValid) && (result = []);

            result = checkExistingAndFilter(selected, result);

            maxSelected &&
              selected?.length === maxSelected &&
              result.forEach(item => (item.disabled = true));

            resultHandler && (result = resultHandler({ result, isValid }));

            emptyLabel =
              (isEmptyOrNull(result) && (
                <IFXAutoSuggestErrorItem display={emptyLabel} />
              )) ||
              [];

            //console.log("result:", result);

            setAutosuggestState({
              isLoading: false,
              options: result,
              emptyLabel,
              isLoading: false,
              open: isFocused,
            });
          })
        );
      },
      [
        url,
        urlSuffix,
        params,
        serviceName,
        fieldType,
        _props,
        autosuggestState,
        ajaxObservable,
        maxSelected,
        selected,
        isFocused,
      ]
    );
    ///const { options, isLoading, emptyLabel } = autosuggestState;
    //_props = {..._props, emptyLabel, onSearch };
    const inputProps = useMemo(
      () => ({
        id: _props.id || controlId,
        autoComplete: "off",
      }),
      [_props, controlId]
    );
    const id = useMemo(() => (_props.id ? _props.id : controlId), [
      _props,
      controlId,
    ]);
    const className = useMemo(
      () => cx(DEFAULT_PROPS.className, _props.className),
      []
    );

    return (
      <div className={parentClassName}>
        <AsyncTypeahead
          {...{ selected }}
          {..._props}
          {...autosuggestState}
          onSearch={onSearch}
          onChange={onChangeCustom}
          onFocus={onFocusCustom}
          onBlur={onBlurCustom}
          //onInputChange={onInputChange}
          id={id}
          inputProps={inputProps}
          className={className}
          ref={combinedRef}
          useCache={false}
          onInputChange={onInputChangeCustom}
        />
      </div>
    );
  })
);

const IFXInMemoryAutoSuggest = memo(
  forwardRef((props, ref) => {
    const renderTokenFunc = useMemo(
      () => getRenderTokenFunc(props.truncateToken),
      [props.truncateToken]
    );
    //combine and override props
    let { required, options, emptyLabel, ..._props } = {
      ...SYNC_DEFAULT_PROPS,
      className: "",
      renderToken: renderTokenFunc,
      ...props,
    };

    const { controlId } = useContext(FormContext);

    //console.log("IFXInMemoryAutoSuggest", _props);

    //const { options, isLoading, emptyLabel } = autosuggestState;
    //_props = { isLoading, options, ..._props, emptyLabel, onSearch };

    let _emptyLabel = emptyLabel;
    let _options = options;
    if (options?.length === 1) {
      const [option] = options;
      if (option.isError) {
        _options = [];
        _emptyLabel = <IFXAutoSuggestErrorItem display={option.display} />;
      }
    }
    const inputProps = useMemo(
      () => ({
        id: _props.id || controlId,
        autoComplete: "off",
      }),
      [props, controlId]
    );

    return (
      <div
        className={cx(WRAPPER_CLASS_NAME, "non-async", {
          "field-mandatory": required,
        })}
      >
        <Typeahead
          {..._props}
          options={_options}
          emptyLabel={_emptyLabel}
          id={_props.id ? _props.id : controlId}
          inputProps={inputProps}
          className={cx(DEFAULT_PROPS.className, _props.className)}
          ref={ref}
        />
      </div>
    );
  })
);

const IFXReduxAutoSuggest = memo(
  forwardRef(({ reduxSelector, optionsConverter, ...props }, ref) => {
    const _options = useSelector(state => {
      if (typeof reduxSelector === "string") {
        return lodashUtils.get(state, reduxSelector, []);
      } else {
        return reduxSelector(state);
      }
    });

    // const options = (optionsConverter && optionsConverter(_options)) || _options;
    const options = useMemo(
      () => (optionsConverter && optionsConverter(_options)) || _options,
      [_options]
    );

    return <IFXInMemoryAutoSuggest {...props} ref={ref} options={options} />;
  })
);

export const IFXAutoSuggest = memo(
  forwardRef(
    ({ reduxSelector, inMemory = false, async = true, ...props }, ref) => {
      if (reduxSelector || inMemory) async = false;

      return (
        (async && <IFXAsyncAutoSuggest {...props} ref={ref} />) ||
        (reduxSelector && (
          <IFXReduxAutoSuggest
            {...props}
            ref={ref}
            reduxSelector={reduxSelector}
          />
        )) || <IFXInMemoryAutoSuggest {...props} ref={ref} />
      );
    }
  )
);

IFXAutoSuggest.propTypes = {
  //enablePrepend: PropTypes.bool,
  serviceName: PropTypes.string,
  fieldType: PropTypes.string,
  url: PropTypes.string,
  urlSuffix: PropTypes.string,
  truncateToken: PropTypes.bool,
  maxSelected: PropTypes.number,
};
