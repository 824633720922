import React from "react";
import { Container, Row } from "react-bootstrap";
import {
  IFXErrorAlert,
  IFXPageHeaderIconButton,
} from "@ifx-react/ifx-react-core";
import { withRouter } from "react-router-dom";

export const AccessDenied = React.memo(
  withRouter(
    ({
      path,
      children = undefined,
      history: { goBack },
      goBackEnabled = true,
    }) => (
      <Container className="pt-4" fluid>
        <IFXErrorAlert noBackground={false} className="text-center" hideIcon>
          <h3>Access Denied</h3>
          <Row>
            {children || (
              <>
                You dont have access to the URL '<b>{path}</b>'.
              </>
            )}
          </Row>
          {goBackEnabled && (
            <Row className="mt-2 justify-content-center">
              <IFXPageHeaderIconButton type="back" onClick={goBack} />
            </Row>
          )}
        </IFXErrorAlert>
      </Container>
    )
  )
);
