import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { IFXDatePicker } from "../../../components/AppComponents";
import { IFXFieldWrapper } from "../../../components/AppComponents/IFXFieldWrapper";

export const DatePickerCard = () => {
  const [departureDate, setDepartureDate] = useState(null);
  //console.log("selected  departureDate is : " + JSON.stringify(departureDate));
  const [arrivalDate, setArrivalDate] = useState({ serialized: "02282020" });
  // console.log("selected  arrivalDate is : " + JSON.stringify(arrivalDate));

  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title className="mb-4">Date Picker</Card.Title>
        <IFXFieldWrapper
          type="group"
          label="Departure Date"
          controlId="departureDate1"
          alwaysFloat={(() => departureDate != null)()}
        >
          <IFXDatePicker
            selected={departureDate}
            onChange={v => {
              setDepartureDate(v);
            }}
          />
        </IFXFieldWrapper>

        <Card.Title className="my-4">Date Picker with Default Value</Card.Title>
        <IFXFieldWrapper
          type="group"
          label="Arrival Date"
          controlId="arrivalDate1"
          alwaysFloat={(() => arrivalDate != null)()}
        >
          <IFXDatePicker
            selected={arrivalDate}
            onChange={v => {
              setArrivalDate(v);
            }}
            serializeFormat="MMddyyyy" // this is optional default is MM/dd/yyyy
          />
        </IFXFieldWrapper>
      </Card.Body>
    </Card>
  );
};
