import React, { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  IFXModal,
  IFXModalBody,
  IFXModalHeader,
  IFXModalFooter,
  IFXButton,
  IFXFormValidation,
  ifxJsonAjax,
  IFXFieldWrapper,
  CoreValidationRuleCodes,
  useObservableCallback,
  IFXTextBox,
  useToast,
} from "@ifx-react/ifx-react-core";
import { Subject } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { useFormContext } from "react-hook-form";
import { IFXUserDatePicker } from "../../../components/common/DatePickers";
import { subDays, format } from "date-fns";
import { UserRegistrationContext } from "./UserRegistration";
import { EDIT_ACCESS_PROFILE_URL } from "../../../const/portal/endpoints";
import { LayoutActions } from "../../../store/actions/common/actionCreators";
import { CommonToastConsts } from "../../../const/common/toastConsts";

const getEditAccessProfileObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax.post(EDIT_ACCESS_PROFILE_URL, params).pipe(
        map(xhrResponse => xhrResponse),
        catchError(error => {
          console.error("Error in Edit Access Profile", error);
          errorHandler(error);
          return [];
        })
      )
    )
  );

export const EditAccessProfileModal = ({
  onHide,
  show,
  editAccessProfile,
  reloadGrid,
}) => {
  const dispatch = useDispatch();

  const { ifxErrorToast } = useToast();

  const { userData } = useContext(UserRegistrationContext);

  const defaultValues = {
    accessProfileName: editAccessProfile.name,
    accessProfileDesc: editAccessProfile.description,
    effDate: {
      dateValue: new Date(editAccessProfile.effDate),
      serialized: format(new Date(editAccessProfile.effDate), "MM/dd/yyyy"),
    },
    expDate: {
      dateValue: new Date(editAccessProfile.expDate),
      serialized: format(new Date(editAccessProfile.expDate), "MM/dd/yyyy"),
    },
  };

  const editAccessProfile$ = useMemo(() => {
    return getEditAccessProfileObj$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        ifxErrorToast(CommonToastConsts.ERROR);
        onHide();
      },
    });
  }, []);

  const editUserProfile = formData => {
    console.log(formData);
    dispatch(
      LayoutActions.SET_LOADER_SHOW({
        loaderText: "Loading...",
      })
    );
    editAccessProfile$.next({
      id: editAccessProfile.id,
      userId: userData.userId,
      userName: userData.userName,
      accessProfileId: editAccessProfile.accessProfileId,
      effDate: formData.effDate.serialized || null,
      expDate: formData.expDate.serialized || null,
    });
  };

  useObservableCallback(
    editAccessProfile$,
    response => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      onHide();
      reloadGrid();
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      onHide();
    }
  );

  return (
    <>
      <IFXModal show={show} onHide={onHide} size="md">
        <IFXModalHeader>Edit Access Profile</IFXModalHeader>
        <IFXFormValidation
          onSubmit={editUserProfile}
          defaultValues={defaultValues}
        >
          <IFXModalBody>
            <EditAccessProfileForm editAccessProfile={editAccessProfile} />
          </IFXModalBody>
          <IFXModalFooter>
            <IFXButton type="save" name="Save" />
            <IFXButton type="cancel" name="Cancel" onClick={onHide} />
          </IFXModalFooter>
        </IFXFormValidation>
      </IFXModal>
    </>
  );
};

const EditAccessProfileForm = ({ editAccessProfile }) => {
  const { watch } = useFormContext();
  const effDate = watch("effDate");
  const expDate = watch("expDate");

  return (
    <>
      <IFXFieldWrapper
        label="Access Profile Name"
        controlId="accessProfileName"
        name="accessProfileName"
        rules={[CoreValidationRuleCodes.required]}
        disabled
      >
        <IFXTextBox />
      </IFXFieldWrapper>
      <IFXFieldWrapper
        label="Access Profile Description"
        controlId="accessProfileDesc"
        name="accessProfileDesc"
        rules={[CoreValidationRuleCodes.required]}
        disabled
      >
        <IFXTextBox />
      </IFXFieldWrapper>
      <IFXFieldWrapper
        type="datepicker"
        label="Eff date"
        controlId="effDate"
        name="effDate"
        rules={[CoreValidationRuleCodes.required]}
        disabled={
          editAccessProfile.effDateLong < new Date().setHours(0, 0, 0, 0)
        }
      >
        <IFXUserDatePicker
          minDate={subDays(new Date(), 0)}
          maxDate={
            expDate && expDate.dateValue && subDays(expDate.dateValue, 0)
          }
        />
      </IFXFieldWrapper>
      <IFXFieldWrapper
        type="datepicker"
        label="End date"
        controlId="expDate"
        name="expDate"
        rules={[CoreValidationRuleCodes.required]}
      >
        <IFXUserDatePicker
          minDate={
            effDate &&
            effDate.dateValue &&
            !(editAccessProfile.effDateLong < new Date().setHours(0, 0, 0, 0))
              ? subDays(effDate.dateValue, 0)
              : subDays(new Date(), 0)
          }
        />
      </IFXFieldWrapper>
    </>
  );
};
