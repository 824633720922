import React from "react";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import cx from "classnames";
import { format, set, parse } from "date-fns";
import { InputGroup, Form, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { CalenderContainer } from "./IFXDatePicker";

const DEFAULT_PROPS = {
  className: "ifx-time-range-picker",
  showTimeSelect: true,
  showTimeSelectOnly: true,
  dateFormat: "HH:mm",
  timeFormat: "HH:mm",
  timeIntervals: 15,
  timeCaption: "Time",
  size: "sm",
  serializeFormat: "HH:mm",
  isSerialized: true,
  autoComplete: "off",
  minTime: set(new Date(), {
    year: 1976,
    month: 0,
    date: 1,
    hours: 0,
    minutes: 0,
  }),
  maxTime: set(new Date(), {
    year: 1976,
    month: 0,
    date: 1,
    hours: 23,
    minutes: 59,
  }),
  split: true,
  isClearable: true,
};

const IFXTimeRangePicker = ({
  isInvalid = [],
  required = [],
  fromColProps,
  toColProps,
  ...props
}) => {
  const {
    onFocus = [],
    onBlur = [],
    onCalendarClose = [],
    controlId = [],
    label = [],
    alwaysFloat = [],
    wrapperState = { from: {}, to: {} },
    selected = {},
  } = props;
  //console.log(props);
  //const { rangeErrorComponent = undefined } = props;
  //const { fromError = undefined, toError = undefined } = rangeErrorComponent;
  const _props = { ...DEFAULT_PROPS, className: "", ...props };

  let {
    inputGroupClassName = "",
    isSerialized,
    serializeFormat,
    minTime: fromMin,
    minTime: toMin,
    maxTime: fromMax,
    maxTime: toMax,
    split,
  } = _props;

  let onFocusFrom,
    onFocusTo,
    onBlurFrom,
    onBlurTo,
    onCalendarCloseFrom,
    onCalendarCloseTo;
  if (Array.isArray(onFocus)) {
    [onFocusFrom, onFocusTo] = onFocus;
  } else {
    onFocusFrom = onFocusTo = onFocus;
  }

  if (Array.isArray(onBlur)) {
    [onBlurFrom, onBlurTo] = onBlur;
  } else {
    onBlurFrom = onBlurTo = onBlur;
  }
  if (Array.isArray(onCalendarClose)) {
    [onCalendarCloseFrom, onCalendarCloseTo] = onCalendarClose;
  } else {
    onCalendarCloseFrom = onCalendarCloseTo = onCalendarClose;
  }

  const onChange = (key, v) => {
    if (v != null) {
      v = set(v, {
        year: 1976,
        month: 0,
        date: 1,
        seconds: 0,
        milliseconds: 0,
      });

      if (isSerialized)
        v = { serialized: format(v, _props.serializeFormat), dateValue: v };
    }
    v = { ...selected, [key]: v };

    if (_props.onChange) _props.onChange(v);
  };

  let _selected = { from: null, to: null };
  if (isSerialized && selected != null) {
    if (selected.from) {
      _selected.from = parse(
        selected.from.serialized,
        serializeFormat,
        new Date()
      );
      _selected.from = set(_selected.from, { year: 1976, month: 0, date: 1 });
    }
    if (selected.to) {
      fromMax = _selected.to = parse(
        selected.to.serialized,
        serializeFormat,
        new Date()
      );
      _selected.to = set(_selected.to, { year: 1976, month: 0, date: 1 });
    }
  }

  if (_selected.from) toMin = _selected.from;
  if (_selected.to) fromMax = _selected.to;

  if (split) {
    return (
      <Row>
        <Col xs="6" {...fromColProps}>
          <div
            className={cx(
              "range-field-wrapper range-from position-relative",

              {
                "floating-label-active":
                  wrapperState.from.isFocused || alwaysFloat[0],
                "field-mandatory": required[0],
              },
              isInvalid[0] && "is-invalid"
            )}
          >
            <Form.Control
              {..._props}
              className={cx(DEFAULT_PROPS.className, _props.className)}
              id={controlId[0]}
              key="from"
              as={DatePicker}
              onFocus={onFocusFrom}
              onBlur={onBlurFrom}
              onCalendarClose={onCalendarCloseFrom}
              selected={_selected.from}
              onChange={v => {
                onChange("from", v);
              }}
              minTime={fromMin}
              maxTime={fromMax}
              popperContainer={CalenderContainer}
            />
            {label ? (
              <Form.Label htmlFor={controlId[0]}>{label[0]}</Form.Label>
            ) : null}
            <Form.Control.Feedback type="ifx-field-error invalid">
              {wrapperState.from.errorMessage}
            </Form.Control.Feedback>
          </div>
        </Col>
        <Col xs="6" {...toColProps}>
          <div
            className={cx(
              "range-field-wrapper range-to position-relative",

              {
                "floating-label-active":
                  wrapperState.to.isFocused || alwaysFloat[1],
                "field-mandatory": required[1],
              },
              isInvalid[1] && "is-invalid"
            )}
          >
            <Form.Control
              {..._props}
              className={cx(DEFAULT_PROPS.className, _props.className)}
              id={controlId[1]}
              key="to"
              as={DatePicker}
              onFocus={onFocusTo}
              onBlur={onBlurTo}
              onCalendarClose={onCalendarCloseTo}
              selected={_selected.to}
              onChange={v => {
                onChange("to", v);
              }}
              minTime={toMin}
              maxTime={toMax}
              popperContainer={CalenderContainer}
            />
            {label ? (
              <Form.Label htmlFor={controlId[1]}>{label[1]}</Form.Label>
            ) : null}
            <Form.Control.Feedback type="ifx-field-error invalid">
              {wrapperState.to.errorMessage}
            </Form.Control.Feedback>
          </div>
        </Col>
      </Row>
    );
  } else {
    return (
      <>
        <InputGroup
          size={_props.size}
          className={cx(inputGroupClassName)}
          title="Time Range"
        >
          {/* <InputGroup.Prepend>
          <div className="input-group-text">
            <FontAwesomeIcon icon={faClock} />
          </div>
        </InputGroup.Prepend> */}
          <div
            className={cx(
              "range-field-wrapper range-from position-relative",
              {
                "floating-label-active":
                  wrapperState.from.isFocused || alwaysFloat[0],
                "field-mandatory": required[0],
              },
              isInvalid[0] && "is-invalid"
            )}
          >
            <Form.Control
              {..._props}
              className={cx(DEFAULT_PROPS.className, _props.className)}
              id={controlId[0]}
              key="from"
              as={DatePicker}
              onFocus={onFocusFrom}
              onBlur={onBlurFrom}
              onCalendarClose={onCalendarCloseFrom}
              selected={_selected.from}
              onChange={v => {
                onChange("from", v);
              }}
              minTime={fromMin}
              maxTime={fromMax}
              popperContainer={CalenderContainer}
            />
            {label ? (
              <Form.Label htmlFor={controlId[0]}>{label[0]}</Form.Label>
            ) : null}
            <Form.Control.Feedback type="ifx-field-error invalid">
              {wrapperState.from.errorMessage}
            </Form.Control.Feedback>
          </div>
          <div
            className={cx(
              "range-field-wrapper range-to position-relative",
              {
                "floating-label-active":
                  wrapperState.to.isFocused || alwaysFloat[1],
                "field-mandatory": required[1],
              },
              isInvalid[1] && "is-invalid"
            )}
          >
            <Form.Control
              {..._props}
              className={cx(DEFAULT_PROPS.className, _props.className)}
              id={controlId[1]}
              key="to"
              as={DatePicker}
              onFocus={onFocusTo}
              onBlur={onBlurTo}
              onCalendarClose={onCalendarCloseTo}
              selected={_selected.to}
              onChange={v => {
                onChange("to", v);
              }}
              minTime={toMin}
              maxTime={toMax}
              popperContainer={CalenderContainer}
            />
            {label ? (
              <Form.Label htmlFor={controlId[1]}>{label[1]}</Form.Label>
            ) : null}
            <Form.Control.Feedback type="ifx-field-error invalid">
              {wrapperState.to.errorMessage}
            </Form.Control.Feedback>
          </div>
        </InputGroup>
      </>
    );
  }
};
IFXTimeRangePicker.prototype = {
  startTime: PropTypes.string,
  setStartTime: PropTypes.string,
  endTime: PropTypes.string,
  setEndTime: PropTypes.func,
  timeIntervals: PropTypes.string,
  colspan: PropTypes.number,
  fromTimeName: PropTypes.string,
  toTimeName: PropTypes.string,
};
export { IFXTimeRangePicker };
