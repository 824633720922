import { combineReducers } from "redux";
import { DetailedInvoiceReducer } from "./DetailedInvoiceReducer";
import { InvoiceStatusReducer } from "./InvoiceStatusReducer";
import { StationInvoiceParameterReducer } from "./StationInvoiceParameterReducer";
import { InvPendingRevAndBrowseInvPeriodsReducer } from "./InvPendingRevAndBrowseInvPeriodsReducer";
import { SearchInvoiceReducer } from "./SearchInvoiceReducer";
import { RecentlyUpdatedInvoiceReducer } from "./RecentlyUpdatedInvoiceReducer";
import InvoicingPeriodReducer from "./InvoicingPeriodReducer";
import { RecurrenceTemplateReducer } from "./RecurrenceTemplateReducer";
import SearchRulesReducer from "./SearchRulesReducer";
import { RapRequestReducer } from "./RapRequestReducer";
import { SearchRemittanceReducer } from "./SearchRemittanceReducer";
import { ErrorCodesReducer } from "./ErrorCodesReducer";

const appEsvReducer = combineReducers({
  DetailedInvoiceReducer,
  InvoiceStatusReducer,
  StationInvoiceParameterReducer,
  InvPendingRevAndBrowseInvPeriodsReducer,
  SearchInvoiceReducer,
  RecentlyUpdatedInvoiceReducer,
  InvoicingPeriodReducer,
  RecurrenceTemplateReducer,
  SearchRulesReducer,
  RapRequestReducer,
  SearchRemittanceReducer,
  ErrorCodesReducer,
});

export function EsvReducer(state, action) {
  switch (action.type) {
    case "RESET_REDUX": {
      console.log("RESET_REDUX EsvReducer");
      return appEsvReducer(undefined, action);
    }
    default:
      return appEsvReducer(state, action);
  }
}
