export const ESSTasks = {
  ESSApplication: "ESS4App",
  TEST: "TEST",
  SearchRule: "SearchRule",
  FSSCNView: "FSSCNView",
  FSSCompareView: "FSSCompareView",
  GlobCatView: "GlobCatView",
  RuleSetView: "RuleSetView",
  RuleSearchView: "RuleSearchView",
  RuleCreate: "RuleCreate",
  GlobCatListView: "GlobCatListView",
  RuleSetListView: "RuleSetListView",
  ViewSDFCreate: "ViewSDFCreate",
  SDFManageView: "SDFManageView",
  QuerySDF: "QuerySDF",
  ViewSDFQuery: "ViewSDFQuery",
  SDFCompareView: "SDFCompareView",
  SDFRel: "SDFRel",
  RelSDFViewQuery: "RelSDFViewQuery",
  SDFViewQuery: "SDFViewQuery",
  PAXForcstMethodView: "PAXForcstMethodView",
  SDFCreateView: "SDFCreateView",
  ViewSvcFltTagQuery: "ViewSvcFltTagQuery",
  SchSetView: "SchSetView",
  ViewSvcResults: "ViewSvcResults",
  ESSViewOrderOfService: "ESSViewOrderOfService",
  SchAdmView: "SchAdmView",
  HypotheticalFSS: "FSSCreate", //ViewFSSCreate
  FSSRel: "FSSRel",
  RelFSSViewQuery: "RelFSSViewQuery",
  SchAdmView: "SchAdmView",
  ViewSvcResults: "ViewSvcResults",
  SDFManageView: "SDFManageView",
  QuerySDF: "QuerySDF",
  SDFViewQuery: "SDFViewQuery",
  RelFSSFltDetView: "RelFSSFltDetView",
  FSSListView: "FSSListView",
  MasterSchQueryView: "MasterSchQueryView",
  FltSchdCreate: "FltSchdCreate",
  FSQueryView: "FSQueryView",
  FltSchdPrint: "FltSchdPrint",
  MasterSchCompareView: "MasterSchCompareView",
  CUPSearch: "CUPSearch",
  ViewSpecDetail: "ViewSpecDetail",
  FltTagResultsView: "FltTagResultsView",
  RuleSearchView: "RuleSearchView",
  ViewSvcFltTagQuery: "ViewSvcFltTagQuery",
  ESSViewOrderOfService: "ESSViewOrderOfService",
  SchSetView: "SchSetView",
  ViewFSQuery: "ViewFSQuery",
  FSSViewQuery: "FSSViewQuery",
  ESSQueryFSS: "ESSQueryFSS",
  RuleSetCopy: "RuleSetCopy",
  RuleSetDelete: "RuleSetDelete",
  RuleSetCreate: "RuleSetCreate",
  RuleSetUpdate: "RuleSetUpdate",
  RuleGrpCreate: "RuleGrpCreate",
  RuleGrpDelete: "RuleGrpDelete",
  RuleCreate: "RuleCreate",
  RuleCopy: "RuleCopy",
  RuleDelete: "RuleDelete",
  RuleUpdate: "RuleUpdate",

  /** FlightTag Task Const.*/
  AddFltTag: "AddFltTag",
  FltTagCreate: "FltTagCreate",
  DeleteFltTag: "DeleteFltTag",
  FltTagDelete: "FltTagDelete",
  EditFltTag: "EditFltTag",
  FltTagUpdate: "FltTagUpdate",

  AddFltTagCat: "AddFltTagCat",
  FltTagCatCreate: "FltTagCatCreate",
  DeleteFltTagCat: "DeleteFltTagCat",
  FltTagCatDelete: "FltTagCatDelete",
  EditFltTagCat: "EditFltTagCat",
  FltTagCatUpdate: "FltTagCatUpdate",

  ViewFltTagResults: "ViewFltTagResults",
  ViewFltTagCatResults: "ViewFltTagCatResults",

  SchSetCreate: "SchSetCreate",
  SchSetUpdate: "SchSetUpdate",
  SchSetDelete: "SchSetDelete",
  FSSDelete: "FSSDelete",
  FSSPubAndRel: "FSSPubAndRel",
  FSSJobDelete: "FSSJobDelete",
  FSSUserInOutBoundView: "FSSUserInOutBoundView",
  ViewFltDetail: "ViewFltDetail",
  FSSFltRulelogView: "FSSFltRulelogView",
  FSSDownload: "FSSDownload",
  SDFJobDelete:"SDFJobDelete",
  SDFDelete:"SDFDelete",
  GenerateEP7:"GenerateEP7"
};
