export const GalleyPlanningMenu = {
  dashboard: {
    authorize: { hasTask: "Galley Planning" },
    icon: "galleys",
    title: "GALLEYS",
    to: "/app/gp/dashboard",
    source: "/app/gp/",
  },
  menu: [],
};
