import React, { useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import {
  IFXTimePicker,
  IFXDropdown,
  IFXDateRangePicker,
  IFXTextBox,
  IFXDatePicker,
  IFXFieldWrapper,
  IFXAutoSuggest,
  IFXTimeRangePicker,
  IFXButton,
  IFXFormLayout,
  IFXButtonGroup,
  IFXFormValidation,
} from "../../../components/AppComponents";
import { Search, Clear } from "../../../const";
import { substitute } from "../../../utils/string";
import { Row } from "react-bootstrap";

//-----------------------------------------
// Generic Constants and Utilities.
//-----------------------------------------
const labels = {
  actype: "A/C Type",
  station: "Stations",
  status: "Status",
  fltTime: "Flight Time",
  fltDate: "Flight Date",
  caterer: "Caterer",
};
const defaultColWidth = "col-xl-12 col-lg-12 col-md-12 col-sm-12";
const resetObj = {
  actype: "",
  station: [],
  status: [],
  fltTime: null,
  fltDate: null,
  caterer: [],
  dateRange: [],
  timeRange: [],
};
//-----------------------------------------
// Footer Component
//-----------------------------------------
const Footer = React.memo(() => {
  const { reset } = useFormContext();
  const onReset = useCallback(() => reset(resetObj), [resetObj, reset]);
  return (
    <Row>
      <IFXButtonGroup marginClassName="mt-ifx-125">
        <IFXButton type="search" />
        <IFXButton type="clear" onClick={onReset} />
      </IFXButtonGroup>
    </Row>
  );
});

//----------------------------------
// Custom Validations
//----------------------------------
const customValidation = {
  noSpcChar: ({ value, label }, regExp) =>
    (regExp.test(value) &&
      substitute("No Special Characters allowed on ${label}", { label })) ||
    undefined,
};

//----------------------------------
// generic methods
//----------------------------------

const onChangeTextBox = evt => {
  // your logic here
  console.log("onChangeTextBox", evt);
};

const onChangeAutosuggest = v => {
  // your logic here
  console.log("onChangeAutosuggest", v);
  //return e.target.value; // required
};

//---------------------------------
// Non Form field component
//---------------------------------
const NonFormComponent = props => (
  <>
    <IFXFieldWrapper label={"Non Form Field"} controlId="test" {...props}>
      <IFXTextBox />
    </IFXFieldWrapper>
  </>
);

//----------------------------------
// components for form
//----------------------------------
const ACTypeComponent = props => (
  <>
    <IFXFieldWrapper label={labels.actype} controlId="actype" {...props}>
      <IFXTextBox />
    </IFXFieldWrapper>
  </>
);

const StationComponent = props => (
  <>
    <IFXFieldWrapper
      type="group"
      label={labels.station}
      controlId="stations"
      {...props}
    >
      <IFXAutoSuggest
        multiple={true}
        fieldType="station"
        params={{
          TaskName: "eSOViewSmartOrderDash",
        }}
      />
    </IFXFieldWrapper>
  </>
);

const DatePickerComponent = props => (
  <>
    <IFXFieldWrapper
      type="range"
      label={["From Date", "To Date"]}
      controlId={["fromDateId", "toDateId"]}
      {...props}
    >
      <IFXDateRangePicker />
    </IFXFieldWrapper>
  </>
);

const StatusComponent = props => (
  <>
    <IFXFieldWrapper
      label={labels.status}
      controlId="statusId"
      type="dropdown"
      {...props}
    >
      <IFXDropdown />
    </IFXFieldWrapper>
  </>
);

const CatererComponent = props => (
  <>
    <IFXFieldWrapper
      label={labels.caterer}
      controlId="catererId"
      type="dropdown"
      {...props}
    >
      <IFXDropdown />
    </IFXFieldWrapper>
  </>
);

const FlightDateComponent = props => {
  const {
    errors: { fltDate: { message: errorMessage = "" } = {} },
  } = useFormContext() || {};
  return (
    <>
      <IFXFieldWrapper
        type="group"
        label={labels.fltDate}
        controlId="fltDate"
        {...props}
      >
        <IFXDatePicker />
      </IFXFieldWrapper>
    </>
  );
};

const FlightTimeComponent = props => (
  <>
    <IFXFieldWrapper
      type="group"
      label={labels.fltTime}
      controlId="fltTimeID"
      {...props}
    >
      <IFXTimePicker />
    </IFXFieldWrapper>
  </>
);

const TimePickerComponent = props => (
  <>
    <IFXFieldWrapper
      type="range"
      label={["From Time", "To Time"]}
      controlId={["fromTimeDVId", "toTimeDVd"]}
      {...props}
    >
      <IFXTimeRangePicker />
    </IFXFieldWrapper>
  </>
);

//----------------------------------
// FilterValidationDemo component
//----------------------------------
function FilterValidationDemo() {
  const defaultValues = {
    actype: "ACT",
    station: [
      { id: "20959", value: "ATH" },
      { id: "20960", value: "ATL" },
    ],
  };
  const statusList = getStatusList();
  const catererList = getCatererList();
  // below is merely for example
  const [manualValidation, setManualValidation] = useState(false);
  const triggerManualValidation = useCallback(
    () => setManualValidation(true),
    []
  );
  const stopManualValClb = useCallback(() => setManualValidation(false), []);
  const [nonFormFld, setNonFormFld] = useState("");

  return (
    <div>
      <IFXFormValidation
        onSubmit={submitForm}
        customValidationRules={customValidation}
        manualValidation={manualValidation}
        stopManualValClb={stopManualValClb}
      >
        <div>
          <IFXFormLayout>
            <ACTypeComponent
              {...{
                onChange: onChangeTextBox,
                defaultValue: defaultValues["actype"],
                name: "actype",
                rules: ["required", { minLength: [3] }, { noSpcChar: [/\W/] }],
              }}
            />
            <StationComponent
              {...{
                onChange: onChangeAutosuggest,
                name: "station",
                defaultValue: defaultValues["station"],
                rules: ["required"],
                type: "autosuggest",
              }}
            />
            <DatePickerComponent
              {...{
                name: "dateRange",
                rules: ["required"],
                //type: "datepicker",
                valueName: "selected",
              }}
            />
            <StatusComponent
              {...{
                name: "status",
                rules: ["required"],
                valueName: "selected",
                options: statusList,
              }}
            />
            <CatererComponent
              {...{
                isFormField: true,
                name: "caterer",
                rules: ["required"],
                valueName: "selected",
                options: catererList,
              }}
            />
            <FlightDateComponent
              {...{
                isFormField: true,
                name: "fltDate",
                rules: ["required"],
                valueName: "selected",
              }}
            />
            <FlightTimeComponent
              {...{
                isFormField: true,
                name: "fltTime",
                rules: ["required"],
                valueName: "selected",
              }}
            />
            <TimePickerComponent
              {...{
                isFormField: true,
                name: "timeRange",
                rules: ["required"],
                valueName: "selected",
              }}
            />
          </IFXFormLayout>
          <Footer />
        </div>
      </IFXFormValidation>
      <Row>
        <IFXButtonGroup
          marginClassName="mt-ifx-125"
          justifyClassName="justify-content-start"
        >
          <IFXButton
            name="Trigger Manual Validation"
            onClick={triggerManualValidation}
          />
        </IFXButtonGroup>
      </Row>

      <IFXFormLayout>
        <NonFormComponent
          {...{
            name: "test",
            isFormField: false,
            onChange: ({ target: { value } }) => setNonFormFld(value),
            value: nonFormFld,
          }}
        />
      </IFXFormLayout>
    </div>
  );
}

export { FilterValidationDemo };

function getStatusList() {
  return [
    { label: "Initial", value: "INI" },
    { label: "Prelim", value: "PRE" },
    { label: "Final", value: "FIN" },
    { label: "Confirmed", value: "CON" },
    { label: "Closed", value: "CLO" },
    { label: "Completed", value: "COM" },
    { label: "Cancelled", value: "CAN" },
  ];
}

function getCatererList() {
  return [
    { label: "GGI -  GATE GOURMET INC", value: 2005 },
    { label: "LSG - LSG SKY CHEFS INC", value: 2006 },
    { label: "GG - Gate Gourmet", value: 2008 },
  ];
}

const submitForm = data => {
  alert("Success!!");
  console.log(JSON.stringify(data, null, 2));
};
