import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";
import { IFXButton } from "./IFXButton";
import { ButtonMode, ColorOptions } from "../../components/AppComponents";
import { IFXMultiSelectSwap } from "./IFXMultiSelectSwap";

export const IFXMultiSelect = props => {
  const inputList = useRef([]);

  let newSelectData = "";

  let initialRightSide = "";

  if (props.reSelectData.length) {
    newSelectData = props.data.filter(a => {
      return !props.reSelectData.some(b => {
        return b.value === a.value;
      });
    });
    initialRightSide = props.reSelectData;
  } else {
    newSelectData = props.data;
    initialRightSide = [];
  }

  useEffect(() => {
    setDefaultVal(newSelectData);
  }, []);

  const [getDefaultVal, setDefaultVal] = useState([]);

  const [getLeftSideVal, setLeftSideVal] = useState([]);
  const [getRightSideVal, setRightSideVal] = useState(initialRightSide);
  const [getRgtVal, setRgtVal] = useState([]);
  const [disableArrow, setdisableArrow] = useState("right");

  function handleChange(e, type) {
    const id = e.target.id;
    if (id) {
      if (getLeftSideVal.some(item => item.id === id)) {
        inputList.current[id].className = "";
        const data = getLeftSideVal.filter(item => item.id !== id);
        setLeftSideVal(data);
      } else {
        inputList.current[id].className = "active-custom";
        const data1 = getDefaultVal.filter(item => item.id === id);
        setLeftSideVal(prev => [...prev, ...data1]);
      }
      setdisableArrow("right");
    }
  }

  function rightSideArray(data) {
    setRgtVal(data);
    setdisableArrow("left");
  }

  function leftClick() {
    let data = getDefaultVal.filter(a => {
      return !getLeftSideVal.some(b => {
        return b.value === a.value;
      });
    });
    const finalLeftArray = Array.from(
      new Set([...getRightSideVal, ...getLeftSideVal])
    );
    setRightSideVal(finalLeftArray);
    setDefaultVal(data);
    props.selectedVal(finalLeftArray);
  }

  function rightClick() {
    let data = getRightSideVal.filter(a => {
      return !getRgtVal.some(b => {
        return b.value === a.value;
      });
    });
    let val = [...getDefaultVal, ...getRgtVal].sort((a, b) => a.id - b.id);
    const finalRightArray = Array.from(new Set(val));
    setLeftSideVal(data);
    setRightSideVal(data);
    setDefaultVal(finalRightArray);
    props.selectedVal(data);
  }

  return (
    <Row id="multiselectedCard">
      <Col md={5}>
        <Form.Group controlId="exampleForm.ControlSelect2">
          <Form.Label>{props.containerLeftName}</Form.Label>
          <ul className="ifx-custom-ul" onClick={e => handleChange(e)}>
            {getDefaultVal.map(item => (
              <li
                key={item.id}
                id={item.id}
                ref={el => (inputList.current[item.id] = el)}
              >
                {item.display}
              </li>
            ))}
          </ul>
        </Form.Group>
      </Col>
      <Col md={2}>
        <div className="selected-control-icons">
          <div>
            <IFXButton
              key="forward"
              mode={ButtonMode.ICON_MINI}
              icon="forward"
              buttonConfig={false}
              color={ColorOptions.ifxIconPrimary}
              iconProps={{ iconSource: "svg" }}
              backgroundStyle="btn-transparent"
              outline={true}
              variant="ifx-monochrome"
              onClick={() => leftClick()}
              disabled={disableArrow === "left" ? true : false}
            />
          </div>
          <div>
            <IFXButton
              key="forward"
              mode={ButtonMode.ICON_MINI}
              icon="forward"
              buttonConfig={false}
              color={ColorOptions.ifxIconPrimary}
              iconProps={{ iconSource: "svg" }}
              backgroundStyle="btn-transparent"
              outline={true}
              variant="ifx-monochrome"
              onClick={() => rightClick()}
              className="left-forward-icons"
              disabled={disableArrow === "right" ? true : false}
            />
          </div>
        </div>
      </Col>
      <Col md={5}>
        <IFXMultiSelectSwap
          containerName={props.containerRightName}
          data={getRightSideVal}
          swap={true}
          rightSideArray={data => rightSideArray(data)}
        />
      </Col>
    </Row>
  );
};

IFXMultiSelect.prototype = {
  containerLeftName: PropTypes.string,
  containerRightName: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedVal: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    })
  ).isRequired,
  reSelectData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    })
  ),
};
