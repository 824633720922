import { combineReducers } from "redux";
import { LayoutReducer } from "./LayoutReducer";
import { BreadcrumbReducer } from "./BreadcrumbReducer";
import { CarrierPropertiesReducer } from "./CarrierPropertiesReducer";
import { DocumentCenterReducer } from "./DocumentCenterReducer";
import { LegacyReducer } from "./LegacyReducer";
import { CodeValReducer } from "./CodeValReducer";
import { UserStationsReducer } from "./UserStationsReducer";
import { BuildInfoReducer } from "./BuildInfoReducer";
import { HelpLinkReducer } from "./HelpLinkReducer";

const appCommonReducer = combineReducers({
  LayoutReducer,
  BreadcrumbReducer,
  CarrierPropertiesReducer,
  DocumentCenterReducer,
  LegacyReducer,
  CodeValReducer,
  UserStationsReducer,
  BuildInfoReducer,
  HelpLinkReducer,
});

export function CommonReducer(state, action) {
  switch (action.type) {
    case "RESET_REDUX": {
      console.log("RESET_REDUX CommonReducer", state);
      return appCommonReducer(undefined, action);
    }
    default:
      return appCommonReducer(state, action);
  }
}
