import React, { useMemo } from "react";
import {
  useUserTaskGranted,
  useUserTaskAnyGranted,
} from "../../../hooks/portal/userSecurityHooks";
import { useSelector } from "react-redux";

export const Authorize = ({ authorize = {}, children, notAuthorized }) => {
  const { hasTask, hasAllTasks, hasAnyTask } = authorize;
  const authTaskList = useSelector(
    state => state.PortalReducer.UserContextReducer.userInfo.taskList
  );

  const hasTaskAuth = useUserTaskGranted(hasTask);
  const hasAllTasksAuth = useUserTaskGranted(hasAllTasks);
  const hasAnyTaskAuth = useUserTaskAnyGranted(hasAnyTask);

  const isAuthorized = useMemo(() => {
    if (authTaskList && authTaskList.length > 0) {
      if (hasTask && !hasTaskAuth) {
        return false;
      } else if (hasAllTasks && hasAllTasks.length > 0 && !hasAllTasksAuth) {
        return false;
      } else if (hasAnyTask && hasAnyTask.length > 0 && !hasAnyTaskAuth) {
        return false;
      } else {
        return true;
      }
    }
    return null;
  }, [
    authTaskList,
    hasTask,
    hasAllTasks,
    hasAnyTask,
    hasTaskAuth,
    hasAllTasksAuth,
    hasAnyTaskAuth,
  ]);

  if (isAuthorized === true) {
    return <>{children}</>;
  } else {
    if (isAuthorized === false && notAuthorized) {
      return <>{notAuthorized}</>;
    } else {
      return null;
    }
  }
};
