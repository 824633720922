import {
  createAction,
  createSetterAction,
  createAsyncAction,
} from "../createAction";
import * as types from "./actionTypes";

export const StoreDetailedInvSelectionAction = createAsyncAction(
  types.STORE_DETAILED_INV_SELECTION_ACTION
);

export const LoadInvoiceStatus = createAsyncAction(types.INVOICE_STATUS_ACTION);

export const StoreInvoiceStatus = createAction(
  types.INVOICE_STATUS_ACTION,
  types.STORE_INVOICE_STATUS_ACTION
);

export const StoreStationInvSelectionAction = createAsyncAction(
  types.STORE_STATION_INV_SELECTION_ACTION
);

export const StoreInvPendingAndBrowseInvSelectionAction = createAsyncAction(
  types.STORE_INV_PENDING_REV_AND_BROWSE_INV_SELECTION_ACTION
);

export const StoreSearchInvoiceSelectionAction = createAsyncAction(
  types.STORE_SEARCH_INV_SELECTION_ACTION
);

export const StoreRecentInvoiceSelectionAction = createAsyncAction(
  types.STORE_RECENT_INV_SELECTION_ACTION
);

export const StoreRecurringTemplateAction = createAsyncAction(
  types.STORE_RECURRING_TEMPLATE_SELECTION_ACTION
);

export const StoreSearchRulesAction = createAsyncAction(
  types.STORE_SEARCH_RULES_SELECTION_ACTION
);

export const StoreSearchInvoicingPeriodAction = createAsyncAction(
  types.STORE_SEARCH_INVOICING_PERIOD_ACTION
);

export const StoreSearchRapAction = createAsyncAction(
  types.STORE_SEARCH_RAP_REQUEST_ACTION
);

export const StoreSearchRemittanceAction = createAsyncAction(
  types.STORE_SEARCH_REMITTANCE_ACTION
);

export const StoreSearchErrorCodesAction = createAsyncAction(
  types.STORE_SEARCH_ERRORCODES_ACTION
);
