import initialState from "./initialState";

export function RecentlyUpdatedInvoiceReducer(
  state = initialState.RecentInvoiceInfo,
  action
) {
  switch (action.type) {
    case "RECENT_INV_SELECTION_REQUEST":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
