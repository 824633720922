import initialState from "./initialState";

export function ACDependencyReducer(
  state = initialState.acDependencyParams,
  action
) {
  switch (action.type) {
    case "SEARCH_ACDEPENDENCY_REQUEST":
      return action.payload;

    default:
      return state;
  }
}
