import React, { useEffect, useState, useContext, useRef } from "react";
import cx from "classnames";
import ReactResizeDetector from "react-resize-detector";
import {
  Row,
  Col,
  Form,
  Container,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { DropdownCard } from "./demo-cards/DropdownCard";
import { TextAreaCard } from "./demo-cards/TextAreaCard";
import { TimePickerCard } from "./demo-cards/TimePickerCard";
import { NumberTextBoxCard } from "./demo-cards/NumberTextBoxCard";
import { DateRangePickerCard } from "./demo-cards/DateRangePickerCard";
import { CheckBoxCard } from "./demo-cards/CheckBoxCard";
import { IconsCard } from "./demo-cards/IconsCard";
import { AutoSuggestCard } from "./demo-cards/AutoSuggestCard";
import { TimeRangePickerCard } from "./demo-cards/TimeRangePickerCard";
import { DatePickerCard } from "./demo-cards/DatePickerCard";
import { DateTimePickerCard } from "./demo-cards/DateTimePickerCard";
import { TextBoxCard } from "./demo-cards/TextBoxCard";
import { ButtonCard } from "./demo-cards/ButtonCard";
import { TooltipCard } from "./demo-cards/TooltipCard";
import { CSSOnlyToolTip } from "./demo-cards/CSSOnlyToolTip";
import { RadioButtonCard } from "./demo-cards/RadioButtonCard";
import { AccordionCard } from "./demo-cards/AccordionCard";
import { NavBarCard } from "./demo-cards/NavBarCard";
import { Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { FilterDemo } from "./filter-demo";
import { FilterValidationDemo } from "./filter-validation-demo";
import { FormValidationDemo } from "./form-validation-demo";
import { FormLayoutDemo } from "./form-layout-demo";
import { ErrorPage } from "./error/ErrorPage";
import { DataGrid } from "./datagrid/DataGrid";
import { ToastCardNew } from "./demo-cards/ToastCardNew";
import PerfectScrollbar from "react-perfect-scrollbar";
import { LayoutContext } from "../../context";
import { MultiSelectCard } from "./demo-cards/MultiSelectCard";
import { MultiSelectCheckbox } from "./demo-cards/MultiSelectCheckbox";
import { ModalCard } from "./demo-cards/ModalCard";
import { FileCard } from "./demo-cards/FileCard";
import { CopyToClipBoardCard } from "./demo-cards/CopyToClipBoardCard";
import { NumberRangePickerCard } from "./demo-cards/NumberRangePickerCard";

const demoCardsList = [
  { title: "Dropdown", component: DropdownCard, url: "dropdown" },
  { title: "Auto-Suggest", component: AutoSuggestCard, url: "autosuggest" },
  { title: "Date Picker", component: DatePickerCard, url: "datePicker" },
  {
    title: "Date Range Picker",
    component: DateRangePickerCard,
    url: "dateRangePicker",
  },
  { title: "Time Picker", component: TimePickerCard, url: "timePicker" },
  {
    title: "Time Range Picker",
    component: TimeRangePickerCard,
    url: "timeRangePicker",
  },
  {
    title: "Date Time Picker",
    component: DateTimePickerCard,
    url: "dateTimePicker",
  },
  { title: "Text Box", component: TextBoxCard, url: "textbox" },
  { title: "Text Area", component: TextAreaCard, url: "textarea" },
  {
    title: "Number Text Box",
    component: NumberTextBoxCard,
    url: "numberTextbox",
  },
  {
    title: "Number Range Picker(Old)",
    component: NumberRangePickerCard,
    url: "numberRangeTextbox",
  },
  { title: "Check Box", component: CheckBoxCard, url: "checkbox" },
  { title: "Radio Button", component: RadioButtonCard, url: "radio" },
  { title: "Icons", component: IconsCard, url: "icons", sm: 12 },
  { title: "Buttons", component: ButtonCard, url: "buttons", sm: 12 },
  {
    title: "Tooltip",
    component: () => (
      <Row>
        <Col>
          <TooltipCard />
        </Col>
        <Col>
          <CSSOnlyToolTip />
        </Col>
      </Row>
    ),
    url: "tooltip",
    sm: 12,
  },
  { title: "Accordion", component: AccordionCard, url: "accordion", sm: 12 },
  {
    title: "Toast",
    component: ToastCardNew,
    url: "toast",
  },
  { title: "Modal", component: ModalCard, url: "modal" },
  { title: "Nav Bar", component: NavBarCard, url: "navbar", sm: 12 },
  { title: "File Input", component: FileCard, url: "fileInput" },
  {
    title: "Copy to Clipboard",
    component: CopyToClipBoardCard,
    url: "copyToClipBoardCard",
    sm: 12,
  },
  {
    title: "Multi-Select",
    component: () => (
      <Row>
        <Col>
          <MultiSelectCard />
        </Col>
        <Col>
          <MultiSelectCheckbox />
        </Col>
      </Row>
    ),
    url: "multiselect",
    sm: 12,
  },
];

export const DemoPage = withRouter(
  React.memo(prop => {
    let { match, location } = prop;

    const layoutContext = useContext(LayoutContext);
    const scrollbarRef = useRef();
    const compsDropdownRef = useRef();

    const [contentHeight, setContentHeight] = useState(
      layoutContext.contentHeight
    );

    useEffect(() => {
      //console.log(layoutContext.contentHeight);
      let demoContent = document.getElementById("demo-content");
      //console.log(demoContent.offsetTop);
      //console.log(layoutContext.contentHeight - demoContent.offsetTop - 1);
      setContentHeight(
        layoutContext.contentHeight - demoContent.offsetTop - 10
      );
    }, [layoutContext.contentHeight]);

    return (
      <>
        <Container id="demo-parent" fluid>
          <Row>
            <Col sm="12" className="p-0">
              <Navbar bg="light" variant="light">
                <div className="navbar-brand">IFX Demo</div>
                <NavDropdown
                  ref={compsDropdownRef}
                  title="Components"
                  className={cx({
                    active:
                      location.pathname.indexOf(`${match.url}/components`) >= 0,
                  })}
                >
                  <Row className="mx-0">
                    {demoCardsList.map((item, i) => (
                      <Col
                        key={i}
                        sm={4}
                        onClick={() => {
                          compsDropdownRef.current.click();
                        }}
                      >
                        <Link to={`${match.url}/components/${item.url}`}>
                          <Nav
                            className={cx({
                              active:
                                location.pathname ===
                                `${match.url}/components/${item.url}`,
                            })}
                          >
                            <span className="nav-link">{item.title}</span>
                          </Nav>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </NavDropdown>
                <Nav className="mr-auto">
                  {/* <Link
                  className={cx("nav-link", {
                    active: location.pathname === `${match.url}/filter-demo`,
                  }) }
                  to={ `${match.url}/filter-demo` }
                >
                  Filter-Demo
                </Link> */}
                  <Link
                    className={cx("nav-link", {
                      active:
                        location.pathname === `${match.url}/form-layout-demo`,
                    })}
                    to={`${match.url}/form-layout-demo`}
                  >
                    Form-Layout-Demo
                  </Link>
                  <Link
                    className={cx("nav-link", {
                      active:
                        location.pathname ===
                        `${match.url}/form-layout-validation-demo`,
                    })}
                    to={`${match.url}/form-layout-validation-demo`}
                  >
                    Form-Layout-Validation-Demo
                  </Link>
                  <Link
                    className={cx("nav-link", {
                      active:
                        location.pathname ===
                        `${match.url}/form-validation-demo`,
                    })}
                    to={`${match.url}/form-validation-demo`}
                  >
                    Form-Validation-Demo
                  </Link>
                  <Link
                    className={cx("nav-link", {
                      active: location.pathname === `${match.url}/error-page`,
                    })}
                    to={`${match.url}/error-page`}
                  >
                    Error-Page
                  </Link>
                  <Link
                    className={cx("nav-link", {
                      active: location.pathname === `${match.url}/datagrid`,
                    })}
                    to={`${match.url}/datagrid`}
                  >
                    Data Grid
                  </Link>
                </Nav>
              </Navbar>
            </Col>
          </Row>

          <Row>
            <Container
              fluid
              className="p-0"
              id="demo-content"
              style={{ height: contentHeight }}
            >
              <PerfectScrollbar ref={scrollbarRef}>
                <Container fluid className="p-0">
                  <ReactResizeDetector
                    handleHeight
                    onResize={() => scrollbarRef.current.updateScroll()}
                  >
                    <div className="resize-detect-div">
                      <Switch>
                        {demoCardsList.map(
                          (
                            {
                              title,
                              url,
                              component: DemoCardComponent,
                              ...colProps
                            },
                            i
                          ) => (
                            <Route
                              exact
                              key={i}
                              path={`${match.url}/components/${url}`}
                              render={() => (
                                <DemoComponents {...colProps}>
                                  <DemoCardComponent />
                                </DemoComponents>
                              )}
                            />
                          )
                        )}
                        <Route
                          exact
                          path={`${match.url}/filter-demo`}
                          component={FilterDemo}
                        />
                        <Route
                          exact
                          path={`${match.url}/form-layout-demo`}
                          component={FormLayoutDemo}
                        />
                        <Route
                          exact
                          path={`${match.url}/form-validation-demo`}
                          component={FilterValidationDemo}
                        />
                        <Route
                          exact
                          path={`${match.url}/form-layout-validation-demo`}
                          component={FormValidationDemo}
                        />
                        <Route
                          exact
                          path={`${match.url}/error-page`}
                          component={ErrorPage}
                        />
                        <Route
                          exact
                          path={`${match.url}/datagrid`}
                          render={() => (
                            <DataGrid contentHeight={contentHeight} />
                          )}
                        />
                      </Switch>
                      <Route
                        exact
                        path={match.url}
                        render={() => (
                          <Redirect to={`${match.url}/components`} />
                        )}
                      />
                      <Route
                        exact
                        path={`${match.url}/components`}
                        render={() => (
                          <Redirect
                            to={`${match.url}/components/${demoCardsList[0].url}`}
                          />
                        )}
                      />
                    </div>
                  </ReactResizeDetector>
                </Container>
              </PerfectScrollbar>
            </Container>
          </Row>
        </Container>
      </>
    );
  })
);

export const DemoComponents = ({ children, ...colProps }) => (
  <>
    <Form>
      {/* <h3 className="text-center">IFX Common Components</h3> */}
      <Container>
        <Row className="my-3 justify-content-center">
          <Col sm="6" {...colProps}>
            {children}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <ul>
                <li>
                  <a
                    target="_blank"
                    href="https://paxia.atlassian.net/wiki/spaces/IFX/pages/261849116/Known+constraints+with+components"
                  >
                    Click here to see known constraints of components
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row></Row>
      </Container>
    </Form>
  </>
);
