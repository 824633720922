export const LoginConsts = {
  SECRET_ANS_NOT_MATCH: {
    content: `The Answer you entered does not match the Answer you gave during Registration.
            Please enter the same Answer you gave during Registration.`,
  },
  USER_NAME_NOT_EXISTS: {
    content: `The User Name you have entered is not valid. Please enter a valid User Name`,
  },
  ACCOUNT_DEACTIVATED:
    "Your account has been de-activated. Please contact your IFX administrator for help.",
  USER_NOT_FOUND:
    "The login details you entered do not match details in IFX. Please try again.",
  LOGGED_OUT_SUCCESS_INFO: "Logged out successfully.",
  REQUIRED_ERROR: "User Name and Password are required.",
  PASSWORD_CHANGED_INFO: "Password has been changed successfully.",
};
