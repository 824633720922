import React from "react";
import * as random from "random-seed";
import { format, set, parse, addDays } from "date-fns";
import { ColorOptions, IconStyleOptions } from "../../components/AppComponents";

export const statusList = [
  { label: "Initial", id: 1 },
  { label: "Prelim", id: 2 },
  { label: "Final", id: 3 },
  { label: "Confirmed", id: 4 },
  { label: "Closed", id: 5 },
  { label: "Completed", id: 6 },
];

export const staticRowMockData = [
  {
    id: 1,
    flightNo: "1234",
    legNo: { id: 10, value: "10" },
    class: { id: 1, value: "F" },
    flightDate: {
      serialized: format(new Date(), "MM/dd/yyyy"),
      dateValue: new Date(),
    },
    flightTime: {
      serialized: format(new Date(), "HH:mm"),
      dateValue: new Date(),
    },
    name: "Name 1",
    description: "Description",
    carrier: { DL: true, CX: true, SK: true },
    status: { label: "Prelim", id: 2 },
    iconControl: {
      showIcon: true,
      tooltipProps: {
        content: <>System Error Occurred!</>,
      },
    },
    //hasChild: true,
    open: false,
    children: [
      {
        id: 101,
        flightNo: "Child of 1234",
        legNo: { id: 10, value: "10" },
        class: { id: 1, value: "F" },
        flightDate: {
          serialized: format(new Date(), "MM/dd/yyyy"),
          dateValue: new Date(),
        },
        flightTime: {
          serialized: format(new Date(), "HH:mm"),
          dateValue: new Date(),
        },
        name: "Name 1",
        description: "Description",
        carrier: { DL: true, CX: true, SK: true },
        status: { label: "Prelim", id: 2 },
        //hasChild: true,
        open: false,
        children: [
          {
            id: 1011,
            flightNo: "Nested Child of 1234",
            legNo: { id: 10, value: "10" },
            class: { id: 1, value: "F" },
            flightDate: {
              serialized: format(new Date(), "MM/dd/yyyy"),
              dateValue: new Date(),
            },
            flightTime: {
              serialized: format(new Date(), "HH:mm"),
              dateValue: new Date(),
            },
            name: "Name 1",
            description: "Description",
            carrier: { DL: true, CX: true, SK: true },
            status: { label: "Prelim", id: 2 },
            open: false,
            children: [
              {
                id: 10111,
                flightNo: "Nested Child of 1234",
                legNo: { id: 10, value: "10" },
                class: { id: 1, value: "F" },
                flightDate: {
                  serialized: format(new Date(), "MM/dd/yyyy"),
                  dateValue: new Date(),
                },
                flightTime: {
                  serialized: format(new Date(), "HH:mm"),
                  dateValue: new Date(),
                },
                name: "Name 1",
                description: "Description",
                carrier: { DL: true, CX: true, SK: true },
                status: { label: "Prelim", id: 2 },
                open: false,
                children: [
                  {
                    id: 101111,
                    flightNo: "Nested Child of 1234",
                    legNo: { id: 10, value: "10" },
                    class: { id: 1, value: "F" },
                    flightDate: {
                      serialized: format(new Date(), "MM/dd/yyyy"),
                      dateValue: new Date(),
                    },
                    flightTime: {
                      serialized: format(new Date(), "HH:mm"),
                      dateValue: new Date(),
                    },
                    name: "Name 1",
                    description: "Description",
                    carrier: { DL: true, CX: true, SK: true },
                    status: { label: "Prelim", id: 2 },
                    open: false,
                  },
                ],
              },
              {
                id: 10112,
                flightNo: "Nested Child 2 of 1234 abcd",
                legNo: { id: 10, value: "10" },
                class: { id: 1, value: "F" },
                flightDate: {
                  serialized: format(new Date(), "MM/dd/yyyy"),
                  dateValue: new Date(),
                },
                flightTime: {
                  serialized: format(new Date(), "HH:mm"),
                  dateValue: new Date(),
                },
                name: "Name 1",
                description: "Description",
                carrier: { DL: true, CX: true, SK: true },
                status: { label: "Prelim", id: 2 },
              },
            ],
          },
          {
            id: 1012,
            flightNo: "Nested Child 2 of 1234",
            legNo: { id: 10, value: "10" },
            class: { id: 1, value: "F" },
            flightDate: {
              serialized: format(new Date(), "MM/dd/yyyy"),
              dateValue: new Date(),
            },
            flightTime: {
              serialized: format(new Date(), "HH:mm"),
              dateValue: new Date(),
            },
            name: "Name 1",
            description: "Description",
            carrier: { DL: true, CX: true, SK: true },
            status: { label: "Prelim", id: 2 },
            open: false,
            children: [
              {
                id: 10121,
                flightNo: "Nested Child of 1234",
                legNo: { id: 10, value: "10" },
                class: { id: 1, value: "F" },
                flightDate: {
                  serialized: format(new Date(), "MM/dd/yyyy"),
                  dateValue: new Date(),
                },
                flightTime: {
                  serialized: format(new Date(), "HH:mm"),
                  dateValue: new Date(),
                },
                name: "Name 1",
                description: "Description",
                carrier: { DL: true, CX: true, SK: true },
                status: { label: "Prelim", id: 2 },
                open: false,
              },
            ],
          },
        ],
      },
      {
        id: 102,
        flightNo: "2nd Child of 1234",
        legNo: { id: 10, value: "10" },
        class: { id: 1, value: "F" },
        flightDate: {
          serialized: format(new Date(), "MM/dd/yyyy"),
          dateValue: new Date(),
        },
        flightTime: {
          serialized: format(new Date(), "HH:mm"),
          dateValue: new Date(),
        },
        name: "Name 1",
        description: "Description",
        carrier: { DL: true, CX: true, SK: true },
        status: { label: "Prelim", id: 2 },
      },
      {
        id: 103,
        flightNo: "3rdd Child of 1234",
        legNo: { id: 10, value: "10" },
        class: { id: 1, value: "F" },
        flightDate: {
          serialized: format(new Date(), "MM/dd/yyyy"),
          dateValue: new Date(),
        },
        flightTime: {
          serialized: format(new Date(), "HH:mm"),
          dateValue: new Date(),
        },
        name: "Name 1",
        description: "Description",
        carrier: { DL: true, CX: true, SK: true },
        status: { label: "Prelim", id: 2 },
      },
    ],
  },
  {
    id: 2,
    flightNo: "12345",
    legNo: { id: 10, value: "10" },
    class: { id: 1, value: "F" },
    flightDate: {
      serialized: format(new Date(), "MM/dd/yyyy"),
      dateValue: new Date(),
    },
    flightTime: {
      serialized: format(new Date(), "HH:mm"),
      dateValue: new Date(),
    },
    name: "Name 2",
    description: "Description",
    carrier: { DL: true, CX: true, SK: true },
    status: { label: "Prelim", id: 2 },
    iconControl: {
      showIcon: true,
      tooltipProps: {
        content: <>System Error Occurred!</>,
      },
    },
    //hasChild: true,
    open: false,
    children: [
      {
        id: 201,
        flightNo: "child of 12345",
        legNo: { id: 10, value: "10" },
        class: { id: 1, value: "F" },
        flightDate: {
          serialized: format(new Date(), "MM/dd/yyyy"),
          dateValue: new Date(),
        },
        flightTime: {
          serialized: format(new Date(), "HH:mm"),
          dateValue: new Date(),
        },
        name: "Name 2",
        description: "Description",
        carrier: { DL: true, CX: true, SK: true },
        status: { label: "Prelim", id: 2 },
      },
    ],
  },
  {
    id: 3,
    flightNo: "123",
    legNo: { id: 10, value: "10" },
    class: { id: 1, value: "F" },
    flightDate: {
      serialized: format(new Date(), "MM/dd/yyyy"),
      dateValue: new Date(),
    },
    flightTime: {
      serialized: format(new Date(), "HH:mm"),
      dateValue: new Date(),
    },
    name: "Name 1",
    description: "Description",
    carrier: { DL: true, CX: true, SK: true },
    status: { label: "Prelim", id: 2 },
    iconControl: {
      showIcon: true,
      tooltipProps: {
        content: <>System Error Occurred!</>,
      },
    },
    //hasChild: true,
    open: false,
    children: [
      {
        id: 301,
        flightNo: "Child of 123",
        legNo: { id: 10, value: "10" },
        class: { id: 1, value: "F" },
        flightDate: {
          serialized: format(new Date(), "MM/dd/yyyy"),
          dateValue: new Date(),
        },
        flightTime: {
          serialized: format(new Date(), "HH:mm"),
          dateValue: new Date(),
        },
        name: "Name 1",
        description: "Description",
        carrier: { DL: true, CX: true, SK: true },
        status: { label: "Prelim", id: 2 },
      },
    ],
  },
  {
    id: 4,
    flightNo: "2224",
    legNo: { id: 10, value: "10" },
    class: { id: 2, value: "C" },
    flightDate: {
      serialized: format(addDays(new Date(), 3), "MM/dd/yyyy"),
      dateValue: addDays(new Date(), 3),
    },
    flightTime: {
      serialized: format(new Date(), "HH:mm"),
      dateValue: new Date(),
    },
    name: "Name 1",
    description: "Description",
    carrier: { DL: true, CX: false, SK: true },
    status: { label: "Prelim", id: 2 },
    iconControl: {
      showIcon: true,
      tooltipProps: {
        content: (
          <>
            <ul>
              <li>Custom Error 1</li>
              <li>Custom Error 2</li>
            </ul>
          </>
        ),
      },
    },
  },
  {
    id: 5,
    carrier: {},
  },
  { id: 6, carrier: {} },
  { id: 7, carrier: {} },
  { id: 8, carrier: {} },
  { id: 9, carrier: {} },
  { id: 10, carrier: {} },
  { id: 11, carrier: {} },
];

const infiniteMockRefData = {
  legNo: [
    {
      id: "10",
      value: "10",
      display: "10",
    },
    {
      id: "20",
      value: "20",
      display: "20",
    },
    {
      id: "30",
      value: "30",
      display: "30",
    },
  ],
  class: [
    {
      id: "1",
      value: "F",
      display: "F - First",
    },
    {
      id: "2",
      value: "C",
      display: "C - Business",
    },
    {
      id: "3",
      value: "Y",
      display: "Y - Coach",
    },
    {
      id: "4",
      value: "X",
      display: "X - Common",
    },
    {
      id: "5",
      value: "P",
      display: "P - Pilot",
    },
    {
      id: "6",
      value: "A",
      display: "A - Flt Attendent",
    },
  ],
  flightDate: (relDates, today) => {
    return relDates.map(d => {
      let _d = addDays(today, d);
      return {
        serialized: format(_d, "MM/dd/yyyy"),
        dateValue: _d,
      };
    });
  },

  flightTime: [
    { serialized: "00:15" },
    { serialized: "00:45" },
    { serialized: "02:15" },
    { serialized: "03:15" },
    { serialized: "04:30" },
    { serialized: "05:15" },
    { serialized: "06:35" },
    { serialized: "10:00" },
    { serialized: "11:30" },
    { serialized: "13:35" },
    { serialized: "14:45" },
    { serialized: "15:00" },
    { serialized: "16:25" },
    { serialized: "16:40" },
    { serialized: "17:35" },
    { serialized: "18:30" },
    { serialized: "19:35" },
    { serialized: "20:15" },
    { serialized: "21:45" },
    { serialized: "22:15" },
    { serialized: "22:45" },
    { serialized: "23:15" },
    { serialized: "23:45" },
  ].map(t => ({
    serialized: t.serialized,
    dateValue: set(parse(t.serialized, "HH:mm", new Date()), {
      year: 1976,
      month: 0,
      date: 1,
    }),
  })),
};

export const generateInfiniteMockData = params => {
  console.log(params);
  const totalRecords = 10000;
  let { startRow, endRow } = params;
  let result = [];
  const today = new Date();
  const relDates = [-2, -1, 0, 1, 2];

  for (let i = startRow; i < endRow; i++) {
    const rnd = random.create(i);
    result.push({
      id: i + 1,
      flightNo: (i + 1).toString().padStart(4, "0"),
      legNo: infiniteMockRefData.legNo[rnd.intBetween(0, 2)],
      class: infiniteMockRefData.class[rnd.intBetween(0, 3)],
      flightDate: infiniteMockRefData.flightDate(relDates, today)[
        rnd.intBetween(0, relDates.length - 1)
      ],

      flightTime:
        infiniteMockRefData.flightTime[
          rnd.intBetween(0, infiniteMockRefData.flightTime.length - 1)
        ],
      name: `Name ${i + 1}`,
      description: `Description ${i + 1}`,
      status: statusList[rnd.intBetween(0, statusList.length - 1)],
      carrier: {
        DL: rnd.intBetween(0, 100) % 2 === 0 ? true : false,
        CX: rnd.intBetween(0, 100) % 2 === 0 ? true : false,
        SK: rnd.intBetween(0, 100) % 2 === 0 ? true : false,
      },
      editable: rnd.intBetween(0, 100) % 2 === 0 ? true : false,
      iconControl:
        rnd.intBetween(0, 10) === 7
          ? {
              showIcon: true,
              tooltipProps: {
                content: <>Info Flight has departed !</>,
              },
              iconProps: {
                backgroundColor: ColorOptions.info,
                borderColor: ColorOptions.info,
                borderThin: true,
                color: ColorOptions.white,
                iconStyle: IconStyleOptions.CIRCLE,
              },
            }
          : undefined,
    });
  }
  return { result, totalRecords };
};

export const staticESSDashboardMockData = [
  {
    id: 1,
    flightNo: "I5774",
    legNo: { id: 10, value: "10" },
    class: { id: 1, value: "F" },
    flightDate: {
      serialized: format(new Date(), "MM/dd/yyyy"),
      dateValue: new Date(),
    },
    flightTime: {
      serialized: format(new Date(), "HH:mm"),
      dateValue: new Date(),
    },
    name: "Paxian",
    description: "Description",
    carrier: { DL: true, CX: true, SK: true },
    status: { label: "Prelim", id: 2 },
    iconControl: {
      showIcon: true,
      tooltipProps: {
        content: <>System Error Occurred!</>,
      },
    },
    scheduleSetCode: "012811",
    scheduleSetName: "THIS IS THE PRODUCTION SCHED SET",
    isProduction: true,
  },
  {
    id: 2,
    flightNo: "I5774",
    legNo: { id: 10, value: "10" },
    class: { id: 1, value: "F" },
    flightDate: {
      serialized: format(new Date(), "MM/dd/yyyy"),
      dateValue: new Date(),
    },
    flightTime: {
      serialized: format(new Date(), "HH:mm"),
      dateValue: new Date(),
    },
    name: "Paxian",
    description: "Description",
    carrier: { DL: true, CX: true, SK: true },
    status: { label: "Prelim", id: 2 },
    iconControl: {
      showIcon: true,
      tooltipProps: {
        content: <>System Error Occurred!</>,
      },
    },
    scheduleSetCode: "Redtail_Merge_1",
    scheduleSetName: "**July 13th partial schedule merge only**",
    isProduction: false,
  },
  {
    id: 3,
    flightNo: "I5775",
    legNo: { id: 10, value: "10" },
    class: { id: 1, value: "F" },
    flightDate: {
      serialized: format(new Date(), "MM/dd/yyyy"),
      dateValue: new Date(),
    },
    flightTime: {
      serialized: format(new Date(), "HH:mm"),
      dateValue: new Date(),
    },
    name: "Paxian",
    description: "Description",
    carrier: { DL: true, CX: true, SK: true },
    status: { label: "Prelim", id: 2 },
    iconControl: {
      showIcon: true,
      tooltipProps: {
        content: <>System Error Occurred!</>,
      },
    },
    scheduleSetCode: "Redtail_Merge_2",
    scheduleSetName: "**July 14th partial schedule merge only**",
    isProduction: false,
  },
];

export const staticESSDashboardFSSMockData = [
  {
    id: 1,
    flightNo: "I5774",
    legNo: { id: 10, value: "10" },
    class: { id: 1, value: "F" },
    flightDate: {
      serialized: format(new Date(), "MM/dd/yyyy"),
      dateValue: new Date(),
    },
    flightTime: {
      serialized: format(new Date(), "HH:mm"),
      dateValue: new Date(),
    },
    name: "Paxian",
    description: "Description",
    carrier: { DL: true, CX: true, SK: true },
    status: { label: "Prelim", id: 2 },
    iconControl: {
      showIcon: true,
      tooltipProps: {
        content: <>System Error Occurred!</>,
      },
    },
    fssCode: "pl-3338",
    fssName: "pl-3338",
    effDate: "04/08/2020",
    expDate: "05/08/2020",
    ruleSet: "PI-3156_FLT480",
    scheduleSet: "012811",
    createdBy: "dladmin",
    createdDate: "04/08/2020",
    reelaseDate: "04/08/2020",
    sceduleFSStatus: {
      showIcon: true,
      tooltipProps: {
        content: <>Errors</>,
      },
      iconProps: {
        backgroundColor: ColorOptions.danger,
        borderColor: ColorOptions.grey,
        borderThin: true,
        color: ColorOptions.white,
        iconStyle: IconStyleOptions.CIRCLE,
      },
    },
    flightServiceStatus: {
      showIcon: true,
      tooltipProps: {
        content: <>Released</>,
      },
      iconProps: {
        icon: "arrow-alt-circle-right",
        backgroundColor: ColorOptions.primary,
        borderColor: ColorOptions.primary,
        borderThin: true,
        color: ColorOptions.white,
        iconStyle: IconStyleOptions.CIRCLE,
      },
    },
  },
];
