import initialState from "./initialState";

export function LayoutReducer(state = initialState.LayoutReducer, action) {
  switch (action.type) {
    case "LAYOUT_SIDEBAR_CLOSED_ENABLE":
      return {
        ...state,
        sidebarClosedEnable: action.payload,
      };

    case "LAYOUT_FULLSCREEN_ENABLE":
      return {
        ...state,
        fullScreenEnable: action.payload,
      };

    case "LAYOUT_CONTENT_WIDTH":
      return {
        ...state,
        contentWidth: action.payload,
      };

    case "LAYOUT_LOADER_SHOW":
      let { loaderOpaque, loaderText, cancellable=false } = action.payload ? action.payload : {};
      return {
        ...state,
        loaderEnable: true,
        callAbort: false,
        cancellable,
        loaderOpaque: loaderOpaque,
        loaderText: loaderText
          ? loaderText
          : typeof action.payload === "string"
          ? action.payload
          : undefined,
      };
    case "LAYOUT_LOADER_HIDE":
      return {
        ...state,
        loaderOpaque: null,
        cancellable: false,
        loaderEnable: false,
      };
    case "LAYOUT_LOADER_TEXT":
      return {
        ...state,
        loaderText: action.payload,
      };

    case "LAYOUT_LOADER_SCOPE_SHOW": {
      const { loaderScoped } = state;
      const { scope, ...payload } = action.payload || {};
      return {
        ...state,
        loaderScoped: {
          ...loaderScoped,
          [scope]: { ...payload, enable: true },
        },
      };
    }
    case "LAYOUT_LOADER_SCOPE_HIDE": {
      const { loaderScoped } = state;
      const { scope } = action.payload;
      const { [scope]: scoped, ..._loaderScoped } = loaderScoped;
      //console.log("scoped", scoped);
      return { ...state, loaderScoped: _loaderScoped };
    }
    case "ABORT_STOP_FETCH_CALL":
      return {
        ...state,
        loaderOpaque: null,
        loaderEnable: false,
        callAbort: true,
      };

    default:
      return state;
  }
}
