import initialState from "./initialState";

export function FssFlightDetailsReducer(
  state = initialState.flightInfo,
  action
) {
  switch (action.type) {
    case "FSS_FLT_INFO_REQUEST":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
