import React, { useState } from "react";
import { Card, FormGroup } from "react-bootstrap";
import {
  IFXNumberTextBox,
  IFXFieldWrapper,
} from "../../../components/AppComponents";

export const NumberTextBoxCard = () => {
  const [formState, setFormState] = useState({
    numberTextBox1: "",
    numberTextBox2: "4",
  });
  //console.log("NumberField Content: " + formState);

  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title className="mb-4">NUMBER TEXTBOX</Card.Title>
        <IFXFieldWrapper
          label="Number Field"
          controlId="numberTextBox1"
          alwaysFloat={(() =>
            formState.numberTextBox1 != null &&
            formState.numberTextBox1.length > 0)()}
        >
          <IFXNumberTextBox
            value={formState.numberTextBox1}
            onChange={e => {
              const {
                target: { value: v },
              } = e;
              setFormState({ ...formState, numberTextBox1: v });
            }}
            maxLength={6}
          />
        </IFXFieldWrapper>
        <Card.Title className="mt-4 mb-4">
          NUMBER TEXTBOX WITH DEFAULT VALUE
        </Card.Title>
        <IFXFieldWrapper
          label="Number Field"
          controlId="numberTextBox2"
          alwaysFloat={(() =>
            formState.numberTextBox2 != null &&
            formState.numberTextBox2.length > 0)()}
        >
          <IFXNumberTextBox
            value={formState.numberTextBox2}
            onChange={e => {
              const {
                target: { value: v },
              } = e;
              setFormState({ ...formState, numberTextBox2: v });
            }}
          />
        </IFXFieldWrapper>
      </Card.Body>
    </Card>
  );
};
