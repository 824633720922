import React from "react";
import { Col, Row } from "react-bootstrap";
import { IFXButton } from "./IFXButton";
import PropTypes from "prop-types";
import cx from "classnames";

const DEFAULT_COL_PROPS = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
};

const DEFAULT_BUTTON_GROUP_COL_PROPS = {
  xs: null,
  sm: null,
  md: null,
  lg: null,
};

const multiplySpan = (size, span) => {
  return size && (size = size * span) > 12 ? 12 : size;
};

const IFXCol = props => {
  let { span, xs, sm, md, lg, xl, ..._props } = {
    ...DEFAULT_COL_PROPS,
    ...props,
  };

  if (span) {
    xs = multiplySpan(xs, span);
    sm = multiplySpan(sm, span);
    md = multiplySpan(md, span);
    lg = multiplySpan(lg, span);
    xl = multiplySpan(xl, span);
  }
  _props = { ..._props, xs, sm, md, lg, xl };

  return <Col {..._props} />;
};

export const IFXButtonGroup = ({ children, ...props }) => {
  let _children = children;
  if (!Array.isArray(children)) {
    _children = [children];
  }
  const {
    marginClassName = "mt-2",
    displayClassName = "d-flex",
    justifyClassName = "justify-content-end",
    wrapClassName = "flex-wrap",
    buttonClassName,
    enableBottomMargin,
    ...colProps
  } = props;
  return (
    <IFXCol {...DEFAULT_BUTTON_GROUP_COL_PROPS} {...colProps}>
      <div
        className={cx(
          "ifx-button-group",
          marginClassName,
          displayClassName,
          justifyClassName,
          wrapClassName
        )}
      >
        {_children.map((child, idx) => (
          <div
            className={cx(
              "d-inline-block",
              buttonClassName,
              enableBottomMargin && "mb-1"
            )}
            key={idx}
          >
            {child}
          </div>
        ))}
      </div>
    </IFXCol>
  );
};

IFXButtonGroup.propTypes = {
  className: PropTypes.string,
  marginClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  displayClassName: PropTypes.string,
  justifyClassName: PropTypes.string,
  wrapClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  enableBottomMargin: PropTypes.bool,
};

const DEFAULT_PROPS = {
  footerActions: [],
  footerProps: {},
};

export const IFXFormLayout = React.memo(props => {
  const _props = { ...DEFAULT_PROPS, ...props };
  const {
    fields,
    children,
    footerActions,
    footerActionIdx = 0,
    footerProps,
    footerComponent,
    submitButton,
    clearCallBack,
    allFieldsColProps,
    ...rowProps
  } = _props;

  let _footerActions = [];
  let _children = children;
  if (children && !Array.isArray(children)) {
    _children = [children];
  }

  if (submitButton) {
    _footerActions.push(
      <IFXButton
        type="search"
        {...(submitButton !== true ? submitButton : null)}
      />
    );
  }

  if (clearCallBack) {
    _footerActions.push(<IFXButton type="clear" onClick={clearCallBack} />);
  }

  if (footerActions)
    _footerActions.splice(footerActionIdx, 0, ...footerActions);

  return (
    <>
      <Row {...rowProps}>
        {fields &&
          fields.map(({ component, colProps }, idx) => (
            <IFXCol
              key={idx}
              {...allFieldsColProps}
              {...colProps}
              {...component.props.colProps}
            >
              {component}
            </IFXCol>
          ))}
        {_children &&
          _children.map(
            (field, idx) =>
              field && (
                <IFXCol
                  key={idx}
                  {...allFieldsColProps}
                  {...field.props.colProps}
                >
                  {field}
                </IFXCol>
              )
          )}
        {_footerActions && _footerActions.length > 0 && (
          <IFXButtonGroup
            children={_footerActions}
            marginClassName="mt-ifx-125"
            {...footerProps}
          />
        )}
        {footerComponent}
      </Row>
    </>
  );
});

IFXFormLayout.propTypes = {
  fields: PropTypes.array,
  footerActions: PropTypes.array,
  footerActionIdx: PropTypes.number,
  footerProps: PropTypes.object,
  footerComponent: PropTypes.any,
  submitButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  clearCallBack: PropTypes.func,
  allFieldsColProps: PropTypes.object,
};
