export const SEARCH_DETAILED_INVOICE_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/SearchDetailedInvoice.do";
export const INV_PMNT_HISTORY_POPUP_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/getInvPaymentHistory.do";
export const INV_BREAKDOWN_CHARGES_POPUP_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/getBreakdownCharges.do";
export const INV_ERROR_CODES_POPUP_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/getErrorCodes.do";
export const INV_TAGS_POPUP_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/getInvoiceTags.do";
export const INV_PERIOD_DETAILS_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/";
export const INV_SERVICE_CODES_FOR_ESV_POPUP_URL =
  "/ifx/api/esm/common/serviceCode/getServiceCode.do";
export const INV_SERVICE_CODES_POPUP_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/getSpecPrices.do";
export const INV_BULK_APPROVE_OR_VALIDATE_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/searchInvoiceOperation.do";
export const FETCH_INVOICE_STATUS_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/fetch/invStatus.do";
export const PRINT_CSV_REPORT_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/printCSVReport.do";
export const PRINT_DETAIL_CSV_REPORT_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/printDetailCSVReport.do";
export const PRINT_INVOICE_PDF_REPORT_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/printDetailSearchPDF.do";
export const FETCH_INVOICE_REMITTANCE_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/fetch/invRemittance.do";
export const PRINT_PMNT_REMITTANCE_DETAILS_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/printInvPmntRemittanceDetails.do";
export const PRINT_PMNT_HISTORY_DETAILS_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/printInvPmntHistory.do";
export const PRINT_DETAIL_L2_CSV_REPORT_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/printDetailLevel2CSVReport.do";

export const GET_INV_HEADER_INFO =
  "/ifx/api/esv/invoice/manageInvoice/headerInfo/";
export const GET_INV_MEAL_ORDER_SUMMARY =
  "/ifx/api/esv/invoice/manageInvoice/mealOrderSummary.do";
export const GET_INV_BREAKDOWN =
  "/ifx/api/esv/invoice/manageInvoice/invoiceBreakDown.do";
export const GET_PAYMENT_HISTORY =
  "/ifx/api/esv/invoice/manageInvoice/paymentHistory.do";
export const GET_LINE_ITEMS_DETAILS =
  "/ifx/api/esv/invoice/manageInvoice/invoiceLineItems";
export const GET_INV_TOTALS =
  "/ifx/api/esv/invoice/manageInvoice/invoiceTotal.do";
export const GET_SPEC_PRICE_STATUS =
  "/ifx/api/esv/invoice/manageInvoice/specPriceStatus.do";
export const GET_INV_CHANGE_HISTORY =
  "/ifx/api/esv/invoice/manageInvoice/invChangeHistory.do";
export const AUTH_PAX_CHANGES =
  "/ifx/api/eso/order/soChangeLog/getPaxChanges.do";
export const AUTH_PRESELECT_CHANGES =
  "/ifx/api/eso/order/soChangeLog/getPreSelectChanges.do";
export const VIEW_INV_COMMENTS =
  "/ifx/api/esv/invoice/manageInvoice/invoiceComments.do";
export const VIEW_INV_FLTTAGS =
  "/ifx/api/esv/invoice/manageInvoice/invoiceFltTags/";
export const GET_INV_BEING_PAID =
  "/ifx/api/esv/invoice/dashBoard/viewInvoicesBeingPaid.do";
export const GET_INV_BEING_PAID_DETAILS =
  "/ifx/api/esv/invoice/dashBoard/invoicesBeingPaidDetail.do";
export const GET_SPML_DETAILS =
  "/ifx/api/esv/invoice/manageInvoice/getSPMLDetails.do";
export const PRINT_INV_BEING_PAID =
  "/ifx/api/esv/invoice/dashBoard/printInvBeingPaid.do";
export const GET_LINE_ITEM_BOM_DETAILS =
  "/ifx/api/esv/invoice/manageInvoice/updateBomInvLineItems.do";
export const ITEM_CHANGE_HISTORY =
  "/ifx/api/esv/invoice/manageInvoice/lineItemChangeHistory.do";
export const SO_AUTHORIZATION =
  "/ifx/api/esv/invoice/manageInvoice/soItemAuthorization.do";
export const VIEW_INVOICE_UPDATE =
  "/ifx/api/esv/invoice/manageInvoice/updateInvHeader.do";
export const ITEM_CHARGES_BREAKDOWN =
  "/ifx/api/esv/invoice/manageInvoice/lineItemBreakDownCharges.do";
export const GET_ALL_INV_PAYMENT =
  "/ifx/api/esv/invoice/manageInvoice/getAllInvoicePaymentsByInvId.do";

export const CREATE_MISC_INVOICE_URL =
  "/ifx/api/esv/invoice/manageInvoice/createMiscInvoice.do";

export const CREATE_ESV_RULE = "/ifx/api/esv/rules/esvrule/createESVRule.do";
export const RULE_CATEGORIES =
  "/ifx/api/esv/rules/ruleCategory/getRuleCategories.do";
export const DELETE_RULE_CATEGORIES =
  "/ifx/api/esv/rules/ruleCategory/deleteRuleCategory.do";
export const GET_ESV_RULE = "/ifx/api/esv/rules/esvrule/getEsvRule/";
export const GET_ESV_GROUP_RULE =
  "/ifx/api/esv/rules/ruleGroup/getEsvRuleGroup/";
export const UPDATE_ESV_GROUP_RULE =
  "/ifx/api/esv/rules/ruleGroup/addOrUpdateRuleGroup.do";
export const UPDATE_ESV_RULE = "/ifx/api/esv/rules/esvrule/updateESVRule.do";
export const SAVEUPDATE_RULE_CATEGORY =
  "/ifx/api/esv/rules/ruleCategory/addOrUpdateRuleCategory.do";
export const RULE_GROUPS = "/ifx/api/esv/rules/ruleGroup/getRuleGroups/";
export const DELETE_RULE_GROUPS =
  "/ifx/api/esv/rules/ruleGroup/deleteRuleGroup.do";
export const SAVEUPDATE_RULE_GROUP =
  "/ifx/api/esv/rules/ruleGroup/addOrUpdateRuleGroup.do";
export const GET_RULES_BYGROUP =
  "/ifx/api/esv/rules/esvrule/getEsvRulesByGroup/";
export const DELETE_RULES = "/ifx/api/esv/rules/esvrule/deleteESVRule.do";
export const COPY_RULES = "/ifx/api/esv/rules/esvrule/copyESVRule.do";
export const LOAD_COPY_RULE_PARAMS =
  "/ifx/api/esv/rules/esvrule/getCopyRuleDropDownData.do";

export const RULE_VARIABLES =
  "/ifx/api/esv/rules/ruleVariable/getRuleVariables.do";
export const DELETE_RULE_VARIABLES =
  "/ifx/api/esv/rules/ruleVariable/deleteESVRuleVariable.do";
export const SAVEUPDATE_RULE_VARIABLE =
  "/ifx/api/esv/rules/ruleVariable/createUpdateESVRuleVariable.do";
export const FIND_ESV_RULES = "/ifx/api/esv/rules/esvrule/findRules.do";

export const CREATE_UPDATE_PAM =
  "/ifx/api/esv/invoice/charges/pam/createorUpdatePAM.do";
export const GET_PAM =
  "/ifx/api/esv/invoice/charges/pam/priceAlterationMethods.do";
export const DELETE_PAM = "/ifx/api/esv/invoice/charges/pam/delete.do";
export const GET_PAM_DETAILS =
  "/ifx/api/esv/invoice/charges/pam/viewPriceAlterationMethod/";
export const UPDATE_PAM = "/ifx/api/esv/invoice/charges/pam/update.do";

export const GET_FILTERED_SERVICE =
  "/ifx/api/esv/invoice/manageInvoice/getFilteredService.do";
export const MANAGE_INVOICE_PERIOD =
  "/ifx/api/esv/masterdata/invoicingPeriod/searchInvoicingPeriod.do";
export const ADD_INVOICE_PERIOD =
  "/ifx/api/esv/masterdata/invoicingPeriod/addInvoicingPeriod.do";
export const EDIT_INVOICE_PERIOD =
  "/ifx/api/esv/masterdata/invoicingPeriod/editInvoicingPeriod.do";
export const DELETE_INVOICE_PERIOD =
  "/ifx/api/esv/masterdata/invoicingPeriod/deleteInvoicingPeriod.do";
export const GENERATE_INVOICE_PERIOD =
  "/ifx/api/esv/masterdata/invoicingPeriod/autoGenerateInvoicingPeriod.do";
export const LOAD_INVOICE_PERIOD_COMMENTS =
  "/ifx/api/esv/masterdata/invoicingPeriod/loadIPReOpenComments.do";
export const SAVE_INVOICE_PERIOD_COMMENTS =
  "/ifx/api/esv/masterdata/invoicingPeriod/saveIPReOpenComments.do";

export const CATERER_STATIONS =
  "/ifx/api/esv/carrierPreferences/stationProperties/catererStations.do";
export const GET_STATION_PROPERTIES_INFO =
  "/ifx/api/esv/carrierPreferences/stationProperties/";
export const STATION_PROPS_ADD_UPDATE_BILLING_INFO =
  "/ifx/api/esv/carrierPreferences/stationProperties/addUpdateBillingPayment.do";
export const STATION_PROPS_ADD_UPDATE_CHARGE_VALUE_INFO =
  "/ifx/api/esv/carrierPreferences/stationProperties/addUpdateChargeValues.do";
export const STATION_PROPS_ADD_UPDATE_LIABILITY_INFO =
  "/ifx/api/esv/carrierPreferences/stationProperties/addUpdateLiability.do";
export const STATION_PROPS_COPY_CHARGE_VALUE_INFO =
  "/ifx/api/esv/carrierPreferences/stationProperties/copyChargeValues.do";
export const STATION_PROPS_SAVE_UPDATE_INFO =
  "/ifx/api/esv/carrierPreferences/stationProperties/saveOrUpdate.do";
export const STATION_PROPS_DEL_BILLING_INFO =
  "/ifx/api/esv/carrierPreferences/stationProperties/validateDeleteBillingAndPayment.do";
export const STATION_PROPS_DEL_CHARGE_VAL_INFO =
  "/ifx/api/esv/carrierPreferences/stationProperties/validateDeleteChargeValues.do";
export const STATION_PROPS_DEL_LIABILITY_INFO =
  "/ifx/api/esv/carrierPreferences/stationProperties/validateDeleteLiability.do";

export const RECURRING_INVOICE_TEMPLATE =
  "/ifx/api/esv/invoice/recurrenceInvoice/searchRecurrenceTamplate.do";
export const DELETE_RECURRING_INVOICE_TEMPLATE =
  "/ifx/api/esv/invoice/recurrenceInvoice/delete.do";
export const GET_RECURRING_TEMPLATE_INFO =
  "/ifx/api/esv/invoice/recurrenceInvoice/";
export const SAVEUPDATE_REC_TEMPLATE =
  "/ifx/api/esv/invoice/recurrenceInvoice/saveOrUpdate.do";

export const SEARCH_INV_PENDING_REVIEW_AND_BROWSE_INVOICE_PERIODS_URL =
  "/ifx/api/esv/invoice/searchInvoicingPeriod/allInvoices.do";
export const INVOICING_PERIOD_LEVEL2_CODE_URL =
  "/ifx/api/esv/invoice/searchInvoicingPeriod/invoiceDetails.do";
export const INVOICING_PERIOD_DETAILS_BY_STATUS_URL =
  "/ifx/api/esv/invoice/searchInvoicingPeriod/invPeriodDetailsByStatus.do";
export const INVOICING_PERIOD_PMNT_HISTORY_URL =
  "/ifx/api/esv/invoice/dashBoard/invoicePeriodPaymentHistory.do";
export const INVOICING_PERIOD_PRINT_PMNT_HISTORY_URL =
  "/ifx/api/esv/invoice/dashBoard/printInvPeriodPaymentHistory.do";
export const INVOICING_PERIOD_VALIDATE_SCHEDULE_PMNT_URL =
  "/ifx/api/esv/invoice/dashBoard/checkPayment.do";
export const INVOICING_PERIOD_SCHEDULE_PMNT_URL =
  "/ifx/api/esv/invoice/dashBoard/saveAndConfirmPayment.do";
export const SEARCH_DIGITAL_INVOICE_DOC_URL =
  "/ifx/api/esv/invoice/digitalInvDocument/searchDigitalInvoiceDocuments.do";
export const DELETE_DIGITAL_INVOICE_DOC_URL =
  "/ifx/api/esv/invoice/digitalInvDocument/deleteDigitalInvoiceDocument.do";
export const DOWNLOAD_DIGITAL_INVOICE_DOC_URL =
  "/ifx/api/esv/invoice/digitalInvDocument/downloadDigitalInvoiceDocument.do";
export const UPLOAD_DIGITAL_INVOICE_DOC_URL =
  "/ifx/api/esv/invoice/digitalInvDocument/uploadDigitalInvoiceDocument.do";
export const UPLOAD_DIGITAL_INVOICE_DOC_OF_INVOICE_URL =
  "/ifx/api/esv/invoice/digitalInvDocument/invoices/uploadDigitalInvoiceDocument.do";
export const DELETE_DIGITAL_INVOICE_DOC_OF_INVOICE_URL =
  "/ifx/api/esv/invoice/digitalInvDocument/invoices/deleteDigitalInvoiceDocument.do";
export const GET_DIGITAL_INVOICE_DOC_URL =
  "/ifx/api/esv/invoice/digitalInvDocument/invoices/getDigitalInvoiceDocuments.do";  
export const DOWNLOAD_DIGITAL_INVOICE_DOC_OF_INVOICE_URL =
  "/ifx/api/esv/invoice/digitalInvDocument/invoices/downloadDigitalInvoiceDocument.do";
export const INVOICE_UPLOAD_URL =
  "/ifx/api/esv/invoice/dashBoard/uploadPe3File.do";
export const DOWNLOAD_PE3_INSTRUCTION_TEMPLATE_URL =
  "/ifx/api/esv/invoice/searchInvoicingPeriod/downloadPE3InstructionTemplate.do";
export const GET_VENDOR_INVOICE_NUMBER_URL =
  "/ifx/api/esv/invoice/searchInvoicingPeriod/getVendorInvoiceNumber/";
export const UPDATE_VENDOR_INVOICE_NUMBER_URL =
  "/ifx/api/esv/invoice/searchInvoicingPeriod/updateVendorInvNum.do";

export const SEARCH_INVOICE_TAG =
  "/ifx/api/esv/invoice/masterData/searchInvoiceTags.do";
export const SEARCH_INVOICE_TAG_CATEGORY =
  "/ifx/api/esv/invoice/masterData/searchInvoiceTagCategories.do";
export const SEARCH_INVOICE_TAG_BYCATEGORY =
  "/ifx/api/esv/invoice/masterData/searchViewInvoiceTags.do";
export const SAVEUPDATE_INV_TAG =
  "/ifx/api/esv/invoice/masterData/addUpdateInvoiceTag.do";
export const SAVEUPDATE_INV_TAG_CAT =
  "/ifx/api/esv/invoice/masterData/addUpdateInvoiceTagCategory.do";
export const DELETE_INVOICE_TAG =
  "/ifx/api/esv/invoice/masterData/deleteInvoiceTags.do";
export const DELETE_INVOICE_TAG_CAT =
  "/ifx/api/esv/invoice/masterData/deleteInvoiceTagCategories.do";

export const GENERATE_PMNT_REPORT =
  "/ifx/api/esv/invoice/manageInvoice/generatePmntReport.do";

export const SEARCH_RAP_REQUEST = "/ifx/api/esv/invoice/rapRequest/search.do";
export const RAP_AFFECTED_INVOICES_URL =
  "/ifx/api/esv/invoice/rapRequest/getRapAffectedInvoices/";
export const RAP_PMNT_REQUEST_URL =
  "/ifx/api/esv/invoice/rapRequest/getPmntReqforRAP/";
export const PRINT_RAP_REQUEST =
  "/ifx/api/esv/invoice/rapRequest/printRapRequest.do";
export const LOAD_CURRENCY =
  "/ifx/api/esv/invoice/paymentandremittance/getCurrencyDropDownData.do";
export const SEARCH_REMITTANCE =
  "/ifx/api/esv/invoice/paymentandremittance/searchRemittance.do";
export const VIEW_REMITTANCE_SUMMARY =
  "/ifx/api/esv/invoice/paymentandremittance/getRemittanceSummary.do";
export const PRINT_REMITTANCE_SUMMARY =
  "/ifx/api/esv/invoice/paymentandremittance/printRemittanceDetails.do";
export const GET_PMNT_STATUS =
  "/ifx/api/esv/invoice/paymentandremittance/getPmntReqStatusList.do";
export const GET_CHARGE_CATEGORY =
  "/ifx/api/esv/invoice/charges/getChargeCategories.do";
export const GET_CHARGE_TYPE = "/ifx/api/esv/invoice/charges/getChargeTypes.do";
export const DOWNLOAD_CHARGE_TYPE =
  "/ifx/api/esv/invoice/charges/downloadChargeTypes.do";
export const DELETE_CHARGE_TYPE =
  "/ifx/api/esv/invoice/charges/deleteChargeType.do";
export const DELETE_CHARGE_CATEGORY =
  "/ifx/api/esv/invoice/charges/deleteChargeCategory.do";
export const SAVEUPDATE_CHARGE_TYPE =
  "/ifx/api/esv/invoice/charges/saveOrUpdateChargeType.do";
export const SAVEUPDATE_CHARGE_CATEGORY =
  "/ifx/api/esv/invoice/charges/saveOrUpdateChargeCategory.do";
export const SAVEUPDATE_ERROR_CODES =
  "/ifx/api/esv/masterData/errorRejectionCode/createorUpdate.do";
export const SEARCH_ERROR_CODES =
  "/ifx/api/esv/masterData/errorRejectionCode/errorRejectionCodes.do";
export const DELETE_ERROR_CODES =
  "/ifx/api/esv/masterData/errorRejectionCode/delete.do";

export const SEARCH_INV_PENDING_REVIEW_AND_BROWSE_INVOICE_PERIODS_COUNT =
  "/ifx/api/esv/invoice/searchInvoicingPeriod/countReinstateStatusInv.do";

export const SEARCH_DETAILED_INVOICE_COUNT_URL =
  "/ifx/api/esv/invoiceManagement/detailedSearch/countReinstateStatusDetailInv.do";

export const INVOICING_PERIOD_DETAILS_BY_STATUS_COUNT_URL =
  "/ifx/api/esv/invoice/searchInvoicingPeriod/countReinstateStatusInvPeriod.do";

export const UPLOAD_INVOICE_PERIODS =
  "/ifx/api/esv/masterdata/invoicingPeriod/uploadInvoicingPeriod.do";

export const UPLOAD_INVOICING_PERIODS_TEMPLATE =
  "/ifx/api/esv/masterdata/invoicingPeriod/downloadTemplate.do";
 
