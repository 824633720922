import React, { useCallback, useRef } from "react";
import { Modal, Container } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import cx from "classnames";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { useObservableCallback } from "../../../hooks";
import ReactResizeDetector from "react-resize-detector";

const resizeDebounceObv = new Subject().pipe(debounceTime(100));

const handleResize = () => {
  resizeDebounceObv.next();
};

export const IFXModalBodyContent = ({
  className,
  disableBackground,
  handleResize,
  isScroll,
  children,
}) => {
  return (
    <Container
      fluid={true}
      className={cx(
        "body-content",
        //disableBackground && isScroll && "pl-0 pr-3",
        disableBackground && "disable-background",
        disableBackground && (className || "p-0"),
        !disableBackground && (className || "pt-1 px-2 pb-2")
      )}
    >
      {isScroll ? (
        <div>
          <ReactResizeDetector handleHeight onResize={handleResize}>
            <div>{children}</div>
          </ReactResizeDetector>
        </div>
      ) : (
        children
      )}
    </Container>
  );
};

export const IFXModalBody = ({
  disableBackground = false,
  isScroll = false,
  className,
  contentClassName,
  scrollContainerClassName,
  ...props
}) => {
  const scrollbarRef = useRef();
  const handleUpdateScroll = useCallback(() => {
    //console.log("handleUpdateScroll");
    //if (scrollbarRef.current)
    scrollbarRef.current.updateScroll();
  }, []);

  useObservableCallback(resizeDebounceObv, () => {
    handleUpdateScroll(scrollbarRef);
  });

  return (
    <>
      <Modal.Body
        {...props}
        className={cx("IFXModalBody", className, isScroll && "pr-1")}
      >
        {isScroll ? (
          <PerfectScrollbar ref={scrollbarRef}>
            <div className={cx(scrollContainerClassName, "pr-3")}>
              <IFXModalBodyContent
                className={contentClassName}
                handleResize={handleResize}
                disableBackground={disableBackground}
                isScroll={isScroll}
              >
                {props.children}
              </IFXModalBodyContent>
            </div>
          </PerfectScrollbar>
        ) : (
          <IFXModalBodyContent
            handleResize={handleResize}
            disableBackground={disableBackground}
            isScroll={isScroll}
          >
            {props.children}
          </IFXModalBodyContent>
        )}
      </Modal.Body>
    </>
  );
};
