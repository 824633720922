import initialState from "./initialState";

export function ManageSpmlCrwmlReducer(
    state = initialState.MangeSpmlCrwmlFormValues,
    action
) {
    switch (action.type) {
        case "MANAGE_SPML_CRWML_REQUEST":
            return {
                ...state,
                MangeSpmlCrwmlFormValues: action.payload,
            };
        default:
            return state;
    }
}