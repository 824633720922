export default {
  QueryFSSReducer: {
    searchFssCriteria: {
      serviceType: "initialState",
    },
    searchReleaseFssCriteria: {
      serviceType: "initialState",
    },
    flightInfo: {
      serviceType: "initialState",
    },
    soCreateJobs: [],
  },
  flightInfo: {},
  ruleSetParams: {
    ruleSetCategoryData: { serviceType: "initialState" },
    ruleGroupInfo: { serviceType: "initialState" },
  },
  printInfo: {},
};
