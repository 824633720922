import initialState from "./initialState";

export function UserContextReducer(
  state = initialState.UserContextReducer,
  action
) {
  switch (action.type) {
    case "USER_CONTEXT_INITIAL_LOAD":
      return {
        ...state,
        initialLoad: action.payload,
      };

    case "USER_CONTEXT_SELECTED_AIRLINE":
      return {
        ...state,
        ...action.payload,
      };

    case "USER_CONTEXT_RESET_CARRIER_SWITCH_KEY": {
      return {
        ...state,
        carrierSwitchKey: null,
      };
    }

    case "USER_CONTEXT_AIRLINE_LIST":
      return {
        ...state,
        airlineList: action.payload,
      };
    case "USER_CONTEXT_USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };
    default:
      return state;
  }
}
