import React, {memo} from "react";
import Loader from "react-loaders";

export const LoadingRenderer = memo(() => {
  return (
    <>
      <div className="ifx-loader-renderer">
        <Loader
          className="ifx-processing-icon"
          type="ball-clip-rotate-multiple"
          active={true}
        />
        <div className="loading-text">Loading . . .</div>
      </div>
    </>
  );
});
