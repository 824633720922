import { combineReducers } from "redux";
import { SearchRuleReducer } from "./SearchRuleReducer";
import { QueryFSSReducer } from "./QueryFSSReducer";
import { FssFlightDetailsReducer } from "./FssFlightDetailsReducer";
import { MaintainRuleSetReducer } from "./MaintainRuleSetReducer";
import { ViewRuleLogReducer } from "./ViewRuleLogReducer";

const appEssReducer = combineReducers({
  SearchRuleReducer,
  QueryFSSReducer,
  FssFlightDetailsReducer,
  MaintainRuleSetReducer,
  ViewRuleLogReducer,
});

export function EssReducer(state, action) {
  switch (action.type) {
    case "RESET_REDUX": {
      console.log("RESET_REDUX EssReducer");
      return appEssReducer(undefined, action);
    }
    default:
      return appEssReducer(state, action);
  }
}
