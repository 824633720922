import initialState from "./initialState";

export function LegacyReducer(state = initialState.LegacyReducer, action) {
  switch (action.type) {
    case "LEGACY_REDIRECT_LEGACY_URL":
      //console.log("asutosh here", action.payload.url);
      //console.log("asutosh show state", JSON.stringify(state));
      return {
        ...action.payload,
      };
    case "LEGACY_RESET_LEGACY_STATE":
      return {};
    default:
      return state;
  }
}
