import React, { useState } from "react";
import { Card, Container, Row, Button, Col } from "react-bootstrap";
import { useToast } from "../../../components/AppComponents/IFXToast";

import { sanatizeHtml } from "../../../utils/string";

const onCallback = (e, name) => console.log(`Callback got ${name} clicked`);

const onClose = props =>
  console.log("demo-card onClose is triggered for ", props);

const showDyanamicMsg = infoMsg => {
  return (
    <>
      <p>
        Cannot submit past date spec(s)[
        <font
          size="2"
          color="blue"
          dangerouslySetInnerHTML={{ __html: sanatizeHtml(infoMsg) }}
        />
        ]in bulk for Approval. Please modify Effective Date to be greater than
        today.
      </p>
    </>
  );
};

export const ToastCardNew = React.memo(props => {
  const [toastId, setToastId] = useState(null);
  const {
    ifxToast,
    ifxWarnToast,
    ifxSuccessToast,
    ifxErrorToast,
    ifxInfoToast,
    ifxConfirmToast,
    ifxDismissToast,
    ifxIsActiveToast,
    ifxDoubleConfirmToast,
  } = useToast();
  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title className="mb-4">Toast Card New Version</Card.Title>
        <Container>
          <p>Click the button to see the Toast</p>
          <Row className="mb-2">
            <Button
              variant="success"
              onClick={e => {
                ifxSuccessToast({
                  content: "This is Success !",
                  options: { onClose },
                });
                /* setTimeout(() => {
                  ifxSuccessToast({
                    content: "This is Success 2!",
                    options: { onClose },
                  });
                }, 1000); */
              }}
            >
              Toast Success v2
            </Button>
          </Row>
          <Row className="mb-2">
            <Button
              variant="success"
              onClick={e => {
                ifxSuccessToast({
                  content: "This is Success with overlay!",
                  options: { onClose },
                  hideBackground: false,
                });

                /* setTimeout(() => {
                  ifxSuccessToast({
                    content: "This is Success with overlay 2!",
                    options: { onClose },
                    hideBackground: false,
                  });
                }, 1000); */
              }}
            >
              Toast Success v2 with overlay
            </Button>
          </Row>
          <Row className="mb-2">
            <Button
              variant="info"
              onClick={() => {
                const toastId1 = ifxInfoToast({
                  content: "This is default toast 1",
                  options: { onClose },
                });
                console.log("toastId1", toastId1);
                setTimeout(() => {
                  const toastId2 = ifxInfoToast({
                    content: "This is default toast 2",
                  });
                  console.log("toastId2", toastId2);
                }, 100);
              }}
            >
              Toast Info v2
            </Button>
          </Row>
          <Row className="mb-2">
            <Button
              variant="dark"
              onClick={() =>
                ifxConfirmToast({
                  content: "Please Confirm ...",
                  options: { onClose },
                  onCallback,
                })
              }
            >
              Toast CONFIRM v2
            </Button>
          </Row>
          <Row className="mb-2">
            <Button
              variant="info"
              onClick={() =>
                ifxDoubleConfirmToast({
                  content: "Please Confirm ...",
                  options: { onClose },
                  onCallback,
                })
              }
            >
              Double Confirm
            </Button>
          </Row>
          <Row className="mb-2">
            <Button
              variant="dark"
              onClick={() =>
                ifxDoubleConfirmToast({
                  content:
                    "Would you like to delete his records? longer message Please Confirm, ...",
                  options: { onClose },
                  onCallback,
                })
              }
            >
              Double Confirm 2
            </Button>
          </Row>
          <Row className="mb-2">
            <Button
              variant="warning"
              onClick={() =>
                ifxWarnToast({
                  content: showDyanamicMsg(
                    "123-2, 0.01, 13 Apr 2018<br/>1234, 0.01, 12 May 2017<br/>123456, 0.01, 14 May 2017<br/>"
                  ),
                  onCallback,
                })
              }
            >
              Toast Warning v2
            </Button>
          </Row>
          <Row className="mb-2">
            <Button
              variant="danger"
              onClick={() =>
                ifxErrorToast({
                  content: (
                    <p className="mb-0 text-justify">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum.
                    </p>
                  ),
                  options: { onClose },
                  onCallback,
                })
              }
            >
              Toast ERROR v2
            </Button>
          </Row>
          <Row className="mb-2">
            <Button
              onClick={() => {
                ifxConfirmToast({
                  content:
                    "DEMO: Your session is about to timeout due to inactivity. Would you like to extend?",
                  hideProgressBar: false,
                  autoClose: 20000,
                  onCallback: (e, name, { type }) => {
                    console.log("confirm onCallback", e, name, type);
                    if (type === "yes") {
                    } else {
                    }
                  },
                  options: {
                    pauseOnHover: false,
                    pauseOnFocusLoss: false,
                    onClose: ({ lastAction }) => {
                      //console.log("confirm onClose", lastAction);
                      if (lastAction === null) {
                        ifxDismissToast();
                      }
                    },
                  },
                });
              }}
            >
              Toast Confirmation with auto close
            </Button>
          </Row>
          <Row className="mb-2">
            <Col>
              <Button
                variant="primary"
                onClick={() =>
                  setToastId(
                    ifxToast({
                      content: "Click Close Toast Button to close this",
                      title: "Custom Toast",
                      options: { onClose },
                      onCallback,
                      actions: [
                        { name: "Save", type: "save" },
                        { name: "Cancel", type: "cancel" },
                      ],
                    })
                  )
                }
              >
                Custom Actions
              </Button>
            </Col>
            <Col>
              <Button variant="light" onClick={() => ifxDismissToast(toastId)}>
                Close Custom
              </Button>
            </Col>
            <Col>
              <Button
                variant="secondary"
                onClick={() =>
                  console.log(
                    "is Custom Toast active -",
                    ifxIsActiveToast(toastId)
                  )
                }
              >
                IsActive
              </Button>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
});
