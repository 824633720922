const TIMEOUT = 5 * 60 * 1000;
//const TIMEOUT = 30 * 1000;

export const RESPONSE_TYPES = {
  string: { responseType: "text" },
  text: { responseType: "text" },
  blob: { responseType: "blob" },
  json: { responseType: "json" },
};

export const AJAX_HEADER_IFX_REACT = {
  "ifx-react": "true",
};

export const AJAX_HEADER_FORM_URLENCODED = {
  "Content-Type": "application/x-www-form-urlencoded",
};

export const AJAX_HEADER_JSON = {
  "Content-Type": "application/json",
};

export const AJAX_HEADER_STREAM = {
  Accept: "application/octet-stream",
};

export const AJAX_HEADER_EXCEL = {
  Accept: "application/vnd.ms-excel",
};
export const AJAX_HEADER_ACCEPT_TEXT = {
  Accept: "plain/text",
};

export const AJAX_HEADER_APP_DEFAULT = {
  ...AJAX_HEADER_IFX_REACT,
  ...AJAX_HEADER_FORM_URLENCODED,
};

export const AJAX_HEADER_APP_JSON_DEFAULT = {
  ...AJAX_HEADER_IFX_REACT,
  ...AJAX_HEADER_JSON,
};

export const AJAX_HEADER_APP_STREAM_DEFAULT = {
  ...AJAX_HEADER_JSON,
  ...AJAX_HEADER_STREAM,
};

export const AJAX_HEADER_APP_EXCEL_DEFAULT = {
  ...AJAX_HEADER_JSON,
  ...AJAX_HEADER_EXCEL,
};

export const DEFAULT_AJAX_JSON_SETTINGS = {
  timeout: TIMEOUT,
  headers: AJAX_HEADER_APP_JSON_DEFAULT,
  method: "POST",
};

export const DEFAULT_AJAX_FORM_SETTINGS = {
  timeout: TIMEOUT,
  headers: AJAX_HEADER_APP_DEFAULT,
  method: "POST",
};

export const DEFAULT_AJAX_STREAM_SETTINGS = {
  timeout: TIMEOUT,
  headers: AJAX_HEADER_APP_STREAM_DEFAULT,
  method: "POST",
  ...RESPONSE_TYPES.blob,
};

export const DEFAULT_AJAX_EXCEL_SETTINGS = {
  timeout: TIMEOUT,
  headers: AJAX_HEADER_APP_EXCEL_DEFAULT,
  method: "POST",
  ...RESPONSE_TYPES.blob,
};

/* export const DEFAULT_AJAX_PDF_SETTINGS = {
  timeout: 1200000,
  headers: AJAX_HEADER_APP_JSON_DEFAULT,
  method: "POST",
  ...RESPONSE_TYPES.blob,
}; */
