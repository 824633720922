export const ESOTasks = {
  ESOApplication: "ESOApplication",
  eSOViewSmartOrderDash: "eSOViewSmartOrderDash",
  ViewServiceOrderQuery: "ViewServiceOrderQuery",
  ViewServiceOrderDashboard: "ViewServiceOrderDashboard",
  ServiceOrderCreate: "ServiceOrderCreate",
  RepBrdDocDet: "RepBrdDocDet",
  RepBrdDocSum: "RepBrdDocSum",
  ViewESOFSR: "ViewESOFSR",
  SOExport: "SOExport",
  SODashBoardUpdate: "SODashBoardUpdate",
  SOStatusChng: "SOStatusChng",
  UPDATE_MANUAL_APPROVAL: "UpdateManualApproval",
  SO_COMPONENT_SERVICE_READONLY: "SOComponentServiceReadonly",
  SO_SPEC_AUTHORIZATION: "SOSpecAuthorization",
  VIEW_SO_SPEC_AUTHORIZATION: "ViewSOSpecAuthorization",
  SPML_SPEC_AUTHORIZATION: "SPMLSpecAuthorization",
  CRML_SPEC_AUTHORIZATION: "CRMLSpecAuthorization",
  MANUAL_APP_SPEC_AUTHORIZATION: "ManualAppSpecAuthorization",
  SO_AIRLINE_ORDCNT_CHGE: "SOAirlineOrdCntChng",
  SO_CATERER_REPCNT_CHGE: "SOCatererRepCntChng",
  SO_ADD_SPML: "SOAddSPML",
  SO_DELETE_SPML: "SODeleteSPML",
  SO_UPDATE_SPML: "SOUpdateSPML",
  VIEW_CRML: "ViewCRML",
  SO_ADD_CRML: "SOAddCRML",
  SO_DELETE_CRML: "SODeleteCRML",
  SO_UPDATE_CRML: "SOUpdateCRML",
  SO_PRE_SELECT_VIEW: "SOPreSelectView",
  SO_PRE_SELECT_EDIT: "SOPreSelectEdit",
  SO_PRE_SELECT_AUTHORIZATION: "SOPreSelectAuthorization",
  SO_REOPEN_AUTHORIZATION: "SOReOpenAuthorization",
  SERVICE_ORDER_CREATION: "ServiceOrderCreation",
  SERVICE_ORDER_CREATE: "ServiceOrderCreate",
  SOCreate: "SOCreate",
  VIEW_EDIT_INVOICE: "View/edit invoice",
  VIEW_ITEM_OVERVIEW: "viewItemOverview",
  VIEW_SPML: "ViewSPML",
  VIEW_SERVICE_ORDER_LOG: "ViewServiceOrderLog",
  MANUAL_APPROVAL: "ViewManualApproval",
  SO_SAVE: "SOSave",
  SO_RULE_AUDIT_LOG: "ESOESSCOMMRuleLogView",
  ViewSOeSOeSSCommLog:"ViewSOeSOeSSCommLog",
  SOAddDownLndLeg: "SOAddDownLndLeg",
  SOSearch:"SOSearch",
  SOFltDetailsChng: "SOFltDetailsChng",

  /* PAX Rules*/
  SO_VIEW_PAX_RULE_LOG: "SOViewPAXRuleLog",
  ViewReports: "ViewReports",
  RepOverShort: "RepOverShort",
  RepOrderedBooked: "RepOrderedBooked",
  RepAuthorizations: "RepAuthorizations",
  MaintainPollingPolicies: "MaintainPollingPolicies",
  SOgeneralSetup: "SOgeneralSetup",
  ServiceOrderBoardDocSetup: "ServiceOrderBoardDocSetup",
  TopFocusItems: "TopFocusItems",
  SOJobs: "SOJobs",
  ViewQueryFSS: "ViewQueryFSS",
  ViewPAxVariables: "ViewPAxVariables",
  MaintainRules: "Maintain Rules",
  SearchPAXRules: "SearchPAXRules",
  CreateRule: "eSOCreateRule",
  TestRules: "TestRules",
  ESOExcelImportOrder: "ESOExcelImportOrder",
  ServiceOrdersArch: "ServiceOrdersArch",
  SearchPAXRules: "SearchPAXRules",
  eSOPAXRuleSetCreate: "eSOPAXRuleSetCreate",
  eSOPAXRuleSetUpdate: "eSOPAXRuleSetUpdate",
  PAXRuleSetCopy: "PAXRuleSetCopy",
  PAXRuleSetDelete: "PAXRuleSetDelete",
  PAXRuleSetCatCreate: "PAXRuleSetCatCreate",
  ViewAdjustmentFactor: "ViewAdjustmentFactor",
  ViewFormulas: "ViewFormulas",
  ViewCrewRest: "ViewCrewRest",
  ViewRollover: "ViewRollover",
  PAXRuleSetCatUpdate: "PAXRuleSetCatUpdate",
  PAXRuleSetCatDelete: "PAXRuleSetCatDelete",
  PAXRuleCreate: "eSOPAXRuleCreate",
  PAXRuleUpdate: "eSOPAXRuleUpdate",
  PAXRuleVariableDelete: "PAXVarDelete",
  PaxRuleVariableUpdate: "PAXVarUpdate",
  PAXRuleDelete: "eSOPAXRuleDelete",
  PaxRuleVariableCreate: "PAXVarCreate",
  PollingPoliciesView: "PPlcListView",
  PollingPoliciesUpdate: "PPlcUpdate",
  PollingPolicyDelete: "PPlcDelete",
};
