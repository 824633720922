import initialState from "./initialState";

export function SearchInvoiceReducer(
  state = initialState.SearchInvoiceInfo,
  action
) {
  switch (action.type) {
    case "SEARCH_INV_SELECTION_REQUEST":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
