import React, {memo} from "react";
import { ActionButton } from "./ActionCol";

export const LabelButtonRenderer = memo(({ data, value, buttonProps = {} }) => {
  return (
    <div className="d-flex">
      <div className="float-left action-col-container">
        <ActionButton data={data} {...buttonProps} />
      </div>
      <span className="float-left ifx-label ifx-text-overflow">{value}</span>
    </div>
  );
});
