import React, {
  Fragment,
  useState,
  memo,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { IFXMenuItem } from "./IFXMenuItem";
import { IFXMenus } from "./NavItems";
import { useSelector } from "react-redux";
import { authorizeConfigRecursive } from "../../../../hooks/portal/userSecurityHooks";
import { Link } from "react-router-dom";
import { isEmpty } from "@ifx-react/ifx-react-core/node_modules/ramda";
import PerfectScrollbar from "react-perfect-scrollbar";
import { isEmptyOrNull } from "@ifx-react/ifx-react-core";
import { Redirect } from "react-router-dom";

const MenuList = memo(
  ({
    selectedMenu: { menu = [] } = {},
    setHideOnMouseLeave,
    setIconClicked,
  } = {}) => {
    const onClick = useCallback(() => {
      setHideOnMouseLeave(true);
      setIconClicked(null);
    }, []);
    const menuList = useMemo(
      () =>
        menu.map(({ to = "/", label, content }, ind) => (
          <Fragment key={`ifx-menu-${ind}`}>
            {!content ? (
              <li
                key={`ifx-menu-${ind}`}
                className="ifx-menu-style"
                data-temp={`ifx-menu-${ind}`}
                onClick={onClick}
              >
                <Link to={to}>{label}</Link>
              </li>
            ) : (
              <li
                key={`ifx-menu-${ind}`}
                className="ifx-menu-with-content-style"
                data-temp={`ifx-menu-${ind}`}
              >
                {label}
              </li>
            )}

            {content?.map(({ to = "/", label }, subInd) => (
              <li
                key={`ifx-sub-menu-${subInd}`}
                className="pl-4 ifx-submenu-style"
                data-temp={`ifx-sub-menu-${subInd}`}
                onClick={onClick}
              >
                <Link to={to}>{label}</Link>
              </li>
            ))}
          </Fragment>
        )),
      [menu]
    );

    return (
      <div className="pl-2">
        <ul>{menuList}</ul>
      </div>
    );
  }
);

export const IFXVerticalMenu = memo(
  ({
    showRightMenu,
    setHideOnMouseLeave,
    sideMenuScrollBarRef,
    height,
    iconClicked,
    setIconClicked,
  }) => {
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState({});
    const [hideMenu, setHideMenu] = useState(false);

    const authTaskList = useSelector(
      state => state.PortalReducer.UserContextReducer.userInfo.taskList
    );
    const tmpMenus = useMemo(() => {
      return (
        (authTaskList &&
          authTaskList.length > 0 &&
          IFXMenus.map((mConfig, i) => {
            const newmConfig = authorizeConfigRecursive(
              mConfig,
              {},
              authTaskList
            );
            if (newmConfig && newmConfig.dashboard)
              return (
                <IFXMenuItem
                  key={`${mConfig?.dashboard?.title || "ifx-menu-icon-" + i}`}
                  id={`ifx-menu-icon-${i}`}
                  config={newmConfig}
                  source={mConfig?.dashboard?.source || ""}
                  title={mConfig?.dashboard?.title || ""}
                  setSelectedMenu={setSelectedMenu}
                  selectedMenu={selectedMenu}
                  showRightMenu={showRightMenu}
                  setHideOnMouseLeave={setHideOnMouseLeave}
                  scrollbarRef={sideMenuScrollBarRef}
                  setRedirectUrl={setRedirectUrl}
                  {...{ iconClicked, setIconClicked }}
                  //checked={iconClicked === newmConfig?.dashboard?.title}
                />
              );
          })) ||
        []
      );
    }, [authTaskList, iconClicked]);

    const _showRightMenu = useMemo(() => {
      const show = !isEmpty(selectedMenu) && !showRightMenu;
      show && setHideMenu(false);
      return show;
    }, [selectedMenu, showRightMenu]);

    useEffect(() => {
      if (!_showRightMenu) {
        const timer = setTimeout(() => {
          setHideMenu(true); //unmount menu with delay of 500
        }, 500);
        return () => clearTimeout(timer);
      } else {
        const timer = setTimeout(() => {
          sideMenuScrollBarRef.current.updateScroll();
        });
        return () => clearTimeout(timer);
      }
    }, [_showRightMenu, sideMenuScrollBarRef]);

    return (
      <>
        {!isEmptyOrNull(redirectUrl) && <Redirect to={redirectUrl} />}
        <div className="app-sidebar-menu-container app-sidebar-menu-left">
          <div>{tmpMenus}</div>
        </div>

        <div
          className={`app-sidebar-menu-container app-sidebar-menu-right${(_showRightMenu &&
            " active") ||
            ""}`}
          style={{ height }}
        >
          <div className="py-2 pl-2 pr-1">
            {!hideMenu && (
              <PerfectScrollbar ref={sideMenuScrollBarRef}>
                <MenuList
                  selectedMenu={selectedMenu}
                  setHideOnMouseLeave={setHideOnMouseLeave}
                  setIconClicked={setIconClicked}
                />
              </PerfectScrollbar>
            )}
          </div>
        </div>
      </>
    );
  }
);
