import React, { useState } from "react";
import { Card, Row } from "react-bootstrap";
import {
  IFXTextBox,
  IFXFieldWrapper,
  IFXButton,
  IFXModal,
  IFXModalHeader,
  IFXModalBody,
  IFXModalFooter,
  IFXAccordion,
  IFXFormLayout,
  IFXDataTable,
} from "../../../components/AppComponents";

export const ModalCard = () => {
  const [isOpen, setOpen] = useState(false);
  const [isOpenScroll, setOpenScroll] = useState(false);
  const [isOpenMultiAccordion, setOpenMultiAccordion] = useState(false);

  return (
    <>
      <Card className="main-card mb-3">
        <Card.Body>
          <Card.Title className="mb-4">Modal</Card.Title>

          <IFXButton
            name="Show Modal"
            onClick={() => {
              setOpen(true);
            }}
          />
          <IFXButton
            name="Show Modal Scroll"
            onClick={() => {
              setOpenScroll(true);
            }}
          />
          <IFXButton
            name="Show Modal with multiple Accordion with scroll"
            onClick={() => {
              setOpenMultiAccordion(true);
            }}
          />
        </Card.Body>
      </Card>

      <IFXModal
        show={isOpen}
        onHide={() => {
          setOpen(false);
        }}
        size="md"
      >
        <IFXModalHeader>IFX Modal</IFXModalHeader>
        <IFXModalBody>
          <IFXFormLayout>
            <IFXFieldWrapper
              colProps={{ sm: 6, md: 6, lg: 6 }}
              label="Text Box"
              controlId="sampleTextBox"
            >
              <IFXTextBox></IFXTextBox>
            </IFXFieldWrapper>
            <IFXFieldWrapper
              colProps={{ sm: 6, md: 6, lg: 6 }}
              label="Text Box 2"
              controlId="sampleTextBox2"
            >
              <IFXTextBox></IFXTextBox>
            </IFXFieldWrapper>
            <IFXFieldWrapper
              colProps={{ sm: 6, md: 6, lg: 6 }}
              label="Text Box 3"
              controlId="sampleTextBox3"
            >
              <IFXTextBox></IFXTextBox>
            </IFXFieldWrapper>
            <IFXFieldWrapper
              colProps={{ sm: 6, md: 6, lg: 6 }}
              label="Text Box 4"
              controlId="sampleTextBox4"
            >
              <IFXTextBox></IFXTextBox>
            </IFXFieldWrapper>
          </IFXFormLayout>
        </IFXModalBody>
        <IFXModalFooter>
          <IFXButton name="Update" onClick={() => {}} />
          <IFXButton
            name="Close"
            onClick={() => {
              setOpen(false);
            }}
          />
        </IFXModalFooter>
      </IFXModal>
      <IFXModal
        show={isOpenScroll}
        onHide={() => {
          setOpenScroll(false);
        }}
        size="lg"
      >
        <IFXModalHeader>IFX Modal With Scroll</IFXModalHeader>
        <IFXModalBody isScroll contentClassName="px-0" style={{ height: 300 }}>
          <IFXFormLayout className="pt-1 px-2 pb-2">
            <IFXFieldWrapper
              colProps={{ sm: 6, md: 6, lg: 6 }}
              label="Text Box"
              controlId="sampleTextBox"
            >
              <IFXTextBox></IFXTextBox>
            </IFXFieldWrapper>
            <IFXFieldWrapper
              colProps={{ sm: 6, md: 6, lg: 6 }}
              label="Text Box 2"
              controlId="sampleTextBox2"
            >
              <IFXTextBox></IFXTextBox>
            </IFXFieldWrapper>
            <IFXFieldWrapper
              colProps={{ sm: 6, md: 6, lg: 6 }}
              label="Text Box 3"
              controlId="sampleTextBox3"
            >
              <IFXTextBox></IFXTextBox>
            </IFXFieldWrapper>
            <IFXFieldWrapper
              colProps={{ sm: 6, md: 6, lg: 6 }}
              label="Text Box 4"
              controlId="sampleTextBox4"
            >
              <IFXTextBox></IFXTextBox>
            </IFXFieldWrapper>
          </IFXFormLayout>
          <div className="mb-2">
            <IFXDataTable
              style={{ height: 300 }}
              columnDefs={[]}
            ></IFXDataTable>
          </div>
          <IFXDataTable style={{ height: 300 }} columnDefs={[]}></IFXDataTable>
        </IFXModalBody>
        <IFXModalFooter>
          <IFXButton name="Update" onClick={() => {}} />
          <IFXButton
            name="Close"
            onClick={() => {
              setOpenScroll(false);
            }}
          />
        </IFXModalFooter>
      </IFXModal>
      <IFXModal
        show={isOpenMultiAccordion}
        onHide={() => {
          setOpenMultiAccordion(false);
        }}
        size="lg"
      >
        <IFXModalHeader>IFX Modal With Accordion and Scroll</IFXModalHeader>
        <IFXModalBody isScroll disableBackground style={{ height: 400 }}>
          <IFXAccordion title="Section 1" isOpen={true}>
            <IFXFormLayout>
              <IFXFieldWrapper
                colProps={{ sm: 12, md: 12, lg: 12 }}
                label="Text Box"
                controlId="sampleTextBox"
              >
                <IFXTextBox></IFXTextBox>
              </IFXFieldWrapper>
              <IFXFieldWrapper
                colProps={{ sm: 12, md: 12, lg: 12 }}
                label="Text Box 2"
                controlId="sampleTextBox2"
              >
                <IFXTextBox></IFXTextBox>
              </IFXFieldWrapper>
              <IFXFieldWrapper
                colProps={{ sm: 12, md: 12, lg: 12 }}
                className="mb-0"
                label="Text Box 3"
                controlId="sampleTextBox3"
              >
                <IFXTextBox></IFXTextBox>
              </IFXFieldWrapper>
            </IFXFormLayout>
          </IFXAccordion>
          <IFXAccordion title="Section 2" isOpen={true}>
            <IFXFormLayout>
              <IFXFieldWrapper
                colProps={{ sm: 12, md: 12, lg: 12 }}
                label="Text Box"
                controlId="sampleTextBox"
              >
                <IFXTextBox></IFXTextBox>
              </IFXFieldWrapper>
              <IFXFieldWrapper
                colProps={{ sm: 12, md: 12, lg: 12 }}
                label="Text Box 2"
                controlId="sampleTextBox2"
              >
                <IFXTextBox></IFXTextBox>
              </IFXFieldWrapper>
              <IFXFieldWrapper
                colProps={{ sm: 12, md: 12, lg: 12 }}
                className="mb-0"
                label="Text Box 3"
                controlId="sampleTextBox3"
              >
                <IFXTextBox></IFXTextBox>
              </IFXFieldWrapper>
            </IFXFormLayout>
          </IFXAccordion>
          <IFXAccordion title="Section 3" isOpen={true}>
            <IFXFormLayout>
              <IFXFieldWrapper
                colProps={{ sm: 12, md: 12, lg: 12 }}
                label="Text Box"
                controlId="sampleTextBox"
              >
                <IFXTextBox></IFXTextBox>
              </IFXFieldWrapper>
              <IFXFieldWrapper
                colProps={{ sm: 12, md: 12, lg: 12 }}
                label="Text Box 2"
                controlId="sampleTextBox2"
              >
                <IFXTextBox></IFXTextBox>
              </IFXFieldWrapper>
              <IFXFieldWrapper
                colProps={{ sm: 12, md: 12, lg: 12 }}
                className="mb-0"
                label="Text Box 3"
                controlId="sampleTextBox3"
              >
                <IFXTextBox></IFXTextBox>
              </IFXFieldWrapper>
            </IFXFormLayout>
          </IFXAccordion>
        </IFXModalBody>
        <IFXModalFooter>
          <IFXButton name="Update" onClick={() => {}} />
          <IFXButton
            name="Close"
            onClick={() => {
              setOpenMultiAccordion(false);
            }}
          />
        </IFXModalFooter>
      </IFXModal>
    </>
  );
};
