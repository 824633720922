import { createAsyncAction, createAction } from "../createAction";
import * as types from "./actionTypes";

export const SearchSpecAction = createAsyncAction(types.SEARCH_SPEC);

export const CreateComponentAction = createAsyncAction(types.CREATE_COMPONENT);

export const AddComponentAction = createAsyncAction(types.ADD_COMPONENT);
export const DeleteComponentAction = createAsyncAction(types.DELETE_COMPONENT);

export const SearchSpecPriceAction = createAsyncAction(types.SEARCH_SPEC_PRICE);

export const SpecTagAction = createAsyncAction(types.SPEC_TAG);

export const SpecWhereUsedAction = createAsyncAction(types.SPEC_WHERE_USED);

export const LoadUserPriceGroups = createAsyncAction(
  types.USER_PRICEGROUPS_ACTION,
);

export const StoreUserPriceGroups = createAction(
  types.USER_PRICEGROUPS_ACTION,
  types.STORE_USER_PRICEGROUPS_ACTION,
);

export const SearchContractPriceAction = createAsyncAction(
  types.SEARCH_CONTRACT_PRICE,
);

export const LoadScaleAction = createAsyncAction(types.LOAD_SCALE_SEARCH);

export const SearchACDependencyAction = createAsyncAction(
  types.SEARCH_ACDEPENDENCY,
);

export const SearchRotationTypeAction = createAsyncAction(
  types.SEARCH_ROTATION_TYPE,
);

export const AuditTrailFormActions = createAsyncAction(
  types.AUDIT_TRAIL_FORM_DATA
);
export const SearchPriceGroupAction = createAsyncAction(
  types.SEARCH_PRICE_GROUP
);
export const LoadPriceGroupStationAction = createAsyncAction(
  types.LOAD_PRICEGROUP_STATION
);
export const SearchSpecTypeAction = createAsyncAction(types.SEARCH_SPEC_TYPE);

export const SearchPriceParametersAction = createAsyncAction(
  types.SEARCH_PRICEPARAMETERS
);

export const PrgFormAction = createAsyncAction(types.PRG_FORM_DATA);
export const VersionTimelineAction = createAsyncAction(types.VERSION_TIMELINE);
export const PriceTimelineAction = createAsyncAction(types.PRICE_TIMELINE);
export const SpmlAssignmentAction = createAsyncAction(types.SPML_ASSIGNMENT);
export const CrwmlAssignmentAction = createAsyncAction(types.CRWML_ASSIGNMENT);