import React, { useCallback, useState, useRef } from "react";
import JoditEditor from "jodit-react";

const config = { removeButtons: ["about", "video", "image", "file", "link"] };
export const IFXRichTextEditor = React.memo(({ onChange, value, ...props }) => {
  const editor = useRef(null);
  return (
    <div className="ifx-rte-editor">
      <JoditEditor
        ref={editor}
        value={value}
        tabIndex={1}
        onChange={onChange}
        config={config}
      />
    </div>
  );
});
