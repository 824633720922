import { combineReducers } from "redux";
import { SearchSpecReducer } from "./SearchSpecReducer";
import { SearchPriceReducer } from "./SearchPriceReducer";
import { UserPriceGroupsReducer } from "./UserPriceGroupsReducer";
import { SpecTagReducer } from "./SpecTagReducer";
import { WhereUsedReducer } from "./WhereUsedReducer";
import { ContractPriceReducer } from "./ContractPriceReducer";
import { LoadScaleSearchReducer } from "./LoadScaleSearchReducer";
import { ACDependencyReducer } from "./ACDependencyReducer";
import { AuditTrailReducer } from "./AuditTrailReducer";
import { PriceGroupReducer } from "./PriceGroupReducer";
import { SpecTypesReducer } from "./SpecTypesReducer";
import { RotationTypeReducer } from "./RotationTypeReducer";
import { PriceParametersReducer } from "./PriceParametersReducer";
import { PrgReducer } from "./PrgReducer";
import { PriceTimelineReducer } from "./PriceTimelineReducer";
import{SpmlAssignmentReducer} from "./SpmlAssignmentReducer"
import{CrwmlAssignmentReducer} from "./CrwmlAssignmentReducer"

import { VersionTimelineReducer } from "./VersionTimelineReducer";
const appEsmReducer = combineReducers({
  SearchSpecReducer,
  SearchPriceReducer,
  UserPriceGroupsReducer,
  SpecTagReducer,
  WhereUsedReducer,
  ContractPriceReducer,
  LoadScaleSearchReducer,
  ACDependencyReducer,
  AuditTrailReducer,
  PriceGroupReducer,
  SpecTypesReducer,
  RotationTypeReducer,
  PriceParametersReducer,
  PrgReducer,
  VersionTimelineReducer,
  PriceTimelineReducer,
  SpmlAssignmentReducer,
  CrwmlAssignmentReducer,
});

export function EsmReducer(state, action) {
  switch (action.type) {
    case "RESET_REDUX": {
      return appEsmReducer(undefined, action);
    }
    default:
      return appEsmReducer(state, action);
  }
}
