import initialState from "./initialState";

export default function InvoicingPeriodReducer(
  state = initialState.InvoicingPeriodInfo,
  action
) {
  switch (action.type) {
    case "SEARCH_INVOICING_PERIOD_SELECTION_REQUEST":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
