import initialState from "./initialState";

export function WhereUsedReducer(
  state = initialState.whereUsedFormInfo,
  action
) {
  switch (action.type) {
    case "SPEC_WHERE_USED_REQUEST":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
