import React, { useState } from "react";
import { Card } from "react-bootstrap";
import {
  IFXDropdown,
  IFXFieldWrapper,
} from "../../../components/AppComponents";

export const DropdownCard = () => {
  const colorList = [
    { label: "red", value: "#FF0000" },
    { label: "green", value: "#00FF00" },
    { label: "yellow", value: "#FFFF00" },
    { label: "blue", value: "#0000FF" },
    { label: "black", value: "#000000" },
    { label: "white", value: "#FFFFFF" },
  ];

  const [formState, setFormState] = useState({
    colorsId1: [],
    colorsId2: [{ label: "red", value: "#FF0000" }],
  });

  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title>Dropdown</Card.Title>
        <IFXFieldWrapper
          label="Colors"
          controlId="colorsId"
          type="group"
          alwaysFloat={(() => formState.colorsId1.length > 0)()}
        >
          <IFXDropdown
            options={colorList}
            selected={formState.colorsId1}
            onChange={v => {
              setFormState({ ...formState, colorsId1: v });
            }}
          />
        </IFXFieldWrapper>

        <Card.Title className="my-4">Dropdown Default Value</Card.Title>
        <IFXFieldWrapper
          label="Colors"
          controlId="colorsId2"
          type="group"
          alwaysFloat={(() => formState.colorsId2.length > 0)()}
        >
          <IFXDropdown
            options={colorList}
            selected={formState.colorsId2}
            multiple={true}
            onChange={v => {
              setFormState({ ...formState, colorsId2: v });
            }}
          />
        </IFXFieldWrapper>

        <Card.Title className="my-4">Dropdown Disabled</Card.Title>
        <IFXFieldWrapper
          label="Colors"
          controlId="colorsId3"
          type="group"
          alwaysFloat={(() => formState.colorsId2.length > 0)()}
        >
          <IFXDropdown
            disabled
            options={colorList}
            selected={formState.colorsId2}
            multiple={true}
            onChange={v => {
              setFormState({ ...formState, colorsId2: v });
            }}
          />
        </IFXFieldWrapper>
      </Card.Body>
    </Card>
  );
};
