import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { IFXDateRangePicker } from "../../../components/AppComponents";
import { IFXFieldWrapper } from "../../../components/AppComponents/IFXFieldWrapper";
import { format } from "date-fns";

export const DateRangePickerCard = () => {
  const [dateRange, setDateRange] = useState({});
  const [dateRangeDV, setDateRangeDV] = useState({
    from: {
      serialized: format(new Date(), "MM/dd/yyyy"),
      dateValue: new Date(),
    },
    to: { serialized: "03/31/2020" },
  });

  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title>Date Range</Card.Title>
        <IFXFieldWrapper
          type="range"
          label={["From Date", "To Date"]}
          controlId={["fromDateId", "toDateId"]}
          alwaysFloat={[
            (() => dateRange.from != null)(),
            (() => dateRange.to != null)(),
          ]}
        >
          <IFXDateRangePicker
            selected={dateRange}
            onChange={v => {
              setDateRange(v);
            }}
          />
        </IFXFieldWrapper>

        <Card.Title className="mt-4 mb-4">
          Date Range with default value
        </Card.Title>
        <IFXFieldWrapper
          type="range"
          label={["From Date", "To Date"]}
          controlId={["fromDateDVId", "toDateDVId"]}
          alwaysFloat={[
            (() => dateRangeDV.from != null)(),
            (() => dateRangeDV.to != null)(),
          ]}
          disabled={[false, true]}
        >
          <IFXDateRangePicker
            selected={dateRangeDV}
            onChange={v => {
              setDateRangeDV(v);
            }}
          />
        </IFXFieldWrapper>
      </Card.Body>
    </Card>
  );
};
