import initialState from "./initialState";

export function DocumentCenterReducer(
  state = initialState.emptyObject,
  action
) {
  switch (action.type) {
    case "DOCUMENT_CENTER_PUT_POLL_RESULT": {
      return { result: action.payload };
    }

    default:
      return state;
  }
}
