import initialState from "./initialState";

export function PaxSearchRuleReducer(
  state = initialState.PaxSearchRuleReducer,
  action
) {
  switch (action.type) {
    case "SEARCH_RULE_REQUEST":
      return action.payload;

    default:
      return state;
  }
}
