export const PortalTasks = {
  PortalApplication: "Portal",
  AdminMainMenu: "AdminMainMenu",
  viewReportDownloadCenter: "viewReportDownloadCenter",
  ViewDocument: "ViewDocument",
  SearchContactDirectory: "SearchContactDirectory",
  SearchUsers: "SearchUsers",
  SearchStation: "SearchStation",
  SearchCateringStation: "SearchCateringStation",
  maintainCaterer: "maintainCaterer",
  ViewCaterer: "ViewCaterer",
  ifxSystemAdministration: "ifxSystemAdministration",
  MaintainUserProfile: "MaintainUserProfile",
  TestPage: "TestPage",

  /** Reports Speccific Tasks */
  ReportCatererList: "REPORT.CATERERLIST",
  ReportCateringService: "REPORT.CATERINGSERVICE",
  ReportChargeType: "REPORT.CHARGETYPE",
  ReportDishOverflow: "REPORT.DISHOVERFLOWTAGEXP",
  ReportDishWithOutOverflow: "REPORT.DISHWTHOUTOVERFLOWTAGEXP",
  ReportMealOrder: "REPORT.ESOMEALORDER",
  ReportOrderMealServed: "REPORT.ESOMEALSERVED",
  ReportInvoiceMealServed: "REPORT.ESVMEALSERVED",
  ReportInvoiceDetails: "REPORT.INVOICEDETAILS",
  ReportInvoicePayment: "REPORT.INVPMNTREQUEST",
  ReportMealDish: "REPORT.MEALDISH",
  ReportMealWastage: "REPORT.MEALWASTAGE",
  ReportNoDishPrice: "REPORT.NODISHPRICE",
  ReportItemsServed: "REPORT.NOOFITEMSSERVED",
  ReportItemOverview: "REPORT.SOITEMOVERVIEW",
  ReportSpecDesc: "REPORT.SPECDESC",
  ReportSpecMealType: "REPORT.SPECMEALTYPEEXCEPTION",
  ReportSpecPricable: "REPORT.SPECPRCABLE",
  ReportSpecPrice: "REPORT.SPECPRICE",
  ReportSPMLConsumption: "REPORT.SPMLCONSUMPTION",
  IA42InvoiceDetails: "REPORT.IA42INVOICEDETAILS",

  CreateNotice: "CreateNotice",
  SearchNotice: "SearchNotice",
  EditNotice: "EditNotice",
  DeleteNotice: "DeleteNotice",
  ViewNotice: "ViewNotice",
  ReportNonMatchACTypeShip: "REPORT.NOTMATCHSHIPNOACTYPEORDERS",
  ReportApprovedIngredient: "REPORT.APPROVEDINGREDIENT",
  ReportApprovedRecipe: "REPORT.APPROVEDRECIPE",
  ReportApprovedMenu: "REPORT.APPROVEDMENU",
  ReportInvValidation: "REPORT.INVOICEVALIDATION",
  ReportSPMT: "REPORT.SPMT",
  ReportCrewMeal: "REPORT.CREWMEAL",
  ReportDownloadCrewMeal: "REPORT.DOWNLINECREWMEAL",
  ReportAgingReport: "REPORT.AGINGREPORT",
  ReportCancelledFltSOS: "REPORT.CANCELLEDFLIGHTSOS",
  ReportRemittance: "REPORT.REMREPORT",
  ReportCompareReport: "REPORT.COMPAREREPORT",
  ReportAllRejectionReport: "REPORT.ALLREJECTIONREPORT",
  ReportMonthlySPML: "REPORT.MONTHLYSPML",
  ReportCompareBrdFltCount: "REPORT.COMPAREBRDFLTCOUNT",
  ReportMenuRotation: "REPORT.MENUROTATIONREPORT",
  ReportInvDetailSummary: "REPORT.INVDETAILSUMMARY",
  ReportMenuReport: "REPORT.MENUREPORT",
  ReportUnusedFltTag: "REPORT.UNUSEDFLTTAGREPORT",
  ReportInternationalLocation: "REPORT.INTERNATIONLOCATIONREPORT",
  ReportTimeToDL: "REPORT.TIMETODLREPORT",
  ReportEGMApp: "REPORT.EGMAPPREPORT",

  ReportMissingPrice: "REPORT.MISSINGPRICE",
  ReportPennyPrice: "REPORT.PENNYPRICE",
  ReportZeroPrice: "REPORT.ZEROPRICE",

  /** Document Managements Task Const.*/
  SearchDocuments: "SearchDocuments",
  UploadDocument: "UploadDocument",
  CreateFolder: "CreateFolder",
  ViewDocumentProperties: "ViewDocumentProperties",
  ViewFolderProperties: "ViewFolderProperties",
  ModifyDocumentProperties: "ModifyDocumentProperties",
  EditFolder: "EditFolder",
  UploadDocumentVersion: "UploadDocumentVersion",
  DeleteDocument: "DeleteDocument",
  DeleteFolder: "DeleteFolder",
  ViewDocVersionHistory: "ViewDocVersionHistory",
  AddFolderToFavorites: "AddFolderToFavorites",
  EmailPreference: "EmailPreference",
  UserLinks: "UserLinks",

  /** Aircraft Task Const */
  SearchAircraftTypes: "SearchAircraftTypes",
  SearchACSeatCfgs: "SearchACSeatCfgs",
  AddACSeatCfgs: "AddACSeatCfgs",
  EditACSeatCfgs: "EditACSeatCfgs",
  DeleteACSeatCfgs: "DeleteACSeatCfgs",
  /** Manage SPMLCRWML */
  ManageSpmlCrwml: "ManageSpmlCrwml",

  /* Help Links */
  ManageHelpLinks: "ManageHelpLinks",
};
