import React from "react";
import { Card, FormGroup, Row, Container, Col } from "react-bootstrap";
import {
  ButtonConfig,
  IFXButton,
  ButtonMode,
  IFXSVGTemplates,
  ColorOptions,
  IFXPrimaryIconButton,
  IFXGridPrimaryIconButton,
  IFXGridSecondaryIconButton,
  IFXGridRowActionIconButton,
  IFXPageHeaderIconButton,
  IFXAccordionHeaderIconButton,
  IFXIconButton,
} from "../../../components/AppComponents";

export const ButtonCard = () => {
  const alertHello = () => alert("Button Clicked");

  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title>Buttons</Card.Title>
        <FormGroup>
          <div>
            <IFXButton type="submit" name="My Submit" className="mb-1" />
            <IFXButton type="cancel" name="My Cancel" className="mb-1" />
            <IFXButton
              className="hello-alert"
              onClick={alertHello}
              className="mb-1"
            >
              Alert Hello
            </IFXButton>
            {Object.keys(ButtonConfig).map(key => {
              //console.log(key);
              return <IFXButton key={key} type={key} className="mb-1" />;
            })}

            <Container className="mt-2">
              <Row>
                <Col>
                  <h6>Button Options:</h6>
                </Col>
              </Row>
              <Row className="my-1">
                <Col className="text-right">icon-label:</Col>
                <Col>
                  <IFXButton type="download" mode={ButtonMode.ICON_LABEL} />
                </Col>
                <Col className="text-right">icon:</Col>
                <Col>
                  <IFXButton type="download" mode={ButtonMode.ICON} />
                </Col>
              </Row>
              <Row className="my-1">
                <Col className="text-right">label:</Col>
                <Col>
                  <IFXButton type="download" mode={ButtonMode.LABEL} />
                </Col>
                <Col className="text-right">label-icon:</Col>
                <Col>
                  <IFXButton type="download" mode={ButtonMode.LABEL_ICON} />
                </Col>
              </Row>
              <Row className="my-1">
                <Col className="text-right">label-mini:</Col>
                <Col>
                  <IFXButton type="download" mode={ButtonMode.LABEL_MINI} />
                </Col>
                <Col className="text-right">label-full-mini:</Col>
                <Col>
                  <IFXButton
                    type="download"
                    mode={ButtonMode.LABEL_FULL_MINI}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <h6>Button Icon Mini:</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  {Object.keys(ButtonConfig)
                    .filter(i => ButtonConfig[i].icon)
                    .map(key => {
                      //console.log(key);
                      return (
                        <IFXButton
                          key={key}
                          mode={ButtonMode.ICON_MINI}
                          type={key}
                        />
                      );
                    })}
                  <IFXButton
                    key={"trash"}
                    mode={ButtonMode.ICON_MINI}
                    icon="trash"
                    color="danger"
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <h6>Button Label Mini:</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  {Object.keys(ButtonConfig)
                    .filter(i => ButtonConfig[i].icon)
                    .map(key => {
                      //console.log(key);
                      return (
                        <IFXButton
                          key={key}
                          mode={ButtonMode.LABEL_MINI}
                          type={key}
                        />
                      );
                    })}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <h6>Button Label Icon Circle:</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  {Object.keys(ButtonConfig)
                    .filter(i => ButtonConfig[i].icon)
                    .map(key => {
                      //console.log(key);
                      return (
                        <IFXButton
                          key={key}
                          mode={ButtonMode.ICON_MINI}
                          type={key}
                          shape="circle"
                        />
                      );
                    })}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <h6>Button Label Mini Circle:</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  {Object.keys(ButtonConfig)
                    .filter(i => ButtonConfig[i].icon)
                    .map(key => {
                      //console.log(key);
                      return (
                        <IFXButton
                          key={key}
                          mode={ButtonMode.LABEL_MINI}
                          type={key}
                          shape="circle"
                        />
                      );
                    })}
                </Col>
              </Row>
              <div
                className="p-2"
                style={{ backgroundColor: "#F2F2F2", margin: ".25rem -.5rem" }}
              >
                <Row className="mt-2">
                  <Col>
                    <h6>Button style 'ifx-monochrome':</h6>
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>IFXPrimaryIconButton:</Col>
                </Row>
                <Row>
                  <Col>
                    {Object.keys(IFXSVGTemplates).map(icn => {
                      return (
                        <IFXPrimaryIconButton
                          className="m-1"
                          key={icn}
                          icon={icn}
                          name={icn}
                        />
                      );
                    })}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>IFXGridPrimaryIconButton:</Col>
                </Row>
                <Row className="mt-1">
                  <Col>
                    {Object.keys(IFXSVGTemplates).map(icn => {
                      return (
                        <IFXGridPrimaryIconButton
                          key={icn}
                          className="m-1"
                          icon={icn}
                          name={icn}
                        />
                      );
                    })}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>IFXGridSecondaryIconButton:</Col>
                </Row>
                <Row className="mt-1">
                  <Col>
                    {Object.keys(IFXSVGTemplates).map(icn => {
                      return (
                        <IFXGridSecondaryIconButton
                          key={icn}
                          className="m-1"
                          icon={icn}
                          name={icn}
                        />
                      );
                    })}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>IFXGridRowActionIconButton:</Col>
                </Row>
                <Row className="mt-1">
                  <Col>
                    {Object.keys(IFXSVGTemplates).map(icn => {
                      return (
                        <IFXGridRowActionIconButton
                          key={icn}
                          className="m-1"
                          icon={icn}
                          name={icn}
                        />
                      );
                    })}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>IFXPageHeaderIconButton:</Col>
                </Row>
                <Row className="mt-1">
                  <Col>
                    {Object.keys(IFXSVGTemplates).map(icn => {
                      return (
                        <IFXPageHeaderIconButton
                          key={icn}
                          className="m-1"
                          icon={icn}
                          name={icn}
                        />
                      );
                    })}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>IFXAccordionHeaderIconButton:</Col>
                </Row>
                <Row className="mt-1 px-3">
                  <Col className="mt-1 p-1" style={{ background: "#7b868c" }}>
                    {Object.keys(IFXSVGTemplates).map(icn => {
                      return (
                        <IFXAccordionHeaderIconButton
                          key={icn}
                          className="m-1"
                          icon={icn}
                          name={icn}
                        />
                      );
                    })}
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    {Object.values(ColorOptions)
                      .filter(c => {
                        return c !== "transparent";
                      })
                      .map(color => {
                        return (
                          <IFXIconButton
                            key={color}
                            className="m-1"
                            icon="bulb"
                            name="bulb"
                            color={color}
                          />
                        );
                      })}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>With Shadow:</Col>
                </Row>
                <Row>
                  <Col>
                    {Object.keys(IFXSVGTemplates).map(icn => {
                      return (
                        <IFXGridPrimaryIconButton
                          key={icn}
                          className="m-1"
                          icon={icn}
                          name={icn}
                          shadow={true}
                        />
                      );
                    })}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    {Object.keys(IFXSVGTemplates).map(icn => {
                      return (
                        <IFXGridSecondaryIconButton
                          key={icn}
                          className="m-1"
                          icon={icn}
                          name={icn}
                          shadow={true}
                        />
                      );
                    })}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    {Object.values(ColorOptions)
                      .filter(c => {
                        return (
                          c !== "transparent" && c !== "yellow" && c !== "white"
                        );
                      })
                      .map(color => {
                        return (
                          <IFXIconButton
                            key={color}
                            className="m-1"
                            icon="bulb"
                            name="bulb"
                            color={color}
                            shadow={true}
                          />
                        );
                      })}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h6>Toggle Button:</h6>
                  </Col>
                </Row>
                <Row className="mt-1 px-3">
                  <Col className="mt-1 p-1" style={{ background: "#7b868c" }}>
                    <IFXAccordionHeaderIconButton
                      className="m-1"
                      icon={["approve", "reject"]}
                      name={["Approve 1", "Reject 1"]}
                      isToggle
                      onToggle={toggle => {
                        console.log("toggle 1", toggle);
                      }}
                      defaultToggle
                    />
                    <IFXAccordionHeaderIconButton
                      className="m-1"
                      type="approve-reject"
                      onToggle={toggle => {
                        console.log("toggle 2", toggle);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-1 px-3">
                  <Col className="mt-1 p-1">
                    <IFXButton
                      className="m-1"
                      type="approve-reject"
                      onToggle={toggle => {
                        console.log("toggle 3", toggle);
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </FormGroup>
      </Card.Body>
    </Card>
  );
};
