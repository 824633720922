import { ESMTasks } from "../../../../../const/esm/ESMTaskConsts";

export const SpecManagerMenu = {
  dashboard: {
    icon: "specifications",
    title: "SPECIFICATIONS",
    source: "/app/esm/",
    authorize: { hasTask: ESMTasks.ESMApplication },
  },
  menu: [
    {
      icon: "pe-7s-browser",
      label: "Dashboard",
      to: "/app/esm/spec/search",
      authorize: { hasTask: ESMTasks.SearchSpec },
    },
    {
      icon: "pe-7s-browser",
      label: "Spec",
      authorize: {
        hasAnyTask: [
          ESMTasks.SearchSpec,
          ESMTasks.CreateSpec,
          ESMTasks.ViewVersionTimeLine,
          ESMTasks.ViewSpecAuditTrail,
          ESMTasks.SpecGlobalFindReplace,
        ],
      },
      content: [
        {
          label: "Search Spec",
          to: "/app/esm/spec/search",
          authorize: { hasTask: ESMTasks.SearchSpec },
        },
        {
          label: "Create Spec",
          to: "/app/esm/spec/create",
          authorize: { hasTask: ESMTasks.CreateSpec },
        },
        {
          label: "Version Timeline",
          to: "/app/esm/spec/versions",
          authorize: { hasTask: ESMTasks.ViewVersionTimeLine },
        },
        {
          label: "Audit Trail",
          to: "/app/esm/spec/audit",
          authorize: { hasTask: ESMTasks.ViewSpecAuditTrail },
        },
        {
          label: "Spec Global Replace",
          to: "/app/esm/spec/replace",
          authorize: { hasTask: ESMTasks.SpecGlobalFindReplace },
        },
      ],
    },
    {
      icon: "pe-7s-browser",
      label: "Price",
      authorize: {
        hasAnyTask: [
          ESMTasks.SearchSpecPrice,
          ESMTasks.ViewAirLineSpecPriceTimeine,
          ESMTasks.PriceGroup,
          ESMTasks.SearchContractPrices,
          ESMTasks.SearchPricingParameter,
        ],
      },
      content: [
        {
          label: "Search Price",
          to: "/app/esm/price/search",
          authorize: { hasTask: ESMTasks.SearchSpecPrice },
        },
        {
          label: "Price Timeline",
          to: "/app/esm/price/timeline",
          authorize: { hasTask: ESMTasks.ViewAirLineSpecPriceTimeine },
        },
        {
          label: "Price Group",
          to: "/app/esm/price/pricegroup",
          authorize: { hasTask: ESMTasks.PriceGroup },
        },
        {
          label: "Contract Price",
          to: "/app/esm/price/contractPrice",
          authorize: { hasTask: ESMTasks.SearchContractPrices },
        },
        {
          label: "Price Parameters",
          to: "/app/esm/price/parameters",
          authorize: { hasTask: ESMTasks.SearchPricingParameter },
        },
      ],
    },
    {
      icon: "pe-7s-browser",
      label: "Administration",
      authorize: {
        hasAnyTask: [
          ESMTasks.LoadScale,
          ESMTasks.SpecType,
          ESMTasks.SpecTag,
          ESMTasks.RotationType,
          ESMTasks.SearchCateringRule,
          ESMTasks.SearchProposalRequestGroup,
          ESMTasks.SPMLMenu,
          ESMTasks.CRWMLMenu,
          ESMTasks.ACDependency,
        ],
      },
      content: [
        {
          label: "Spec Types",
          to: "/app/esm/specType",
          authorize: { hasTask: ESMTasks.SpecType },
        },
        {
          label: "Spec Tags",
          to: "/app/esm/specTags/specCategory",
          authorize: { hasTask: ESMTasks.SpecTag },
        },
        {
          label: "Load Scales",
          to: "/app/esm/loadScale",
          authorize: { hasTask: ESMTasks.LoadScale },
        },
        {
          label: "Rotation Types",
          to: "/app/esm/rotationType",
          authorize: { hasTask: ESMTasks.RotationType },
        },
        {
          label: "Aircraft Dependency",
          to: "/app/esm/aircraftDependency",
          authorize: { hasTask: ESMTasks.ACDependency },
        },
        {
          label: "SPML Assignment",
          to: "/app/esm/spml/search",
          authorize: { hasTask: ESMTasks.SPMLMenu },
        },
        {
          label: "CRWML Assignment",
          to: "/app/esm/crwml/search",
          authorize: { hasTask: ESMTasks.CRWMLMenu },
        },
        {
          label: "Proposal Request Groups",
          to: "/app/esm/prg",
          authorize: { hasTask: ESMTasks.SearchProposalRequestGroup },
        },
        {
          label: "Catering Rules",
          to: "/app/esm/cateringRule",
          authorize: { hasTask: ESMTasks.SearchCateringRule },
        },
      ],
    },
  ],
};
