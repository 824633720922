export const UserRegistrationConsts = {
  CARRIER_LIST_CONST: "carrier-list",
  TYPE_OF_USER_CONST: "type-of-user-list",
  LANG_LIST_CONST: "language-list",
  COUNTRY_LIST_CONST: "country-list",
  SECRET_QUESTION_LIST_CONST: "secret-questions",
  DATE_FORMAT_LIST_CONST: "date-format-list",
  TIME_FORMAT_LIST_CONST: "time-format-list",
  REGISTER_CANCEL_CONFIRM: {
    type: "CONFIRM",
    title: "Confirmation",
    content: "This will cancel the entire registration process. Are you sure?",
  },
  CLEAR_ACCESS_REQ_CONFIRM: {
    type: "CONFIRM",
    title: "Confirmation",
    content:
      "Are you sure you wish to reset this user's access request message?",
  },
  DELETE_ACCESS_PROFILE_CONFIRM: {
    type: "CONFIRM",
    title: "Confirmation",
    content: "Do you really want to delete the User Access Profile?",
  },
  DELETE_CREDENTIAL_SET_CONFIRM: {
    type: "CONFIRM",
    title: "Confirmation",
    content: "Do you really want to delete the User credential?",
  },
};
