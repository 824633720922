import React, {
  useRef,
  useState,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import { ajax } from "rxjs/ajax";
import { of, Subject } from "rxjs";
import { map, delay } from "rxjs/operators";
import {
  generateInfiniteMockData,
  staticRowMockData,
} from "../../mockData/dataGridMockData";

import {
  IFXDataTable,
  IFXAccordion,
  DT_DEFAULT_CONTROL_ACTIONS,
  IFXRadioButton,
  DT_MODEL_TYPE,
  IFXButton,
  createNewRowData,
} from "../../../components/AppComponents";
import { Row, Col, Container } from "react-bootstrap";

import { useObservableCallback } from "../../../hooks";
import { getServerSideGridColumn } from "./serverSideGridColumn";
import { getClientSideGridColumn } from "./clientSideGridColumn";
import { createAction as reduxCreateAction } from "redux-actions";

const ajaxMockObs$ = new Subject().pipe(
  //debounceTime(400), // to remove quick request
  delay(500), //simulating server delay
  map(params => {
    //console.log("ajaxMockObs$ map params::", params);
    let result = generateInfiniteMockData(params);
    return { ...params, ...result };
  })
  //debounceTime(400)
);

const createDatasource = myFormParams => {
  //console.log("createDatasource myFormParams::", myFormParams);
  return {
    myFormParams, //only if we pass formParams it is getting scoped in getRows
    rowCount: null,
    getRows: params => {
      //console.log("getRows myFormParams::", myFormParams);
      //console.log("getRows params::", params);
      if (myFormParams == null) {
        params.successCallback([], 0);
      } else {
        //params.successCallback(rowData, rowData.length);
        /* ajaxMockObs$({ ...params, data: myFormParams }).subscribe(val => {
          console.log("success");
        }); */
        ajaxMockObs$.next({ ...params, data: myFormParams });
      }
    },
  };
};

//below action is added here only for demo, actual actions should be placed in corresponding module action
const createAction = (type, actionTypes, prefix = true) => {
  const Action = {
    type,
  };
  const _prefix = prefix === true ? `${type}_` : "";
  Object.keys(actionTypes).forEach(key => {
    //console.log(`${type}_${actionTypes[key]}`);
    Action[actionTypes[key]] = reduxCreateAction(
      `${_prefix}${actionTypes[key]}`
    );
  });
  //console.log(Action);
  return Action;
};

const DT_ACTIONS = [
  "SET_ROW_DATA",
  "ADD_ROW",
  "DELETE_ROW",
  "UPDATE_ROW",
  "CLEAR",
  "NEW_PAGE_DATA",
];

const DemoClientDTActions = createAction("MY_CLIENT_GRID", DT_ACTIONS, false);

function DemoClientDTReducer(state, action) {
  switch (action.type) {
    /* case "SET_ROW_DATA":
      return {
        ...state,
      }; */

    case "ADD_ROW": {
      const {
        payload: { add, addIndex },
      } = action;
      let _rowData = [...state.rowData];
      _rowData.splice(addIndex, 0, ...add);
      return { ...state, rowData: _rowData };
    }
    case "NEW_PAGE_DATA": {
      const {
        payload: { currentPage, pageSize, active },
      } = action;
      let _rowData = [...state.rowData];
      const newRows = _rowData.slice(currentPage, pageSize);
      return { ...state, rowData: [..._rowData, ...newRows] };
    }
    /* case "DELETE_ROW": {
      let _rowData = [...state.rowData];
      let flatRowData = flattenRowData(_rowData);
      console.log("flatRowData", flatRowData);

      const {
        payload: {
          event: { node, data },
        },
      } = action;

      let oldData = getDataById(flatRowData, node.id);
      console.log("data to update", oldData);
      oldData.flightNo = "";

      return state;
    } */

    default:
      return state;
  }
}

export const DataGrid = ({ contentHeight }) => {
  //console.log(contentHeight);
  const refClientGrid = useRef(null);
  const refServerGrid = useRef(null);
  const [datatableHeight, setDatatableHeight] = useState(200);
  const [demoOptions, setDemoOptions] = useState({ mode: "Client Side" });
  const [columnDefs, setColumnDefs] = useState();
  const [serverSideColumnDefs, setServerSideColumnDefs] = useState();
  const [formParams, setFormParams] = useState(null);
  const [datasource, setDatasource] = useState(null);
  const [selectAll, setSelectAll] = useState(false); // only for serverside

  const [dtState, dispatch] = useReducer(
    DemoClientDTReducer,
    //below is default state
    {
      rowData: staticRowMockData,
    }
  );

  const onCellValueChanged = event => {
    event.data["modified"] = true;
    //todo maybe update through redux instead of direct maniuplation if it requires some refresh
  };

  useObservableCallback(ajaxMockObs$, response => {
    //console.log(response);
    response.successCallback(response.result, response.totalRecords);
    if (refServerGrid && refServerGrid.current)
      refServerGrid.current.updateDisplayedRows();
    //response.failCallback
  });

  // OnLoad one time
  useEffect(() => {
    setFormParams({
      dummyKey1: "value1",
      dummyKey2: "value2",
    });
  }, []);

  useEffect(() => {
    // Adding Dynamic Columns
    const hasEditableTask = true;

    if (demoOptions.mode === "Server Side") {
      let _columnDefs = [
        ...getServerSideGridColumn({
          setSelectAll,
        }),
      ];
      _columnDefs.splice(
        8,
        0,
        ...["DL", "CX", "SK"].map(item => ({
          headerName: item,
          headerTooltip: item,
          width: 60,
          suppressSizeToFit: true,
          flex: false,
          field: `carrier.${item}`,
          cellRenderer: "EditableCheckboxFacade",
          editable: false,
        }))
      );
      setServerSideColumnDefs([..._columnDefs]);
    } else {
      let _columnDefs = [
        ...getClientSideGridColumn({ editButtonEnabled: hasEditableTask }),
      ];
      _columnDefs.splice(
        8,
        0,
        ...["DL", "CX", "SK"].map(item => ({
          headerName: item,
          headerTooltip: item,
          width: 60,
          suppressSizeToFit: true,
          flex: false,
          field: `carrier.${item}`,
          cellRenderer: "EditableCheckboxFacade",
          cellEditor: "IFXCheckboxCol",
        }))
      );
      setColumnDefs([..._columnDefs]);
    }
  }, [demoOptions.mode]);

  // we can change datasource on search click instead of useEffect
  useEffect(() => {
    //console.log("formParams::", formParams);
    setDatasource(createDatasource(formParams));
  }, [formParams]);

  useEffect(() => {
    //console.log(contentHeight);
    let pageConainer = document.getElementById("page-container");
    let myDatatable = document.getElementsByClassName("ifx-datatable")[0];
    if (myDatatable) {
      setDatatableHeight(
        pageConainer.offsetTop + contentHeight - myDatatable.offsetTop - 35
      );
    }
  }, [contentHeight]);

  // Add few rows on page change hack. Here we will be making api call instead.
  const onPageChange = useCallback(params => {
    // console.log("page changed event handler called", params);
    const {
      api: {
        paginationProxy: { currentPage, pageSize, active, totalPages } = {},
        sortController: { getSortModel },
      } = {},
    } = params;

    console.log("sort model ", getSortModel());
    // console.log(currentPage, pageSize, active);
    if (currentPage && totalPages < 3) {
      // This  logic is to hack example of api call on page change
      dispatch(
        DemoClientDTActions.NEW_PAGE_DATA({ currentPage, pageSize, active })
      );
    }
  }, []);

  return (
    <>
      <Container fluid className="p-0" id="page-container">
        <Container fluid>
          <Row className="my-1">
            <Col className="text-left">
              Demo options:
              {["Client Side", "Server Side"].map(mode => (
                <IFXRadioButton
                  key={mode}
                  className="d-inline-block mx-2"
                  name="modeRadios"
                  id={`choice_${mode.toLowerCase()}`}
                  onChange={v => {
                    setDemoOptions({
                      ...demoOptions,
                      mode: v.currentTarget.value,
                    });
                  }}
                  checked={demoOptions.mode === mode}
                  value={mode}
                  label={
                    mode === "Server Side"
                      ? mode + " (For large readonly data with pagination)"
                      : mode + " (added sample Tree Data )"
                  }
                />
              ))}
            </Col>
            {demoOptions.mode === "Server Side" ? (
              <>
                <IFXButton
                  name="log selected rows"
                  onClick={() => {
                    if (selectAll) {
                      console.log("all rows selected");
                    } else {
                      const api = refServerGrid.current.getInstance().api;
                      console.log(api.getSelectedRows());
                    }

                    // api.getSelectedNodes()//: Returns an array of the selected nodes.
                    //  api.getSelectedRows()//: Returns an array of data from the selected rows.
                  }}
                />
              </>
            ) : null}
          </Row>
        </Container>
        <IFXAccordion
          title="Flight Selection Criteria Search Results"
          isOpen={true}
          isAccordion={true}
          contentClassName="p-0"
        >
          <>
            <div id="myDatatable">
              {demoOptions.mode === "Server Side" ? (
                <>
                  <IFXDataTable
                    id="demoServerGrid"
                    key="serverGrid"
                    ref={refServerGrid}
                    wrapperProps={{ style: { height: datatableHeight } }}
                    columnDefs={serverSideColumnDefs}
                    tableControlActions={[
                      DT_DEFAULT_CONTROL_ACTIONS.delete,
                      DT_DEFAULT_CONTROL_ACTIONS.export,
                      DT_DEFAULT_CONTROL_ACTIONS.reload,
                    ]}
                    datasource={datasource}
                    rowModelType={DT_MODEL_TYPE.infinite}
                    tableOptionsProps={{
                      suppressHideColumn: ["iconControl", "action"],
                    }}
                    headerHeight={48}
                    headerWrapped={true}
                  />
                </>
              ) : (
                <>
                  <IFXDataTable
                    id="demoClientGrid"
                    key="clientGrid"
                    pagination={true}
                    paginationPageSize={10}
                    onPageChange={onPageChange}
                    enablePageSizeSelection
                    cacheBlockSize={10}
                    ref={refClientGrid}
                    wrapperProps={{ style: { height: datatableHeight } }}
                    columnDefs={columnDefs}
                    tableControlActions={[
                      DT_DEFAULT_CONTROL_ACTIONS.clearfilter,
                      {
                        ...DT_DEFAULT_CONTROL_ACTIONS.add,
                        params: {
                          index: 0,
                          newRowData: { carrier: {}, modified: true },
                        },
                        onClick: (e, gridRef, { count, index, newRowData }) => {
                          // console.log(DemoClientDTActions);
                          let _newItems = createNewRowData(
                            gridRef,
                            count,
                            newRowData
                          );
                          //console.log("_newItems", _newItems);
                          dispatch(
                            DemoClientDTActions.ADD_ROW({
                              add: _newItems,
                              addIndex: index,
                            })
                          );
                        },
                      },
                      DT_DEFAULT_CONTROL_ACTIONS.delete,
                      DT_DEFAULT_CONTROL_ACTIONS.clear,
                      DT_DEFAULT_CONTROL_ACTIONS.export,
                      DT_DEFAULT_CONTROL_ACTIONS.reset,
                      DT_DEFAULT_CONTROL_ACTIONS.reload,
                      DT_DEFAULT_CONTROL_ACTIONS.save,
                    ]}
                    rowData={dtState.rowData}
                    tableOptionsProps={{
                      suppressHideColumn: ["iconControl", "action"],
                    }}
                    /* deltaRowDataMode={true} */
                    onCellValueChanged={onCellValueChanged}
                    treeColumnEnabled={true}
                  />
                </>
              )}
            </div>
          </>
        </IFXAccordion>
      </Container>
    </>
  );
};
