export const STORE_DETAILED_INV_SELECTION_ACTION = "DETAILED_INV_SELECTION";
export const INVOICE_STATUS_ACTION = "INVOICE_STATUS";
export const STORE_INVOICE_STATUS_ACTION = ["PUT_INVOICE_STATUS"];
export const STORE_STATION_INV_SELECTION_ACTION = "STATION_INV_SELECTION";
export const STORE_INV_PENDING_REV_AND_BROWSE_INV_SELECTION_ACTION =
  "INV_PENDING_REV_AND_BROWSE_INV_SELECTION";
export const STORE_SEARCH_INV_SELECTION_ACTION = "SEARCH_INV_SELECTION";
export const STORE_RECENT_INV_SELECTION_ACTION = "RECENT_INV_SELECTION";
export const STORE_RECURRING_TEMPLATE_SELECTION_ACTION =
  "RECURRING_TEMPLATE_SELECTION";
export const STORE_SEARCH_RULES_SELECTION_ACTION = "SEARCH_RULES_SELECTION";
export const STORE_SEARCH_INVOICING_PERIOD_ACTION =
  "SEARCH_INVOICING_PERIOD_SELECTION";
export const STORE_SEARCH_RAP_REQUEST_ACTION = "SEARCH_RAP_SELECTION";
export const STORE_SEARCH_REMITTANCE_ACTION = "SEARCH_REMITTANCE_SELECTION";
export const STORE_SEARCH_ERRORCODES_ACTION = "SEARCH_ERRORCODE_SELECTION";
