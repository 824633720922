import initialState from "./initialState";

export function SearchRuleReducer(state = null, action) {
  switch (action.type) {
    case "ESS_SEARCH_RULE_REQUEST":
      return action.payload;

    default:
      return state;
  }
}
