export const atleastOneQty =
  "Please enter at least one of the Quantity details like Quantity or Item:Pax Ratio or Boarding% or LoadScale or Rotation Type";
export const bothRotation =
  "Please enter both Rotation Type and Rotation Number";
export const onlyOneQty =
  "Please enter only one of Quantity, Item:Pax Ratio, Boarding% or LoadScale.";
export const boardQty =
  "If Board% value is  entered, Portion and Rounding should not be empty";
export const boardPrtnRnd =
  "If Board%, Portion and Rounding values are entered, Load Scale and Item:Pax Ratio should be empty";
export const loadScaleQty =
  "If Load Scale value is entered, Board%, Portion, Item:Pax Ratio must be empty";
export const itemPaxRatio =
  "If Item:Pax Ratio value is entered, Board%, Portion, Load Scale must be empty";
export const itemPaxRatioRnd =
  "If Item:Pax Ratio value is entered, Rounding must be entered";
export const paxFigAndFactor =
  "If Quantity or Board% values are entered, Pax Figure and Pax Factor should be empty";
export const paxFactorFig =
  "Pax Factor is applicable only when Pax Figure is entered";
export const maxRotValid = "Rotation No Exceeds Max Rotation No";
