import React, { useState, useCallback } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { IFXButton } from "../../../components/AppComponents/IFXButton";
import { IFXTextBox } from "../../../components/AppComponents/IFXTextBox";
import { IFXTextArea } from "../../../components/AppComponents/IFXTextArea";
import { copyTextToClipboard } from "../../../utils/utils";

export const CopyToClipBoardCard = () => {
  const [text, setText] = useState("");
  const [textArea, setTextArea] = useState("");
  const onTextBoxChange = useCallback(
    ({ target: { value } }) => setText(value),
    []
  );
  const onTextAreaChange = useCallback(
    ({ target: { value } }) => setTextArea(value),
    []
  );
  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title className="mb-4">Copy To Clip Board </Card.Title>
        <Container>
          <Row>
            <Col sm={5}>
              <IFXTextBox
                label="Type here"
                placeholder="Type and Click Copy"
                onChange={onTextBoxChange}
              />
            </Col>
            <Col sm={2}>
              <IFXButton name="Copy" onClick={copyTextToClipboard(text)} />
            </Col>
            <Col sm={5}>
              <IFXTextArea
                label="Paste here"
                placeholder="Paste from Clipboard"
                onChange={onTextAreaChange}
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};
