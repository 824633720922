import React, { useState } from "react";
import {
  IFXAccordion,
  IFXTimePicker,
  IFXDropdown,
  IFXDateRangePicker,
  IFXTextBox,
  IFXFilter,
  IFXDatePicker,
  IFXFieldWrapper,
} from "../../../components/AppComponents";
import { Form } from "react-bootstrap";

function FilterDemo() {
  const [values, setInputValue] = useState({
    station: "",
    actype: "",
  });

  const statusList = [
    { label: "Initial", value: "INI" },
    { label: "Prelim", value: "PRE" },
    { label: "Final", value: "FIN" },
    { label: "Confirmed", value: "CON" },
    { label: "Closed", value: "CLO" },
    { label: "Completed", value: "COM" },
    { label: "Cancelled", value: "CAN" },
  ];

  const catererList = [
    { label: "GGI -  GATE GOURMET INC", value: 2005 },
    { label: "LSG - LSG SKY CHEFS INC", value: 2006 },
    { label: "GG - Gate Gourmet", value: 2008 },
  ];
  const [startDate, setStartDate] = useState({});
  const [status, setStatus] = useState({
    statusList: [],
  });
  const [caterer, setCaterer] = useState({
    catererList: [],
  });
  const [fltTime, setFltTime] = useState(null);
  const [fltDate, setFltDate] = useState(null);

  const data = {
    formFields: [
      {
        component: (
          <IFXFieldWrapper
            label="Station"
            controlId="station"
            alwaysFloat={(() =>
              values.station != null && values.station.length > 0)()}
          >
            <IFXTextBox
              value={values.station}
              onChange={e => {
                const {
                  target: { value: v },
                } = e;
                setInputValue({ ...values, station: v });
              }}
              maxLength={15}
            ></IFXTextBox>
          </IFXFieldWrapper>
        ),
        required: "true",
        colWidth: "col-xl-3 col-lg-4 col-md-6 col-sm-6",
      },
      {
        component: (
          <IFXFieldWrapper
            label="A/C Type"
            controlId="actype"
            alwaysFloat={(() =>
              values.actype != null && values.actype.length > 0)()}
          >
            <IFXTextBox
              value={values.actype}
              onChange={e => {
                const {
                  target: { value: v },
                } = e;
                setInputValue({ ...values, actype: v });
              }}
            ></IFXTextBox>
          </IFXFieldWrapper>
        ),
        required: "true",
        colWidth: "col-xl-3 col-lg-4 col-xm-12 col-md-6 col-sm-6",
      },
      {
        component: (
          <IFXFieldWrapper
            type="range"
            label={["From Date", "To Date"]}
            controlId={["fromDateId", "toDateId"]}
            alwaysFloat={[
              (() => startDate.from != null)(),
              (() => startDate.to != null)(),
            ]}
          >
            <IFXDateRangePicker
              selected={startDate}
              onChange={v => {
                setStartDate(v);
              }}
            />
          </IFXFieldWrapper>
        ),
        required: "true",
        colWidth: "col-xl-3 col-lg-4 col-xm-12 col-md-6 col-sm-6",
      },
      {
        component: (
          <IFXFieldWrapper
            label="Status"
            controlId="statusId"
            type="group"
            alwaysFloat={(() => status.statusList.length > 0)()}
          >
            <IFXDropdown
              options={statusList}
              selected={status.statusList}
              onChange={v => {
                setStatus({ ...status, statusList: v });
              }}
            />
          </IFXFieldWrapper>
        ),
        required: "true",
        colWidth: "col-xl-3 col-lg-4 col-md-6 col-sm-6",
      },
      {
        component: (
          <IFXFieldWrapper
            label="Caterer"
            controlId="catererId"
            type="group"
            alwaysFloat={(() => caterer.catererList.length > 0)()}
          >
            <IFXDropdown
              options={catererList}
              selected={caterer.catererList}
              onChange={v => {
                setCaterer({ ...caterer, catererList: v });
              }}
            />
          </IFXFieldWrapper>
        ),
        required: "true",
        colWidth: "col-xl-3 col-lg-4 col-md-6 col-sm-6",
      },
      {
        component: (
          <IFXFieldWrapper
            type="group"
            label="Flight Date"
            controlId="fltDate"
            alwaysFloat={(() => fltDate != null)()}
          >
            <IFXDatePicker
              selected={fltDate}
              onChange={v => {
                setFltDate(v);
              }}
            />
          </IFXFieldWrapper>
        ),
        required: "true",
        colWidth: "col-xl-3 col-lg-4 col-md-6 col-sm-6",
      },
      {
        component: (
          <IFXFieldWrapper
            type="group"
            label="Flight Time"
            controlId="fltTime"
            alwaysFloat={(() => fltTime != null)()}
          >
            <IFXTimePicker
              selected={fltTime}
              onChange={v => {
                setFltTime(v);
              }}
            />
          </IFXFieldWrapper>
        ),
        required: "true",
        colWidth: "col-xl-3 col-lg-4 col-md-6 col-sm-6",
      },
    ],
    actionFields: [],
  };
  const submitForm = () => {
    alert("Submit clicked");
  };
  const resetForm = () => {
    setInputValue({
      station: "",
      actype: "",
    });
  };
  return (
    <div>
      <IFXAccordion
        title="Flight Selection Criteria"
        isOpen={true}
        enabled={true}
        isAccordion={true}
      >
        <Form>
          <IFXFilter
            title=""
            colWidth="col-xl-3 col-lg-4 col-md-6 col-sm-6"
            data={data}
            resetCallBack={resetForm}
            submitCallBack={submitForm}
          />
        </Form>
      </IFXAccordion>
    </div>
  );
}

export { FilterDemo };
