import React, { useState, useEffect, useRef } from "react";
import { Button, Container, Row, Col, Fade, Form } from "react-bootstrap";
import Loader from "react-loaders";
import cx from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import { timer } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, switchMap, map } from "rxjs/operators";
import {
  IFXModal,
  IFXModalHeader,
  IFXModalBody,
  IFXIcons,
  IFXTooltip,
  ColorOptions,
  IconStyleOptions,
  IFXButton,
  ButtonMode,
  useObservableRedux,
  IFXGridRowActionIconButton,
} from "@ifx-react/ifx-react-core";
import {
  DOWNLOAD_CENTER_POLL_URL,
  DOWNLOAD_CENTER_DOWNLOAD_FILE_URL,
  DOWNLOAD_CENTER_URL,
} from "../../const/common/endpoints";
import { AJAX_HEADER_APP_DEFAULT } from "../../const/common/ajaxConsts";
import { TIMER_CONSTS } from "../../const/common/timerConsts";
import { useDispatch } from "react-redux";
import { DocumentCenterActions } from "../../store/actions/common/actionCreators";

const downloadCenterPollRequest = timer(
  0,
  TIMER_CONSTS.DOCUMENT_CENTER_INTERVAL
).pipe(
  switchMap(() =>
    ajax
      .post(
        DOWNLOAD_CENTER_POLL_URL,
        {},
        {
          ...AJAX_HEADER_APP_DEFAULT,
        }
      )
      .pipe(
        map(({ response }) => {
          //console.log(response);
          return response;
        }),
        catchError(error => {
          console.error(error);
          return [];
        })
      )
  )
);

const documentCenterDeleteRequest = requestId => {
  return ajax
    .post(
      DOWNLOAD_CENTER_URL,
      { deleteRequests: requestId },
      {
        ...AJAX_HEADER_APP_DEFAULT,
      }
    )
    .pipe(
      switchMap(({ response }) => {
        //console.log(response);
        if (response && response.length > 0) {
          let { result } = response[0];
          return result === "success" ? [true] : [false];
        }
        return [false];
      }),
      catchError(error => {
        console.error(error);
        return [false];
      })
    );
};

const DownloadCenterItem = ({
  item,
  requestName,
  fileColor,
  fileIcon,
  openFile,
  deleteReportItem,
}) => {
  const [doDeleted, setDoDeleted] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    let sub;
    if (doDeleted) {
      sub = documentCenterDeleteRequest(item.requestId).subscribe(val => {
        setIsDeleted(val);
      });
    }
    return () => sub && sub.unsubscribe();
  }, [doDeleted, setIsDeleted, item.requestId]);

  return (
    <>
      <Fade
        in={!isDeleted}
        onExited={() => {
          deleteReportItem(item);
        }}
      >
        <Row>
          <Col className="file-link">
            {item.creationStatus === 1 ? (
              <IFXButton
                type="link"
                onClick={() => {
                  openFile(item);
                }}
              >
                <span>
                  <IFXIcons
                    className="mr-2 opacity-8"
                    icon={fileIcon}
                    color={fileColor}
                  />
                  {requestName}
                </span>
              </IFXButton>
            ) : (
              <>
                <span className="table-icon">
                  <IFXIcons
                    className="mr-2 opacity-8"
                    icon={fileIcon}
                    color={fileColor}
                  />
                  {requestName}
                </span>
              </>
            )}
          </Col>
          <Col sm="3">
            <span className="table-text">{item.reportType}</span>
          </Col>
          <Col sm="3">
            <span className="table-text">{item.reportRequestedDate}</span>
          </Col>
          <Col sm="1" className="text-center">
            <div className="table-button">
              {item.creationStatus === 0 ? (
                <>
                  <IFXTooltip placement="left" content={<>Processing</>}>
                    <div className="document-center-processing">
                      <Loader
                        className="ifx-processing-icon"
                        type="ball-clip-rotate-multiple"
                        active
                      />
                    </div>
                  </IFXTooltip>
                </>
              ) : item.creationStatus === 1 ? (
                <IFXGridRowActionIconButton
                  type="delete"
                  onClick={() => {
                    setDoDeleted(true);
                  }}
                />
              ) : (
                <>
                  <IFXTooltip
                    placement="left"
                    content={<>Failed to generate report</>}
                  >
                    <IFXIcons
                      borderThin
                      border
                      borderColor={ColorOptions.grey}
                      backgroundColor={ColorOptions.yellow}
                      iconStyle={IconStyleOptions.ROUNDED_SQUARE}
                      className="opacity-8"
                      icon="exclamation"
                    />
                  </IFXTooltip>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Fade>
    </>
  );
};

const DownloadCenterList = ({ items, openFile, deleteReportItem }) => {
  if (items && items.length > 0) {
    return items.map((item, i) => {
      let fileIcon = "file-pdf";
      let fileColor = "#C80000";
      if (item.contentType === "csv") {
        fileIcon = "file-csv";
        fileColor = "#266999";
      } else if (item.contentType === "xlsx" || item.contentType === "xls") {
        fileIcon = "file-excel";
        fileColor = "#81BD00";
      }
      let requestName =
        item.requestName +
        (item.recordCount && item.recordCount > 0
          ? ` (${item.recordCount})`
          : "");
      return (
        <DownloadCenterItem
          key={item.requestId}
          item={item}
          requestName={requestName}
          fileColor={fileColor}
          fileIcon={fileIcon}
          openFile={openFile}
          deleteReportItem={deleteReportItem}
        />
      );
    });
  }

  return (
    <Row key="noFiles">
      <Col>No Files in Download Center</Col>
    </Row>
  );
};

export const DocumentCenter = props => {
  const [isOpen, setOpen] = useState(false);
  const [createdCount, setCreatedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const formRef = useRef();
  const docCenterScrollBarRef = useRef();

  const result = useObservableRedux(
    downloadCenterPollRequest,
    DocumentCenterActions.PUT_POLL_RESULT,
    state => state.CommonReducer.DocumentCenterReducer.result
  );

  const [reportDownloadForm, setReportDownloadForm] = useState({
    requestId: "",
    requestName: "",
  });

  useEffect(() => {
    //console.log(downloadCenterResult);
    if (result && result.length > 0) {
      setTotalCount(result.length);
      setCreatedCount(result.filter(i => i.creationStatus === 1).length);
    } else {
      setTotalCount(0);
      setCreatedCount(0);
    }
  }, [result]);

  const openDownloadCenter = () => {
    setOpen(true);
  };

  const closeDownloadCenter = () => {
    setOpen(false);
  };

  const openFile = item => {
    //console.log("openFile");
    if (item) {
      //console.log(item);
      let { requestId, requestName } = item;
      try {
        setReportDownloadForm({ requestId, requestName });
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (
      reportDownloadForm.requestId !== "" &&
      reportDownloadForm.requestName !== ""
    ) {
      //console.log("submitting form");
      //console.log(formRef.current);
      try {
        formRef.current.submit();
      } catch (e) {
        console.error(e);
      }
    }
  }, [reportDownloadForm]);

  const deleteReportItem = item => {
    //console.log("deleteReportItem");
    //console.log(item);
    dispatch(
      DocumentCenterActions.PUT_POLL_RESULT(
        result.filter(i => i.requestId !== item.requestId)
      )
    );
  };

  return (
    <>
      <div className="download-center-wrapper">
        <div className="header-icons-wrapper">
          <IFXTooltip placement="top" content="Download Center">
            <Button variant="transparent" onClick={openDownloadCenter}>
              <div className="header-icon header-download-center-icon" />
            </Button>
          </IFXTooltip>
          <div
            className={cx("download-center-count", {
              "d-none": totalCount <= 0,
            })}
          >
            <span>
              {createdCount}/{totalCount}
            </span>
          </div>
          {isOpen && (
            <IFXModal
              backdrop
              show={isOpen}
              onHide={closeDownloadCenter}
              onShow={() => {
                docCenterScrollBarRef.current.updateScroll();
              }}
              size="lg"
              dialogClassName="document-center-modal"
            >
              <IFXModalHeader>File Downloads</IFXModalHeader>
              <IFXModalBody
                disableBackground
                className="download-center-content"
              >
                <Container className="table-header">
                  <Row>
                    <Col>
                      <span className="table-text">File Name</span>
                    </Col>
                    <Col sm="3">
                      <span className="table-text">Report Type</span>
                    </Col>
                    <Col sm="3">
                      <span className="table-text">Created Date</span>
                    </Col>
                    <Col sm="1" className="pl-0">
                      <span className="table-text">Status</span>
                    </Col>
                  </Row>
                </Container>
                <PerfectScrollbar ref={docCenterScrollBarRef}>
                  <Container className="table-body" style={{ maxHeight: 200 }}>
                    <DownloadCenterList
                      items={result}
                      openFile={openFile}
                      deleteReportItem={deleteReportItem}
                    />
                  </Container>
                </PerfectScrollbar>
              </IFXModalBody>
            </IFXModal>
          )}
        </div>
        <form
          ref={formRef}
          action={DOWNLOAD_CENTER_DOWNLOAD_FILE_URL}
          method="post"
          target="_blank"
          style={{ display: "none" }}
        >
          <Form.Control
            name="reportIdParam"
            type="hidden"
            value={reportDownloadForm.requestId}
          />
          <Form.Control
            name="reportNameParam"
            type="hidden"
            value={reportDownloadForm.requestName}
          />
        </form>
      </div>
    </>
  );
};
