import initialState from "./initialState";

export function UserPriceGroupsReducer(
  state = initialState.UserPriceGroupsReducer,
  action
) {
  switch (action.type) {
    case "USER_PRICEGROUPS_PUT_USER_PRICEGROUPS":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
