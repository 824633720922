import React, { memo, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { isEmpty } from "ramda";

export const IFXPromptIfDirty = memo(
  ({
    formContext = useFormContext,
    message = "Are you sure you want to leave? You have unsaved changes.",
  }) => {
    const {
      formState: { dirty, submitCount, dirtyFields, touched },
    } = formContext() || {};
    //------------------------------
    // Intercept window close
    //------------------------------
    useEffect(() => {
      window.onbeforeunload = dirty && (() => message);
      return () => (window.onbeforeunload = null);
    }, [dirty]);
    //------------------------------
    // Show prompt on Navigation
    //------------------------------
    return (
      <Prompt
        when={(!isEmpty(touched) && !submitCount) || (dirty && !submitCount)}
        message={message}
      />
    );
  }
);
