import React from "react";
import { Route } from "react-router-dom";

import { AccessDenied } from "../../views/common/AccessDenied";
import { IFXNavWrapper } from "./IFXBreadcrumb/IFXNavWrapper";
import { Authorize } from "./security/Authorize";

export const IFXRoute = ({ children, authorize, ...props }) => (
  <>
    {authorize ? (
      <Authorize
        authorize={authorize}
        notAuthorized={
          <IFXNavWrapper
            item={{ title: "Access Denied" }}
            uniqueId="AccessDenied"
          >
            <AccessDenied {...props} />
          </IFXNavWrapper>
        }
      >
        <Route {...props}>{children}</Route>
      </Authorize>
    ) : (
      <Route {...props}>{children}</Route>
    )}
  </>
);
