import React, { useContext, useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  IFXAccordion,
  IFXFormLayout,
  IFXFieldWrapper,
  CoreValidationRuleCodes,
  IFXDropdown,
  ifxJsonAjax,
  useObservableCallback,
  isEmptyOrNull,
} from "@ifx-react/ifx-react-core";
import { Subject } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { UserRegistrationContext } from "./UserRegistration";
import { UserRegistrationConsts } from "./userRegistrationConsts";

const timezoneDetails$ = new Subject().pipe(
  mergeMap(params =>
    ifxJsonAjax
      .get(
        `/ifx/api/portal/user/getTimeZones.do?countryId=${params.country.value}`,
        params
      )
      .pipe(
        map(xhrResponse => {
          return {
            response: xhrResponse.response,
            country: params.country,
            onChangeCountry: params.onChangeCountry,
          };
        }),
        catchError(error => {
          return error;
        })
      )
  )
);

export const UserPrefForm = props => {
  const { setValue } = useFormContext();
  // const [languageList, setLanguageList] = useState([]);
  // const [countryList, setCountryList] = useState([]);
  // const [timezoneList, setTimezoneList] = useState([]);
  // const [dateFormatList, setDateFormatList] = useState([]);
  // const [timeFormatList, setTimeFormatList] = useState([]);

  const { defaultOptions, userData, editedBy, toggleAcc } = useContext(
    UserRegistrationContext
  );

  const [timezoneList, setTimezoneList] = useState(userData.timeZoneList || []);

  // useEffect(() => {
  //   const data = defaultOptions
  //     ? defaultOptions.languageList.map(element => ({
  //         ...element,
  //         label: element.displaylanguage,
  //         value: element.id,
  //       }))
  //     : [];
  //   // const language = data.find(item => item.id === userData.language);
  //   // !!editedBy && language
  //   //   ? setValue("language", language)
  //   //   : setValue("language", data[0]);
  //   setLanguageList(data);
  // }, [UserRegistrationConsts.LANG_LIST_CONST]);

  // useEffect(() => {
  //   const data = defaultOptions
  //     ? defaultOptions.countryVOList.map(element => ({
  //         ...element,
  //         label: element.countryName,
  //         value: element.id,
  //       }))
  //     : [];
  //   if (!!editedBy) {
  //     const country = data.find(item => item.id === userData.country);
  //     country && setValue("country", country);
  //   }
  //   setCountryList(data);
  // }, [UserRegistrationConsts.COUNTRY_LIST_CONST]);

  // useEffect(() => {
  //   if (!!editedBy && !isEmptyOrNull(props.countryList)) {
  //     const country = props.countryList.find(
  //       item => item.id === userData.country
  //     );
  //     country && getTimezoneList(country);
  //   }
  // }, [props.countryList]);

  // useEffect(() => {
  //   const data = defaultOptions
  //     ? defaultOptions.shortDateList.map(element => ({
  //         ...element,
  //         label: element.dateFormatPattern,
  //         value: element.id,
  //       }))
  //     : [];
  //   const dateFormat = data.find(
  //     item => item.id === userData.shortDateFormateId
  //   );
  //   !!editedBy && dateFormat
  //     ? setValue("dateFormat", dateFormat)
  //     : setValue("dateFormat", data[0]);
  //   setDateFormatList(data);
  // }, [UserRegistrationConsts.DATE_FORMAT_LIST_CONST]);

  // useEffect(() => {
  //   const data = defaultOptions
  //     ? defaultOptions.timeFormatList.map(element => ({
  //         ...element,
  //         label: element.dateFormatPattern,
  //         value: element.id,
  //       }))
  //     : [];
  //   const timeFormat = data.find(item => item.id === userData.timeFormateId);
  //   !!editedBy && timeFormat
  //     ? setValue("timeFormat", timeFormat)
  //     : data.length && setValue("timeFormat", data[0]);
  //   setTimeFormatList(data);
  // }, [UserRegistrationConsts.TIME_FORMAT_LIST_CONST]);

  const getTimezoneList = (v, onChangeCountry) => {
    v && timezoneDetails$.next({ country: v, onChangeCountry });
  };

  useObservableCallback(
    timezoneDetails$,
    response => {
      const data = response.response.length ? response.response : [];
      // let timeZone = null;
      // if (!!editedBy && !response.onChangeCountry) {
      //   timeZone = data.find(item => item.value == userData.timeZoneId);
      // } else {
      //   timeZone = data[0];
      // }
      data.length && setValue("timeZone", data[0], { shouldValidate: true });
      setTimezoneList(data);
    },
    error => {
      console.error("Error in Company Information", error);
    }
  );

  return (
    <>
      <IFXAccordion
        title="User Preferences"
        isOpen={
          toggleAcc == "initial" ? !editedBy || editedBy === "self" : toggleAcc
        }
      >
        <IFXFormLayout
          allFieldsColProps={{ xl: 3, lg: 3, md: 3, sm: 6, xs: 12 }}
        >
          <IFXFieldWrapper
            label="Language"
            controlId="language"
            name="language"
            rules={[CoreValidationRuleCodes.required]}
            type="dropdown"
          >
            <IFXDropdown options={props.languageList} />
          </IFXFieldWrapper>
          <IFXFieldWrapper
            label="Country"
            controlId="country"
            name="country"
            rules={[CoreValidationRuleCodes.required]}
            type="dropdown"
            onChange={v => {
              getTimezoneList(v, true);
            }}
          >
            <IFXDropdown options={props.countryList} />
          </IFXFieldWrapper>
          <IFXFieldWrapper
            label="Time Zone"
            controlId="timeZone"
            name="timeZone"
            rules={[CoreValidationRuleCodes.required]}
            type="dropdown"
          >
            <IFXDropdown
              options={timezoneList}
              disabled={(() => timezoneList && timezoneList.length === 1)()}
            />
          </IFXFieldWrapper>
          <IFXFieldWrapper
            label="Date Format"
            controlId="dateFormat"
            name="dateFormat"
            rules={[CoreValidationRuleCodes.required]}
            type="dropdown"
          >
            <IFXDropdown options={props.dateFormatList} />
          </IFXFieldWrapper>
          <IFXFieldWrapper
            label="Time Format"
            controlId="timeFormat"
            name="timeFormat"
            rules={[CoreValidationRuleCodes.required]}
            type="dropdown"
          >
            <IFXDropdown options={props.timeFormatList} />
          </IFXFieldWrapper>
        </IFXFormLayout>
      </IFXAccordion>
    </>
  );
};
