import React, { forwardRef } from "react";

export const IFXFloatingHeader = forwardRef(({ children }, ref) => (
  <div
    ref={ref}
    className="ifx-floating-header mb-2 px-2 pb-2 u-boxShadow-bottom-light rounded-bottom floating-header u-position--sticky u-position--top-0 u-zIndex-medium"
  >
    {children}
  </div>
));

export const IFXPageBody = forwardRef(({ children }, ref) => (
  <div ref={ref} className="px-1">
    {children}
  </div>
));

export const IFXFloatingFooter = forwardRef(({ children }, ref) => (
  <div
    ref={ref}
    className="ifx-floating-footer px-2 pb-2 u-boxShadow-top-light rounded-top floating-footer u-position--sticky u-position--bottom-0 u-zIndex-medium"
  >
    {children}
  </div>
));
