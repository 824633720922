import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import { IFXDatePicker } from "../../..";
import { omit } from "../../../../../..";
import { CalenderContainer } from "../../../IFXDatePicker";

export const IFXDatePickerCol = forwardRef((props, ref) => {
  const {
    colDef: { valueSetter },
    onChangeCallback,
    data,
  } = props;
  //console.log(props);
  const innerRef = useRef();
  const [selected, setSelected] = useState(props.value);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return selected;
      },
    };
  });

  const onChange = v => {
    setSelected(v);
    valueSetter({ data, newValue: v });
    if (onChangeCallback) {
      onChangeCallback(v, props);
    }
  };

  useEffect(() => {
    window.setTimeout(() => {
      try {
        //console.log(innerRef);
        innerRef.current.setFocus();
      } catch (e) {}
    }, 100);
  }, []);

  const _props = omit(props, [
    "keyPress",
    "charPress",
    "colDef",
    "rowIndex",
    "columnApi",
    "stopEditing",
    "eGridCell",
    "parseValue",
    "formatValue",
    "agGridReact",
    "frameworkComponentWrapper",
    "reactContainer",
    "value",
    "cellStartedEdit",
    "column",
    "node",
    "data",
    "api",
  ]);

  //console.log(selected);
  return (
    <IFXDatePicker
      {...props}
      ref={innerRef}
      selected={selected}
      enablePrepend={false}
      onChange={onChange}
      popperContainer={CalenderContainer}
    />
  );
});
