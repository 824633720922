import React from "react";
import { Col, Row } from "react-bootstrap";
import { IFXButton } from "./IFXButton";

const DEFAULT_PROPS = {
  submitBtnName: "Search",
  clearBtnName: "Clear",
};

/**
 * @deprecated In favor of IFXFormLayout
 */
export const IFXFilter = props => {
  console.warn("IFXFilter is deprecated in favor of IFXFormLayout!");
  const _props = { ...DEFAULT_PROPS, ...props };
  const {
    flag,
    submitCallBack,
    resetCallBack,
    colWidth,
    top,
    padding,
    submitBtnName,
    clearBtnName,
  } = _props;

  return (
    <>
      <div>
        <Row>
          {props.data.formFields.map((eachData, id) => {
            return (
              <div
                className={`${
                  eachData.colWidth && eachData.colWidth === ""
                    ? "col-md-3"
                    : eachData.colWidth
                } ${
                  eachData.padding && eachData.padding !== ""
                    ? eachData.padding
                    : ""
                }`}
                key={id}
              >
                {eachData.component}
              </div>
            );
          })}
          <div
            className={`${
              colWidth && colWidth === "" ? "col-md-3" : colWidth
            } mt-${top}`}
          >
            <div className="mt-4 pull-right d-inline-flex">
              {flag ? null : (
                <div>
                  <IFXButton
                    type="search"
                    name={submitBtnName}
                    onClick={submitCallBack}
                  />
                  <IFXButton
                    type="clear"
                    name={clearBtnName}
                    onClick={resetCallBack}
                  />
                </div>
              )}
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};
