import React, {memo} from "react";
import { IFXRadioButton } from "../../..";

export const IFXRadioRowSelectCol = memo(({
  node,
  data,
  colDef: { field },
  rowIndex,
  onChangeCallBack,
  api,
}) => {
  const _onChange = val => {
    api.forEachNode(({ data: nData }) => {
      nData[field] = false;
    });
    node.setSelected(true, true);
    data[field] = true;
    if (onChangeCallBack) onChangeCallBack(val, data);
  };

  const id = `${field.replace(/\./g, "_")}_${rowIndex}`;

  const defaultChecked = data[field];
  if (defaultChecked) {
    node.setSelected(true, true);
  }

  return (
    <IFXRadioButton
      label=""
      defaultChecked={defaultChecked}
      name={field}
      id={id}
      onChange={_onChange}
    />
  );
});
