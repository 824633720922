import React, { useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IFXModal,
  IFXModalBody,
  IFXModalHeader,
  IFXModalFooter,
  IFXButton,
  IFXAutoSuggest,
  IFXFormValidation,
  ifxJsonAjax,
  IFXFieldWrapper,
  CoreValidationRuleCodes,
  useObservableCallback,
  useToast,
  IFXButtonGroup,
  IFXModalAlertFooter,
  isEmptyOrNull,
} from "@ifx-react/ifx-react-core";
import { Subject } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { useFormContext } from "react-hook-form";
import { IFXUserDatePicker } from "../../../components/common/DatePickers";
import { subDays, format, addYears } from "date-fns";
import { UserRegistrationContext } from "./UserRegistration";
import { CREATE_CREDENTIAL_SET } from "../../../const/portal/endpoints";
import { LayoutActions } from "../../../store/actions/common/actionCreators";
import { CommonToastConsts } from "../../../const/common/toastConsts";

const getAddCredentialObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax.post(CREATE_CREDENTIAL_SET, params).pipe(
        map(xhrResponse => xhrResponse),
        catchError(error => {
          console.error("Error in Add Credential Set", error);
          errorHandler(error);
          return [];
        })
      )
    )
  );

export const AddCredentialSetModal = ({
  onHide,
  show,
  addCredential,
  reloadGrid,
  credentialSet,
}) => {
  const { accessProfileId, effDate, expDate } = addCredential;

  const [errorMsg, setErrorMsg] = useState("");

  const dispatch = useDispatch();

  const { ifxErrorToast } = useToast();

  const { userData } = useContext(UserRegistrationContext);

  const defaultValues = {
    effDate: {
      dateValue: new Date(),
      serialized: format(new Date(), "MM/dd/yyyy"),
    },
    expDate: {
      dateValue: new Date(expDate),
      serialized: format(new Date(expDate), "MM/dd/yyyy"),
    },
  };

  const addCredentialSet$ = useMemo(() => {
    return getAddCredentialObj$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        ifxErrorToast(CommonToastConsts.ERROR);
        onHide();
      },
    });
  }, []);

  const addCredentialSet = formData => {
    console.log(formData);
    dispatch(
      LayoutActions.SET_LOADER_SHOW({
        loaderText: "Loading...",
      })
    );
    const alreadyExistData = formData.credentialSet.find(item => {
      return credentialSet.find(profile => {
        if (
          profile.credntlSetId == item.id &&
          profile.expDateLong >
            new Date(formData.effDate.dateValue).setHours(0, 0, 0, 0)
        ) {
          return true;
        }
      });
    });
    if (!isEmptyOrNull(alreadyExistData)) {
      setErrorMsg("This Credential overlaps with same CredentialSet.");
      dispatch(LayoutActions.SET_LOADER_HIDE());
      return;
    }
    addCredentialSet$.next({
      id: addCredential.id,
      userId: userData.userId,
      userName: userData.userName,
      credentialSetsList: formData.credentialSet,
      effDate: formData.effDate.serialized || null,
      expDate: formData.expDate.serialized || null,
    });
  };

  useObservableCallback(
    addCredentialSet$,
    response => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      onHide();
      reloadGrid();
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      onHide();
    }
  );

  return (
    <>
      <IFXModal show={show} onHide={onHide} size="md">
        <IFXModalHeader>Add Credential</IFXModalHeader>
        <IFXFormValidation
          onSubmit={addCredentialSet}
          defaultValues={defaultValues}
        >
          <IFXModalBody>
            <AddCredentialForm
              userData={userData}
              accessProfileId={accessProfileId}
            />
          </IFXModalBody>
          <IFXModalFooter>
            <IFXModalAlertFooter message={errorMsg} errorColProps={{ xs: 8 }}>
              <IFXButtonGroup>
                <IFXButton type="save" />
                <IFXButton type="cancel" name="Cancel" onClick={onHide} />
              </IFXButtonGroup>
            </IFXModalAlertFooter>
          </IFXModalFooter>
        </IFXFormValidation>
      </IFXModal>
    </>
  );
};

const AddCredentialForm = ({ userData, accessProfileId }) => {
  const { watch } = useFormContext();
  const effDate = watch("effDate");
  const expDate = watch("expDate");

  return (
    <>
      <IFXFieldWrapper
        label="Credential Name"
        controlId="credentialSet"
        name="credentialSet"
        rules={[CoreValidationRuleCodes.required]}
        multiple
      >
        <IFXAutoSuggest
          serviceName="autoSuggestController"
          fieldType="addCredentialSet"
          params={{
            userId: userData.userId,
            accProfileId: accessProfileId || "",
          }}
        />
      </IFXFieldWrapper>
      <IFXFieldWrapper
        type="datepicker"
        label="Eff date"
        controlId="effDate"
        name="effDate"
        rules={[CoreValidationRuleCodes.required]}
      >
        <IFXUserDatePicker
          minDate={subDays(new Date(), 0)}
          maxDate={
            expDate && expDate.dateValue && subDays(expDate.dateValue, 0)
          }
        />
      </IFXFieldWrapper>
      <IFXFieldWrapper
        type="datepicker"
        label="End date"
        controlId="expDate"
        name="expDate"
        rules={[CoreValidationRuleCodes.required]}
      >
        <IFXUserDatePicker
          minDate={
            effDate && effDate.dateValue
              ? subDays(effDate.dateValue, 0)
              : subDays(new Date(), 0)
          }
        />
      </IFXFieldWrapper>
    </>
  );
};
