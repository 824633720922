import { mergeMap, switchMap, catchError, tap } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import { ajax } from "rxjs/ajax";
import * as Observable from "rxjs";
import { LOGIN_URL, LOGOUT_URL } from "../../../../const/portal/endpoints";
import {
  LoginActions,
  LogoutActions,
  ResetReduxActions,
} from "../../../actions/portal/actionCreators";
import { LayoutActions } from "../../../actions/common/actionCreators";
import { AJAX_HEADER_APP_DEFAULT } from "../../../../const/common/ajaxConsts";

const loginEpic = (action$, state$, { dispatch }) =>
  action$.pipe(
    ofType(LoginActions.REQUEST().type),
    tap(() => {
      dispatch(
        LayoutActions.SET_LOADER_SHOW({
          loaderText: "Logging In",
          //loaderOpaque: true,
        })
      );
    }),
    switchMap(({ payload: { username, password } }) =>
      ajax
        .post(
          LOGIN_URL,
          {
            j_username: username,
            j_password: password,
          },
          AJAX_HEADER_APP_DEFAULT
        )
        .pipe(
          //tap(val => console.log(`BEFORE MAP2: ${JSON.stringify(val)}`)),
          mergeMap(xhrResponse =>
            Observable.of(
              LayoutActions.SET_LOADER_HIDE(),
              LoginActions.SUCCESS(xhrResponse.response)
            )
          ),
          catchError(error => {
            let { message, response, status } = error;
            let payload = { message, response, status };
            return Observable.of(
              LoginActions.FAILURE(payload),
              LayoutActions.SET_LOADER_HIDE()
            );
          })
        )
    )
  );

const logoutEpic = (action$, state$, { dispatch }) =>
  action$.pipe(
    ofType(LogoutActions.REQUEST().type),
    tap(() => {
      dispatch(LayoutActions.SET_LOADER_SHOW("Logging Out"));
    }),
    switchMap(val =>
      ajax.post(LOGOUT_URL, {}, AJAX_HEADER_APP_DEFAULT).pipe(
        mergeMap(({ response }) =>
          Observable.of(
            LogoutActions.SUCCESS(response),
            ResetReduxActions.RESET_REDUX(),
            LayoutActions.SET_LOADER_HIDE()
          )
        ),
        catchError(error => {
          console.error(error);
          return Observable.of(
            LogoutActions.FAILURE(),
            ResetReduxActions.RESET_REDUX(),
            LayoutActions.SET_LOADER_HIDE()
          );
        })
      )
    )
  );

export const authEpic = combineEpics(loginEpic, logoutEpic);
