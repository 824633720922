import React, { useCallback, useState, useEffect } from "react";
import cx from "classnames";
import Loader from "react-loaders";
import { useSelector, useDispatch } from "react-redux";
import { IFXPageHeaderIconButton } from "@ifx-react/ifx-react-core";
import { AbortActions } from "../../store/actions/common/actionCreators";

export const IFXLoader = ({
  message,
  enable,
  globalScope = false,
  loaderOpaque = false,
  scope = null,
}) => {
  const dispatch = useDispatch();
  const [showAbort, setShowAbort] = useState(false);
  const {
    loaderText = "Loading",
    loaderEnable,
    loaderScoped,
    cancellable = false,
    callAbort = false,
  } = useSelector(state => state.CommonReducer.LayoutReducer);

  message = message ? message : globalScope ? loaderText : "Loading";
  enable = enable ? enable : globalScope && loaderEnable ? loaderEnable : false;

  const { message: _message = "Loading", enable: _enable = false } = (scope &&
    loaderScoped?.[scope]) || { message, enable };
  const onAbort = useCallback(() => {
    dispatch(AbortActions.STOP_FETCH_CALL());
  }, []);

  useEffect(() => {
    let abortTimeout;
    if (loaderEnable && cancellable) {
      abortTimeout = setTimeout(() => {
        !showAbort && setShowAbort(true);
      }, 4000);
    } else {
      clearTimeout(abortTimeout);
      showAbort && setShowAbort(false);
    }
    return () => {
      clearTimeout(abortTimeout);
    };
  }, [loaderEnable, cancellable]);
  //console.log("SHow Abort", showAbort);
  return (
    <>
      <div
        className={cx(
          "loader-wrapper justify-content-center align-items-center",
          {
            "d-flex loader-active": _enable,
            "loader-opaque": loaderOpaque,
          }
        )}
      >
        {showAbort && (
          <IFXPageHeaderIconButton
            className="m-1"
            icon="cancel"
            name="Abort"
            shadow={true}
            onClick={onAbort}
          />
        )}

        <div className="loader-inner-wrapper ">
          <Loader type="ball-pulse" active={_enable} />
          <span className="loader-text">{_message}</span>
        </div>
      </div>
    </>
  );
};
