import initialState from "./initialState";

export function CodeValReducer(state = initialState.CodeValReducer, action) {
  switch (action.type) {
    case "CODE_VAL_PUT_CODE_VAL":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
