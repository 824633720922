import initialState from "./initialState";

export function CatererReducer(
  state = initialState.catererFormValues,
  action
) {
  switch (action.type) {
    case "CATERER_FORM_DATA_REQUEST":
      return {
        ...state,
        catererFormValues: action.payload,
      };
    default:
      return state;
  }
}
