import React, { useState } from "react";
import { Card } from "react-bootstrap";
import {
  IFXTextArea,
  IFXFieldWrapper,
} from "../../../components/AppComponents";

export const TextAreaCard = () => {
  const [formState, setFormState] = useState({
    textArea1: "",
    textArea2: "Write an essay about your favorite DOM element.",
  });

  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title className="mb-4">TEXT AREA</Card.Title>
        <IFXFieldWrapper
          label="Description"
          controlId="textArea1"
          alwaysFloat={(() =>
            formState.textArea1 != null && formState.textArea1.length > 0)()}
        >
          <IFXTextArea
            value={formState.textArea1}
            onChange={e => {
              const {
                target: { value: v },
              } = e;
              setFormState({ ...formState, textArea1: v });
            }}
          />
        </IFXFieldWrapper>
        <Card.Title className="mt-4 mb-4">
          TEXT AREA WITH DEFAULT VALUE
        </Card.Title>
        <IFXFieldWrapper
          label="Description"
          controlId="textArea2"
          alwaysFloat={(() =>
            formState.textArea2 != null && formState.textArea2.length > 0)()}
        >
          <IFXTextArea
            maxLength={150}
            value={formState.textArea2}
            onChange={e => {
              const {
                target: { value: v },
              } = e;
              setFormState({ ...formState, textArea2: v });
            }}
          />
        </IFXFieldWrapper>
      </Card.Body>
    </Card>
  );
};
