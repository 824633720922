import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

/**
 * @deprecated In favor of IFXFormValidation
 */
export const IFXFormValidationOld = props => {
  console.warn(
    "IFXFormValidationOld is deprecated in favor of IFXFormValidation!"
  );
  const { render, onSubmit, resetCallBack, formScemaRegister = [] } = props;

  const {
    handleSubmit,
    register,
    errors,
    setValue,
    setError,
    clearError,
  } = useForm();

  const formParams = {
    ifxRegister: register,
    ifxErrors: errors,
    ifxSetValue: setValue,
    ifxSetError: setError,
    ifxClearError: clearError,
  };

  const getFormScema = argumentList => {
    function getRegister(ParaName) {
      return register(
        { name: ParaName },
        {
          required: true,
        }
      );
    }
    return () => {
      argumentList.map(arg => getRegister(arg.key));
    };
  };

  useEffect(getFormScema(formScemaRegister), [register]);
  useEffect(() => {
    formScemaRegister.map(arg =>
      setTimeout(() => setValue(arg.key, arg.value))
    );
  }, [setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>{render(formParams)}</form>
    </>
  );
};
