import React, { useEffect, useMemo, useState, memo } from "react";
import { useDispatch } from "react-redux";
import {
  ifxJsonAjax,
  useToast,
  useObservableCallback,
  IFXPageHeaderIconButton,
} from "@ifx-react/ifx-react-core";
import { Subject } from "rxjs";
import { catchError, mergeMap, map } from "rxjs/operators";
import { useHistory, withRouter } from "react-router-dom";
import { CommonToastConsts } from "../../const/common/toastConsts";
import { LayoutActions } from "../../store/actions/common/actionCreators";
import { LOAD_ALERTS } from "../../const/portal/endpoints";

const loadAlertsAjaxObs$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(() =>
      ifxJsonAjax.get(LOAD_ALERTS).pipe(
        map(xhrResponse => {
          return xhrResponse.response;
        }),
        catchError(error => {
          errorHandler(error);
        })
      )
    )
  );

export const AlertIcon = withRouter(
  memo(({ location: { pathname } }) => {
    const history = useHistory();
    const { ifxConfirmToast, ifxErrorToast } = useToast();
    const dispatch = useDispatch();
    const [alerts, setAlerts] = useState([]);

    const loadAlertNoticationsAjaxObs$ = useMemo(() => {
      return loadAlertsAjaxObs$({
        errorHandler: error => {
          dispatch(LayoutActions.SET_LOADER_HIDE({}));
          if (error && error.response && error.response.message) {
            ifxErrorToast(CommonToastConsts.ERROR);
          }
        },
      });
    }, []);

    useObservableCallback(loadAlertNoticationsAjaxObs$, response => {
      if (response) {
        dispatch(LayoutActions.SET_LOADER_HIDE({}));
        setAlerts(response);
      }
    });

    useEffect(() => {
      if (loadAlertNoticationsAjaxObs$) {
        dispatch(
          LayoutActions.SET_LOADER_SHOW({
            loaderText: "Loading...",
          })
        );
        loadAlertNoticationsAjaxObs$.next();
      }
    }, [loadAlertNoticationsAjaxObs$]);

    const navigateIFXHome = () => {
      if (!pathname.startsWith("/app/portal/myIFX")) {
        const confirmAlert = CommonToastConsts.CONFIRM_ALERT_ICON;
        confirmAlert.onCallback = (() => (e, name) => {
          if (name === "Yes") {
            history.push("/app/portal/myIFX");
          }
        })();
        ifxConfirmToast(confirmAlert);
      }
    };

    return (
      <>
        {alerts.length > 0 && (
          <IFXPageHeaderIconButton
            icon="notice-warning"
            name="Alerts & Notifications"
            onClick={() => {
              navigateIFXHome();
            }}
          />
        )}
      </>
    );
  })
);
