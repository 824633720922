import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import { IFXTimePicker } from "../../..";
import { omit } from "../../../../../..";
import { parse } from "date-fns";
import { CalenderContainer } from "../../../IFXDatePicker";

const DEFAULT_PROPS = {
  serializeFormat: "HH:mm",
};

export const IFXTimePickerCol = forwardRef(
  (
    {
      valueType,
      value,
      serializeFormat = DEFAULT_PROPS.serializeFormat,
      onChangeCallBack,
      isClearable = false,
      ...props
    },
    ref
  ) => {
    const innerRef = useRef();
    const [selected, setSelected] = useState(
      value &&
        (valueType === "string"
          ? {
              serialized: value,
              dateValue: parse(value, serializeFormat, new Date()),
            }
          : value)
    );

    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return (
            selected &&
            (valueType === "string" ? selected.serialized : selected)
          );
        },
      };
    });

    const onChange = v => {
      setSelected(v);
      if (onChangeCallBack) onChangeCallBack(v, props);
    };

    useEffect(() => {
      window.setTimeout(() => {
        try {
          //console.log(innerRef);
          innerRef.current.setFocus();
        } catch (e) {}
      }, 100);
    }, []);

    const _props = omit(props, [
      "keyPress",
      "charPress",
      "colDef",
      "rowIndex",
      "columnApi",
      "stopEditing",
      "eGridCell",
      "parseValue",
      "formatValue",
      "agGridReact",
      "frameworkComponentWrapper",
      "reactContainer",
      "value",
      "cellStartedEdit",
      "column",
      "node",
      "data",
      "api",
    ]);

    //console.log(selected);
    return (
      <IFXTimePicker
        {...props}
        serializeFormat={serializeFormat}
        ref={innerRef}
        selected={selected}
        enablePrepend={false}
        onChange={onChange}
        popperContainer={CalenderContainer}
        isClearable={isClearable}
      />
    );
  }
);
