import React, { useRef, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { IFXFile } from "../../../components/AppComponents/IFXFile";
import { IFXButton } from "../../../components/AppComponents/IFXButton";

export const FileCard = () => {
  const fileRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const validateFileSize = uploadedFile => {
    let size = 5000000;
    if (uploadedFile && uploadedFile.size > size) {
      return false;
    }
    return true;
  };

  const onFileUpload = () => {
    console.log("onFileUpload>selectedFile>>", selectedFile);
    if (validateFileSize(selectedFile)) {
      alert(`'${selectedFile.name}'>>>File is uploaded`);
    } else {
      alert(`'${selectedFile.name}'>>>File Size is too large`);
    }
  };

  const onFileChange = () => {
    const uploadedFile = fileRef.current.files[0];
    setSelectedFile(uploadedFile);
  };

  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title className="mb-4">File Upload</Card.Title>
        <Container>
          <Row>
            <Col>
              <IFXFile ref={fileRef} onChange={onFileChange} />
            </Col>
            <Col>
              <IFXButton name="Upload" onClick={onFileUpload} />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};
