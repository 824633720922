import { useSelector } from "react-redux";
import { useMemo } from "react";
import { format, parse } from "date-fns";
import { moment } from "@ifx-react/ifx-react-core";

export const useUserDateFormat = () => {
  const {
    dateFormat: userDateFormat,
    timeFormat: userTimeFormat,
    timeZone: userTimeZone,
  } = useSelector(state => state.PortalReducer.UserContextReducer.userInfo);

  return useMemo(() => {
    //console.log("useUserDateFormat memo", userDateFormat);
    const momentDateFormat = moment().toMomentFormatString(userDateFormat);
    let momentTimeFormat = moment().toMomentFormatString(userTimeFormat);
    momentTimeFormat = momentTimeFormat.replace("aa", "A");
    momentTimeFormat = momentTimeFormat.replaceAll("Z", "z");
    momentTimeFormat = momentTimeFormat.replaceAll("zz", "z");
    const momentDateTimeFormat = `${momentDateFormat} ${momentTimeFormat}`;

    const currentDateTimeForTimeZone = timeZone => {
      return new Date(
        moment()
          .tz(timeZone)
          .format("YYYY/MM/DD HH:mm:ss")
      );
    };

    const toLocaleDateMoment = (
      value,
      { isUTC = true, format = momentDateFormat } = {}
    ) => {
      if (userDateFormat) {
        return (isUTC ? moment.utc(value) : moment(value))
          .tz(userTimeZone)
          .format(format);
      }
    };

    const toLocaleDateTimeMoment = (
      value,
      {
        isUTC = true,
        format = momentDateTimeFormat,
        timeZone = userTimeZone,
      } = {}
    ) => {
      return (isUTC ? moment.utc(value) : moment(value))
        .tz(timeZone)
        .format(format);
    };

    const toUserDateMoment = (value, { format = momentDateFormat } = {}) => {
      if (userDateFormat) {
        return moment(value).format(format);
      }
    };

    const toUserDateTimeMoment = (
      value,
      { isUTC = false, format = momentDateTimeFormat } = {}
    ) => {
      if (userDateFormat && userTimeFormat) {
        return (isUTC ? moment.utc(value) : moment(value)).format(format);
      }
    };

    const toLocaleDate = value => {
      //console.log("toLocaleDate userDateFormat", userDateFormat);
      if (userDateFormat) {
        return format(new Date(value), userDateFormat);
      }
    };

    const toLocalDateTime = value => {
      if (userTimeFormat) {
        const dateStr = toLocaleDate(value);
        const timeStr = format(new Date(value), userTimeFormat);
        return `${dateStr} ${timeStr}`;
      }
    };

    const toUserTime = value => {
      if (userTimeFormat) {
        const timeStr = format(new Date(value), userTimeFormat);
        return timeStr;
      }
    };

    const serializeToLocaleDate = value => {
      if (userDateFormat && value) {
        return format(parse(value, "MM/dd/yyyy", new Date()), userDateFormat);
      }
      return "";
    };

    const localeDateToSerialize = date => {
      if (date) {
        return format(new Date(date), "MM/dd/yyyy");
      }
      return "";
    };

    return {
      toLocaleDate,
      toLocalDateTime,
      toUserTime,
      serializeToLocaleDate,
      localeDateToSerialize,
      currentDateTimeForTimeZone,
      toLocaleDateMoment,
      toLocaleDateTimeMoment,
      toUserDateMoment,
      toUserDateTimeMoment,
      momentDateFormat,
      momentTimeFormat,
      momentDateTimeFormat,
      userTimeFormat,
      userDateFormat,
    };
  }, [userTimeFormat, userDateFormat]);
};
