export const AJAX_ACTIONS = [
  "REQUEST",
  "SUCCESS",
  "FAILURE",
  "CANCEL",
  "RESET",
];

//LAYOUT ACTIONS
export const LAYOUT_ACTION = "LAYOUT";

export const LAYOUT_SUB_ACTIONS = [
  "SIDEBAR_CLOSED_ENABLE",
  "FULLSCREEN_ENABLE",
  "CONTENT_WIDTH",
  "LOADER_SHOW",
  "LOADER_HIDE",
  "LOADER_TEXT",
  "LOADER_SCOPE_SHOW",
  "LOADER_SCOPE_HIDE",
];

export const MODAL_ACTIONS = ["SHOW", "HIDE"];

export const BREADCRUMB_ACTION = "BREADCRUMB";
export const BREADCRUMB_SUB_ACTIONS = [
  "ADD_ITEM",
  "RESET_ADD_ITEM",
  "REMOVE_LAST_ITEM",
  "RESET_TO_ITEM",
];

export const CARRIER_PROPERTIES_ACTION = "CARRIER_PROPERTIES";

export const STORE_CARRIER_PROPERTIES_ACTION = [
  "PUT_CARRIER_PROPERTIES",
  "PUT_CARRIER_PROPERTY",
  "CLEAR_CARRIER_PROPERTIES",
];

export const BUILD_INFO_ACTION = "BUILD_INFO";
export const BUILD_INFO_SUB_ACTIONS = ["PUT_IFX_RESPONSE", "PUT_GP_RESPONSE"];

export const DOCUMENT_CENTER_ACTION = "DOCUMENT_CENTER";
export const DOCUMENT_CENTER_SUB_ACTIONS = ["PUT_POLL_RESULT"];

export const LEGACY_ACTION = "LEGACY";
export const LEGACY_SUB_ACTIONS = ["REDIRECT_LEGACY_URL", "RESET_LEGACY_STATE"];

export const CODE_VAL_ACTION = "CODE_VAL";
export const STORE_CODE_VAL_ACTION = ["PUT_CODE_VAL"];

export const USER_STATIONS_ACTION = "USER_STATIONS";
export const STORE_USER_STATIONS_ACTION = ["PUT_USER_STATIONS"];
export const ABORT = "ABORT";
export const STOP_FETCH_CALL = ["STOP_FETCH_CALL"];

export const HELP_LINK_ACTION = "HELP_LINK";