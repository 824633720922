import React, { forwardRef } from "react";
import { InputGroup, Form } from "react-bootstrap";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import { format, set, parse } from "date-fns";
import cx from "classnames";
import PropTypes from "prop-types";
import { CalenderContainer } from "./IFXDatePicker";

const DEFAULT_PROPS = {
  className: "ifx-time-picker",
  showTimeSelect: true,
  showTimeSelectOnly: true,
  dateFormat: "HH:mm",
  timeFormat: "HH:mm",
  timeIntervals: 15,
  timeCaption: "Time",
  size: "sm",
  serializeFormat: "HH:mm",
  isSerialized: true,
  autoComplete: "off",
  enablePrepend: false,
  isClearable: true,
};
export const IFXTimePicker = forwardRef(({ required, ...props }, ref) => {
  const _props = { ...DEFAULT_PROPS, className: "", ...props };
  const {
    inputGroupClassName,
    selected,
    isSerialized,
    serializeFormat,
    enablePrepend,
  } = _props;

  const onChange = v => {
    if (v != null) {
      v = set(v, { year: 1976, month: 0, date: 1 });

      if (isSerialized)
        v = { serialized: format(v, _props.serializeFormat), dateValue: v };
    }

    if (_props.onChange) _props.onChange(v);
  };

  let _selected = selected;
  if (isSerialized && selected != null) {
    _selected = parse(selected.serialized, serializeFormat, new Date());
    _selected = set(_selected, { year: 1976, month: 0, date: 1 });
  }
  if (enablePrepend) {
    return (
      <>
        <InputGroup
          size={_props.size}
          className={cx(inputGroupClassName, {
            "field-mandatory": required,
          })}
          title="Time"
        >
          <InputGroup.Prepend>
            <div className="input-group-text">
              <FontAwesomeIcon icon={faClock} />
            </div>
          </InputGroup.Prepend>
          <Form.Control
            {..._props}
            className={cx(DEFAULT_PROPS.className, _props.className)}
            as={DatePicker}
            onChange={onChange}
            selected={_selected}
            ref={ref}
            popperContainer={CalenderContainer}
          />
        </InputGroup>
      </>
    );
  } else {
    return (
      <Form.Control
        {..._props}
        className={cx(DEFAULT_PROPS.className, _props.className, {
          "field-mandatory": required,
        })}
        as={DatePicker}
        onChange={onChange}
        selected={_selected}
        ref={ref}
        popperContainer={CalenderContainer}
        isClearable={_props.disabled ? false : _props.isClearable}
      />
    );
  }
});

IFXTimePicker.propTypes = {
  isSerialized: PropTypes.bool,
  serializeFormat: PropTypes.string,
};
