import React, { memo } from "react";

import { IFXVerticalMenu } from "./IFXVerticalMenu";

export const Nav = memo(
  ({
    showRightMenu,
    height,
    setHideOnMouseLeave,
    sideMenuScrollBarRef,
    iconClicked,
    setIconClicked,
  }) => {
    return (
      <>
        <IFXVerticalMenu
          showRightMenu={showRightMenu}
          setHideOnMouseLeave={setHideOnMouseLeave}
          height={height}
          sideMenuScrollBarRef={sideMenuScrollBarRef}
          {...{ iconClicked, setIconClicked }}
        />
      </>
    );
  }
);
