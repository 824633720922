export const FETCH_CARRIER_PROPERTIES_URL =
  "/ifx/api/common/util/fetch/carrierProperties.do";

export const FETCH_CARRIER_PROPERTIES_BY_CARRIER_URL =
  "/ifx/api/common/util/fetch/carrierPropertyByCarriers.do";

export const DOWNLOAD_CENTER_URL = "/ifx/documentCenter.do";

export const DOWNLOAD_CENTER_POLL_URL =
  DOWNLOAD_CENTER_URL + "?requestType=polling";

export const DOWNLOAD_CENTER_DOWNLOAD_FILE_URL =
  "/ifx/documentCenterFileDownload.do";

export const FETCH_CODE_VAL_URL = "/ifx/api/common/util/fetch/codeVal.do";

export const FETCH_USER_STATIONS_URL =
  "/ifx/api/common/util/fetch/userStations.do";

export const GET_BUILD_INFO_URL = "/ifx/api/buildInfo.do";
export const GET_GP_BUILD_INFO_URL = "/gp-react/build-info.json";

export const ESO_SERVICE_CODES_FOR_POPUP_URL =
  "/ifx/api/esm/common/serviceCode/lookup.do";

export const REPORTS_DOWNLOAD_URL = "/ifx/adm/reports/download.do";

export const REPORTS_SEND_TO_DOWNLOAD_CENTER_URL =
  "/ifx/adm/reports/downloadCenter.do";

export const REPORTS_ADD_SCHEDULER_URL = "/ifx/adm/reports/addScheduler.do";

export const GET_HELP_LINK =
  "/ifx/api/common/help/userFunctionalHelp/getAllLinks.do";

export const SAVE_AND_UPDATE_HELP_LINK =
  "/ifx/api/common/help/userFunctionalHelp/save.do";

export const DELETE_HELP_LINK =
  "/ifx/api/common/help/userFunctionalHelp/delete.do";
