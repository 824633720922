import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider as ReduxProvider } from "react-redux";
import configureStore from "./store/configureStore";
import "./assets/styles/ifxAppStyles.scss";
import { withToastProvider } from '@ifx-react/ifx-react-core';

const store = configureStore();
const AppWithToastProvider = withToastProvider(App);

const startupDom = () => {
  return (
    <ReduxProvider store={ store }>
      <Router>
        <AppWithToastProvider />
      </Router>
    </ReduxProvider>
  );
};

ReactDOM.render(startupDom(), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
