import React, { useState, useContext } from "react";
import { Row, Col, Container } from "react-bootstrap";
import {
  IFXAccordion,
  IFXFormLayout,
  IFXFieldWrapper,
  CoreValidationRuleCodes,
  IFXTextBox,
  IFXDropdown,
  IFXUserNote,
  isEmptyOrNull,
} from "@ifx-react/ifx-react-core";
import { UserRegistrationContext } from "./UserRegistration";

// const passwordRequirements = {
//     "hasUseranme": 20,
//     "hasMinChar": 20,
//     "hasOneNumber": 10,
//     "hasOneLetter": 10,
//     "hasNoSpace": 10,
//     "hasNoSpecialChar": 10,
//     "hasRepeatedChars": 10,
//     "hasMaxChar": 10
// }

export const LoginInfoForm = ({ setValueMatchRule, valueMatchRule }) => {
  const [progressValue, setProgressValue] = useState(0);

  // const checkStrength = password => {
  //     let progressVal = 0;
  //     const pattern = /^(?=.*\d)(?=.*[a-zA-Z]).{6,25}$/;
  //     if (password.match(pattern)) {
  //         console.log('1234567890');
  //         setProgressValue(40)
  //     } else {
  //         setProgressValue(0)
  //     }
  // }

  const [passwordMatch, setPasswordPattern] = useState(null);
  const {
    defaultOptions,
    editedBy,
    userData,
    toggleAcc,
    samlAccessUserType,
  } = useContext(UserRegistrationContext);

  const secretQuestions = defaultOptions
    ? defaultOptions?.secretQuestionList?.map(element => ({
        ...element,
        label: element.description,
        value: element.id,
      }))
    : [];

  const allFieldsColProps =
    editedBy === "self"
      ? { xl: 3, lg: 3, md: 6, sm: 12 }
      : { xl: 4, lg: 4, md: 6, sm: 12 };

  const passwordCheckStrength = e => {
    const numericPattern = /^(?=.*[0-9])/;

    const minCharPattern = /^.{10,}$/;

    const uppercasePattern = /^(?=.*[A-Z])/;

    const lowercasePattern = /^(?=.*[a-z])/;

    const specialCharPattern = /^(?=.*[`~!@#\\$%\\^&\\*\\(\\)_\\+=,\\.\\?;:\\|\\-])/;

    const repeatedPattern = new RegExp("(.)\\1{3,}");

    const value = e.target.value;

    const validationCheck = {
      numericPattern: numericPattern.test(value),
      minCharPattern: minCharPattern.test(value),
      uppercasePattern: uppercasePattern.test(value),
      lowercasePattern: lowercasePattern.test(value),
      specialCharPattern: specialCharPattern.test(value),
      spacesPattern: value.length > 0 ? value.indexOf(" ") < 0 : false,
      repeatedPattern: value.length >= 3 ? !repeatedPattern.test(value) : false,
    };

    setPasswordPattern(validationCheck);
  };

  return (
    <>
      {!isEmptyOrNull(valueMatchRule) && (
        <IFXAccordion
          title="Login Information"
          isOpen={
            toggleAcc == "initial"
              ? !editedBy || editedBy === "self"
              : toggleAcc
          }
        >
          {!(editedBy || samlAccessUserType) && (
            <IFXUserNote>
              <Container fluid className="p-0">
                <Row>
                  <Col>
                    <ul>
                      <li
                        className={
                          passwordMatch?.minCharPattern
                            ? "password-active"
                            : "password-default"
                        }
                      >
                        Password must be a minimum of 10 characters and maximum
                        of 25
                      </li>
                      <li
                        className={
                          passwordMatch?.uppercasePattern
                            ? "password-active"
                            : "password-default"
                        }
                      >
                        Password must contain at least one uppercase letter
                      </li>
                      <li
                        className={
                          passwordMatch?.lowercasePattern
                            ? "password-active"
                            : "password-default"
                        }
                      >
                        Password must contain at least one lowercase letter
                      </li>
                    </ul>
                  </Col>
                  <Col>
                    <ul>
                      <li
                        className={
                          passwordMatch?.numericPattern
                            ? "password-active"
                            : "password-default"
                        }
                      >
                        Password must contain at least one numeric character
                      </li>
                      <li
                        className={
                          passwordMatch?.specialCharPattern
                            ? "password-active"
                            : "password-default"
                        }
                      >
                        Password must contain at least one special character
                      </li>
                      <li
                        className={
                          passwordMatch?.spacesPattern
                            ? "password-active"
                            : "password-default"
                        }
                      >
                        Password cannot have spaces
                      </li>
                      <li
                        className={
                          passwordMatch?.repeatedPattern
                            ? "password-active"
                            : "password-default"
                        }
                      >
                        Password cannot have the same character repeated more
                        than 4 times consecutively
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </IFXUserNote>
          )}
          <IFXFormLayout allFieldsColProps={allFieldsColProps}>
            <IFXFieldWrapper
              label="User Name"
              controlId="userName"
              name="userName"
              rules={[
                CoreValidationRuleCodes.required,
                { regexCheck: [/^[a-zA-Z0-9_.-]*$/] },
              ]}
              disabled={!!editedBy}
            >
              <IFXTextBox maxLength={25} value={userData.userName} />
            </IFXFieldWrapper>
            {!(editedBy || samlAccessUserType) && (
              <IFXFieldWrapper
                label={editedBy === "self" ? "New Password" : "Password"}
                controlId="passwordText"
                name="passwordText"
                rules={[
                  CoreValidationRuleCodes.required,
                  { checkPasswordStrength: "" },
                ]}
                autoComplete="new-password"
                // now={progressValue}
                // onChange={(v) => checkStrength(v.target.value)}
                onChange={passwordCheckStrength}
              >
                <IFXTextBox type="password" maxLength={25} />
              </IFXFieldWrapper>
            )}
            {!(editedBy || samlAccessUserType) && (
              <IFXFieldWrapper
                label="Confirm Password"
                controlId="confirmPassword"
                name="confirmPassword"
                rules={[
                  CoreValidationRuleCodes.required,
                  { valueMatch: "password" },
                ]}
              >
                <IFXTextBox type="password" maxLength={25} />
              </IFXFieldWrapper>
            )}
            {(!editedBy || editedBy === "self") && !samlAccessUserType && (
              <IFXFieldWrapper
                label="Secret Question"
                controlId="secretQuestion"
                name="secretQuestion"
                type="dropdown"
                rules={[CoreValidationRuleCodes.required]}
              >
                <IFXDropdown options={secretQuestions} />
              </IFXFieldWrapper>
            )}
            {(!editedBy || editedBy === "self") && !samlAccessUserType && (
              <IFXFieldWrapper
                label="Secret Answer"
                controlId="secretAnswer"
                name="secretAnswer"
                rules={[CoreValidationRuleCodes.required]}
              >
                <IFXTextBox type="password" maxLength={20} />
              </IFXFieldWrapper>
            )}
            {(!editedBy || editedBy === "self") && !samlAccessUserType && (
              <IFXFieldWrapper
                label="Confirm Answer"
                controlId="confirmAnswer"
                name="confirmAnswer"
                rules={[
                  CoreValidationRuleCodes.required,
                  { valueMatch: "secretAnswer" },
                ]}
              >
                <IFXTextBox type="password" maxLength={20} />
              </IFXFieldWrapper>
            )}
          </IFXFormLayout>
        </IFXAccordion>
      )}
    </>
  );
};
