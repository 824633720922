export const ESMTasks = {
  ESMApplication: "Meal Manager",
  ViewVersionTimeLine: "ViewVersionTimeLine",
  PriceGroup: "PriceGroup",
  AverageMealPrice: "AverageMealPrice",
  HistoricAmpCompare: "HistoricAmpCompare",
  ViewCIALookup: "ViewCIALookup",
  SearchContractPrices: "SearchContractPrices",
  SearchPricingParameter: "SearchPricingParameter",
  LoadScale: "LoadScale",
  SpecType: "SpecType",
  SpecTag: "SpecTag",
  RotationType: "RotationType",
  SearchCateringRule: "SearchCateringRule",
  SearchProposalRequestGroup: "SearchProposalRequestGroup",
  CreateProposalRequestGroup: "CreateProposalRequestGroup",
  EditProposalRequestGroup: "EditProposalRequestGroup",
  DeleteProposalRequestGroup: "DeleteProposalRequestGroup",
  DeleteSQR: "DeleteSQR",
  CreateSDR: "CreateSDR",
  SearchOutstandingSQRs: "SearchOutstandingSQRs",
  SearchOutstandingSDRs: "SearchOutstandingSDRs",
  ViewSQR: "ViewSQR",
  EditSQR: "EditSQR",
  ACDependency: "ACDependency",
  SPMLMenu: "SPMLMenu",
  CRWMLMenu: "CRWMLMenu",
  SearchSpec: "SearchSpec",
  CreateSpec: "CreateSpec",
  EditSpec: "EditSpec",
  ApproveSpec: "ApproveSpec",
  SubApprover1: "Sub Approver1",
  SubApprover2: "Sub Approver2",
  SubmitSpecForApproval: "SubmitSpecForApproval",
  RejectSpec: "RejectSpec",
  CancelSpec: "CancelSpec",
  DeleteDraftSpec: "DeleteDraftSpec",
  DeleteCancelSpec: "DeleteCancelSpec",
  EditSearchSpec: "EditSearchSpec",
  CreateSpecCopy: "CreateSpecCopy",
  MakeSpecObsolate: "MakeSpecObsolate",
  ViewSpecWhereUsed: "ViewSpecWhereUsed",
  VersionHistory: "VersionHistory",
  CreateSpecFromTemplate: "CreateSpecFromTemplate",
  ViewSpecPrice: "ViewSpecPrice",
  ViewCalcPrice: "ViewCalcPrice",
  ServeInstructionsUpload: "ServeInstructionsUpload",
  SpecUpload: "SpecUpload",
  SearchSpecPrice: "SearchSpecPrice",
  ViewCurrency: "ViewCurrency",
  ApproveSpecPrice: "ApproveSpecPrice",
  SubmitSpecPriceForApproval: "SubmitSpecPriceForApproval",
  RejectSpecPrice: "RejectSpecPrice",
  DeleteCancelSpecPrice: "DeleteCancelSpecPrice",
  DeleteDraftSpecPrice: "DeleteDraftSpecPrice",
  SuspendSpecPrice: "SuspendSpecPrice",
  SpecPriceUpload: "SpecPriceUpload",
  AddSpecPrice: "AddSpecPrice",
  ViewPriceTimeLineAuthorized: "ViewSpecPriceTimeLine",
  ViewPriceHistoryAuthorized: "ViewSpecPriceAuditHistory",
  AddPriceAuthorized: "AddSpecPriceImg",
  ViewSpecResultsAuthorized: "EditSearchSpec",
  ViewCreateSQR: "ViewCreateSQR",
  CreateSQR: "CreateSQR",
  EditAirLineSpecPriceInDraft: "EditAirLineSpecPriceInDraft",
  SaveSpecPriceInDraft: "SaveSpecPriceInDraft",
  InitiateRollup: "InitiateRollup",
  ViewAirLineSpecPriceTimeine: "ViewAirLineSpecPriceTimeine",
  ViewSpecWhereUsedAllLevels: "ViewSpecWhereUsedAllLevels",
  DeleteComponent: "DeleteComponent",
  AddComponent: "AddComponent",
  BoardQTYData: "BoardQTYData",
  AcceptFlags: "AcceptFlags",
  DeleteImage: "DeleteImage",
  AddImage: "AddImage",
  EditImages: "EditImages",
  ViewImages: "ViewImages",
  EditComponentDetails: "EditComponentDetails",
  EditComponentQuantity: "EditComponentQuantity",
  MakeSpecObsolete: "MakeSpecObsolete",
  ViewSpecVersionTimeLine: "ViewSpecVersionTimeLine",
  ViewSpecAuditTrail: "ViewSpecAuditTrail",
  ViewSpecVrsnChanges: "ViewSpecVrsnChanges",
  SaveAll: "SaveAll",
  SpecSaveAs: "SpecSaveAs",
  DistributorData: "DistributorData",
  EditBoardQTYData: "EditBoardQTYData",
  EditDistributorData: "EditDistributorData",
  AddInstructionSet: "AddInstructionSet",
  EditSpecButton: "EditSpecButton",
  InactiveSpec: "InactiveSpec",
  SpecGlobalFindReplace: "SpecGlobalFindReplace",
  SearchSpecTag: "SearchSpecTag",
  CreateNewSpecTag: "CreateNewSpecTag",
  EditSpecTag: "EditSpecTag",
  DeleteSpecTag: "DeleteSpecTag",
  ApproveContractPrices: "ApproveContractPrices",
  ProposeContractPrices: "ProposeContractPrices",
  RejectContractPrices: "RejectContractPrices",
  CancelContractPrices: "CancelContractPrices",
  DeleteContractPrices: "DeleteContractPrices",
  AddContractPrices: "AddContractPrices",
  EditContractPrices: "EditContractPrices",
  PrintLoadScale: "Print/ExportLoadScale",
  DeleteLoadScale: "DeleteLoadScale",
  SaveAsLoadScale: "SaveAsLoadScale",
  LoadScalewhereUsed: "LoadScalewhereUsed",
  EditLoadScale: "EditLoadScale",
  SearchLoadScale: "SearchLoadScale",
  AddLoadScale: "AddLoadScale",
  ViewLoadScale: "ViewLoadScale",
  ImportLoadScale: "ImportLoadScale",

  ViewPriceGroups: "ViewPriceGroups",
  DeletePriceGroup: "DeletePriceGroup",
  AddPriceGroup: "AddPriceGroup",
  EditPriceGroup: "EditPriceGroup",
  SearchPriceGroup: "SearchPriceGroup",
  SetSpecTypeIndicators: "SetSpecTypeIndicators",
  AddSpecType: "AddSpecType",
  EditSpecType: "EditSpecType",
  DeleteSpecType: "DeleteSpecType",
  SpecTypeRelationship: "SpecTypeRelationship",
  SearchSpecType: "SearchSpecType",
  ViewSpecType: "ViewSpecType",

  SearchRotationType: "SearchRotationType",
  ViewRotationType: "ViewRotationType",
  AddRotationType: "AddRotationType",
  EditRotationType: "EditRotationType",
  DeleteRoatationType: "DeleteRoatationType",
  ExportPrintRotationType: "Export/PrintRotationType",
  ViewRotationTypeCalendar: "ViewRotationTypeCalendar",
  EditRotationTypeCalendar: "EditRotationTypeCalendar",
  RotationTypewhereused: "RotationTypewhereused",
  AddPricingParameter: "AddPricingParameter",
  EditPricingParameter: "EditPricingParameter",
  ViewSpecPriceRepTask: "ViewSpecPriceRepTask",

  DeleteSPML: "DeleteSPML",
  EditSPML: "EditSPML",
  DownloadSPML: "DownloadSPML",
  CreateSPML: "CreateSPML",
  UploadSPML: "UploadSPML",
  SearchSPML: "SearchSPML",

  DeleteCRWML: "DeleteCRWML",
  EditCRWML: "EditCRWML",
  DownloadCRWML: "DownloadCRWML",
  CreateCRWML: "CreateCRWML",
  UploadCRWML: "UploadCRWML",
  SearchCRWML: "SearchCRWML",
};
