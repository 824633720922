import initialState from "./initialState";

export function RotationTypeReducer(
  state = initialState.rotationTypeParams,
  action,
) {
  switch (action.type) {
    case "SEARCH_ROTATION_TYPE_REQUEST":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
