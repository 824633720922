import initialState from "./initialState";

export function HelpLinkReducer(state = initialState.HelpLinkReducer, action) {
  switch (action.type) {

    case "HELP_LINK_SUCCESS":
      return action.payload;
      
    case "HELP_LINK_RESET":
    case "HELP_LINK_FAILURE":
      return [];

    default:
      return state;
  }
}
