import React from "react";
import PropTypes from "prop-types";
import { IFXSVGTemplates } from "./IFXSVGTemplates";

const DEFAULT_PROPS = {
  style: {},
  fill: "currentColor",
  height: 32,
  width: 32,
};

export const IFXSVGIcon = props => {
  let { icon, color, templates = IFXSVGTemplates, ..._props } = props;

  let _width,
    _height,
    iconName,
    _icon = icon; // string, svg

  if (typeof icon === "string" && templates.hasOwnProperty(icon)) {
    iconName = icon;
    [_width, _height, _icon] = templates[icon];
    _icon = { icon: _icon, width: _width, height: _height };
  } else if (React.isValidElement(icon)) {
    _icon = {};
  }
  const { style, fill, icon: svg, className, height, width } = {
    ...DEFAULT_PROPS,
    icon,
    ..._icon,
    ..._props,
  };

  const viewBox = "0 0 " + width + " " + height;
  const widthClass = "fa-w-" + Math.ceil((width / height) * 16);

  return (
    <svg
      fill={fill}
      style={style}
      viewBox={viewBox}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-inline--fa ifx-svg-icon ${
        iconName ? "ifx-svg-" + iconName : ""
      } ${widthClass} ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {svg}
    </svg>
  );
};

IFXSVGIcon.propTypes = {
  /* phone: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element,
  ]), */
};
