export default {
  emptyArray: [],
  emptyObject: {},
  LayoutReducer: {
    sidebarClosedEnable: false,
    fullScreenEnable: false,
    loaderEnable: false,
    loaderText: "Loading",
    loaderScoped: {},
  },
  LegacyReducer: {},
  BreadcrumbReducer: {
    navBreadcrumbs: [
      /* { icon: "home", title: "IFX Home", link: "" },
    {
      icon: { iconSource: "className", className: "pe-7s-helm" },
      title: "My IFX",
      link: "",
    },
    {
      titleIcon: { iconSource: "className", className: "pe-7s-rocket" },
      title: "Dashboard",
      link: "",
    }, */
    ],
  },
  CarrierPropertiesReducer: {},
  CodeValReducer: {},
  UserStationsReducer: {},
  HelpLinkReducer: [],
};
