import React from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import cx from "classnames";

/**
 * @deprecated In favor of IFXFieldset2
 */
export const IFXFieldset = props => {
  console.warn("IFXFieldset is deprecated in favor of IFXFieldset2!");
  const _props = { ...props };
  const { title, className, contentClassName } = _props;

  return (
    <>
      <fieldset className={cx("border ifx-fieldeset-wrapper mt-2", className)}>
        <legend className="w-auto">{title}</legend>
        <Row className={cx("ifx-content-wrapper", contentClassName)}>
          {props.content.fields.map((element, idx) => {
            return (
              <Col {...element.colProps} key={idx}>
                {element.component}
              </Col>
            );
          })}
        </Row>
      </fieldset>
    </>
  );
};

export const IFXFieldset2 = ({
  children,
  title,
  className = "px-3 pb-ifx-075",
  marginClassName = "mt-2",
}) => (
  <fieldset className={cx("border ifx-fieldeset", marginClassName, className)}>
    <legend className="w-auto">{title}</legend>
    {children}
  </fieldset>
);

IFXFieldset.propTypes = {
  title: PropTypes.string,
};

IFXFieldset2.propTypes = {
  title: PropTypes.string,
  marginClassName: PropTypes.string,
  layoutProps: PropTypes.object,
};
