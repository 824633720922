import initialState from "./initialState";
export function PriceTimelineReducer(
  state = initialState.priceTimelineParams,
  action
) {
   switch (action.type) {
    case "PRICE_TIMELINE_REQUEST":
      return action.payload;
    default:
      return state;
  }
}
