import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { IFXTextBox, IFXFieldWrapper } from "../../../components/AppComponents";

export const TextBoxCard = () => {
  const [formState, setFormState] = useState({
    textBox1: "",
    textBox2: "Enter the text",
  });

  //console.log("TextBox Content: " + values);
  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title className="mb-4">TEXTBOX</Card.Title>
        <IFXFieldWrapper
          label="Text Box"
          controlId="textBox1"
          alwaysFloat={(() =>
            formState.textBox1 != null && formState.textBox1.length > 0)()}
        >
          <IFXTextBox
            value={formState.textBox1}
            onChange={e => {
              const {
                target: { value: v },
              } = e;
              setFormState({ ...formState, textBox1: v });
            }}
            maxLength={15}
          ></IFXTextBox>
        </IFXFieldWrapper>

        <Card.Title className="mt-4 mb-4">
          TEXTBOX WITH DAFAULT VALUE
        </Card.Title>
        <IFXFieldWrapper
          label="Text Box"
          controlId="textBox2"
          alwaysFloat={(() =>
            formState.textBox2 != null && formState.textBox2.length > 0)()}
        >
          <IFXTextBox
            value={formState.textBox2}
            onChange={e => {
              const {
                target: { value: v },
              } = e;
              setFormState({ ...formState, textBox2: v });
            }}
            maxLength={15}
          ></IFXTextBox>
        </IFXFieldWrapper>
      </Card.Body>
    </Card>
  );
};
