import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";

import ResizeDetector from "react-resize-detector";

import { AppMain } from "../AppMain";

export const Main = withRouter(props => {
  const sidebarClosedEnable = useSelector(
    state => state.CommonReducer.LayoutReducer.sidebarClosedEnable
  );

  const isfullScreenEnabled = useSelector(
    state => state.CommonReducer.LayoutReducer.fullScreenEnable
  );

  let {
    colorScheme = "white",
    enableFixedHeader = true,
    enableFixedSidebar = true,
    enableFixedFooter = true,
    //closedSmallerSidebar,
    //enableMobileMenu,
    enablePageTabsAlt = true,
  } = props;

  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <>
          <div
            className={cx(
              "app-container app-theme-" + colorScheme,
              { "fixed-header": enableFixedHeader },
              { "fixed-sidebar": enableFixedSidebar },
              { "fixed-footer": enableFixedFooter },
              { "closed-sidebar": sidebarClosedEnable },
              { "closed-sidebar-mobile": width < 1250 },
              { "sidebar-mobile-open": true },
              { "body-tabs-shadow-btn": enablePageTabsAlt },
              { "full-screen-mode": isfullScreenEnabled }
            )}
          >
            <AppMain />
          </div>
        </>
      )}
    />
  );
});
