export const BuildInfoReducer = (state = null, action) => {
  switch (action.type) {
    case "BUILD_INFO_PUT_IFX_RESPONSE": {
      return { ...state, ifx: action.payload };
    }
    case "BUILD_INFO_PUT_GP_RESPONSE": {
      return { ...state, gp: action.payload };
    }

    default:
      return state;
  }
};
