import { IFXHomeMenu } from "./PortalNavItems";
import { SpecManagerMenu } from "./ESMNavItems";
import { ServiceSchedulingMenu } from "./ESSNavItems";
import { ServiceOrderingMenu } from "./ESONavItems";
import { ServiceValidationMenu } from "./ESVNavItems";
import { SPMTMenu } from "./SPMTNavItems";
import { GalleyPlanningMenu } from "./GPNavItems";
import { ReportsMenu } from "./ReportsNavItems";

export const IFXMenus = [
  IFXHomeMenu,
  SpecManagerMenu,
  ServiceSchedulingMenu,
  ServiceOrderingMenu,
  ServiceValidationMenu,
  GalleyPlanningMenu,
  ReportsMenu,
  SPMTMenu,
];
