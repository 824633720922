import React, { useState } from "react";
import { Card, Container } from "react-bootstrap";
import { IFXMultiSelectCheckbox } from "../../../components/AppComponents/IFXMultiSelectCheckbox";

export const MultiSelectCheckbox = () => {
  // sample output

  const multiSelectOptions = [
    {
      id: "1",
      value: "a",
      display: "a",
    },
    {
      id: "2",
      value: "ab",
      display: "ab",
    },
    {
      id: "3",
      value: "abc",
      display: "abc",
    },
    {
      id: "4",
      value: "test",
      display: "test",
    },
    {
      id: "5",
      value: "test1",
      display: "test1",
    },
    {
      id: "6",
      value: "test2",
      display: "test2",
    },
  ];

  const defaultSelectedVal = [];

  const [getSelectVal, setSelectVal] = useState(defaultSelectedVal);

  // final output

  // console.log("check--------",getSelectVal)

  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title className="mb-4">Multi Select Checkbox Card</Card.Title>
        <Container>
          <IFXMultiSelectCheckbox
            selectedVal={data => setSelectVal(data)}
            data={multiSelectOptions}
            containerLeftName="Available Spec Type"
            containerRightName="Selected Spec Types"
          />
        </Container>
      </Card.Body>
    </Card>
  );
};
