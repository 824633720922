import React from "react";
import {
  IFXModal,
  IFXModalBody,
  IFXModalHeader,
  IFXModalFooter,
} from "@ifx-react/ifx-react-core";
import { Row, Col } from "react-bootstrap";

export const ViewTaskModal = ({ onHide, show, viewTaskData }) => {
  return (
    <>
      <IFXModal show={show} onHide={onHide} size="lg">
        <IFXModalHeader>Tasks</IFXModalHeader>
        <IFXModalBody
          isScroll
          style={{
            height: 200,
          }}
        >
          <Row>
            {viewTaskData.map(item => {
              return (
                <Col sm="4">
                  <h6>
                    {item.application}: {item.name}
                  </h6>
                </Col>
              );
            })}
          </Row>
        </IFXModalBody>
        <IFXModalFooter></IFXModalFooter>
      </IFXModal>
    </>
  );
};
