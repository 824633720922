import { ESSTasks } from "../../../../../const/ess/ESSTaskConsts";
import { PortalTasks } from "../../../../../const/portal/PortalTaskConsts";

export const ServiceSchedulingMenu = {
  dashboard: {
    icon: "schedules",
    title: "SCHEDULES",
    source: "/app/ess/",
    authorize: { hasTask: ESSTasks.ESSApplication },
  },
  menu: [
    {
      icon: "pe-7s-display1",
      label: "Dashboard",
      to: "/app/ess/dashboard",
      authorize: { hasTask: ESSTasks.SchAdmView },
    },
    {
      icon: "pe-7s-id",
      label: "Demo Page",
      to: "/app/ess/demo",
      authorize: { hasTask: PortalTasks.TestPage },
    },
    {
      icon: "pe-7s-browser",
      label: "Flight Service Schedule",
      authorize: {
        hasAnyTask: [
          ESSTasks.ESSQueryFSS,
          ESSTasks.HypotheticalFSS,
          ESSTasks.FSSRel,
          ESSTasks.RelFSSViewQuery,
          ESSTasks.FSSCompareView,
          ESSTasks.FSSCNView,
          ESSTasks.CUPSearch,
          ESSTasks.RelFSSFltDetView,
          ESSTasks.ViewFSQuery,
          ESSTasks.FSSViewQuery,
        ],
      },
      content: [
        {
          label: "Search FSS",
          to: "/app/ess/fss/queryFSS",
          authorize: {
            hasAnyTask: [
              ESSTasks.ViewFSQuery,
              ESSTasks.FSSViewQuery,
              ESSTasks.ESSQueryFSS,
            ],
          },
        },
        {
          label: "Create FSS",
          to: "/app/ess/fss/create",
          authorize: { hasTask: ESSTasks.HypotheticalFSS },
        },
        {
          label: "Compare FSS",
          to: "/app/ess/fss/compare",
          authorize: { hasTask: ESSTasks.FSSCompareView },
        },
        {
          label: "Release FSS",
          to: "/app/ess/fss/release",
          authorize: { hasTask: ESSTasks.FSSRel },
        },
        {
          label: "Search Released FSS",
          to: "/app/ess/fss/queryReleasedFSS",
          authorize: {
            hasAnyTask: [ESSTasks.RelFSSViewQuery, ESSTasks.RelFSSFltDetView],
          },
        },
        {
          label: "FSS Change Notice",
          to: "/app/ess/fss/notice/change",
          authorize: { hasTask: ESSTasks.FSSCNView },
        },
        {
          label: "Search CUP",
          to: "/app/ess/cupSearch",
          authorize: { hasTask: ESSTasks.CUPSearch },
        },
      ],
    },
    {
      icon: "pe-7s-browser",
      label: "Scheduling Rules",
      authorize: {
        hasAnyTask: [
          ESSTasks.GlobCatView,
          ESSTasks.GlobCatListView,
          ESSTasks.RuleSetView,
          ESSTasks.RuleSetListView,
          ESSTasks.RuleSearchView,
          ESSTasks.RuleCreate,
        ],
      },
      content: [
        {
          label: "Rule Categories",
          to: "/app/ess/rulesView/globalRuleCategories",
          authorize: {
            hasAnyTask: [ESSTasks.GlobCatView, ESSTasks.GlobCatListView],
          },
        },
        {
          label: "Rule Sets",
          to: "/app/ess/rulesView/maintainRuleSet",
          authorize: {
            hasAnyTask: [ESSTasks.RuleSetView, ESSTasks.RuleSetListView],
          },
        },
        {
          label: "Search Rules",
          to: "/app/ess/searchRule",
          authorize: { hasTask: ESSTasks.RuleSearchView },
        },
        {
          label: "Create Rule",
          to: "/app/ess/createRule",
          authorize: { hasTask: ESSTasks.RuleCreate },
        },
      ],
    },
    {
      icon: "pe-7s-browser",
      label: "Service Demand Forecast",
      authorize: {
        hasAnyTask: [
          ESSTasks.ViewSDFCreate,
          ESSTasks.SDFCreateView,
          ESSTasks.PAXForcstMethodView,
          ESSTasks.SDFManageView,
          ESSTasks.QuerySDF,
          ESSTasks.ViewSDFQuery,
          ESSTasks.SDFCompareView,
          ESSTasks.SDFRel,
          ESSTasks.RelSDFViewQuery,
          ESSTasks.SDFViewQuery,
        ],
      },
      content: [
        {
          label: "Passenger Forecast",
          to: "/app/ess/sdf/forecast",
          authorize: { hasTask: ESSTasks.PAXForcstMethodView },
        },
        {
          label: "Search SDF",
          to: "/app/ess/sdf/search",
          authorize: {
            hasAnyTask: [ESSTasks.QuerySDF, ESSTasks.SDFViewQuery],
          },
        },
        {
          label: "Create SDF",
          to: "/app/ess/sdf/create",
          authorize: {
            hasAnyTask: [ESSTasks.ViewSDFCreate, ESSTasks.SDFCreateView],
          },
        },
        {
          label: "Compare SDF",
          to: "/app/ess/sdf/compare",
          authorize: { hasTask: ESSTasks.SDFCompareView },
        },
        {
          label: "Release SDF",
          to: "/app/ess/sdf/release",
          authorize: { hasTask: ESSTasks.SDFRel },
        },
        {
          label: "Search Released SDF",
          to: "/app/ess/sdf/search/released",
          authorize: {
            hasAnyTask: [ESSTasks.ViewSDFQuery, ESSTasks.RelSDFViewQuery],
          },
        },
        {
          label: "SDF Dashboard",
          to: "/app/ess/sdf/dashboard",
          authorize: { hasTask: ESSTasks.SDFManageView },
        },
      ],
    },
    {
      icon: "pe-7s-browser",
      label: "Administration",
      authorize: {
        hasAnyTask: [
          ESSTasks.ViewSvcFltTagQuery,
          ESSTasks.ESSViewOrderOfService,
        ],
      },
      content: [
        {
          label: "Flight Tags",
          to: "/app/ess/flighttag",
          authorize: {
            hasTask: ESSTasks.ViewSvcFltTagQuery,
          },
        },
        {
          label: "Order of Service",
          to: "/app/ess/order/service",
          authorize: { hasTask: ESSTasks.ESSViewOrderOfService },
        },
      ],
    },
  ],
};
