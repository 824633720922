import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  IFXModal,
  IFXModalBody,
  IFXModalHeader,
  IFXModalFooter,
  IFXButton,
  IFXFieldWrapper,
  IFXTextBox,
  IFXFormLayout,
  IFXFormValidation,
  CoreValidationRuleCodes,
  IFXButtonGroup,
  IFXAccordion,
  ifxJsonAjax,
  useObservableCallback,
  useToast,
} from "@ifx-react/ifx-react-core";
import { Subject } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { VALIDATE_SECRET_DATA_URL } from "../../../const/portal/endpoints";
import { LayoutActions } from "../../../store/actions/common/actionCreators";
import { LoginConsts } from "./loginConts";
import { CommonToastConsts } from "../../../const/common/toastConsts";

const validateSecretData$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax
        .post(
          `${VALIDATE_SECRET_DATA_URL}?strUserName=${params.userName}&secretDataEntered=${params.secretAnswer}`
        )
        .pipe(
          map(xhrResponse => {
            return xhrResponse.response || "";
          }),
          catchError(error => {
            console.error("Error in Validate Secret Data", error);
            errorHandler(error.response || {});
            return [];
          })
        )
    )
  );

const ValidateSecretModal = ({ onHide }) => {
  return (
    <>
      <IFXModalBody disableBackground>
        <div className="reset-password-info mb-2">
          Please verify the identity by answering the secret question.After
          verification, a new temporary password will be sent to the Email
          address associated with the User Name below.
        </div>
        <IFXAccordion title="" isOpen={true} enabled={true} isAccordion={false}>
          <IFXFormLayout allFieldsColProps={{ lg: 12 }}>
            <IFXFieldWrapper
              label="User Name"
              controlId="userName"
              name="userName"
              rules={[CoreValidationRuleCodes.required]}
              disabled={true}
            >
              <IFXTextBox maxLength={25} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="Secret Question"
              controlId="secretQuestion"
              name="secretQuestion"
              rules={[CoreValidationRuleCodes.required]}
              disabled={true}
            >
              <IFXTextBox />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="Secret Answer"
              controlId="secretAnswer"
              name="secretAnswer"
              autocomplete="new-password"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXTextBox type="password" />
            </IFXFieldWrapper>
          </IFXFormLayout>
        </IFXAccordion>
        <div className="reset-password-info mt-4">
          If you require technical assistance with this web site, please feel
          free to contact our Help Desk using one of the methods listed below:
        </div>
        <div className="mt-2 reset-password-contact">
          Phone: (+1) 571 370 9700
        </div>
        <div className="reset-password-contact">
          Email:{" "}
          <a href="mailto:support@paxiasolutions.com">
            support@paxiasolutions.com
          </a>
        </div>
      </IFXModalBody>
      <IFXModalFooter>
        <IFXButtonGroup>
          <IFXButton type="submit" name="Submit" />
          <IFXButton type="cancel" onClick={onHide} />
        </IFXButtonGroup>
      </IFXModalFooter>
    </>
  );
};

export const VerifySecretDataModal = ({ show, onHide, secretData }) => {
  const dispatch = useDispatch();
  const { ifxInfoToast, ifxErrorToast } = useToast();

  const validateSecretQuestion = useMemo(() => {
    return validateSecretData$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        if (error.messageCode === "SECRET_ANS_NOTMATCH") {
          ifxInfoToast(LoginConsts.SECRET_ANS_NOT_MATCH);
          return;
        }
        ifxErrorToast(CommonToastConsts.ERROR);
        onHide();
      },
    });
  }, []);

  const handleOnSubmit = formValues => {
    dispatch(
      LayoutActions.SET_LOADER_SHOW({
        loaderText: "Loading...",
      })
    );
    validateSecretQuestion.next({
      userName: secretData.userName,
      secretAnswer: formValues.secretAnswer,
    });
  };

  useObservableCallback(
    validateSecretQuestion,
    response => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      ifxInfoToast({
        content: response,
      });
      onHide();
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
    }
  );

  const defaultValues = {
    userName: secretData.userName,
    secretQuestion: secretData.secretquestionData,
    secretAnswer: "",
  };

  return (
    <>
      <IFXModal show={show} onHide={onHide} size="md">
        <IFXModalHeader>Verify Secret Question</IFXModalHeader>
        <IFXFormValidation
          defaultValues={defaultValues}
          onSubmit={handleOnSubmit}
        >
          <ValidateSecretModal onHide={onHide} />
        </IFXFormValidation>
      </IFXModal>
    </>
  );
};
