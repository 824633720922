import React, { useState, useMemo, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useFormContext } from "react-hook-form";
import {
  IFXModal,
  IFXModalBody,
  IFXModalHeader,
  IFXModalFooter,
  IFXButton,
  IFXFieldWrapper,
  IFXTextBox,
  IFXFormLayout,
  IFXFormValidation,
  CoreValidationRuleCodes,
  IFXButtonGroup,
  IFXAccordion,
  ifxJsonAjax,
  useObservableCallback,
  IFXAlert,
  substitute,
  isEmptyOrNull,
  IFXUserNote,
  useToast,
} from "@ifx-react/ifx-react-core";
import { Subject, throwError } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import {
  PASSWORD_EXPIRATION_CHECK_URL,
  PASSWORD_EXPIRATION_URL,
  PASSWORD_LIMIT_URL,
  PASSWORD_UPDATE_URL,
} from "../../../const/portal/endpoints";
import { LayoutActions } from "../../../store/actions/common/actionCreators";
import { CommonToastConsts } from "../../../const/common/toastConsts";
import { LoginConsts } from "./loginConts";

const getChangePasswordObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax.post(PASSWORD_UPDATE_URL, params).pipe(
        map(xhrResponse => {
          return xhrResponse.response || "";
        }),
        catchError(error => {
          console.error("Error in Change Password", error);
          errorHandler(error.response || {});
          return [];
        })
      )
    )
  );
const gePasswordHistoryObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax.post(PASSWORD_EXPIRATION_CHECK_URL, params).pipe(
        map(xhrResponse => {
          return { xhrResponse: xhrResponse.response, params };
        }),
        catchError(error => {
          console.error("Error in Change Password", error);
          errorHandler({ error: error.response, params });
          return [];
        })
      )
    )
  );

const getPasswordLimitObj$ = () => {
  return ifxJsonAjax.post(PASSWORD_LIMIT_URL, {}).pipe(
    map(xhrResponse => {
      return xhrResponse.response;
    }),
    catchError(error => {
      return throwError(error);
    })
  );
};

const defaultValues = {};

const numericPattern = /^(?=.*[0-9])/;

const minCharPattern = /^.{10,}$/;

const uppercasePattern = /^(?=.*[A-Z])/;

const lowercasePattern = /^(?=.*[a-z])/;

const specialCharPattern = /^(?=.*[`~!@#\\$%\\^&\\*\\(\\)_\\+=,\\.\\?;:\\|\\-])/;

const repeatedPattern = new RegExp("(.)\\1{3,}");

const passwordVaildation = value => {
  return {
    numericPattern: numericPattern.test(value),
    minCharPattern: minCharPattern.test(value),
    uppercasePattern: uppercasePattern.test(value),
    lowercasePattern: lowercasePattern.test(value),
    specialCharPattern: specialCharPattern.test(value),
    spacesPattern: value.length > 0 ? value.indexOf(" ") < 0 : false,
    repeatedPattern: value.length >= 3 ? !repeatedPattern.test(value) : false,
  };
};

const validatePrevPasswordRule = ({ label }, passwordCheckRef, message) => {
  return passwordCheckRef?.current === true ? message : undefined;
};

const ChangePasswordForm = ({
  onHide,
  setValueMatchRule,
  valueMatchRule,
  username,
  passwordLimit,
  showError,
  prevPasswordCheckRef,
  setPrevPasswordCheck,
}) => {
  const { getValues } = useFormContext();
  const [passwordMatch, setPasswordPattern] = useState(null);

  const prevPasswordMessage = `New Password cannot be the same as any of your ${passwordLimit} 
  previous passwords`;

  useEffect(() => {
    setValueMatchRule({
      valueMatch: ({ value, label }, compareLabel) => {
        let compareValue = "";
        let validationText = "";
        if (compareLabel === "newChngPassword") {
          compareValue = getValues("newChngPassword");
          validationText = "Password and Confirm Password values do not match";
        }
        if (value !== compareValue) {
          return substitute(validationText, { label });
        }
        return undefined;
      },
      checkPasswordStrength: ({ value, label }) => {
        if (
          value.includes(" ") ||
          !numericPattern.test(value) ||
          !minCharPattern.test(value) ||
          !uppercasePattern.test(value) ||
          !lowercasePattern.test(value) ||
          !specialCharPattern.test(value) ||
          !value.indexOf(" ") < 0 ||
          repeatedPattern.test(value)
        ) {
          return substitute(`${label} is not valid`, { label });
        }
        return undefined;
      },
    });
    return () => {
      setValueMatchRule({});
      setPasswordPattern(null);
    };
  }, []);

  const passwordCheckStrength = e => {
    const value = e.target.value;
    let validationCheck = passwordVaildation(value);
    if (value > 10) {
      validationCheck.passwordLimitPattern = false;
    }
    setPasswordPattern(validationCheck);
  };

  const passwordHistory$ = useMemo(() => {
    return gePasswordHistoryObj$({
      errorHandler: errorResponse => {
        if (errorResponse.error.message === "NEW_PASSWORD_NOT_MATCH") {
          setPrevPasswordCheck(true);
          let errorObj = passwordVaildation(errorResponse.params.newPassword);
          errorObj.passwordLimitPattern = false;
          setPasswordPattern(errorObj);
        }
      },
    });
  }, []);

  useObservableCallback(passwordHistory$, response => {
    if (response.xhrResponse === "success") {
      let successObj = passwordVaildation(response.params.newPassword);
      successObj.passwordLimitPattern = true;
      setPasswordPattern(successObj);
      setPrevPasswordCheck(false);
    }
  });

  const passwordOnBlur = e => {
    e.target.value.length > 0 &&
      passwordHistory$.next({
        newPassword: e.target.value,
        userName: username,
        passwordLimit: passwordLimit,
      });
  };

  return !isEmptyOrNull(valueMatchRule) ? (
    <>
      <IFXModalBody disableBackground>
        <IFXAccordion title="" isOpen={true} enabled={true} isAccordion={false}>
          <IFXUserNote>
            <ul>
              <li
                className={
                  passwordMatch?.minCharPattern
                    ? "password-active"
                    : "password-default"
                }
              >
                Password must be a minimum of 10 characters and maximum of 25
              </li>
              <li
                className={
                  passwordMatch?.uppercasePattern
                    ? "password-active"
                    : "password-default"
                }
              >
                Password must contain at least one uppercase letter
              </li>
              <li
                className={
                  passwordMatch?.lowercasePattern
                    ? "password-active"
                    : "password-default"
                }
              >
                Password must contain at least one lowercase letter
              </li>
              <li
                className={
                  passwordMatch?.numericPattern
                    ? "password-active"
                    : "password-default"
                }
              >
                Password must contain at least one numeric character
              </li>
              <li
                className={
                  passwordMatch?.specialCharPattern
                    ? "password-active"
                    : "password-default"
                }
              >
                Password must contain at least one special character
              </li>
              <li
                className={
                  passwordMatch?.spacesPattern
                    ? "password-active"
                    : "password-default"
                }
              >
                Password cannot have spaces
              </li>
              <li
                className={
                  passwordMatch?.repeatedPattern
                    ? "password-active"
                    : "password-default"
                }
              >
                Password cannot have the same character repeated more than 4
                times consecutively
              </li>
              <li
                className={
                  passwordMatch?.passwordLimitPattern
                    ? "password-active"
                    : "password-default"
                }
              >
                {prevPasswordMessage}
              </li>
            </ul>
          </IFXUserNote>
          {showError && <IFXAlert variant="danger">{showError}</IFXAlert>}
          <IFXFormLayout allFieldsColProps={{ lg: 12 }}>
            <IFXFieldWrapper
              label="Current Password"
              controlId="currentPassword"
              name="currentPassword"
              rules={[CoreValidationRuleCodes.required]}
              autoComplete="new-password"
            >
              <IFXTextBox type="password" maxLength={25} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="New Password"
              controlId="newChngPassword"
              name="newChngPassword"
              rules={[
                CoreValidationRuleCodes.required,
                "checkPasswordStrength",
                {
                  validatePrevPasswordRule: [
                    prevPasswordCheckRef,
                    prevPasswordMessage,
                  ],
                },
              ]}
              autoComplete="new-password"
              onChange={passwordCheckStrength}
            >
              <IFXTextBox
                type="password"
                maxLength={25}
                onBlur={passwordOnBlur}
              />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="Confirm Password"
              controlId="confirmChngPassword"
              name="confirmChngPassword"
              rules={[
                CoreValidationRuleCodes.required,
                { valueMatch: "newChngPassword" },
              ]}
              autoComplete="new-password"
            >
              <IFXTextBox type="password" maxLength={25} />
            </IFXFieldWrapper>
          </IFXFormLayout>
        </IFXAccordion>
      </IFXModalBody>
      <IFXModalFooter>
        <IFXButtonGroup>
          <IFXButton type="submit" />
          <IFXButton type="cancel" onClick={onHide} />
        </IFXButtonGroup>
      </IFXModalFooter>
    </>
  ) : null;
};

export const ChangePasswordModal = ({ show, onHide, username, editedBy }) => {
  const [valueMatchRule, setValueMatchRule] = useState({});
  const [passwordLimit, setPasswordLimit] = useState();
  const [showError, setShowError] = useState("");

  const dispatch = useDispatch();
  const { ifxErrorToast, ifxSuccessToast } = useToast();

  const prevPasswordCheckRef = useRef();
  const setPrevPasswordCheck = v => (prevPasswordCheckRef.current = v);

  const changePassword$ = useMemo(() => {
    return getChangePasswordObj$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        if (error.message === "CURR_PASSWORD_NOT_MATCH") {
          setShowError(
            "You have entered an incorrect value for your Current Password. Please correct this before continuing."
          );
          return;
        } else if (error.message === "NEW_PASSWORD_NOT_MATCH") {
          setShowError(
            `New Password cannot be the same as any of your ${error.param} previous passwords.`
          );
          return;
        }
        ifxErrorToast(CommonToastConsts.ERROR);
      },
    });
  }, []);

  const passwordLimit$ = useMemo(() => getPasswordLimitObj$(), [
    !passwordLimit,
  ]);

  useEffect(() => {
    setShowError("");
  }, [show]);

  const handleOnSubmit = formValues => {
    dispatch(
      LayoutActions.SET_LOADER_SHOW({
        loaderText: "Loading...",
      })
    );
    changePassword$.next({
      currentPassword: formValues.currentPassword,
      newPassword: formValues.newChngPassword,
      userName: username,
      passwordLimit: passwordLimit,
    });
  };
  useObservableCallback(
    changePassword$,
    response => {
      onHide();
      ifxSuccessToast({
        content: LoginConsts.PASSWORD_CHANGED_INFO,
      });
      dispatch(LayoutActions.SET_LOADER_HIDE());
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
    }
  );

  useObservableCallback(
    passwordLimit$,
    response => {
      setPasswordLimit(response);
    },
    error => {}
  );

  return (
    <>
      <IFXModal
        show={show}
        onHide={onHide}
        size="md"
        className="change-password-modal"
      >
        <IFXModalHeader>Change Password</IFXModalHeader>
        {editedBy !== "self" && (
          <IFXAlert variant="danger">
            Your password has expired. Please choose a new password.
          </IFXAlert>
        )}
        <IFXFormValidation
          defaultValues={defaultValues}
          onSubmit={handleOnSubmit}
          customValidationRules={{
            ...valueMatchRule,
            validatePrevPasswordRule,
          }}
        >
          <ChangePasswordForm
            {...{
              onHide,
              setValueMatchRule,
              valueMatchRule,
              username,
              passwordLimit,
              showError,
              prevPasswordCheckRef,
              setPrevPasswordCheck,
            }}
          />
        </IFXFormValidation>
      </IFXModal>
    </>
  );
};
