import React, {
  forwardRef,
  useRef,
  useEffect,
  useImperativeHandle,
  useCallback,
} from "react";
import { omit } from "../../../../../..";
import { IFXNumberTextBox2 } from "../../..";
import { isEmptyOrNull } from "../../../../../utils";

export const IFXNumberBoxCol = forwardRef(
  ({ onChange, onBlur, ...props }, ref) => {
    const innerRef = useRef();

    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          const v = innerRef.current.value;
          return !isEmptyOrNull(v) ? Number(v) : null;
        },
      };
    });

    useEffect(() => {
      window.setTimeout(() => {
        //console.log(innerRef);
        try {
          innerRef.current.focus();
          //innerRef.current.select();
        } catch (e) {}
      }, 100);
    }, []);

    const _onChange = useCallback(
      e => {
        onChange && onChange(e.target.value, props);
      },
      [onChange]
    );

    const _onBlur = useCallback(() => {
      onBlur && onBlur(props);
    }, [onBlur]);

    const _props = omit(props, [
      "keyPress",
      "charPress",
      "colDef",
      "rowIndex",
      "columnApi",
      "stopEditing",
      "eGridCell",
      "parseValue",
      "formatValue",
      "agGridReact",
      "frameworkComponentWrapper",
      "reactContainer",
      "value",
      "cellStartedEdit",
      "column",
      "node",
      "data",
      "api",
    ]);

    return (
      <IFXNumberTextBox2
        {..._props}
        ref={innerRef}
        defaultValue={props.value}
        onChange={_onChange}
        onBlur={_onBlur}
      />
    );
  }
);
