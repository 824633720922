export const INITIAL_CARRIER_PROPERTIES_LIST = [
  "ENABLE_SERVICE_SEQ_NO",
  "IS_CATERER_SO_STATUS_CHANGE_DISABLED",
];

export const CarrierPropertiesKeys = {
  SPEC_DETAIL_ON_THE_FLY_DOWNLOAD_MAX_LIMIT: "SPEC_DETAIL_ON_THE_FLY_DOWNLOAD_MAX_LIMIT",
  COMMON_CLASS_OF_SVC_ABBR: "COMMON_CLASS_OF_SVC_ABBR",
  IS_CATERER_SO_STATUS_CHANGE_DISABLED: "IS_CATERER_SO_STATUS_CHANGE_DISABLED",
  ESO_DASHBOARD_FSR_MAX_DOWNLOAD_LIMIT:"ESO_DASHBOARD_FSR_MAX_DOWNLOAD_LIMIT",
  ENABLE_SERVICE_SEQ_NO: "ENABLE_SERVICE_SEQ_NO",
  REPORT_DOWNLOAD_CENTER_MAX_LIMIT: "REPORT_DOWNLOAD_CENTER_MAX_LIMIT",
  REPORT_DOWNLOAD_CENTER_BOUNDS: "REPORT_DOWNLOAD_CENTER_BOUNDS",
  SEARCH_INVOICE_MAX_RECORD_COUNT: "SEARCH_INVOICE_MAX_RECORD_COUNT",
  SPECPRICE_REPORT_DOWNLOAD_CENTER_MAX_LIMIT:
    "SPECPRICE_REPORT_DOWNLOAD_CENTER_MAX_LIMIT",
  SPECPRICE_REPORT_DOWNLOAD_CENTER_BOUNDS:
    "SPECPRICE_REPORT_DOWNLOAD_CENTER_BOUNDS",
  SKIP_LABOUR_RATES_UPDATE: "SKIP_LABOUR_RATES_UPDATE",
  FLAG_TO_EDIT_CATERER_SPEC: "FLAG_TO_EDIT_CATERER_SPEC",
  FLAG_TO_EDIT_CARRIER_SPEC: "FLAG_TO_EDIT_CARRIER_SPEC",
  COMMON_CLASS_COUNT_UPDATE: "COMMON_CLASS_COUNT_UPDATE",
  RULE_LOG_PAXCOUNT_LABELS: "RULE_LOG_PAXCOUNT_LABELS",
  RULE_LOG_PAXCOUNT_VALUETYPES: "RULE_LOG_PAXCOUNT_VALUETYPES",
  VIEWFSS_DAILY_REPORT_DOWNLOAD_CENTER_BOUNDS:
    "VIEWFSS_DAILY_REPORT_DOWNLOAD_CENTER_BOUNDS",
  VIEWFSS_DAILY_REPORT_DOWNLOAD_CENTER_MAX_LIMIT:
    "VIEWFSS_DAILY_REPORT_DOWNLOAD_CENTER_MAX_LIMIT",
  VIEWFSS_PERODIC_REPORT_DOWNLOAD_CENTER_BOUNDS:
    "VIEWFSS_PERODIC_REPORT_DOWNLOAD_CENTER_BOUNDS",
  VIEWFSS_PERODIC_REPORT_DOWNLOAD_CENTER_MAX_LIMIT:
    "VIEWFSS_PERODIC_REPORT_DOWNLOAD_CENTER_MAX_LIMIT",
    VENDOR_INVOICE_NUMBER_CHECK: "VENDOR_INVOICE_NUMBER_CHECK",
    CAT_DESC_MANDATORY_CHECK:"CAT_DESC_MANDATORY_CHECK",
    CAT_DESC_LENGTH:"CAT_DESC_LENGTH",
    CATERING_STATION_CODE_LENGTH: "CATERING_STATION_CODE_LENGTH",
    ALLOWED_EP7_GENERATE_STATUSES:"ALLOWED_EP7_GENERATE_STATUSES",
    INVPERIOD_REPORT_DOWNLOAD_CENTER_BOUNDS: "INVPERIOD_REPORT_DOWNLOAD_CENTER_BOUNDS",
};
