import { combineEpics } from "redux-observable";
import { commonEpic } from "./common";
import { portalEpic } from "./portal";
import { esoEpic } from "./eso";
import { esvEpic } from "./esv";
import { esmEpic } from "./esm";

const rootEpic = combineEpics(
  commonEpic,
  portalEpic,
  esoEpic,
  esvEpic,
  esmEpic
);

export default rootEpic;
