import React, {memo} from "react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { PlainReadOnlyCell } from "./EditableFacade";

export const EditableDropDownFacade = memo(props => {
  //console.log(props);
  const {
    value,
    colDef: { editable = true },
    required = false,
    plainReadOnlyType,
  } = props;

  const _editable = typeof editable === "function" ? editable(props) : editable;
  const _required = typeof required === "function" ? required(props) : required;
  const _plainReadOnlyType =
    typeof plainReadOnlyType === "function"
      ? plainReadOnlyType(props)
      : plainReadOnlyType;

  let _value = value ? value.label : "";

  if (_plainReadOnlyType && !_editable) {
    return <PlainReadOnlyCell value={_value} />;
  }

  return (
    <>
      <div
        //title={_value}
        className={cx("ifx-dropdown-wrapper input-group input-group-sm", {
          disabled: !_editable,
          "field-mandatory": _required,
        })}
      >
        {/* <div className="input-group-prepend">
          <div className="input-group-text"></div>
        </div> */}
        <div className="position-relative ifx-dropdown">
          <div className="rbt clearfix open ">
            <div className="rbt-input-hint-container">
              <div className="rbt-input-main form-control rbt-input  input-sm form-control-sm ifx-text-overflow">
                {_value}
              </div>
            </div>
          </div>
        </div>
        <div className="input-group-append">
          <div className="ifx-drowpdown-btn btn-transition btn btn-outline-light btn-sm">
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
        </div>
      </div>
    </>
  );
});
