import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { IFXTimePicker } from "../../../components/AppComponents";
import { IFXFieldWrapper } from "../../../components/AppComponents/IFXFieldWrapper";

export const TimePickerCard = () => {
  const [departureTime, setDepartureTime] = useState(null);
  const [arrivalTime, setArrivalTime] = useState({ serialized: "1655" });
  //console.log(selectedTime !== "" ? moment(selectedTime).format("HH:mm") : "");

  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title className="mb-4">Time Picker</Card.Title>
        <IFXFieldWrapper
          type="group"
          label="Departure Time"
          controlId="departureTime1"
          alwaysFloat={(() => departureTime != null)()}
        >
          <IFXTimePicker
            selected={departureTime}
            onChange={v => {
              setDepartureTime(v);
            }}
          />
        </IFXFieldWrapper>

        <Card.Title className="my-4">Time Picker Default Value</Card.Title>
        <IFXFieldWrapper
          type="group"
          label="Arrival Time"
          controlId="arrivalTime1"
          alwaysFloat={(() => arrivalTime != null)()}
        >
          <IFXTimePicker
            selected={arrivalTime}
            onChange={v => {
              setArrivalTime(v);
            }}
            serializeFormat="HHmm" // this is optional default is HH:mm
          />
        </IFXFieldWrapper>
        <Card.Title className="my-4">Time Picker Disabled</Card.Title>
        <IFXFieldWrapper
          type="group"
          label="Arrival Time Disabled"
          controlId="arrivalTime2"
          alwaysFloat={(() => arrivalTime != null)()}
        >
          <IFXTimePicker
            disabled
            selected={arrivalTime}
            onChange={v => {
              setArrivalTime(v);
            }}
            serializeFormat="HHmm" // this is optional default is HH:mm
          />
        </IFXFieldWrapper>
      </Card.Body>
    </Card>
  );
};
