import React, { useState } from "react";
import { Card, FormGroup, Row, Col } from "react-bootstrap";
import { IFXRadioButton } from "../../../components/AppComponents";

export const RadioButtonCard = () => {
  const [colorChoice, setColorChoice] = useState("Red");

  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title>Radios</Card.Title>
        <FormGroup>
          <div>
            {["Red", "Green", "Blue"].map(color => (
              <IFXRadioButton
                key={color}
                className="d-inline-block m-2"
                name="colorRadios"
                id={`color_${color.toLowerCase()}`}
                onChange={v => {
                  setColorChoice(v.currentTarget.value);
                }}
                checked={colorChoice === color}
                value={color}
                label={color}
              />
            ))}

            <Row>
              <Col>
                <h6>Disabled Radio Buttons:</h6>
              </Col>
            </Row>

            {["Red", "Green", "Blue"].map(color => (
              <IFXRadioButton
                key={color}
                className="d-inline-block m-2"
                name="colorRadios_disabled"
                id={`color_disabled_${color.toLowerCase()}`}
                disabled
                checked={colorChoice === color}
                label={color}
              />
            ))}
          </div>
        </FormGroup>
      </Card.Body>
    </Card>
  );
};
