import initialState from "./initialState";

export function InvoiceStatusReducer(
  state = initialState.InvoiceStatusReducer,
  action
) {
  switch (action.type) {
    case "INVOICE_STATUS_PUT_INVOICE_STATUS":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
