import initailState from "./initialState";

export const EsoCommonReducer = (
  state = initailState.EsoCommonReducer,
  action
) => {
  switch (action.type) {
    case "STATUS_INFO_PUSH_DATA":
      return { ...state, statusInfo: action.payload };
    case "PUT_SYSTEM_PAX_FIGURE":
      return { ...state, systemPaxFigure: action.payload };
    case "SO_GENARAL_CUSTOMIZATION":
      return { ...state, soCustomization: action.payload };
    default:
      return state;
  }
};
