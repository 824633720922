import initialState from "./initialState";

export function DetailedInvoiceReducer(
  state = initialState.DetailedInvoiceFilteredInfo,
  action
) {
  switch (action.type) {
    case "DETAILED_INV_SELECTION_REQUEST":
      return {
        ...state,
        DetailedInvoiceFilteredInfo: action.payload,
      };
    default:
      return state;
  }
}
