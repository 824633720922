import initialState from "./initialState";

export function VersionTimelineReducer(
  state = initialState.versionTimelineParams,
  action
) {
  switch (action.type) {
    case "VERSION_TIMELINE_REQUEST":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}