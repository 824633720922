import React, { forwardRef, useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import cx from "classnames";
import { omit } from "../../utils/utils";
import { useCombinedRefs } from "../../hooks";

const DEFAULT_PROPS = {
  className: "ifx-number-textbox",
  size: "sm",
  autoComplete: "off",
  type: "text",
  //step: 0.01,
  min: 0, //to avoid negative
  allowLeadingZero: false,
};

function getSelectionStart(o) {
  //console.log("getSelectionStart", o);
  if (o.createTextRange) {
    var r = document.selection.createRange().duplicate();
    r.moveEnd("character", o.value.length);
    if (r.text == "") return o.value.length;
    return o.value.lastIndexOf(r.text);
  } else return o.selectionStart;
}

const getValueString = (orgStr, key, caratPos, end) => {
  if (caratPos === 0 && orgStr.length === end) {
    return key;
  } else
    return [orgStr.slice(0, caratPos), key, orgStr.slice(caratPos)].join("");
};

const getRegEx = (decimal, min, allowLeadingZero) => {
  const negative = min < 0;
  //console.log("negative", negative, min, allowLeadingZero);
  const regex = new RegExp(
    `^${(negative && "\\-?") || ""}${(allowLeadingZero && "(0+)?(\\d+)?") ||
      (decimal && decimal > 0 && "(\\d+)?(\\.)?(\\d{1," + decimal + "})?") ||
      "(\\d+)?"}$`
  );
  //console.log("regex", regex);
  return regex;
};

const zeroRegex = /^\-?0([0-9])+$/;

export const IFXNumberTextBox2 = forwardRef((props, ref) => {
  const combinedRef = useCombinedRefs(ref);

  let {
    required,
    decimal,
    step,
    allowLeadingZero,
    onKeyPress,
    onFocus,
    preventPaste = false,
    ..._props
  } = {
    ...DEFAULT_PROPS,
    className: "",
    ...props,
  };
  const { max, min } = _props;

  _props = omit(_props, ["defaultValue", "value"]);

  const regEx = useMemo(() => {
    return getRegEx(decimal, min, allowLeadingZero);
  }, [decimal, min, allowLeadingZero]);

  const _onFocus = useCallback(
    e => {
      //console.log("IFXNumberTextBox2 onFocus");
      onFocus && onFocus(e);
      combinedRef.current.select();
    },
    [onFocus]
  );

  const _onKeyPress = e => {
    const _value = combinedRef.current.value;
    const caratPos = getSelectionStart(e.currentTarget);
    const selectionEnd = e.currentTarget.selectionEnd;

    if (e.key !== "Enter") {
      const _newValue = getValueString(_value, e.key, caratPos, selectionEnd);

      //const _newValue = `${_value}${e.key}`;
      const floatVal = parseFloat(_newValue);
      if ((!allowLeadingZero || decimal) && zeroRegex.test(_newValue)) {
        e.preventDefault();
        return;
      }
      if (!decimal && floatVal === 0 && /\-/.test(_newValue)) {
        e.preventDefault();
        return;
      }
      if (
        !regEx.test(_newValue) ||
        (max && floatVal > max) ||
        (min && floatVal < min)
      ) {
        e.preventDefault();
        return;
      }
    }
    onKeyPress && onKeyPress(e);
  };

  const _onPaste = useCallback(
    e => {
      if (preventPaste) return e.preventDefault();

      let clipboardData =
        e.clipboardData ||
        e.originalEvent.clipboardData ||
        window.clipboardData;
      let pastedData = clipboardData.getData("text");
      //console.log("_onPaste pastedData::" + pastedData);

      if ((!allowLeadingZero || decimal) && zeroRegex.test(pastedData)) {
        return e.preventDefault();
      }
      const floatVal = parseFloat(pastedData);
      if (!decimal && floatVal === 0 && /\-/.test(pastedData)) {
        return e.preventDefault();
      }
      if (
        !regEx.test(pastedData) ||
        (max && floatVal > max) ||
        (min && floatVal < min)
      ) {
        return e.preventDefault();
      }
    },
    [preventPaste, allowLeadingZero, decimal, max, min, regEx]
  );

  return (
    <Form.Control
      {...{ className: "" }}
      {...(("value" in props && { value: props.value }) || {
        defaultValue: props.defaultValue,
      })}
      {..._props}
      className={cx(DEFAULT_PROPS.className, props.className, {
        "field-mandatory": required,
      })}
      onKeyPress={_onKeyPress}
      onPaste={_onPaste}
      onFocus={_onFocus}
      ref={combinedRef}
    />
  );
});
