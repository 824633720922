import React from "react";
import { Card, FormGroup, Container, Row, Col } from "react-bootstrap";
import {
  IFXIcons,
  IconStyleOptions,
  ColorOptions,
  IFXSVGTemplates,
} from "../../../components/AppComponents";

const ICON_CHAR_LIST = ["E", "B", "P", "C", "U"];
const ICON_LIST = [
  "sync-alt",
  "edit",
  "user-lock",
  "trash",
  ["far", "trash-alt"],
  ["far", "save"],
  ["far", "clone"],
  "home",
  "cog",
  "spinner",
  "square",
  "check-square",
  "check-circle",
  "angle-left",
  "angle-right",
  "angle-up",
  "angle-down",
  "archive",
  "calendar-alt",
  "user-cog",
  "arrow-alt-circle-down",
  "arrow-alt-circle-left",
  "arrow-alt-circle-right",
  "arrow-alt-circle-up",
  "arrow-circle-down",
  "arrow-circle-left",
  "arrow-circle-right",
  "arrow-circle-up",
  "sign-out-alt",
  "file-csv",
  "file-excel",
  "file-pdf",
  "file-word",
  "times",
  "exclamation",
  "search",
  "search-plus",
  "search-minus",
  "plus",
  "download",
  "file-download",
  "ban",
  "binoculars",
  "image",
  "images",
];

export const IconsCard = () => {
  return (
    <Card className="main-card mb-3">
      <Card.Body>
        <Card.Title>Icons</Card.Title>
        <FormGroup>
          <Container>
            <Row>
              <Col>
                {ICON_LIST.map(i => (
                  <div key={i} className="m-1 d-inline">
                    <IFXIcons icon={i} />
                  </div>
                ))}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <h6>Icon Square Border:</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                {ICON_LIST.map(i => (
                  <div key={i} className="d-inline-block">
                    <IFXIcons
                      icon={i}
                      iconStyle={IconStyleOptions.ROUNDED_SQUARE}
                      border={true}
                      borderColor={ColorOptions.black}
                    />
                  </div>
                ))}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <h6>Icon Circle Border:</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                {ICON_LIST.map(i => (
                  <div key={i} className="d-inline-block">
                    <IFXIcons
                      icon={i}
                      iconStyle={IconStyleOptions.CIRCLE}
                      border={true}
                      borderColor={ColorOptions.black}
                    />
                  </div>
                ))}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <h6>Icon Circle Character:</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                {ICON_CHAR_LIST.map(i => (
                  <div key={i} className="d-inline-block">
                    <IFXIcons
                      character={i}
                      iconStyle={IconStyleOptions.CIRCLE}
                      border={true}
                      borderColor={ColorOptions.black}
                    />
                  </div>
                ))}
              </Col>
            </Row>
            <Row>
              <Col>
                {ICON_CHAR_LIST.map(i => (
                  <div key={i} className="d-inline-block">
                    <IFXIcons
                      character={i}
                      iconStyle={IconStyleOptions.CIRCLE}
                      border={true}
                      borderColor={ColorOptions.green}
                      color={ColorOptions.green}
                    />
                  </div>
                ))}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <h6>
                  Icon color choice combo - border , background, color(font):
                </h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-inline-block">
                  <IFXIcons
                    character="T"
                    iconStyle={IconStyleOptions.ROUNDED_SQUARE}
                    border={true}
                    borderColor={ColorOptions.green}
                    color={ColorOptions.green}
                  />
                </div>
                <div className="d-inline-block">
                  <IFXIcons
                    character="T"
                    iconStyle={IconStyleOptions.CIRCLE}
                    border={true}
                    borderColor={ColorOptions.green}
                    color={ColorOptions.danger}
                  />
                </div>
                <div className="d-inline-block">
                  <IFXIcons
                    character="T"
                    iconStyle={IconStyleOptions.ROUNDED_SQUARE}
                    backgroundColor={ColorOptions.primary}
                    color={ColorOptions.white}
                  />
                </div>
                <div className="d-inline-block">
                  <IFXIcons
                    character="T"
                    iconStyle={IconStyleOptions.ROUNDED_SQUARE}
                    backgroundColor={ColorOptions.yellow}
                    border={true}
                    borderColor={ColorOptions.danger}
                    color={ColorOptions.danger}
                  />
                </div>
                <div className="d-inline-block">
                  <IFXIcons
                    character="T"
                    iconStyle={IconStyleOptions.CIRCLE}
                    backgroundColor={ColorOptions.yellow}
                    border={true}
                    borderColor={ColorOptions.danger}
                    color={ColorOptions.danger}
                  />
                </div>
                <div className="d-inline-block">
                  <IFXIcons
                    character="T"
                    iconStyle={IconStyleOptions.CIRCLE}
                    backgroundColor={ColorOptions.yellow}
                    border={true}
                    borderThin={true}
                    borderColor={ColorOptions.danger}
                    color={ColorOptions.danger}
                  />
                </div>
                <div className="d-inline-block">
                  <IFXIcons
                    character="T"
                    iconStyle={IconStyleOptions.ROUNDED_SQUARE}
                    color={ColorOptions.danger}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-inline-block">
                  <IFXIcons
                    icon="edit"
                    iconStyle={IconStyleOptions.ROUNDED_SQUARE}
                    border={true}
                    borderColor={ColorOptions.green}
                    color={ColorOptions.green}
                  />
                </div>
                <div className="d-inline-block">
                  <IFXIcons
                    icon="edit"
                    iconStyle={IconStyleOptions.CIRCLE}
                    border={true}
                    borderColor={ColorOptions.green}
                    color={ColorOptions.danger}
                  />
                </div>
                <div className="d-inline-block">
                  <IFXIcons
                    icon="edit"
                    iconStyle={IconStyleOptions.ROUNDED_SQUARE}
                    backgroundColor={ColorOptions.primary}
                    color={ColorOptions.white}
                  />
                </div>
                <div className="d-inline-block">
                  <IFXIcons
                    icon="edit"
                    iconStyle={IconStyleOptions.ROUNDED_SQUARE}
                    backgroundColor={ColorOptions.yellow}
                    border={true}
                    borderColor={ColorOptions.danger}
                    color={ColorOptions.danger}
                  />
                </div>
                <div className="d-inline-block">
                  <IFXIcons
                    icon="edit"
                    iconStyle={IconStyleOptions.CIRCLE}
                    backgroundColor={ColorOptions.yellow}
                    border={true}
                    borderColor={ColorOptions.danger}
                    color={ColorOptions.danger}
                  />
                </div>
                <div className="d-inline-block">
                  <IFXIcons
                    icon="edit"
                    iconStyle={IconStyleOptions.CIRCLE}
                    backgroundColor={ColorOptions.yellow}
                    border={true}
                    borderThin={true}
                    borderColor={ColorOptions.danger}
                    color={ColorOptions.danger}
                  />
                </div>
                <div className="d-inline-block">
                  <IFXIcons
                    icon="edit"
                    iconStyle={IconStyleOptions.ROUNDED_SQUARE}
                    color={ColorOptions.danger}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {Object.values(ColorOptions).map(i => {
                  let color = i;
                  if (i === "white" || i === "transparent") color = "black";
                  return (
                    <div key={i} className="d-inline-block">
                      <IFXIcons
                        title={i}
                        character="D"
                        iconStyle={IconStyleOptions.CIRCLE}
                        border={true}
                        borderColor={color}
                        color={color}
                      />
                    </div>
                  );
                })}
              </Col>
            </Row>
            <Row>
              <Col>
                {Object.values(ColorOptions).map(i => {
                  let color = i;
                  if (i === "white" || i === "transparent") color = "black";
                  return (
                    <div key={i} className="d-inline-block">
                      <IFXIcons
                        title={i}
                        icon="sync-alt"
                        iconStyle={IconStyleOptions.CIRCLE}
                        border={true}
                        borderColor={color}
                        color={color}
                      />
                    </div>
                  );
                })}
              </Col>
            </Row>
            <Row>
              <Col>
                {Object.values(ColorOptions).map(i => {
                  let color = i,
                    borderColor = i;
                  if (i === "warning" || i === "transparent") color = "black";
                  if (i === "white") {
                    borderColor = "warning";
                  }
                  return (
                    <div key={i} className="d-inline-block">
                      <IFXIcons
                        title={i}
                        icon="times"
                        iconStyle={IconStyleOptions.CIRCLE}
                        border={true}
                        borderColor={borderColor}
                        backgroundColor={ColorOptions.warning}
                        color={color}
                      />
                    </div>
                  );
                })}
              </Col>
            </Row>
            <Row>
              <Col>
                {Object.values(ColorOptions).map(i => {
                  let color = i,
                    borderColor = i;
                  if (i === "primary" || i === "transparent") color = "white";
                  if (i === "white") {
                    borderColor = "primary";
                  }
                  return (
                    <div key={i} className="d-inline-block">
                      <IFXIcons
                        title={i}
                        icon="sync-alt"
                        iconStyle={IconStyleOptions.ROUNDED_SQUARE}
                        border={true}
                        borderColor={borderColor}
                        backgroundColor={ColorOptions.primary}
                        color={color}
                      />
                    </div>
                  );
                })}
              </Col>
            </Row>
            <Row>
              <Col>
                {Object.values(ColorOptions).map(i => {
                  let color = i;
                  if (i === "white" || i === "transparent") {
                    color = "black";
                  }
                  return (
                    <div key={i} className="m-1 d-inline-block">
                      <IFXIcons title={i} icon="sync-alt" color={color} />
                    </div>
                  );
                })}
              </Col>
            </Row>
            <Row>
              <Col>
                {ICON_LIST.map(i => (
                  <div key={i} className="d-inline-block">
                    <IFXIcons
                      icon={i}
                      iconStyle={IconStyleOptions.ROUNDED_SQUARE}
                      border={true}
                      borderColor={ColorOptions.primary}
                      color={ColorOptions.primary}
                    />
                  </div>
                ))}
              </Col>
            </Row>
            <Row>
              <Col>
                {ICON_LIST.map(i => (
                  <div key={i} className="d-inline-block">
                    <IFXIcons
                      icon={i}
                      iconStyle={IconStyleOptions.ROUNDED_SQUARE}
                      border={true}
                      borderThin={true}
                      borderColor={ColorOptions.danger}
                      color={ColorOptions.danger}
                    />
                  </div>
                ))}
              </Col>
            </Row>
            <Row>
              <Col>
                {ICON_LIST.map(i => (
                  <div key={i} className="d-inline-block">
                    <IFXIcons
                      icon={i}
                      iconStyle={IconStyleOptions.ROUNDED_SQUARE}
                      border={true}
                      borderColor={ColorOptions.danger}
                      backgroundColor={ColorOptions.yellow}
                    />
                  </div>
                ))}
              </Col>
            </Row>
            <Row>
              <Col>
                {Object.keys(IFXSVGTemplates).map(i => (
                  <div key={i} className="d-inline-block">
                    <div className="m-1 d-inline-block">
                      <IFXIcons
                        iconSource="svg"
                        icon={i}
                        color={ColorOptions.info}
                      />
                    </div>
                    <div className="d-inline-block">
                      <IFXIcons
                        icon={i}
                        iconSource="svg"
                        iconStyle={IconStyleOptions.ROUNDED_SQUARE}
                        border={true}
                        borderColor={ColorOptions.info}
                        color={ColorOptions.danger}
                        backgroundColor={ColorOptions.transparent}
                      />
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="m-2 d-inline-block">
                  <IFXIcons
                    iconSource="svg"
                    icon="bulb"
                    color={ColorOptions.ifxBrand}
                  />
                </div>
                <div className="m-2 d-inline-block">
                  <IFXIcons
                    iconSource="svg"
                    icon="bulb"
                    color={ColorOptions.ifxBrand}
                    size="xs"
                  />
                </div>
                <div className="m-2 d-inline-block">
                  <IFXIcons
                    iconSource="svg"
                    icon="bulb"
                    color={ColorOptions.ifxBrand}
                    size="sm"
                  />
                </div>
                <div className="m-2 d-inline-block">
                  <IFXIcons
                    iconSource="svg"
                    icon="bulb"
                    color={ColorOptions.ifxBrand}
                    size="md"
                  />
                </div>
                <div className="m-2 d-inline-block">
                  <IFXIcons
                    iconSource="svg"
                    icon="bulb"
                    color={ColorOptions.ifxBrand}
                    size="lg"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {Object.values(ColorOptions)
                  .filter(c => {
                    return c !== "transparent";
                  })
                  .map((color, i) => {
                    return (
                      <div key={i} className="m-2 d-inline-block">
                        <IFXIcons
                          key={color}
                          iconSource="svg"
                          icon="bulb"
                          className="animate-flash"
                          color={color}
                          size="lg"
                        />
                      </div>
                    );
                  })}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="m-2 d-inline-block">
                  <IFXIcons
                    iconSource="svg"
                    icon="bulb"
                    className="animate-flash"
                    size="lg"
                    color="#e4424e"
                  />
                </div>
                <div
                  className="m-2 d-inline-block"
                  style={{ color: "#e4424e" }}
                >
                  <IFXIcons
                    iconSource="svg"
                    icon="bulb"
                    className="animate-flash"
                    size="lg"
                  />
                </div>
                <div className="m-2 d-inline-block">
                  <IFXIcons
                    iconSource="svg"
                    icon="bulb"
                    className="animate-flash icon-color-ifx-light"
                    size="lg"
                  />
                </div>
                <div className="m-2 d-inline-block">
                  <IFXIcons
                    iconSource="svg"
                    icon="bulb"
                    className="animate-flash manual-approval"
                    size="lg"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </Card.Body>
    </Card>
  );
};
