import React, { memo, useMemo, useState } from "react";
import {
  IFXButton,
  ifxJsonAjax,
  IFXModal,
  IFXModalBody,
  IFXModalFooter,
  IFXModalHeader,
  IFXPrimaryIconButton,
  isEmptyOrNull,
  useObservableRedux,
} from "@ifx-react/ifx-react-core";
import { IFXLoader } from "../../components/common/IFXLoader";
import {
  GET_BUILD_INFO_URL,
  GET_GP_BUILD_INFO_URL,
} from "../../const/common/endpoints";
import { BuildInfoActions } from "../../store/actions/common/actionCreators";
import { catchError, map } from "rxjs/operators";
import { Col, Container, Row } from "react-bootstrap";
import { useUserDateFormat } from "../../hooks/common/useUserDateFormat";
import { useSelector } from "react-redux";
//import { useRouteMatch } from "react-router-dom";

const buildInfoObsv$ = ifxJsonAjax
  .get(`${GET_BUILD_INFO_URL}?preventCache=${new Date().getTime()}`)
  .pipe(
    map(xhrResponse => {
      return xhrResponse.response;
    }),
    catchError(error => {
      console.error("IFX BuildInfo API Error:", error);
      return [];
    })
  );

const gpBuildInfoObsv$ = ifxJsonAjax
  .get(`${GET_GP_BUILD_INFO_URL}?preventCache=${new Date().getTime()}`)
  .pipe(
    map(xhrResponse => {
      return xhrResponse.response;
    }),
    catchError(error => {
      console.error("GP BuildInfo API Error:", error);
      return [];
    })
  );

export const BuildInfo = memo(() => {
  const [buildPopup, setBuildPopup] = useState(null);
  const buildInfo = useMemo(
    () => ({
      show: () => setBuildPopup(true),
      hide: () => setBuildPopup(false),
    }),
    []
  );

  return (
    <>
      <div
        className="position-absolute build-info-button"
        style={{ bottom: 0, right: 0 }}
      >
        <IFXPrimaryIconButton
          name="Build Info"
          icon="info"
          size="xxs"
          className="m-1"
          onClick={buildInfo.show}
        />
      </div>
      {buildPopup && <BuildInfoPopup onHide={buildInfo.hide} />}
    </>
  );
});

const BuildInfoPopup = memo(({ onHide }) => {
  const buildInfo = useObservableRedux(
    buildInfoObsv$,
    BuildInfoActions.PUT_IFX_RESPONSE,
    state => state.CommonReducer.BuildInfoReducer?.ifx || null
  );

  const gpBuildInfo = useObservableRedux(
    gpBuildInfoObsv$,
    BuildInfoActions.PUT_GP_RESPONSE,
    state => state.CommonReducer.BuildInfoReducer?.gp || null
  );

  return (
    <IFXModal show onHide={onHide} className="build-info-modal">
      {isEmptyOrNull(buildInfo) && <IFXLoader enabled />}
      <IFXModalHeader>Build Info</IFXModalHeader>
      <IFXModalBody disableBackground style={{ minHeight: 50 }}>
        {buildInfo && <BuildInfoContent {...buildInfo} />}
        {gpBuildInfo && <GpBuildInfoContent {...gpBuildInfo} />}
      </IFXModalBody>
      <IFXModalFooter>
        <IFXButton type="close" onClick={onHide} />
      </IFXModalFooter>
    </IFXModal>
  );
});

const BuildInfoContent = memo(
  ({
    apiBuildVersion,
    apiBuildTimestamp,
    apiBuildProfile,
    apiBuildGitBranch,
    apiBuildGitRevision,
    apiPortalInstance,
  }) => {
    const { toLocaleDateTimeMoment } = useUserDateFormat();
    const { dateFormat: userDateFormat } = useSelector(
      state => state.PortalReducer.UserContextReducer.userInfo
    );
    const uiBuild = window.appConfig["ui-build"] || undefined;
    const apiBuildTimestampFormatted = toLocaleDateTimeMoment(
      apiBuildTimestamp,
      !userDateFormat
        ? {
            format: "DD MMM YYYY HH:mm:ss z",
            timeZone: "UTC",
          }
        : undefined
    );
    const uiBuildTimestampFormatted =
      (uiBuild &&
        toLocaleDateTimeMoment(
          uiBuild.date,
          !userDateFormat
            ? {
                format: "DD MMM YYYY HH:mm:ss z",
                timeZone: "UTC",
              }
            : undefined
        )) ||
      "";

    return (
      <Container className="table-body">
        {uiBuild && apiBuildGitRevision !== uiBuild["git-revision"] ? (
          <>
            <BuildInfoEntry label="Version" value={apiBuildVersion} />
            <BuildInfoEntry label="API Profile" value={apiBuildProfile} />
            <BuildInfoEntry
              label="API Build Timestamp"
              value={apiBuildTimestampFormatted}
            />
            <BuildInfoEntry label="API Branch" value={apiBuildGitBranch} />
            <BuildInfoEntry label="API Revision" value={apiBuildGitRevision} />
            <BuildInfoEntry label="Portal Instance" value={apiPortalInstance} />
            {/* <BuildInfoEntry label="UI Version" value={uiBuild["pom-version"]} /> */}
            <BuildInfoEntry label="UI Profile" value={uiBuild["profile"]} />
            <BuildInfoEntry
              label="UI Build Timestamp"
              value={uiBuildTimestampFormatted}
            />

            <BuildInfoEntry label="UI Branch" value={uiBuild["git-branch"]} />
            <BuildInfoEntry
              label="UI Revision"
              value={uiBuild["git-revision"]}
            />
          </>
        ) : (
          <>
            <BuildInfoEntry label="Version" value={apiBuildVersion} />
            <BuildInfoEntry label="Profile" value={apiBuildProfile} />
            <BuildInfoEntry
              label="Build Timestamp"
              value={apiBuildTimestampFormatted}
            />
            <BuildInfoEntry label="Branch" value={apiBuildGitBranch} />
            <BuildInfoEntry label="Revision" value={apiBuildGitRevision} />
            <BuildInfoEntry label="Portal Instance" value={apiPortalInstance} />
          </>
        )}
      </Container>
    );
  }
);

const BuildInfoEntry = memo(({ label, value }) => (
  <Row>
    <Col xs={5}>
      <span className="table-text" style={{ fontWeight: 500 }}>
        {label}:
      </span>
    </Col>
    <Col>
      <span className="table-text">{value}</span>
    </Col>
  </Row>
));

const GpBuildInfoContent = memo(
  ({ gpBuildGitBranch, gpBuildGitRevision, gpBuildTimestamp }) => {
    const { toLocaleDateTimeMoment } = useUserDateFormat();
    const { dateFormat: userDateFormat } = useSelector(
      state => state.PortalReducer.UserContextReducer.userInfo
    );

    const gpBuildTimestampFormatted = toLocaleDateTimeMoment(
      gpBuildTimestamp,
      !userDateFormat
        ? {
            format: "DD MMM YYYY HH:mm:ss z",
            timeZone: "UTC",
          }
        : undefined
    );

    return (
      <>
        <Container className="table-header">
          <Row>
            <Col>
              <span
                className="table-text"
                style={{ fontWeight: 500, width: "100%" }}
              >
                Galleys Build Info
              </span>
            </Col>
          </Row>
        </Container>
        <Container className="table-body">
          <BuildInfoEntry
            label="Build Timestamp"
            value={gpBuildTimestampFormatted}
          />
          <BuildInfoEntry label="Branch" value={gpBuildGitBranch} />
          <BuildInfoEntry label="Revision" value={gpBuildGitRevision} />
        </Container>
      </>
    );
  }
);
