import React from "react";
import { Nav, Dropdown } from "react-bootstrap";
import { IFXIcons } from "@ifx-react/ifx-react-core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const UserBox = React.memo(() => {
  const userInfo = useSelector(
    state => state.PortalReducer.UserContextReducer.userInfo
  );

  return (
    <div className="header-btn-lg pr-0">
      <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <div className="widget-content-right">
            <div className="user-box-wrapper">
              <Dropdown>
                <Dropdown.Toggle
                  variant="link"
                  className="p-0"
                  bsPrefix={"ifx-dropdown-menu"}
                >
                  <div className="user-box-btn">
                    <div className="user-icon rounded-circle"></div>
                    <IFXIcons
                      className="ml-2 opacity-8 align-middle"
                      size="xs"
                      icon="angle-down"
                    />
                    <div className="ml-2 user-info">
                      <span className="user-fullname">
                        {userInfo.firstName} {userInfo.lastName}
                      </span>
                      <span className="user-role">{userInfo.jobTitle}</span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  popperConfig={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 0],
                        },
                      },
                    ],
                  }}
                  className="rm-pointers dropdown-menu-sm header-dropdown-menu"
                >
                  <div className="header-dropdown-menu-shadow" />
                  <Nav className="flex-column">
                    <UserBoxNavItem
                      icon="user-cog"
                      title="User Preference"
                      linkTo="/app/portal/user/editProfile"
                    />
                    <UserBoxNavItem
                      icon="sign-out-alt"
                      title="Logout"
                      linkTo="/logout"
                    />
                  </Nav>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const UserBoxNavItem = React.memo(({ icon, title, linkTo }) => (
  <Link to={linkTo}>
    <Nav.Item>
      <div className="nav-link">
        <IFXIcons icon={icon} />
        &nbsp;{title}
      </div>
    </Nav.Item>
  </Link>
));
