import initialState from "./initialState";

export function CrwmlAssignmentReducer(
  state = initialState.crwmlAssignmentParams,
  action
) {
  switch (action.type) {
    case "CRWML_ASSIGNMENT_REQUEST":
      return  action.payload;
    
    default:
      return state;
  }
}