import initialState from "./initialState";

export function MaintainRuleSetReducer(
  state = initialState.ruleSetParams,
  action
) {
  switch (action.type) {
    case "RULE_GROUP_REQUEST":
      return {
        ...state,
        ruleSetCategoryData: action.payload,
      };
    case "RULE_REQUEST":
      return {
        ...state,
        ruleGroupInfo: action.payload,
      };

    default:
      return state;
  }
}
