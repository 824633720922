import React from "react";

export const IFXTextBoxReadOnly = ({
  isAutosuggest = false,
  value,
  ...props
}) => {
  const _props = {
    ...props,
    className:
      "form-control form-control-sm ifx-text-overflow disabled " +
      props.className,
  };
  return (
    <>
      <div {..._props}>{(isAutosuggest ? value?.value : value) || ""}</div>
    </>
  );
};
