import React from "react";
import { Alert, Fade, Collapse, Col, Container } from "react-bootstrap";
import cx from "classnames";
import { IFXIcons } from "./IFXIcons";

export const IFXAlert = ({
  show = true,
  noBackground = true,
  noBorder = true,
  marginClassName = "mt-1 mb-0",
  paddingClassName = "",
  className,
  contentJustifyClassName = "justify-content-center",
  children,
  listUnstyled = false,
  animate = true,
  ...props
}) => {
  const ifxAlertComp = (
    <Alert
      className={cx(
        "ifx-alert",
        paddingClassName,
        marginClassName,
        noBackground && "no-background",
        noBorder && "no-border",
        listUnstyled && "list-unstyled",
        className
      )}
      transition={null}
      {...props}
    >
      <div className={`d-flex ${contentJustifyClassName}`}>{children}</div>
    </Alert>
  );
  return (
    (animate && (
      <Fade in={show} appear>
        <div>
          <Collapse in={show} appear>
            {ifxAlertComp}
          </Collapse>
        </div>
      </Fade>
    )) ||
    (show && ifxAlertComp) ||
    null
  );
};

export const IFXErrorAlert = ({ hideIcon, ...props }) => (
  <IFXAlert variant="danger" {...props}>
    {!hideIcon && <IFXIcons iconSource="svg" icon="error" size="lg" />}
    <Col xs="auto my-auto">{props.children}</Col>
  </IFXAlert>
);

export const IFXInfoAlert = props => (
  <IFXAlert variant="ifx-brand" {...props} />
);

export const IFXUserNote = props => (
  <IFXAlert
    variant="ifx-brand"
    noBackground={false}
    noBorder={false}
    animate={false}
    marginClassName="mt-3 mb-0"
    paddingClassName="p-3"
    contentJustifyClassName=""
    {...props}
  />
);
