import { ajax } from "rxjs/ajax";
import {
  DEFAULT_AJAX_JSON_SETTINGS,
  DEFAULT_AJAX_FORM_SETTINGS,
  DEFAULT_AJAX_STREAM_SETTINGS,
  DEFAULT_AJAX_EXCEL_SETTINGS,
  /* DEFAULT_AJAX_PDF_SETTINGS, */
} from "../const";

import * as queryString from "query-string";

export { queryString };
//This is for content-type JSON
export const ifxJsonAjax = {
  get: (url, headers = DEFAULT_AJAX_JSON_SETTINGS.headers, settings = {}) =>
    ajax({
      ...DEFAULT_AJAX_JSON_SETTINGS,
      method: "GET",
      url,
      headers,
      ...settings,
    }),
  post: (
    url,
    body,
    headers = DEFAULT_AJAX_JSON_SETTINGS.headers,
    settings = {}
  ) => ajax({ ...DEFAULT_AJAX_JSON_SETTINGS, url, body, headers, ...settings }),
  delete: (url, headers = DEFAULT_AJAX_JSON_SETTINGS.headers) =>
    ajax({ ...DEFAULT_AJAX_JSON_SETTINGS, method: "DELETE", url, headers }),
};

//This is for form URL encoded
export const ifxFormAjax = {
  get: (url, headers = DEFAULT_AJAX_FORM_SETTINGS.headers, settings = {}) =>
    ajax({
      ...DEFAULT_AJAX_FORM_SETTINGS,
      method: "GET",
      url,
      headers,
      ...settings,
    }),
  post: (
    url,
    body,
    headers = DEFAULT_AJAX_FORM_SETTINGS.headers,
    settings = {}
  ) => ajax({ ...DEFAULT_AJAX_FORM_SETTINGS, url, body, headers, ...settings }),
  delete: (url, headers = DEFAULT_AJAX_FORM_SETTINGS.headers) =>
    ajax({ ...DEFAULT_AJAX_FORM_SETTINGS, method: "DELETE", url, headers }),
};

//This is for accept OCTET-STREAM
export const ifxOctetStreamAjax = {
  get: (url, headers = DEFAULT_AJAX_STREAM_SETTINGS.headers) =>
    ajax({ ...DEFAULT_AJAX_STREAM_SETTINGS, method: "GET", url, headers }),
  post: (url, body, headers = DEFAULT_AJAX_STREAM_SETTINGS.headers) =>
    ajax({ ...DEFAULT_AJAX_STREAM_SETTINGS, url, body, headers }),
};

//This is for accept MS-EXCEL
export const ifxExcelAjax = {
  get: (url, headers = DEFAULT_AJAX_EXCEL_SETTINGS.headers) =>
    ajax({ ...DEFAULT_AJAX_EXCEL_SETTINGS, method: "GET", url, headers }),
  post: (url, body, headers = DEFAULT_AJAX_EXCEL_SETTINGS.headers) =>
    ajax({ ...DEFAULT_AJAX_EXCEL_SETTINGS, url, body, headers }),
};

/* //This is for accept PDF
export const ifxPDFAjax = {
  get: (url, headers = DEFAULT_AJAX_PDF_SETTINGS.headers) =>
    ajax({ ...DEFAULT_AJAX_PDF_SETTINGS, method: "GET", url, headers }),
  post: (url, body, headers = DEFAULT_AJAX_PDF_SETTINGS.headers) =>
    ajax({ ...DEFAULT_AJAX_PDF_SETTINGS, url, body, headers }),
}; */