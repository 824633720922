import { mergeMap, catchError, tap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { GET_HELP_LINK } from "../../../const/common/endpoints";
import { LoadHelpLink } from "../../actions/common/actionCreators";
import { AJAX_HEADER_JSON } from "../../../const/common/ajaxConsts";

export const HelpLinkEpic = action$ =>
  action$.pipe(
    ofType(LoadHelpLink.REQUEST().type),
    mergeMap(({ payload: { carrierId = null , userType } }) =>
      ajax
        .post(
          GET_HELP_LINK,
          {
            carrierId,
            userType
          },
          {
            ...AJAX_HEADER_JSON,
          }
        )
        .pipe(
          mergeMap(xhrResponse => {
            return of(LoadHelpLink.SUCCESS(xhrResponse.response));
          }),
          catchError(error => {
            console.error(error);
            return of(LoadHelpLink.FAILURE());
          })
        )
    )
  );
