import { format, subDays } from "date-fns";

export default {
  DetailedInvoiceFilteredInfo: {
    caterer: [],
    serviceDate: {
      from: {
        serialized: format(subDays(new Date(), 15), "MM/dd/yyyy"),
        dateValue: new Date(),
      },
      to: {
        serialized: format(new Date(), "MM/dd/yyyy"),
        dateValue: new Date(),
      },
    },
    invoiceStatusName: [],
    cateringStation: [],
    invoiceDate: {},
    invTags: [],
    invTagsDD: [
      {
        label: "Any",
        value: "9186",
      },
    ],
    deptStation: [],
    flightNo: { fromFltNo: "", toFltNo: "" },
    errCodeChkBox: false,
    invType: [],
    invNos: [],
    invErrCodes: [],
    invErrCodesDD: [
      {
        label: "Any",
        value: 9186,
      },
    ],
    fltStatus: [],
    invPaymentStatus: [],
    service: [],
    invServiceDD: [
      {
        label: "Categories",
        value: 9000,
      },
    ],
    flight: [],
    flightDD: [
      {
        label: "Tag",
        value: 9010,
      },
    ],
    invReInState: false,
    sortBy: [
      [{ label: "Invoice #", value: 9189 }],
      [{ label: "Station", value: 9190 }],
      [{ label: "Service Date", value: 9191 }],
      [{ label: "Flight #", value: 9192 }],
    ],
  },
  InvoiceStatusReducer: {},

  StationInvoiceSearchInfo: {
    stationType: "initialState",
  },

  InvPendingRevAndBrowseInvPeriodsSearchInfo: {
    searchType: "initialState",
    invPendingReview: false,
  },

  SearchInvoiceInfo: {
    searchType: "initialState",
  },

  InvoicingPeriodInfo: {
    searchType: "initialState",
  },

  RecentInvoiceInfo: {
    searchType: "initialState",
  },

  RecurrenceTemplateInfo: {
    catererCode: [],
    dateRange: {
      from: null,
      to: null,
    },
    stationCode: [],
    sortByInvoice: "Invoice #",
  },

  SearchRulesInfo: {
    activeEnabledChk: 1,
    ruleCode: "",
    ruleName: "",
    ruleDescription: "",
    ruleCategory: [],
    ruleGroup: [],
    flightNo: "",
    specClassObj: [],
    originStn: [],
    destinationStn: [],
    invTagsDD: "Any",
    invErrCodesDD: "Any",
    servicesValues: "Category",
    invTags: [],
    invErrCodes: [],
    service: [],
    ruleDateRange: {
      from: null,
      to: null,
    },
    sortBy: [
      [{ label: "Rule Category", value: 9083 }],
      [{ label: "Rule Group", value: 9084 }],
      [{ label: "Rule Priority", value: 9085 }],
      [{ label: "Eff Date", value: 9086 }],
      [{ label: "Exp Date", value: 9087 }],
    ],
  },
  RapRequestInfo: {
    caterer: [],
    dateRange: {
      from: {
        serialized: format(subDays(new Date(), 30), "MM/dd/yyyy"),
        dateValue: new Date(),
      },
      to: {
        serialized: format(new Date(), "MM/dd/yyyy"),
        dateValue: new Date(),
      },
    },
    station: [],
    cateringStation: [],
    priceGroup: [],
    serviceItems: [],
  },
  SearchRemittanceInfo: {
    checkNumber: "",
    checkDate: "",
    cateringStation: [],
    station: [],
    invoiceNumber: [],
    carrierSummaryInvNo: "",
    airlineRefInvNo: "",
    invoiceType: [],
    remittanceRefId: "",
    remittanceStatus: [],
    pmtReqRef: "",
    pmntReqStatus: [],
    currency: [],
    paymentAmnt: "",
    remittanceDateRange: {
      from: null,
      to: null,
    },
    flightRange: {
      from: "",
      to: "",
    },
  },
  ErrorCodesInfo: {
    errorRejectionCode: "",
    errorRejectionDesc: "",
  },
};
