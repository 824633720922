import initialState from "./initialState";

export function PrgReducer(
  state = initialState.prgFormValues,
  action
) {
  switch (action.type) {
    case "PRG_FORM_DATA_REQUEST":
      return action.payload;
    default:
      return state;
  }
}