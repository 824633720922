/* Spec Common */
export const FETCH_USER_PRICEGROUPS_URL =
  "/ifx/api/esm/common/getUserPriceGroups.do";

/* Search Spec */
export const SEARCH_SPEC_URL = "/ifx/api/esm/spec/search.do";
export const BULK_SPEC_URL = "/ifx/api/esm/spec/bulkOperation.do";
export const DOWNLOAD_SPEC_URL = "/ifx/api/esm/spec/download.do";
export const OBSOLETE_SPEC_URL = "/ifx/api/esm/spec/obsolete.do";
export const CLONE_SPEC_URL = "/ifx/api/esm/spec/copy.do";
export const UPLOAD_SERVE_INSTRS_URL = "/ifx/api/esm/spec/uploadServeInstrs.do";
export const UPLOAD_SPEC_URL = "/ifx/api/esm/spec/uploadSpec.do";
export const DOWNLOAD_TEMPLATE_URL = "/ifx/api/esm/spec/downloadTemplate.do";
export const SET_COMP_PARAMS_URL = "/ifx/api/esm/spec/setCompParams.do";
export const GET_IMAGE_URL = "/ifx/api/esm/spec/getImage.do";

export const DOWNLOAD_SPEC_DETAIL_URL = "/ifx/api/esm/spec/downloadSpecDetail.do";
// "/ifx/api/esm/spec/download2.do"; 
// "/ifx/api/esm/spec/downloadSpecDetail.do";

/* Spec Lookup */
export const ADD_EDIT_SPEC_URL = "/ifx/api/esm/spec/addOrUpdate.do";
export const GET_DEFAULT_VALUES_FOR_COMPONENT =
  "/ifx/api/esm/spec/defaultValuesForComponent.do";
export const GET_VERSION_LOOKUP_FOR_COMPONENT =
  "/ifx/api/esm/spec/specVersionLookup.do";

/* Spec Print */
export const DOWNLOAD_SPEC_REPORT_URL = "/ifx/api/esm/spec/printSpec.do";
export const DOWNLOAD_SPEC_PRICE_REPORT_URL =
  "/ifx/api/esm/spec/printSpecPrice.do";
export const GET_PRINT_OPTIONS = "/ifx/api/esm/spec/getReports.do";

/* Search Spec Price */
export const SEARCH_SPEC_PRICE_URL = "/ifx/api/esm/specPrice/search.do";
export const DOWNLOAD_SPEC_PRICE_URL = "/ifx/api/esm/specPrice/download.do";
export const BULK_SPEC_PRICE_URL = "/ifx/api/esm/specPrice/bulkOperations.do";
export const GET_SPEC_DETAILS_URL =
  "/ifx/api/esm/specPrice/getSpecCodeDetails.do";
export const UPLOAD_SPEC_PRICE_URL = "/ifx/api/esm/specPrice/uploadPrice.do";
export const ADD_SPEC_PRICE_URL = "/ifx/api/esm/specPrice/create.do";
export const GET_PRICE_URL = "/ifx/api/esm/specPrice/exists.do";
export const GET_PRICE_INFO_BY_ID = "/ifx/api/esm/specPrice/priceById.do";
export const UPDATE_SPEC_PRICE_URL = "/ifx/api/esm/specPrice/update.do";
export const LOAD_PRICE_HISTORY_URL = "/ifx/api/esm/specPrice/priceHistory.do";
export const DOWNLOAD_PRICE_HISTORY_URL =
  "/ifx/api/esm/specPrice/downloadPriceHistory.do";
export const DOWNLOAD_ROLLUP_PRICE_URL =
  "/ifx/api/esm/specPrice/downloadRollupPrice.do";
export const ROLL_UP_PRICE_CALC_URL =
  "/ifx/api/esm/specPrice/rollupCalculate.do";
export const GET_ROLL_UP_ERROR = "/ifx/api/esm/specPrice/getRollupError.do";
export const CURRENCY_URL =
  "/ifx/api/autosuggest/specMaintenanceEJBService/currencyName.do?key=*";
export const GET_SPEC_INFO = "/ifx/api/esm/common/getSpecDetails.do";
export const GET_SPEC_WHERE_USED_INFO = "/ifx/api/esm/spec/whereUsed.do";
export const GET_SPEC_USED_FSS_INFO = "/ifx/api/esm/spec/whereUsedFss.do";
export const DOWNLOAD_WHERE_USED_INFO =
  "/ifx/api/esm/spec/whereUsed/download.do";
export const DOWNLOAD_SPEC_TREE = "/ifx/api/esm/spec/downloadSpecTree.do";
export const GET_SPEC_COMPARE_INFO = "/ifx/api/esm/spec/compare.do";

/* Find and Replace Spec */
export const SPEC_GLOBAL_REPLACE_SEARCH_URL =
  "/ifx/api/esm/spec/replace/search.do";
export const SPEC_GLOBAL_REPLACE_GET_COMPONENT_URL =
  "/ifx/api/esm/spec/replace/getComponent.do";
export const SPEC_GLOBAL_REPLACE_URL = "/ifx/api/esm/spec/replace/component.do";

/* Show Spec Version Changes URLs */
export const GET_SPEC_VERSION_DETAILS =
  "/ifx/api/esm/spec/showSpecVersionChanges.do";

/* Spec Tag URLs */
export const GET_SPEC_TAG_CATEGORY =
  "/ifx/api/esm/masterData/tagCategory/search.do";
export const ADD_UPDATE_SPEC_TAG_CATEGORY =
  "/ifx/api/esm/masterData/tagCategory/addOrUpdate.do";
export const DELETE_SPEC_CATEGORY_URL =
  "/ifx/api/esm/masterData/tagCategory/delete.do";
export const GET_CATEORY_BY_ID_URL =
  "/ifx/api/esm/masterData/tagCategory/specCategoryById.do";

export const SEARCH_CONTRACT_PRICE_URL =
  "/ifx/api/esm/pricing/contractPrice/search.do";

export const SEARCH_VALID_COMPONENTS_URL =
  "/ifx/api/esm/spec/searchValidComponents.do";

export const GET_CONTRACT_PRICE_CHILD_INFO_URL =
  "/ifx/api/esm/pricing/contractPrice/childInfo.do";

export const CHANGE_CONTRACT_PRICE_STATUS_URL =
  "/ifx/api/esm/pricing/contractPrice/statusChange.do";

export const DOWNLOAD_CONTRACT_PRICE_URL =
  "/ifx/api/esm/pricing/contractPrice/download.do";

export const ADD_OR_UPDATE_CONTRACT_PRICE_URL =
  "/ifx/api/esm/pricing/contractPrice/addOrUpdate.do";

export const GET_DISTRIBUTOR_NAME_URL =
  "/ifx/api/autosuggest/contractPriceAutoSuggest/Distributor.do";

//Load Scale
export const LOAD_SCALE_SEARCH = "/ifx/api/esm/masterData/loadScale/search.do";

export const GENERATE_LOAD_SCALE_RANGE_OR_DATA_URL =
  "/ifx/api/esm/masterData/loadScale/generateLoadScale.do";
export const ADD_OR_UPDATE_LOAD_SCALE_URL =
  "/ifx/api/esm/masterData/loadScale/addorUpdateLoadScale.do";

export const COPY_LOAD_SCALE_URL =
  "/ifx/api/esm/masterData/loadScale/saveas.do";

export const DELETE_LOAD_SCALE_URL =
  "/ifx/api/esm/masterData/loadScale/delete.do";

export const LOAD_SCALE_BY_VERSION =
  "/ifx/api/esm/masterData/loadScale/getLoadScaleByVersion.do";

export const LOAD_SCALE_PRINT_PREVIEW =
  "/ifx/api/esm/masterData/loadScale/printSpec.do";

export const UPLOAD_LOAD_SCALE =
  "/ifx/api/esm/masterData/loadScale/uploadLoadScale.do";

export const DOWNLOAD_LOADSCALE_INS_TEMPLATE_URL =
  "/ifx/api/esm/masterData/loadScale/downloadLSInstructionTemplate.do";
export const SEARCH_AC_DEPENDENCY_URL =
  "/ifx/api/esm/masterData/acDependency/search.do";
export const DELETE_AC_DEPENDENCY_URL =
  "/ifx/api/esm/masterData/acDependency/delete.do";
export const ADD_OR_UPDATE_AC_DEPENDENCY_URL =
  "/ifx/api/esm/masterData/acDependency/addOrUpdate.do";
export const LOAD_SCALE_WHEREUSED_URL =
  "/ifx/api/esm/masterData/loadScale/whereused.do";
export const LOAD_SCALE_WHEREUSED_DOWNLOAD_URL =
  "/ifx/api/esm/masterData/loadScale/exportwhereused.do";

export const AUDIT_TRAIL = "/ifx/api/esm/spec/audit/search.do";
export const AUDIT_TRAIL_DOWNLOAD = "/ifx/api/esm/spec/audit/download.do";
export const AUDIT_TRAIL_IMAGE = "/ifx/api/esm/spec/audit/specImage.do";
export const SEARCH_PRICE_GROUP_URL = "/ifx/api/esm/price/pricegroup/search.do";
export const GET_PRICE_GROUP_STATION_URL =
  "/ifx/api/esm/price/pricegroup/station.do";
export const GET_CURRENCY_PRICE_GROUP_URL =
  "/ifx/api/esm/price/pricegroup/currency.do";
export const ADD_EDIT_PRICE_GROUP_URL =
  "/ifx/api/esm/price/pricegroup/addUpdate.do";
export const ADD_EDIT_PRICE_GROUP_STATION_URL =
  "/ifx/api/esm/price/pricegroup/addUpdateStation.do";
export const DELETE_PRICE_GROUP_STATION_URL =
  "/ifx/api/esm/price/pricegroup/delete.do";
export const SEARCH_SPEC_TYPE_URL =
  "/ifx/api/esm/administrator/spectype/search.do";
export const EDIT_PRE_SPEC_TYPE_URL =
  "/ifx/api/esm/administrator/spectype/editSpecType.do";
export const SAVE_UPDATE_DEL_SPEC_TYPE_URL =
  "/ifx/api/esm/administrator/spectype/saveUpdateDeleteSpecType.do";

export const GET_ROTATION_TYPE_INFO =
  "/ifx/api/esm/masterData/rotationType/search.do";

export const ADD_ROTATION_TYPE_URL =
  "/ifx/api/esm/masterData/rotationType/addrotationtype.do";

export const EDIT_ROTATION_TYPE_URL =
  "/ifx/api/esm/masterData/rotationType/updaterotationtype.do";

export const DELETE_ROTATION_TYPE_URL =
  "/ifx/api/esm/masterData/rotationType/delete.do";

export const COPY_ROTATION_TYPE_URL =
  "/ifx/api/esm/masterData/rotationType/copy.do";

export const DOWNLOAD_ROTATION_TYPE_URL =
  "/ifx/api/esm/masterData/rotationType/download.do";

export const ROTATION_WHERE_USED_URL =
  "/ifx/api/esm/masterData/rotationType/whereused.do";

export const ROTATION_WHERE_USED_PRINT_URL =
  "/ifx/api/esm/masterData/rotationType/whereusedprint.do";

export const GET_ROTATION_CALENDAR_INFO_URL =
  "/ifx/api/esm/masterData/rotationType/calender/search.do";

export const UPDATE_ROTATION_CALENDAR_INFO =
  "/ifx/api/esm/masterData/rotationType/calender/update.do";
export const SEARCH_PRICE_PARAMETER_URL =
  "/ifx/api/esm/specPrice/priceParameters/search.do";
export const DOWNLOAD_PRICE_PARAMETERS =
  "/ifx/api/esm/specPrice/priceParameters/downloadPricingParameters.do";
export const SAVE_PRICE_PARAMETERS =
  "/ifx/api/esm/specPrice/priceParameters/savePricingParameters.do";

export const SEARCH_PRG = "/ifx/api/esm/prg/search.do";
export const DELETE_PRG = "/ifx/api/esm/prg/delete.do";
export const ADD_UPDATE_PRG = "/ifx/api/esm/prg/addOrUpdate.do";
export const GET_PRG = "/ifx/api/esm/prg/get/";
export const DELETE_SQR = "/ifx/api/esm/prg/sqr/delete.do";
export const DELETE_SDR = " /ifx/api/esm/prg/sdr/delete.do";
export const ADD_UPDATE_SQR = "/ifx/api/esm/prg/sqr/addOrUpdate.do";
export const GET_SQR = "/ifx/api/esm/prg/sqr/get/";
export const VERSION_TIMELINE_SEARCH = "/ifx/api/esm/spec/vtimeline/search.do";
export const VERSION_TIMELINE_DOWNLOAD =
  "/ifx/api/esm/spec/vtimeline/download.do";

//PriceTimeline
export const PRICE_TIMELINE_SEARCH =
  "/ifx/api/esm/specPrice/timeline/search.do";
export const PRICE_TIMELINE_DOWNLOAD =
  "/ifx/api/esm/specPrice/timeline/download.do";

export const SEARCH_SPML_ASSIGNMENT_URL =
  "/ifx/api/esm/spml/searchSPMLAssignment.do";
export const DOWNLOAD_SPML_ASSIGNMENT_URL =
  "/ifx/api/esm/spml/downloadSPMLAssignment.do";
export const DOWNLOAD_SPMLASSIGNMENT_TEMPLATE_URL =
  "/ifx/api/esm/spml/downloadSpmlUploadTemplate.do";
export const UPLOAD_SPML_ASSIGNMENT_URL =
  "/ifx/api/esm/spml/spmlAssignmentFTPUpload.do";
export const ADD_SPMLASSIGNMENT_URL = "/ifx/api/esm/spml/addSPMLAssignment.do";
export const EDIT_SPMLASSIGNMENT_URL =
  "/ifx/api/esm/spml/editSPMLAssignment.do";
export const DELETE_SPMLASSIGNMENT_URL =
  "/ifx/api/esm/spml/deleteSPMLAssignment.do";
export const GET_SPMLASSIGNMENT_BYID =
  "/ifx/api/esm/spml/getSpmlAssignmentById.do";
export const UPLOAD_CRWML_ASSIGNMENT_URL =
  "/ifx/api/esm/spml/crwmlAssignmentFTPUpload.do";
