import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useContext,
  useCallback,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { Typeahead, TypeaheadMenu } from "react-bootstrap-typeahead";
import { InputGroup, Button } from "react-bootstrap";
import { faBars, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import defaultFilterBy from "react-bootstrap-typeahead/lib/utils/defaultFilterBy";
import cx from "classnames";
import FormContext from "react-bootstrap/FormContext";

const renderMenu = (results, menuProps) => {
  if (results == null || results.length === 0) return null;
  return React.createElement(TypeaheadMenu, {
    ...menuProps,
    options: results,
    className: cx("ifx-dropdown-menu", menuProps.className),
  });
};

const customfilterBy = (option, props) => {
  const { multiple, selected } = props;
  if (
    multiple &&
    selected.some(function(o) {
      return o.value === option.value;
    })
  ) {
    return false;
  }
  return true;
};

const customDefaultfilterBy = (option, props) => {
  return defaultFilterBy(option, { ...props, filterBy: ["label"] });
};

const WRAPPER_CLASS_NAME = "position-relative ifx-dropdown";
const DEFAULT_PROPS = {
  //className: "ifx-dropdown",
  clearButton: false,
  options: [],
  autoComplete: "off",
  flip: true,
  caseSensitive: false,
  dropup: false,
  dropdownBtnBorder: "",
  selectHintOnEnter: true,
  promptText: false,
  selected: [],
  bsSize: "sm",
  renderMenu,
  positionFixed: true,
  multiple: false,
  overlayProps: { className: "hello" },
  required: false,
};

export const IFXDropdown = forwardRef(
  (
    { serializedId = false, options = [], selected = [], onChange, ...props },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const [filterBy, setFilterBy] = useState({ filterBy: customfilterBy });
    const dropdownRef = useRef(null);

    let _props = {
      ...DEFAULT_PROPS,
      className: "",
      ...props,
    };
    let { inputGroupClassName = "", filtering = true, required } = _props;

    const { controlId } = useContext(FormContext);

    const _selected = useMemo(() => {
      const [selectedId] = selected || [];
      return (
        (serializedId && options?.filter(item => item.value === selectedId)) ||
        selected
      );
    }, [serializedId, selected, options]);

    useImperativeHandle(ref, () => {
      return {
        getDropdownRef: () => {
          return dropdownRef.current;
        },
      };
    });

    const dropDownOnClick = () => {
      dropdownRef.current.getInput().focus();
      setFilterBy({ filterBy: customfilterBy });
      setOpen(!open);
    };

    const _onChange = useCallback(
      v => {
        setOpen(false);
        if (v == null || v.length === 0) {
          dropdownRef.current.getInstance().clear();
        }
        if (serializedId) {
          //console.log("onchange", v);
          const _v = [v?.[0]?.value];
          //console.log("onChange", _v);
          return onChange && onChange(_v);
        }

        onChange && onChange(v);
      },
      [onChange]
    );

    const onKeyDown = e => {
      if (filtering) {
        setOpen(true);
        setFilterBy({ filterBy: customDefaultfilterBy });
      } else {
        if (e.key !== "Tab" && e.key !== "Enter") e.preventDefault();
      }
      if (_props.onKeyDown) _props.onKeyDown(e);
    };

    const onBlur = e => {
      setOpen(false);
      if (selected == null || selected.length === 0)
        dropdownRef.current.getInstance().clear();

      if (_props.onBlur) _props.onBlur(e);
    };

    return (
      <>
        <InputGroup
          size="sm"
          className={cx(inputGroupClassName, "ifx-dropdown-wrapper", {
            "field-mandatory": required,
          })}
        >
          <div className={WRAPPER_CLASS_NAME}>
            <Typeahead
              {..._props}
              ref={dropdownRef}
              id={_props.id ? _props.id : controlId}
              inputProps={{
                id: _props.id ? _props.id : controlId,
                autoComplete: "off",
              }}
              open={open}
              className={cx(
                DEFAULT_PROPS.className,
                _props.className
                //, {readonly: !filtering,}
              )}
              disabled={_props.disabled}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              onChange={_onChange}
              selected={_selected}
              options={options}
              {...filterBy}
            />
          </div>
          <InputGroup.Append>
            <Button
              disabled={_props.disabled}
              style={{ borderColor: _props.dropdownBtnBorder }}
              size="sm"
              className="ifx-drowpdown-btn btn-transition"
              variant="outline-light"
              onClick={dropDownOnClick}
              //tabindex="-1"
            >
              <FontAwesomeIcon icon={faAngleDown} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </>
    );
  }
);
IFXDropdown.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  filtering: PropTypes.bool,
};
