import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";

import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { FETCH_USER_PRICEGROUPS_URL } from "../../../const/esm/esmendpoints";
import {
  LoadUserPriceGroups,
  StoreUserPriceGroups,
} from "../../actions/esm/actionCreators";
import { AJAX_HEADER_JSON } from "../../../const/common/ajaxConsts";
import { ESMTasks } from "../../../const/esm/ESMTaskConsts";

export const DEFAULT_VAL = ESMTasks.SearchSpecPrice;

export const userPriceGroupsEpic = action$ =>
  action$.pipe(
    ofType(LoadUserPriceGroups.REQUEST().type),
    mergeMap(val =>
      ajax
        .post(
          FETCH_USER_PRICEGROUPS_URL,
          {
            TaskName: val.payload?.taskName
              ? val.payload.taskName
              : DEFAULT_VAL,
          },
          {
            ...AJAX_HEADER_JSON,
          }
        )
        .pipe(
          mergeMap(xhrResponse => {
            return of(
              LoadUserPriceGroups.SUCCESS(),
              StoreUserPriceGroups.PUT_USER_PRICEGROUPS(xhrResponse.response)
            );
          }),
          catchError(error => {
            console.log(error);
            return of(LoadUserPriceGroups.FAILURE());
          })
        )
    )
  );
