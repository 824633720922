import initialState from "./initialState";

export function CarrierPropertiesReducer(
  state = initialState.CarrierPropertiesReducer,
  action
) {
  switch (action.type) {
    case "CARRIER_PROPERTIES_PUT_CARRIER_PROPERTIES": {
      const { response, propertyNames } = action.payload;
      return {
        ...state,
        ...propertyNames.reduce((out, k) => ({ ...out, [k]: null }), {}),
        ...response.reduce((out, cp) => ({ ...out, [cp.key]: cp.value }), {}),
      };
    }

    case "CARRIER_PROPERTIES_PUT_CARRIER_PROPERTY":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case "CARRIER_PROPERTIES_CLEAR_CARRIER_PROPERTIES":
      return {};

    default:
      return state;
  }
}
