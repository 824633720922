import React from "react";
import {
  IFXDatePicker,
  IFXDateRangePicker,
  IFXDateTimePicker,
  IFXTextBoxReadOnly,
} from "@ifx-react/ifx-react-core";
import { useSelector } from "react-redux";
import { useUserDateFormat } from "../../../hooks/common/useUserDateFormat";

export const IFXUserDateReadonly = props => {
  const { toLocaleDateMoment } = useUserDateFormat();
  const _value = toLocaleDateMoment(props.value);
  return <IFXTextBoxReadOnly {...props} value={_value} />;
};

export const IFXUserDateTimeReadonly = props => {
  const { toLocaleDateTimeMoment } = useUserDateFormat();
  const _value = toLocaleDateTimeMoment(props.value);
  return <IFXTextBoxReadOnly {...props} value={_value} />;
};

export const IFXUserDatePicker = props => {
  const userDateFormat = useSelector(
    state => state.PortalReducer.UserContextReducer.userInfo.dateFormat
  );
  return <IFXDatePicker dateFormat={userDateFormat} {...props} />;
};

export const IFXUserDateRangePicker = props => {
  const userDateFormat = useSelector(
    state => state.PortalReducer.UserContextReducer.userInfo.dateFormat
  );
  return <IFXDateRangePicker dateFormat={userDateFormat} {...props} />;
};

export const IFXUserDateTimePicker = props => {
  const { dateFormat: userDateFormat } = useSelector(
    state => state.PortalReducer.UserContextReducer.userInfo
  );

  return (
    <IFXDateTimePicker
      dateFormat={`${userDateFormat} HH:mm`}
      //timeFormat={userTimeFormat}
      {...props}
    />
  );
};
