import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IFXButton } from "../IFXButton";
import { isEmpty } from "ramda";

const TOAST_BUTTON_DEFAULT_PROPS = {
  type: "default",
  size: "sm",
};

export const IFXToast = React.memo(params => {
  const {
    title = "Information",
    message,
    onCallback,
    actions = [],
    className = "",
    ifxDismissToast,
    setLastAction,
    onCloseCustom,
    hideProgressBar,
  } = params;
  const [maxHeight, setMaxHeight] = useState(window.innerHeight);

  const handleResize = useCallback(e => setMaxHeight(window.innerHeight), []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    return () => {
      onCloseCustom(params);
    };
  }, []);

  const actionButtons = useMemo(
    () =>
      actions.map((action, key) => {
        const label = typeof action === "string" ? action : undefined;
        const _name = typeof action === "string" ? action : action.name;
        return (
          <IFXButton
            {...{
              ...TOAST_BUTTON_DEFAULT_PROPS,
              key,
              ...((label && { name: label }) || {}),
              ...((typeof action !== "string" && action) || {}),
              onClick: e =>
                !isEmpty(actions) &&
                (setLastAction(action),
                ifxDismissToast(),
                onCallback(e, _name, action)),
            }}
          />
        );
      }),
    [actions, onCallback]
  );
  return (
    <div className={`toastbody ${className}`}>
      <header className={className}>
        <h6>{title}</h6>
      </header>
      <main style={{ maxHeight: `calc(${maxHeight}px - 10.715rem)` }}>
        <div className="toastcontent">{message}</div>
      </main>
      {actionButtons?.length ? (
        <footer
          className={`toastbuttons pt-1 ${
            hideProgressBar === false ? "pb-3" : "pb-2"
          }`}
        >
          {actionButtons}
        </footer>
      ) : null}
    </div>
  );
});
