import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";

import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { INITIAL_CARRIER_PROPERTIES_LIST } from "../../../const/common/carrierPropertiesConsts";
import { FETCH_CARRIER_PROPERTIES_URL } from "../../../const/common/endpoints";
import { convertArrayToFormSerialized } from "../../../util/common/ajaxHelper";
import {
  LoadCarrierProperties,
  StoreCarrierProperties,
} from "../../actions/common/actionCreators";
import { AJAX_HEADER_APP_DEFAULT } from "../../../const/common/ajaxConsts";

export const carrierPropertiesEpic = (action$, state$) =>
  action$.pipe(
    //tap(val => console.log(state$)),
    ofType(LoadCarrierProperties.REQUEST().type),
    //tap(val => console.log(val)),
    mergeMap(val => {
      const propertyNames =
        val.payload && val.payload.propertyNames
          ? val.payload.propertyNames
          : INITIAL_CARRIER_PROPERTIES_LIST;
      return ajax
        .post(
          FETCH_CARRIER_PROPERTIES_URL,
          {
            ...convertArrayToFormSerialized("propertyNames", propertyNames),
            carrierId:
              val.payload && val.payload.carrierId
                ? val.payload.carrierId
                : state$.value.PortalReducer.UserContextReducer.selectedAirline
                    ?.id || 0,
          },
          {
            ...AJAX_HEADER_APP_DEFAULT,
          }
        )
        .pipe(
          /* tap(val =>
            console.log(
              `carrierPropertiesEpic after ajax: ${JSON.stringify(val)}`
            )
          ), */
          mergeMap(xhrResponse => {
            //console.log(xhrResponse.response);
            return of(
              LoadCarrierProperties.SUCCESS(),
              StoreCarrierProperties.PUT_CARRIER_PROPERTIES({
                response: xhrResponse.response,
                propertyNames,
              })
            );
          }),
          catchError(error => {
            console.log(error);
            return of(LoadCarrierProperties.FAILURE());
          })
        );
    })
  );
