import React, { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  IFXModal,
  IFXModalBody,
  IFXModalHeader,
  IFXModalFooter,
  IFXButton,
  IFXFormValidation,
  ifxJsonAjax,
  IFXFieldWrapper,
  CoreValidationRuleCodes,
  useObservableCallback,
  IFXTextBox,
  useToast,
} from "@ifx-react/ifx-react-core";
import { Subject } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { useFormContext } from "react-hook-form";
import { IFXUserDatePicker } from "../../../components/common/DatePickers";
import { subDays, format } from "date-fns";
import { UserRegistrationContext } from "./UserRegistration";
import { EDIT_CREDENTIAL_SET_URL } from "../../../const/portal/endpoints";
import { LayoutActions } from "../../../store/actions/common/actionCreators";
import { CommonToastConsts } from "../../../const/common/toastConsts";

const getEditCredentialObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax.post(EDIT_CREDENTIAL_SET_URL, params).pipe(
        map(xhrResponse => xhrResponse),
        catchError(error => {
          console.error("Error in Edit Credential Set", error);
          errorHandler(error);
          return [];
        })
      )
    )
  );

export const EditCredentialSetModal = ({
  onHide,
  show,
  editCredential,
  reloadGrid,
}) => {
  const dispatch = useDispatch();

  const { ifxErrorToast } = useToast();

  const { userData } = useContext(UserRegistrationContext);

  const defaultValues = {
    credentialName: editCredential.name,
    credentialDesc: editCredential.description,
    effDate: {
      dateValue: new Date(editCredential.effDate),
      serialized: format(new Date(editCredential.effDate), "MM/dd/yyyy"),
    },
    expDate: {
      dateValue: new Date(editCredential.expDate),
      serialized: format(new Date(editCredential.expDate), "MM/dd/yyyy"),
    },
  };

  const editCredentialSet$ = useMemo(() => {
    return getEditCredentialObj$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        ifxErrorToast(CommonToastConsts.ERROR);
        onHide();
      },
    });
  }, []);

  const handleEditCredentialSet = formData => {
    console.log(formData);
    dispatch(
      LayoutActions.SET_LOADER_SHOW({
        loaderText: "Loading...",
      })
    );
    editCredentialSet$.next({
      id: editCredential.id,
      userId: userData.userId,
      effDate: formData.effDate.serialized || null,
      expDate: formData.expDate.serialized || null,
    });
  };

  useObservableCallback(
    editCredentialSet$,
    response => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      onHide();
      reloadGrid();
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      onHide();
    }
  );

  return (
    <>
      <IFXModal show={show} onHide={onHide} size="md">
        <IFXModalHeader>Edit Credential</IFXModalHeader>
        <IFXFormValidation
          onSubmit={handleEditCredentialSet}
          defaultValues={defaultValues}
        >
          <IFXModalBody>
            <EditCredentialSetForm editCredential={editCredential} />
          </IFXModalBody>
          <IFXModalFooter>
            <IFXButton type="save" name="Save" />
            <IFXButton type="cancel" name="Cancel" onClick={onHide} />
          </IFXModalFooter>
        </IFXFormValidation>
      </IFXModal>
    </>
  );
};

const EditCredentialSetForm = ({ editCredential }) => {
  const { watch } = useFormContext();
  const effDate = watch("effDate");
  const expDate = watch("expDate");

  return (
    <>
      <IFXFieldWrapper
        label="Credential Name"
        controlId="credentialName"
        name="credentialName"
        rules={[CoreValidationRuleCodes.required]}
        disabled
      >
        <IFXTextBox />
      </IFXFieldWrapper>
      <IFXFieldWrapper
        label="Credential Description"
        controlId="credentialDesc"
        name="credentialDesc"
        rules={[CoreValidationRuleCodes.required]}
        disabled
      >
        <IFXTextBox />
      </IFXFieldWrapper>
      <IFXFieldWrapper
        type="datepicker"
        label="Eff date"
        controlId="effDate"
        name="effDate"
        rules={[CoreValidationRuleCodes.required]}
        disabled={editCredential.effDateLong < new Date().setHours(0, 0, 0, 0)}
      >
        <IFXUserDatePicker
          minDate={subDays(new Date(), 0)}
          maxDate={
            expDate && expDate.dateValue && subDays(expDate.dateValue, 0)
          }
        />
      </IFXFieldWrapper>
      <IFXFieldWrapper
        type="datepicker"
        label="End date"
        controlId="expDate"
        name="expDate"
        rules={[CoreValidationRuleCodes.required]}
      >
        <IFXUserDatePicker
          minDate={
            effDate &&
            effDate.dateValue &&
            !(editCredential.expDateLong < new Date().setHours(0, 0, 0, 0))
              ? subDays(effDate.dateValue, 0)
              : subDays(new Date(), 0)
          }
        />
      </IFXFieldWrapper>
    </>
  );
};
