import { catchError, tap, switchMap } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";

import { ajax } from "rxjs/ajax";
import * as Observable from "rxjs";
import {
  UserInfoActions,
  UserContextActions,
  AirlineSwitchActions,
  ResetReduxActions,
  LoginActions,
} from "../../../actions/portal/actionCreators";
import {
  USER_INFO_URL,
  AIRLINE_SWITCH_URL,
} from "../../../../const/portal/endpoints";
import {
  LoadCarrierProperties,
  LayoutActions,
  StoreCarrierProperties,
} from "../../../actions/common/actionCreators";
import { AJAX_HEADER_APP_DEFAULT } from "../../../../const/common/ajaxConsts";

const userInfoEpic = (action$, state$, { dispatch }) =>
  action$.pipe(
    ofType(UserInfoActions.REQUEST().type),
    tap(() => {
      dispatch(
        LayoutActions.SET_LOADER_SHOW({
          //loaderText: "Loading Session",
          loaderOpaque: true,
        })
      );
    }),
    switchMap(
      ({ payload: { carrierCode = null, failureStatus, carrierSwitchKey } }) =>
        ajax
          .post(
            USER_INFO_URL,
            { carrierCode },
            {
              ...AJAX_HEADER_APP_DEFAULT,
            }
          )
          .pipe(
            // tap(val => console.log(`BEFORE MAP2: ${JSON.stringify(val)}`)),
            switchMap(({ response }) => {
              //console.log("user info response received");
              //console.log(response);
              const { IS_SAML = false } = response;
              if (!IS_SAML) {
                const {
                  userInfo,
                  userInfo: { carrierList },
                } = response;
                let selectedAirline;
                carrierList.forEach(c => {
                  if (c.isSelected) {
                    selectedAirline = c;
                  }
                });
                selectedAirline =
                  !selectedAirline && carrierList.length > 0
                    ? carrierList[0]
                    : selectedAirline;
                return Observable.of(
                  UserInfoActions.SUCCESS(),
                  UserContextActions.SET_USER_INFO(userInfo),
                  UserContextActions.SET_SELECTED_AIRLINE({
                    selectedAirline,
                    carrierSwitchKey,
                  }),
                  UserContextActions.SET_INITIAL_LOAD(2), //success status
                  StoreCarrierProperties.CLEAR_CARRIER_PROPERTIES(),
                  LoadCarrierProperties.REQUEST({
                    carrierId: selectedAirline?.id || 0,
                  }),
                  LayoutActions.SET_LOADER_HIDE()
                );
              } else {
                const { AUTHENTICATION_STATUS: authStatus } = response;
                if (authStatus === "success") {
                  return Observable.of(
                    UserInfoActions.SUCCESS(),
                    UserContextActions.SET_INITIAL_LOAD(1),
                    UserInfoActions.REQUEST({
                      carrierCode,
                      failureStatus: -1,
                    })
                  );
                } else {
                  return Observable.of(
                    UserInfoActions.SUCCESS(),
                    LoginActions.SUCCESS(response),
                    UserContextActions.SET_INITIAL_LOAD(
                      authStatus === "saml_forward" ? -3 : -1
                    ) //saml forward status or saml login error
                  );
                }
              }
            }),
            catchError(error => {
              console.error(error);
              //let { message, response, status } = error;
              //let payload = { message, response, status };
              //console.log("failureStatus::" + failureStatus);
              return Observable.of(
                UserContextActions.SET_INITIAL_LOAD(failureStatus),
                UserInfoActions.FAILURE(),
                LayoutActions.SET_LOADER_HIDE()
              );
            })
          )
    )
  );

const switchAirlineEpic = (action$, state$, { dispatch }) =>
  action$.pipe(
    ofType(AirlineSwitchActions.REQUEST().type),
    tap(val => {
      // console.log(val);
      dispatch(
        LayoutActions.SET_LOADER_SHOW({
          loaderText: "Switching Airline",
          loaderOpaque: true,
        })
      );
    }),
    switchMap(({ payload: { iataCode } }) =>
      ajax
        .post(
          AIRLINE_SWITCH_URL,
          { IataCode: iataCode },
          {
            ...AJAX_HEADER_APP_DEFAULT,
          }
        )
        .pipe(
          // tap(val => console.log(`BEFORE MAP2: ${JSON.stringify(val)}`)),
          switchMap(({ response }) => {
            //console.log(response);
            //{"AIRLINE_SWITCH":"success"}
            const { AIRLINE_SWITCH } = response;
            if (AIRLINE_SWITCH === "success") {
              return Observable.of(
                AirlineSwitchActions.SUCCESS(),
                ResetReduxActions.RESET_REDUX(),
                LayoutActions.SET_LOADER_HIDE(),
                UserInfoActions.REQUEST({
                  carrierCode: iataCode,
                  carrierSwitchKey: Math.random(),
                  failureStatus: -2,
                })
              );
            } else {
              return Observable.of(
                AirlineSwitchActions.FAILURE(),
                LayoutActions.SET_LOADER_HIDE(),
                UserContextActions.SET_INITIAL_LOAD(-2) //redirect to login
              );
            }
          }),
          catchError(error => {
            console.error(error);
            //let { message, response, status } = error;
            //let payload = { message, response, status };
            return Observable.of(
              AirlineSwitchActions.FAILURE(),
              LayoutActions.SET_LOADER_HIDE()
            );
          })
        )
    )
  );

export const userContextEpic = combineEpics(userInfoEpic, switchAirlineEpic);
