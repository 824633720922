import React, { memo } from "react";
import { Col } from "react-bootstrap";
import { IFXIcons } from "../../../IFXIcons";
import { IFXTooltip } from "../../../IFXTooltip";

export const LabelIconRenderer = memo(({ value, tooltipProps, iconProps }) => {
  return (
    <div className="d-flex justify-content-between">
      <div className="">
        <IFXTooltip {...tooltipProps}>
          <div className="table-icon-col position-relative">
            <IFXIcons
              iconSource="svg"
              icon="question_default"
              color="ifx-brand"
              {...iconProps}
            />
          </div>
        </IFXTooltip>
      </div>
      <Col className="p-0 ifx-text-overflow">
        <span className="form-control form-control-sm ifx-text-overflow ifx-cell-renderer-readonly">
          {value}
        </span>
      </Col>
    </div>
  );
});
