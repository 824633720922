import React, { useEffect, useContext } from "react";
import {
  IFXAccordion,
  IFXFormLayout,
  IFXFieldWrapper,
  CoreValidationRuleCodes,
  IFXTextBox,
  IFXFieldGroup,
  IFXDropdown,
  IFXAutoSuggest,
  IFXErrorAlert,
  substitute,
} from "@ifx-react/ifx-react-core";
import { isEmptyOrNull } from "@ifx-react/ifx-react-core/src/utils/form";
import { useFormContext } from "react-hook-form";
import { UserRegistrationContext } from "./UserRegistration";

export const UserInfoForm = ({ setValueMatchRule,valueMatchRule }) => {
  const { formState, getValues } = useFormContext();

  const { editedBy, toggleAcc } = useContext(UserRegistrationContext);

  const allowRegex = /^[0-9-+]*$/;
  const phoneRegex = /^[+]?([0-9-])+$/;

  const allowOnlyNumbers = e => {
    if (e.key !== "Enter") {
      const _newValue = `${e.target.value}${e.key}`;
      if (!allowRegex.test(_newValue)) {
        e.preventDefault();
        return;
      }
    }
  };

  useEffect(() => {
    const numericPattern = /^(?=.*[0-9])/;

    const minCharPattern = /^.{10,}$/;

    const uppercasePattern = /^(?=.*[A-Z])/;

    const lowercasePattern = /^(?=.*[a-z])/;

    const specialCharPattern = /^(?=.*[`~!@#\\$%\\^&\\*\\(\\)_\\+=,\\.\\?;:\\|\\-])/;

    const repeatedPattern = new RegExp("(.)\\1{3,}");

    const value = getValues("passwordText");

    setValueMatchRule({
      valueMatch: ({ value, label }, compareLabel) => {
        let compareValue = "";
        let validationText = "";
        if (compareLabel === "secretAnswer") {
          compareValue = getValues("secretAnswer");
          validationText =
            "Secret Answer and Confirm Answer values do not match";
        } else if (compareLabel === "password") {
          compareValue = getValues("passwordText");
          validationText = "Password and Confirm Password values do not match";
        } else if (compareLabel === "emailId") {
          compareValue = getValues("emailText");
          validationText =
            "Email Address and Confirm Email Address values do not match";
        }
        if (value !== compareValue) {
          return substitute(validationText, { label });
        }
        return undefined;
      },
      checkPasswordStrength: ({ value, label }) => {
        if (
          value.includes(" ") ||
          !numericPattern.test(value) ||
          !minCharPattern.test(value) ||
          !uppercasePattern.test(value) ||
          !lowercasePattern.test(value) ||
          !specialCharPattern.test(value) ||
          repeatedPattern.test(value)
        ) {
          return substitute("Password is not valid", { label });
        }
        return undefined;
      },
    });
  }, []);

  return (
    <>
      {!isEmptyOrNull(valueMatchRule) && (
        <IFXAccordion
          title="User Information"
          isOpen={
            toggleAcc == "initial"
              ? !editedBy || editedBy === "self"
              : toggleAcc
          }
        >
          <IFXFormLayout
            allFieldsColProps={{ xl: 3, lg: 3, md: 3, sm: 6, xs: 12 }}
          >
            <IFXFieldWrapper
              label="First Name"
              controlId="firstName"
              name="firstName"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXTextBox maxLength={40} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="Last Name"
              controlId="lastName"
              name="lastName"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXTextBox maxLength={40} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="Email Address"
              controlId="emailText"
              name="emailText"
              rules={[
                CoreValidationRuleCodes.required,
                {
                  regexCheck: [/^[^\s@]+@[^\s@]+\.[^\s@]+$/],
                },
              ]}
            >
              <IFXTextBox maxLength={100} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="Confirm Email Address"
              controlId="confirmEmailText"
              name="confirmEmailText"
              rules={[
                CoreValidationRuleCodes.required,
                {
                  regexCheck: [/^[^\s@]+@[^\s@]+\.[^\s@]+$/],
                },

                { valueMatch: "emailId" },
              ]}
            >
              <IFXTextBox maxLength={100} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="Phone"
              controlId="workPhone"
              name="workPhone"
              rules={[
                CoreValidationRuleCodes.required,
                {
                  regexCheck: phoneRegex,
                },
              ]}
              onKeyPress={allowOnlyNumbers}
            >
              <IFXTextBox maxLength={15} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="Alternate Phone"
              controlId="cellPhone"
              name="cellPhone"
              rules={[
                {
                  regexCheck: phoneRegex,
                },
              ]}
              onKeyPress={allowOnlyNumbers}
            >
              <IFXTextBox maxLength={15} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="Department"
              controlId="department"
              name="department"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXTextBox maxLength={40} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="Job Title"
              controlId="jobtitle"
              name="jobtitle"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXTextBox maxLength={40} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="Home Station"
              controlId="station"
              name="station"
              type="autosuggest"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXAutoSuggest
                serviceName="autoSuggestController"
                fieldType="stations"
                params={{
                  stationSearch: "stationSearch",
                }}
              />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="Manager Name"
              controlId="managerName"
              name="managerName"
              rules={[CoreValidationRuleCodes.required]}
            >
              <IFXTextBox maxLength={100} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="Manager Email"
              controlId="managerEmail"
              name="managerEmail"
              rules={[
                CoreValidationRuleCodes.required,
                {
                  regexCheck: [/^[^\s@]+@[^\s@]+\.[^\s@]+$/],
                },
              ]}
            >
              <IFXTextBox maxLength={100} />
            </IFXFieldWrapper>
            <IFXFieldWrapper
              label="Manager Phone"
              controlId="managerPhone"
              name="managerPhone"
              rules={[
                CoreValidationRuleCodes.required,
                {
                  regexCheck: phoneRegex,
                },
              ]}
              onKeyPress={allowOnlyNumbers}
            >
              <IFXTextBox maxLength={15} />
            </IFXFieldWrapper>
          </IFXFormLayout>
        </IFXAccordion>
      )}
    </>
  );
};
