import React, {
  forwardRef,
  useRef,
  useEffect,
  useImperativeHandle,
} from "react";
import { IFXTextBox } from "../../..";
import { omit } from "../../../../../..";

export const IFXTextBoxCol = forwardRef((props, ref) => {
  const innerRef = useRef();
  const {
    required = false,
  } = props;
  const _required = typeof required === "function" ? required(props) : required;
  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return innerRef.current.value;
      },
    };
  });

  useEffect(() => {
    window.setTimeout(() => {
      //console.log(innerRef);
      try {
        innerRef.current.focus();
        innerRef.current.select();
      } catch (e) {}
    }, 100);
  }, []);

  const _props = omit(props, [
    "keyPress",
    "charPress",
    "colDef",
    "rowIndex",
    "columnApi",
    "stopEditing",
    "eGridCell",
    "parseValue",
    "formatValue",
    "agGridReact",
    "frameworkComponentWrapper",
    "reactContainer",
    "value",
    "cellStartedEdit",
    "column",
    "node",
    "data",
    "api",
  ]);
  return (
    <IFXTextBox
      {..._props}
      ref={innerRef}
      defaultValue={props.value}
      required={_required}
    />
  );
});
