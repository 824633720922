import { isEmptyOrNull } from "../utils/form";
import { substitute } from "../utils/string";
import { isEmpty } from "ramda";

export const CoreValidationRuleCodes = {
  required: "required",
  daysOfWeekRequired: "daysOfWeekRequired",
  requiredNoMarker: "requiredNoMarker",
  minLength: "minLength",
  //maxLength: "maxLength", NOT Required can used maxLength attribute of field
  regexCheck: "regexCheck",
  noSpcChar: "noSpcChar",
  noSpecialCharCheck: "noSpecialCharCheck",
  rangeDateTimeRequiredWhen: "rangeDateTimeRequiredWhen", //Validation for Date and Time Range From and To is required
  rangeNumberRequiredWhen: "rangeNumberRequiredWhen", //Validation for Nuber Range From and To is required
  numberRangeCheck: "numberRangeCheck",
  timeRangeCheck: "timeRangeCheck",
  dateRangeCheck: "dateRangeCheck",
  dateRangeLimitDays: "dateRangeLimitDays",
  minMaxNumberCheck: "minMaxNumberCheck",
};

const errorMap = {
  minLength: "${label} cannot be less than ${min} chars",
  //maxLength: "${label} cannot be greater than ${max} chars",
  required: "${label} field is required",
  daysOfWeekRequired: "Please select atleast one Day(s) of the Week.",
  regexCheck: "${label} field is invalid",
  noSpcCharCheck: "The value entered is not valid",
  noSpecialCharCheck: "Special characters are not allowed",
  numberRangeCheck: "${labelTo} cannot be lesser than ${labelFrom}",
  timeRangeCheck: "${labelTo} cannot be lesser than ${labelFrom}",
  dateRangeCheck: "${labelTo} cannot be lesser than ${labelFrom}",
  dateRangeLimitDays:
    "Please minimize your data range to ${rangeLimitDays} days",
  minMaxNumberCheck: "${label} must be between ${min} - ${max}",
};

const daysOfWeekRequired = ({ label }, name, watch) =>
  (!(watch(name) || []).some(v => v) &&
    substitute(errorMap["daysOfWeekRequired"])) ||
  undefined;

const dateRangeLimitDays = (
  { value, label: [labelFrom, labelTo] },
  rangeLimitDays
) => {
  const equalLabel = labelFrom === labelTo;
  let { from: valueFrom, to: valueTo } = value || {};

  if (isEmptyOrNull(valueFrom) || isEmptyOrNull(valueTo)) return undefined;

  valueFrom = new Date(valueFrom.serialized);
  valueTo = new Date(valueTo.serialized);

  let maxDateLimit = new Date(valueFrom);
  maxDateLimit.setDate(maxDateLimit.getDate() + parseInt(rangeLimitDays - 1));

  return (
    (valueTo > maxDateLimit &&
      substitute(errorMap["dateRangeLimitDays"], {
        labelFrom: equalLabel ? "From " + labelFrom : labelFrom,
        labelTo: equalLabel ? "To " + labelTo : labelTo,
        rangeLimitDays,
      })) ||
    undefined
  );
};

const requiredValidation = ({ value, label }, ...range) => {
  if (isEmpty(range))
    return (
      (isEmptyOrNull(value) && substitute(errorMap["required"], { label })) ||
      undefined
    );
  else {
    const { from = {}, to = {} } = value || {};
    return (
      (((range.includes("from") && isEmptyOrNull(from)) ||
        (range.includes("to") && isEmptyOrNull(to))) &&
        [
          (range.includes("from") &&
            isEmptyOrNull(from) &&
            substitute(errorMap["required"], { label: label[0] })) ||
            "",
          (range.includes("to") &&
            isEmptyOrNull(to) &&
            substitute(errorMap["required"], { label: label[1] })) ||
            "",
        ].join()) ||
      undefined
    );
  }
};

export const validationRulesMap = {
  maxLength: ({ value, label }, max) =>
    (value.length > max && substitute(errorMap["maxLength"], { max, label })) ||
    undefined,
  minLength: ({ value, label }, min) =>
    (value.length < min && substitute(errorMap["minLength"], { min, label })) ||
    undefined,
  required: requiredValidation,
  requiredNoMarker: requiredValidation,
  regexCheck: ({ value, label }, regex) => {
    const result =
      (value &&
        value !== "" &&
        !regex.test(value) &&
        substitute(errorMap["regexCheck"], { label })) ||
      undefined;
    return result;
  },
  noSpcChar: ({ value, label }, regExp) =>
    (!isEmptyOrNull(value) &&
      !regExp.test(value) &&
      substitute(errorMap["noSpcCharCheck"], { label })) ||
    undefined,
  noSpecialCharCheck: ({ value, label }, exculdeCharRegex = /\W/) => {
    const result =
      (exculdeCharRegex.test(value) &&
        substitute(errorMap["noSpecialCharCheck"], { label })) ||
      undefined;
    return result;
  },
  rangeDateTimeRequiredWhen: ({ value, label: [labelFrom, labelTo] }) => {
    const { from = null, to = null } = value || {};
    const fromMessage =
      (to &&
        to.dateValue &&
        !from &&
        substitute(errorMap["required"], {
          label: labelFrom,
        })) ||
      "";
    const toMessage =
      (from &&
        from.dateValue &&
        !to &&
        substitute(errorMap["required"], {
          label: labelTo,
        })) ||
      "";
    return (
      ((!isEmptyOrNull(fromMessage) || !isEmptyOrNull(toMessage)) &&
        [fromMessage, toMessage].join()) ||
      undefined
    );
  },
  rangeNumberRequiredWhen: ({ value, label: [labelFrom, labelTo] }) => {
    const { from = null, to = null } = value || {};
    const fromMessage =
      (to &&
        !from &&
        substitute(errorMap["required"], {
          label: labelFrom,
        })) ||
      "";
    const toMessage =
      (from &&
        !to &&
        substitute(errorMap["required"], {
          label: labelTo,
        })) ||
      "";
    return (
      ((!isEmptyOrNull(fromMessage) || !isEmptyOrNull(toMessage)) &&
        [fromMessage, toMessage].join()) ||
      undefined
    );
  },
  numberRangeCheck: ({ value, label: [labelFrom, labelTo] }) => {
    const equalLabel = labelFrom === labelTo;
    let { from: valueFrom, to: valueTo } = value || {};
    valueFrom = parseFloat(valueFrom);
    valueTo = parseFloat(valueTo);

    return (
      (!isNaN(valueFrom) &&
        !isNaN(valueTo) &&
        valueTo < valueFrom &&
        substitute(errorMap["numberRangeCheck"], {
          labelFrom: equalLabel ? "From " + labelFrom : labelFrom,
          labelTo: equalLabel ? "To " + labelTo : labelTo,
        })) ||
      undefined
    );
  },
  timeRangeCheck: ({ value, label: [labelFrom, labelTo] }) => {
    const equalLabel = labelFrom === labelTo;
    let { from: valueFrom, to: valueTo } = value || {};
    valueFrom = valueFrom ? valueFrom.dateValue.getTime() : {};
    valueTo = valueTo ? valueTo.dateValue.getTime() : {};
    return (
      (!isNaN(valueFrom) &&
        !isNaN(valueTo) &&
        valueTo < valueFrom &&
        substitute(errorMap["timeRangeCheck"], {
          labelFrom: equalLabel ? "From " + labelFrom : labelFrom,
          labelTo: equalLabel ? "To " + labelTo : labelTo,
        })) ||
      undefined
    );
  },
  dateRangeCheck: ({ value, label: [labelFrom, labelTo] }) => {
    const equalLabel = labelFrom === labelTo;
    let { from: valueFrom, to: valueTo } = value || {};
    valueFrom = !isEmptyOrNull(valueFrom) ? new Date(valueFrom.serialized) : "";
    valueTo = !isEmptyOrNull(valueTo) ? new Date(valueTo.serialized) : "";

    return (
      (!isEmptyOrNull(valueFrom) &&
        !isEmptyOrNull(valueTo) &&
        valueTo < valueFrom &&
        substitute(errorMap["dateRangeCheck"], {
          labelFrom: equalLabel ? "From " + labelFrom : labelFrom,
          labelTo: equalLabel ? "To " + labelTo : labelTo,
        })) ||
      undefined
    );
  },
  dateRangeLimitDays,
  daysOfWeekRequired,
  minMaxNumberCheck: ({ value, label }, min, max) =>
    ((value < min || value > max) &&
      substitute(errorMap["minMaxNumberCheck"], { min, max, label })) ||
    undefined,
};
