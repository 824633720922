import React, { Suspense } from "react";
import { IFXLoader } from "./IFXLoader";

export const IFXSuspense = props => {
  const { message } = props;
  return (
    <>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <IFXLoader message={message} enable />
              </div>
            </div>
          </div>
        }
      >
        {props.children}
      </Suspense>
    </>
  );
};
