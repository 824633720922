import initialState from "./initialState";

export function UserStationsReducer(
  state = initialState.UserStationsReducer,
  action
) {
  switch (action.type) {
    case "USER_STATIONS_PUT_USER_STATIONS":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
