import React, { useState } from "react";

const ErrorCode = props => {
  const [counter, setCounter] = useState(0);

  const throwError = function() {
    throw new Error("Component Crashed!");
  };

  return (
    <>
      {counter === 5 ? (
        // Simulate a JS error
        throwError()
      ) : (
        <h1 onClick={() => setCounter(counter + 1)}> Counter : {counter} </h1>
      )}
    </>
  );
};

export { ErrorCode };
