import initialState from "./initialState";

export function AircraftSeatConfigReducer(
  state = initialState.AcSeatConfigInfo,
  action
) {
  switch (action.type) {
    case "SEARCH_AC_SEAT_CONFIG_REQUEST":
      return {
        ...state,
        searchAcSeatConfigCriteria: action.payload,
      };
    default:
      return state;
  }
}
