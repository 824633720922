import {
  lensIndex,
  equals,
  view,
  values,
  keys,
  findIndex,
  compose,
  type,
} from "ramda";

export const getKeyFromValue = (obj, value) =>
  view(lensIndex(findIndex(equals(value), values(obj))), keys(obj));

export const isType = (value, str) => compose(equals(str), type)(value);
