import initialState from "./initialState";

export function SpecTypesReducer(state = initialState.specTypeParams, action) {
  switch (action.type) {
    case "SEARCH_SPEC_TYPE_REQUEST":
      return {
        ...state,
        data: { ...action.payload },
      };
    default:
      return state;
  }
}
