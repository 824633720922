import initialState from "./initialState";

export default function SearchRulesReducer(
  state = initialState.SearchRulesInfo,
  action
) {
  switch (action.type) {
    case "SEARCH_RULES_SELECTION_REQUEST": {
      //console.log("its coming main reducer>>>", action.payload);
      return {
        ...state,
        searchRuleValues: action.payload,
        isSearchTriggered: true,
      };
    }
    default:
      return state;
  }
}
