import { mergeMap, catchError, tap } from "rxjs/operators";
import { ofType } from "redux-observable";

import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { INITIAL_CODE_VAL_LIST } from "../../../const/common/initialCodeVal";
import { FETCH_CODE_VAL_URL } from "../../../const/common/endpoints";
import { LoadCodeVal, StoreCodeVal } from "../../actions/common/actionCreators";
import { AJAX_HEADER_APP_DEFAULT } from "../../../const/common/ajaxConsts";

export const codeValEpic = action$ =>
  action$.pipe(
    ofType(LoadCodeVal.REQUEST().type),
    mergeMap(val =>
      ajax
        .post(
          FETCH_CODE_VAL_URL,
          {
            internalName:
              val.payload && val.payload.internalName
                ? val.payload.internalName
                : INITIAL_CODE_VAL_LIST,
          },
          {
            ...AJAX_HEADER_APP_DEFAULT,
          }
        )
        .pipe(
          mergeMap(xhrResponse => {
            return of(
              LoadCodeVal.SUCCESS(),
              StoreCodeVal.PUT_CODE_VAL(xhrResponse.response)
            );
          }),
          catchError(error => {
            console.log(error);
            return of(LoadCodeVal.FAILURE());
          })
        )
    )
  );
