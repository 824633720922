import React, { useRef, useState, useEffect, useMemo, useContext } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import {
  IFXAccordion,
  IFXDataTable,
  IFXGridPrimaryIconButton,
  DT_DEFAULT_CONTROL_ACTIONS,
  isEmptyOrNull,
  useObservableCallback,
  useToast,
  ifxJsonAjax,
} from "@ifx-react/ifx-react-core";
import { Subject } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { useUserDateFormat } from "../../../hooks/common/useUserDateFormat";
import { useResponsiveGrid } from "../../../hooks/common/layoutHooks";
import { LayoutActions } from "../../../store/actions/common/actionCreators";
import { credentialSetColDefs } from "./CredentialSetColDefs";
import { AddCredentialSetModal } from "./AddCredentialSetModal";
import { CommonToastConsts } from "../../../const/common/toastConsts";
import { UserRegistrationConsts } from "./userRegistrationConsts";
import { UserRegistrationContext } from "./UserRegistration";
import {
  APPROVE_CREDENTIALSET_URL,
  DELETE_CREDENTIAL_SET_URL,
  REJECT_CREDENTIALSET_URL,
} from "../../../const/portal/endpoints";
import { EditCredentialSetModal } from "./EditCredentialSetModal";
import { useUserTaskGranted } from "../../../hooks/portal/userSecurityHooks";
import { MasterDataTaskConst } from "../../Portal/MasterData/Common/MasterDataTaskConst";

const getDeleteCredentialSetObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax.post(DELETE_CREDENTIAL_SET_URL, params.paramObj).pipe(
        map(xhrResponse => {
          xhrResponse.accessProfId = params.accessProfId;
          return xhrResponse;
        }),
        catchError(error => {
          console.error("Error in Delete Access Profile", error);
          errorHandler(error);
          return [];
        })
      )
    )
  );
const getApproveCredentialSetObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax.post(APPROVE_CREDENTIALSET_URL, params.paramObj).pipe(
        map(xhrResponse => {
          xhrResponse.accessProfId = params.accessProfId;
          return xhrResponse;
        }),
        catchError(error => {
          console.error("Error in Approve Credential Set", error);
          errorHandler(error);
          return [];
        })
      )
    )
  );
const getRejectCredentialSetObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax.post(REJECT_CREDENTIALSET_URL, params.paramObj).pipe(
        map(xhrResponse => {
          xhrResponse.accessProfId = params.accessProfId;
          return xhrResponse;
        }),
        catchError(error => {
          console.error("Error in reject credential set", error);
          errorHandler(error);
          return [];
        })
      )
    )
  );

export const CredentialSets = props => {
  const dispatch = useDispatch();

  const {
    accessProfileData,
    credentialAccorRef,
    reloadGrid,
    setAccessProfileData,
  } = props;

  const { ifxConfirmToast, ifxInfoToast, ifxErrorToast } = useToast();

  const { userData, toggleAcc } = useContext(UserRegistrationContext);

  const { serializeToLocaleDate } = useUserDateFormat();
  const [credentialSetCols, setCredentialSetCols] = useState([]);
  const [addCredential, setAddCredential] = useState(null);
  const [editCredential, setEditCredential] = useState(null);

  const credentialSetRef = useRef(null);
  useResponsiveGrid(credentialSetRef);
  const maintainUserCredentials = useUserTaskGranted(
    MasterDataTaskConst.MaintainUserCredentials
  );

  useEffect(() => {
    let _columnDefs = credentialSetColDefs({
      serializeToLocaleDate,
      setEditCredential,
      approveCredentialSet,
      rejectCredentialSet,
      maintainUserCredentials,
    });
    setCredentialSetCols(_columnDefs);
  }, []);

  const onHideAddCredential = () => {
    setAddCredential(null);
  };

  const onHideEditCredential = () => {
    setEditCredential(null);
  };

  const deleteCredentialSet$ = useMemo(() => {
    return getDeleteCredentialSetObj$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        ifxErrorToast(CommonToastConsts.ERROR);
      },
    });
  }, []);
  const approveCredentialSet$ = useMemo(() => {
    return getApproveCredentialSetObj$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        ifxErrorToast(CommonToastConsts.ERROR);
      },
    });
  }, []);
  const rejectCredentialSet$ = useMemo(() => {
    return getRejectCredentialSetObj$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        ifxErrorToast(CommonToastConsts.ERROR);
      },
    });
  }, []);

  const reloadCredentialGrid = params => {
    if (params && params.accessProfId) {
      reloadGrid(params.accessProfId);
      return;
    }
    reloadGrid(accessProfileData.id);
  };

  const getSelectedRows = () => {
    const api = credentialSetRef.current.getInstance().gridOptions.api;
    return api.getSelectedRows();
  };

  const deleteCredentialSet = () => {
    const selectedRows = getSelectedRows();
    if (selectedRows.length === 0) {
      ifxInfoToast(CommonToastConsts.SELECT_RECORD);
      return;
    }
    const delCredentialSetConfirm =
      UserRegistrationConsts.DELETE_CREDENTIAL_SET_CONFIRM;
    delCredentialSetConfirm.onCallback = (() => (e, name) => {
      if (name === "Yes") {
        dispatch(
          LayoutActions.SET_LOADER_SHOW({
            loaderText: "Deleting...",
          })
        );
        const params = {
          paramObj: {
            userId: userData.userId,
            userName: userData.userName,
            userAccessProfCredIds: selectedRows.map(item => item.id),
          },
          accessProfId: accessProfileData.id,
        };
        deleteCredentialSet$.next(params);
      }
    })();
    ifxConfirmToast(delCredentialSetConfirm);
  };

  useObservableCallback(
    deleteCredentialSet$,
    response => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      reloadCredentialGrid({ accessProfId: response.accessProfId });
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
    }
  );
  const approveCredentialSet = data => {
    const params = {
      paramObj: {
        userId: userData.userId,

        userAccessProfCredId: data.id,
      },
    };
    approveCredentialSet$.next(params);
  };

  useObservableCallback(
    approveCredentialSet$,
    response => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      reloadCredentialGrid({ accessProfId: response.accessProfId });
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
    }
  );
  const rejectCredentialSet = data => {
    const params = {
      paramObj: {
        userId: userData.userId,
        userAccessProfCredId: data.id,
      },
    };
    rejectCredentialSet$.next(params);
  };

  useObservableCallback(
    rejectCredentialSet$,
    response => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      reloadCredentialGrid({ accessProfId: response.accessProfId });
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
    }
  );

  return (
    <>
      {!isEmptyOrNull(addCredential) ? (
        <AddCredentialSetModal
          onHide={onHideAddCredential}
          show={true}
          addCredential={addCredential}
          reloadGrid={reloadCredentialGrid}
          credentialSet={accessProfileData.credentailSetVO}
        />
      ) : (
        <></>
      )}
      {!isEmptyOrNull(editCredential) ? (
        <EditCredentialSetModal
          onHide={onHideEditCredential}
          show={true}
          editCredential={editCredential}
          reloadGrid={reloadCredentialGrid}
        />
      ) : (
        <></>
      )}
      <IFXAccordion
        title={"Credentials in Access Profile : " + accessProfileData.name}
        isOpen={toggleAcc == "initial" ? true : toggleAcc}
        contentClassName="p-0"
        ref={credentialAccorRef}
        accordionActions={[
          {
            type: "close",
            name: "Close",
            onClick: () => setAccessProfileData({}),
            includeCondition: true,
          },
        ]}
      >
        <Container fluid>
          <Row className="ifx-primary-table-action-wrapper">
            <Col className="pr-1">
              <div className="ifx-table-action-wrapper">
                <div className="ifx-action-btn-controls">
                  <div className="float-right btn-control-action-icons-group">
                    <IFXGridPrimaryIconButton
                      type="add"
                      name="Add Credential"
                      onClick={() => {
                        setAddCredential(accessProfileData);
                      }}
                    />
                    <IFXGridPrimaryIconButton
                      type="delete"
                      name="Delete Credential"
                      onClick={deleteCredentialSet}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <IFXDataTable
          key="credentialSets"
          id="credentialSets"
          ref={credentialSetRef}
          wrapperProps={{ style: { height: 180 } }}
          editable={false}
          columnDefs={credentialSetCols}
          rowData={accessProfileData.credentailSetVO || []}
          tableControlActions={[
            {
              ...DT_DEFAULT_CONTROL_ACTIONS.clearfilter,
            },
            {
              ...DT_DEFAULT_CONTROL_ACTIONS.reload,
              onClick: reloadCredentialGrid,
            },
            {
              ...DT_DEFAULT_CONTROL_ACTIONS.reset,
            },
          ]}
        />
      </IFXAccordion>
    </>
  );
};
