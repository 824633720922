import React, { useState } from "react";
import { Card, FormGroup } from "react-bootstrap";
import {
  IFXTab,
  IFXAccordion,
  IFXAutoSuggest,
  IFXButton,
  IFXFieldWrapper,
} from "../../../components/AppComponents";

const alertHello = () => alert("Button Clicked");

export const NavBarCard = () => {
  const defaultFilterElements = {
    carrier: [],
  };
  const [filterElements, setfilterElements] = useState(defaultFilterElements);

  return (
    <div className="main-card mb-3">
      <div className="card-body">
        <div className="card-title">Basic nav bar Example</div>
        <IFXTab>
          <div name="Nav One">
            <IFXAccordion
              title="Accordion with auto suggest"
              isOpen={false}
              enabled={true}
              isAccordion={true}
            >
              <FormGroup>
                <IFXFieldWrapper
                  label="Carriers"
                  controlId="carrier_navbar"
                  alwaysFloat={(() => filterElements.carrier.length > 0)()}
                  type="group"
                >
                  <IFXAutoSuggest
                    fieldType="carrier"
                    onChange={v => {
                      console.log(v);
                      setfilterElements({ ...filterElements, carrier: v });
                    }}
                    selected={filterElements.carrier}
                  />
                </IFXFieldWrapper>
              </FormGroup>
            </IFXAccordion>
            <IFXAccordion
              title="Accordion with buttons"
              isOpen={false}
              enabled={true}
              isAccordion={true}
            >
              <FormGroup>
                <div>
                  <IFXButton type="submit" name="My Submit" />
                  <IFXButton type="cancel" name="My Cancel" />
                  <IFXButton onClick={alertHello}>Alert Hello</IFXButton>
                </div>
              </FormGroup>
            </IFXAccordion>
          </div>
          <div name="Nav Two">Pill Two</div>
          <div name="Nav Three">Pill Three</div>
        </IFXTab>
      </div>
    </div>
  );
};
