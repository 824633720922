export const SEARCH_SPEC = "SEARCH_SPEC";
export const CREATE_COMPONENT = "CREATE_COMPONENT";
export const ADD_COMPONENT = "ADD_COMPONENT";
export const DELETE_COMPONENT = "DELETE_COMPONENT";
export const SEARCH_SPEC_PRICE = "SEARCH_SPEC_PRICE";
export const USER_PRICEGROUPS_ACTION = "USER_PRICEGROUPS";
export const STORE_USER_PRICEGROUPS_ACTION = ["PUT_USER_PRICEGROUPS"];
export const SPEC_TAG = "SPEC_TAG";
export const SPEC_WHERE_USED = "SPEC_WHERE_USED";
export const SEARCH_CONTRACT_PRICE = "SEARCH_CONTRACT_PRICE";
export const LOAD_SCALE_SEARCH = "LOAD_SCALE_SEARCH";
export const SEARCH_ACDEPENDENCY = "SEARCH_ACDEPENDENCY";
export const AUDIT_TRAIL_FORM_DATA = "AUDIT_TRAIL_FORM_DATA";
export const SEARCH_PRICE_GROUP = "SEARCH_PRICE_GROUP";
export const LOAD_PRICEGROUP_STATION = "LOAD_PRICEGROUP_STATION";
export const SEARCH_SPEC_TYPE = "SEARCH_SPEC_TYPE";
export const SEARCH_ROTATION_TYPE = "SEARCH_ROTATION_TYPE";
export const SEARCH_PRICEPARAMETERS = "SEARCH_PRICEPARAMETERS";
export const PRG_FORM_DATA = "PRG_FORM_DATA";
export const VERSION_TIMELINE = "VERSION_TIMELINE";
export const PRICE_TIMELINE="PRICE_TIMELINE";
export const SPML_ASSIGNMENT="SPML_ASSIGNMENT";
export const CRWML_ASSIGNMENT="CRWML_ASSIGNMENT";
