import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import { IFXDropdown, IFXFieldWrapper } from "../../..";
import { omit, getObjectBySelector } from "../../../../../utils";

export const IFXDropdownCol = forwardRef((props, ref) => {
  //console.log(props);
  const innerRef = useRef();
  const [selected, setSelected] = useState(
    props.value
      ? Array.isArray(props.value)
        ? props.value
        : [props.value]
      : []
  );

  let { rowParams, params = {}, data, onChangeCallBack } = props;

  if (rowParams) {
    Object.entries(rowParams).forEach(entry => {
      const [key, value] = entry;
      params[key] = getObjectBySelector(data, value);
    });
    //console.log(params);
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        //console.log(selected);
        if (props.multiple) {
          return selected;
        } else {
          return selected && selected.length > 0 ? selected[0] : null;
        }
      },
    };
  });

  const onChange = v => {
    if (onChangeCallBack) onChangeCallBack(v, props);
    setSelected(v);
  };

  useEffect(() => {
    window.setTimeout(() => {
      try {
        innerRef.current.getDropdownRef().focus();
      } catch (e) {}
    }, 100);
  }, []);

  const _props = omit(props, [
    "keyPress",
    "charPress",
    "colDef",
    "rowIndex",
    "columnApi",
    "stopEditing",
    "eGridCell",
    "parseValue",
    "formatValue",
    "agGridReact",
    "frameworkComponentWrapper",
    "reactContainer",
    "value",
    "cellStartedEdit",
    "column",
    "node",
    "data",
    "api",
  ]);

  const id = `${props.colDef.field}_${props.rowIndex}`;
  //console.log(selected);
  return (
    <IFXFieldWrapper controlId={id} type="group" marginClassName={false}>
      <IFXDropdown
        {..._props}
        enablePrepend={false}
        ref={innerRef}
        selected={selected}
        onChange={onChange}
      />
    </IFXFieldWrapper>
  );
});
