import {
  QUICK_FILTER_FUNCS,
  CUSTOM_SORT_COMPARATOR,
  ColorOptions,
} from "../../../components/AppComponents";
import { format } from "date-fns";

const printOnClick = (e, data, param, action) => {
  console.log("data:", data);
  console.log("param:", param);
  console.log("action:", action);
  alert(`'${action.type}' is clicked, see console log for params`);
};

export const getServerSideGridColumn = ({ setSelectAll }) => [
  {
    headerName: "Sel",
    headerTooltip: "Sel",
    field: "iconControl",
    checkboxSelection: true,
    editable: false,
    sortable: false,
    suppressMenu: true,
    filter: false,
    pinned: true,
    resizable: false,
    //dragable: false,
    headerComponent: "HeaderSelectAll",
    headerComponentParams: { setSelectAll },
    cellRenderer: "IconCol",
    cellRendererParams: {
      isSelectableKey: "isSelectable",
      wrapperClassName: "validation-icon",
    },
    width: 80,
    flex: false,
  },
  {
    //Number
    headerName: "Flight#",
    headerTooltip: "Flight#",
    field: "flightNo",
    width: 100,
    flex: false,
    editable: false,

    //tooltipField: "flightNo",
  },
  {
    //Autosuggest
    headerName: "Leg#",
    headerTooltip: "Leg#",
    field: "legNo",
    width: 90,
    flex: false, // auto width expand
    valueGetter: ({ data }) => {
      return data && data["legNo"] ? data["legNo"].value : "";
    },
    editable: false,
    getQuickFilterText: QUICK_FILTER_FUNCS.IFXAutoSuggestCol,
    //tooltipField: "legNo.value",
  },
  {
    //Autosuggest
    headerName: "Class",
    headerTooltip: "Class",
    field: "class",
    width: 90,
    flex: false, // auto width expand
    editable: false,
    valueGetter: ({ data }) => {
      return data && data["class"] ? data["class"].value : "";
    },

    getQuickFilterText: QUICK_FILTER_FUNCS.IFXAutoSuggestCol,
    //tooltipField: "class.value",
  },
  {
    //Date
    headerName: "Flight Date",
    headerTooltip: "Flight Date",
    field: "flightDate",
    width: 140,
    flex: false,
    editable: false,
    comparator: CUSTOM_SORT_COMPARATOR.IFXDatePickerCol,
    valueFormatter: params => {
      //console.log(params);
      return params.value && params.value.dateValue
        ? format(params.value.dateValue, "MM/dd/yyyy")
        : "";
    },
    //tooltipField: "flightDate",
  },
  {
    //Date
    headerName: "Flight Time",
    headerTooltip: "Flight Time",
    field: "flightTime",
    width: 130,
    flex: false,
    editable: false,
    valueFormatter: params => {
      //console.log(params);
      return params.value && params.value.dateValue
        ? format(params.value.dateValue, "HH:mm")
        : "";
    },
    //tooltipField: "flightTime",
  },
  {
    //text
    headerName: "Name",
    headerTooltip: "Name",
    field: "name",
    minWidth: "60",
    //tooltipField: "name",
  },
  {
    //text
    headerName: "Description",
    headerTooltip: "Description",
    field: "description",
    editable: false,
    minWidth: "60",
    //tooltipField: "description",
  },
  {
    //Dropdown
    headerName: "Status",
    headerTooltip: "Status",
    field: "status",
    editable: false,
    width: 150,
    flex: false,
    valueGetter: ({ data }) => {
      return data && data["status"] ? data["status"].label : "";
    },
    getQuickFilterText: QUICK_FILTER_FUNCS.IFXDropdownCol,
  },
  {
    headerName: "Action",
    headerTooltip: "Action",
    field: "action",
    editable: false,
    cellRenderer: "ActionCol",
    cellRendererParams: {
      actions: [
        {
          type: "print",
          onClick: printOnClick,
        },
      ],
    },
    //   filter: "agTextColumnFilter",
    //   filterParams: { applyButton: true, resetButton: true },
  },
];
