import React from "react";

//import { useSelector } from "react-redux";
//import { IFXIcons } from "@ifx-react/ifx-react-core";

export const IFXBreadcrumb = props => {
  /* const selector = props.storeSelector
    ? props.storeSelector
    : "CommonReducer.BreadcrumbReducer.navBreadcrumbs"; */
  /* const items = useSelector(state => {
    //console.log(selector);
    let selectors = selector.split(".");
    let val = state;
    selectors.forEach(e => {
      val = val[e];
    });
    return val;
  }); */

  /* const breadCurmbItems = items => {
    if (items.length === 1) {
      return null;
    }
    return items.map((item, key) => {
      let iconArgs = item.icon
        ? typeof item.icon === "string"
          ? { icon: item.icon }
          : item.icon
        : null;
      let icon = item.icon ? (
        <>
          <IFXIcons {...iconArgs} />
          &nbsp;
        </>
      ) : null;
      let isCurrent = key === items.length - 1;
      //console.log(icon);
      let title = (item, isCurrent, icon) => {
        if (isCurrent) {
          return item.title ? item.title : null;
        } else {
          return (
            <a href={item.url ? item.url : "#"}>
              {icon}
              {item.title ? item.title : null}
            </a>
          );
        }
      };
      return (
        <BreadcrumbItem key={key} active={item.active ? true : false}>
          {title(item, isCurrent, icon)}
        </BreadcrumbItem>
      ); 
     // return null;
    });
  }; */
  //console.log(breadCurmbItems);

  return <>{/* <Breadcrumb>{breadCurmbItems(items)}</Breadcrumb> */}</>;
};
