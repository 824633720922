export function LoginReducer(state = {}, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return action.payload;
    case "LOGIN_FAILURE":
      return {
        errorResponse: action.payload,
      };
    case "LOGIN_RESET":
      return {};

    case "LOGOUT_SUCCESS":
      return action.payload;
    case "LOGOUT_FAILURE":
      return { LOGOUT_STATUS: "success" };

    default:
      return state;
  }
}
