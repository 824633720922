import React, { useState, useRef, useCallback, useEffect } from "react";
import { Col, Button, Form, Row, Accordion, Nav } from "react-bootstrap";
import {
  ifxJsonAjax,
  useObservableCallback,
  IFXIcons,
  isEmptyOrNull,
  IFXAlert,
  IFXUserNote,
} from "@ifx-react/ifx-react-core";
import { Subject, timer } from "rxjs";
import PerfectScrollbar from "react-perfect-scrollbar";
import { mergeMap, map, catchError, debounceTime } from "rxjs/operators";
import Slider from "react-slick";
import { LoginActions } from "./../../../store/actions/portal/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "./logout";
import { LayoutActions } from "../../../store/actions/common/actionCreators";
import { LEGAL_NOTICE_URL } from "../../../const/portal/endpoints";
import { LegalNoticePopup } from "./LegalNoticePopup";
import { ResetPassword } from "./ResetPassword";
import specificationBg from "../../../assets/images/login/Specifications.jpeg";
import schedulesBg from "../../../assets/images/login/Schedules.jpeg";
import ordersBg from "../../../assets/images/login/Orders.jpeg";
import invoicesBg from "../../../assets/images/login/Invoices.jpeg";
import galleysBg from "../../../assets/images/login/Galleys.jpeg";
import { VerifyAccount } from "./VerifyAccount";
import { VerifySecretDataModal } from "./VerifySecretDataModal";
import { LoginConsts } from "./loginConts";
import { ChangePasswordModal } from "./ChangePasswordModal";
import { withRouter } from "react-router-dom";
import ReactResizeDetector from "react-resize-detector";

const initialAnimationDelay$ = timer(700);

const resizeDebounceObv$ = new Subject().pipe(debounceTime(100));

const handleResize = () => {
  resizeDebounceObv$.next();
};

const legalNotice$ = new Subject().pipe(
  mergeMap(() =>
    ifxJsonAjax.get(LEGAL_NOTICE_URL).pipe(
      map(xhrResponse => {
        return xhrResponse.response || {};
      }),
      catchError(error => {
        console.error("Legal Notice", error);
        return error;
      })
    )
  )
);

const sliderProps = {
  dots: true,
  infinite: true,
  speed: 5000,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true,
  accessibility: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  // nextArrow: <IFXGridPrimaryIconButton icon="right-arrow" />
  //prevArrow: <IFXGridPrimaryIconButton icon="left-arrow" />
};

const sliderContent = [
  {
    image: specificationBg,
    classTitle: "slider-specification-title",
    classContent: "slider-specification-body",
    title: "Specifications",
    content: `A menu design and planning tool with collaborative features to benefit from 
      both airline and caterer menu design expertise`,
  },
  {
    image: schedulesBg,
    classTitle: "slider-schedules-title",
    classContent: "slider-schedules-body",
    title: "Schedules",
    content: `Rules-based service scheduling to ensure that every flight has the right level of service`,
  },
  {
    image: ordersBg,
    classTitle: "slider-orders-title",
    classContent: "slider-orders-body",
    title: "Orders",
    content: `Day-of-operation service ordering tool, which ensures real-time and accurate communication of 
      orders and can reduce meal wastage`,
  },
  {
    image: invoicesBg,
    classTitle: "slider-invoices-title",
    classContent: "slider-invoices-body",
    title: "Invoices",
    content: `Invoice validation solution that assures billing accuracy and supports a range of models from 
      simple invoice matching to complete reverse billing`,
  },
  {
    image: galleysBg,
    classTitle: "slider-galleys-title",
    classContent: "slider-galleys-body",
    title: "Galleys",
    content: `Next generation galley planning tool with smart integration into all areas of planning 
      and operations process`,
  },
];

const ContactSupport = React.memo(({ setshowToggle, setTitle }) => {
  const onClick = useCallback(() => {
    setshowToggle(false);
    setTitle("Login");
  }, []);
  return (
    <div className="login-form contact-support">
      <div className="contact-content login-link">
        If you are having trouble accessing InFlight Exchange (IFX™),
        <br /> or have any questions about the software suite,
        <br /> Please contact our Help Desk.
      </div>
      <div className="contact-info login-link">
        <span>Phone: (+1) 571 370 9700</span>
        <br />
        <span>
          Email:{" "}
          <a href="mailto:support@paxiasolutions.com">
            support@paxiasolutions.com
          </a>
        </span>
      </div>
      <Button
        variant="primary"
        size="sm"
        className="login-button"
        onClick={onClick}
        block
      >
        Close
      </Button>
    </div>
  );
});

const LoginMessageAlert = React.memo(
  ({
    showRequiredError,
    showChangePasswordModal,
    username,
    onHide,
    setIsNewEula,
    loginClicked,
  }) => {
    const dispatch = useDispatch();

    const {
      AUTHENTICATION_STATUS: authStatus = null,
      LOGOUT_STATUS: logoutStatus,
      errorResponse,
      errorMessages,
      errorParams,
    } = useSelector(state => state.LoginReducer);

    let message = null;
    let color = "danger";
    if (showRequiredError) {
      message = LoginConsts.REQUIRED_ERROR;
    } else if (authStatus === "failed") {
      if (errorMessages && errorMessages.length === 1) {
        let [msg] = errorMessages;
        switch (msg) {
          case "WRONG_CREDENTIALS_PROVIDED": {
            message = (
              <>
                {`Login details you entered are Incorrect. You have ${errorParams[1]} attempt(s) left to enter correct login details before deactivating your account.`}
                <br />
                Please click the "Need Help" link on the logon page if you have
                forgotten your password.
              </>
            );
            break;
          }
          case "ACCOUNT_DEACTIVATED": {
            message = LoginConsts.ACCOUNT_DEACTIVATED;
            break;
          }
          case "USER_NOT_FOUND": {
            message = LoginConsts.USER_NOT_FOUND;
            break;
          }
          default: {
            message = msg;
          }
        }
        if (msg === "PASSWORD_IS_EXPIRED") {
          dispatch(LayoutActions.SET_LOADER_HIDE());
          username = errorParams[0];
          return (
            <ChangePasswordModal
              show={showChangePasswordModal}
              {...{ onHide, username }}
            />
          );
        }
        if (msg === "NEW_EULA_NOT_ACCEPTED" && loginClicked) {
          setIsNewEula(true);
        }
      } else if (errorResponse && errorResponse.message) {
        message = errorResponse.message;
      } else {
        message = null;
      }
    } else if (logoutStatus === "success") {
      color = "success";
      message = LoginConsts.LOGGED_OUT_SUCCESS_INFO;
    } else {
      message = null;
    }
    if (message == null) return null;

    dispatch(LayoutActions.SET_LOADER_HIDE());

    return (
      <IFXAlert paddingClassName="py-3" variant={color}>
        {message}
      </IFXAlert>
    );
  }
);

export const Login = withRouter(
  React.memo(props => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showRequiredError, setShowRequiredError] = useState(false);
    const [showToggle, setshowToggle] = useState(false);
    const [showLegalNotice, setShowLegalNotice] = useState(false);
    const [legalNoticeContent, setLegalNoticeContent] = useState(false);
    const [forgetPassword, setForgetPassword] = useState(false);
    const [verifySecretDataModal, setVerifySecretDataModal] = useState(false);
    const [secretData, setSecretData] = useState({});
    const [title, setTitle] = useState("Login");
    const [loginClicked, setLoginClicked] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(
      false
    );
    const [isNewEula, setIsNewEula] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      isNewEula && legalNotice$.next({});
    }, [isNewEula]);

    const {
      IS_SAML: isSaml,
      SAML_EXTERNAL_LOGIN_URL: samlExternalLoginURL,
    } = useSelector(state => state.LoginReducer);

    const scrollbarRef = useRef();

    const handleUpdateScroll = useCallback(
      () => scrollbarRef.current.updateScroll(),
      []
    );

    useObservableCallback(initialAnimationDelay$, handleUpdateScroll);
    useObservableCallback(resizeDebounceObv$, handleUpdateScroll);

    const submitHandler = useCallback(
      e => {
        if (!(username && password)) {
          setShowRequiredError(true);
          e.preventDefault();
          return;
        }
        showRequiredError && setShowRequiredError(false);
        setShowChangePasswordModal(true);
        setLoginClicked(true);
        e.preventDefault();
        console.log("submitHandler");
        dispatch(LoginActions.RESET());
        dispatch(LoginActions.REQUEST({ username, password }));
        //dispatch();
      },
      [username, password, showRequiredError]
    );

    const showLegalNoticePopup = useCallback(() => {
      if (!legalNoticeContent) {
        legalNotice$.next({});
        return;
      }
      setShowLegalNotice(true);
    }, [legalNoticeContent]);

    useObservableCallback(
      legalNotice$,
      response => {
        const content = response.legalNotice || "";
        setLegalNoticeContent(content);
        setShowLegalNotice(true);
      },
      error => {
        console.error("Error in Legal Notice", error);
      }
    );

    const onHide = useCallback(() => {
      setShowLegalNotice(false);
      setForgetPassword(false);
      setShowChangePasswordModal(false);
      setIsNewEula(false);
      setLoginClicked(false);
    }, []);

    const onHideSecretModal = useCallback(() => {
      setSecretData({});
      setVerifySecretDataModal(false);
    }, []);

    const ContactSupport = ({ setshowToggle }) => {
      return (
        <div className="login-form contact-support">
          <div className="contact-content login-link">
            If you are having trouble accessing InFlight Exchange (IFX™), or
            have any questions about the software suite,
            <br /> please contact our Help Desk.
          </div>
          <div className="contact-info login-link">
            <span>Phone: (+1) 571 370 9700</span>
            <br />
            <span>
              Email:{" "}
              <a href="mailto:support@paxiasolutions.com">
                support@paxiasolutions.com
              </a>
            </span>
          </div>
          <Button
            variant="primary"
            size="sm"
            className="login-button"
            onClick={() => {
              setshowToggle(false);
              setTitle("Login");
            }}
            block
          >
            Close
          </Button>
        </div>
      );
    };

    const verifyYourAccountOnClick = useCallback(
      () => setTitle("Verify Account"),
      []
    );

    const contactSupportOnClick = useCallback(
      () => setTitle("Contact Support"),
      []
    );

    const forgotPasswordOnClick = useCallback(
      () => setForgetPassword(true),
      []
    );

    const needHelpOnClick = useCallback(() => setshowToggle(!showToggle), []);

    const mainLoginBoxClassName =
      title === "Login"
        ? "mx-auto slide-down-animation main-login pb-2 main-login-box"
        : "mx-auto slide-down-animation main-login pb-2";

    return (
      <>
        <div className="bg-animation">
          {showLegalNotice && (
            <LegalNoticePopup
              show={showLegalNotice}
              {...{ username, password, onHide, legalNoticeContent, isNewEula }}
            />
          )}
          {forgetPassword && (
            <ResetPassword
              show={forgetPassword}
              onHide={onHide}
              setSecretData={setSecretData}
              setVerifySecretDataModal={setVerifySecretDataModal}
            />
          )}
          {verifySecretDataModal && !isEmptyOrNull(secretData) && (
            <VerifySecretDataModal
              show={verifySecretDataModal}
              onHide={onHideSecretModal}
              secretData={secretData}
            />
          )}
          <div>
            <Row className="login-page">
              <Col
                md="5"
                className="mx-auto app-login-box login-slider d-none d-lg-block"
              >
                <div className="w-100 mx-auto slide-down-animation">
                  <div className="slider-light">
                    <Slider {...sliderProps}>
                      {sliderContent.map(slider => {
                        return (
                          <div
                            key={slider.title}
                            className="h-100 d-flex justify-content-center align-items-center bg-plum-plate"
                          >
                            <div
                              className="slide-img-bg"
                              style={{
                                backgroundImage: "url(" + slider.image + ")",
                              }}
                            />
                            <div className="slider-content">
                              <div
                                className={slider.classTitle + " slider-title"}
                              >
                                {slider.title}
                              </div>
                              <div
                                className={slider.classContent + " slider-info"}
                              >
                                {slider.content}
                              </div>
                            </div>
                            <div className="shadow-box"></div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </Col>
              <Col md="12" lg="7" className="mx-auto app-login-box">
                <PerfectScrollbar ref={scrollbarRef}>
                  <div className="h-100">
                    <ReactResizeDetector handleHeight onResize={handleResize}>
                      <div>
                        <Col md="6" sm="12" className="mx-auto login-col">
                          <div className={mainLoginBoxClassName}>
                            <a
                              href="http://www.paxiasolutions.com/"
                              className="d-flex justify-content-center paxia-logo"
                              target="_blank"
                            >
                              <div className="logo-src my-ifx-175 login d-inline-block" />
                            </a>
                            <div className="login-box">
                              <div className="d-flex justify-content-center">
                                <div className="ifx-logo-src login" />
                              </div>
                              <div className="login-title text-center">
                                {(!isSaml && title) || ""}
                              </div>
                              {isSaml ? (
                                <div className="login-form">
                                  <LoginMessageAlert
                                    showRequiredError={showRequiredError}
                                    showChangePasswordModal={
                                      showChangePasswordModal
                                    }
                                    onHide={onHide}
                                    username={username}
                                    {...{
                                      setIsNewEula,
                                      loginClicked,
                                    }}
                                  />
                                  {samlExternalLoginURL && (
                                    <div>
                                      <IFXUserNote
                                        noBackground
                                        noBorder
                                        paddingClassName="p-0"
                                      >
                                        <span className="d-inline-block">
                                          You have been successfully logged out
                                          or your session has been timed out.
                                          Click
                                          <Nav.Link
                                            className="p-0 ml-1 my-0 mr-0 d-inline-block"
                                            href={samlExternalLoginURL}
                                          >
                                            here
                                          </Nav.Link>
                                          to Login.
                                        </span>
                                      </IFXUserNote>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <>
                                  {title === "Login" && (
                                    <Form onSubmit={submitHandler}>
                                      <div className="login-form">
                                        <LoginMessageAlert
                                          showRequiredError={showRequiredError}
                                          showChangePasswordModal={
                                            showChangePasswordModal
                                          }
                                          onHide={onHide}
                                          username={username}
                                          {...{
                                            setIsNewEula,
                                            loginClicked,
                                          }}
                                        />
                                        <Form.Row>
                                          <Col md={12}>
                                            <Form.Group>
                                              <Form.Control
                                                name="username"
                                                id="username"
                                                value={username}
                                                placeholder="User Name"
                                                onChange={e =>
                                                  setUsername(e.target.value)
                                                }
                                              />
                                            </Form.Group>
                                          </Col>
                                          <Col md={12}>
                                            <Form.Group>
                                              <Form.Control
                                                type="password"
                                                name="password"
                                                id="password"
                                                placeholder="Password"
                                                onChange={e =>
                                                  setPassword(e.target.value)
                                                }
                                              />
                                            </Form.Group>
                                          </Col>
                                        </Form.Row>
                                        <Button
                                          variant="primary"
                                          size="sm"
                                          type="submit"
                                          className="login-button"
                                          block
                                        >
                                          Login
                                        </Button>

                                        <Accordion className="need-help">
                                          <Accordion.Toggle
                                            as={Button}
                                            variant="link"
                                            eventKey="0"
                                            className="need-help-btn"
                                            onClick={needHelpOnClick}
                                          >
                                            {!showToggle ? (
                                              <IFXIcons
                                                className=""
                                                icon="right-arrow"
                                                iconSource="svg"
                                              />
                                            ) : (
                                              <IFXIcons
                                                className=""
                                                icon="down-arrow"
                                                iconSource="svg"
                                              />
                                            )}
                                            <span className="login-link">
                                              Need Help?
                                            </span>
                                          </Accordion.Toggle>
                                          <Accordion.Collapse
                                            className="need-help-child"
                                            eventKey="0"
                                          >
                                            <>
                                              <Button
                                                variant="link"
                                                size="lg"
                                                type="button"
                                                className="login-link py-0 pr-0"
                                                onClick={forgotPasswordOnClick}
                                              >
                                                Forgot your password?
                                              </Button>
                                              <br />
                                              <Button
                                                variant="link"
                                                size="lg"
                                                type="button"
                                                className="login-link py-0 pr-0"
                                                onClick={contactSupportOnClick}
                                              >
                                                Contact Support
                                              </Button>
                                            </>
                                          </Accordion.Collapse>
                                        </Accordion>
                                      </div>
                                      <div className="new-to-ifx login-link">
                                        New to IFX?
                                      </div>
                                      <div className="register-btn">
                                        <Button
                                          variant="primary"
                                          size="sm"
                                          className="login-button"
                                          onClick={showLegalNoticePopup}
                                          block
                                        >
                                          Register for an Account
                                        </Button>
                                      </div>
                                      <Button
                                        variant="link"
                                        size="lg"
                                        type="button"
                                        className="verify-account login-link"
                                        onClick={verifyYourAccountOnClick}
                                      >
                                        Verify your account
                                      </Button>
                                    </Form>
                                  )}
                                  {title === "Contact Support" && (
                                    <ContactSupport
                                      setshowToggle={setshowToggle}
                                      setTitle={setTitle}
                                    />
                                  )}
                                  {title === "Verify Account" && (
                                    <VerifyAccount
                                      setTitle={setTitle}
                                      setshowToggle={setshowToggle}
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </Col>
                      </div>
                    </ReactResizeDetector>
                  </div>
                </PerfectScrollbar>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  })
);

export { Logout };
