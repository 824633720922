import React, { useCallback, useRef, useEffect, useState } from "react";
import { Nav } from "../AppNav/Nav";
import { LayoutActions } from "../../../../store/actions/common/actionCreators";
import { useDispatch } from "react-redux";
import { useClickedOutside } from "@ifx-react/ifx-react-core";

export const AppSidebar = React.memo(({ height, sideMenuScrollBarRef }) => {
  const [showRightMenu, setHideOnMouseLeave] = useState(false);
  const [iconClicked, setIconClicked] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(LayoutActions.SET_SIDEBAR_CLOSED_ENABLE(true));
  }, []);

  const appSideBarRef = useRef();

  const closeSideBarMenuDetails = useCallback(() => {
    setHideOnMouseLeave(true);
    setIconClicked(null);
  }, []);
  useClickedOutside(appSideBarRef, closeSideBarMenuDetails);
  return (
    <>
      {/* <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} /> */}
      <div className="app-sidebar" ref={appSideBarRef}>
        <Nav
          showRightMenu={showRightMenu}
          setHideOnMouseLeave={setHideOnMouseLeave}
          height={height}
          sideMenuScrollBarRef={sideMenuScrollBarRef}
          {...{ iconClicked, setIconClicked }}
        />
      </div>
    </>
  );
});
