export const AJAX_HEADER_IFX_REACT = {
  "ifx-react": "true",
};

export const AJAX_HEADER_FORM_URLENCODED = {
  "Content-Type": "application/x-www-form-urlencoded",
};

export const AJAX_HEADER_JSON = {
  ...AJAX_HEADER_IFX_REACT,
  "Content-Type": "application/json",
};

export const AJAX_HEADER_APP_DEFAULT = {
  ...AJAX_HEADER_IFX_REACT,
  ...AJAX_HEADER_FORM_URLENCODED,
};
