export const SYSTEM_ERROR_MSG =
  "There appears to be an issue. Please contact Support team for assistance";

export const CommonToastConsts = {
  DELETE_CONFIRM: {
    content: "Do you want to permanently delete?",
  },
  NO_RECORDS: {
    content: "No Records Available",
  },
  NO_RECORDS_FOUND: {
    content: "No Records Found.",
  },
  ERROR: {
    content: SYSTEM_ERROR_MSG,
  },
  TIMEOUT: {
    content: "Request Timed out. Please try again later.",
  },
  SELECT_RECORD: {
    content: "Please select at least one record.",
  },
  WARNING: {
    content: "Hmmm. Something doesn't look right.",
  },
  CREATE_SUCCESS: {
    content: "Record has been successfully created.",
  },
  UPDATE_SUCCESS: {
    content: "Record has been successfully updated.",
  },
  DELETE_SUCCESS: {
    content: "Record has been successfully deleted.",
  },
  APPROVE_CONFIRM: {
    content: "Do you want to Approve this Spec Price?",
  },
  CONFIRM_DRAFT_PROPOSE: {
    content: "Do you want to Propose this Spec Price?",
  },
  CONFIRM_ALERT_ICON: {
    content:
      "You will navigate away from the current application. Do you wish to continue?",
  },
  DELETE_CONFIRM_STANDARD: {
    content: "Are you sure you want to delete the selected records?",
  },
  DATATABLE_VALIDATION_ERROR: {
    content:
      "Operation cannot be completed. Please check error icon for details.",
  },
  MANDATORY_FIELDS: {
    content: "Please enter mandatory fields",
  },
};

export const SPEC_LOOKUP_TOAST = {
  SELECT_ATLEAST_ONE: {
    content: "Please select at least one Spec",
  },
};
