import initialState from "./initialState";

export function QueryFSSReducer(state = initialState.QueryFSSReducer, action) {
  switch (action.type) {
    case "SEARCH_FSS_REQUEST":
      return {
        ...state,
        searchFssCriteria: action.payload,
      };
    case "SEARCH_RELEASE_FSS_REQUEST":
      return {
        ...state,
        searchReleaseFssCriteria: action.payload,
      };
    case "SRH_REL_FSS_CREATE_SO_FLT_LEG_REQUEST":
      let soCreateJobs = [...state.soCreateJobs];
      soCreateJobs.push(action.payload);
      return {
        ...state,
        soCreateJobs: soCreateJobs,
      };
    case "SRH_REL_FSS_CREATE_SO_LEGIDS_REQUEST":
      return {
        ...state,
        fssLegIds: action.payload,
      };
    case "SRH_REL_FSS_CREATE_SO_JOBIDS_REQUEST":
      return {
        ...state,
        jobIds: action.payload,
      };
    case "FSS_FLIGHT_INFO_REQUEST":
      return {
        ...state,
        flightInfo: action.payload,
      };
    case "FSS_PRINT_INFO_REQUEST":
      return {
        ...state,
        printInfo: action.payload,
      };
    default:
      return state;
  }
}
