import React from "react";
import cx from "classnames";
import { InputGroup, Form, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { omit } from "../../utils";

const DEFAULT_PROPS = {
  className: "ifx-number-textbox",
  size: "sm",
  autoComplete: "off",
  type: "number",
  // required: false,
};

/**
 * @deprecated In favor of IFXNumberRangeTextBox
 */
export const IFXNumberRangePicker = ({
  isInvalid = [],
  controlId = [],
  alwaysFloat = [],
  wrapperState = { from: {}, to: {} },
  numberonly = true,
  split = true,
  required = [],
  ...props
}) => {
  console.warn(
    "IFXNumberRangePicker is deprecated in favor of IFXNumberRangeTextBox!"
  );
  const {
    onFocus = [],
    onBlur = [],
    names = [],
    label = [],
    selected = {},
  } = props;

  let _props = { ...DEFAULT_PROPS, className: "", ...props };

  let { maxLength, onKeyPress, inputGroupClassName = "" } = _props;

  _props = omit(_props, ["inputGroupClassName"]);

  const handleKeyPress = e => {
    let regEx;
    if (numberonly) {
      regEx = /[0-9]$/;
    } else {
      regEx = /[0-9.]$/;
    }
    if (!regEx.test(e.key) || e.target.value.length + 1 > maxLength) {
      e.preventDefault();
      return;
    }
    if (onKeyPress) onKeyPress(e);
  };

  let onFocusFrom, onFocusTo, onBlurFrom, onBlurTo;
  if (Array.isArray(onFocus)) {
    [onFocusFrom, onFocusTo] = onFocus;
  } else {
    onFocusFrom = onFocusTo = onFocus;
  }

  if (Array.isArray(onBlur)) {
    [onBlurFrom, onBlurTo] = onBlur;
  } else {
    onBlurFrom = onBlurTo = onBlur;
  }

  const onChange = (key, v) => {
    v = { ...selected, [key]: v.target.value };
    if (v.to !== "" && v.from !== "" && Number(v.from) > Number(v.to)) {
      let newVal = { ...v, isInvalid: true };
      if (_props.onChange) _props.onChange(newVal);
      return;
    } else {
      let newVal = { ...v, isInvalid: false };
      if (_props.onChange) _props.onChange(newVal);
    }
  };

  let _selected = { from: null, to: null };
  if (selected != null) {
    if (selected.from) {
      _selected.from = selected.from;
    }
    if (selected.to) {
      _selected.to = selected.to;
    }
  }

  if (split) {
    return (
      <>
        <Row>
          <Col sm="6">
            <div
              className={cx(
                "range-field-wrapper range-from position-relative",
                {
                  "floating-label-active":
                    wrapperState.from.isFocused || alwaysFloat[0],
                  "field-mandatory": required[0],
                },
                isInvalid[0] && "is-invalid"
              )}
            >
              <Form.Control
                {..._props}
                className={cx(DEFAULT_PROPS.className, _props.className)}
                name={names[0]}
                id={controlId[0]}
                key="from"
                onFocus={onFocusFrom}
                onBlur={onBlurFrom}
                selected={_selected.from}
                max={_selected.to}
                value={_selected.from || ""}
                onChange={v => {
                  onChange("from", v);
                }}
                onKeyPress={handleKeyPress}
              />
              {label && (
                <Form.Label htmlFor={controlId[0]}>{label[0]}</Form.Label>
              )}
              <Form.Control.Feedback type="ifx-field-error invalid">
                {wrapperState.from.errorMessage}
              </Form.Control.Feedback>
            </div>
          </Col>
          <Col sm="6">
            <div
              className={cx(
                "range-field-wrapper range-to position-relative",
                {
                  "floating-label-active":
                    wrapperState.to.isFocused || alwaysFloat[1],
                  "field-mandatory": required[1],
                },
                isInvalid[1] && "is-invalid"
              )}
            >
              <Form.Control
                {..._props}
                className={cx(DEFAULT_PROPS.className, _props.className)}
                name={names[1]}
                id={controlId[1]}
                key="to"
                onFocus={onFocusTo}
                onBlur={onBlurTo}
                selected={_selected.to}
                value={_selected.to || ""}
                onChange={v => {
                  onChange("to", v);
                }}
                max={_selected.to}
                onKeyPress={handleKeyPress}
              />
              {label && (
                <Form.Label htmlFor={controlId[1]}>{label[1]}</Form.Label>
              )}
              <Form.Control.Feedback type="ifx-field-error invalid">
                {wrapperState.to.errorMessage}
              </Form.Control.Feedback>
            </div>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <>
        <InputGroup
          size={_props.size}
          className={cx(inputGroupClassName)}
          title="Number Range"
        >
          <div
            className={cx(
              "range-field-wrapper range-from position-relative",
              {
                "floating-label-active":
                  wrapperState.from.isFocused || alwaysFloat[0],
                "field-mandatory": required[0],
              },
              isInvalid[0] && "is-invalid"
            )}
          >
            <Form.Control
              {..._props}
              className={cx(DEFAULT_PROPS.className, _props.className)}
              name={names[0]}
              id={controlId[0]}
              key="from"
              onFocus={onFocusFrom}
              onBlur={onBlurFrom}
              selected={_selected.from}
              max={_selected.to}
              value={_selected.from || ""}
              onChange={v => {
                onChange("from", v);
              }}
              onKeyPress={handleKeyPress}
            />
            {label && (
              <Form.Label htmlFor={controlId[0]}>{label[0]}</Form.Label>
            )}
            <Form.Control.Feedback type="ifx-field-error invalid">
              {wrapperState.from.errorMessage}
            </Form.Control.Feedback>
          </div>
          <div
            className={cx(
              "range-field-wrapper range-to position-relative",
              {
                "floating-label-active":
                  wrapperState.to.isFocused || alwaysFloat[1],
                "field-mandatory": required[1],
              },
              isInvalid[1] && "is-invalid"
            )}
          >
            <Form.Control
              {..._props}
              className={cx(DEFAULT_PROPS.className, _props.className)}
              name={names[1]}
              id={controlId[1]}
              key="to"
              onFocus={onFocusTo}
              onBlur={onBlurTo}
              selected={_selected.to}
              value={_selected.to || ""}
              onChange={v => {
                onChange("to", v);
              }}
              max={_selected.to}
              onKeyPress={handleKeyPress}
            />
            {label && (
              <Form.Label htmlFor={controlId[1]}>{label[1]}</Form.Label>
            )}
            <Form.Control.Feedback type="ifx-field-error invalid">
              {wrapperState.to.errorMessage}
            </Form.Control.Feedback>
          </div>
        </InputGroup>
      </>
    );
  }
};
IFXNumberRangePicker.prototype = {
  from: PropTypes.string,
  to: PropTypes.string,
};
