import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Nav } from "react-bootstrap";
import { IFXCheckbox } from "../../components/AppComponents";

export const IFXMultiSelectCheckbox = props => {
  const [getDefaultVal, setDefaultVal] = useState([]);

  const inputCheckBox = useRef([]);

  const allCheckBox = useRef(null);

  useEffect(() => {
    setDefaultVal(props.data);
  }, []);

  const [getCheckboxVal, setCheckboxVal] = useState([]);

  function handleChangeCheckbox(e) {
    const checkBoxId = e.target.id;

    let finalArray = [];

    if (e.target.checked) {
      if (e.target.value === "all") {
        const allChecked = [];
        getDefaultVal.forEach(item => {
          inputCheckBox.current[item.id].checked = true;
          allChecked.push(item);
        });
        setCheckboxVal(allChecked);
        finalArray = allChecked;
      } else {
        const accessOrgAry = getDefaultVal.filter(item => {
          return item.id === checkBoxId;
        });
        setCheckboxVal(prev => [...prev, accessOrgAry[0]]);
        finalArray = [...getCheckboxVal, accessOrgAry[0]];
      }
    } else {
      if (e.target.value === "all") {
        getDefaultVal.forEach(item => {
          inputCheckBox.current[item.id].checked = false;
        });
        setCheckboxVal([]);
        finalArray = [];
      } else {
        allCheckBox.current.checked = false;
        let removeId = getCheckboxVal.filter(item => {
          return item.id !== checkBoxId;
        });
        setCheckboxVal(removeId);
        finalArray = removeId;
      }
    }
    props.selectedVal(finalArray);
  }

  return (
    <Row id="multiselectedCard">
      <Col md={5}>
        <div>
          <Form.Label>{props.containerLeftName}</Form.Label>
          <div className="ifx-multi-checkbox">
            <Nav.Item className="ml-3">
              <IFXCheckbox
                id="all"
                value="all"
                label="All"
                onChange={e => handleChangeCheckbox(e)}
                ref={el => (allCheckBox.current = el)}
              />
            </Nav.Item>
            {getDefaultVal.map(item => (
              <Nav.Item className="ml-3" key={item.id}>
                <IFXCheckbox
                  id={item.id}
                  value={item.value}
                  label={item.display}
                  onChange={e => handleChangeCheckbox(e)}
                  ref={el => (inputCheckBox.current[item.id] = el)}
                />
              </Nav.Item>
            ))}
          </div>
        </div>
      </Col>
    </Row>
  );
};

IFXMultiSelectCheckbox.prototype = {
  containerLeftName: PropTypes.string,
  containerRightName: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedVal: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    })
  ).isRequired,
};
