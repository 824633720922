import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
  memo,
} from "react";
import { IFXIcons } from "../../..";

const DEFAULT_PROPS = {
  iconPropsCollapsed: { icon: "angle-right" },
  iconPropsExpanded: { icon: "angle-down" },
};

export const TreeControlHeader = forwardRef((props, ref) => {
  const _props = { ...DEFAULT_PROPS, ...props };
  const { api, iconPropsCollapsed, iconPropsExpanded } = _props;

  const [isTreeExpand, setIsTreeExpand] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses: () => {
        return ["ifx-tree-header-container w-100"];
      },
    };
  });

  const onActionClick = () => {
    //console.log("api", api);
    const _isTreeExpand = !isTreeExpand;

    if (_isTreeExpand) {
      api.expandAll();
    } else {
      api.collapseAll();
    }

    setIsTreeExpand(_isTreeExpand);
  };
  const iconProps = isTreeExpand ? iconPropsExpanded : iconPropsCollapsed;

  const onRowGroupOpened = useCallback(({ api }) => {
    let _isTreeExpand = true;
    api.forEachNodeAfterFilter(node => {
      node.childrenAfterFilter &&
        node.childrenAfterFilter.length > 0 &&
        !node.expanded &&
        (_isTreeExpand = false);
    });
    setIsTreeExpand(_isTreeExpand);
  }, []);

  useEffect(() => {
    api.addEventListener("rowGroupOpened", onRowGroupOpened);
    api.addEventListener("filterChanged", onRowGroupOpened);
    return () => {
      api.removeEventListener("rowGroupOpened", onRowGroupOpened);
      api.addEventListener("filterChanged", onRowGroupOpened);
    };
  }, []);

  return (
    <div
      className="tree-header-icon w-100 "
      style={{ cursor: "pointer" }}
      onClick={onActionClick}
    >
      <IFXIcons {...iconProps} />
    </div>
  );
});
