import React from "react";
import { Row, Col } from "react-bootstrap";
import { IFXTooltip } from ".";

export const IFXLabelValue = props => {
  const {
    label = "",
    value = "",
    seperator: { seperatorValue = ":", isSeperator = true } = {},
    colprops: {
      labelProp = {
        sm: 6,
        className: `text-nowrap text-right ${isSeperator ? "pr-0" : ""}`,
      },
      valueProp = {
        sm: 5,
        className: "pl-0 text-nowrap ",
      },
    } = {},
    className = "form-control-label form-control-sm u-font-wieght--medium",
    truncate: { isTruncate = false, truncateValue = 40 } = {},
  } = props;
  const _props = {
    ...props,
    className,
  };
  const _valProp = valueProp.className
    ? valueProp
    : { ...valueProp, className: "pl-0 text-nowrap" };

  const _labelProp = labelProp.className
    ? labelProp
    : {
        ...labelProp,
        className: `text-nowrap text-right ${isSeperator ? "pr-0" : ""}`,
      };

  const textTruncate = (str, num) => {
    let ending = "...";
    let val = undefined;
    str
      ? str.length > num
        ? (val = str.substring(0, num - ending.length) + ending)
        : (val = str)
      : (val = null);
    if (val) val = val.split(",").join(", ");
    return val;
  };
  return (
    <Row {..._props}>
      <Col {..._labelProp}>{label}</Col>
      {isSeperator && (
        <Col sm={1} className="p-0">
          {seperatorValue}
        </Col>
      )}
      {isTruncate && value && Number(value.length) > Number(truncateValue) ? (
        <IFXTooltip placement="bottom" content={value}>
          <Col {..._valProp}>{textTruncate(value, truncateValue)}</Col>
        </IFXTooltip>
      ) : (
        <Col {..._valProp}>{value}</Col>
      )}
    </Row>
  );
};
