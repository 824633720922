import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import { IFXAutoSuggest } from "../../..";
import { omit, getObjectBySelector } from "../../../../../..";

export const IFXAutoSuggestCol = forwardRef((props, ref) => {
  //console.log("IFXAutoSuggestCol props  data>>>>", props);

  const innerRef = useRef();
  const [selected, setSelected] = useState(
    props.value
      ? Array.isArray(props.value)
        ? props.value
        : [props.value]
      : []
  );

  let { rowParams, params = {}, data, onChangeCallBack } = props;

  if (rowParams) {
    Object.entries(rowParams).forEach(entry => {
      const [key, value] = entry;
      params[key] = getObjectBySelector(data, value);
    });
    //console.log(params);
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        //console.log(selected);
        if (props.multiple) {
          return selected;
        } else {
          return selected && selected.length > 0 ? selected[0] : null;
        }
      },
    };
  });

  const onChange = v => {
    setSelected(v);
    if (onChangeCallBack) onChangeCallBack(v, data, props);
  };

  useEffect(() => {
    window.setTimeout(() => {
      try {
        const inputEle = innerRef.current.getInstance().getInput();
        inputEle.focus();
        !props.multiple && inputEle.select();
      } catch (e) {}
    }, 100);
  }, []);

  const _props = omit(props, [
    "keyPress",
    "charPress",
    "colDef",
    "rowIndex",
    "columnApi",
    "stopEditing",
    "eGridCell",
    "parseValue",
    "formatValue",
    "agGridReact",
    "frameworkComponentWrapper",
    "reactContainer",
    "value",
    "cellStartedEdit",
    "column",
    "node",
    "data",
    "api",
    "onChangeCallBack",
  ]);

  const id = `${props.colDef.field}_${props.rowIndex}`;
  //console.log(selected);
  return (
    <IFXAutoSuggest
      {..._props}
      enablePrepend={false}
      ref={innerRef}
      id={id}
      selected={selected}
      //options={selected}
      onChange={onChange}
      params={params}
    />
  );
});
