import React, {memo} from "react";
import cx from "classnames";
import { Row, Col } from "react-bootstrap";

export const PlainReadOnlyCell = memo(({ value }) => (
  <Row className="m-0">
    <Col className="d-flex px-2">
      <span className="ifx-cell-renderer-readonly ifx-text-overflow">
        {value}
      </span>
    </Col>
  </Row>
));

export const EditableFacade = memo(props => {
  //console.log(props);
  const {
    value,
    valueFormatted,
    colDef: { editable = true },
    plainReadOnlyType,
    required = false,
    isInvalid,
  } = props;

  const _editable = typeof editable === "function" ? editable(props) : editable;
  const _required = typeof required === "function" ? required(props) : required;
  const _plainReadOnlyType =
    typeof plainReadOnlyType === "function"
      ? plainReadOnlyType(props)
      : plainReadOnlyType;

  const _isInvalid =
    typeof isInvalid === "function" ? isInvalid(props) : isInvalid;
  //console.log(_editable);

  const _value = valueFormatted ? valueFormatted : value;

  if (_plainReadOnlyType && !_editable) {
    return <PlainReadOnlyCell value={_value} />;
  }

  return (
    <div
      //title={_value}
      className={cx("form-control form-control-sm ifx-text-overflow", {
        disabled: !_editable,
        "field-mandatory": _required,
        "is-invalid": _isInvalid,
      })}
    >
      {_value}
    </div>
  );
});
