import { combineReducers } from "redux";
import { CommonReducer } from "./common";
import { PortalReducer } from "./portal";
import { LoginReducer } from "./portal/LoginReducer";
import { EssReducer } from "./ess";
import { EsmReducer } from "./esm";
import { EsoReducer } from "./eso";
import { EsvReducer } from "./esv";

const appRootReducer = combineReducers({
  LoginReducer,
  CommonReducer,
  PortalReducer,
  EssReducer,
  EsmReducer,
  EsoReducer,
  EsvReducer,
});

export default function RootReducer(state, action) {
  switch (action.type) {
    case "RESET_REDUX": {
      console.log("RESET_REDUX Root");
      // return undefined;
    }
    default:
      return appRootReducer(state, action);
  }
}
