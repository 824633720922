import React, { useRef, useState, useEffect, useMemo, useContext } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import {
  IFXAccordion,
  IFXDataTable,
  IFXGridPrimaryIconButton,
  DT_DEFAULT_CONTROL_ACTIONS,
  useToast,
  ifxJsonAjax,
  isEmptyOrNull,
  useObservableCallback,
} from "@ifx-react/ifx-react-core";
import { Subject } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { useResponsiveGrid } from "../../../hooks/common/layoutHooks";
import { userAccessProfilesColDefs } from "./UserAccessProfilesColDefs";
import { useUserDateFormat } from "../../../hooks/common/useUserDateFormat";
import { AddAccessProfileModal } from "./AddAccessProfileModal";
import { EditAccessProfileModal } from "./EditAccessProfileModal";
import { ViewTaskModal } from "./ViewTaskModal";
import { UserRegistrationConsts } from "./userRegistrationConsts";
import { CommonToastConsts } from "../../../const/common/toastConsts";
import { LayoutActions } from "../../../store/actions/common/actionCreators";
import {
  DELETE_ACCESS_PROFILE_URL,
  GET_ACCESS_PROFILES_URL,
  ACTIVATE_ACCESS_PROFILE_URL,
  DEACTIVATE_ACCESS_PROFILE_URL,
  VIEW_TASK_URL,
} from "../../../const/portal/endpoints";
import { CredentialSets } from "./CredentialSets";
import { UserRegistrationContext } from "./UserRegistration";

const getDeleteAccessProfileObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax.post(DELETE_ACCESS_PROFILE_URL, params).pipe(
        map(xhrResponse => xhrResponse),
        catchError(error => {
          console.error("Error in Delete Access Profile", error);
          errorHandler(error);
          return [];
        })
      )
    )
  );

const getViewTaskObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(accessProfileId =>
      ifxJsonAjax
        .post(VIEW_TASK_URL + "?useracessprofileId=" + accessProfileId)
        .pipe(
          map(xhrResponse => xhrResponse.response || {}),
          catchError(error => {
            console.error("Error in View Task", error);
            errorHandler(error);
            return [];
          })
        )
    )
  );

const getAccessProfilesObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax
        .get(GET_ACCESS_PROFILES_URL + "?editUserName=" + params.userName)
        .pipe(
          map(xhrResponse => {
            xhrResponse.response.accessProfId = params.accessProfileId;
            return xhrResponse.response;
          }),
          catchError(error => {
            console.error("Error in Access Profile", error);
            errorHandler(error);
            return [];
          })
        )
    )
  );

const getProfileStatusObj$ = ({ errorHandler }) =>
  new Subject().pipe(
    mergeMap(params =>
      ifxJsonAjax.post(params.url + "?" + params.queryString).pipe(
        map(xhrResponse => xhrResponse.response),
        catchError(error => {
          console.error(
            "Error in Changing the status of User Access Profile",
            error
          );
          errorHandler(error);
          return [];
        })
      )
    )
  );

export const UserAccessProfiles = props => {
  const dispatch = useDispatch();

  const { serializeToLocaleDate } = useUserDateFormat();
  const [accessProfilesColDefs, setAccessProfilesColDefs] = useState();
  const [showAddAccessProf, setShowAddAccessProf] = useState(false);
  const [editAccessProfile, setEditAccessProfile] = useState(null);
  const [accessProfileData, setAccessProfileData] = useState([]);
  const [viewTaskData, setViewTaskData] = useState(null);
  const [userAccessProfiles, setUserAccessProfiles] = useState(
    props.userAccessProfiles || []
  );
  const userData = props.userData || {};
  const profilesAccessRef = useRef(null);
  const credentialAccorRef = useRef(null);
  useResponsiveGrid(profilesAccessRef);

  const { ifxConfirmToast, ifxInfoToast, ifxErrorToast } = useToast();
  const { toggleAcc, editedBy } = useContext(UserRegistrationContext);

  useEffect(() => {
    let _columnDefs = userAccessProfilesColDefs({
      serializeToLocaleDate,
      showCredentialSet,
      setEditAccessProfile,
      changeStatus,
      viewTask,
      editedBy,
    });
    setAccessProfilesColDefs(_columnDefs);
  }, []);

  useEffect(() => {
    scrollToCredentialSet();
  }, [accessProfileData]);

  const showCredentialSet = data => {
    setAccessProfileData(data || {});
  };

  const scrollToCredentialSet = () => {
    credentialAccorRef.current &&
      props.scrollbarRef.current._container.scrollTo({
        top: credentialAccorRef.current.getInstance().offsetTop,
        behavior: "smooth",
      });
  };

  const viewTask$ = useMemo(() => {
    return getViewTaskObj$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        ifxErrorToast(CommonToastConsts.ERROR);
        onHideViewTask();
      },
    });
  }, []);

  const viewTask = accessProfileId => {
    dispatch(
      LayoutActions.SET_LOADER_SHOW({
        loaderText: "Loading...",
      })
    );
    viewTask$.next(accessProfileId);
  };

  const changeProfileStatus$ = useMemo(() => {
    return getProfileStatusObj$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        ifxErrorToast(CommonToastConsts.ERROR);
        onHide();
      },
    });
  }, []);

  const changeStatus = (data, status) => {
    const queryString = `userAccessProfId=${data.id}&userId=${userData.userId}`;
    if (status === "activate") {
      dispatch(
        LayoutActions.SET_LOADER_SHOW({
          loaderText: "Deactivating...",
        })
      );
      changeProfileStatus$.next({
        url: DEACTIVATE_ACCESS_PROFILE_URL,
        queryString,
      });
      return;
    }
    dispatch(
      LayoutActions.SET_LOADER_SHOW({
        loaderText: "Activating...",
      })
    );
    changeProfileStatus$.next({
      url: ACTIVATE_ACCESS_PROFILE_URL,
      queryString,
    });
  };

  const onHide = () => {
    setShowAddAccessProf(false);
  };

  const onHideViewTask = () => {
    setViewTaskData(null);
  };

  const onHideEditAccessProf = () => {
    setEditAccessProfile(null);
  };

  const deleteAccessProfiles$ = useMemo(() => {
    return getDeleteAccessProfileObj$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        ifxErrorToast(CommonToastConsts.ERROR);
        onHide();
      },
    });
  }, []);

  const getSelectedRows = () => {
    const api = profilesAccessRef.current.getInstance().gridOptions.api;
    return api.getSelectedRows();
  };

  const deleteAccessProfile = () => {
    const selectedRows = getSelectedRows();
    if (selectedRows.length === 0) {
      ifxInfoToast(CommonToastConsts.SELECT_RECORD);
      return;
    }
    const delAccessProfileConfirm =
      UserRegistrationConsts.DELETE_ACCESS_PROFILE_CONFIRM;
    delAccessProfileConfirm.onCallback = (() => (e, name) => {
      if (name === "Yes") {
        dispatch(
          LayoutActions.SET_LOADER_SHOW({
            loaderText: "Deleting...",
          })
        );
        deleteAccessProfiles$.next({
          userId: userData.userId,
          userName: userData.userName,
          userAccessProfIds: selectedRows.map(item => item.id),
        });
      }
    })();
    ifxConfirmToast(delAccessProfileConfirm);
  };

  const reloadGrid = accessProfileId => {
    dispatch(
      LayoutActions.SET_LOADER_SHOW({
        loaderText: "Loading...",
      })
    );
    getAccessProfile$.next({
      userName: userData.userName,
      accessProfileId,
    });
  };

  const getAccessProfile$ = useMemo(() => {
    return getAccessProfilesObj$({
      errorHandler: error => {
        dispatch(LayoutActions.SET_LOADER_HIDE());
        ifxErrorToast(CommonToastConsts.ERROR);
        onHide();
      },
    });
  }, []);

  useObservableCallback(
    deleteAccessProfiles$,
    response => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      reloadGrid();
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
    }
  );

  useObservableCallback(
    getAccessProfile$,
    response => {
      setUserAccessProfiles(response.accessProfileList || []);
      if (response.accessProfId) {
        const credData = response.accessProfileList.find(
          item => item.id === response.accessProfId
        );
        setAccessProfileData(credData);
      } else {
        setAccessProfileData([]);
      }
      dispatch(LayoutActions.SET_LOADER_HIDE());
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
    }
  );

  useObservableCallback(
    changeProfileStatus$,
    response => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
      reloadGrid();
    },
    error => {
      dispatch(LayoutActions.SET_LOADER_HIDE());
    }
  );

  useObservableCallback(viewTask$, response => {
    dispatch(LayoutActions.SET_LOADER_HIDE());
    setViewTaskData(response.taskList);
  });

  return (
    <>
      {showAddAccessProf && (
        <AddAccessProfileModal
          onHide={onHide}
          show={showAddAccessProf}
          reloadGrid={reloadGrid}
          userAccessProfiles={userAccessProfiles}
        />
      )}
      {!isEmptyOrNull(editAccessProfile) ? (
        <EditAccessProfileModal
          onHide={onHideEditAccessProf}
          show={true}
          editAccessProfile={editAccessProfile}
          reloadGrid={reloadGrid}
        />
      ) : (
        <></>
      )}
      {!isEmptyOrNull(viewTaskData) ? (
        <ViewTaskModal
          onHide={onHideViewTask}
          show={true}
          viewTaskData={viewTaskData}
        />
      ) : (
        <></>
      )}
      <IFXAccordion
        title="User Access Profiles"
        isOpen={toggleAcc == "initial" ? true : toggleAcc}
        contentClassName="p-0"
        ref={props.accessProfilesRef}
      >
        {editedBy === "admin" && (
          <Container fluid>
            <Row className="ifx-primary-table-action-wrapper">
              <Col className="pr-1">
                <div className="ifx-table-action-wrapper">
                  <div className="ifx-action-btn-controls">
                    <div className="float-right btn-control-action-icons-group">
                      <IFXGridPrimaryIconButton
                        type="add"
                        name="Add Access Profile"
                        onClick={() => {
                          setShowAddAccessProf(true);
                        }}
                      />
                      <IFXGridPrimaryIconButton
                        type="delete"
                        name="Delete Access Profile"
                        onClick={deleteAccessProfile}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}
        <IFXDataTable
          key="userAccessProfiles"
          id="userAccessProfiles"
          ref={profilesAccessRef}
          wrapperProps={{ style: { height: 300 } }}
          editable={false}
          columnDefs={accessProfilesColDefs}
          rowData={userAccessProfiles}
          getRowClass={({ data }) => {
            if (data?.isCredentialSetNew === true)
              return "sqr-version-highlight";
          }}
          tableControlActions={[
            {
              ...DT_DEFAULT_CONTROL_ACTIONS.clearfilter,
            },
            {
              ...DT_DEFAULT_CONTROL_ACTIONS.reload,
              onClick: reloadGrid,
            },
            {
              ...DT_DEFAULT_CONTROL_ACTIONS.reset,
            },
          ]}
        />
      </IFXAccordion>
      {!isEmptyOrNull(accessProfileData) && (
        <CredentialSets
          accessProfileData={accessProfileData}
          credentialAccorRef={credentialAccorRef}
          reloadGrid={reloadGrid}
          setAccessProfileData={setAccessProfileData}
        />
      )}
    </>
  );
};
