// Component Name: IFXTab
// Purpose: For collapsable Items as card view
// Properties:
//   title = type string
//   div elements = type block scope with data for individual pill
// Developed by: Anik Chakraborty

import React from "react";
import { Nav, Tab } from "react-bootstrap";
import PropTypes from "prop-types";
import cx from "classnames";

/**
 * @deprecated In favor of IFXTab2
 */
export const IFXTab = React.memo(props => {
  console.warn("IFXFieldset is deprecated in favor of IFXTab2!");
  const { children, defaultActiveKey, id, size = "sm" } = props;

  let _children = [];
  children.forEach(c => {
    if (c !== null) _children.push(c);
  });

  const onChange = v => {
    if (props.onChange) props.onChange(v);
  };

  return (
    <>
      <div
        className={cx("ifx-tab-container", {
          [`ifx-tab-container-${size}`]: size,
        })}
      >
        <Tab.Container
          defaultActiveKey={
            defaultActiveKey
              ? defaultActiveKey
              : _children.findIndex(child => !child.props.disabled)
          }
          id={id ? id : "ifx-uncontrolled-tabs"}
          onSelect={v => {
            onChange(v);
          }}
        >
          <Nav variant="pills">
            {_children.map((each, idx) => {
              return each.type === "div" ? (
                <Nav.Item key={idx}>
                  <Nav.Link
                    eventKey={idx.toString()}
                    disabled={each.props.disabled ? true : false}
                  >
                    {each.props.name}
                  </Nav.Link>
                </Nav.Item>
              ) : null;
            })}
          </Nav>
          <Tab.Content>
            {_children.map((each, idx) => {
              return (
                <Tab.Pane key={idx} className="p-0" eventKey={idx.toString()}>
                  {each.props.children}
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
});
IFXTab.propTypes = {
  // children: PropTypes.element.isRequired,
  title: PropTypes.string,
  size: PropTypes.oneOf(["sm", "mini", false]),
};

export const IFXTab2 = React.memo(({ size = "sm", children, ...props }) => (
  <div className={cx(`ifx-tab-container ifx-tab-container-${size}`)}>
    <Tab.Container mountOnEnter unmountOnExit {...props}>
      <Nav variant="pills">
        {children
          .filter(c => !!c)
          .map(({ props: { tabProps: { name, ...tabProps } } }, idx) => (
            <Nav.Item key={idx}>
              <Nav.Link {...tabProps}>{name}</Nav.Link>
            </Nav.Item>
          ))}
      </Nav>
      <Tab.Content>
        {children
          .filter(c => !!c)
          .map(({ props: { tabProps, ...childProps } }, idx) => (
            <Tab.Pane
              key={idx}
              className="p-0"
              eventKey={tabProps.eventKey}
              active={tabProps.active}
              {...childProps}
            />
          ))}
      </Tab.Content>
    </Tab.Container>
  </div>
));

export const IFXTabItem = ({ children }) => <>{children}</>;

IFXTab2.propTypes = {
  size: PropTypes.oneOf(["sm", "mini", false]),
};

IFXTabItem.propTypes = {
  tabProps: PropTypes.object,
};
