import React, {memo} from "react";
import { IFXButton, ButtonMode } from "../../..";
import { isEmptyOrNull } from "../../../../../utils/form";

export const ActionLinkButton = memo(({
  data,
  params,
  onClick,
  value,
  enableCondition = true,
  ...props
}) => {
  let _enableCondition = true;
  if (typeof enableCondition === "boolean") {
    _enableCondition = enableCondition;
  } else if (typeof enableCondition === "function") {
    _enableCondition = enableCondition(data);
  } else if (typeof enableCondition === "string") {
    _enableCondition =
      data[_enableCondition] !== undefined ? data[enableCondition] : true;
  }
  if (!_enableCondition) {
    return (
      <span className="ifx-cell-renderer-readonly ifx-text-overflow px-1 text-left">
        {value}
      </span>
    );
  } else {
    return (
      <div className="action-col-container">
        <IFXButton
          mode={ButtonMode.LABEL_FULL_MINI}
          type="link"
          className="ifx-text-overflow w-100 text-left"
          onClick={e => {
            onClick
              ? onClick(e, data, params)
              : console.error("No onClick() provided for action");
          }}
          {...props}
        >
          {value}
        </IFXButton>
      </div>
    );
  }
});

export const ButtonAsLinkRenderer = memo(props => {
  const { data, value, buttonProps = {} } = props;
  return (
    <div>
      {!isEmptyOrNull(value) ? (
        <ActionLinkButton data={data} value={value} {...buttonProps} />
      ) : null}
    </div>
  );
});
