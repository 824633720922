// Component Name: IFXAccordion
// Purpose: For collapsable Items as card view
// Properties:
//   title = type string
//   isOpen = type boolean for keep the subject by deffault open or close
//   isAccordion = type boolean for enabling or disabling the default feature of the accordion
// Developed by: Anik Chakraborty

import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Collapse, Button, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { omit } from "../../utils/utils";
import { IFXIcons, ColorOptions } from "./IFXIcons";
import cx from "classnames";
import { IFXAccordionHeaderIconButton } from "./IFXButton";
import { IFXButtonGroup } from "./IFXFormLayout";
import { useContentHeight } from "../../hooks/layoutHooks";

export const AccordionLayoutContext = React.createContext();

const DEFAULT_PROPS = {
  contentClassName: "pt-1 px-2 pb-2",
};

const AccordionActionButton = ({
  data,
  params,
  onClick,
  includeCondition = true,
  ...action
}) => {
  let _includeCondition = true;
  if (typeof includeCondition === "boolean") {
    _includeCondition = includeCondition;
  } else if (typeof includeCondition === "function") {
    _includeCondition = includeCondition(data);
  } else if (typeof includeCondition === "string") {
    _includeCondition =
      data[includeCondition] !== undefined ? data[includeCondition] : true;
  }

  if (!_includeCondition) {
    return null;
  } else {
    return (
      <IFXAccordionHeaderIconButton
        {...action}
        onClick={e => {
          onClick
            ? onClick(e, data, params, action)
            : console.error("No OnClick() provided for action", action);
        }}
      />
    );
  }
};

export const IFXAccordion = React.memo(
  forwardRef((props, ref) => {
    let _props = { ...DEFAULT_PROPS, ...props };
    const divRef = useRef(null);
    const [focused, setFocused] = useState(false);
    const {
      title = "",
      isOpen = true,
      isAccordion = true, //Enable expand/collapse
      contentClassName,
      marginClassName = "my-2",
      noBorder = false,
      className,
      noRoundedBorder = false,
      toggleCallback,
      accordionActions = [],
      focusable = false,
      contentHeightDelta,
    } = _props;

    _props = omit(_props, [
      "isOpen",
      "isAccordion",
      "title",
      "contentClassName",
      "focusable",
      "contentHeightDelta",
    ]);
    const [accordionToggle, setAccordionToggle] = useState(isOpen);
    let toggleButtons;

    if (isAccordion) {
      toggleButtons = (
        <span className="control-icon">
          {!accordionToggle ? (
            <IFXIcons
              icon="up-arrow"
              iconSource="svg"
              color={ColorOptions.white}
            />
          ) : (
            <IFXIcons
              icon="down-arrow"
              iconSource="svg"
              color={ColorOptions.white}
            />
          )}
        </span>
      );
    } else {
      toggleButtons = "";
    }

    useEffect(() => {
      setAccordionToggle(isOpen);
    }, [isOpen]);

    const collapse = () => {
      setAccordionToggle(false);
      toggleCallback && toggleCallback(false);
    };
    const expand = () => {
      setAccordionToggle(true);
      toggleCallback && toggleCallback(true);
    };

    const toggleAccordion = () => {
      const _accordion = !accordionToggle;
      setAccordionToggle(_accordion);
      toggleCallback && toggleCallback(_accordion);
    };

    useImperativeHandle(ref, () => {
      return {
        collapse,
        expand,
        toggleAccordion,
        getInstance: () => divRef.current,
      };
    });

    let _accordionActions = accordionActions;
    if (focusable) {
      _accordionActions = [...accordionActions]; //make a copy to avoid appending to same array
      _accordionActions.push({
        isToggle: true,
        icon: ["restore", "focus"],
        name: ["Show Less", "Show More"],
        defaultToggle: focused,
        onToggle: setFocused,
        key: focused,
      });
    }

    return (
      <div
        ref={divRef}
        className={cx(
          "ifx-accordion accordion-panel",
          marginClassName,
          className,
          noRoundedBorder && "accordion-no-rounded-border",
          noBorder && "accordion-no-border"
        )}
      >
        <div
          className={cx("accordion-item", {
            "no-header": title === "",
          })}
        >
          {title !== "" && (
            <div className="title d-flex">
              {isAccordion ? (
                <Button
                  className="toggle-button"
                  block
                  variant=""
                  onClick={toggleAccordion}
                >
                  {toggleButtons}
                  <div className="m-0 p-0 link">{title}</div>
                </Button>
              ) : (
                <Button
                  className="toggle-button cursor-default"
                  block
                  variant=""
                >
                  <div className="m-0 p-0 link">{title}</div>
                </Button>
              )}
              {accordionToggle && _accordionActions.length > 0 && (
                <Row>
                  <IFXButtonGroup
                    className="ml-3 mr-1"
                    marginClassName={false}
                    wrapClassName="flex-nowrap"
                  >
                    {_accordionActions.map((buttonProps, idx) => (
                      <AccordionActionButton key={idx} {...buttonProps} />
                    ))}
                  </IFXButtonGroup>
                </Row>
              )}
            </div>
          )}

          <Collapse in={accordionToggle}>
            <div>
              <IFXAccordionContent
                contentClassName={contentClassName}
                focused={focused}
                focusable={focusable}
                setFocused={setFocused}
                contentHeightDelta={contentHeightDelta}
              >
                {_props.children}
              </IFXAccordionContent>
            </div>
          </Collapse>
        </div>
      </div>
    );
  })
);

const IFXAccordionContent = React.memo(
  ({
    contentClassName,
    children,
    focused,
    focusable,
    setFocused,
    contentHeightDelta = 130,
  }) => {
    const contentHeight = useContentHeight({ delta: contentHeightDelta });
    //    console.log("contentHeight", contentHeight);

    const content = (
      <div className="ifx-accordion-content">
        <div className={contentClassName}>{children}</div>
      </div>
    );
    return (
      (focusable && (
        <AccordionLayoutContext.Provider
          value={{ setFocused, focused, contentHeight }}
        >
          {content}
        </AccordionLayoutContext.Provider>
      )) ||
      content
    );
  }
);

IFXAccordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.node.isRequired,
  ]),
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  isAccordion: PropTypes.bool,
  contentClassName: PropTypes.string,
  toggleCallback: PropTypes.func,
};
