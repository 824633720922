import React, { forwardRef, useCallback } from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import cx from "classnames";
import { omit } from "../../utils";
import { useCombinedRefs } from "../../hooks";

const DEFAULT_PROPS = {
  className: "ifx-textbox",
  size: "sm",
  type: "text",
  autoComplete: "off",
  required: false,
};

export const IFXValidationTextBox = forwardRef((props, ref) => {
  const combinedRef = useCombinedRefs(ref);

  let {
    required,
    onKeyPress,
    onFocus,
    preventPaste = false,
    regEx,
    ..._props
  } = props;
  _props = omit(_props, ["defaultValue", "value"]);

  const _onFocus = useCallback(
    e => {
      onFocus && onFocus(e);
      combinedRef.current.select();
    },
    [onFocus]
  );

  const regExCond = regEx || "";

  const _onPaste = useCallback(
    e => {
      if (preventPaste) return e.preventDefault();
      let clipboardData =
        e.clipboardData ||
        e.originalEvent.clipboardData ||
        window.clipboardData;
      let pastedData = clipboardData.getData("text");

      if (regExCond && !regExCond.test(pastedData)) {
        return e.preventDefault();
      }
    },
    [preventPaste, regEx]
  );

  const _onKeyPress = e => {
    if (preventPaste) return e.preventDefault();

    if (e.key !== "Enter" && regExCond && !regExCond.test(e.key)) {
      return e.preventDefault();
    }

    onKeyPress && onKeyPress(e);
  };

  return (
    <Form.Control
      {...DEFAULT_PROPS}
      {...(("value" in props && { value: props.value || "" }) || {
        defaultValue: props.defaultValue,
      })}
      {..._props}
      className={cx(DEFAULT_PROPS.className, props.className, {
        "field-mandatory": required,
      })}
      onPaste={_onPaste}
      onFocus={_onFocus}
      onKeyPress={_onKeyPress}
      ref={combinedRef}
    />
  );
});
